<app-main-header
  [title]="'tasks.title' | translate"
  [defaultPage]="(backButtonPage$ | async) || '/tasks/card'"
  [showBackButton]="showBackButton$ | async"
>
</app-main-header>

<ion-content class="main-content" [ngClass]="{'is-menu-pinned': isMenuPinned}" scrollY="false">
  <app-task-card-toolbar [taskId]="taskId$ | async" [inDetailView]="isInEntryView$ | async" (taskCreated)="handleTaskCreated($event.id)"></app-task-card-toolbar>

  <ion-content class="split-pane-content" scrollX="false" scrollY="false">
    <ion-split-pane
      contentId="main"
      [when]="'lg'"
      [style.flex-grow]="1"
      (ionSplitPaneVisible)="onSplitPaneVisible($event)"
    >
      <ion-content class="task-list-content split-pane-side" color="omg-card-surface">
        <ng-container *rxLet="hasTasksStatus$; let hasTasksStatus; renderCallback: cardListRendered;">
          <app-entry-card-list
            [class.ion-hide]="hasTasksStatus === 'no-entries'"
            [selectedEntryId]="currentTaskId$ | async"
            (entryClick)="navigateToTask($event.entry)"
            (threeDotsClick)="entryActions($event.event, $event.entry)"
          ></app-entry-card-list>
          <div class="entry-card-placeholder ion-hide-lg-down" *ngIf="hasTasksStatus === 'no-entries'"></div>
          <app-no-filtered-tasks slot="fixed" *ngIf="hasTasksStatus === 'empty-results'"></app-no-filtered-tasks>
        </ng-container>
      </ion-content>

      <ion-content class="detail-content" id="main" color="omg-card-surface">
        <ion-router-outlet></ion-router-outlet>
      </ion-content>
    </ion-split-pane>
  </ion-content>

  <ion-content class="filters" *ngIf="(isFilterVisible$ | async)" color="elevated">
    <div class="d-flex flex-column gap-2 pt-4">
      <div class="pl-6 pr-6 omg-modal-header">{{'tasks.filters.modal.title' | translate}}</div>
      <app-entry-filter></app-entry-filter>
    </div>
  </ion-content>
</ion-content>

<app-tab-menu></app-tab-menu>
