import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {isHttpApplicationError} from 'src/app/utils/error-utils';
import {PosthogService} from '../posthog/posthog.service';
import {AuthenticationService} from './authentication.service';
import {TokenManagerError, TokenManagerService} from './token-manager.service';
import {UnauthorizedModalService} from './unauthorized-modal.service';

@Injectable()
export class HttpResponseUnauthorizedInterceptor implements HttpInterceptor {

  constructor(private unauthorizedModalService: UnauthorizedModalService, private tokenManagerService: TokenManagerService, private posthogService: PosthogService) { }

  private getRequestWithNewAuthOrThrow(error: unknown, request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.tokenManagerService.getNewToken$().pipe(
      catchError((e) => throwError(e instanceof TokenManagerError ? error : e)),
      switchMap((auth) => {
        return next.handle(request.clone({
          setHeaders: {
            Authorization: 'Token ' + auth.token,
          }
        }));
      }),
      this.getUnauthorizedCatcher(request.headers.get('Authorization'), 'after_new_token_request')
    );
  }

  private getUnauthorizedCatcher<T>(authHeader: string, caughtIn: string) {
    return catchError<T, Observable<never>>((error: HttpErrorResponse) => {
      if (error?.status === 401) {
        this.posthogService.captureEvent('[Security][Forced_re-authentication] caught_401_error', {caughtIn});
        this.unauthorizedModalService.openModal(authHeader);
      }
      return throwError(error);
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (request.url === AuthenticationService.REFRESH_TOKEN_URL) {
            // 401 for refresh token must be skipped, so `getRequestWithNewAuthOrThrow` does not go into a loop/deadlock.
            // Also, it will allow `getUnauthorizedCatcher` to present login modal.
            if (isHttpApplicationError(error, 'AUTHENTICATION_INVALID_USERNAME_PASSWORD')) {
              this.posthogService.captureEvent('[Security][Forced_re-authentication] invalid_refresh_token', {});
              this.unauthorizedModalService.openModal(request.headers.get('Authorization'));
            }
          } else if (error?.status === 401) {
            return this.getRequestWithNewAuthOrThrow(error, request, next);
          }

          return throwError(error);
        }),
        this.getUnauthorizedCatcher(request.headers.get('Authorization'), 'after_intercept_catch_error')
      );
  }
}
