<ion-textarea color="text-primary" class="no-bottom-line-when-focus no-padding" rows="1" [ngModel]="value.text" (ngModelChange)="updateModel({ text: $event })" autoGrow [placeholder]="'emailSignature.form.text' | translate"></ion-textarea>

<div class="image-container" *ngIf="value.includeCompanyLogo; else addIncludeCompanyLogo">
  <ng-container *ngIf="(userEmailSignatureImage$ | async) as userEmailSignatureImage; else noSignatureUploaded">
    <ion-img class="logo-size" [ngClass]="'logo-size--' + (value.size | lowercase)" [src]="userEmailSignatureImage | imageUri:'image' | async"></ion-img>
    <ion-button class="details-button omg-btn-secondary" size="small" (click)="clientLogoDetailsClick($event)">
      <fa-icon [icon]="['fal', 'ellipsis-h']"></fa-icon>
    </ion-button>
  </ng-container>
</div>

<ng-template #noSignatureUploaded>
  <ion-button (click)="fileUploadLogo.click()" class="omg-btn-secondary omg-btn-upload">
    <div>
      <fa-icon [icon]="['fal', 'paperclip']" size="lg"></fa-icon>
      <span>{{ 'emailSignature.form.image' | translate }}</span>
    </div>
  </ion-button>
  <input type="file" [accept]="acceptedMimeTypesForUpload" (change)="handleFileUpload($event)" #fileUploadLogo class="ion-hide">
</ng-template>

<ng-template #addIncludeCompanyLogo>
  <ion-button class="omg-btn-text" (click)="updateModel({ includeCompanyLogo: true })">{{ 'emailSignature.form.addSignatureImage' | translate }}</ion-button>
</ng-template>
