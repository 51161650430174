<app-modal-header [showCloseButton]="false">
  <ion-title>{{'LOGIN.title' | translate}}</ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <div class="omg-attention omg-attention-warning ion-text-center flex-column">
    <div class="omg-t-header-3-bold">
      {{'loginModal.sessionExpired' | translate}}
    </div>
    <div class="omg-t-body">
      {{'loginModal.sessionExpiredExplanation' | translate}}
    </div>
  </div>
  <app-login
    *rxLet="username$; let username"
    [forcedUsername]="username"
    [loginOptions]="loginOptions"
    (loggedIn)="dismiss()"
    [redirectOnLoggedIn]="false"
    [posthogEventType]="'Forced_re-authentication'"
  ></app-login>
  <ion-button class="omg-btn-secondary" [class.omg-btn-color-danger]="hasLocalChanged$ | async" (click)="logout()">
    {{'MENU.logout' | translate}}
  </ion-button>
</ion-content>
