import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {map} from 'rxjs/operators';
import {IdType, ProtocolEntryDefaultValue, User} from 'submodules/baumaster-v2-common';
import {AbstractProjectAwareDataService} from './abstract-project-aware-data.service';
import {ProjectDataService} from './project-data.service';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';

const REST_ENDPOINT_URI = 'api/data/protocolEntryDefaultValues';
const VERSION_INTRODUCED = '2.31.0'; // todo: Change before release

@Injectable({
  providedIn: 'root'
})
export class ProtocolEntryDefaultValueDataService extends AbstractProjectAwareDataService<ProtocolEntryDefaultValue> {
  constructor(http: HttpClient, storage: StorageService, authenticationService: AuthenticationService,
              protected projectDataService: ProjectDataService,
              protected projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
              loggingService: LoggingService, integrityResolverService: IntegrityResolverService,
              protected userService: UserService) {
    super(StorageKeyEnum.PROTOCOL_ENTRY_DEFAULT_VALUE, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, projectDataService, loggingService,
          projectAvailabilityExpirationService, integrityResolverService, VERSION_INTRODUCED);
  }

  public getByProtocolId(protocolId: IdType): Observable<ProtocolEntryDefaultValue | undefined> {
    return this.data.pipe(
      map((protocolEntryDefaultValues: ProtocolEntryDefaultValue[]) =>
        protocolEntryDefaultValues.find(protocolEntryDefaultValue => protocolEntryDefaultValue.protocolId === protocolId)
      )
    );
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }

}
