<app-modal-header [showCloseButton]="false">
  <ion-title>{{'maxDevices.modalTitle' | translate}}</ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <app-user-devices (selectionChanged)="selectionChanged($event)" (inactiveChanged)="inactiveChanged($event)"></app-user-devices>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-secondary" (click)="refresh()">{{'maxDevices.refresh' | translate}}</ion-button>
    <ion-button class="omg-btn-secondary" (click)="logout()">{{'maxDevices.logout' | translate}}</ion-button>
    <ion-button class="omg-btn-primary" [disabled]="buttonDisabled" (click)="removeSelectedDevice()">
      <ion-spinner slot="start" class="spinner-center-button" *ngIf="buttonDisabled"></ion-spinner>
      <span>{{'maxDevices.removeAndConfirm' | translate}}</span>
    </ion-button>
  </ion-buttons>
</app-modal-footer>
