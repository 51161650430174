import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TrackByFunction} from '@angular/core';
import {Observable, of} from 'rxjs';
import {ProfileDataService} from '../../../services/data/profile-data.service';
import {PdfProtocolSignatureForm} from './pdf-protocol-signatures.interface';
import {ContactService} from '../../../services/contact/contact.service';
import {ProfileCompanyAddress} from '../../../model/contacts';
import {ProtocolSignatureService} from '../../../services/protocol/protocol-signature.service';
import {AlertController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {ToastService} from '../../../services/common/toast.service';
import {LicenseType} from 'submodules/baumaster-v2-common';
import {FeatureEnabledService} from '../../../services/feature/feature-enabled.service';
import {KeyboardResizeOptions} from '@capacitor/keyboard';
import {IonicSelectableComponent} from 'ionic-selectable';
import {SelectableUtilService} from '../../../services/common/selectable-util.service';

const SIGNER_NAME_MAX_LENGTH = 55;

@Component({
  selector: 'app-pdf-protocol-signatures',
  templateUrl: './pdf-protocol-signatures.component.html',
  styleUrls: ['./pdf-protocol-signatures.component.scss'],
})
export class PdfProtocolSignaturesComponent implements OnInit, OnChanges {

  @Input()
  showSignatures?: boolean;
  @Output()
  showSignaturesChange = new EventEmitter<boolean>();

  @Input()
  pdfProtocolSignatures: PdfProtocolSignatureForm[] = [];
  @Output()
  pdfProtocolSignaturesChange = new EventEmitter<PdfProtocolSignatureForm[]>();
  public operationInProgress = false;

  public profileCompanyAddresses$: Observable<Array<ProfileCompanyAddress>>;
  public selectedProfileCompanyAddresses$: Observable<ProfileCompanyAddress[]> = of([]);
  public readonly isFeatureEnabled$ = this.featureEnabledService.isFeatureEnabled$(false, true, [LicenseType.VIEWER, LicenseType.LIGHT]);
  public readonly trackByProfileOrName: TrackByFunction<PdfProtocolSignatureForm> =
    (index, pdfProtocolSignature: PdfProtocolSignatureForm) => pdfProtocolSignature.profileId || pdfProtocolSignature.name;

  private resizeModeBeforeOpen: KeyboardResizeOptions | undefined;

  constructor(private profileDataService: ProfileDataService, private contactService: ContactService, private protocolSignatureService: ProtocolSignatureService,
              private alertController: AlertController, private translateService: TranslateService, private toastService: ToastService, private featureEnabledService: FeatureEnabledService,
              private selectableUtilService: SelectableUtilService) {}

  ngOnInit() {
    this.profileCompanyAddresses$ = this.contactService.getProfileCompanyAddresses$();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.pdfProtocolSignatures) {
      this.updateSelectedProfileCompanyAddresses();
    }
  }

  async onShowChange(show: boolean) {
    this.showSignatures = show;
    this.showSignaturesChange.emit(this.showSignatures);
  }

  isValid(): boolean {
    return true;
  }

  private updateSelectedProfileCompanyAddresses() {
    const profileIds = this.pdfProtocolSignatures?.filter((pdfProtocolSignature) => pdfProtocolSignature.profileId)
      .map((pdfProtocolSignature) => pdfProtocolSignature.profileId) ?? [];
    this.selectedProfileCompanyAddresses$ = this.contactService.getProfileCompanyAddresses$(profileIds);
  }

  public async handleProfileCompanyAddressesChange(profileCompanyAddresses: Array<ProfileCompanyAddress>) {
    try {
      this.operationInProgress = true;
      let pdfProtocolSignatures: PdfProtocolSignatureForm[] = this.protocolSignatureService.filterProtocolSignaturesCustomName(this.pdfProtocolSignatures);
      for (const profileCompanyAddress of profileCompanyAddresses) {
        const existingProfileCompanyAddress = this.pdfProtocolSignatures.find((existingPdfProtocolSignature) => existingPdfProtocolSignature.profileId === profileCompanyAddress.profile.id);
        if (existingProfileCompanyAddress) {
          pdfProtocolSignatures.push(existingProfileCompanyAddress);
        } else {
          pdfProtocolSignatures.push({
            profileId: profileCompanyAddress.profile.id
          });
        }
      }
      pdfProtocolSignatures = await this.protocolSignatureService.sortPdfProtocolSignatures(pdfProtocolSignatures);
      this.pdfProtocolSignaturesChange.emit(pdfProtocolSignatures);
    } finally {
      this.operationInProgress = false;
    }
  }

  public async handlePdfProtocolSignatureChange(pdfProtocolSignature: PdfProtocolSignatureForm, index: number) {
    try {
      this.operationInProgress = true;
      this.pdfProtocolSignaturesChange.emit(this.pdfProtocolSignatures);
    } finally {
      this.operationInProgress = false;
    }
  }

  public async handlePdfProtocolSignatureDelete(pdfProtocolSignature: PdfProtocolSignatureForm, index: number) {
    try {
      this.operationInProgress = true;
      this.pdfProtocolSignatures.splice(index, 1);
      this.pdfProtocolSignaturesChange.emit(this.pdfProtocolSignatures);
      this.updateSelectedProfileCompanyAddresses();
    } finally {
      this.operationInProgress = false;
    }
  }

  public async addCustomSigner() {
    try {
      this.operationInProgress = true;
      const name = await this.showAlertName();
      if (!name) {
        return;
      }
      if (this.findPdfProtocolSignatureWithName(name)) {
        await this.toastService.error('sendProtocol.protocolConfig.signature.signerNameExists');
        return;
      }
      this.pdfProtocolSignatures.push({
        name
      });
      this.pdfProtocolSignatures = await this.protocolSignatureService.sortPdfProtocolSignatures(this.pdfProtocolSignatures);
      this.pdfProtocolSignaturesChange.emit(this.pdfProtocolSignatures);
    } finally {
      this.operationInProgress = false;
    }
  }

  private findPdfProtocolSignatureWithName(name: string): PdfProtocolSignatureForm|undefined {
    if (!this.pdfProtocolSignatures?.length) {
      return undefined;
    }
    return this.pdfProtocolSignatures.find((pdfProtocolSignature) => !pdfProtocolSignature.profileId && pdfProtocolSignature.name === name);
  }

  private async showAlertName(): Promise<string|undefined> {
    const alert = await this.alertController.create(
      {
        header: this.translateService.instant('sendProtocol.protocolConfig.signature.addCustomSigner'),
        inputs: [
          {type: 'text', id: 'signerName', name: 'signerName', min: 1, max: SIGNER_NAME_MAX_LENGTH,
            placeholder: this.translateService.instant('sendProtocol.protocolConfig.signature.signerNameLabel'), tabindex: 0}
        ],
        buttons: [{
          text: this.translateService.instant('okay'),
          role: 'ok'
        },
          {
            text: this.translateService.instant('cancel'),
            role: 'cancel'
          }]
      });
    await alert.present();
    const result = await alert.onDidDismiss();
    if (result.role !== 'ok') {
      return undefined;
    }
    let signerName = result.data?.values?.signerName;
    if (!signerName?.length) {
      await this.toastService.error('sendProtocol.protocolConfig.signature.signerNameMandatory');
      return undefined;
    }
    if (signerName.length > SIGNER_NAME_MAX_LENGTH) {
      signerName = signerName.substring(0, SIGNER_NAME_MAX_LENGTH);
    }
    return signerName;
  }

  async onOpen($event: { component: IonicSelectableComponent }) {
    this.resizeModeBeforeOpen = await this.selectableUtilService.setKeyboardResizeModeOnOpen();
  }

  async onClose($event: { component: IonicSelectableComponent }) {
    await this.selectableUtilService.setKeyboardResizeModeOnClose($event, this.resizeModeBeforeOpen);
  }
}
