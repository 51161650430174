import { Directive, ElementRef, Input } from '@angular/core';
import {Nullish} from 'src/app/model/nullish';

/**
 * The only purpose of this directive is to fix an issue with virtual scroll + ion-radios.
 *
 * When scrolling, different radio buttons (meaning next to other item) start to have active state,
 * even though it is not active. To prevent that, private method inside ion-radio must be called.
 * The only way to call it is to emit `ionChange` event from `ion-radio-group`.
 *
 * Since ion-virtual-scroll reuses the same ion-radio component instance for different values,
 * this directive checks if the value changes, and if it had, an custom event is manually dispatched.
 *
 * @deprecated Remove once ion-virtual-scroll is removed from the app
 */
@Directive({
  selector: 'ion-radio[appIonRadio]'
})
export class IonRadioDirective {

  private _value: string|undefined;
  private get radioGroup(): Nullish<HTMLIonRadioGroupElement> {
    return this.elementRef.nativeElement.closest('ion-radio-group');
  }

  @Input()
  set value(value: string|undefined) {
    const {_value} = this;
    this._value = value;
    if (this.radioGroup && value !== _value) {
      setTimeout(() => this.radioGroup.dispatchEvent(new CustomEvent('ionChange', {
        detail: {
          value: this.radioGroup.value
        }
      })));
    }
  }

  get value() {
    return this._value;
  }


  constructor(private elementRef: ElementRef<HTMLIonRadioElement>) {}

}
