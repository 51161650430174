<ion-segment class="omg" [ngModel]="projectMode" (ngModelChange)="projectModeChange.emit($event)">
  <ion-segment-button value="currentProject">
    <div class="d-flex gap-1 ion-align-items-baseline">
      <span>{{ 'this_project' | translate }}</span>
      <span *ngIf="unseenCountByMode.currentProject > 0" class="omg-notification-badge">{{ unseenCountByMode.currentProject > 99 ? '99+' : unseenCountByMode.currentProject }}</span>
    </div>
  </ion-segment-button>
  <ion-segment-button value="allProjects">
    <div class="d-flex gap-1 ion-align-items-baseline">
      <span>{{ 'all_projects' | translate }}</span>
      <span *ngIf="unseenCountByMode.allProjects > 0" class="omg-notification-badge">{{ unseenCountByMode.allProjects > 99 ? '99+' : unseenCountByMode.allProjects }}</span>
    </div>
  </ion-segment-button>
</ion-segment>

<div class="d-flex gap-2">
  <ion-button [disabled]="unseenCountByMode[projectMode] === 0" class="omg-btn-secondary flex-grow-1" (click)="markAllAsSeenClick.emit($event)">
    {{'notificationCenter.markAllAsSeen' | translate:{count:unseenCountByMode[projectMode]} }}
  </ion-button>
  <ion-button id="notification-toolbar-show-seen" [class.omg-btn-color-selected]="!showSeen" class="omg-btn-secondary" (click)="showSeenClick.emit($event)">
    <fa-icon [icon]="['fal', 'eye-slash']"></fa-icon>
  </ion-button>
  <app-tooltip target="notification-toolbar-show-seen" placement="bottom">
    {{ 'notificationCenter.showSeen.tooltip' | translate }}
  </app-tooltip>
  <ion-button id="notification-toolbar-settings" class="omg-btn-secondary ion-hide-sm-down" (click)="settingsClick.emit($event)">
    <fa-icon [icon]="['fal', 'cog']"></fa-icon>
  </ion-button>
  <app-tooltip target="notification-toolbar-settings" placement="bottom">
    {{ 'notificationCenter.settings.tooltip' | translate }}
  </app-tooltip>
</div>
