import {Injectable} from '@angular/core';
import {AbstractProjectAwareDataService} from './abstract-project-aware-data.service';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {ProjectDataService} from './project-data.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {IdType, ProtocolEntryChat, User} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import _ from 'lodash';
import {StorageKeyEnum} from '../../shared/constants';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';

const REST_ENDPOINT_URI = 'api/data/protocolEntryChats';

@Injectable({
  providedIn: 'root'
})
export class ProtocolEntryChatDataService extends AbstractProjectAwareDataService<ProtocolEntryChat> {

  readonly dataByProtocolEntryId$: Observable<Record<IdType, ProtocolEntryChat[]>> = this.data.pipe(
    map((chats) => _.groupBy(chats ?? [], 'protocolEntryId'))
  );
  readonly dataByProtocolEntryIdAcrossProjects$: Observable<Record<IdType, ProtocolEntryChat[]>> = this.dataAcrossProjects$.pipe(
    map((chats) => _.groupBy(chats ?? [], 'protocolEntryId'))
  );

  constructor(http: HttpClient, storage: StorageService, authenticationService: AuthenticationService, userService: UserService, protected projectDataService: ProjectDataService,
              protected projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
              loggingService: LoggingService, integrityResolverService: IntegrityResolverService) {
    super(StorageKeyEnum.PROTOCOL_ENTRY_CHAT, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, projectDataService, loggingService, projectAvailabilityExpirationService,
          integrityResolverService);
  }

  public getByProtocolEntry(protocolEntryId: IdType): Observable<Array<ProtocolEntryChat>> {
    return this.data.pipe(
      map(entries => _.chain(entries).filter(protocolEntryChat => protocolEntryChat.protocolEntryId === protocolEntryId).sortBy(f => new Date(f.createdAt)).value())
    );
  }

  public getByProtocolEntryAcrossProjects(protocolEntryId: IdType): Observable<Array<ProtocolEntryChat>> {
    return this.dataAcrossProjects$.pipe(
      map(entries => _.chain(entries).filter(protocolEntryChat => protocolEntryChat.protocolEntryId === protocolEntryId).sortBy(f => new Date(f.createdAt)).value())
    );
  }

  public getByProtocolEntries(protocolEntryIds: Array<IdType>): Observable<Array<ProtocolEntryChat>> {
    return this.data.pipe(
      map(entries => _.chain(entries).filter(protocolEntryChat => protocolEntryIds.some((protocolEntryId) => protocolEntryId === protocolEntryChat.protocolEntryId))
        .sortBy(f => new Date(f.createdAt)).value())
    );
  }

  public getByProtocolEntriesAcrossProjects(protocolEntryIds: Array<IdType>): Observable<Array<ProtocolEntryChat>> {
    return this.dataAcrossProjects$.pipe(
      map(entries => _.chain(entries).filter(protocolEntryChat => protocolEntryIds.some((protocolEntryId) => protocolEntryId === protocolEntryChat.protocolEntryId))
        .sortBy(f => new Date(f.createdAt)).value())
    );
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }
}
