import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import {TagBase} from 'submodules/baumaster-v2-common';
import {v4} from 'uuid';

const colors = [
  'blue',
  'red',
  'yellow',
  'green',
  'dark-blue',
  'orange',
  'purple',
  'pink',
  'brown',
  'grey'
];
const pickRandomColor = () => colors[Math.floor(Math.random() * colors.length)];

@Injectable()
export class TagsPopoverService implements OnDestroy {

  readonly newIdPrefix = 'new-';

  private searchSubject = new BehaviorSubject('');
  search$ = this.searchSubject.asObservable();
  private searchTagColorAndId$: Observable<[string, string]> = this.search$.pipe(
    map((v) => !!v),
    distinctUntilChanged(),
    map(() => [pickRandomColor(), `${this.newIdPrefix}${v4()}`]),
  );

  set search(search: string) {
    this.searchSubject.next(search);
  }

  get search() {
    return this.searchSubject.value;
  }

  constructor() { }

  getTagsWithFilter$(allTags$: Observable<TagBase[]>): Observable<TagBase[]> {
    return allTags$.pipe(
      switchMap((tags) => this.search$.pipe(
        map((searchString) => searchString ? [
          ...tags.filter((tag) => tag.name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())),
        ] : tags)
      ))
    );
  }

  getTagsWithFilterAndNew$(
    tags$: Observable<TagBase[]>,
    excludedTags$: Observable<TagBase[]>
  ): Observable<TagBase[]> {
    return combineLatest([
      tags$,
      excludedTags$,
      this.search$,
      this.searchTagColorAndId$
    ]).pipe(map(([filteredTags, excludedTags, searchString, [color, newId]]) =>
      [
        ...filteredTags.filter((tag) => !excludedTags.some(({ id }) => id === tag.id)),
        ...(!searchString || filteredTags.some((tag) => tag.name === searchString) ? [] : [{
          id: newId,
          color,
          isActive: true,
          name: searchString,
          createdAt: new Date().toISOString(),
          changedAt: new Date().toISOString(),
        }])
      ]
    ));
  }

  ngOnDestroy() {
    this.searchSubject.complete();
  }
}
