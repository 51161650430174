import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {ActiveSortType} from 'src/app/services/project-room/pdf-plans-filter.service';
import {PlanAnalyticsService} from 'src/app/services/project-room/plan-analytics.service';

@Component({
  selector: 'app-pdf-plan-holder-list-header',
  templateUrl: './pdf-plan-holder-list-header.component.html',
  styleUrls: ['./pdf-plan-holder-list-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfPlanHolderListHeaderComponent {
  @HostBinding('class.is-edit-mode')
  @Input()
  editMode: boolean;

  @Input()
  activeSort: ActiveSortType;

  @Input()
  hideCheckbox = false;

  @Input()
  selected = false;

  @Input()
  indeterminate = false;

  @Output()
  selectedChange = new EventEmitter<boolean>();

  @Output()
  activeSortChange = new EventEmitter<ActiveSortType>();

  constructor(public analytics: PlanAnalyticsService) {}

}
