import { ColumnConfig } from '../ColumnConfig';
import { skipColumn } from './skipColumn';

const UniqueOpenEntriesInProtocolUniq = ['protocol_id', 'protocolentry_id'];

export const ProtocolOpenEntryColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true, skip: skipColumn},
  {name: 'protocol_id', prop: 'protocolId', compositeKey: true, uniqueWith: [UniqueOpenEntriesInProtocolUniq]},
  {name: 'protocolentry_id', prop: 'protocolEntryId', compositeKey: true, uniqueWith: [UniqueOpenEntriesInProtocolUniq]},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'}
];
