import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, take} from 'rxjs/operators';
import {EnsureStorageInitializedGuard} from 'src/app/guards/ensure-storage-initialized.guard';
import {DashboardFilter} from 'src/app/model/dashboard-filter';
import ProjectForDisplay from 'src/app/model/ProjectForDisplay';
import {ProtocolEntrySearchFilter} from 'src/app/model/protocol-entry-search-filter';
import {combineLatestAsync} from 'src/app/utils/async-utils';
import {compareDashboardFilters, dashboardFiltersToProtocolEntryFilters} from 'src/app/utils/dashboard-utils';
import {ProtocolEntryIconStatus, ProtocolEntryPriorityLevel} from 'submodules/baumaster-v2-common';
import {AuthenticationService} from '../auth/authentication.service';
import {ProjectDataService} from '../data/project-data.service';
import {ProtocolEntryCompareFilter, ProtocolEntryInFilter} from '../protocol/protocol-entry-filter.service';
import {EMPTY_PROTOCOL_ENTRY_SEARCH_FILTER} from '../search/protocol-entry-search-filter.service';
import {ProjectService} from '../project/project.service';
import _ from 'lodash';
import {SyncService} from '../sync/sync.service';

export const DEFAULT_DASHBOARD_FILTER: DashboardFilter = {
  companies: null,
  projectTeam: null,
  date: {
    type: 'all',
  },
  priorities: [null, ProtocolEntryPriorityLevel.HIGH, ProtocolEntryPriorityLevel.MEDIUM, ProtocolEntryPriorityLevel.LOW],
  projects: null,
  protocolTypes: null,
  protocols: null,
  statuses: [ProtocolEntryIconStatus.OPEN, ProtocolEntryIconStatus.ON_HOLD],
};

@Injectable({
  providedIn: 'root'
})
export class DashboardFilterService {

  private filtersSubject = new BehaviorSubject<DashboardFilter>(DEFAULT_DASHBOARD_FILTER);
  readonly filters$ = this.filtersSubject.asObservable().pipe(distinctUntilChanged(compareDashboardFilters));
  readonly entryFilters$: Observable<(ProtocolEntryInFilter | ProtocolEntryCompareFilter)[]> = this.filters$.pipe(
    map(dashboardFiltersToProtocolEntryFilters)
  );
  readonly protocolFilters$: Observable<ProtocolEntrySearchFilter> = this.filters$.pipe(
    map((filters) => ({
      project: { in: filters.projects ?? [] },
      protocol: {
        projectId: { in: filters.projects ?? [] },
        typeId: { in: filters.protocolTypes ?? [] },
        id: { in: filters.protocols ?? [] },
      },
      entry: _.cloneDeep(EMPTY_PROTOCOL_ENTRY_SEARCH_FILTER.entry),
    }))
  );

  get filters(): DashboardFilter {
    return this.filtersSubject.value;
  }

  readonly projects$ = this.projectService.activeProjectsForDisplay$.pipe(
    map((projects) => projects.map((project) => ({
      ...project,
      recentlyUsedLabel: project.isAvailable || project.isOfflineAvailable ? this.translateService.instant('recently_used') : this.translateService.instant('others'),
    }))),
    startWith<ProjectForDisplay[]>([]),
  );

  readonly defaultProjects$: Observable<ProjectForDisplay[]|null> = combineLatestAsync([this.projects$, this.projectDataService.currentProjectObservable]).pipe(
    map(([projects, currentProject]) => {
      const currentProjectForDisplay = projects.find(({id}) => id === currentProject?.id) ?? null;
      if (!currentProjectForDisplay) {
        return currentProjectForDisplay as null;
      }
      return [currentProjectForDisplay];
    })
  );
  readonly defaultFilter$ = this.projectDataService.currentProjectObservable.pipe(
    map((project) => ({
      ...DEFAULT_DASHBOARD_FILTER,
      projects: project ? [project.id] : null,
    }))
  );

  constructor(
    private ensureStorageInitializedGuard: EnsureStorageInitializedGuard,
    private projectService: ProjectService,
    private projectDataService: ProjectDataService,
    private translateService: TranslateService,
    private authService: AuthenticationService,
    private syncService: SyncService,
  ) {
    this.getCurrentProjectAndSetItToCurrentFilter();

    this.authService.isAuthenticated$.pipe(
      filter((v) => !v)
    ).subscribe(() => this.filtersSubject.next({...DEFAULT_DASHBOARD_FILTER}));
  }

  private getCurrentProjectAndSetItToCurrentFilter() {
    this.authService.isAuthenticated$.pipe(
      filter((v) => v),
      switchMap(() => this.ensureStorageInitializedGuard.canActivate().pipe(
        switchMap(() => this.projectDataService.dataReally),
        debounceTime(0),
        switchMap(() => this.defaultProjects$),
        take(1)
      ))
    ).subscribe((projects) => {
      if (compareDashboardFilters(this.filtersSubject.value, DEFAULT_DASHBOARD_FILTER)) {
        this.filtersSubject.next({
          ...DEFAULT_DASHBOARD_FILTER,
          projects: projects?.map(({id}) => id) ?? null,
        });
      }
    });
  }

  setFilters(filters: DashboardFilter) {
    if (!compareDashboardFilters(filters, this.filtersSubject.value)) {
      this.filtersSubject.next(filters);
    }
  }

  patchFilters(filters: Partial<DashboardFilter>) {
    if (filters.projects) {
      this.syncService.additionalActiveProjectIdsToSync = filters.projects;
    }
    this.filtersSubject.next({
      ...this.filtersSubject.value,
      ...filters,
    });
  }

  async clearFilters(): Promise<DashboardFilter> {
    const currentProject = await this.projectDataService.getMandatoryCurrentProject();
    const filters: DashboardFilter = {
      ...DEFAULT_DASHBOARD_FILTER,
      projects: [currentProject.id],
    }
    this.setFilters(filters);
    this.syncService.additionalActiveProjectIdsToSync = [currentProject.id];
    return filters;
  }

  clearFiltersEmptyProjects(): DashboardFilter {
    const filters: DashboardFilter = {
      ...DEFAULT_DASHBOARD_FILTER,
      projects: [],
    }
    this.setFilters(filters);
    this.syncService.additionalActiveProjectIdsToSync = [];
    return filters;
  }
}
