<ion-header class="ion-no-border">
  <ion-grid class="subheader project-subheader">
    <ion-row>
      <app-project-selector-col>
        <app-project-selector></app-project-selector>
      </app-project-selector-col>
      <ion-col size-xs="12" size-sm>
        <div class="options-row">
          <div class="options-item">
            <ion-button class="omg-btn-secondary" (click)="toggleSortOrder()" [id]="'protocol-list-sort-button-' + instanceId">
              <fa-icon [icon]="['fal', 'sort-alt']"></fa-icon>
            </ion-button>
            <app-tooltip [target]="'protocol-list-sort-button-' + instanceId" placement="bottom">
              {{ 'Sort' | translate }}
            </app-tooltip>
          </div>
          <div class="option-closed options-item ion-align-self-center ion-text-end d-flex ion-align-items-center">
            <ion-toggle appSlimToggle (ionChange)="toggleClosed($event)" #ionToggleClosed
              class="close-protocols-toggle" checked="true"></ion-toggle>
            <ion-text class="size-sm label">
              {{ "closed" | translate }}{{
                ionToggleClosed.checked
                ? ""
                : ("closedProtocolCount"
                | translate: { count: closedProtocolCount })
              }}
            </ion-text>
          </div>
          <div class="options-item ion-hide-lg-up">
            <app-protocol-type-selector [(protocolType)]="protocolTypeFilter" layout="selectable">
            </app-protocol-type-selector>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid class="ion-no-padding subheader list-title text-secondary">
    <ion-row>
      <ion-col>
        <div class="app-split-pane top-split-pane ion-text-center">
          <div class="app-split-pane-side">
            <ion-text class="size-xs">
              {{ 'project_settings.protocol_types' | translate }}
            </ion-text>
          </div>
          <div class="app-split-pane-main">
            <ion-text class="size-xs">
              {{ 'copyWorkflow.protocols' | translate }}
            </ion-text>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-header>
<ion-content>
  <div class="app-split-pane">
    <ion-content
      color="omg-sidebar-bg"
      class="
        ion-hide-lg-down
        protocol-type-list
        app-split-pane-side
      "
    >
      <app-protocol-type-selector
        [(protocolType)]="protocolTypeFilter"
        layout="list"
      >
      </app-protocol-type-selector>
    </ion-content>
    <ion-content
      color="omg-sidebar-bg"
      #content
      class="protocol-list app-split-pane-main"
    >
    <ng-container *ngIf="filteredProtocols?.length === 0">
      <ng-template #noRowsInProtocolType>
        <div class="no-rows-found ion-hide-lg-up">
          <app-no-content-svg [messageString]="'noProtocolsFound' | translate:{protocolType: (protocolTypeFilterObservable | async)?.name}"></app-no-content-svg>
        </div>
        <div class="no-rows-found ion-hide-lg-down">
          <app-no-content-svg [messageString]="'noProtocolsFoundBig' | translate:{protocolType: (protocolTypeFilterObservable | async)?.name}"></app-no-content-svg>
        </div>
      </ng-template>
      <div class="no-rows-found" *ngIf="!(protocolTypeFilterObservable | async)?.name; else noRowsInProtocolType">
        <app-no-content-svg [messageString]="'noProtocols' | translate"></app-no-content-svg>
      </div>
    </ng-container>
      <rx-virtual-scroll-viewport #virtualScroll *ngIf="filteredProtocols?.length" [itemSize]="115">
        <app-protocol-detail *rxVirtualFor="let protocol of filteredProtocols; trackBy trackById; let itemsBounds = bounds; let index = index"
                             [protocol]="protocol" [routerLink]="[protocolsBase, 'view', protocol.id]" [active]="selectedProtocol?.id === protocol.id">
        </app-protocol-detail>
      </rx-virtual-scroll-viewport>
    </ion-content>
  </div>
</ion-content>
