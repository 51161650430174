<app-modal-header>
  <ion-title>{{'copyWorkflow.protocol.title' | translate}}</ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <form class="omg-form" [formGroup]="copyForm" action="#">
    <ion-grid>
      <ion-row>
        <ion-col size="12">
          <ion-item>
            <ion-label position="floating">{{ 'copyWorkflow.destinationProject' | translate }}</ion-label>
            <ionic-selectable #projectSelectable
                              hasVirtualScroll="true"
                              *ngIf="projects?.length"
                              [isMultiple]="false"
                              required="true"
                              (onChange)="projectOnChange($event)"
                              item-content
                              itemTextField="name"
                              formControlName="project"
                              [items]="projects"
                              itemValueField="id"
                              [canSearch]="true"
                              searchPlaceholder="{{'search' | translate}}"
                              searchFailText="{{'noItemsFound' | translate}}"
                              appSelectableCommon
                              #fs="appSelectableCommon"
                              (onOpen)="onOpen($event)"
                              (onClose)="onClose($event)"
                              [virtualScrollHeaderFn]="getGroupText"
                              [shouldFocusSearchbar]="fs.isDesktop">
              <ng-template ionicSelectableItemTemplate let-project="item" let-isSelected="isItemSelected">
                <app-project-selector-item [project]="project"></app-project-selector-item>
              </ng-template>
              <ng-template ionicSelectableItemIconTemplate let-selected="isItemSelected">
                <app-selectable-item-icon [selected]="selected"></app-selectable-item-icon>
              </ng-template>
              <ng-template ionicSelectableValueTemplate let-value="value">
                <app-selectable-value [value]="value"></app-selectable-value>
              </ng-template>
              <ng-template ionicSelectableHeaderTemplate>
                <app-selectable-header></app-selectable-header>
              </ng-template>
              <ng-template ionicSelectableFooterTemplate>
                <app-selectable-footer [canClear]="false"></app-selectable-footer>
              </ng-template>
            </ionic-selectable>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <ion-item>
            <ion-input label="{{ 'protocol.name' | translate }}" label-placement="floating" type="text" formControlName="protocolName" maxlength="50"></ion-input>
          </ion-item>
          <div *ngIf="copyForm.controls.protocolName.errors?.required && copyForm.controls.protocolName.dirty" class="error-message"> {{ 'formValidation.emptyField' | translate:{field: 'protocol.name' | translate} }}</div>
          <div *ngIf="copyForm.controls.protocolName.errors?.nameExist" class="error-message"> {{ 'copyWorkflow.protocolNameAlreadyExists' | translate }} </div>
          <div *ngIf="copyForm.controls.protocolName.errors?.continuousProtocolExists" class="error-message"> {{ 'copyWorkflow.continuousProtocolAlreadyExists' | translate }} </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <ion-item>
            <ion-input label="{{ 'copyWorkflow.protocol.copyAmount' | translate }}" label-placement="floating" type="text" formControlName="amountCopies"></ion-input>
          </ion-item>
          <div *ngIf="copyForm.controls.amountCopies.errors?.notANumber" class="error-message"> {{ 'copyWorkflow.mustBeANumber' | translate }} </div>
          <div *ngIf="copyForm.controls.amountCopies.errors?.numberOutOfRange" class="error-message"> {{ 'copyWorkflow.inputRange' | translate }} </div>
          <div *ngIf="copyForm.controls.amountCopies.errors?.continuousOnlyOneCopy" class="error-message"> {{ 'copyWorkflow.continuousOneCopy' | translate }} </div>
        </ion-col>
      </ion-row>
    </ion-grid>

    <div class="ion-padding">
      <ion-label>{{ "copyWorkflow.entrySettings" | translate }}</ion-label>
      <div class="padding-top-5">
        <ion-label class="danger" *ngIf="!sourceProject?.isOfflineAvailable && allAttachmentsWithOffline?.length && !isConnected">
          {{'copyWorkflow.textNoInternet' | translate}}
        </ion-label>
        <ion-label class="danger" *ngIf="!this.sourceProject?.isOfflineAvailable && allAttachmentsWithOffline?.length && copyForm.get('copyAttachments').value === true">
          {{'copyWorkflow.textOnlineProject' | translate}}
        </ion-label>
        <ion-label class="danger" *ngIf="sourceProject?.isOfflineAvailable && allAttachmentsWithOffline?.length && !allAttachmentsOfflineAvailable && !isConnected">
          {{'copyWorkflow.textNotAllAttachmentsOfflineAvailable' | translate}}
        </ion-label>
        <ion-label class="danger" *ngIf="sourceProject?.isOfflineAvailable && allAttachmentsWithOffline?.length && !allAttachmentsOfflineAvailable && isConnected && copyForm.get('copyAttachments').value === true">
          {{'copyWorkflow.textDownloadAttachmentForOfflineProject' | translate}}
        </ion-label>
      </div>
    </div>

    <ion-grid>
      <ion-row>
        <ion-col size="6">
          <ion-item class="omg omg-form-checkbox">
           <ion-checkbox label-placement="end" justify="start" formControlName="copyAttachments">
             {{ 'copyWorkflow.attachments' | translate }}
            </ion-checkbox>
          </ion-item>
        </ion-col>
        <ion-col size="6">
          <ion-item class="omg omg-form-checkbox">
           <ion-checkbox label-placement="end" justify="start" formControlName="ignoreMissingAttachments">
             {{ 'copyWorkflow.ignoreMissingAttachments' | translate }}
            </ion-checkbox>
          </ion-item>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="6">
          <ion-item class="omg omg-form-checkbox">
           <ion-checkbox label-placement="end" justify="start" formControlName="copyDetails">
             {{ 'copyWorkflow.details' | translate }}
           </ion-checkbox>
          </ion-item>
        </ion-col>
        <ion-col size="6">
          <ion-item class="omg omg-form-checkbox">
           <ion-checkbox label-placement="end" justify="start" formControlName="copyContacts">
             {{ 'copyWorkflow.copyContacts' | translate }}
           </ion-checkbox>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="6">
          <ion-item class="omg omg-form-checkbox">
           <ion-checkbox label-placement="end" justify="start" formControlName="copyCreationDate">
             {{ 'copyWorkflow.entryCreationDate' | translate }}
           </ion-checkbox>
          </ion-item>
        </ion-col>
        <ion-col size="6">
          <ion-item class="omg omg-form-checkbox">
            <ion-checkbox labelPlacement="end" justify="start" formControlName="copyMarkers">
              {{ 'copyWorkflow.markers' | translate }}
            </ion-checkbox>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>


<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" (click)="cancel()">
      {{'cancel' | translate}}
    </ion-button>
    <ion-button class="omg-btn-primary" (click)="copyEntry()" [disabled]="!destinationProjectSubject.value || !copyForm.valid">
      {{'copy' | translate}}
    </ion-button>
  </ion-buttons>
</app-modal-footer>
