import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MainHeaderComponent} from './main-header.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {IonicModule} from '@ionic/angular';
import {SyncStatusComponent} from 'src/app/components/sync/sync-status/sync-status.component';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {LicenseModule} from 'src/app/shared/module/license/license.module';
import {MenuButtonModule} from 'src/app/shared/module/menu-button/menu-button.module';
import {UiModule} from '../../shared/module/ui/ui.module';
import {MainHeaderSettingsPopoverComponent} from './main-header-settings-popover/main-header-settings-popover.component';
import {PipesModule} from 'src/app/pipes/pipes.module';
import { BuyingWorkflowComponent } from 'src/app/components/common/buying-workflow/buying-workflow.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectableInputModule } from 'src/app/shared/module/selectable-input/selectable-input.module';
import { BuyingWorkflowCanduComponent } from 'src/app/components/common/buying-workflow/buying-workflow-candu/buying-workflow-candu.component';
import {StorageQuotaNotificationComponent} from '../storage-quota-notification/storage-quota-notification.component';
import {TooltipModule} from '../../shared/module/tooltip/tooltip.module';
import {StorageQuotaNotificationHeaderComponent} from '../storage-quota-notification-header/storage-quota-notification-header.component';

@NgModule({
  declarations: [MainHeaderComponent, SyncStatusComponent, MainHeaderSettingsPopoverComponent, BuyingWorkflowComponent, BuyingWorkflowCanduComponent,
                 StorageQuotaNotificationComponent, StorageQuotaNotificationHeaderComponent],
  imports: [CommonModule, FontAwesomeModule, IonicModule, TranslateModule, RouterModule, LicenseModule, MenuButtonModule, UiModule, PipesModule, FormsModule, ReactiveFormsModule,
            SelectableInputModule, TooltipModule],
  exports: [MainHeaderComponent],
})
export class MainHeaderModule {}
