import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Platform, PopoverController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import _ from 'lodash';
import {Subscription, combineLatest} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {ProtocolEntryEditComponent} from 'src/app/components/protocol/protocol-entry-edit/protocol-entry-edit.component';
import {ProjectDataService} from 'src/app/services/data/project-data.service';
import {FeatureEnabledService} from 'src/app/services/feature/feature-enabled.service';
import {getProtocolEntryPagePath, getProtocolEntryPagePathForSearch} from 'src/app/utils/router-utils';
import {
  IdType,
  LicenseType,
  ProtocolEntry
} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../../services/common/logging.service';
import {ProtocolEntrySelectionService} from 'src/app/services/protocol/protocol-entry-selection.service';
import {Breakpoints, DeviceService} from 'src/app/services/ui/device.service';

const LOG_SOURCE = 'ProtocolEntryEditPage';

@Component({
  selector: 'app-protocol-entry-edit-page',
  templateUrl: './protocol-entry-edit.page.html',
  styleUrls: ['./protocol-entry-edit.page.scss'],
})
export class ProtocolEntryEditPage implements OnInit, OnDestroy {
  private static nextInstanceNumber = 1;
  private static numberOfInstances = 0;

  public protocolId: IdType;
  private routerEventSubscription: Subscription|undefined;
  private readonly instanceNumber: number;

  public readonly isEditEnabled$ = this.featureEnabledService.isFeatureEnabled$(false, true, [LicenseType.VIEWER]);
  public readonly isNavigationButtonsVisible$ = combineLatest([
    this.protocolEntrySelectionService.isSelectMode$,
    this.deviceService.isAboveBreakpoint(Breakpoints.lg)
  ]).pipe(map(([isSelectMode, isAboveLg]) => !isAboveLg || !isSelectMode));
  private addBeforeProjectChangeEventHandlerFn: () => void;

  get getProtocolEntryPath() {
    return this.router.url.includes('protocols-search') ? getProtocolEntryPagePathForSearch : getProtocolEntryPagePath;
  }

  @ViewChild(ProtocolEntryEditComponent, {
    static: true
  }) protocolEntryEditComponent: ProtocolEntryEditComponent;

  protocolId$ = this.activatedRoute.params.pipe(tap((params) => this.protocolId = params.protocolId), map((params) => params.protocolId));
  protocolEntryId$ = this.activatedRoute.params.pipe(map((params) => params.protocolEntryId));

  constructor(private activatedRoute: ActivatedRoute,
              public translateService: TranslateService,
              private router: Router,
              public platform: Platform,
              public popoverCtr: PopoverController,
              private loggingService: LoggingService,
              private featureEnabledService: FeatureEnabledService,
              private projectDataService: ProjectDataService,
              private protocolEntrySelectionService: ProtocolEntrySelectionService,
              private deviceService: DeviceService
              ) {
    this.instanceNumber = ProtocolEntryEditPage.nextInstanceNumber++;
    ProtocolEntryEditPage.numberOfInstances++;
    this.loggingService.debug(LOG_SOURCE, `constructor - instanceNumber=${this.instanceNumber}, numberOfInstances=${ProtocolEntryEditPage.numberOfInstances}`);
  }

  ngOnInit() {
    this.loggingService.debug(LOG_SOURCE, 'ngOnInit called.');
    this.addBeforeProjectChangeEventHandlerFn = () => {
      if (this.protocolEntryEditComponent) {
        this.loggingService.debug(LOG_SOURCE, `beforeProjectChangeEvent called. protocolEntryEditComponent.protocolEntryId=${this.protocolEntryEditComponent.protocolEntryId}, 
        protocolEntryFormDirty=${this.protocolEntryEditComponent.protocolEntryFormDirty.dirty}`);
        this.protocolEntryEditComponent.forceSaveIfDirty();
      }
    };
    this.projectDataService.addBeforeProjectChangeEventHandler(this.addBeforeProjectChangeEventHandlerFn);
  }

  ngOnDestroy() {
    this.loggingService.debug(LOG_SOURCE, 'ngOnDestroy called.');
    this.projectDataService.removeBeforeProjectChangeEventHandler(this.addBeforeProjectChangeEventHandlerFn);
    this.routerEventSubscription?.unsubscribe();
  }

  goToNextProtocolEntry(protocolEntry: ProtocolEntry|null) {
    if (!_.isEmpty(protocolEntry)) {
      this.router.navigate(this.getProtocolEntryPath(protocolEntry.protocolId, protocolEntry.id), {
        state: { protocolListShowActive: true }
      });
    } else {
      this.router.navigate(this.getProtocolEntryPath(this.protocolId, null).slice(0, 3), {
        state: { suppressAutoScroll: true }
      });
    }
  }

}
