export function formatProjectNumberOptional(projectNumber: string|number|null|undefined): string {
    const projectNumberString = convertOptionalProjectNumberToString(projectNumber);
    if (!projectNumberString) {
      return '';
    }
    return formatProjectNumber(projectNumberString);
}

export function formatProjectNumber(projectNumberString: string): string {
    if (REGEXP_IS_NUMBER.test(projectNumberString)) {
        return projectNumberString.padStart(5, '0');
    } else {
        return projectNumberString;
    }
}

export function convertOptionalProjectNumberToString(projectNumber: string|number|null|undefined): string|null|undefined {
    if (projectNumber === null) {
      return null;
    }
    if (projectNumber === undefined) {
      return undefined;
    }
    return convertProjectNumberToString(projectNumber);
}

export function convertProjectNumberToString(projectNumber: string|number): string {
    if (typeof projectNumber === 'number') {
      return (projectNumber as number).toString();
    } else if (typeof projectNumber === 'string') {
      return projectNumber;
    }
    throw new Error(`Unable to convert projectNumber "${projectNumber}" to a string as the type is not supported.`);
}

export function trimLeadingZeros(value: string): string {
  if (REGEXP_IS_NUMBER.test(value)) {
    const valueAsNumber = parseInt(value, 10);
    value = valueAsNumber.toString();
    return value;
  } else {
    return value;
  }
}

export function isValidDate(date: Date): boolean {
  return date.toString() === 'Invalid Date' ? false : true;
}

export function convertISOStringToDate(dateOrString: Date|string|undefined|null): Date|undefined|null {
  if (dateOrString === undefined) {
    return undefined;
  }
  if (dateOrString === null) {
    return null;
  }
  if (dateOrString instanceof Date) {
    return dateOrString as Date;
  }
  if (typeof dateOrString !== 'string') {
    throw new Error(`Value "${dateOrString}" is not of type Date nor String.`);
  }
  const dateAsString = dateOrString as string;
  if (dateAsString === '') {
    return null;
  }
  const dateAsDate = new Date(dateAsString);
  if (!isValidDate(dateAsDate)) {
    throw new Error(`Value "${dateAsString}" cannot be converted to a valid Date.`);
  }
  return dateAsDate;
}

/**
 * Taken from https://github.com/parshap/node-sanitize-filename/tree/209c39b914c8eb48ee27bcbde64b2c7822fdf3de
 *
 * Original source had also a truncate feature, which is omitted here.
 *
 * Additionally, this version also replaces **all** dots, as it's meant to be used without file extension.
 *
 * Original license:
 *
 * Permission to use, copy, modify, and/or distribute this software for any purpose with or without fee is hereby granted,
 * provided that the above copyright notice and this permission notice appear in all copies.
 * THE SOFTWARE IS PROVIDED "AS IS" AND THE AUTHOR DISCLAIMS ALL WARRANTIES WITH REGARD TO THIS SOFTWARE
 * INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS. IN NO EVENT SHALL THE AUTHOR BE LIABLE FOR ANY SPECIAL,
 * DIRECT, INDIRECT, OR CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS,
 * WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SOFTWARE.
 *
 * Replaces characters in strings that are illegal/unsafe for filenames.
 * Unsafe characters are either removed or replaced by a substitute set
 * in the optional `options` object.
 *
 * Illegal Characters on Various Operating Systems
 * / ? < > \ : * | "
 * https://kb.acronis.com/content/39790
 *
 * Unicode Control codes
 * C0 0x00-0x1f & C1 (0x80-0x9f)
 * http://en.wikipedia.org/wiki/C0_and_C1_control_codes
 *
 * Reserved filenames on Unix-based systems (".", "..")
 * Reserved filenames in Windows ("CON", "PRN", "AUX", "NUL", "COM1",
 * "COM2", "COM3", "COM4", "COM5", "COM6", "COM7", "COM8", "COM9",
 * "LPT1", "LPT2", "LPT3", "LPT4", "LPT5", "LPT6", "LPT7", "LPT8", and
 * "LPT9") case-insesitively and with or without filename extensions.
 */

const illegalRe = /[\/\?<>\\:\*\|"]/g;
const controlRe = /[\x00-\x1f\x80-\x9f]/g;
const reservedRe = /^\.+$/;
const windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i;
const windowsTrailingRe = /[\. ]+$/;
const dotRe = /\./g;

function _sanitizeForFilename(input: string, replacement: string) {
  const sanitized = input
    .replace(illegalRe, replacement)
    .replace(controlRe, replacement)
    .replace(reservedRe, replacement)
    .replace(windowsReservedRe, replacement)
    .replace(windowsTrailingRe, replacement)
    .replace(dotRe, replacement);
  return sanitized;
}

export function sanitizeForFilename(input: string, {replacement = '', includesFileExt = false}: {replacement?: string; includesFileExt?: boolean;} = {}) {
  let filenameWithoutExtension = input;
  let fileExtWithDot = '';
  const indexOfFileExt = input.lastIndexOf('.');
  if (includesFileExt && indexOfFileExt >= 0) {
    filenameWithoutExtension = input.substring(0, indexOfFileExt);
    fileExtWithDot = input.substring(indexOfFileExt);
  }
  const output = _sanitizeForFilename(filenameWithoutExtension, replacement);
  if (replacement === '') {
    return output + fileExtWithDot;
  }
  return _sanitizeForFilename(output, '') + fileExtWithDot; // Sanitize again, in case replacement is an illegal character, or forms illegal word
}


export const REGEXP_IS_NUMBER = /^\d+$/;
