import {Column, Content, Table, TableCell, TableLayout} from 'pdfmake/interfaces';
import {SvgIcons} from '../pdfSvgIcons';
import {AbstractPdfContent, formatDate} from './abstractPdf.content';
import _ from 'lodash';
import {Address, Client, Company, Craft, IdType, IndividualNextMeetingRequest, Participant, PdfPreview, Profile} from '../../../models';
import {PdfColor, PdfProtocolGenerateData} from '../pdfProtocol.model';
import {convertToRichText, formatOneLineAddress, isRichText, PdfHelperFunctions} from '../../common-report-utils';
import {PdfProtocolSendReq} from '../../../requestResponse';
import {protocolIndexNumberToString} from '../pdfutils';
import {formatProjectNumberOptional} from '../../../commonUtils';
import {UNIT_OWNER_TENANT_COMPANY_ID, HIDE_PROTOCOL_DATE_ON_PDF_CLIENT_IDS} from '../../../constants';
import {isUnitFeatureEnabledForClient} from '../../../unitUtils';

export interface ParticipantsProfile extends Participant {
  profile: Profile|undefined;
  client: Client|undefined;
  address: Address|undefined;
  craft: Craft|undefined;
}

export interface CompanyWithParticipants {
  companyName: string;
  company: Company;
  participants: ParticipantsProfile[];
  companyOrder?: number | null;
}

const lowerCaseCompanyNameIdentity = (value: {companyName: string}) => value.companyName.toLocaleLowerCase();

export abstract class AbstractProjectContent extends AbstractPdfContent {

  constructor(lng: string, config: PdfProtocolSendReq, data: PdfProtocolGenerateData, protected pdfHelperFunctions: PdfHelperFunctions, pdfPreview?: PdfPreview) {
    super(lng, config, data, pdfPreview);
  }

  protected tableLayout: TableLayout = {
    hLineWidth(i) { return i === 0 ? 0 : 0.5; },
    hLineColor() { return '#B3B3B3'; },
    vLineWidth() { return 0; },
    paddingLeft() { return 0; },
    paddingRight() { return 0; },
    paddingTop() { return 2; },
    paddingBottom() { return 2; }
  };

  protected writeProjectPhoto(content: Content[]) {
    const bannerContentBase64 = this.data.pdfProjectBanners?.get('pdf_start_banner')?.contentBase64 ?? this.data.attachmentClients?.get('pdfStartBanner')?.contentBase64;
    if (!_.isEmpty(bannerContentBase64) && !this.config.pdfProtocolSetting?.showHeaderBannerOnEveryPage) {
      content.push({ image: 'pdfStartBanner', fit: [515, 105] });
    }
  }

  protected writeCompanyInfo(content: Content[]) {
    const client = this.getValueNotNull(this.data.client, 'client');
    if (!this.data.client.addressId || !this.config.pdfProtocolSetting?.clientShowAddress) {
      return; // can it be ignored?
    }
    const addressId = this.getValueNotNull(this.data.client.addressId, 'client.addressId');
    const address = this.getValueNotNull(this.data.lookup.addresses.get(addressId), 'client address');

    let companyAddress = client.name + '';
    if (address.street1 && address.street1 !== '') {
      companyAddress += ' - ' + address.street1;
    }
    if (address.street2 && address.street2 !== '') {
      companyAddress += ' - ' + address.street2;
    }
    if (address.zipCode && address.zipCode !== '' && address.city && address.city !== '') {
      companyAddress += ' ' + address.zipCode + ' ' + address.city;
    }
    content.push({
      text: companyAddress,
      style: ['companyInfo', 'font8']
    });
  }

  protected writeProjectSection(content: Content[], indexNumber: number|undefined) {
    const protocolType = this.data.lookup?.protocolTypes.get(this.data.protocol.typeId)?.code;
    const protocolShortId = `${protocolType}${this.data.protocol.number.toString().padStart(2, '0')}`;
    const indexString = protocolIndexNumberToString(indexNumber);
    let projectAddress = '';
    if (this.data.project.addressSite && !this.data.project.addressId) {
      projectAddress = this.data.project.addressSite;
    } else {
      projectAddress = formatOneLineAddress(this.data.projectAddress);
    }
    content.push({
      columns: [
        [
          { text: `${formatProjectNumberOptional(this.data.project.number)} - ${this.data.project.name}`, style: ['projectInfo', 'fontColorGray'] },
          { text: `${this.sanitizeValue(projectAddress)}`, style: 'font9', },
          { text: `${this.data.lookup?.protocolTypes.get(this.data.protocol.typeId)?.name} | Nr. ${protocolShortId} ${indexString !== undefined ? '| ' + indexString : ''}`, style: ['textBold', 'font18', 'marginTop10', 'fontColorGray'] }
        ],
        {
          columns: [
            [
              { text: `${this.i18n?.get('client')}`, style: ['projectInfo', 'font7'] },
              { text: `${this.sanitizeValue(this.data.project.contractee)}`, style: ['font8'] },
              { text: `${this.sanitizeValue(this.data.project.addressContractee)}`, style: ['font8'] }
            ]
          ],
          width: 150,
          style: ['marginTop20']
        }
      ],
    });

    content.push({
      columns: [this.writeProjectDetails()]
    });
  }

  protected writeProjectDetails(): Column[] {
    const columns: Column[] = [];
    const projectColumms: Column[] = [];
    const isProjectImageExisting = !_.isEmpty(this.data.attachmentProjectImage);


    if (isProjectImageExisting) {
      projectColumms.push({
        columns: [
          { image: 'projectPhoto', fit: [140, 140] }
        ],
        width: 'auto'
      });
    }

    projectColumms.push({
      columns: [[this.getProjectDetailInformation()]],
      width: '*',
      style: [!isProjectImageExisting ? '' : 'marginLeft10']
    });

    columns.push({
      columns: [
        {
          style: 'marginTop10',
          columns: projectColumms
        }
      ]
    });

    if (!_.isEmpty(this.config.pdfProtocolSetting?.additionalText)) {
      columns.push({
        style: 'marginTop20',
        columns: [
            {
              columns: [
                {
                  svg: this.changeSvgIconFill(SvgIcons.paper, this.getProtocolColor()),
                  fit: [12, 12],
                }
              ],
              width: 'auto'
            },
            {
              width: '*',
              text: `${this.config.pdfProtocolSetting?.additionalText}`,
              style: ['font9', 'marginLeft10']
            }
        ]
      });
    }
    return columns;
  }

  protected getProjectDetailInformation(): Column[] {
    const columns: Column[] = [];
    const isProjectImageExisting = !_.isEmpty(this.data.attachmentProjectImage);
    const dateTimeColumn: Column[] = [];

    
    if (!HIDE_PROTOCOL_DATE_ON_PDF_CLIENT_IDS.includes(this.data.client.id)) {
      dateTimeColumn.push({
        columns: [
          [
            { text: `${this.i18n?.get('date')}`, style: ['font7', 'marginTop5'] },
            {
              text: `${this.getDateValueNotNull(this.data.protocol.date)}`,
              style: ['font10']
            }
          ]
        ]
      });
    }

    if (!this.isEmptyDate(this.data.protocol.timeFrom) || !this.isEmptyDate(this.data.protocol.timeUntil)) {
      let protocolTime = `${this.getTime(this.data.protocol.timeFrom)} - ${this.getTime(this.data.protocol.timeUntil)}`;
      if (this.isEmptyDate(this.data.protocol.timeUntil) && !this.isEmptyDate(this.data.protocol.timeFrom)) {
        protocolTime = `${this.i18n?.get('start')} ${this.getTime(this.data.protocol.timeFrom)}`;
      } else if (this.isEmptyDate(this.data.protocol.timeFrom) && !this.isEmptyDate(this.data.protocol.timeUntil)) {
        protocolTime = `${this.i18n?.get('finished')} ${this.getTime(this.data.protocol.timeUntil)}`;
      }

      dateTimeColumn.push({
        columns:  [
          [
            { text: `${this.i18n?.get('time')}`, style: ['font7', 'marginTop5'] },
            { text: `${protocolTime}`, style: ['font10']}
          ]
        ]
      });
    }

    columns.push({
      columns: [
        {
          columns: [
            [
              { text: `${this.i18n?.get('titel')}`, style: ['font7', !isProjectImageExisting ? '' : 'marginLeft10'] },
              { text: `${this.sanitizeValue(this.data.protocol.name)}`, style: ['font12', !isProjectImageExisting ? '' : 'marginLeft10'] },
            ]
          ],
          width: '*'
        },
        {
          columns: dateTimeColumn,
          width: 150,
        }
      ]
    });

    columns.push({
      columns: [
        {
          columns: [
            // [ /** need for future */
            //   { text: `${this.i18n?.get('author')}`, style: ['font7', !isProjectImageExisting ? '' : 'marginLeft10Top5'] },
            //   { text: 'Author', style: ['font12', !isProjectImageExisting ? '' : 'marginLeft10'] },
            // ]
          ],
          width: '*'
        },
        {
          columns: [
            {
              columns:  [
                [
                  { text: `${this.i18n?.get('sent_date')}`, style: ['font7', 'marginTop5'] },
                  { text: `${this.getDateValueNotNull(new Date().toISOString())}`, style: ['font10'] }
                ]
              ]
            },
            {
              columns: [
                [
                  { text: `${this.i18n?.get('protocol_status')}`, style: ['font7', 'marginTop5'] },
                  {
                    text: this.isEmptyDate(this.data.protocol.closedAt) ? `${this.i18n?.get('open')}` : `${this.i18n?.get('closed')}`,
                    style: ['font10', this.isEmptyDate(this.data.protocol.closedAt) ? 'fontColorRed' : 'colorGreen']
                  }
                ]
              ]
            }
          ],
          width: 150,
        }
      ]
    });

    columns.push({
      columns: [
        {
          columns: [
            [
              { text: `${this.i18n?.get('place')}`, style: ['font7', !isProjectImageExisting ? '' : 'marginLeft10Top5'] },
              { text: `${this.sanitizeValue(this.data.protocol.location)}`, style: ['font12', !isProjectImageExisting ? '' : 'marginLeft10']}
            ]
          ],
          width: '*'
        }
      ]
    });

    if (!_.isEmpty(this.config.sendPdfProtocolAdditionalInfo.customText)) {
      columns.push({
        columns: [
          {
            columns: [
              [
                { text: '', style: ['font8', !isProjectImageExisting ? '' : 'marginLeft10Top5'] },
                { text: '', style: ['font12', !isProjectImageExisting ? '' : 'marginLeft10']}
              ]
            ],
            width: '*'
          },
          {
            columns: [
              [
                {
                  style: 'marginTop10',
                  columns: [
                    {
                      columns: [
                        {
                          svg: this.changeSvgIconFill(SvgIcons.info, PdfColor.RED),
                          fit: [12, 12]
                        }
                      ],
                      width: 'auto'
                    },
                    {
                      text: `${this.config.sendPdfProtocolAdditionalInfo.customText}`,
                      style: ['font10', 'fontColorRed', 'marginLeft10']
                    }
                  ]
                }
              ]
            ],
            width: 150,
          }
        ]
      });
    }

    return columns;
  }

  private getProfileDetails(profileId: IdType): {
    companyName: string;
    name: string;
    email: string;
    phone: string;
  } {
    const profile = this.data.lookup.profiles.get(profileId);
    const company = this.data.lookup.companies.get(profile?.companyId + '');
    const companyName = company?.name ?? '';

    const address = this.data.lookup.addresses.get(profile?.addressId + '');
    const name = `${address?.firstName} ${address?.lastName}`;
    const email = this.getParticipantEmail({ profile, address });
    const phone = this.getParticipantPhone({ profile, address });

    return {
      companyName,
      name,
      email,
      phone
    };
  }

  private getCompactIndividualNextMeetingCells(content: Content[], meeting: IndividualNextMeetingRequest): TableCell[] {
    const details = this.getProfileDetails(meeting.profileId);

    return [
      { text: `${this.sanitizeValue(details.companyName)}`, style: ['font8', 'participant'] },
      { text: `${this.sanitizeValue(details.name)}`, style: ['font8', 'participant'] },
      { text: `${this.sanitizeValue(details.email)}`, link: `mailto: ${this.sanitizeValue(details.email)}`, style: ['font8', 'fontColorBlue', 'participant'] },
      { text: `${this.sanitizeValue(details.phone)}`, style: ['font8', 'fontColorBlue', 'participant'] },
      {
        style: ['font8', 'participant'],
        text: this.sanitizeValue(`${meeting.timeStart} - ${meeting.timeEnd}`)
      }
    ];
  }

  private writeCompactIndividualNextMeetingsHeader(content: Content[]) {
    content.push(
      {
        columns: [
          { text: `${this.i18n?.get('company')}`, width: 100, style: ['font9', 'textBold', 'margin5'] },
          { text: `${this.i18n?.get('name_without_degree')}`, width: 144, style: ['font9', 'textBold', 'margin5'] },
          { text: `${this.i18n?.get('email')}`, width: 128, style: ['font9', 'textBold', 'margin5'] },
          { text: `${this.i18n?.get('phone')}`, width: 86, style: ['font9', 'textBold', 'margin5'] },
          { text: `${this.i18n?.get('time_range')}`, width: 64, style: ['font9', 'textBold', 'margin5'] },
        ]
      }
    );
  }

  private writeCompactIndividualNextMeetings(content: Content[]) {
    this.writeCompactIndividualNextMeetingsHeader(content);

    const cells: TableCell[][] = [];

    for (const meeting of _.sortBy(this.config.individualNextMeetings, ['timeStart', 'timeEnd'])) {
      cells.push(this.getCompactIndividualNextMeetingCells(content, meeting));
    }
    const table = {
      headerRows: 0,
      dontBreakRows: true,
      widths: [100, 144, 128, 86, 64],
      body: cells,
    };

    content.push({table, layout: this.tableLayout});
  }

  protected writeIndividualNextMeetings(content: Content[]) {
    if (_.isEmpty(this.config.individualNextMeetings)) {
      return;
    }

    this.writeNextMeetingHeading(content);

    this.writeCompactIndividualNextMeetings(content);
  }

  protected writeNextMeeting(content: Content[]) {

    if (_.isEmpty(this.config.nextMeeting?.date)) {
      return;
    }

    const additionalText = !_.isEmpty(this.config.individualNextMeetings) ? this.i18n?.get('individual_next_meetings_details_last_page') : undefined;

    this.writeNextMeetingHeading(content, additionalText);
  }

  protected getWeather(weatherKey: number|undefined|null): string {
    const weatherOption = this.i18n?.get('weatherOption_' + weatherKey);
    return _.isEmpty(weatherOption) ? '' : weatherOption + '';
}

  protected writeWeather(content: Content[]): void {
    let weather = '';
    let temp = '';
    let humidity = '';
    let windspeed = '';
    if (this.getWeather(this.data.protocol.weather)) {
        weather += this.getWeather(this.data.protocol.weather);
    }

    if ((this.data.protocol.minTemp !== null && this.data.protocol.minTemp !== undefined) || (this.data.protocol.maxTemp !== null && this.data.protocol.maxTemp !== undefined)) {
        if ((this.data.protocol.minTemp !== null && this.data.protocol.minTemp !== undefined) && (this.data.protocol.maxTemp !== null && this.data.protocol.maxTemp !== undefined)) {
            temp += this.data.protocol.minTemp;
            temp += '° ' + this.i18n?.get('to') + ' ';
            temp += this.data.protocol.maxTemp;
        }
        else {
            temp += this.data.protocol.minTemp ? this.data.protocol.minTemp : this.data.protocol.maxTemp;
        }
        temp += '°';
    }
    if (this.data.protocol.humidity !== null && this.data.protocol.humidity !== undefined) {
        humidity += this.data.protocol.humidity;
        humidity += ' %';
    }
    if (this.data.protocol.windspeed !== null && this.data.protocol.windspeed !== undefined){
        windspeed += this.data.protocol.windspeed;
        windspeed += ' km/h';
    }

    const tblWeather: Table = {
        headerRows: 0,
        widths: [130, 130, 130, 130],
        body: [
            [
              {
                border: [false, false, false, false],
                margin: [0, 0, 0, 0],
                columns: [
                  {
                    width: 130,
                    text: [
                        {
                            text: `${this.i18n?.get('weather')}`,
                            style: ['font7'],
                        },
                        {text: '\n', style: ['font7'], },
                        {
                            text: weather,
                            style: ['font8', 'textBold'],
                        },
                    ]
                  },
                  {
                    width: 130,
                    text: [
                        {
                            text: `${this.i18n?.get('temperature')}`,
                            style: ['font7'],
                        },
                        {text: '\n', style: ['font7'], },
                        {
                            text: temp,
                            style: ['font8', 'textBold'],
                        },
                    ]
                  },
                  {
                    width: 130, 
                    text: [
                        {
                            text: `${this.i18n?.get('humidity')}`,
                            style: ['font7'],
                        },
                        {text: '\n', style: ['font7'], },
                        {
                            text: humidity,
                            style: ['font8', 'textBold'],
                        },
                    ]
                  },
                  {
                    width: 130,
                    text: [
                        {
                            text: `${this.i18n?.get('windspeed')}`,
                            style: ['font7'],
                        },
                        {text: '\n', style: ['font7'], },
                        {
                            text: windspeed,
                            style: ['font8', 'textBold'],
                        },
                    ]
                  }
                ]
              }
            ]
        ]
    };

    content.push({
        layout: this.tableLayout,
        table: tblWeather,
        margin: [0, 10, 0, 0],
    });
  }

  protected writeWeatherHeading(content: Content[]) {
    const columns: Column[] = [];
    const protocolDate = formatDate(this.data.protocol.date);
    columns.push({
      columns: [
        {
          svg: this.changeSvgIconFill(SvgIcons.weather, this.getProtocolColor()),
          fit: [12, 12]
        }
      ],
      width: 12
    });

    columns.push({
      text: `${this.i18n?.get('weather')}`,
      style: ['font10', 'protocolFontColor', 'marginLeft10'],
      width: 50
    });

    columns.push({
      text: `${this.i18n?.get('forDate')}` + ' ' + protocolDate,
      style: ['font10'],
      width: 120
    });

    content.push({
      style: ['marginTop20'],
      columns
    });

    this.writeLine(content);
  }


  private writeNextMeetingHeading(content: Content[], additionalText?: string) {
    const columns: Column[] = [];
    columns.push({
      columns: [
        {
          svg: this.changeSvgIconFill(SvgIcons.calendar, this.config.pdfProtocolSetting?.protocolColor + ''),
          fit: [12, 12]
        }
      ],
      width: 'auto'
    });

    columns.push({
      text: `${this.i18n?.get('next_meeting')}`,
      style: ['font10', 'protocolFontColor', 'marginLeft10'],
      width: 120
    });

    if (additionalText) {
      columns.push({
        text: `${additionalText}`,
        style: ['font8'],
        margin: [10, 2, 10, 0],
        width: 'auto'
      });
    }

    let nextMeetingDate = this.getDateValueNotNull(this.config.nextMeeting?.date) + ' ';
    nextMeetingDate += '| ' + this.i18n?.get('from') + ' ';
    nextMeetingDate += this.sanitizeValue(this.config.nextMeeting?.timeStart) + ' ';
    nextMeetingDate += this.i18n?.get('to') + ' ';
    nextMeetingDate += this.sanitizeValue(this.config.nextMeeting?.timeEnd) + ' ';

    columns.push({
      text: `${nextMeetingDate}`,
      style: ['font10'],
      alignment: 'right',
      width: '*'
    });

    content.push({
      style: ['marginTop20'],
      columns
    });

    this.writeLine(content);
  }

  protected writeNoProtocolEntries(content: Content[]) {
    const profile = this.data.lookup.profiles.get(this.config.participant?.profileId + '');
    const company = this.data.lookup.companies.get(profile?.companyId + '');

    content.push({
      columns: [
        {
          text: `${this.i18n?.get('no_entries')?.replace('{{ company_name }}', this.sanitizeValue(company?.name))}`,
          style: ['participantCompany', 'textBold', 'font12', 'alignCenter', 'marginTop20']
        },
      ],
      style: ['marginTop20']
    });
  }

  protected writeCompactParticipantList(content: Content[], showParticipantCol: boolean|null|undefined, companyWithParticipants?: CompanyWithParticipants[]) {
    if (!companyWithParticipants) {
      companyWithParticipants = this.getCompanyWithSelectedParticipants();
    }
    const containsUnitCompany = companyWithParticipants.some((c) => c.company.id === UNIT_OWNER_TENANT_COMPANY_ID);
    content.push(
      {
        columns: [
          { text: `${this.i18n?.get(containsUnitCompany ? 'unit' : 'company')}`, width: 100, style: ['font9', 'textBold', 'margin5'] },
          { text: `${this.i18n?.get('name_without_degree')}`, width: 144, style: ['font9', 'textBold', 'margin5'] },
          { text: `${this.i18n?.get('email')}`, width: 128, style: ['font9', 'textBold', 'margin5'] },
          { text: `${this.i18n?.get('phone')}`, width: 86, style: ['font9', 'textBold', 'margin5'] },
          { text: `${this.i18n?.get('rec')}`, width: 32, style: ['font9', 'textBold', 'margin5'] },
          { text: showParticipantCol !== false ? `${this.i18n?.get('part')}` : '', width: 32, style: ['font9', 'textBold', 'margin5'] },
        ]
      }
    );
    for (const companyParticipants of companyWithParticipants) {
      for (const participantProfile of this.sortParticipantsProfiles(companyParticipants.participants)) {
        const participantColumn: Column[] = [];
        if (showParticipantCol !== false) {
          participantColumn.push([{
            width: 32,
            style: ['participant'],
            columns: [
              {
                svg: participantProfile.present ? SvgIcons.blackCheckboxCheck : SvgIcons.blackCheckboxUncheck, width: 12
              }
            ]
          }]);
        }
        const unitForBreadcrumbName = containsUnitCompany ? this.data.unitForBreadcrumbs?.find((v) => v.id === this.data.protocol?.unitId)?.name ?? '' : undefined;
        content.push({
          columns: [
            { text: `${this.sanitizeValue(unitForBreadcrumbName ?? companyParticipants.companyName)}`, width: 100, style: ['font8', 'participant'] },
            { text: `${this.sanitizeValue(participantProfile.address?.firstName)} ${this.sanitizeValue(participantProfile.address?.lastName)} `, width: 144, style: ['font8', 'participant'] },
            { text: `${this.getParticipantEmail(participantProfile)}`, link: `mailto: ${this.sanitizeValue(participantProfile.address?.email)}`, width: 128, style: ['font8', 'fontColorBlue', 'participant'] },
            { text: `${this.getParticipantPhone(participantProfile)}`, width: 86, style: ['font8', 'fontColorBlue', 'participant'] },
            {
              width: 32,
              style: ['participant'],
              columns: [
                {
                  svg: participantProfile.mailingList ? SvgIcons.blackCheckboxCheck : SvgIcons.blackCheckboxUncheck, width: 12
                }
              ]
            },
            participantColumn
          ]
        });
        this.writeLine(content, undefined, 2);
      }
    }
  }

  protected writeParticipantList(content: Content[], showParticipantCol: boolean|null|undefined, companyWithParticipants?: CompanyWithParticipants[]) {
    if (!companyWithParticipants) {
      companyWithParticipants = this.getCompanyWithSelectedParticipants();
    }
    const containsUnitCompany = companyWithParticipants.some((c) => c.company.id === UNIT_OWNER_TENANT_COMPANY_ID);

    content.push(
      {
        columns: [
          { text: `${this.i18n?.get('name_without_degree')}`, width: 146, style: ['font9', 'textBold', 'margin5'] },
          { text: `${containsUnitCompany ? this.i18n?.get('unit') : this.i18n?.get('craft')}`, width: 100, style: ['font9', 'textBold', 'margin5'] },
          { text: `${this.i18n?.get('phone')}`, width: 86, style: ['font9', 'textBold', 'margin5'] },
          { text: `${this.i18n?.get('email')}`, width: 128, style: ['font9', 'textBold', 'margin5'] },
          { text: `${this.i18n?.get('rec')}`, width: 32, style: ['font9', 'textBold', 'margin5'] },
          { text: showParticipantCol !== false ? `${this.i18n?.get('part')}` : '', width: 32, style: ['font9', 'textBold', 'margin5']  },
        ]
      }
    );
    for (const companyParticipants of companyWithParticipants) {
      if (companyParticipants.company.id !== UNIT_OWNER_TENANT_COMPANY_ID) {
        // No company header for unit contacts
        content.push({
          text: companyParticipants.companyName,
          style: ['participantCompany', 'textBold', 'font10']
        });
      }
      for (const participantProfile of this.sortParticipantsProfiles(companyParticipants.participants))  {
        const participantColumn: Column[] = [];
        if (showParticipantCol !== false) {
          participantColumn.push([{
            width: 32,
            style: ['participant'],
            columns: [
              {
                svg: participantProfile.present ? SvgIcons.blackCheckboxCheck : SvgIcons.blackCheckboxUncheck, width: 12
              }
            ]
          }]);
        }
        const unitForBreadcrumb = containsUnitCompany && this.data.unitForBreadcrumbs ? this.data.unitForBreadcrumbs.find((v) => v.id === this.data.protocol?.unitId) : undefined;
        content.push({
          columns: [
            { text: `${this.sanitizeValue(participantProfile.address?.firstName)} ${this.sanitizeValue(participantProfile.address?.lastName)} `, width: 144, style: ['font8', 'participant'] },
            { text: `${this.sanitizeValue(unitForBreadcrumb?.name ?? participantProfile.craft?.name)}`, width: 100, style: ['font8', 'participant'] },
            { text: `${this.getParticipantPhone(participantProfile)}`, width: 86, style: ['font8', 'fontColorBlue', 'participant'] },
            { text: `${this.getParticipantEmail(participantProfile)}`, link: `mailto: ${this.sanitizeValue(participantProfile.address?.email)}`, width: 128, style: ['font8', 'fontColorBlue', 'participant'] },
            {
              width: 32,
              style: ['participant'],
              columns: [
                {
                  svg: participantProfile.mailingList ? SvgIcons.blackCheckboxCheck : SvgIcons.blackCheckboxUncheck, width: 12
                }
              ]
            },
            participantColumn
          ]
        });
        this.writeLine(content, undefined, 2);
      }
    }
  }

  protected getParticipantPhone(participantProfile: Pick<ParticipantsProfile, 'profile' | 'address'>): string {
    if (participantProfile?.profile?.dsgvoShowTel && !_.isEmpty(participantProfile?.address?.mobile)) {
      return participantProfile?.address?.mobile + '';
    } else if (participantProfile?.profile?.dsgvoShowTel && !_.isEmpty(participantProfile?.address?.phone)) {
      return participantProfile?.address?.phone + '';
    }
    return '';
  }

  protected getParticipantEmail(participantProfile: Pick<ParticipantsProfile, 'profile' | 'address'>): string {
    if (participantProfile?.profile?.dsgvoShowEmail && !_.isEmpty(participantProfile?.address?.email)) {
      return participantProfile?.address?.email + '';
    }
    return '';
  }

  protected writeParticipantsHeader(content: Content[], translationKey = 'participants', svgIcon = SvgIcons.participants) {
    content.push({
      style: ['marginTop10'],
      columns: [
          {
            columns: [
              {
                svg: this.changeSvgIconFill(svgIcon, this.config.pdfProtocolSetting?.protocolColor + ''),
                fit: [12, 12],
              }
            ],
            width: 'auto'
          },
          {
              text: `${this.i18n?.get(translationKey)}`,
              width: '*',
              style: ['font10', 'marginLeft10', 'protocolFontColor'],
          }
      ]
    });
    this.writeLine(content);
  }

  protected sortParticipantsProfiles(participantsProfiles: ParticipantsProfile[]): ParticipantsProfile[] {
    const sortAddressName = (participantProfile: ParticipantsProfile) => `${participantProfile.address?.firstName} ${participantProfile.address?.lastName}`;
    return _.orderBy(participantsProfiles, sortAddressName, ['asc']);
  }

  protected getCompanyWithSelectedParticipants(): CompanyWithParticipants[] {
    const companyWithParticipants = this.config.pdfProtocolSetting?.showAllContactsInProject ? this.getAllParticipants() : this.getSelectedParticipants();

    return _.orderBy(companyWithParticipants, ['companyOrder', lowerCaseCompanyNameIdentity], ['asc', 'asc']);
  }

  private getAllParticipants(): CompanyWithParticipants[] {
    const companyWithParticipants: CompanyWithParticipants[] = [];
    const isUnitFeatureEnabled = this.isUnitFeatureEnabledForCurrentClient();

    for (const [, projectCompany] of this.data.lookup.projectCompanies.entries()) {
      if (this.data.lookup.companies.has(projectCompany.companyId)) {
        const company = this.data.lookup.companies.get(projectCompany.companyId);
        if (company !== undefined) {
          if (company.isActive === false) {
            continue;
          }
          const profiles = this.getProjectProfilesByCompanyId(company.id);
          for (const currentProfile of profiles) {
            let companyForProfile = company;
            let profile = currentProfile;
            if (profile.isActive === false) {
              continue;
            }
            const participant = this.data.participants.find(record => record.profileId === profile.id);
            const participantsProfile = this.getParticipantsProfile(profile, participant);
            const isUnitContact = profile.type === 'UNIT_CONTACT';
            if (isUnitContact) {
              if (!isUnitFeatureEnabled) {
                continue;
              }
              if (!participant?.present && !participant?.mailingList) {
                continue;
              }
              companyForProfile = this.getOrCreateUnitCompanyFromLookupCompanies();
              profile = {
                ...currentProfile,
                companyId: companyForProfile.id
              };
            }
            const companyWithParticipantsIndex = _.findIndex(companyWithParticipants, (companyWithParticipant) => companyWithParticipant.company.id === companyForProfile?.id);
            if (companyWithParticipantsIndex !== -1) {
              companyWithParticipants[companyWithParticipantsIndex].participants.push(participantsProfile);
            } else if (companyWithParticipantsIndex === -1) {
              companyWithParticipants.push({
                companyName: companyForProfile.name,
                company: companyForProfile,
                companyOrder: projectCompany.sortOrder,
                participants: [participantsProfile]
              });
            }
          }
        }
      }
    }
    return companyWithParticipants;
  }

  protected getProjectProfilesByCompanyId(companyId: IdType): Profile[] {
    const profiles: Profile[] = [];
    for (const [profileId, profile] of this.data.lookup.profiles.entries()) {
      if (profile.isActive !== false && profile.companyId === companyId && this.data.lookup.projectProfiles.find(projectProfile => projectProfile.profileId === profileId)) {
        profiles.push(profile);
      }
    }
    return profiles;
  }

  private getSelectedParticipants(): CompanyWithParticipants[] {
    const isUnitFeatureEnabled = isUnitFeatureEnabledForClient(this.data.client.id);
    const companyWithParticipants: CompanyWithParticipants[] = [];
    const projectCompanyByCompanyId = _.keyBy(Array.from(this.data.lookup.projectCompanies.values()), 'companyId');
    for (const participant of this.data.participants) {
      let profile = this.data.lookup.profiles.get(participant.profileId);
      if (profile?.isActive === false) {
        continue;
      }
      const isUnitContact = profile?.type === 'UNIT_CONTACT';
      if (!isUnitFeatureEnabled && isUnitContact) {
        continue;
      }
      if (profile && (participant.mailingList || participant.present)) {
        if (isUnitFeatureEnabled && isUnitContact) {
          profile = {...profile, companyId: UNIT_OWNER_TENANT_COMPANY_ID};
          this.getOrCreateUnitCompanyFromLookupCompanies();
        }
        const company = this.data.lookup.companies.get(profile.companyId);
        if (company?.isActive === false) {
          continue;
        }
        const projectCompany = company ? projectCompanyByCompanyId[company.id] : undefined;
        const participantsProfile = this.getParticipantsProfile(profile, participant);
        const companyWithParticipantsIndex = _.findIndex(companyWithParticipants, (companyWithParticipant) => companyWithParticipant.company.id === company?.id);
        if (companyWithParticipantsIndex !== -1 && !this.isParticipantInList(participant, companyWithParticipants[companyWithParticipantsIndex].participants)) {
          companyWithParticipants[companyWithParticipantsIndex].participants.push(participantsProfile);
        } else if (company && companyWithParticipantsIndex === -1) {
          companyWithParticipants.push({
            companyName: company.name,
            company,
            companyOrder: projectCompany?.sortOrder,
            participants: [participantsProfile]
          });
        }
      }
    }
    return companyWithParticipants;
  }

  protected isParticipantInList(participant: Participant, participantList: ParticipantsProfile[]): boolean {
    return participantList.find(record => record.profileId === participant.profileId) !== undefined;
  }

  protected getParticipantsProfile(profile: Profile, participant?: Participant): ParticipantsProfile {
    const client = this.data.lookup.clients.get(profile.clientId);
    const address = this.data.lookup.addresses.get(profile?.addressId || '');
    const clientProfileCraft = this.data.lookup.profileCrafts.find(profileCraft => profileCraft.profileId === profile.id);
    const clientCraft = this.data.lookup.crafts.get(clientProfileCraft?.craftId || '');

    let participantsProfile: ParticipantsProfile;
    if (participant !== undefined) {
      participantsProfile = _.clone(participant) as ParticipantsProfile;
    } else {
      participantsProfile = {
        invited: false,
        mailingList: false,
        profileId: profile.id
      } as ParticipantsProfile;
    }

    participantsProfile.profile = profile;
    participantsProfile.client = client;
    participantsProfile.address = address;
    participantsProfile.craft = clientCraft;

    return participantsProfile;
  }

  protected writeStartText(content: Content[]) {
    if (!_.isEmpty(this.config.pdfProtocolSetting?.pdfStartText)) {
      const pdfStartText = this.config.pdfProtocolSetting?.pdfStartText;
      if (isRichText(pdfStartText)) {
        content.push({
          text: '',
          style: ['font9', 'marginTop10']
        });
        content.push(this.pdfHelperFunctions.convertHtmlToPdfmake(convertToRichText(pdfStartText), {fontSize: 9}));
      } else {
        content.push({
          text: `${this.config.pdfProtocolSetting?.pdfStartText}`,
          style: ['font9', 'marginTop10']
        });
      }
    }
  }

  protected writeStartTextHeader(content: Content[]) {
    content.push({
      style: ['marginTop10'],
      columns: [
          {
            columns: [
              {
                svg: this.changeSvgIconFill(SvgIcons.paper, this.getProtocolColor()),
                fit: [12, 12]
              }
            ],
            width: 'auto'
          },
          {
              text: `${this.i18n?.get('start_text')}`,
              width: 'auto',
              style: ['font10', 'protocolFontColor', 'marginLeft10'],
          }
      ]
    });
    this.writeLine(content);
  }
}
