import {Content} from 'pdfmake/interfaces';
import _ from 'lodash';
import moment from 'moment';
import Translation from '../../../i18n/translation';
import {Company, IdType, PdfPreview, ProtocolEntry, ProtocolEntryCompany, ProtocolSortEntriesByEnum} from '../../../models';
import {PdfColor, PdfProtocolGenerateData} from '../pdfProtocol.model';
import {SvgIcons} from '../pdfSvgIcons';
import {PdfProtocolSendReq} from '../../../requestResponse';
import {getFormattedNumber} from '../../../i18n/number-format';
import {PdfPrintEntryDetails} from '../pdfProtocolEnums';
import {isUnitFeatureEnabledForClient} from '../../../unitUtils';
import {UNIT_OWNER_TENANT_COMPANY_ID} from '../../../constants';

export interface ProtocolEntryWithSubEntries extends ProtocolEntry {
  minimize: boolean;
  subEntries: ProtocolEntry[];
}

export interface MaybeMinimizeProtocolEntry extends ProtocolEntry {
  minimize?: boolean;
}

export interface GroupProtocolEntry {
  groupName: string;
  groupSortOrder?: number;
  protocolNumber: number;
  protocolEntries: ProtocolEntryWithSubEntries[];
}

export const formatDate = (value: string | Date | undefined | null, dateFormat?: string, lng?: string): string => {
  if (_.isEmpty(value + '')) {
    return '';
  }
  const date = _.isDate(value) ? value as Date : new Date(value as string);
  moment.locale(lng);
  if (!_.isEmpty(dateFormat)) {
    return moment(date).format(dateFormat);
  }
  return moment(date).format('DD.MM.YYYY');
};

export const getWeekDay = (value: string | Date | undefined | null): number => {
  if (_.isEmpty(value + '')) {
    return -1;
  }
  const date = _.isDate(value) ? value as Date : new Date(value as string);
  return date.getDay();
};

export const canvasLine = (width?: number, padding?: number): Content => {
  let x2 =  595 - 2 * 40;
  let pad = 5;
  if (width !== undefined && width > 0) {
    x2 = width;
  }
  if (padding !== undefined && padding > 0) {
    pad = padding;
  }
  return {canvas: [{ type: 'line', x1: 0, y1: pad, x2 , y2: pad, lineWidth: 0.5, lineColor: '#B3B3B3' }]};
};

export abstract class AbstractPdfContent {

  protected i18n: Map<string, string>|undefined;
  protected config: PdfProtocolSendReq;
  protected data: PdfProtocolGenerateData;
  protected pdfPreview: PdfPreview|undefined;
  protected lng: string;

  private _groupedObserverCompanies: Record<IdType, ProtocolEntryCompany[]>|null = null;
  protected get groupedObserverCompanies(): Record<IdType, ProtocolEntryCompany[]> {
    if (!this._groupedObserverCompanies) {
      this._groupedObserverCompanies = this.getGroupedObserverCompanies();
    }

    return this._groupedObserverCompanies;
  }

  constructor(lng: string, config: PdfProtocolSendReq, data: PdfProtocolGenerateData, pdfPreview?: PdfPreview) {
    const translation = new Translation();
    this.lng = lng;
    this.i18n = translation.getTranslation(lng);
    this.data = data;
    this.config = config;
    this.pdfPreview = pdfPreview;
  }

  private companyOrderComparer(): (protocolEntryCompany: ProtocolEntryCompany | MaybeMinimizeProtocolEntry) => any {
    const projectCompaniesByCompanyId = _.keyBy(Array.from(this.data.lookup.projectCompanies.values()), 'companyId');
    return (protocolEntryCompany: ProtocolEntryCompany | MaybeMinimizeProtocolEntry) => {
      const companyId = protocolEntryCompany.companyId;
      const projectCompany = companyId ? projectCompaniesByCompanyId[companyId] : undefined;

      const allCompanies = 'allCompanies' in protocolEntryCompany && protocolEntryCompany.allCompanies;
      if (allCompanies || !projectCompany) {
        return -Infinity;
      }

      return projectCompany.sortOrder ?? Infinity;
    };
  }

  private getGroupedObserverCompanies(): Record<IdType, ProtocolEntryCompany[]> {
    const { companies } = this.data.lookup;

    const groupedObserverCompanies: Record<IdType, ProtocolEntryCompany[]> = _.groupBy(this.data.protocolEntryCompanies, 'protocolEntryId');
    const companyOrderComparer = this.companyOrderComparer();
    const companyNameComparer = (protocolEntryCompany: ProtocolEntryCompany) => companies.get(protocolEntryCompany.companyId)?.name?.toLowerCase();

    for (const [protocolEntryId, protocolEntryCompanies] of Object.entries(groupedObserverCompanies)) {
      groupedObserverCompanies[protocolEntryId] = _.orderBy(protocolEntryCompanies, [companyOrderComparer, companyNameComparer, 'companyId']);
    }

    return groupedObserverCompanies;
  }

  protected getFormattedNumber(value: number | null | undefined, style: 'currency' = 'currency'): string {
    return getFormattedNumber(this.lng, value, style);
  }

  protected writeLine(content: Content[], width?: number, padding?: number) {
    content.push(canvasLine(width, padding));
  }

  protected sanitizeValue(value: string|undefined|null): string {
    return _.isEmpty(value) ? '' : value + '';
  }

  protected getDateValueNotNull(value: string | Date | undefined | null, dateFormat?: string): string {
    return formatDate(value, dateFormat, this.lng);
  }

  protected getTime(time: string | Date | undefined | null): string|null {
    const dateRegex = /^\d+-\d+-\w+/;
    const timeRegex = /^\d+:\d+/;
    if (_.isDate(time) || _.isString(time) && dateRegex.test(time)) {
      return formatDate(time, 'HH:mm', this.lng);
    } else if (_.isString(time) && timeRegex.test(time)) {
      const filteredTime = time.match(timeRegex);
      return _.get(filteredTime, '[0]', '');
    }
    return null;
  }

  protected getValueNotNull<T>(value: T | undefined | null, description: string): T {
    if (value === undefined || value === null) {
      throw new Error(`Value "${value}" and Description "${description}" was null or undefined.`);
    }
    return value;
  }

  protected isEmptyDate(date: string | Date | null | undefined) {
    return (date === undefined || date === null || date === '');
  }

  protected isGroupByCriteriaMet(sortBy: string | null | undefined) {
    return _.includes([ProtocolSortEntriesByEnum.COMPANY, ProtocolSortEntriesByEnum.CRAFT, ProtocolSortEntriesByEnum.NAMEABLE_DROPDOWN], sortBy);
  }

  protected isImage(mimeType: string): boolean {
    return mimeType.startsWith('image/');
  }

  protected replaceSpecialCharToSpace(text: string): string {
    return text.replace(/[-_]+/gi, ' ');
  }

  protected sortProtocolEntries(protocolEntries: MaybeMinimizeProtocolEntry[]): MaybeMinimizeProtocolEntry[] {
    const protocolNumberComparer = (protocolEntry: MaybeMinimizeProtocolEntry) => this.getProtocolNumber(protocolEntry);
    const protocolCreatedAtComparer = (protocolEntry: MaybeMinimizeProtocolEntry) => this.getProtocolCreatedAt(protocolEntry);
    const protocolEntryNumberComparer = (protocolEntry: MaybeMinimizeProtocolEntry) => protocolEntry.number;
    const protocolEntryCreatedAtComparer = (protocolEntry: MaybeMinimizeProtocolEntry) =>
      (_.isDate(protocolEntry.createdAt) ? protocolEntry.createdAt as Date : new Date(protocolEntry.createdAt)).getTime();
    const customFieldComparer = (lookupData: Map<IdType, any>, sortField: string, protocolEntryField: keyof MaybeMinimizeProtocolEntry): (value: any) => any => {
      return (protocolEntry: MaybeMinimizeProtocolEntry) => lookupData.has(protocolEntry[protocolEntryField] + '') ? lookupData.get(protocolEntry[protocolEntryField] + '')[sortField] : undefined;
    };

    const companyOrderComparer = this.companyOrderComparer();

    let sortedProtocolEntries: MaybeMinimizeProtocolEntry[];
    switch (this.data.protocol?.sortEntriesBy) {
      case ProtocolSortEntriesByEnum.COMPANY:
        sortedProtocolEntries = _.orderBy(protocolEntries, [companyOrderComparer, customFieldComparer(this.data.lookup.companies, 'name', 'companyId'),
                                                           protocolNumberComparer, protocolEntryNumberComparer],
                                                           ['asc', 'asc', 'asc', 'asc']);
        break;

      case ProtocolSortEntriesByEnum.CRAFT:
        sortedProtocolEntries = _.orderBy(protocolEntries, [
                                                            customFieldComparer(this.data.lookup.crafts, 'name', 'craftId'),
                                                            protocolNumberComparer, protocolEntryNumberComparer],
                                                            ['asc', 'asc', 'asc']);
        break;

      case ProtocolSortEntriesByEnum.NAMEABLE_DROPDOWN:
        sortedProtocolEntries = _.orderBy(protocolEntries, [
                                                            customFieldComparer(this.data.lookup.nameableDropdowns, 'name', 'nameableDropdownId'),
                                                            protocolNumberComparer, protocolEntryNumberComparer],
                                                            ['asc', 'asc', 'asc']);
        break;

      case ProtocolSortEntriesByEnum.CREATED_AT_DATE:
        sortedProtocolEntries = _.orderBy(protocolEntries, [protocolCreatedAtComparer, protocolEntryCreatedAtComparer, protocolEntryNumberComparer], ['asc', 'asc', 'asc']);
        break;

      default:
        sortedProtocolEntries = _.orderBy(protocolEntries, [protocolNumberComparer, protocolEntryNumberComparer], ['asc', 'asc']);
        break;
    }
    return sortedProtocolEntries;
  }

  protected groupProtocolEntries(sortedProtocolEntries: MaybeMinimizeProtocolEntry[], isOwnEntriesAndAppendCarried = false): GroupProtocolEntry[] {
    const groupProtocolEntries: GroupProtocolEntry[] = [];
    let name: string|undefined;
    let sortOrder: number|undefined;

    const projectCompaniesByCompanyId = _.keyBy(Array.from(this.data.lookup.projectCompanies.values()), 'companyId');

    const handleSingleProtocolEntry = (protocolEntry: MaybeMinimizeProtocolEntry, companyId: string|null|undefined = protocolEntry.companyId) => {
      sortOrder = undefined;
      switch (this.data.protocol?.sortEntriesBy) {
        case ProtocolSortEntriesByEnum.COMPANY:
          if (protocolEntry.allCompanies) {
            name = `${String.fromCharCode(0)}__${this.i18n?.get('project_team')}`; // making sure always at the top
            sortOrder = -Infinity;
          } else {
            const projectCompany = companyId ? projectCompaniesByCompanyId[companyId] : undefined;
            name = this.data.lookup.companies.get(companyId + '')?.name;
            sortOrder = projectCompany?.sortOrder ?? undefined;
          }
          break;

        case ProtocolSortEntriesByEnum.CRAFT:
          name = this.data.lookup.crafts.get(protocolEntry.craftId + '')?.name;
          break;

        case ProtocolSortEntriesByEnum.NAMEABLE_DROPDOWN:
          name = this.data.lookup.nameableDropdowns.get(protocolEntry.nameableDropdownId + '')?.name;
          break;
      }
      name = name === undefined ? `${String.fromCharCode(255)}___${this.i18n?.get('other')}` : name; // making sure always at the bottom
      if (_.isEmpty(protocolEntry.parentId) && this.isNotIncludedInProtocolEntryFilter(protocolEntry)) {
        this.addParentProtocolEntry(groupProtocolEntries, name, sortOrder, protocolEntry);
      } else {
        this.addSubProtocolEntry(groupProtocolEntries, sortedProtocolEntries, name, sortOrder, protocolEntry);
      }
    };

    for (const protocolEntry of sortedProtocolEntries) {
      handleSingleProtocolEntry(protocolEntry);
      if (
        this.config.pdfProtocolSetting?.printEntryDetails?.includes(PdfPrintEntryDetails.OBSERVER_COMPANIES)
        && this.data.protocol?.sortEntriesBy === ProtocolSortEntriesByEnum.COMPANY
      ) {
        const observerCompanies = (this.groupedObserverCompanies[protocolEntry.id] ?? []).filter(
          ({ companyId }) => companyId !== protocolEntry.companyId
        );

        for (const { companyId } of observerCompanies) {
          handleSingleProtocolEntry(protocolEntry, companyId);
        }
      }
    }
    if (isOwnEntriesAndAppendCarried) {
      for (const groupProtocolEntry of groupProtocolEntries) {
        for (const entryOfGroup of groupProtocolEntry.protocolEntries) {
          if (this.data.protocolOpenEntries.some((entry) => entry.protocolEntryId === entryOfGroup.id && !entryOfGroup.subEntries?.length)) {
            const indexGroup = groupProtocolEntries.indexOf(groupProtocolEntry);
            const indexGroupEntry = groupProtocolEntry.protocolEntries.indexOf(entryOfGroup);
            groupProtocolEntries[indexGroup].protocolEntries.splice(indexGroupEntry, 1);
            if (groupProtocolEntries[indexGroup].protocolEntries.length === 0) {
              groupProtocolEntries.splice(indexGroup, 1);
            }
          }
        }
      }
    }

    return _.orderBy(groupProtocolEntries, ['groupSortOrder', 'groupName', 'protocolNumber'], ['asc', 'asc', 'asc']);
  }

  protected addParentProtocolEntry(groupProtocolEntries: GroupProtocolEntry[], name: string, sortOrder: number|undefined, protocolEntry: MaybeMinimizeProtocolEntry) {

    const groupProtocolEntryIndex = _.findIndex(groupProtocolEntries, (groupProtocolEntry) => groupProtocolEntry.groupName === name);
    const existingParentProtocolEntry = groupProtocolEntries[groupProtocolEntryIndex]?.protocolEntries.find(parentProtocolEntry => parentProtocolEntry.id === protocolEntry?.id);
    const cloneProtocolEntry: ProtocolEntryWithSubEntries = {minimize: false, ...protocolEntry, subEntries: new Array<ProtocolEntry>()};
    if (groupProtocolEntryIndex !== -1 && !existingParentProtocolEntry) {
      groupProtocolEntries[groupProtocolEntryIndex].protocolEntries.push(cloneProtocolEntry);
    } else if (groupProtocolEntryIndex === -1) {
      cloneProtocolEntry.minimize = false;
      groupProtocolEntries.push({
        groupName: name,
        groupSortOrder: sortOrder,
        protocolNumber: protocolEntry.number,
        protocolEntries: [cloneProtocolEntry]
      });
    }
  }

  protected isNotIncludedInProtocolEntryFilter(protocolEntry: ProtocolEntry): boolean {
    if (this.data.filteredProtocolEntries?.length) {
      return this.data.filteredProtocolEntries.some(filteredProtocolEntry => filteredProtocolEntry.id === protocolEntry.id);
    }
    return true;
  }

  protected addSubProtocolEntry(groupProtocolEntries: GroupProtocolEntry[], sortedProtocolEntries: MaybeMinimizeProtocolEntry[], name: string, sortOrder: number|undefined, protocolEntry: MaybeMinimizeProtocolEntry) {
    const groupProtocolEntryIndex = _.findIndex(groupProtocolEntries, (groupProtocolEntry) => groupProtocolEntry.groupName === name);
    const parentProtocolEntryRecord = sortedProtocolEntries.find(parentProtocolEntry => parentProtocolEntry.id === protocolEntry.parentId);
    const entryExistingInGroupProtocolEntry = groupProtocolEntries[groupProtocolEntryIndex]?.protocolEntries.find(parentProtocolEntry => parentProtocolEntry.id === parentProtocolEntryRecord?.id);
    const cloneProtocolEntry = _.clone(protocolEntry);

    if (groupProtocolEntryIndex === -1 || entryExistingInGroupProtocolEntry === undefined) {
      if (parentProtocolEntryRecord !== undefined) {
        this.addParentProtocolEntry(groupProtocolEntries, name, sortOrder, parentProtocolEntryRecord);
        this.addSubProtocolEntry(groupProtocolEntries, sortedProtocolEntries, name, sortOrder, cloneProtocolEntry);
      }
      return;
    }

    groupProtocolEntries[groupProtocolEntryIndex].protocolEntries = _.map(groupProtocolEntries[groupProtocolEntryIndex].protocolEntries, (parentProtocolEntry) => {
      if (parentProtocolEntry.id === protocolEntry.parentId) {
        parentProtocolEntry.minimize = this.isMinimizeProtocolEntry(parentProtocolEntry, cloneProtocolEntry);
        parentProtocolEntry.subEntries.push(cloneProtocolEntry);
      }
      return parentProtocolEntry;
    });
  }

  protected isMinimizeProtocolEntry(parentProtocolEntry: ProtocolEntry, subProtocolEntry: ProtocolEntry): boolean {
    if (this.config.pdfProtocolSetting?.hideMainEntry) {
      switch (this.data.protocol?.sortEntriesBy) {
        case ProtocolSortEntriesByEnum.COMPANY:
          if (parentProtocolEntry.allCompanies && subProtocolEntry.allCompanies) {
            return false;
          }
          const parentCompany = this.data.lookup.companies.get(parentProtocolEntry.companyId + '')?.name;
          const subCompany = this.data.lookup.companies.get(subProtocolEntry.companyId + '')?.name;
          return parentCompany !== subCompany;

        case ProtocolSortEntriesByEnum.CRAFT:
          const parentCraft = this.data.lookup.crafts.get(parentProtocolEntry.craftId + '')?.name;
          const subCraft = this.data.lookup.crafts.get(subProtocolEntry.craftId + '')?.name;
          return parentCraft !== subCraft;

        case ProtocolSortEntriesByEnum.NAMEABLE_DROPDOWN:
          const parentNameable = this.data.lookup.nameableDropdowns.get(parentProtocolEntry.nameableDropdownId + '')?.name;
          const subNameable = this.data.lookup.nameableDropdowns.get(subProtocolEntry.nameableDropdownId + '')?.name;
          return parentNameable !== subNameable;
      }
    }
    return false;
  }

  protected sanitizeBase64Image(base64Image: string): string {
    if (!(/^data:/.test(base64Image))) {
      return `data:${base64Image}`;
    }
    return base64Image;
  }

  protected getProtocolColor(): string {
    if (this.config?.pdfProtocolSetting?.protocolColor !== undefined && this.config?.pdfProtocolSetting?.protocolColor !== '') {
      return this.config?.pdfProtocolSetting?.protocolColor;
    }
    return PdfColor.BLUE;
  }

  protected changeSvgIconFill(svg: SvgIcons, color: string): string {
    return svg.toString().replace('__COLOR__', color);
  }

  protected getProtocolCreatedAt(protocolEntry: ProtocolEntry): Date {
    const originalProtocolId = _.get(protocolEntry, 'originalProtocolId', protocolEntry.protocolId);
    const protocol = this.data.lookup.protocols.has(originalProtocolId) ? this.data.lookup.protocols.get(originalProtocolId) || this.data.protocol : this.data.protocol;
    return _.isDate(protocol.createdAt) ? protocol.createdAt as Date : new Date(protocol.createdAt);
  }

  protected getProtocolNumber(protocolEntry: ProtocolEntry): number {
    const originalProtocolId = _.get(protocolEntry, 'originalProtocolId', protocolEntry.protocolId);
    const protocol = this.data.lookup.protocols.has(originalProtocolId) ? this.data.lookup.protocols.get(originalProtocolId) || this.data.protocol : this.data.protocol;
    return protocol.number;
  }

  protected getProtocolEntryShortId(protocolEntry: ProtocolEntry): string {
    const protocolType = this.data.lookup?.protocolTypes.get(this.data.protocol.typeId);
    const originalProtocolId = _.get(protocolEntry, 'originalProtocolId', protocolEntry.protocolId);
    const protocol = this.data.lookup.protocols.has(originalProtocolId) ? this.data.lookup.protocols.get(originalProtocolId) : this.data.protocol;
    let shortId = protocolType?.code;
    shortId += _.padStart(protocol?.number + '', 2, '0');
    if (protocolEntry?.parentId) {
      const parentProtocolEntry = this.data.protocolEntries.find(entry => entry.id === protocolEntry.parentId);
      shortId += '.' + _.padStart(parentProtocolEntry?.number + '', 3, '0');
    }
    shortId += '.' + _.padStart(protocolEntry?.number + '', 3, '0');
    return shortId + '';
  }

  protected getTaskShortId(protocolEntry: ProtocolEntry): string {
    const taskNumberAsString = protocolEntry.number.toString().padStart(3, '0');
    if (!protocolEntry.parentId) {
      return taskNumberAsString;
    }
    const parentProtocolEntry = this.data.protocolEntries.find(entry => entry.id === protocolEntry.parentId);
    if (!parentProtocolEntry) {
      return taskNumberAsString;
    }
    return parentProtocolEntry.number.toString().padStart(3, '0') + '.' + taskNumberAsString;
  }

  protected isForSpecificParticipantCompany(companyName: string): boolean {
    const profile = this.data.lookup.profiles.get(this.config.participant?.profileId + '');
    const company = this.data.lookup.companies.get(profile?.companyId + '');
    if ((company !== undefined && company.name === companyName) || companyName === this.i18n?.get('project_team') ) {
        return false;
    } else if (company !== undefined) {
      return true;
    }
    return false;
  }

  protected isHasProtocolEntries(protocolEntries: ProtocolEntry[]): boolean {
    if (!this.isGroupByCriteriaMet(this.data.protocol.sortEntriesBy)) {
      return true;
    }

    const groupProtocolEntries = this.groupProtocolEntries(protocolEntries);
    for (const groupProtocolEntry of groupProtocolEntries) {
      const currentGroupName = groupProtocolEntry.groupName.replace(/.+__/, '');
      if (this.isForSpecificParticipantCompany(currentGroupName)) {
        continue;
      }
      return true;
    }
    return false;
  }

  protected isUnitFeatureEnabledForCurrentClient(): boolean {
    return isUnitFeatureEnabledForClient(this.data.client.id);
  }

  protected getOrCreateUnitCompanyFromLookupCompanies(): Company {
    let company = this.data.lookup.companies.get(UNIT_OWNER_TENANT_COMPANY_ID);
    if (!company) {
      company = {
        id: UNIT_OWNER_TENANT_COMPANY_ID,
        name: this.i18n?.get('ownerTenant') ?? 'Owner/Tenant',
        clientId: this.data.client.id,
        isActive: true,
        changedAt: new Date()
      };
      this.data.lookup.companies.set(UNIT_OWNER_TENANT_COMPANY_ID, company);
    }
    return company;
  }
}
