import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import {Capacitor} from '@capacitor/core';
import {Keyboard, KeyboardResize} from '@capacitor/keyboard';
import {KeyboardResizeOptions} from '@capacitor/keyboard/dist/esm/definitions';
import {IonicSelectableComponent} from 'ionic-selectable';

@Injectable({
  providedIn: 'root'
})
export class SelectableUtilService {

  constructor(private platform: Platform) {
  }

  public async setKeyboardResizeModeOnOpen(): Promise<KeyboardResizeOptions|undefined> {
    if (this.isIosNative() && Capacitor.isPluginAvailable('Keyboard')) {
      const resizeModeBefore = await Keyboard.getResizeMode();
      await Keyboard.setResizeMode({mode: KeyboardResize.Native});
      return resizeModeBefore;
    }
    return undefined;
  }

  async setKeyboardResizeModeOnClose($event: { component: IonicSelectableComponent }, resizeModeBefore = {mode: KeyboardResize.Ionic}) {
    await this.setKeyboardResizeModeOnCloseWithoutEvent(resizeModeBefore);
  }

  async setKeyboardResizeModeOnCloseWithoutEvent(resizeModeBefore = {mode: KeyboardResize.Ionic}) {
    if (this.isIosNative() && Capacitor.isPluginAvailable('Keyboard')) {
      await Keyboard.setResizeMode(resizeModeBefore);
    }
  }

  isIosNative(): boolean {
    return this.platform.is('ios') && this.platform.is('capacitor');
  }
}
