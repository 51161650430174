import { Injectable, OnDestroy } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class PageDidEnterLifecycleService implements OnDestroy {

  private pageDidEnterSubject = new Subject<void>();

  pageDidEnter$ = this.pageDidEnterSubject.asObservable();

  constructor() { }

  pageDidEnter() { this.pageDidEnterSubject.next(); }

  ngOnDestroy() {
    this.pageDidEnterSubject.complete();
  }
}
