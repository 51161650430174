import {Injectable} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';
import {AvailableLicense, determineAvailableLicenses, LicenseType, User} from 'submodules/baumaster-v2-common';
import {UserDataService} from '../data/user-data.service';
import {ClientService} from '../client/client.service';
import {UserInviteDataService} from '../data/user-invite-data.service';

@Injectable({
  providedIn: 'root'
})
export class LicenseService {

  currentUser$: Observable<User|null> = this.userDataService.currentUser$.pipe(
    map((currentUser) => {
      return currentUser || null;
    })
  );

  currentUserLicense$: Observable<LicenseType|null> = this.currentUser$.pipe(
    map((user) => user?.role ?? null)
  );
  public availableLicences$: Observable<AvailableLicense|undefined>;

  constructor(private userDataService: UserDataService, private clientService: ClientService, private userInviteDataService: UserInviteDataService) {
    this.availableLicences$ = combineLatest([this.clientService.ownClient$, this.userDataService.data, this.userInviteDataService.dataForOwnClient$])
      .pipe(debounceTime(0))
      .pipe(map(([client, users, userInvites]) => {
        return client ? determineAvailableLicenses(client, users, userInvites) : undefined;
      }));
  }

  public getReadableLicenseType(licenseType: LicenseType): string {
    switch (licenseType) {
      case LicenseType.VIEWER: return 'viewer';
      case LicenseType.LIGHT: return 'light';
      case LicenseType.BASIC: return 'basic';
      case LicenseType.PROFESSIONAL: return 'professional';
      default: return `Unknown ${licenseType}`;
    }
  }
}
