import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-pdf-plan-comment-popover',
  templateUrl: './pdf-plan-comment-popover.component.html',
  styleUrls: ['./pdf-plan-comment-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfPlanCommentPopoverComponent {

  @Input()
  comment: string;

}
