import {AVAILABLE_PROJECT_EXPIRATION_IN_MS, DOWNLOADED_PROJECT_EXPIRATION_IN_MS, TEMPORARILY_PROJECT_EXPIRATION_IN_MS} from '../shared/constants';

export interface DevModeSettings {
  disableSync: boolean;
  disableUploadAttachments: boolean;
  disableChangeAttachmentVersion: boolean;
  temporarilyProjectExpirationInMs: number;
  availableProjectExpirationInMs: number;
  downloadedProjectExpirationInMs: number;
}

export const DEFAULT_DEV_MODE_SETTINGS: DevModeSettings = {
  disableSync: false,
  disableUploadAttachments: false,
  disableChangeAttachmentVersion: false,
  temporarilyProjectExpirationInMs: TEMPORARILY_PROJECT_EXPIRATION_IN_MS,
  availableProjectExpirationInMs: AVAILABLE_PROJECT_EXPIRATION_IN_MS,
  downloadedProjectExpirationInMs: DOWNLOADED_PROJECT_EXPIRATION_IN_MS
};
