import {Injectable} from '@angular/core';
import {AbstractPdfProtocolCommonService, PdfProtocolGenerateData, PdfProtocolSendReq} from 'submodules/baumaster-v2-common';
import * as pdfMake from 'pdfmake/build/pdfmake';
import pdfMakeUnicode from 'pdfmake-unicode';
import mulishJson from '../../../assets/fonts/mulish/mulish.json';
import interJson from '../../../assets/fonts/inter/inter.json';
import {getImageDimension, PdfHelperFunctionsImpl} from './pdf-client-util';
import { PdfPreviewDataService } from '../data/pdf-preview-data.service';
import { observableToPromise } from 'src/app/utils/async-utils';
import _ from 'lodash';
import { convertErrorToMessage } from 'src/app/shared/errors';
import { LoggingService } from '../common/logging.service';

(pdfMake as any).vfs = pdfMakeUnicode.pdfMake.vfs;

const PDF_GENERATION_TIMEOUT_IN_MS = 3 * 60 * 1000;
const LOG_SOURCE = 'PdfProtocolCommonService';

@Injectable({
  providedIn: 'root'
})
export class PdfProtocolCommonService extends AbstractPdfProtocolCommonService {
  constructor(private pdfPreviewDataService: PdfPreviewDataService, private loggingService: LoggingService) {
    super();
  }

  public async generatePdf(pdfProtocolSendReq: PdfProtocolSendReq, data: PdfProtocolGenerateData, abortSignal?: AbortSignal): Promise<Blob> {
    // TODO find a solution to handle errors. getBlob just never returns that's why callbacks are here.
    const pdfGenerationPromise = new Promise<Blob>(async (resolve, reject) => {
      try {
        const pdf = await this.createPdf(pdfProtocolSendReq, data);
        pdf.getBlob((blob) => {
          resolve(blob);
        });
      } catch (e) {
        reject(e);
      }
    });

    const promises: Array<Promise<Blob>> = [
      pdfGenerationPromise,
      new Promise<Blob>((blob, reject) => setTimeout(
        () => reject(new Error('Generating PDF timed out.')),
        PDF_GENERATION_TIMEOUT_IN_MS
      ))
    ];
    if (abortSignal) {
      const abortSignalPromise = new Promise<Blob>((blob, reject) => {
        abortSignal.onabort = () => {
          reject(new Error('generatePdf aborted'));
        };
      });
      promises.push(abortSignalPromise);
    }

    return await Promise.race(promises);
  }

  public async openPdf(pdfProtocolSendReq: PdfProtocolSendReq, data: PdfProtocolGenerateData) {
    const pdf = await this.createPdf(pdfProtocolSendReq, data);
    pdf.open();
  }

  private async createPdf(pdfProtocolSendReq: PdfProtocolSendReq, data: PdfProtocolGenerateData): Promise<pdfMake.TCreatedPdf> {
    const imgSrc = data.pdfProjectBanners?.get('pdf_end_banner')?.contentBase64 ?? data.attachmentClients?.get('pdfEndBanner')?.contentBase64;
    let footerDimension = {width: 1, height: 1};
    if (imgSrc) {
      try {
        footerDimension = await getImageDimension(imgSrc);
      } catch (error) {
        this.loggingService.error(LOG_SOURCE, `getImageDimension failed ${convertErrorToMessage(error)}`);
      }
    }
    const imgSrcHeader = data.pdfProjectBanners?.get('pdf_start_banner')?.contentBase64 ?? data.attachmentClients?.get('pdfStartBanner')?.contentBase64;
    let headerDimension = {width: 1, height: 1};
    if (imgSrcHeader) {
      try {
        headerDimension = await getImageDimension(imgSrcHeader);
      } catch (error) {
        this.loggingService.error(LOG_SOURCE, `getImageDimension failed ${convertErrorToMessage(error)}`);
      }
    }
    const lastPdfPreview = _.last(await observableToPromise(this.pdfPreviewDataService.getByProtocolIdSortedByIndexNumber(data.protocol.id)));
    const indexNumber = lastPdfPreview ? lastPdfPreview.indexNumber + 1 : 0;
    const printIndexNumber = !!lastPdfPreview;
    return await pdfMake.createPdf(this.getDocDefinition(pdfProtocolSendReq, data, PdfHelperFunctionsImpl,
      [footerDimension.width, footerDimension.height, headerDimension.width, headerDimension.height], printIndexNumber ? indexNumber : undefined), null, {
      Mulish: {
        normal: 'Mulish-Regular.ttf',
        bold: 'Mulish-Bold.ttf',
        italics: 'Mulish-Italic.ttf',
        bolditalics: 'Mulish-BoldItalic.ttf'
      },
      Inter: {
        normal: 'Inter-Regular.ttf',
        bold: 'Inter-Bold.ttf',
        italics: 'Inter-Italic.ttf',
        bolditalics: 'Inter-BoldItalic.ttf'
        }
    },
    {
      'Mulish-Regular.ttf': mulishJson.MulishRegular,
      'Mulish-Bold.ttf': mulishJson.MulishBold,
      'Mulish-Italic.ttf': mulishJson.MulishItalic,
      'Mulish-BoldItalic.ttf': mulishJson.MulishBoldItalic,
      'Inter-Regular.ttf': interJson.InterRegular,
      'Inter-Bold.ttf': interJson.InterBold,
      'Inter-Italic.ttf': interJson.InterItalic,
      'Inter-BoldItalic.ttf': interJson.InterBoldItalic
    });
  }
}
