import {Component} from '@angular/core';
import {ColorPickerDirective} from 'src/app/directives/ui/color-picker.directive';

@Component({
  selector: 'app-color-picker-button',
  templateUrl: './color-picker-button.component.html',
  styleUrls: ['./color-picker-button.component.scss'],
  standalone: true,
  imports: [
    ColorPickerDirective
  ],
  hostDirectives: [{
    directive: ColorPickerDirective,
    inputs: ['colors']
  }]
})
export class ColorPickerButtonComponent {

  constructor(protected picker: ColorPickerDirective) { }

}
