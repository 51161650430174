import {Component, OnInit} from '@angular/core';
import {AttachmentSettingService} from '../../../services/attachment/attachmentSetting.service';

@Component({
  selector: 'app-quota-exceeded',
  templateUrl: './quota-exceeded.component.html',
  styleUrls: ['./quota-exceeded.component.scss'],
})
export class QuotaExceededComponent implements OnInit {
  private modal: HTMLIonModalElement;
  public isSwitchingFileAccessUtilSupported: boolean;

  constructor(private attachmentSettingService: AttachmentSettingService) {
    this.isSwitchingFileAccessUtilSupported = this.attachmentSettingService.isSwitchingFileAccessUtilSupported();
  }

  ngOnInit() {
  }

  public async close() {
    await this.modal.dismiss();
  }
}
