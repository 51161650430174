import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AlertController, Platform} from '@ionic/angular';
import {Nullish} from 'src/app/model/nullish';
import {Translatable} from 'src/app/model/translatable';
import {dismissOverlayOnBackButtonOrNavigation} from 'src/app/utils/overlay-utils';
import {WithParamsTranslateService} from '../common/with-params-translate.service';

type AlertOptions = Parameters<AlertController['create']>[0];
interface AlertButtonOptions {
  fill: 'clear' | 'solid' | 'outline';
  color: string;
}

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private alertController: AlertController, private withParamsTranslateService: WithParamsTranslateService, private router: Router, private platform: Platform) {}

  async confirm(
    {
      message,
      header,
      subHeader,
      confirmLabel = 'okay',
      cancelLabel = 'cancel',
      confirmButton: {
        color: confirmColor = 'text-primary',
        fill: confirmFill = 'clear',
      } = {},
      cancelButton: {
        color: cancelColor = 'text-primary',
        fill: cancelFill = 'clear',
      } = {},
      dismissOnBackButtonOrNavigation = true,
    }: {
      message: Translatable;
      header?: Nullish<Translatable>;
      subHeader?: Nullish<Translatable>;
      confirmLabel?: Nullish<Translatable>;
      confirmButton?: Nullish<Partial<AlertButtonOptions>>;
      cancelLabel?: Nullish<Translatable>;
      cancelButton?: Nullish<Partial<AlertButtonOptions>>;
      dismissOnBackButtonOrNavigation?: boolean;
    },
    options?: Partial<Omit<AlertOptions, 'buttons'>>
  ): Promise<boolean> {
    const alert = await this.createAlert({
      ...options,
      header: this.withParamsTranslateService.translate(header),
      subHeader: this.withParamsTranslateService.translate(subHeader),
      message: this.withParamsTranslateService.translate(message),
      buttons: [{
        text: this.withParamsTranslateService.translate(cancelLabel),
        role: 'cancel',
        cssClass: this.getButtonCssClass({color: cancelColor, fill: cancelFill}),
      }, {
        text: this.withParamsTranslateService.translate(confirmLabel),
        role: 'confirm',
        cssClass: this.getButtonCssClass({color: confirmColor, fill: confirmFill}),
      }]
    });

    if (dismissOnBackButtonOrNavigation) {
      dismissOverlayOnBackButtonOrNavigation(alert, this.router, this.platform);
    }
    await alert.present();

    return (await alert.onWillDismiss()).role === 'confirm';
  }

  async ok(
    {
      message,
      header,
      subHeader,
      confirmLabel = 'okay',
      confirmButton: {
        color: confirmColor = 'text-primary',
        fill: confirmFill = 'clear',
      } = {}
    }: {
      message: Translatable;
      header?: Nullish<Translatable>;
      subHeader?: Nullish<Translatable>;
      confirmLabel?: Nullish<Translatable>;
      confirmButton?: Nullish<Partial<AlertButtonOptions>>;
    },
    options?: Partial<Omit<AlertOptions, 'buttons'>>
  ): Promise<boolean> {
    const alert = await this.createAlert({
      ...options,
      header: this.withParamsTranslateService.translate(header),
      subHeader: this.withParamsTranslateService.translate(subHeader),
      message: this.withParamsTranslateService.translate(message),
      buttons: [{
        text: this.withParamsTranslateService.translate(confirmLabel),
        role: 'confirm',
        cssClass: this.getButtonCssClass({color: confirmColor, fill: confirmFill}),
      }]
    });

    await alert.present();

    return (await alert.onWillDismiss()).role === 'confirm';
  }

  async confirmOneOrAll(
    {
      message,
      header,
      subHeader,
      confirmLabel = 'delete',
      cancelLabel = 'cancel',
      confirmAllLabel = 'deleteAll',
      confirmButton: {
        color: confirmColor = 'text-primary',
        fill: confirmFill = 'clear',
      } = {},
      confirmAllButton: {
        color: confirmAllColor = 'text-primary',
        fill: confirmAllFill = 'clear',
      } = {},
      cancelButton: {
        color: cancelColor = 'text-primary',
        fill: cancelFill = 'clear',
      } = {},
    }: {
      message: Translatable;
      header?: Nullish<Translatable>;
      subHeader?: Nullish<Translatable>;
      confirmLabel?: Nullish<Translatable>;
      confirmAllLabel?: Nullish<Translatable>;
      confirmButton?: Nullish<Partial<AlertButtonOptions>>;
      confirmAllButton?: Nullish<Partial<AlertButtonOptions>>;
      cancelLabel?: Nullish<Translatable>;
      cancelButton?: Nullish<Partial<AlertButtonOptions>>;
    },
    options?: Partial<Omit<AlertOptions, 'buttons'>>
  ): Promise<string> {
    const alert = await this.createAlert({
      ...options,
      header: this.withParamsTranslateService.translate(header),
      subHeader: this.withParamsTranslateService.translate(subHeader),
      message: this.withParamsTranslateService.translate(message),
      buttons: [{
        text: this.withParamsTranslateService.translate(cancelLabel),
        role: 'cancel',
        cssClass: this.getButtonCssClass({color: cancelColor, fill: cancelFill}),
      }, {
        text: this.withParamsTranslateService.translate(confirmAllLabel),
        role: 'confirmAll',
        cssClass: this.getButtonCssClass({color: confirmAllColor, fill: confirmAllFill}),
      }, {
        text: this.withParamsTranslateService.translate(confirmLabel),
        role: 'confirm',
        cssClass: this.getButtonCssClass({color: confirmColor, fill: confirmFill}),
      }]
    });

    dismissOverlayOnBackButtonOrNavigation(alert, this.router, this.platform);
    await alert.present();

    return (await alert.onWillDismiss()).role;
  }

  async createAlert(options?: Partial<AlertOptions>) {
    const alert = await this.alertController.create({
      ...options,
      cssClass: `omg omg-boundary${options?.cssClass ? ` ${options.cssClass}` : ''}`,
    });

    return alert;
  }

  getButtonCssClass({color = 'text-primary', fill = 'clear'}: Partial<AlertButtonOptions> = {}) {
    return `ion-color-${color}${fill === 'clear' ? '' : ` alert-button-${fill}`}`;
  }
}
