<app-modal-header [showCloseButton]="false">
  <ion-title>{{'paymentOverdue.title' | translate}}</ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <div class="h-100 d-flex ion-align-items-stretch ion-justify-content-center flex-column gap-4">
    <h3>{{'paymentOverdue.title' | translate}}</h3>
    <p class="omg-t-body" [innerHTML]="'paymentOverdue.body.1' | translate"></p>
  </div>
</ion-content>
