import {Component, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, Output, SimpleChanges} from '@angular/core';
import {ProtocolEntry, ProtocolEntryStatus, ProtocolEntryType} from 'submodules/baumaster-v2-common';
import {ProtocolEntryTypeDataService} from 'src/app/services/data/protocol-entry-type-data.service';
import {Subscription} from 'rxjs';
import {ProtocolEntryStatusPopoverComponent} from '../protocol-entry-status-popover/protocol-entry-status-popover.component';
import {PopoverController} from '@ionic/angular';
import {SystemEventService} from '../../../services/event/system-event.service';
import {ProtocolService} from 'src/app/services/protocol/protocol.service';
import {getColorName} from 'src/app/utils/protocol-entry-utils';
import {ProtocolEntryContinuousInfoPopoverComponent} from '../protocol-entry-continuous-info-popover/protocol-entry-continuous-info-popover.component';

const LOG_SOURCE = 'ProtocolEntryStatusComponent';

@Component({
  selector: 'app-protocol-entry-status',
  templateUrl: './protocol-entry-status.component.html',
  styleUrls: ['./protocol-entry-status.component.scss'],
})
export class ProtocolEntryStatusComponent implements OnChanges, OnDestroy {
  @Input() acrossProjects = true;
  @Input() protocolEntry: ProtocolEntry;
  @Input() showLabel = true;
  @Input() canEdit = false;
  @Input() isProtocolLayoutShort: boolean|undefined;
  @Input() isProtocolLayoutContinuous: boolean|undefined;
  @Input() isTaskProtocol = false;
  @Output() statusChange = new EventEmitter<ProtocolEntryStatus>();
  @Output() isContinuousInfoChange = new EventEmitter<boolean>();

  @Input() statusFieldActive: boolean;
  @Output() statusFieldActiveChange = new EventEmitter<boolean>(true);

  public protocolEntryType: ProtocolEntryType|null;
  public protocolEntryStatus = ProtocolEntryStatus;
  public protocolEntryTypeSubscription: Subscription|undefined;
  public currentStatusFieldActive = false;

  @HostBinding('style.--bau-status-color')
  get varBauStatusColor() {
    const { protocolEntry, statusFieldActive, isProtocolLayoutShort } = this;
    const color = getColorName(protocolEntry, statusFieldActive, isProtocolLayoutShort);
    return `var(--ion-color-${color === 'danger' ? 'text-' : ''}${color})`;
  }

  constructor(
    private protocolEntryTypeDataService: ProtocolEntryTypeDataService,
    private systemEventService: SystemEventService,
    private popoverCtr: PopoverController,
    private protocolService: ProtocolService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    try {
      this.unsubscribeProtocolEntryType();
      if (this.isProtocolLayoutShort) {
        this.currentStatusFieldActive = true;
        this.changeStatusFieldActive();
      } else {
        this.protocolEntryTypeSubscription = (this.acrossProjects
          ? this.protocolEntryTypeDataService
              .getByIdAcrossClients(this.protocolEntry?.typeId)
          : this.protocolEntryTypeDataService
              .getById(this.protocolEntry?.typeId)
        )
          .subscribe(protocolEntryType => {
            this.currentStatusFieldActive = Boolean(protocolEntryType?.statusFieldActive);
            this.protocolEntryType = protocolEntryType;
            this.changeStatusFieldActive();
          });
      }
    } catch (error) {
      this.systemEventService.logErrorEvent(LOG_SOURCE + ' - ngOnChanges', error?.userMessage + '-' + error?.message);
    }
  }

  changeStatusFieldActive() {
    this.statusFieldActive = this.currentStatusFieldActive;
    this.statusFieldActiveChange.emit(this.currentStatusFieldActive);
  }

  ngOnDestroy() {
    this.unsubscribeProtocolEntryType();
  }

  async showStatusPopover(event) {
    event.stopPropagation();
    if (!this.canEdit) {
      return;
    }
    const popover = await this.popoverCtr.create({
      component: ProtocolEntryStatusPopoverComponent,
      componentProps: {
        isProtocolLayoutShort: this.isProtocolLayoutShort,
        isTaskProtocol: this.isTaskProtocol,
        currentStatus: this.protocolEntry?.status,
        assignmentId: this.protocolEntry?.internalAssignmentId,
        isOwnClient: await this.protocolService.isOwnClientProtocol(this.protocolEntry?.protocolId),
      },
      event,
      translucent: true
    });

    popover.onDidDismiss()
      .then((response: any) => {
        if (typeof response.data !== 'undefined') {
          this.statusChange.emit(response.data?.newStatus);
        }
      });
    return await popover.present();
  }

  async showContinuousInfoPopover(event) {
    event.stopPropagation();
    if (!this.canEdit || !this.isProtocolLayoutContinuous) {
      return;
    }
    const popover = await this.popoverCtr.create({
      component: ProtocolEntryContinuousInfoPopoverComponent,
      componentProps: {
        isProtocolLayoutShort: this.isProtocolLayoutShort,
        isTaskProtocol: this.isTaskProtocol,
        currentStatus: this.protocolEntry?.status,
        assignmentId: this.protocolEntry?.internalAssignmentId,
        isOwnClient: await this.protocolService.isOwnClientProtocol(this.protocolEntry?.protocolId),
      },
      event,
      translucent: true
    });

    popover.onDidDismiss()
      .then((response: any) => {
        if (typeof response.data !== 'undefined') {
          this.isContinuousInfoChange.emit(response.data?.newIsContinuousInfo);
        }
      });
    return await popover.present();
  }

  unsubscribeProtocolEntryType() {
    if (this.protocolEntryTypeSubscription) {
      this.protocolEntryTypeSubscription.unsubscribe();
      this.protocolEntryTypeSubscription = undefined;
    }
  }

}
