<ionic-selectable *ngIf="mergedItems"
                  #selectable
                  item-content
                  class="text-primary"
                  [canClear]="canClear"
                  [items]="mergedItems"
                  [disabledItems]="disabledItems"
                  [itemValueField]="itemValueField"
                  [itemTextField]="itemTextField"
                  (onChange)="onChangeEvent($event)"
                  [isEnabled]="isEnabled"
                  [canSearch]="canSearch"
                  [isMultiple]="isMultiple"
                  [shouldStoreItemValue]="shouldStoreItemValue"
                  [ngModel]="value"
                  (ngModelChange)="valueChangeHandle($event)"
                  (onOpen)="onOpen($event)"
                  (onClose)="onClose($event)"
                  [disabled]="!isEnabled"
                  searchPlaceholder="{{(allowCreateNew ? 'searchAndCreate' : 'search') | translate}}"
                  (onSelect)="onSelectItem($event)"
                  (onSearch)="onSearchEvent($event)"
                  [isOnSearchEnabled]="disableDefaultOnSearch"
                  appSelectableCommon
                  [hasVirtualScroll]="true"
                  virtualScrollApproxItemHeight="48px"
                  [virtualScrollHeaderFn]="getGroupText"
                  #fs="appSelectableCommon" [shouldFocusSearchbar]="fs?.isDesktop">
  <ng-template ionicSelectableHeaderTemplate>
    <app-selectable-header [title]="modalTitle"></app-selectable-header>
  </ng-template>
  <ng-template ionicSelectableGroupTemplate let-group="group">
    {{group.text}}
  </ng-template>
  <ng-template ionicSelectableItemIconTemplate let-selected="isItemSelected">
    <app-selectable-item-icon [selected]="selected"></app-selectable-item-icon>
  </ng-template>
  <ng-template *ngIf="!valueTemplate" ionicSelectableValueTemplate let-value="value">
    <app-selectable-value [value]="value"></app-selectable-value>
  </ng-template>

  <ng-template *ngIf="itemTemplate" ionicSelectableItemTemplate let-item="item" let-isItemSelected="isItemSelected">
    <ng-container *ngTemplateOutlet="itemTemplate; context: {item: item, isItemSelected: isItemSelected}">
    </ng-container>
  </ng-template>

  <ng-template *ngIf="valueTemplate" ionicSelectableValueTemplate let-value="value">
    <ng-container *ngTemplateOutlet="valueTemplate; context: {value: value}">
    </ng-container>
  </ng-template>

  <ng-template ionicSelectableFooterTemplate>
    <app-selectable-footer [canClear]="canClear">
      <ion-button (click)="createNew(selectable?.searchText)" [disabled]="!isEnabled" *ngIf="(allowCreateNew && createNewFunction && ((mergedItems && !mergedItems.length) || selectable?.searchText))" class="omg-btn-secondary">
        <fa-icon [fixedWidth]="true" [icon]="['fal', 'plus']"></fa-icon>
        {{ 'button.create' | translate }}
      </ion-button>
    </app-selectable-footer>
  </ng-template>

  <ng-template ionicSelectableSearchFailTemplate>
    <div *ngIf="!selectable?.searchText" class="noItemsFound">{{'noItemsFound' | translate}}</div>
    <div *ngIf="selectable?.searchText" class="noItemsFound">{{'noItemsFoundClickCreate' | translate: {searchText: selectable?.searchText} }}</div>
    <div *ngIf="allowCreateNew && createNewFunction" class="noItemsFound noItemsFoundSub">{{'noItemsFoundClickCreate2' | translate: {searchText: selectable?.searchText} }}</div>
  </ng-template>
</ionic-selectable>
