import {Component, forwardRef} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { PopoverService } from 'src/app/services/ui/popover.service';
import {UserEmailSignatureService} from 'src/app/services/user/user-email-signature.service';
import { observableToPromise } from 'src/app/utils/async-utils';
import {EmailSignatureFormUtilDirective} from '../email-signature-form-util.directive';

@Component({
  selector: 'app-email-signature-inline-form',
  templateUrl: './email-signature-inline-form.component.html',
  styleUrls: ['./email-signature-inline-form.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EmailSignatureInlineFormComponent),
    multi: true
  }],
})
export class EmailSignatureInlineFormComponent extends EmailSignatureFormUtilDirective {

  userEmailSignatureImage$ = this.userEmailSignatureService.userEmailSignatureImage$;
  disabledSizes$ = this.userEmailSignatureService.disabledSizes$.pipe(map((sizes) => sizes.map(({ id }) => id)));

  constructor(
    private userEmailSignatureService: UserEmailSignatureService,
    private popoverService: PopoverService,
    private translateService: TranslateService
  ) { super(); }

  async clientLogoDetailsClick(event: MouseEvent) {
    const disabledSizes = await observableToPromise(this.disabledSizes$);
    const smallLabel = disabledSizes.includes('SMALL')
      ? this.translateService.instant('emailSignature.form.sizeIsDisabled', {size: this.translateService.instant('sendProtocol.protocolConfig.image_SMALL')})
      : this.translateService.instant('sendProtocol.protocolConfig.image_SMALL');
    const mediumLabel = disabledSizes.includes('MEDIUM')
      ? this.translateService.instant('emailSignature.form.sizeIsDisabled', {size: this.translateService.instant('sendProtocol.protocolConfig.image_MEDIUM')})
      : this.translateService.instant('sendProtocol.protocolConfig.image_MEDIUM');

    const result = await this.popoverService.openActions(event, [
      {
        label: 'emailSignature.form.size',
        role: 'undefined',
        disabled: true
      },
      {
        role: 'SMALL',
        label: smallLabel,
        disabled: disabledSizes.includes('SMALL')
      },
      {
        role: 'MEDIUM',
        label: mediumLabel,
        disabled: disabledSizes.includes('MEDIUM')
      },
      {
        role: 'ORIGINAL',
        label: 'sendProtocol.protocolConfig.image_ORIGINAL',
      },
      {
        label: 'actions',
        role: 'undefined',
        disabled: true
      },
      {
        role: 'remove',
        label: 'emailSignature.form.deleteLogo'
      },
    ]);

    if (result !== 'backdrop' && result !== 'feature-disabled-connected' && result !== 'feature-disabled-license') {
      switch (result) {
        case 'SMALL':
          this.updateModel({ size: 'SMALL' });
          break;
        case 'MEDIUM':
          this.updateModel({ size: 'MEDIUM' });
          break;
        case 'ORIGINAL':
          this.updateModel({ size: 'ORIGINAL' });
          break;
        case 'remove':
          this.updateModel({ includeCompanyLogo: false });
          break;
        default:
          throw new Error('Unsupported action: ' + result);
      }
    }
  }
}
