import {CommonModule} from '@angular/common';
import {Component, Input} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {Nullish} from 'src/app/model/nullish';
import {UiModule} from 'src/app/shared/module/ui/ui.module';

@Component({
  selector: 'app-color-picker-modal',
  templateUrl: './color-picker-modal.component.html',
  styleUrls: ['./color-picker-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    UiModule,
    TranslateModule,
  ]
})
export class ColorPickerModalComponent {

  private modal: HTMLIonModalElement;

  @Input()
  colors: string[];
  @Input()
  color: Nullish<string>;

  constructor() { }

  dismiss() {
    this.modal.dismiss();
  }

  compareColors(a: Nullish<string>, b: Nullish<string>) {
    return a?.toLocaleLowerCase() === b?.toLocaleLowerCase();
  }

  apply() {
    this.modal.dismiss(this.color, 'update');
  }

  selectColor(color: Nullish<string>) {
    this.color = color;
  }

}
