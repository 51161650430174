const BLUE = '#2691C8';
const BLUE_RGB = '38, 145, 200';

const SUCCESS = '#78C832';
const SUCCESS_RGB = '120, 200, 50';

const WARNING = '#EFB003';
const WARNING_RGB = '239, 176, 3';

const RED = '#AD003E';
const RED_RGB = '173, 0, 62';

const TEXT_DANGER = '#BE4968';
const TEXT_DANGER_RGB = '190, 73, 104';

const HEADING_PRIMARY = '#1D252E';
const HEADING_PRIMARY_RGB = '29, 37, 46';

const TEXT_PRIMARY = '#1D252E';
const TEXT_PRIMARY_RGB = '29, 37, 46';

const TEXT_SECONDARY = '#8594A5';
const TEXT_SECONDARY_RGB = '133, 148, 165';

const TEXT_DARK = '#1D252E';
const TEXT_DARK_RGB = '29, 37, 46';

const INACTIVE = '#A5B4C5';
const INACTIVE_RGB = '165, 180, 197';

const WHITE = '#FFFFFF';
const WHITE_RGB = '255, 255, 255';

const SKETCH_BLACK = '#1A1A1A';
const SKETCH_BLACK_RGB = '26, 26, 26';

const LIGHT = '#E6EDF6';
const LIGHT_RGB = '230, 237, 246';

const ALTERNATIVE = '#004F74';
const ALTERNATIVE_RGB = '0, 79, 116';

export const BauColors = {
  BLUE,
  PRIMARY: BLUE,
  WARNING,
  RED,
  DANGER: RED,
  SUCCESS,
  INACTIVE,
  TEXT_PRIMARY,
  LIGHT,
  WHITE,
  TEXT_DANGER,
  HEADING_PRIMARY,
  TEXT_SECONDARY,
  TEXT_DARK,
  SKETCH_BLACK,
  ALTERNATIVE
};

export const BauAccentColors = [
  {label: 'tags.colors.blue', color: 'blue'},
  {label: 'tags.colors.red', color: 'red'},
  {label: 'tags.colors.yellow', color: 'yellow'},
  {label: 'tags.colors.green', color: 'green'},
  {label: 'tags.colors.darkBlue', color: 'dark-blue'},
  {label: 'tags.colors.orange', color: 'orange'},
  {label: 'tags.colors.purple', color: 'purple'},
  {label: 'tags.colors.pink', color: 'pink'},
  {label: 'tags.colors.brown', color: 'brown'},
  {label: 'tags.colors.grey', color: 'grey'},
];

export const BauRgbColors = {
  BLUE: BLUE_RGB,
  PRIMARY: BLUE_RGB,
  WARNING: WARNING_RGB,
  RED: RED_RGB,
  DANGER: RED_RGB,
  SUCCESS: SUCCESS_RGB,
  INACTIVE: INACTIVE_RGB,
  TEXT_PRIMARY: TEXT_PRIMARY_RGB,
  LIGHT: LIGHT_RGB,
  WHITE: WHITE_RGB,
  TEXT_DANGER: TEXT_DANGER_RGB,
  HEADING_PRIMARY: HEADING_PRIMARY_RGB,
  TEXT_SECONDARY: TEXT_SECONDARY_RGB,
  TEXT_DARK: TEXT_DARK_RGB,
  SKETCH_BLACK: SKETCH_BLACK_RGB,
  ALTERNATIVE: ALTERNATIVE_RGB
};

Object.freeze(BauColors);
Object.freeze(BauRgbColors);
Object.freeze(BauAccentColors);

type UnderscoreToDash<T extends string> = T extends `${infer L}_${infer R}` ? `${UnderscoreToDash<L>}-${UnderscoreToDash<R>}` : T;

export type BauColorsKeys = UnderscoreToDash<Lowercase<keyof typeof BauColors>>;
