import {ErrorHandler, Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {SystemEventService} from './services/event/system-event.service';

const chunkFailedMessage = /Loading chunk [\d]+ failed/;

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private systemEventService: SystemEventService) {
  }

  handleError(error: any): void {
    this.systemEventService.logErrorEvent('GlobalErrorHandler', error);
    if (chunkFailedMessage.test(error.message)) {
      console.warn('Reloading app due to chunk failed error');
      window.location.reload();
    } else {
      if (!environment.production) {
        console.error(error);
      }
      throw error;
    }
  }
}
