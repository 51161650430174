<app-modal-header>
  <ion-title>
    <strong>{{ (boldTitle || 'tasks.assignToProtocol.title') | translate }}</strong> {{ (title || 'tasks.assignToProtocol.chooseProtocol') | translate }}
  </ion-title>
</app-modal-header>

<ion-content>
  <div class="omg-attention omg-attention-warning d-flex flex-column ion-text-center">
    <span>
      {{ (selectedCountTranslationKey || 'tasks.assignToProtocol.selectedEntriesCount') | translate:{selectedCount:entryIds.length,willMoveCount:entryIdsToMove.length} }}
    </span>
    <span class="omg-t-medium-body" *ngIf="!hasAllChildren">
      {{ (allSubEntriesWillMoveTranslationKey || 'tasks.assignToProtocol.allSubEntriesWillBeMoved') | translate }}
    </span>
  </div>
  <div class="omg-attention omg-attention-danger ion-text-center" *ngIf="!hasAllParents">
    {{ (withoutParentWillSkipTranslationKey || 'tasks.assignToProtocol.subEntriesSelectedWithoutParent') | translate }}
  </div>
  <div class="pt-6 pl-6 pr-6">
    <ion-searchbar class="slim-searchbar" [placeholder]="'search' | translate" [ngModel]="searchSubject | async" (ngModelChange)="searchSubject.next($event)"></ion-searchbar>
  </div>
  <ion-list lines="none" class="omg-boundary">
    <ion-radio-group [(ngModel)]="targetProtocolId">
      <div *rxFor="let item of protocols$; parent false">
        <ion-item-divider *ngIf="item.firstInGroup">
          {{ ('tasks.assignToProtocol.group.' + item.inGroup) | translate }}
        </ion-item-divider>
        <ion-item>
          <ion-radio [value]="item.id" labelPlacement="end" justify="start">
            <ng-container *ngIf="item.inGroup !== 'task'">
              <div class="protocol-name-container">
                <div class="protocol-name">
                  {{item.protocolType.code}}-{{item.number|number:'2.0'}} |
                  {{item.name}}
                </div>
                <div class="protocol-type-name pl-5">
                  {{item.protocolType.name}}
                </div>
              </div>
            </ng-container>
            <span *ngIf="item.inGroup === 'task'">
            {{ 'Tasks' | translate }}
            </span>
          </ion-radio>
        </ion-item>
        <ion-item *ngIf="item.groupSubtext" class="text-secondary omg-t-body">{{ item.groupSubtext | translate }}</ion-item>
      </div>
    </ion-radio-group>
  </ion-list>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" (click)="close()">
      {{ 'cancel' | translate }}
    </ion-button>
    <ion-button class="omg-btn-primary" [disabled]="!targetProtocolId" (click)="confirmTarget()">
      {{ 'assign' | translate }}
    </ion-button>
  </ion-buttons>
</app-modal-footer>
