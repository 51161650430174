import {AsyncPipe} from '@angular/common';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlContainer, ReactiveFormsModule} from '@angular/forms';
import {KeyboardResizeOptions} from '@capacitor/keyboard';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {IonicSelectableComponent} from 'ionic-selectable';
import {map, Observable, Subject, takeUntil} from 'rxjs';
import {SelectableUtilService} from 'src/app/services/common/selectable-util.service';
import {CountryDataService} from 'src/app/services/data/country-data.service';
import {SelectableInputModule} from 'src/app/shared/module/selectable-input/selectable-input.module';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
  standalone: true,
  imports: [IonicModule, ReactiveFormsModule, TranslateModule, SelectableInputModule, AsyncPipe]
})
export class AddressFormComponent implements OnInit, OnDestroy {

  @Input()
  readonly: boolean;

  countries$: Observable<string[]>;

  private resizeModeBeforeOpen: KeyboardResizeOptions | undefined;
  private destroy$ = new Subject<void>();

  constructor(protected parent: ControlContainer, private selectableUtilService: SelectableUtilService, private countryDataService: CountryDataService) {
    this.countries$ = this.countryDataService.dataSorted$.pipe(
      map(countries => countries.map((country) => country.name))
    );
  }

  ngOnInit(): void {
    this.countries$.pipe(takeUntil(this.destroy$)).subscribe(countries => {
      const preSelectedCountry = countries.find(country => country.toLowerCase() === this.parent.control.value?.country?.toLowerCase());
      if (preSelectedCountry && !this.parent.control.get('country')?.dirty) {
        this.parent.control.patchValue({country: preSelectedCountry}, {emitEvent: false});
      }
    });
    if (this.readonly) {
      this.parent.control.get('country')?.disable();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async onOpen($event: { component: IonicSelectableComponent }) {
    this.resizeModeBeforeOpen = await this.selectableUtilService.setKeyboardResizeModeOnOpen();
  }

  async onClose($event: { component: IonicSelectableComponent }) {
    await this.selectableUtilService.setKeyboardResizeModeOnClose($event, this.resizeModeBeforeOpen);
  }
}
