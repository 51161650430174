<app-main-header
  [title]="'protocols.title' | translate"
  [defaultPage]="(backButtonPage$ | async) || '/protocols'"
  [showBackButton]="(showBackButton$ | async)"
>
</app-main-header>

<ng-template #fabButton>
  <ion-fab
    class="margin-right-10"
    slot="fixed"
    *appFeatureEnabled="false; forConnected true; forLicenses [lt.LicenseType.VIEWER]"
    vertical="bottom"
    horizontal="end"
  >
    <ion-button class="omg-btn-primary" *ngIf="showFullSizeCreateButton$ | async; else fabIconOnly" (click)="addNewProtocol()">
      {{ 'protocols.create' | translate }}
    </ion-button>
    <ng-template #fabIconOnly>
      <ion-button class="omg-btn-primary omg-btn-fab" (click)="addNewProtocol()">
        <fa-icon [icon]="['fal', 'plus']"></fa-icon>
      </ion-button>
    </ng-template>
  </ion-fab>
</ng-template>


<ion-content>
  <ion-split-pane
    contentId="main"
    [when]="(isFourColumnWithNotEnoughSpaceAndPinnedSideBar$ | async) ? '(max-width: 0px)' : fourColumnLayoutBreakpoint"
    [style.flex-grow]="1"
    (ionSplitPaneVisible)="onSplitPaneVisible($event)"
  >
    <ion-content #lt="appLicenseType" appLicenseType class="split-pane-side">
      <app-protocol-list></app-protocol-list>

      <ng-container *ngTemplateOutlet="fabButton"></ng-container>
    </ion-content>

    <ion-content id="main">
      <ion-router-outlet></ion-router-outlet>

      <ng-container *ngIf="showRouterOutletFab$ | async">
        <ng-container *ngTemplateOutlet="fabButton"></ng-container>
      </ng-container>
    </ion-content>
  </ion-split-pane>
</ion-content>

<app-tab-menu></app-tab-menu>
