import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TasksPageService} from 'src/app/services/tasks/tasks-page.service';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.page.html',
  styleUrls: ['./tasks.page.scss'],
})
export class TasksPage implements OnDestroy, OnInit {

  private readonly destroy$ = new Subject<void>();

  constructor(
    private tasksPageService: TasksPageService
  ) { }

  ngOnInit() {
    // Making sure at least one subscription reference is attached to these observables
    this.tasksPageService.entriesFiltered$.pipe(takeUntil(this.destroy$)).subscribe();
    this.tasksPageService.taskProtocolId$.pipe(takeUntil(this.destroy$)).subscribe();
    this.tasksPageService.lastTaskId$.pipe(takeUntil(this.destroy$)).subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
