import {ColumnConfig} from '../ColumnConfig';
import {NextMeetingColumnSet} from './NextMeetingColumnSet';
import {ParticipantColumnSet} from './ParticipantColumnSet';
import {PdfPreviewColumnSet} from './PdfPreviewColumnSet';
import {ProtocolEntryColumnSet} from './ProtocolEntryColumnSet';
import {ProtocolOpenEntryColumnSet} from './ProtocolOpenEntryColumnSet';
import {AttachmentProtocolSignatureColumnSet} from './AttachmentProtocolSignatureColumnSet';

export const ProtocolColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'number'},
  {name: 'name', def: null},
  {name: 'date', cast: 'Date', def: null},
  {name: 'time_from', prop: 'timeFrom', cast: 'Time', def: null},
  {name: 'time_until', prop: 'timeUntil', cast: 'Time', def: null},
  {name: 'location', def: null},
  {name: 'closed_at', prop: 'closedAt', cast: 'Timestamp', def: null},
  {name: 'project_id', prop: 'projectId'},
  {name: 'type_id', prop: 'typeId'},
  {name: 'sort_entries_by', prop: 'sortEntriesBy', def: null},
  {name: 'owner_client_id', prop: 'ownerClientId', cnd: true},
  {name: 'created_by_id', prop: 'createdById', cnd: true, def: null},
  {name: 'created_at', prop: 'createdAt', cnd: true, cast: 'Timestamp'},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'includes_vat', prop: 'includesVat', def: false},
  {name: 'weather', prop: 'weather', def: null},
  {name: 'min_temp', prop: 'minTemp', def: null},
  {name: 'max_temp', prop: 'maxTemp', def: null},
  {name: 'humidity', prop: 'humidity', def: null},
  {name: 'windspeed', prop: 'windspeed', def: null},
  {name: 'weather_from_api', prop: 'weatherFromApi', def: false},
  {name: 'weather_activated', prop: 'weatherActivated', def: false},
  {name: 'unit_id', prop: 'unitId', def: null},
  {name: 'is_unit_entry_default', prop: 'isUnitEntryDefault', def: null},
  {name: 'protocolEntries', fk: 'protocolId', mappedBy: ProtocolEntryColumnSet, transient: true},
  {name: 'protocolEntries', fk: 'createdInProtocolId', mappedBy: ProtocolEntryColumnSet, transient: true},
  {name: 'participants', fk: 'protocolId', mappedBy: ParticipantColumnSet, transient: true},
  {name: 'pdfPreviews', fk: 'protocolId', mappedBy: PdfPreviewColumnSet, transient: true},
  {name: 'protocolOpenEntries', fk: 'protocolId', mappedBy: ProtocolOpenEntryColumnSet, transient: true},
  {name: 'nextMeetings', fk: 'protocolId', mappedBy: NextMeetingColumnSet, transient: true},
  {name: 'attachmentProtocolSignatures', fk: 'protocolId', mappedBy: AttachmentProtocolSignatureColumnSet, transient: true}
];
