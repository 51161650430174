import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Platform} from '@ionic/angular';
import _ from 'lodash';
import {ProjectDataService} from 'src/app/services/data/project-data.service';
import {getProtocolEntryPagePath} from 'src/app/utils/router-utils';
import {IdAware, IdType} from 'submodules/baumaster-v2-common';
import {LocalSyncClientAwareResponse, LocalSyncNonClientAwareResponse, LocalSyncProjectAwareResponse} from '../../../model/sync-models';
import {SyncConflict} from '../../../services/sync/sync-utils';

@Component({
  selector: 'app-sync-conflicts',
  templateUrl: './sync-conflicts.component.html',
  styleUrls: ['./sync-conflicts.component.scss'],
})
export class SyncConflictsComponent implements OnInit, OnChanges {
  private modal: HTMLIonModalElement;
  @Input()
  private nonClientAwareResult?: LocalSyncNonClientAwareResponse<IdAware>;
  @Input()
  private clientAwareResults?: Array<LocalSyncClientAwareResponse<IdAware>>;
  @Input()
  private projectAwareResults?: Array<LocalSyncProjectAwareResponse<IdAware>>;

  public syncConflicts = new Array<SyncConflict<any>>();

  constructor(private platform: Platform, private projectDataService: ProjectDataService) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.iniData();
  }

  ionViewWillEnter() {
    this.iniData();
  }

  private iniData() {
    this.syncConflicts = new Array<SyncConflict<any>>();
    this.syncConflicts = this.syncConflicts.concat(this.extractConflicts(this.nonClientAwareResult));
    if (this.clientAwareResults?.length) {
      for (const clientAwareResult of this.clientAwareResults) {
        this.syncConflicts = this.syncConflicts.concat(this.extractConflicts(clientAwareResult));
      }
    }
    if (this.projectAwareResults?.length) {
      for (const projectAwareResult of this.projectAwareResults) {
        this.syncConflicts = this.syncConflicts.concat(this.extractConflicts(projectAwareResult));
      }
    }
    this.syncConflicts = _.sortBy(this.syncConflicts, ['resolved', 'type', 'storageKey']);
  }

  private extractConflicts(syncResponse: LocalSyncNonClientAwareResponse<IdAware> | LocalSyncClientAwareResponse<IdAware> | LocalSyncProjectAwareResponse<IdAware> | undefined):
    Array<SyncConflict<any>> {
    let conflicts = new Array<SyncConflict<any>>();
    if (syncResponse && syncResponse.conflict) {
      for (const dataKey of Object.keys(syncResponse.data)) {
        if (syncResponse.data[dataKey].conflicts && syncResponse.data[dataKey].conflicts.length) {
          conflicts = conflicts.concat(syncResponse.data[dataKey].conflicts);
        }
      }
    }
    return conflicts;
  }

  public async close() {
    await this.modal.dismiss();
  }

  public protocolRouterLink(protocolId: IdType) {
    return getProtocolEntryPagePath(protocolId, 'first');
  }

  isScreenLg(): boolean {
    return this.platform.width() >= 992;
  }

  async onProtocolNavigation(projectId: IdType) {
    await this.projectDataService.setCurrentProjectId(projectId);
  }
}
