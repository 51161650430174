import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-buying-workflow-candu',
  templateUrl: './buying-workflow-candu.component.html',
  styleUrls: ['./buying-workflow-candu.component.scss'],
})
export class BuyingWorkflowCanduComponent {

  @Input()
  annual = true;

  modal: HTMLIonModalElement;

  async dismissModal() {
    await this.modal.dismiss();
  }
}
