import { ProtocolEntry } from 'submodules/baumaster-v2-common';
// Base height + margin + 2 * border width
export const PROTOCOL_ENTRY_DEFAULT_HEIGHT = 132 + 16 + 2 * 1;
const COMPANY_TEXT_HEIGHT = 22;
const SUBENTRIES_MARGIN = 0;

export function getSubEntriesTotalHeight(subEntries: ProtocolEntry[]): number {
    const subentryHeight = 0;
    let totalHeight = subEntries?.length * (PROTOCOL_ENTRY_DEFAULT_HEIGHT + subentryHeight);
    subEntries.forEach((protocolEntry: ProtocolEntry) => {
        if (protocolEntry?.allCompanies || protocolEntry?.companyId) {
            totalHeight += COMPANY_TEXT_HEIGHT;
        }
    });
    return totalHeight + SUBENTRIES_MARGIN;
}

export function getProtocolEntryTotalHeight(protocolEntry: ProtocolEntry, isShort: boolean = false): number {
    let totalHeight = PROTOCOL_ENTRY_DEFAULT_HEIGHT;
    if ((protocolEntry?.allCompanies || protocolEntry?.companyId) && !isShort) {
        totalHeight += COMPANY_TEXT_HEIGHT;
    }
    return totalHeight;
}
