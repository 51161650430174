import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-pdf-plan-folder-title',
  templateUrl: './pdf-plan-folder-title.component.html',
  styleUrls: ['./pdf-plan-folder-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfPlanFolderTitleComponent {

  @Input()
  name: string;

  @Input()
  icon?: string;

}
