<form class="omg-form omg-boundary" [formGroup]="protocolForm" action="#">
  <ion-grid class="grid-gap-4">
    <ion-row>
      <ion-col size="12" sizeMd="11">
        <ion-item>
          <ion-label position="floating" class="omg-required"><fa-icon [icon]="['fal', 'tag']"></fa-icon> {{ 'ProtocolType' | translate }}</ion-label>
          <app-selectable
              #typeSelectable
              [class.look-disabled]="!protocolTypeEnabled"
              class="app-protocol-form-type"
              formControlName="type"
              [items]="filteredSelectableProtocolTypes"
              [allItems]="filteredAllSelectedProtocolTypes"
              (onChange)="protocolTypeChanged($event)"
              itemValueField="id"
              itemTextField="name"
              [isEnabled]="enabledForm && protocolTypeEnabled"
              [allowCreateNew]="allowCreatedProjectSettings$ | async"
              [allowEmptyValue]="false"
              [createNewFunction]="createNewProtocolTypeFunction"
              [assignToProjectFunction]="assignProtocolTypeToProjectFunction"
              [messageString]="'selectable.protocol' | translate"
              [itemTemplate]="protocolTypeItemTemplate"
              [valueTemplate]="protocolTypeValueTemplate"
              [disableDefaultOnSearch]="true"
              (onSearch)="searchTypes($event)"
          >
            <ng-template #protocolTypeItemTemplate let-protocolType="item">
              <span>{{protocolType.code + ' - ' + protocolType.name + ' | '}}</span> <span class="ml-2 omg-f-italic text-secondary">{{(protocolType.layoutId | protocolLayoutName | async)}}</span>
            </ng-template>
            <ng-template #protocolTypeValueTemplate let-protocolType="value">
              {{protocolType.code + ' - ' + protocolType.name + ' | ' + (protocolType.layoutId | protocolLayoutName | async)}}
            </ng-template>
          </app-selectable>
          <span *ngIf="protocolForm.controls.type.errors?.openContinuousProtocolExists && !protocol" class="error-message"> {{'protocol.validationMessage.openContinuousProtocolExists' | translate}} </span>
          <span *ngIf="protocolForm.controls.type.errors?.unsupportedProtocolType" class="error-message"> {{protocolForm.controls.type.errors?.message}} </span>
        </ion-item>
        <span class="omg-f-italic text-secondary" *ngIf="protocolTypeMessage" > {{protocolTypeMessage}} </span>
      </ion-col>
      <ion-col 
        appInfoPopoverClick
        [infoTitle]="'infoPopover.protocol.title'"
        [infoText]="'infoPopover.protocol.text'"
        [infoHintText]="'infoPopover.protocol.hintText'"
        *ngIf="isAboveBreakpointMd | async"
        size="1"
        class="d-flex ion-align-items-center ion-justify-content-center info-icon">
        <fa-icon [icon]="['fal', 'info-circle']"></fa-icon>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="6">
        <ion-item [disabled]="!enabledForm">
          <ion-input label="{{ 'protocol.name' | translate }}" label-placement="floating" #inputName type="text" formControlName="name" maxLength="50" class="app-protocol-form-name omg-required" [class.look-disabled]="!enabledForm"></ion-input>
        </ion-item>
        <span *ngIf="protocolForm.controls.name.errors?.nameSystemReserved && protocolForm.controls.name.dirty" class="error-message">  {{ 'formValidation.nameSystemReserved' | translate }} </span>
        <span *ngIf="protocolForm.controls.name.errors?.required && protocolForm.dirty" class="error-message"> {{ 'formValidation.emptyField' | translate:{field: 'protocol.name' | translate} }}</span>
      </ion-col>
      <ion-col size="6">
        <ion-item [disabled]="!enabledForm">
          <ion-input label="{{ 'protocol.number' | translate }}" label-placement="floating" (keypress)="numberOnlyValidation($event)" #inputNumber type="number" formControlName="number" maxLength="10" [class.look-disabled]="!enabledForm"></ion-input>
        </ion-item>
        <span *ngIf="protocolForm.controls.number.errors?.numberAlreadyUsed && protocolForm.controls.number.dirty" class="error-message">  {{ 'formValidation.numberAlreadyUsed' | translate }} </span>
        <span *ngIf="protocolForm.controls.number.errors?.notFirstOfContinuous && protocolForm.controls.number.dirty" class="error-message">  {{ 'formValidation.notFirstOfContinuous' | translate }} </span>
        <span *ngIf="(protocolForm.controls.number.errors?.pattern || protocolForm.controls.number.errors?.max) 
          && protocolForm.controls.number.dirty" class="error-message"> {{ 'formValidation.numberTooSmall' | translate }} </span>
        <span *ngIf="protocolForm.controls.number.errors?.required && protocolForm.controls.number.dirty" class="error-message"> {{ 'formValidation.emptyField' | translate:{field: 'protocol.number' | translate} }}</span>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="6">
        <ion-item>
          <ion-label position="floating"><fa-icon [icon]="['fal', 'layer-group']"></fa-icon> {{ 'sort_entries_by' | translate }}</ion-label>
          <ionic-selectable #sortEntriesByIonicSelectable
            item-content
            [items]="protocolSortEntries"
            itemValueField="uniqueId"
            itemTextField="label"
            [isEnabled]="enabledForm"
            [class.look-disabled]="!enabledForm"
            formControlName="sortEntriesBy"
            [canSearch]="true"
            searchPlaceholder="{{'search' | translate}}"
            searchFailText="{{'noItemsFound' | translate}}"
            appSelectableCommon
            #fs="appSelectableCommon"
            [shouldFocusSearchbar]="fs.isDesktop"
            (onOpen)="onOpen($event)"
            (onClose)="onClose($event)">
            <ng-template ionicSelectableItemIconTemplate let-selected="isItemSelected">
              <app-selectable-item-icon [selected]="selected"></app-selectable-item-icon>
            </ng-template>
            <ng-template ionicSelectableValueTemplate let-value="value">
              <app-selectable-value [value]="value"></app-selectable-value>
            </ng-template>
            <ng-template ionicSelectableHeaderTemplate>
              <app-selectable-header></app-selectable-header>
            </ng-template>
            <ng-template ionicSelectableFooterTemplate>
              <app-selectable-footer></app-selectable-footer>
            </ng-template>
          </ionic-selectable>
        </ion-item>
      </ion-col>
      <ion-col size="6" class="ion-align-items-center d-flex">
        <ion-item class="omg omg-form-checkbox" [disabled]="!enabledForm">
         <ion-checkbox [class.look-disabled]="!enabledForm" label-placement="end" justify="start" appSlimToggle formControlName="includesVat">
           {{ 'protocol.includesVat' | translate }}
         </ion-checkbox>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-accordion-group [multiple]="true" [value]="['place', 'weather', 'unit', 'defaultValue']" class="mt-2">
      <ion-accordion toggleIconSlot="start" [value]="isNativeApp ? 'placeInactive' : 'place'" class="section">
        <ion-item slot="header" lines="none">
          <ion-label>{{ "protocol.placeTime" | translate }}</ion-label>
        </ion-item>
        <div slot="content">
          <ion-row>
            <ion-col size="12" >
              <ion-item [disabled]="!enabledForm">
                <ion-input label="{{ 'protocol.date' | translate }}" label-placement="floating"
                           [class.look-disabled]="!enabledForm"
                           #dateDatepicker="mobiscroll"
                           formControlName="date"
                           [dateFormat]="MBSC_DATE_FORMAT"
                           [showWeekNumbers]="true"
                           [firstSelectDay]="1"
                           [firstDay]="1"
                           [setText]="'button.apply' | translate"
                           [cancelText]="'cancel' | translate"
                           [buttons]="[ 'cancel', {text: 'reset_short' | translate, handler: resetDate}, 'set' ]"
                           [locale]="mbscLocale$ | async"
                           [themeVariant]="mbscThemeVariant$ | async"
                           theme="windows"
                           mbsc-datepicker [controls]="['calendar']"></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
    
          <ion-row>
            <ion-col>
              <ion-item [disabled]="!enabledForm">
                <ion-input label-placement="floating"
                           #timeFromDatePicker="mobiscroll"
                           (onChange)="protocolForm.get('timeUntil').reset(); timeUntilMinTime=protocolForm.get('timeFrom').value"
                           (onClose)="closeCalender()"
                           formControlName="timeFrom"
                           [timeFormat]="MBSC_TIME_FORMAT"
                           [setText]="'button.apply' | translate"
                           [cancelText]="'cancel' | translate"
                           [buttons]="[ 'cancel', {text: 'reset_short' | translate, handler: resetTimeFrom}, 'set' ]"
                           [locale]="mbscLocale$ | async"
                           [themeVariant]="mbscThemeVariant$ | async"
                           theme="windows"
                           [stepMinute]="5"
                           layout="liquid"
                           mbsc-datepicker [controls]="['time']">
                  <div slot="label"><fa-icon [icon]="['fal', 'alarm-clock']"></fa-icon> {{ 'protocol.timeFrom' | translate }}</div>
                </ion-input>
              </ion-item>
            </ion-col>
            <ion-col>
              <ion-item [disabled]="!enabledForm">
                <ion-input label-placement="floating"
                           #timeUntilDatePicker="mobiscroll"
                           (onClose)="closeCalender()"
                           formControlName="timeUntil"
                           [timeFormat]="MBSC_TIME_FORMAT"
                           [setText]="'button.apply' | translate"
                           [cancelText]="'cancel' | translate"
                           [buttons]="[ 'cancel', {text: 'reset_short' | translate, handler: resetTimeUntil}, 'set' ]"
                           [locale]="mbscLocale$ | async"
                           [themeVariant]="mbscThemeVariant$ | async"
                           theme="windows"
                           (onOpen)="timeUntilMinTime=protocolForm.get('timeFrom').value"
                           [min]="timeUntilMinTime"
                           [stepMinute]="5"
                           mbsc-datepicker [controls]="['time']">
                  <div slot="label"><fa-icon [icon]="['fal', 'alarm-clock']"></fa-icon> {{ 'protocol.timeUntil' | translate }}</div>
                </ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
    
          <ion-row>
            <ion-col>
              <ion-radio-group class="d-flex" formControlName="selectedLocation" (ngModelChange)="onLocationChange($event)" [ngModel]="selectedLocation">
                <ion-row class="ion-no-margin ion-no-padding">
                  <ion-col sizeSm="auto" size="12" class="ion-no-margin ion-no-padding" *ngFor="let location of protocolLocations">
                    <ion-item lines="none">
                      <ion-radio (click)="focusLocationOnOther(location)" labelPlacement="end" justify="start" [disabled]="!enabledForm" class="radio-margin ion-no-margin" [value]="location">
                        {{ location?.label }}
                      </ion-radio>
                    </ion-item>
                  </ion-col>
                </ion-row>
              </ion-radio-group>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="12" *ngIf="showLocationInputText">
              <ion-item [disabled]="!enabledForm" >
                <ion-input #inputLocation label="{{ locationInputLabel }}" label-placement="floating" [placeholder]="locationInputPlaceholder" [readonly]="readOnlyLocationInputText" type="text" formControlName="location" maxLength="256"></ion-input>
              </ion-item> 
            </ion-col>
          </ion-row>
        </div>
      </ion-accordion>

      <ion-accordion toggleIconSlot="start" [value]="isNativeApp ? 'weatherInactive' : 'weather'" class="section mt-2">
        <ion-item slot="header" lines="none">
          <ion-label>{{ "protocol.weather" | translate }}</ion-label>
        </ion-item>
        <div slot="content">    
          <ion-row>
            <ion-col class="ion-align-items-center d-flex">
              <ion-item class="omg omg-form-checkbox" [disabled]="!enabledForm">
                <ion-checkbox [class.look-disabled]="!enabledForm" labelPlacement="end" justify="start" formControlName="weatherActivated" appSlimToggle>
                  {{ 'protocol.enableWeather' | translate }}
                </ion-checkbox>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col [hidden]="!protocolForm.get('weatherActivated').value">
              <app-protocol-form-weather [protocolForm]="protocolForm" (invalidProjectAddress)="invalidProjectAddress()" (invalidOfficeAddress)="invalidOfficeAddress()"></app-protocol-form-weather>
            </ion-col>
          </ion-row>
        </div>
      </ion-accordion>

      <ion-accordion *ngIf="showDefaultValues" toggleIconSlot="start" [value]="'defaultValue'" class="section mt-2">
        <ion-item slot="header" lines="none">
          <ion-label>{{ "protocol.defaultValues.title" | translate }}</ion-label>
        </ion-item>
        <div slot="content">    
          <ion-row>
            <ion-col>
              <app-protocol-form-default-value [protocol]="protocol" [readonly]="!enabledForm" [form]="protocolForm.get('defaultValues')"></app-protocol-form-default-value>
            </ion-col>
          </ion-row>
        </div>
      </ion-accordion>

      <ion-accordion toggleIconSlot="start" [value]="'unit'" *ngIf="isUnitFeatureEnabled && isProtocolLayoutStandard" class="section mt-2">
        <ion-item slot="header" lines="none">
          <ion-label>{{ "protocol.unit.sectionTitle" | translate }}
            <span
                appInfoPopoverClick
                [stopPropagation]="true"
                [infoTitle]="'protocol.unit.infoPopover.title'"
                [infoText]="'protocol.unit.infoPopover.text'"
                [infoHintText]="'protocol.unit.infoPopover.hintText'"
                class="info-icon ion-padding-horizontal">
            <fa-icon [icon]="['fal', 'info-circle']"></fa-icon>
          </span>
          </ion-label>
        </ion-item>
        <div slot="content">
          <ion-row>
            <ion-col class="ion-align-items-center d-flex">
              <ion-item [disabled]="!enabledForm" class="unit-field-item" [class.item-has-value]="!!us.value()" (click)="us.chooseUnit()">
                <ion-label position="floating">
                  <fa-icon [fixedWidth]="true" [icon]="['fal','map']"></fa-icon> {{ 'protocol.unit.unit' | translate }}
                </ion-label>
                <div class="ionic-selectable-label-floating">
                  <div
                    class="unit-field-label"
                    formControlName="unit"
                    appUnitSelector
                    #us="appUnitSelector"
                    [selectorUnits]="units$ | async"
                    [selectorUnitLevels]="unitLevels$ | async"
                    [idOnly]="false"
                  >
                    <bdi>{{us.value()?.breadcrumbsName}}</bdi>
                  </div>
                  <div class="ionic-selectable-icon">
                    <div class="ionic-selectable-icon-inner"></div>
                  </div>
                </div>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="ion-align-items-center d-flex">
              <ion-item class="omg omg-form-checkbox" [disabled]="!enabledForm || !protocolForm.controls.unit.value ">
                <ion-checkbox [class.look-disabled]="!enabledForm" labelPlacement="end" justify="start" formControlName="isUnitEntryDefault" appSlimToggle>
                  {{ 'protocol.unit.isUnitEntryDefault' | translate }}
                </ion-checkbox>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="ion-align-items-center d-flex">
              <div class="omg omg-t-body-bold pt-3" [class.look-disabled]="!enabledForm">{{'protocol.unit.profileAddressHeader' | translate}}</div>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <div *ngIf="unitProfileAddresses?.length === 0" class="text-secondary">
                {{'protocol.unit.noProfileAddresses' | translate}}
              </div>
              <div *ngFor="let unitProfileAddress of unitProfileAddresses">
                <span class="ion-padding-end"><fa-icon [icon]="['fal', 'user']"></fa-icon></span>
                {{unitProfileAddress?.address?.firstName}} {{unitProfileAddress?.address?.lastName}} {{unitProfileAddress?.unitProfile?.name ? '(' + unitProfileAddress?.unitProfile?.name + ')' : ''}}
              </div>
            </ion-col>
          </ion-row>
        </div>
      </ion-accordion>
    </ion-accordion-group>
  </ion-grid>
</form>
