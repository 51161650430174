<app-modal-header [dismissModalOnClose]="false" (close)="dismissModal()">
  <app-modal-step-indicator
    [stepsCount]="workflowStepConfigs.length"
    [current]="currentStepIndex"
    [workflowName]="'buyingWorkflow.workflowName' | translate"
    [workflowStep]="('buyingWorkflow.' + currentStep) | translate"
  ></app-modal-step-indicator>
</app-modal-header>

<ion-content class="with-padding">
  <ion-grid *ngIf="currentStep === 'LICENSE_PAGE'" class="with-padding">
    <ion-row>
      <ion-col size="12" class="d-flex ion-justify-content-center ion-align-items-center ion-no-padding">
        <ion-item lines="none" class="fit-content">
          <ion-toggle
            [ngModel]="termsYearly"
            (ngModelChange)="onTermsChange($event)"
            appSlimToggle
          ></ion-toggle>
          <ion-label>{{ termsYearly ? ('buyingWorkflow.yearly' | translate) : ('buyingWorkflow.monthly' | translate) }}</ion-label>
        </ion-item>
        <ion-label *ngIf="termsYearly" class="brandLabel">{{ 'buyingWorkflow.discountInfo' | translate:{discount} }}</ion-label>
      </ion-col>
      <ion-col size="12" class="d-flex ion-justify-content-center ion-align-items-center ion-no-padding">
        <ion-label class="text-secondary">{{'buyingWorkflow.chooseAmountText' | translate}}</ion-label>
      </ion-col>
    </ion-row>
    <form class="omg-form" [formGroup]="licenseDetailsForm" action="#">
      <ion-row class="pt-4">
        <ion-col size="7">
          <ion-label class="license-label">{{ 'buyingWorkflow.smart' | translate:{price: termsYearly ? zohoProducts?.smartY?.price : zohoProducts?.smartM?.price} }}</ion-label><ion-label *ngIf="termsYearly" class="reduced-price">{{zohoProducts?.smartM?.price}}€</ion-label>
          <fa-icon class="pl-2 cursor-pointer" [icon]="['fal', 'info-circle']" (click)="openCanduModal()"></fa-icon><br>
          <ion-label>{{ (termsYearly ? 'buyingWorkflow.perUserY' : 'buyingWorkflow.perUserM') | translate}}</ion-label>
        </ion-col>
        <ion-col size="5">
          <ion-item lines="none" class="buying-input-field">
            <ion-input [placeholder]="'buyingWorkflow.placeholderAmount' | translate" class="text-primary ion-no-padding" type="number" formControlName="amountSmart" maxlength="4"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row class="pt-4">
        <ion-col size="7">
          <ion-label class="license-label">{{ 'buyingWorkflow.expert' | translate:{price: termsYearly ? zohoProducts?.expertY?.price : zohoProducts?.expertM?.price} }}</ion-label><ion-label *ngIf="termsYearly" class="reduced-price">{{zohoProducts?.expertM?.price}}€</ion-label>
          <fa-icon class="pl-2 cursor-pointer" [icon]="['fal', 'info-circle']" (click)="openCanduModal()"></fa-icon><br>
          <ion-label>{{ (termsYearly ? 'buyingWorkflow.perUserY' : 'buyingWorkflow.perUserM')  | translate}}</ion-label>
        </ion-col>
        <ion-col size="5">
          <ion-item lines="none" class="buying-input-field">
            <ion-input [placeholder]="'buyingWorkflow.placeholderAmount' | translate" class="text-primary ion-no-padding" type="number" formControlName="amountExpert" maxlength="4"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row class="pt-4">
        <ion-col size="12" class="d-flex ion-justify-content-center ion-align-items-center">
          <ion-label class="reportInfo" *ngIf="!isAmountEntered">{{'buyingWorkflow.reportInfo' | translate}}</ion-label>
        </ion-col>
      </ion-row>
      <ion-row class="pt-1">
        <ion-col size="7">
          <ion-label class="license-label" [class.text-secondary]="!isAmountEntered">{{ 'buyingWorkflow.report' | translate:{price: zohoProducts?.tagebM?.price} }}</ion-label><br>
          <ion-label [class.text-secondary]="!isAmountEntered">{{ (termsYearly ? 'buyingWorkflow.perUserY' : 'buyingWorkflow.perUserM')  | translate}}</ion-label>
        </ion-col>
        <ion-col size="5">
          <ion-item lines="none" class="buying-input-field">
            <ion-input [disabled]="!isAmountEntered" [placeholder]="'buyingWorkflow.placeholderAmount' | translate" class="text-primary ion-no-padding" type="number" formControlName="amountReport" maxlength="4"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="termsYearly" class="pt-4">
        <ion-col size="7">
          <ion-label>{{'buyingWorkflow.couponCode' | translate}}</ion-label>
        </ion-col>
        <ion-col size="5">
          <ion-item lines="none" class="buying-input-field" [class.invalid]="licenseDetailsForm.controls.couponCode.invalid">
            <ion-input [placeholder]="'buyingWorkflow.placeholderCoupon' | translate" class="text-primary ion-no-padding" type="text" formControlName="couponCode" maxlength="20"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row class="pt-4">
        <ion-col size="12">
          <ion-label class="buyingInfo">{{'buyingWorkflow.buyingInfo' | translate}}</ion-label>
        </ion-col>
      </ion-row>
    </form>
  </ion-grid>
  <ion-grid *ngIf="currentStep === 'CONTACT_DETAILS'">
    <form class="omg-form" [formGroup]="contactDetailsForm" action="#">
      <ion-row>
        <ion-col size="12">
          <div class="omg-modal-header">
            {{ 'buyingWorkflow.CONTACT_DETAILS' | translate }}
          </div>
        </ion-col>
        <ion-col size="6">
          <ion-item>
            <ion-input label="{{ 'contactForm.firstName' | translate }}" label-placement="floating" class="text-primary omg-required" type="text" formControlName="firstName" maxlength="50"></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size="6">
          <ion-item>
            <ion-input label="{{ 'contactForm.lastName' | translate }}" label-placement="floating" class="text-primary omg-required"  type="text" formControlName="lastName" maxlength="50"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="6">
          <ion-item>
            <ion-input label="{{ 'buyingWorkflow.email' | translate }}" label-placement="floating" class="text-primary omg-required" type="text" formControlName="email" maxlength="254"></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size="6">
          <ion-item>
            <ion-input label="{{ 'companyForm.address.phone' | translate }}" label-placement="floating" class="text-primary omg-required" type="text" formControlName="phone" maxlength="25"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <ion-item>
            <ion-input label="{{ 'connectedUserInvite.confirm.companyName' | translate }}" label-placement="floating" class="text-primary omg-required" type="text" formControlName="companyName" maxlength="101"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <ion-item>
            <ion-input label="{{ 'clientDataSettings.vatNumber' | translate }}" label-placement="floating" class="text-primary" type="text" formControlName="uidNumber" maxlength="35"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <ion-label class="vatInfo">{{ 'buyingWorkflow.vatInfo' | translate }}</ion-label>
        </ion-col>
      </ion-row>
    </form>
  </ion-grid>
  <ion-grid *ngIf="currentStep === 'ADDRESS_DETAILS'">
    <form class="omg-form" [formGroup]="addressDetailsForm" action="#">
      <ion-row>
        <ion-col size="12">
          <div class="omg-modal-header">
            {{ 'buyingWorkflow.ADDRESS_DETAILS' | translate }}
          </div>
        </ion-col>
        <ion-col size="9">
          <ion-item>
            <ion-input label="{{ 'companyForm.address.street1' | translate }}" label-placement="floating" class="text-primary omg-required" type="text" formControlName="street" maxlength="60"></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size="3">
          <ion-item>
            <ion-input label="{{ 'companyForm.address.street2' | translate }}" label-placement="floating" class="text-primary omg-required" type="text" formControlName="number" maxlength="60"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <ion-item>
            <ion-input label="{{ 'companyForm.address.zipCode' | translate }}" label-placement="floating" class="text-primary omg-required" type="text" formControlName="zip" maxlength="10"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <ion-item>
            <ion-input label="{{ 'companyForm.address.city' | translate }}" label-placement="floating" class="text-primary omg-required"  type="text" formControlName="city" maxlength="60"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <ion-item>
            <ion-label position="floating" class="text-primary omg-required">{{ 'clientDataSettings.country' | translate }}</ion-label>
            <ionic-selectable #countrySelectable
              item-content
              class="text-primary"
              [items]="countries$ | async"
              formControlName="country"
              itemValueField="id"
              itemTextField="name"
              searchPlaceholder="{{'search' | translate}}"
              searchFailText="{{'noItemsFound' | translate}}"
              [canSearch]="true"
              [className]="'protocol-type-selector'"
              (onOpen)="onOpen($event)"
              (onClose)="onClose($event)"
              appSelectableCommon
              #fs="appSelectableCommon"
              [shouldFocusSearchbar]="fs.isDesktop">
              <ng-template ionicSelectableItemIconTemplate let-selected="isItemSelected">
                <app-selectable-item-icon [selected]="selected"></app-selectable-item-icon>
              </ng-template>
              <ng-template ionicSelectableValueTemplate let-value="value">
                <app-selectable-value [value]="value"></app-selectable-value>
              </ng-template>
              <ng-template ionicSelectableHeaderTemplate>
                <app-selectable-header></app-selectable-header>
              </ng-template>
              <ng-template ionicSelectableFooterTemplate>
                <app-selectable-footer></app-selectable-footer>
              </ng-template>
            </ionic-selectable>
          </ion-item>
        </ion-col>
      </ion-row>
    </form>
  </ion-grid>
</ion-content>


<app-modal-footer>
  <ion-buttons slot="start">
    <ion-button *ngIf="currentWorkflowStepConfig.previousStep !== null" class="omg-btn-secondary" (click)="back()">{{'back' | translate}}</ion-button>
  </ion-buttons>

  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" (click)="dismissModal()">{{'button.cancel' | translate}}</ion-button>
    <ion-button *ngIf="currentWorkflowStepConfig.currentStep === 'LICENSE_PAGE'"[disabled]="!licenseDetailsForm.valid" class="omg-btn-primary" (click)="next()">{{'next' | translate}}</ion-button>
    <ion-button *ngIf="currentWorkflowStepConfig.currentStep === 'CONTACT_DETAILS'" [disabled]="!contactDetailsForm.valid || checkingVat" class="omg-btn-primary" (click)="next()">{{'next' | translate}}
      <ion-spinner slot="start" class="spinner-center-button" *ngIf="checkingVat"></ion-spinner>
    </ion-button>
    <ion-button *ngIf="currentWorkflowStepConfig.currentStep === 'ADDRESS_DETAILS'" [disabled]="!addressDetailsForm.valid" class="omg-btn-primary" (click)="getHostedPage()">{{'buyingWorkflow.toPayment' | translate}}
      <ion-spinner slot="start" class="spinner-center-button" *ngIf="preparingHostedPage"></ion-spinner>
    </ion-button>
  </ion-buttons>

</app-modal-footer>
