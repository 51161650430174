import {TemplatePortal} from '@angular/cdk/portal';
import {AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef, ViewChild, ViewContainerRef, computed, signal} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {IonSearchbar, IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {AutoSizeVirtualScrollStrategy, RxVirtualFor, RxVirtualScrollViewportComponent} from '@rx-angular/template/experimental/virtual-scrolling';
import {compact, maxBy} from 'lodash';
import {DeviceService} from 'src/app/services/ui/device.service';
import {IdType} from 'submodules/baumaster-v2-common';
import {SelectorUnitWithPathAndLevel, SelectorUnitWithPathAndLevelAndSelected, UnitSelectorModalFooterContext} from '../unit-selector-model';

export interface UnitSelectEvent {
  unit: SelectorUnitWithPathAndLevel;
}

@Component({
  selector: 'app-unit-selector-list',
  templateUrl: './unit-selector-list.component.html',
  styleUrls: ['./unit-selector-list.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    RxVirtualFor,
    RxVirtualScrollViewportComponent,
    AutoSizeVirtualScrollStrategy,
    FontAwesomeModule,
    TranslateModule,
    FormsModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnitSelectorListComponent implements AfterViewInit {

  @Input()
  selectorUnitsWithPathAndLevel: SelectorUnitWithPathAndLevel[] = [];

  @ViewChild('autofocus', { static: false }) searchbar: IonSearchbar;

  protected _selectedUnitByLevel = signal<Record<IdType, SelectorUnitWithPathAndLevel|undefined>>({});
  
  @Input()
  set selectedUnitByLevel(selectedUnitByLevel: Record<IdType, SelectorUnitWithPathAndLevel|undefined>) { this._selectedUnitByLevel.set(selectedUnitByLevel); }
  get selectedUnitByLevel(): Record<IdType, SelectorUnitWithPathAndLevel|undefined> { return this._selectedUnitByLevel(); }

  @Input()
  canReset = false;
  
  @ViewChild('toolbar', {read: TemplateRef})
  toolbar: TemplateRef<{}>;

  @ViewChild('footer', {read: TemplateRef})
  footer: TemplateRef<UnitSelectorModalFooterContext>;

  @Output()
  toolbarPortalSet = new EventEmitter<TemplatePortal<unknown>>();

  @Output()
  footerPortalSet = new EventEmitter<TemplatePortal<UnitSelectorModalFooterContext>>();

  @Output()
  unitSelect = new EventEmitter<UnitSelectEvent>();

  @Output()
  clearClick = new EventEmitter<void>();

  protected searchText = signal<string>('');
  protected selectedUnit = computed<SelectorUnitWithPathAndLevel|undefined>(() => maxBy(compact(Object.values(this.selectedUnitByLevel)), 'level.index'));
  protected filteredSelectorUnitsWithPathAndLevel = computed<SelectorUnitWithPathAndLevelAndSelected[]>(() => {
    return this.selectorUnitsWithPathAndLevel.filter((unit) => unit.name.toLocaleLowerCase().includes(this.searchText().toLocaleLowerCase())).map((u) => ({
      ...u,
      isSelected: this.selectedUnit()?.id === u.id,
    }));
  });

  constructor(private viewContainerRef: ViewContainerRef, private deviceService: DeviceService) { }

  ngAfterViewInit() {
    this.toolbarPortalSet.emit(new TemplatePortal(this.toolbar, this.viewContainerRef));
    this.footerPortalSet.emit(new TemplatePortal(this.footer, this.viewContainerRef));
    if (this.deviceService.isDesktop()) {
      this.searchbar?.setFocus();
    }
  }

  selectUnit(unit: SelectorUnitWithPathAndLevel) {
    this.unitSelect.emit({unit});
  }

}
