<app-modal-header>
  <ion-title>{{'pdfPlanMarker.selectPlan' | translate}}</ion-title>
</app-modal-header>

<ion-content>
  <ion-list lines="none" class="omg-boundary project-list">
    <ion-item-divider class="with-line">
      {{'all' | translate}}
    </ion-item-divider>
    <h3 class="ion-padding" *ngIf="this.bimVersions && this.bimVersions.length === 0"> {{ 'pdfPlanMarker.no_files_found' | translate  }} </h3>
    <div *ngIf="this.bimVersions?.length">
      <div class="with-line"></div>
      <ion-radio-group (ngModelChange)="openBimViewerMarker($event)" [ngModel]="selectedBimVersion">
        <ion-item lines="none" *ngFor="let bim of bimVersions">
          <ion-radio label-placement="end" justify="start" [value]="bim">
            {{ bim | bimVersionName }}
          </ion-radio>
        </ion-item>
      </ion-radio-group>
    </div>
  </ion-list>
</ion-content>
  