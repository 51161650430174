<app-modal-header>
  <ion-title>{{'syncMissingAttachments.title' | translate}}</ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <div>
    <ion-radio-group (ngModelChange)="onOnlyCurrentProject($event)" [ngModel]="onlyCurrentProject" [ngModelOptions]="{standalone: true}">
      <ion-item class="omg omg-form-checkbox pb-1" lines="none">
        <ion-radio [disabled]="loading || syncing || anySyncInProgress" label-placement="end" justify="start" [value]="true">{{ 'syncMissingAttachments.onlyCurrentProject' | translate }} ({{currentProject?.name}})</ion-radio>
      </ion-item>
      <ion-item class="omg omg-form-checkbox pt-1" lines="none">
        <ion-radio [disabled]="loading || syncing || anySyncInProgress" label-placement="end" justify="start" [value]="false">{{ 'syncMissingAttachments.allProjects' | translate }}</ion-radio>
      </ion-item>
    </ion-radio-group>
  </div>
  <div *ngIf="anySyncInProgress">
    <ion-spinner></ion-spinner>{{'syncMissingAttachments.syncInProgress' | translate}}
  </div>
  <div *ngIf="loading">
    <ion-spinner></ion-spinner>{{'syncMissingAttachments.loading' | translate}}
  </div>
  <div *ngIf="syncing">
    <ion-spinner></ion-spinner>{{'syncMissingAttachments.syncing' | translate}}
  </div>
  <div *ngIf="!loading && attachmentsMissing && attachmentsMissing.length === 0">
    {{'syncMissingAttachments.nothingToSync' | translate}}
  </div>
  <div *ngIf="!loading && attachmentsMissing && attachmentsMissing.length > 0" class="warning">
    {{'syncMissingAttachments.syncNecessary' | translate: {count: attachmentsMissing.length} }}
  </div>
  <div *ngIf="errorMessage" class="error-message">
    {{'syncMissingAttachments.error' | translate: {errorMessage: errorMessage} }}
  </div>
</ion-content>


<app-modal-footer>
  <ion-buttons slot="start">
    <ion-button class="omg-btn-secondary" (click)="loadMissing()" [disabled]="loading || syncing || anySyncInProgress">
      {{'refresh' | translate}}
    </ion-button>
  </ion-buttons>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" (click)="cancel()">
      {{'cancel' | translate}}
    </ion-button>
    <ion-button class="omg-btn-primary" (click)="startSync()" [disabled]="loading || syncing || anySyncInProgress || !readyToSync">
      {{'syncMissingAttachments.sync' | translate}}
    </ion-button>
  </ion-buttons>
</app-modal-footer>
