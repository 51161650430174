import {Injectable} from '@angular/core';
import lodash from 'lodash';
import {combineLatest, from, Observable} from 'rxjs';
import {debounceTime, delayWhen, map} from 'rxjs/operators';
import {ProjectProtocolAndEntry} from 'src/app/components/search/search-results/search-results.component';
import {ProjectCostsDetails} from 'src/app/model/project-costs';
import {ProtocolEntrySearchFilter} from 'src/app/model/protocol-entry-search-filter';
import {observableToPromise} from 'src/app/utils/async-utils';
import {execSearchFilter, protocolSearchFilterFactory} from 'src/app/utils/filter-utils';
import {
  IdType,
  Project,
  Protocol,
  PROTOCOL_LAYOUT_NAME_CONTINUOUS,
  PROTOCOL_LAYOUT_NAME_SHORT,
  ProtocolEntry,
  ProtocolEntryCompany,
  ProtocolEntryType,
  ProtocolLayout,
  ProtocolOpenEntry,
  ProtocolType,
  ProtocolEntryChat
} from 'submodules/baumaster-v2-common';
import {CompanyDataService} from '../data/company-data.service';
import {CraftDataService} from '../data/craft-data.service';
import {ProjectDataService} from '../data/project-data.service';
import {ProtocolDataService} from '../data/protocol-data.service';
import {ProtocolEntryCompanyDataService} from '../data/protocol-entry-company-data.service';
import {ProtocolEntryDataService} from '../data/protocol-entry-data.service';
import {ProtocolEntryTypeDataService} from '../data/protocol-entry-type-data.service';
import {ProtocolLayoutDataService} from '../data/protocol-layout-data.service';
import {ProtocolOpenEntryDataService} from '../data/protocol-open-entry-data.service';
import {ProtocolTypeDataService} from '../data/protocol-type-data.service';
import {ProjectCostsService} from '../project/project-costs.service';
import {ProtocolEntryCompareFilter, ProtocolEntryFilterService, ProtocolEntryParameterFilter} from '../protocol/protocol-entry-filter.service';
import {ProtocolEntrySearchFilterService} from './protocol-entry-search-filter.service';
import {isTaskProtocol} from '../../utils/protocol-utils';
import {ProtocolEntryChatDataService} from '../data/protocol-entry-chat-data.service';

const defaultComparator = <T>(left: T, right: T) => {
  if (left < right) {
    return 1;
  } else if (left > right) {
    return -1;
  }
  return 0;
};

const comparatorsPerKey = {
  createdAt: (left: string | Date, right: Date) => defaultComparator(left ? new Date(left) : null, right),
};

@Injectable({
  providedIn: 'root'
})
export class ProtocolEntrySearchFilteredDataService {

  // Filtered protocol entries works per protocol.
  // This observable joins all projects and all protocols in projects
  // to form uniform list of filtered out protocol entries.
  protocolEntriesTree$: Observable<ProjectProtocolAndEntry[][][]> = combineLatest([
    this.protocolEntrySearchFilterService.filters$.pipe(delayWhen((filters) => from(this.applyEntryFilter(filters)))),
    this.protocolEntryFilterService.protocolEntryFilters,
    this.protocolTypeDataService.dataWithoutHiddenAcrossClients$,
    this.protocolEntryTypeDataService.dataAcrossClients$,
    this.projectDataService.dataAcrossClientsActive$,
    this.protocolDataService.dataAcrossProjects$,
    this.protocolEntryDataService.dataAcrossProjects$,
    this.protocolOpenEntryDataService.dataAcrossProjects$,
    this.protocolLayoutDataService.dataAcrossClientsGroupedById,
    this.protocolEntryCompanyDataService.dataAcrossProjectsGroupedByEntryId,
    this.protocolEntryChatDataService.dataByProtocolEntryIdAcrossProjects$,
  ])
    .pipe(debounceTime(0)) // prevent multiple calls to subscribe for each observable in combineLatest
    .pipe(map(([
      protocolEntrySearchFilter, protocolEntryFilter, protocolTypes, protocolEntryTypes, projects, protocols, protocolEntries, protocolOpenEntries, protocolLayouts, protocolEntryCompaniesByEntryId,
      protocolEntryChatsByEntryId
    ]: [
      ProtocolEntrySearchFilter, Array<ProtocolEntryParameterFilter>, Array<ProtocolType>, Array<ProtocolEntryType>, Array<Project>, Array<Protocol>,
      Array<ProtocolEntry>, Array<ProtocolOpenEntry>, Record<string, ProtocolLayout>, Record<IdType, ProtocolEntryCompany[]>, Record<IdType, ProtocolEntryChat[]>
    ]) => { // explicit type declaration due to TypeScript limitations when more than 5 params
      const protocolTypesById: Record<IdType, ProtocolType> = lodash.keyBy(protocolTypes, 'id');
      const protocolEntryTypesById: Record<IdType, ProtocolEntryType> = lodash.keyBy(protocolEntryTypes, 'id');
      const filteredProjects: Array<Project> = lodash.sortBy(projects.filter(this.applyProjectFilter(protocolEntrySearchFilter)), ['name', 'number']);
      const filteredProtocols = protocols.filter(protocolSearchFilterFactory(protocolEntrySearchFilter));
      const protocolEntriesOrOpen = this.protocolEntryDataService.convertAllToProtocolEntriesOrOpen(protocolEntries, protocolOpenEntries);
      const parentProtocolEntriesOrOpen = protocolEntriesOrOpen.filter((protocolEntryOrOpen) => !protocolEntryOrOpen.parentId);
      const childProtocolEntriesOrOpen = protocolEntriesOrOpen.filter((protocolEntryOrOpen) => !!protocolEntryOrOpen.parentId);

      const result = filteredProjects.map((project, projectIndex) => {
        const taskProtocolSorter = (protocol: Protocol) => isTaskProtocol(protocol);
        const protocolNameSorter = (protocol: Protocol) => protocol.name?.toLowerCase();
        const protocolsForProject = lodash.orderBy(filteredProtocols.filter((value) => value.projectId === project.id),
          [taskProtocolSorter, protocolNameSorter, 'number'], ['desc', 'asc', 'asc']);
        return protocolsForProject.map((protocol, protocolIndex) => {
          const parentProtocolEntriesOrOpenForProtocol = parentProtocolEntriesOrOpen.filter((protocolEntryOrOpen) =>
            protocolEntryOrOpen.isOpenEntry ?
              (protocolEntryOrOpen.protocolId === protocol.id && protocolEntryOrOpen.originalProtocolId === protocol.id)
              : protocolEntryOrOpen.protocolId === protocol.id);
          const childProtocolEntriesOrOpenForProtocol = childProtocolEntriesOrOpen.filter((protocolEntryOrOpen) =>
            protocolEntryOrOpen.isOpenEntry ?
              (protocolEntryOrOpen.protocolId === protocol.id && protocolEntryOrOpen.originalProtocolId === protocol.id)
              : protocolEntryOrOpen.protocolId === protocol.id);
          const sortedChildProtocolEntriesOrOpenForProtocol = lodash.sortBy(childProtocolEntriesOrOpenForProtocol, ['number']);
          const sortedParentProtocolEntriesOrOpenForProtocol = lodash.sortBy(parentProtocolEntriesOrOpenForProtocol, ['number']);
          const [allMatchingChildren, filteredProtocolEntries, matchingProtocolEntries] = this.protocolEntryFilterService.filterProtocolEntriesSync(protocolEntryFilter,
            sortedParentProtocolEntriesOrOpenForProtocol, protocol, protocolTypesById, protocolEntryTypesById, sortedChildProtocolEntriesOrOpenForProtocol, {
              protocolEntryCompaniesById: protocolEntryCompaniesByEntryId,
              protocolEntryChatsById: protocolEntryChatsByEntryId
            });
          return filteredProtocolEntries.map((entry, entryIndex) => {
            return {
              entry,
              entryChildren: allMatchingChildren?.filter((child) => child.parentId === entry.id) ?? [],
              project,
              protocol,
              firstInProject: protocolIndex === 0 && entryIndex === 0,
              firstInProtocol: entryIndex === 0,
              isTaskProtocol: protocol && isTaskProtocol(protocol),
              entryIndex,
              protocolIndex,
              projectIndex,
              isProtocolLayoutShort: protocolLayouts[protocolTypesById[protocol.typeId]?.layoutId]?.name === PROTOCOL_LAYOUT_NAME_SHORT,
              isProtocolLayoutContinuous: protocolLayouts[protocolTypesById[protocol.typeId]?.layoutId]?.name === PROTOCOL_LAYOUT_NAME_CONTINUOUS,
              isEntryMatched: matchingProtocolEntries.some((theEntry) => theEntry.id === entry.id),
            } as ProjectProtocolAndEntry;
          });
        });
      });

      // Then fix indexing of protocol index
      const result2 = result.map((entriesInProtocols) => entriesInProtocols.filter((v) => v.length).reduce((acc, entriesInProtocol) => {
        const newEntriesInProtocol = entriesInProtocol.map((entryInProtocol) => ({
          ...entryInProtocol,
          protocolIndex: acc.length,
          firstInProject: acc.length === 0 && entryInProtocol.entryIndex === 0,
        } as ProjectProtocolAndEntry));

        return acc.concat([newEntriesInProtocol]);
      }, [] as ProjectProtocolAndEntry[][]));

      return result2;
    }));

  protocolEntries$: Observable<Array<ProjectProtocolAndEntry>> = this.protocolEntriesTree$.pipe(
    map((tree) =>
      // Array of project, with array of protocols, with array of entries;
      tree.reduce((acc, v) => acc.concat(v), []) // Flatten projects...
        .reduce((acc, v) => acc.concat(v), []) // ...and protocols.
    )
  );

  searchProjectsCosts$: Observable<{
    entriesCount: number;
    details: {
      project: Project,
      costs: ProjectCostsDetails,
    }[];
  }> = combineLatest([
    this.protocolEntriesTree$.pipe(map((tree) => tree.map(
      (project) => project.reduce((acc, v) => acc.concat(v), [])
    ))),
    this.protocolDataService.dataAcrossProjectsGroupedById,
    this.companyDataService.dataAcrossClientsGroupedById,
    this.craftDataService.dataAcrossClientsGroupedById,
    this.protocolTypeDataService.dataWithoutHiddenAcrossClientsGroupedById$,
  ]).pipe(
    map(([
      projectsEntries,
      protocolById,
      companiesById,
      craftsById,
      protocolTypesById
    ]) => {
      return {
        entriesCount: projectsEntries.filter((projectEntries) => projectEntries.length > 0).reduce((acc, v) => acc + v.filter((e) => e.entry.cost && parseFloat(`${e.entry.cost}`) !== 0).length, 0),
        details: projectsEntries.filter((projectEntries) => projectEntries.length > 0).map((projectEntries) => {
          return {
            project: projectEntries[0].project,
            costs: this.projectCostsService.getCostsDetailsForProject(
              projectEntries[0].project,
              projectEntries.reduce((acc, entry) => {
                acc.push(...entry.entryChildren);
                if (entry.isEntryMatched) {
                  acc.push(entry.entry);
                }
                return acc;
              }, []),
              protocolById,
              projectEntries.reduce((acc, v) => ({
                ...acc,
                ...lodash.keyBy([...v.entryChildren, v.entry], 'id'),
              }), {}) as Record<IdType, ProtocolEntry>,
              companiesById,
              craftsById,
              protocolTypesById
            ),
          };
        }).filter((details) => details.costs.groups.length > 0),
      };
    })
  );

  constructor(
    private protocolEntrySearchFilterService: ProtocolEntrySearchFilterService,
    private protocolTypeDataService: ProtocolTypeDataService,
    private protocolEntryTypeDataService: ProtocolEntryTypeDataService,
    private protocolEntryFilterService: ProtocolEntryFilterService,
    private projectDataService: ProjectDataService,
    private protocolDataService: ProtocolDataService,
    private protocolEntryDataService: ProtocolEntryDataService,
    private protocolOpenEntryDataService: ProtocolOpenEntryDataService,
    private projectCostsService: ProjectCostsService,
    private companyDataService: CompanyDataService,
    private craftDataService: CraftDataService,
    private protocolLayoutDataService: ProtocolLayoutDataService,
    private protocolEntryCompanyDataService: ProtocolEntryCompanyDataService,
    private protocolEntryChatDataService: ProtocolEntryChatDataService
  ) { }

  private applyProjectFilter(filters: ProtocolEntrySearchFilter): (project: Project) => boolean {
    return (project: Project) => {
      return filters.project ? execSearchFilter(filters.project, project.id) : true;
    };
  }

  private async applyEntryFilter(filters: ProtocolEntrySearchFilter): Promise<void> {
    const entryFilters = await observableToPromise(this.protocolEntryFilterService.protocolEntryFilters);

    const otherFilters = entryFilters.filter((filter) => (filter.mode !== 'in' && filter.mode !== 'standard' && filter.mode !== 'compare') || !Object.keys(filters.entry).includes(filter.fieldName));

    await this.protocolEntryFilterService.applyFilter([
      ...otherFilters,
      ...Object.entries(filters.entry).filter(([_, filter]) => filter !== null).map(([key, filter]) => {
        if (filter.in && filter.in.length > 0) {
          return [{
            fieldName: key,
            value: filter.in,
            mode: 'in',
          } as ProtocolEntryParameterFilter];
        }
        if (filter.eq) {
          return [{
            fieldName: key,
            value: filter.eq,
            mode: 'standard',
          } as ProtocolEntryParameterFilter];
        }
        if (Object.keys(filter).some((filterKey) => ['gt', 'gte', 'lt', 'lte'].includes(filterKey))) {
          return Object.entries(filter)
            .filter(([filterKey, singleFilter]) => ['gt', 'gte', 'lt', 'lte'].includes(filterKey) && singleFilter !== null && singleFilter !== undefined)
            .map(([filterKey, singleFilter]) => ({
              fieldName: key,
              value: singleFilter,
              mode: 'compare',
              direction: filterKey,
              comparator: comparatorsPerKey[key] ? comparatorsPerKey[key] : filter.comparator,
            } as ProtocolEntryCompareFilter));
        }

        return [];
      }).reduce((acc, v) => acc.concat(v), [])
    ]);
  }
}
