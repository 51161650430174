<ion-toolbar color="menu">
  <ion-buttons>
    <ion-menu-button *ngIf="!isDesktop || !(isMenuExpandedOnScreen$ | async)"></ion-menu-button>
    <ion-button *ngIf="isDesktop && (isMenuExpandedOnScreen$ | async)" (click)="openMenu()" class="app-menu-button" (mouseenter)="hoverIcon(true)" (mouseleave)="hoverIcon(false)">
      <ion-icon *ngIf="!(sideBarSetting$ | async)?.pinned && !isHovered && isDesktop" slot="icon-only" name="menu-sharp" ios="menu-outline"></ion-icon>
      <fa-icon *ngIf="!(sideBarSetting$ | async)?.pinned && isHovered && (isMenuExpandedOnScreen$ | async) && isDesktop" [icon]="['far', 'angle-double-right']" slot="icon-only"></fa-icon>
      <fa-icon *ngIf="(sideBarSetting$ | async)?.pinned && (isMenuExpandedOnScreen$ | async) && isDesktop" [icon]="['far', 'angle-double-left']" slot="icon-only"></fa-icon>
    </ion-button>
  </ion-buttons>
</ion-toolbar>
