<app-modal-header>
  <ion-title *ngIf="!craftId"> {{ 'craftForm.createTitle' | translate }}  </ion-title>
  <ion-title *ngIf="craftId"> {{ 'craftForm.editTitle' | translate }}  </ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <form class="omg-form" [formGroup]="craftForm" action="#">
    <ion-grid class="grid-gap-4">
      <ion-row>
        <ion-col size="12">
          <ion-item>
            <input type="color" #colorPicker formControlName="color" [ngClass]="{'ios-native': isIosNative() }" />
            <div *ngIf="!isIosNative()"
              slot="start" class="d-flex ion-align-self-center cursor-pointer color-picker"
              [style.background-color]="craftForm.value.color || DEFAULT_CRAFT_COLOR"
              (click)="colorPicker.focus(); colorPicker.click()"
            ></div>
            <ion-input label="{{ 'craftForm.name' | translate }}" label-placement="floating" #inputName formControlName="name" maxLength="35" type="text" class="omg-required"></ion-input>
          </ion-item>
          <span *ngIf="craftForm.controls.name.errors?.required && craftForm.controls.name.dirty" class="error-message"> {{ 'formValidation.emptyField' | translate:{field: 'craftForm.name' | translate} }}</span>
          <span *ngIf="craftForm.controls.name.errors?.nameExist && craftForm.controls.name.dirty" class="error-message"> {{ 'craftForm.nameExist' | translate }} </span>
          <span *ngIf="craftForm.controls.name.errors?.nameExistAndInactive && craftForm.dirty && craftId" class="error-message">{{ 'craftForm.nameExistAndInactive' | translate }}</span>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="start" class="omg-form">
    <ion-item class="omg omg-form-checkbox" (click)="toggleAddToProject()" *ngIf="!craftId && (notConnected$ | async) && !createForSelectable">
       <ion-checkbox label-placement="end" justify="start" [ngModel]="addToProject">
         {{ 'craftForm.addToProject' | translate }}
       </ion-checkbox>
    </ion-item>
    <ion-button *ngIf="craftId" class="omg-btn-secondary omg-btn-color-danger" (click)="deleteCraft(craft)">
      <fa-icon slot="start" [icon]="['fal', 'trash-alt']"></fa-icon>
      <span>{{'delete' | translate}}</span>
    </ion-button>
  </ion-buttons>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" (click)="dismissModal()">{{ 'cancel' | translate }}</ion-button>
    <ion-button class="omg-btn-primary" [disabled]="!craftForm?.valid || !craftForm?.dirty || loading" (click)="saveCraft()" >
      <span *ngIf="!craftId">{{'button.create' | translate }}</span>
      <span *ngIf="craftId">{{'button.save' | translate }}</span>
    </ion-button>
  </ion-buttons>
</app-modal-footer>
