import {ChangeDetectionStrategy, Component, ContentChild, Directive, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output, TemplateRef} from '@angular/core';
import {Nullish} from 'src/app/model/nullish';
import {trackById} from 'src/app/utils/track-by-id';
import {IdType, TagBase} from 'submodules/baumaster-v2-common';
import {TagEndTemplateDirective, TagStartTemplateDirective} from '../tag/tag.component';

@Directive({
  selector: '[appTagWrapperStartTemplate]'
})
export class TagWrapperStartTemplateDirective {}

@Directive({
  selector: '[appTagWrapperEndTemplate]'
})
export class TagWrapperEndTemplateDirective {}


@Component({
  selector: 'app-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagListComponent {

  readonly trackById = trackById;

  @Input()
  tags: TagBase[];

  @Input()
  maxTags: Nullish<number>;

  @Input()
  showMoreTagsCount: Nullish<boolean>;

  @Input()
  @HostBinding('class.interactive')
  interactive: Nullish<boolean>;

  @Input()
  selectedIds?: IdType[];

  @ContentChild(TagStartTemplateDirective, {
    read: TemplateRef,
    static: true,
  })
  startTemplate: Nullish<TemplateRef<{ tag: TagBase }>>;

  @ContentChild(TagEndTemplateDirective, {
    read: TemplateRef,
    static: true,
  })
  endTemplate: Nullish<TemplateRef<{ tag: TagBase }>>;

  @ContentChild(TagWrapperStartTemplateDirective, {
    read: TemplateRef,
    static: true,
  })
  startWrapperTemplate: Nullish<TemplateRef<{ tag: TagBase }>>;

  @ContentChild(TagWrapperEndTemplateDirective, {
    read: TemplateRef,
    static: true,
  })
  endWrapperTemplate: Nullish<TemplateRef<{ tag: TagBase }>>;

  @Output()
  tagClick = new EventEmitter<TagBase>();

  @HostBinding('class.has-max-tags')
  get hasMaxTags() {
    return !!this.maxTags;
  }

  @HostBinding('attr.role')
  get role() {
    if (this.interactive) {
      return 'listbox';
    }

    return undefined;
  }

  @HostBinding('attr.tabindex')
  get tabindex() {
    if (this.interactive) {
      return '0';
    }

    return undefined;
  }

  get displayTags() {
    if (!this.maxTags) {
      return this.tags;
    }

    return this.tags.slice(0, this.maxTags);
  }

  get showMoreTags() {
    if (!this.showMoreTagsCount) {
      return false;
    }

    if (this.tags.length === this.displayTags.length) {
      return false;
    }

    return true;
  }

  get showMoreTag(): TagBase {
    return {
      id: 'unknown',
      name: `+${this.tags.length - this.displayTags.length}`,
      color: 'grey',
      createdAt: new Date().toISOString(),
      changedAt: new Date().toISOString(),
      isActive: true,
    };
  }

  constructor(private elementRef: ElementRef<HTMLElement>) {}

  @HostListener('focusin', ['$event'])
  handleFocus() {
    this.selectFirst();
  }

  private selectFirst() {
    const wrappers = this.elementRef.nativeElement.querySelectorAll<HTMLElement>('.tag-wrapper');
    if (Array.from(wrappers).some((wrapper) => wrapper.contains(document.activeElement))) {
      return;
    }

    window.blur();
    wrappers.item(0)?.querySelector<HTMLElement>('app-tag')?.focus();
  }

  @HostListener('keydown', ['$event'])
  handleListKeyDown(event: KeyboardEvent) {
    if (!this.interactive) {
      return;
    }
    if (event.key !== 'ArrowUp' && event.key !== 'ArrowDown') {
      return;
    }
    let focusIndex = -1;
    const wrappers = this.elementRef.nativeElement.querySelectorAll<HTMLElement>('.tag-wrapper');
    if (event.key === 'ArrowUp') {
      wrappers.forEach((wrapper, index) => {
        if (wrapper.contains(document.activeElement)) {
          if (index > 0) {
            focusIndex = index - 1;
          } else {
            focusIndex = wrappers.length - 1;
          }
        }
      });
    }
    if (event.key === 'ArrowDown') {
      wrappers.forEach((wrapper, index) => {
        if (wrapper.contains(document.activeElement)) {
          if (index + 1 < wrappers.length) {
            focusIndex = index + 1;
          } else {
            focusIndex = 0;
          }
        }
      });
    }
    if (focusIndex > -1) {
      event.preventDefault();
      window.blur();
      wrappers.item(focusIndex).querySelector<HTMLElement>('app-tag')?.focus();
    }
  }

  handleKeyDown(event: KeyboardEvent, tag: TagBase) {
    if (this.interactive && (event.key === ' ' || event.key === 'Enter')) {
      this.tagClick.emit(tag);
    }
  }

}
