import {Injectable} from '@angular/core';
import {AttachmentProjectImage, generateFilePathsForAttachmentProjectImage, IdType, User} from 'submodules/baumaster-v2-common';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {LoggingService} from '../common/logging.service';
import {TranslateService} from '@ngx-translate/core';
import {ATTACHMENT_DEFAULT_SORT_COLUMNS, ATTACHMENT_DEFAULT_SORT_ORDER, StorageKeyEnum} from '../../shared/constants';
import {AbstractClientAwareAttachmentDataService} from './abstract-client-aware-attachment-data.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ClientService} from '../client/client.service';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {AttachmentSettingService} from '../attachment/attachmentSetting.service';
import {SystemEventService} from '../event/system-event.service';
import {DevModeService} from '../common/dev-mode.service';
import {VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';

const REST_ENDPOINT_URI = 'api/data/attachmentProjectImage/';

@Injectable({
  providedIn: 'root'
})
export class AttachmentProjectImageDataService extends AbstractClientAwareAttachmentDataService<AttachmentProjectImage> {

  constructor(http: HttpClient, storage: StorageService, authenticationService: AuthenticationService, userService: UserService, clientService: ClientService,
              loggingService: LoggingService, systemEventService: SystemEventService, protected devModeService: DevModeService,
              integrityResolverService: IntegrityResolverService, protected translateService: TranslateService, protected attachmentSettingService: AttachmentSettingService) {
    super(StorageKeyEnum.ATTACHMENT_PROJECT_IMAGE, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, clientService, loggingService, systemEventService,
          devModeService, integrityResolverService, translateService, attachmentSettingService, VERSION_INTRODUCED_DEFAULT, ATTACHMENT_DEFAULT_SORT_COLUMNS, ATTACHMENT_DEFAULT_SORT_ORDER);
  }

  protected setGeneratedFilePath(attachment: AttachmentProjectImage) {
    attachment.filePath = generateFilePathsForAttachmentProjectImage(attachment).filePath;
  }

  public getDataMappedById(): Observable<Map<IdType, AttachmentProjectImage>> {
    return this.data.pipe(map((attachments) => {
      const attachmentMap = new Map<IdType, AttachmentProjectImage>();
      attachments.forEach((attachment) => attachmentMap.set(attachment.id, attachment));
      return attachmentMap;
    }));
  }

  public getByProjectId(projectId: IdType) {
    return this.data.pipe(map((attachments) => attachments.filter((attachment) => attachment.projectId === projectId) ));
  }

  public getByProjectIdAcrossClients(projectId: IdType) {
    return this.dataAcrossClients$.pipe(map((attachments) => attachments.filter((attachment) => attachment.projectId === projectId) ));
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }

}
