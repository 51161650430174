import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {IdType, User, PdfPlanVersionDistributionAccess} from 'submodules/baumaster-v2-common';
import {StorageKeyEnum} from '../../shared/constants';
import {AuthenticationService} from '../auth/authentication.service';
import {LoggingService} from '../common/logging.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {StorageService} from '../storage.service';
import {UserService} from '../user/user.service';
import {AbstractProjectAwareDataService} from './abstract-project-aware-data.service';
import {ProjectDataService} from './project-data.service';
import _ from 'lodash';
import { ProjectAvailabilityExpirationService } from '../project/project-availability-expiration.service';
import {VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';

const REST_ENDPOINT_URI = 'api/data/pdfPlanVersionDistributionAccesses';

@Injectable({
  providedIn: 'root'
})
export class PdfPlanVersionDistributionAccessDataService extends AbstractProjectAwareDataService<PdfPlanVersionDistributionAccess>{

  constructor(http: HttpClient, storage: StorageService,
              authenticationService: AuthenticationService,
              userService: UserService,
              protected projectDataService: ProjectDataService,
              protected projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
              loggingService: LoggingService, integrityResolverService: IntegrityResolverService) {
    super(StorageKeyEnum.PDF_PLAN_VERSION_DISTRIBUTION_ACCESS, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, projectDataService, loggingService, projectAvailabilityExpirationService,
      integrityResolverService, VERSION_INTRODUCED_DEFAULT, ['pdfPlanVersionId', 'changedAt']);
  }

  getAllByPlanVersionId$(pdfPlanVersionId: IdType): Observable<PdfPlanVersionDistributionAccess[]> {
    return this.data.pipe(map((data) => data.filter((item) => item.pdfPlanVersionId === pdfPlanVersionId)));
  }

  getAllByPlanVersionDistId$(pdfPlanVersionDistId: IdType): Observable<PdfPlanVersionDistributionAccess[]> {
    return this.data.pipe(map((data) => data.filter((item) => item.distributionId === pdfPlanVersionDistId)));
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }
}
