<div class="fileUploadContainer">
  <ion-grid class="fileUploadGrid"
            [appDragDropFileUpload]="dragAndDropSupported"
            [class.uploaded]="files.length >= 1"
            [class.allUploaded]="files.length === maxFiles"
            (fileDropped)="uploadFileEvent($event)">
    <form class="omg-form" [formGroup]="form">
      <ion-row *ngIf="!processingFiles">
        <ion-col size="12" class="ion-text-center">
          <fa-icon [icon]="['fas', 'file-upload']" class="fileUploadIcon"></fa-icon><br>
          <span *ngIf="dragAndDropSupported && files.length < maxFiles">
            {{title || (maxFiles === 1 ? 'fileUpload.drag_drop_file_title' : 'fileUpload.drag_drop_files_title') | translate}}<br>
            {{'fileUpload.or' | translate}}<br>
          </span>
          <span *ngIf="files.length >= maxFiles">
            {{(maxFiles === 1 ? 'fileUpload.file_uploaded' : 'fileUpload.files_uploaded') | translate:{files: files.length} }}<br>
          </span>
        </ion-col>
        <ion-col size="12" class="ion-text-center">
          <ion-button (click)="fileField.click()" *ngIf="files.length < maxFiles" class="omg-btn-secondary">{{'fileUpload.browse_files' | translate}}</ion-button>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="processingFiles">
        <ion-col class="ion-text-center">
          <ion-spinner class="processingFileSpinner"></ion-spinner><br />
          {{(files.length === 1 ? 'fileUpload.processing_file' : 'fileUpload.processing_files') | translate:{files: files.length} }}<br>
          <ion-button *ngIf="showAbortProcessFile" (click)="onAbortClicked()" class="omg-btn-text">
            {{'cancel' | translate}}
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="col-md-12">
          <input
              type="file"
              name="formFiles"
              #fileField
              (change)="uploadFileEvent($any($event).target.files)"
              hidden
              [attr.multiple] = "maxFiles > 1 ? '' : null"
              [accept]="acceptedExtensions.join(',')"
          />
        </ion-col>
      </ion-row>
      <ion-row *ngFor="let file of (showFilesList ? files : []); let i = index" class="ion-align-items-center">
        <ion-col [size]="fileColumnTemplate ? 9 : 11">
          {{ file.name }}
        </ion-col>
        <ion-col size="2" *ngIf="fileColumnTemplate">
          <ng-container *ngTemplateOutlet="fileColumnTemplate; context:{file: file, index: i}"></ng-container>
        </ion-col>
        <ion-col size="1" class="ion-justify-content-end">
          <ion-button class="omg-btn-text" (click)="removeFileByIndex(i)">
            <fa-icon [icon]="['fal', 'times']"></fa-icon>
          </ion-button>
        </ion-col>
      </ion-row>
    </form>
  </ion-grid>
</div>
