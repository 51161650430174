import {Injectable} from '@angular/core';
import {NameableDropdown, User} from 'submodules/baumaster-v2-common';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {AbstractClientAwareDataService} from './abstract-client-aware-data.service';
import {map} from 'rxjs/operators';
import {ClientService} from '../client/client.service';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import { mapObjectsNameWithDeletedSuffix } from 'src/app/utils/async-utils';
import { TranslateService } from '@ngx-translate/core';
import {VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';

const REST_ENDPOINT_URI = 'api/data/nameableDropdowns';

@Injectable({
  providedIn: 'root'
})
export class NameableDropdownDataService extends AbstractClientAwareDataService<NameableDropdown> {
  public readonly dataActive$ = this.dataForOwnClient$.pipe(
    map((nameableDropdowns) => nameableDropdowns?.filter((nameableDropdown) => nameableDropdown.isActive || nameableDropdown.isActive === undefined)));

  public readonly dataAcrossClientsWithDeletedSuffix$ = this.dataAcrossClients$.pipe(mapObjectsNameWithDeletedSuffix(this.translateService));
  public readonly dataWithDeletedSuffix$ = this.data.pipe(mapObjectsNameWithDeletedSuffix(this.translateService));

  constructor(http: HttpClient,
              storage: StorageService,
              authenticationService: AuthenticationService,
              userService: UserService,
              clientService: ClientService,
              loggingService: LoggingService, integrityResolverService: IntegrityResolverService,
              private translateService: TranslateService) {
    super(StorageKeyEnum.NAMEABLE_DROPDOWN, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, clientService, loggingService,
          integrityResolverService, VERSION_INTRODUCED_DEFAULT, [(value) => value?.name?.toLowerCase()]);
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }
}
