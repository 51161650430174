import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { defineCustomElements } from '@ionic/pwa-elements/loader';

import mime from 'mime';
import {PDFJS_DIST_WORKER_PATH} from './app/shared/constants';

mime.define({'application/autodesk-svf': ['svf']});
mime.define({'application/x-step': ['ifc']});

declare global {
  interface Window {
    pdfWorkerSrc: string;
  }
}

// make sure the pdfjs folder is in sync with angular.json and the pdfjs-dist dependency in package.json
window.pdfWorkerSrc = PDFJS_DIST_WORKER_PATH;

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);
