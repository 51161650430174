import {CommonModule} from '@angular/common';
import {
  AfterViewInit,
  Component,
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {LoadingController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {animationFrameScheduler, Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {AutodeskService} from 'src/app/services/autodesk/autodesk.service';
import {LoggingService} from 'src/app/services/common/logging.service';
import {ToastService} from 'src/app/services/common/toast.service';
import {SystemEventService} from 'src/app/services/event/system-event.service';
import {ProtocolEntryService} from 'src/app/services/protocol/protocol-entry.service';
import {ProtocolService} from 'src/app/services/protocol/protocol.service';
import {AutodeskFileStillProcessingError, convertErrorToMessage} from 'src/app/shared/errors';
import {observableToPromise} from 'src/app/utils/observable-to-promise';
import {Box3Bounds, box3ToArray, vector3ToArray} from 'src/app/utils/three-js-utils';
import {BimMarker, BimMarkerCameraDbIdMapping, BimMarkerObjectIdByProperty, BimMarkerObjectIds, BimVersion, ProtocolEntryIconStatus} from 'submodules/baumaster-v2-common';
import {BIM_MARKER_COLORS, getProtocolEntryStatus} from 'submodules/baumaster-v2-common/dist/planMarker/planMarkerCanvasUtils';
import * as THREEjs from 'three';
import _ from 'lodash';
import {AutodeskDocumentLoadingStateComponent} from 'src/app/components/autodesk/autodesk-document-loading-state/autodesk-document-loading-state.component';
import {AutodeskDocumentLoadingState, BimMarkerCreation} from 'src/app/model/bim-plan-with-deletable';
import {getBoundsOfAutodeskFragments} from 'src/app/utils/autodesk-utils';
import {isRecord} from 'src/app/utils/object-utils';
import { PosthogService } from 'src/app/services/posthog/posthog.service';
import {NetworkStatusService} from '../../../services/common/network-status.service';

const GUID_SOURCES = [
  {by: 'properties', filter: {displayName: 'GUID', displayCategory: 'Item'}, mapFn: (prop: Autodesk.Viewing.Property): BimMarkerObjectIdByProperty => ({
    by: 'item-guid',
    value: `${prop.displayValue}`,
  })},
  {by: 'properties', filter: {displayName: 'IfcGUID', displayCategory: 'Element'}, mapFn: (prop: Autodesk.Viewing.Property): BimMarkerObjectIdByProperty => ({
    by: 'ifc-guid',
    value: `${prop.displayValue}`,
  })},
  {by: 'properties', filter: {displayName: 'Name', displayCategory: 'Element ID'}, mapFn: (prop: Autodesk.Viewing.Property): BimMarkerObjectIdByProperty => ({
    by: 'any-prop',
    category: prop.displayCategory,
    name: prop.displayName,
    value: `${prop.displayValue}`,
  })},
] as const;

const ID_SOURCES = [
  ...GUID_SOURCES,
  {by: 'externalId'},
] as const;

// eslint-disable-next-line no-var
declare var THREE: typeof THREEjs;

class WebGLNotSupportedError extends Error {}

class AutodeskSdkError extends Error {
  constructor(
    public errorCode: number,
    public errorMessage: string,
    public statusCode: number,
    public statusText: string,
  ) {
    super(`AutodeskSdkError(${errorCode},${errorMessage},${statusCode},${statusText})`);
  }
}

export interface IntersectionClickEvent {
  dbId: number;
  point: THREEjs.Vector3Tuple;
  objectBounds: Box3Bounds;
  objectCenter: THREEjs.Vector3Tuple;
  objectIds: BimMarkerObjectIds;
  camera: BimMarker['camera'];
}

const MARKER_SIZE = {
  top: 26,
  left: 10
};

const LOG_SOURCE = 'AutodeskBaseViewerComponent';

const MARKER_SCALE_FACTOR = 0.2;
const MINIMUM_DISTANCE_FROM_CAMERA_TO_MARKER = 0.5;

const NODE_PROPERTY_TYPE_TO_INITIALLY_HIDE: string[] = ['IfcOpeningElement'];

@Directive({selector:'[appBimMarkerInViewer]', standalone: true})
export class BimMarkerInViewerDirective {}

@Directive({selector: '[appBimMarkerFooterTemplate]', standalone: true})
export class BimMarkerFooterTemplateDirective {
  static ngTemplateContextGuard(_dir: BimMarkerFooterTemplateDirective, ctx: unknown): ctx is {$implicit: BimMarker; isSelected: boolean} { return true; }
}

@Component({
  selector: 'app-autodesk-base-viewer',
  templateUrl: './autodesk-base-viewer.component.html',
  styleUrls: ['./autodesk-base-viewer.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    BimMarkerInViewerDirective,
    BimMarkerFooterTemplateDirective,
    AutodeskDocumentLoadingStateComponent,
  ]
})
export class AutodeskBaseViewerComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  protected destroy$ = new Subject<void>();
  viewer: Autodesk.Viewing.GuiViewer3D;
  private scaleFactor = 1;
  protected cylinderMesh: THREEjs.Mesh;
  protected sphereMesh: THREEjs.Mesh;
  currentModel: Autodesk.Viewing.Model|undefined;
  isExplodeExtensionActive = false;

  protected currentModelTree: Autodesk.Viewing.InstanceTree|undefined;

  @ViewChild('forgeViewer', {static: true}) forgeViewerDiv: ElementRef<HTMLDivElement>;
  @ViewChild('markersContainer', {static: true}) markersContainer: ElementRef<HTMLDivElement>;
  @ContentChild(BimMarkerFooterTemplateDirective, {read: TemplateRef}) bimMarkerFooterTemplate?: TemplateRef<{$implicit: BimMarker}>;
  @ViewChildren(BimMarkerInViewerDirective, {read: ElementRef}) markerButtons: QueryList<ElementRef<HTMLElement>>;
  public viewerReady = false;

  @Input() markers: Array<BimMarker|BimMarkerCreation>|undefined;
  @Input() selectedBimVersion: BimVersion;
  @Input() selectedMarker?: BimMarker;
  @Input() panToViewOnClick = true;
  @Input() emitClickEvent = false;
  @Input() showLoader = true;
  @Output() markerClick = new EventEmitter<BimMarker>();
  @Output() viewerLoaded = new EventEmitter<void>();
  @Output() viewerLoadError = new EventEmitter<unknown>();
  @Output() intersectionClick = new EventEmitter<IntersectionClickEvent>();

  protected hadCameraChangedEvent = false;
  autodeskDocumentLoadingState: AutodeskDocumentLoadingState|undefined;
  private online: boolean|undefined;

  constructor(
    protected loggingService: LoggingService = inject(LoggingService),
    protected autodeskService: AutodeskService = inject(AutodeskService),
    protected loadingController: LoadingController = inject(LoadingController),
    protected toastService: ToastService = inject(ToastService),
    protected protocolService: ProtocolService = inject(ProtocolService),
    protected protocolEntryService: ProtocolEntryService = inject(ProtocolEntryService),
    protected translateService: TranslateService = inject(TranslateService),
    protected systemEventService: SystemEventService = inject(SystemEventService),
    protected posthogService: PosthogService = inject(PosthogService),
    protected networkStatusService: NetworkStatusService = inject(NetworkStatusService),
  ) { }

  ngOnInit() {
    this.loggingService.debug(LOG_SOURCE, 'ngOnInit...');
    this.networkStatusService.online$.pipe(takeUntil(this.destroy$)).subscribe((online) => this.online = online);
    this.initializeComponent();
  }

  ngOnDestroy() {
    this.destroyComponent();
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit() {
    this.markerButtons.notifyOnChanges();
    this.markerButtons.changes.pipe(
      takeUntil(this.destroy$),
      debounceTime(0, animationFrameScheduler)
    ).subscribe(
      () => {
        this.drawMarkers();
      }
    );
    if (this.viewer && this.viewerReady) {
      this.viewer.container.appendChild(this.markersContainer.nativeElement);
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedBimVersion && !changes.selectedBimVersion.firstChange) {
      // first change is handled by ngOnInit
      if (this.selectedBimVersion?.autodeskUrn) {
        try {
          await this.loadDocument('urn:' + this.selectedBimVersion.autodeskUrn);
        } catch(error) {
          if (error instanceof AutodeskFileStillProcessingError) {
            await this.toastService.error('bimViewer.errors.errorStillProcessing');
          } else {
            await this.toastService.error('bimViewer.errors.error_loading_viewer');
          }
          this.systemEventService.logErrorEvent(`AutodeskViewerComponent - ngOnChanges - loadDocument`, error);
          this.loggingService.error(LOG_SOURCE, `AutodeskViewerComponent - ngOnChanges - loadDocument - ${convertErrorToMessage(error)}`);
        }
      }
    }
  }

  protected shouldCheckCameraChangedEvent(event: any): boolean { return this.emitClickEvent; }

  protected updateMarkersCallback = async () => {
    if (this.markers) {
      await this.updateMarkers();
    }
  };

  getCurrentModel(): Autodesk.Viewing.Model|undefined {
    try {
      const models = this.viewer.getVisibleModels();
      const model = models.find(m => m.getData().urn === this.selectedBimVersion.autodeskUrn);
      return model;
    } catch (error) {
      this.systemEventService.logErrorEvent(`AutodeskViewerComponent - getCurrentModel`, error);
      this.loggingService.warn(LOG_SOURCE, `AutodeskViewerComponent - getCurrentModel - ${convertErrorToMessage(error)}`);
    }
  }

  protected async getCurrentModelTree(): Promise<Autodesk.Viewing.InstanceTree|undefined> {
    if (!this.currentModelTree) {
      const tree = await new Promise<Autodesk.Viewing.InstanceTree>((res, rej) => {
        try {
          this.viewer.getObjectTree(res, (...args) =>  rej(new AutodeskSdkError(...args)));
        } catch (e) {
          rej(e);
        }
      });

      this.currentModelTree = tree;
    }

    return this.currentModelTree;
  }

  protected assignCurrentModel(overrideIfUndefined = false) {
    const model = this.getCurrentModel();
    if (model !== this.currentModel) {
      this.currentModelTree = undefined;
    }
    if (model || overrideIfUndefined) {
      this.currentModel = model;
    }
  }

  protected geometryLoadedCallbackFn() {
    if (this.viewer.isLoadDone()) {
      this.assignCurrentModel(true);
    }
  }

  public geometryLoadedCallback = () => {
    this.geometryLoadedCallbackFn();
  };

  private handleCameraChangedEvent(event: any){
    if (this.shouldCheckCameraChangedEvent(event)) {
      if (event?.type === Autodesk.Viewing.CAMERA_CHANGE_EVENT) {
        this.hadCameraChangedEvent = true;
      } else {
        this.hadCameraChangedEvent = false;
      }
    }
  }

  protected clickHandler = async (event: MouseEvent) => {
    if (this.emitClickEvent) {
      if (!this.hadCameraChangedEvent) {
        await this.handleClick(event);
      } else {
        this.hadCameraChangedEvent = false;
      }
    }
  };

  protected async handleClick(event: MouseEvent): Promise<void> {
    await this.getIntersectionAndEmitEvent(event);
  }

  private getBoundsAndCenterOfObject(model: Autodesk.Viewing.Model, dbId: number, fragIds = this.getFragmentIdsRecursively(dbId)): { boundsBox: THREEjs.Box3, center: THREEjs.Vector3 } {
    const fragments = model.getFragmentList();
    const boundsBox = getBoundsOfAutodeskFragments(fragments, fragIds);
    return { boundsBox, center: boundsBox.getCenter() };
  }

  private async getProperties(dbId: number) {
    return new Promise<Autodesk.Viewing.PropertyResult>((res, rej) => {
      this.viewer.getProperties(dbId, res, (...args) =>  rej(new AutodeskSdkError(...args)));
    });
  }

  private async getObjectIds(dbId: number): Promise<BimMarkerObjectIds> {
    const props = await this.getProperties(dbId);
    const result: BimMarkerObjectIds = {
      externalId: '',
      properties: [],
    };

    for (const idSource of ID_SOURCES) {
      if (idSource.by === 'properties') {
        const propIds = _.filter<Autodesk.Viewing.Property>(props.properties, idSource.filter);
        result.properties = result.properties.concat(propIds.map(idSource.mapFn));
      } else if (idSource.by === 'externalId') {
        result.externalId = props.externalId;
      }
    }

    return result;
  }

  private async findFirstWithGuid(dbId: number, rootId = this.viewer.model.getRootId(), instanceTree?: Autodesk.Viewing.InstanceTree): Promise<number | undefined> {
    if (dbId === rootId) {
      return;
    }

    const props = await this.getProperties(dbId);

    for (const idSource of GUID_SOURCES) {
      if (idSource.by === 'properties') {
        if (_.some(props.properties, idSource.filter)) {
          return dbId;
        }
      }
    }

    const tree = instanceTree ?? this.viewer.model.getInstanceTree();

    return this.findFirstWithGuid(tree.getNodeParentId(dbId), rootId, tree);
  }

  private getFragmentIdsRecursively(dbId: number, tree = this.viewer.model.getInstanceTree()): number[] {
    const fragIds: number[] = [];
    tree.enumNodeFragments(dbId, (fragId) => { fragIds.push(fragId); }, true);
    return fragIds;
  }

  private async getCameraDbIdMapping(camera: Record<string, unknown>): Promise<BimMarkerCameraDbIdMapping|undefined> {
    // Object set is an array of objects with definitions which objects are hidden or isolated.
    // Array contains more than one entry, if there are more models loaded in the autodesk viewer.
    // Since we only allow one model to be loaded, we expect object set size is equal to 1.
    if (!('objectSet' in camera && camera.objectSet && Array.isArray(camera.objectSet) && camera.objectSet.length === 1)) {
      return undefined;
    }

    const {objectSet} = camera;
    const [modelState] = objectSet as [unknown];

    if (!modelState || !isRecord(modelState)) {
      return undefined;
    }

    // lmv is the idType of state returned by viewer.getState - other id types might come with different object structure
    if (modelState.idType !== 'lmv') {
      return undefined;
    }

    const dbIds: number[] = [];

    // For camera state, two properties matter: isolated and hidden.
    //
    // * `isolated` is an array with dbId of objects that are visible.
    //    All other objects that are not part of isolated array, will be hidden by the autodesk viewer.
    // * `hidden` is an array with dbIds of objects that are hidden.
    //    Objects that are not part of hidden array, will be displayed by the autodesk viewer.
    //
    // `isolated` array is more important. If values exists in both arrays,
    // only `isolated` will be taken into account by autodesk viewer

    if ('isolated' in modelState && modelState.isolated && Array.isArray(modelState.isolated)) {
      for (const isolatedId of modelState.isolated) {
        if (typeof isolatedId === 'number' && !isNaN(isolatedId)) {
          dbIds.push(isolatedId);
        }
      }
    }

    if ('hidden' in modelState && modelState.hidden && Array.isArray(modelState.hidden)) {
      for (const hiddenId of modelState.hidden) {
        if (typeof hiddenId === 'number' && !isNaN(hiddenId)) {
          dbIds.push(hiddenId);
        }
      }
    }

    const cameraDbIdMapping: BimMarkerCameraDbIdMapping['__cameraStateDbMapping'] = {};

    for (const dbId of dbIds) {
      const objectIds = await this.getObjectIds(dbId);
      cameraDbIdMapping[dbId] = objectIds;
    }

    if (Object.keys(cameraDbIdMapping).length > 0) {
      return {__cameraStateDbMapping: cameraDbIdMapping};
    }

    return undefined;
  }

  async getIntersection(event: MouseEvent): Promise<IntersectionClickEvent|undefined> {
    const {clientX, clientY} = event;
    const rect = this.viewer.container.getBoundingClientRect();
    const intersections: Autodesk.Viewing.Private.HitTestResult[] = [];
    this.viewer.disableSelection(true);
    (this.viewer.impl as any).castRayViewport(this.viewer.impl.clientToViewport(clientX - rect.left, clientY - rect.top), false, null, null, intersections);
    this.viewer.disableSelection(false);

    if (!intersections.length) {
      return undefined;
    }

    const [intersection] = intersections;
    const firstDbIdWithGuid = await this.findFirstWithGuid(intersection.dbId);
    const dbId = firstDbIdWithGuid ?? intersection.dbId;

    const {boundsBox, center} = this.getBoundsAndCenterOfObject(this.currentModel, dbId);
    const objectBounds = box3ToArray(boundsBox);
    const objectCenter = vector3ToArray(center);
    const objectIds = await this.getObjectIds(dbId);
    const camera: BimMarker['camera'] = await this.getCameraState();

    return {
      dbId,
      objectCenter,
      objectBounds,
      objectIds,
      point: vector3ToArray(intersection.point),
      camera,
    };
  }

  protected async getCameraState(): Promise<BimMarker['camera']> {
    const camera: BimMarker['camera'] = this.viewer.getState({objectSet: true, viewport: true, autocam: true});

    Object.assign(camera, await this.getCameraDbIdMapping(camera) ?? {});
    return camera;
  }

  async getIntersectionAndEmitEvent(event: MouseEvent) {
    if (!this.emitClickEvent) {
      return;
    }
    const intersectionEvent = await this.getIntersection(event);
    if (intersectionEvent) {
      this.intersectionClick.emit(intersectionEvent);
    }
  }

  async initializeComponent() {
    const spinner = this.showLoader ? await this.loadingController.create(
      {message: this.translateService.instant('bimViewer.loading')}
    ) : undefined;
    try {
      this.loggingService.debug(LOG_SOURCE, 'initializeComponent...');
      await spinner?.present();
      await this.createViewInstance();
      if (this.selectedBimVersion?.autodeskUrn) {
        await this.loadDocument('urn:' + this.selectedBimVersion.autodeskUrn);
      }
      this.scaleFactor = this.currentModel ? this.currentModel.getUnitScale() : 1;
      this.viewer.setSelectionMode(Autodesk.Viewing.SelectionMode.LAST_OBJECT);
      this.viewer.addEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, (ev) => {
        this.handleCameraChangedEvent(ev);
        this.updateMarkersCallback();
      });
      this.viewer.addEventListener(Autodesk.Viewing.EXPLODE_CHANGE_EVENT, this.updateMarkersCallback);
      this.viewer.addEventListener(Autodesk.Viewing.ISOLATE_EVENT, this.updateMarkersCallback);
      this.viewer.addEventListener(Autodesk.Viewing.HIDE_EVENT, this.updateMarkersCallback);
      this.viewer.addEventListener(Autodesk.Viewing.SHOW_EVENT, this.updateMarkersCallback);
      this.viewer.addEventListener(Autodesk.Viewing.MODEL_LAYERS_LOADED_EVENT, this.geometryLoadedCallback);
      this.viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, this.geometryLoadedCallback);
      this.geometryLoadedCallback();

      this.viewerReady = true;

      await this.drawMarkers();

      if (this.markersContainer?.nativeElement) {
        this.viewer.container.appendChild(this.markersContainer.nativeElement);
      }

      this.viewer.waitForLoadDone().then(() => {
        this.assignCurrentModel();

        // No await intentional - hiding unwanted nodes should not block further processing
        this.hideUnwantedNodePropertyTypes();
        this.viewerLoaded.emit();
      });

      this.loggingService.debug(LOG_SOURCE, 'initializeComponent finished successfully');
    } catch (error) {
      const msg = `Error in initializeComponent. ${convertErrorToMessage(error)}`;
      this.loggingService.error(LOG_SOURCE, msg);
      if (error instanceof AutodeskFileStillProcessingError) {
        await this.toastService.error('bimViewer.errors.errorStillProcessing');
        this.posthogService.captureEvent('[Project-Room][BIM] Opened Plan but Autodesk is still processing', {});
      } else {
        this.posthogService.captureEvent('[Project-Room][BIM] Error opening BIM Viewer', {
          errorMessage: convertErrorToMessage(error)
        });
      }
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - initializeComponent', error?.userMessage + '-' + error?.message);
      this.viewerLoadError.emit(error);
    } finally {
      await spinner?.dismiss();
    }
  }

  async hideUnwantedNodePropertyTypes(): Promise<void> {
    return new Promise((res, rej) => {
      try {
        const tree = this.viewer.model.getInstanceTree();

        const dbIds: number[] = [];
        tree.enumNodeChildren(this.viewer.model.getRootId(), (v) => {
          dbIds.push(v);
        }, true);

        const propDb: any = this.viewer.model.getPropertyDb();

        propDb.getBulkProperties2(dbIds, {
          needsExternalId: false,
          propFilter: ['Type'],
        }, (result: Autodesk.Viewing.PropertyResult[]) => {
          try {
            const nodesToHide: number[] = [];
            result.forEach((node) => {
              if (node.properties.length && node.properties.some((prop) => NODE_PROPERTY_TYPE_TO_INITIALLY_HIDE.includes('' + prop.displayValue))) {
                nodesToHide.push(node.dbId);
              }
            });

            this.viewer.hide(nodesToHide, this.viewer.model);

            res();
          } catch (e) {
            this.loggingService.warn(LOG_SOURCE, `hideUnwantedNodePropertyTypes - failed to hide nodes: ${convertErrorToMessage(e)}`);
            rej(e);
          }
        }, (error) => {
          this.loggingService.warn(LOG_SOURCE, `hideUnwantedNodePropertyTypes - failed to get nodes' properties: ${error?.msg ?? convertErrorToMessage(error)}`);
          rej(error);
        });
      } catch (e) {
        rej(e);
      }
    });
  }

  destroyComponent(){
    this.loggingService.debug(LOG_SOURCE, 'destroyComponent...');
    this.destroyViewerInstance();
    this.loggingService.debug(LOG_SOURCE, 'destroyComponent finished successfully');
  }

  private async createViewInstance() {
    try {
      this.loggingService.debug(LOG_SOURCE, 'createViewInstance...');
      this.viewer = await this.initializeViewer(this.forgeViewerDiv.nativeElement);
      this.loggingService.debug(LOG_SOURCE, 'createViewInstance finished successfully');
    } catch (error) {
      this.loggingService.error(LOG_SOURCE, `createViewInstance failed with error ${convertErrorToMessage(error)}`);
      throw error;
    }
  }

  private destroyViewerInstance() {
    this.viewer?.removeEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, this.updateMarkersCallback);
    this.viewer?.removeEventListener(Autodesk.Viewing.EXPLODE_CHANGE_EVENT, this.updateMarkersCallback);
    this.viewer?.removeEventListener(Autodesk.Viewing.ISOLATE_EVENT, this.updateMarkersCallback);
    this.viewer?.removeEventListener(Autodesk.Viewing.HIDE_EVENT, this.updateMarkersCallback);
    this.viewer?.removeEventListener(Autodesk.Viewing.SHOW_EVENT, this.updateMarkersCallback);
    this.viewer?.removeEventListener(Autodesk.Viewing.MODEL_LAYERS_LOADED_EVENT, this.geometryLoadedCallback);
    this.viewer?.removeEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, this.geometryLoadedCallback);

    if (this.viewer) {
      try {
        this.viewer.finish();
      } catch (error) {
        this.loggingService.warn(LOG_SOURCE, `destroyViewerInstance viewer.finish failed with error ${convertErrorToMessage(error)}`);
      }
    }
    this.viewer = undefined;
    try {
      Autodesk.Viewing.shutdown();
    } catch (error) {
      this.loggingService.warn(LOG_SOURCE, `destroyViewerInstance Autodesk.Viewing.shutdown failed with error ${convertErrorToMessage(error)}`);
    }
  }

  private async initializeViewer(htmlDiv: HTMLElement): Promise<Autodesk.Viewing.GuiViewer3D> {
    this.loggingService.debug(LOG_SOURCE, 'initializeViewer...');
    try {
      return await new Promise<Autodesk.Viewing.GuiViewer3D>((resolve, reject) => {
        try {
          const options = this.autodeskService.onlineOptions;
          Autodesk.Viewing.Initializer(options, () => {
            this.loggingService.debug(LOG_SOURCE, `Autodesk.Viewing.Initializer...`);
            if (!htmlDiv) {
              htmlDiv = document.getElementById('forgeViewer');
            }
            const viewer = new Autodesk.Viewing.GuiViewer3D(htmlDiv);
            this.loggingService.debug(LOG_SOURCE, `Autodesk.Viewing.Initializer - viewer.start...`);
            const startedCode = viewer.start();
            this.loggingService.debug(LOG_SOURCE, `Autodesk.Viewing.Initializer - viewer.start - startedCode = ${startedCode}`);
            if (startedCode > 0) {
              this.loggingService.error(LOG_SOURCE, `Initialization of Viewer failed with startedCode ${startedCode}.`);
              reject(new WebGLNotSupportedError('Failed to create a Viewer: WebGL not supported.'));
              return;
            }
            this.loggingService.info(LOG_SOURCE, 'Initialization of Viewer finished.');
            resolve(viewer);
          });
        } catch (e) {
          reject(e);
        }
      });
    } catch (error) {
      this.loggingService.error(LOG_SOURCE, `initializeViewer failed: ${convertErrorToMessage(error)}`);
      throw error;
    } finally {
      this.loggingService.debug(LOG_SOURCE, 'initializeViewer finished');
    }
  }

  protected async loadDocument(documentId: string): Promise<any> {
    this.loggingService.debug(LOG_SOURCE, 'loadDocument called.');
    try {
      if (!(await observableToPromise(this.networkStatusService.online$))) {
        this.autodeskDocumentLoadingState = 'failedOffline';
        throw new Error('loadDocument - loading bim document only works online');
      }
      const ret = await new Promise<any>((resolve, reject) => {
        Autodesk.Viewing.Document.load(documentId, (viewerDocument) => {
          try {
            this.autodeskDocumentLoadingState = 'loading';
            this.loggingService.debug(LOG_SOURCE, 'loadDocument.onLoadSuccess called.');
            if (!(viewerDocument.myData && typeof viewerDocument.myData === 'object' && 'status' in viewerDocument.myData && viewerDocument.myData.status === 'success')) {
              this.autodeskDocumentLoadingState = 'autodeskStillProcessing';
              reject(new AutodeskFileStillProcessingError(`Autodesk is still processing the file (status: ${viewerDocument.myData?.status}, progress: ${viewerDocument.myData?.success})`));
              return;
            }
            const defaultModel = viewerDocument.getRoot().getDefaultGeometry();
            this.viewer.loadDocumentNode(viewerDocument, defaultModel);
            this.autodeskDocumentLoadingState = 'success';
            resolve(viewerDocument);
          } catch (e) {
            const message = LOG_SOURCE + ` - loadDocument for documentId ${documentId} failed with "${convertErrorToMessage(e)}"`;
            this.loggingService.error(LOG_SOURCE, message);
            this.autodeskDocumentLoadingState = this.online === false ? 'failedOffline' : 'failed';
            reject(e);
          }
        }, (errorCode: number, errorMessage: string) => {
          const message = LOG_SOURCE + ` - loadDocument for documentId ${documentId} failed with errorCode=${errorCode}, errorMessage="${errorMessage}"`;
          this.loggingService.error(LOG_SOURCE, message);
          this.autodeskDocumentLoadingState = this.online === false ? 'failedOffline' : 'failed';
          reject(message);
        });
      });
      return ret;
    } catch (error) {
      const errorMessage = convertErrorToMessage(error);
      this.loggingService.error(LOG_SOURCE, errorMessage);
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - loadDocument', error?.userMessage + '-' + error?.message);
      throw error;
    }
  }

  protected findMarkerContainer(marker: BimMarker): HTMLElement|undefined {
    return this.markerButtons.find(item => item.nativeElement.id === marker.id)?.nativeElement;
  }

  protected async drawMarker(marker: BimMarker) {
    const worldPos = this.getMarkerVector(marker);
    const clientPos = this.viewer.worldToClient(worldPos);
    const protocolEntry = await this.protocolEntryService.getProtocolEntry(marker?.protocolEntryId);
    let status = ProtocolEntryIconStatus.INFO;
    if (protocolEntry) {
      const isLayoutShort = await observableToPromise(this.protocolService.getIsProtocolLayoutShort$(marker?.protocolEntryId));
      status = isLayoutShort ? getProtocolEntryStatus(protocolEntry) : await this.protocolEntryService.getProtocolEntryIconStatusByEntryId(marker?.protocolEntryId);
    }

    const markerContainer = this.findMarkerContainer(marker);
    if (!markerContainer) {
      this.loggingService.warn(LOG_SOURCE, `drawMarker silently skipping marker(${marker.id}) - marker container not found in DOM`);
      return;
    }
    const markerButton: HTMLElement = markerContainer.classList.contains('marker') ? markerContainer : markerContainer.querySelector('.marker');
    markerButton.style.border = `8px solid #${BIM_MARKER_COLORS[status]}`;

    markerContainer.style.display = 'block';
    markerContainer.style.left = Math.floor(clientPos.x) - MARKER_SIZE.left + 'px';
    markerContainer.style.top = Math.floor(clientPos.y) - MARKER_SIZE.top + 'px';
  }

  protected async drawMarkers(markersToDraw = this.markers){
    if (markersToDraw?.length && this.viewerReady && this.viewer) {
      markersToDraw.forEach(marker => this.drawMarker(marker));
    }
  }

  handleMarkerClick(marker: BimMarker){
    if (this.panToViewOnClick) {
      this.goToMarkerView(marker);
    }
    this.markerClick.emit(marker);
  }

  public goToMarkerView(marker: BimMarker, immediate = false){
    this.viewer.restoreState(marker.camera, {objectSet: true, viewport: true, autocam: true}, immediate);
  }

  private isPointVisible(point: THREEjs.Vector3): boolean|undefined {
    const isPointVisible = (this.viewer?.navigation as any)?.isPointVisible?.(point);
    if (!isPointVisible) {
      return isPointVisible;
    }

    const camera = this.viewer.getCamera();
    const distance = camera.position.clone().distanceTo(point);

    return distance > (MINIMUM_DISTANCE_FROM_CAMERA_TO_MARKER * this.scaleFactor);
  }

  protected async isNodeOrChildVisible(dbId: number, model: Autodesk.Viewing.Model) {
    const isNodeVisible = this.viewer.isNodeVisible(dbId, model);
    if (isNodeVisible) {
      return isNodeVisible;
    }
    const tree = await this.getCurrentModelTree();
    if (!tree) {
      this.loggingService.warn(LOG_SOURCE, 'isNodeOrChildVisible - getCurrentModelTree returned undefined, cannot determine if children visible');
      return isNodeVisible;
    }

    let childrenVisible = false;
    tree.enumNodeChildren(dbId, (childDbId) => {
      childrenVisible = this.viewer.isNodeVisible(childDbId);

      // enumNodeChildren will stop enumeration when truthy value is returned.
      return childrenVisible;
    }, true);

    return childrenVisible;
  }

  protected async updateMarkers(markers = this.markers, containerOverride?: HTMLElement) {
    const forgeViewerDiv = this.forgeViewerDiv.nativeElement.getElementsByTagName('div')[0];
    const rect = forgeViewerDiv.getBoundingClientRect();
    const markersToUpdate = markers ? markers.slice() : undefined;
    for (const marker of markersToUpdate) {
      const markerContainer = containerOverride ?? this.markerButtons.find(item => item.nativeElement.id === marker.id).nativeElement;
      const explodeExtension = this.viewer.getExtension('Autodesk.Explode');
      this.isExplodeExtensionActive = explodeExtension?.isActive('') ?? false;
      const markerVector = this.getMarkerVector(marker);
      const clientPos = this.viewer.worldToClient(markerVector);
      if (rect.height < Math.floor(clientPos.y)+2 || !(await this.isNodeOrChildVisible(Number(marker.viewerId), this.currentModel))) {
        markerContainer.style.display = 'none';
      } else if (this.isExplodeExtensionActive) {
        markerContainer.style.display = 'none';
      } else if (!this.isPointVisible(markerVector)) {
        markerContainer.style.display = 'none';
      } else {
        markerContainer.style.display = 'block';
        markerContainer.style.left = Math.floor(clientPos.x) - MARKER_SIZE.left + 'px';
        markerContainer.style.top = Math.floor(clientPos.y) - MARKER_SIZE.top + 'px';
      }
    }
  }

  private getMarkerVector(marker: BimMarker) {
    return new THREE.Vector3(Number(marker.positionX), Number(marker.positionY), Number(marker.positionZ));
  }

  protected clearScene(){
    if (this.viewer.overlays.hasScene('markerScene')) {
      this.viewer.overlays.clearScene('markerScene');
    }
  }

  public async draw3DMarker(marker: BimMarker){
    const isLayoutShort = await observableToPromise(this.protocolService.getIsProtocolLayoutShort$(marker?.protocolEntryId));
    const protocolEntry = await this.protocolEntryService.getProtocolEntry(marker?.protocolEntryId);
    const status = isLayoutShort ? getProtocolEntryStatus(protocolEntry) : await this.protocolEntryService.getProtocolEntryIconStatusByEntryId(marker?.protocolEntryId);
    const orbitType = typeof (marker.camera as any)?.viewport.distanceToOrbit;
    if (orbitType !== 'string' && orbitType !== 'number') {
      throw new Error(`Unable to draw 3D marker ${marker?.id} (new); marker.camera.viewport.distanceToOrbit is not string or number`);
    }
    const distanceToOrbitStrOrNumber: number|string = (marker.camera as any)?.viewport.distanceToOrbit;
    const distanceToOrbit = +(distanceToOrbitStrOrNumber);
    if (isNaN(distanceToOrbit)) {
      throw new Error(`Unable to draw 3D marker ${marker?.id} (new); marker.camera.viewport.distanceToOrbit "${distanceToOrbitStrOrNumber}" is not string or number`);
    }
    const radius = MARKER_SCALE_FACTOR / this.scaleFactor * (distanceToOrbit / 10);
    const color = new THREE.Color(`#${BIM_MARKER_COLORS[status]}`);
    const material = new THREE.MeshBasicMaterial({color});
    const cylinderGeom = new THREE.CylinderGeometry(radius, 0, 2*radius, 32 );
    this.cylinderMesh = new THREE.Mesh(cylinderGeom, material);
    this.cylinderMesh.position.set(
      Number(marker.positionX), Number(marker.positionY), Number(marker.positionZ) + radius
    );
    this.cylinderMesh.rotation.x = Math.PI / 2;

    const sphereGeom = new THREE.SphereGeometry(radius, 32, 16, 0, Math.PI, 0, Math.PI);
    this.sphereMesh = new THREE.Mesh(sphereGeom, material);
    this.sphereMesh.position.set(
      Number(marker.positionX), Number(marker.positionY), Number(marker.positionZ) + 2 * radius
    );
    if (!this.viewer.overlays.hasScene('markerScene')) {
      this.viewer.overlays.addScene('markerScene');
    }
    if (!this.viewer.overlays.addMesh(this.sphereMesh, 'markerScene')) {
      throw new Error('failed to add sphereMesh');
    }
    if (!this.viewer.overlays.addMesh(this.cylinderMesh, 'markerScene')) {
      throw new Error('failed to add cylinderMesh');
    }
  }

  public async getScreenshotBlobUrl(marker: BimMarker): Promise<string> {
    const forgeViewerDiv = this.forgeViewerDiv.nativeElement;
    if (marker) {
      await this.draw3DMarker(marker);
    }
    return new Promise((res, rej) => {
      setTimeout(() => {
        try {
          this.viewer.getScreenShot(forgeViewerDiv.clientWidth, forgeViewerDiv.clientHeight, function(blobUrl){
            res(blobUrl);
          });
          this.clearScene();
        } catch (e) {
          rej(e);
        }
      });
    });
  }

}
