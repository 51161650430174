import {ColumnConfig} from '../ColumnConfig';

const SinglePlanMarkerPerActivityUniq = ['activity_id'];
const SinglePlanMarkerPerEquipmentUniq = ['equipment_id'];
const SinglePlanMarkerPerMaterialUniq = ['material_id'];

export const PdfPlanMarkerProtocolEntryColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'position_x', prop: 'positionX', def: null},
  {name: 'position_y', prop: 'positionY', def: null},
  {name: 'protocolentry_id', prop: 'protocolEntryId', def: null},
  {name: 'pdfplanpage_id', prop: 'pdfPlanPageId'},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'activity_id', prop: 'activityId', def: null, uniqueWith: [SinglePlanMarkerPerActivityUniq]},
  {name: 'equipment_id', prop: 'equipmentId', def: null, uniqueWith: [SinglePlanMarkerPerEquipmentUniq]},
  {name: 'material_id', prop: 'materialId', def: null, uniqueWith: [SinglePlanMarkerPerMaterialUniq]}
];
