<app-modal-header>
  <ion-title>
    @if (unit) {
      {{'units_settings.edit_unit' | translate}}
    } @else {
      {{'units_settings.new_unit' | translate}}
    }
  </ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <form [formGroup]="form" class="omg-form app-split-pane gap-4">
    <div class="app-split-pane-side d-flex flex-column ion-hide-lg-down">
      <app-unit-profiles-in-unit
        class="flex-grow-1"
        [unitContacts]="selectedUnitContacts$ | async"
        [unselectedUnitContacts]="availableUnitContacts$ | async"
        (contactMenuClick)="handleContactMenuClick($event)"
        (contactAdded)="handleContactAdded($event)"
        [readonly]="isReadonly$ | async"
      ></app-unit-profiles-in-unit>
    </div>
    <div class="app-split-pane-main">
      <ion-grid class="grid-gap-4">
        <ion-row>
          <ion-col>
            <ion-item class="omg-can-error-text omg-form-invalid-after-touched">
              <ion-input #autofocus [label]="'units_settings.unit_form.name' | translate" label-placement="floating" type="text"
                formControlName="name" [maxlength]="nameMaxLength" class="omg-required" [errorText]="form.controls.name.errors && form.controls.name.dirty ? ((form.controls.name.errors.maxlength ? 'formValidation.fieldTooLong' : 'formValidation.emptyField') | translate:{field: 'units_settings.unit_form.name' | translate, maxLength: nameMaxLength}) : ''"></ion-input>
            </ion-item>
          </ion-col>
        </ion-row>

        @if (unit?.externalId) {
        <ion-row>
          <ion-col>
            <ion-item class="look-disabled-item" [disabled]="true">
              <ion-input [label]="'units_settings.unit_form.external_id' | translate" label-placement="floating" type="text"
                [ngModel]="unit.externalId" [ngModelOptions]="{standalone: true}" [readonly]="true"></ion-input>
            </ion-item>
          </ion-col>
        </ion-row>
        }

        <ion-row class="ion-hide-lg-up">
          <ion-col>
            <app-unit-profiles-in-unit
              class="flex-grow-1"
              [unitContacts]="selectedUnitContacts$ | async"
              [unselectedUnitContacts]="availableUnitContacts$ | async"
              (contactMenuClick)="handleContactMenuClick($event)"
              (contactAdded)="handleContactAdded($event)"
            ></app-unit-profiles-in-unit>
          </ion-col>
        </ion-row>
  
        <app-address-form formGroupName="address"></app-address-form>
  
        <ion-row>
          <ion-col>
            <div class="omg-modal-header">
              {{ 'units_settings.unit_form.other' | translate }}
            </div>
            <ion-item class="omg-can-error-text omg-form-invalid-after-touched">
              <ion-input #autofocus [label]="'units_settings.unit_form.notes' | translate" label-placement="floating" type="text"
              formControlName="notes" [maxlength]="notesMaxLength" [errorText]="form.controls.name.errors && form.controls.name.dirty ? ((form.controls.name.errors.maxlength ? 'formValidation.fieldTooLong' : 'formValidation.emptyField') | translate:{field: 'units_settings.unit_form.notes' | translate, maxLength: 500}) : ''"></ion-input>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </form>
</ion-content>

<app-modal-footer appActionWithLoading #actionWithLoading="appActionWithLoading">
  @if (unit && !(isReadonly$ | async)) {
  <ion-buttons slot="start">
    <ion-button class="omg-btn-secondary omg-btn-color-danger" [disabled]="form.invalid || (actionWithLoading.loading$ | async)" (click)="remove(actionWithLoading)">
      @if (actionWithLoading.loading$ | async) {
      <ion-spinner class="spinner-center-button"></ion-spinner>
      }
      <fa-icon slot="start" [icon]="['fal', 'trash-alt']"></fa-icon>
      <span>{{'delete' | translate}}</span>
    </ion-button>
  </ion-buttons>
  }
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" (click)="cancel()">{{'cancel' | translate}}</ion-button>
    @if (!(isReadonly$ | async)) {
    <ion-button class="omg-btn-primary" [disabled]="form.invalid || (actionWithLoading.loading$ | async)" (click)="actionWithLoading.performAction(save)">
      @if (actionWithLoading.loading$ | async) {
      <ion-spinner class="spinner-center-button"></ion-spinner>
      }
      <span>{{'button.save' | translate}}</span>
    </ion-button>
    }
  </ion-buttons>
</app-modal-footer>
