import {Directive, ElementRef, Input, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {PdfPlanCommentPopoverComponent} from 'src/app/components/project-room/pdf-plan-comment-popover/pdf-plan-comment-popover.component';
import {PopoverService} from 'src/app/services/ui/popover.service';

@Directive({
  selector: '[appPdfPlanCommentPopover]',
  standalone: true
})
export class PdfPlanCommentPopoverDirective implements OnDestroy {
  private openCloseSub: Subscription;

  @Input()
  comment: string;

  constructor(private element: ElementRef, private popoverService: PopoverService) {
    const that = this;
    this.openCloseSub = this.popoverService.getHoverableOrTappable(this.element, {
      component: PdfPlanCommentPopoverComponent,
      componentProps: { get comment() { return that.comment; }, handleLineBreaks: true },
    }, {
      filterCondition: () => Boolean(this.comment)
    }).subscribe();
  }

  ngOnDestroy() {
    this.openCloseSub.unsubscribe();
  }

}
