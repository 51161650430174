import {Inject, Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {IdAware, IdType} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {AbstractClientAwareDataService} from './abstract-client-aware-data.service';
import {DataServiceDeleteOptions, DataServiceInsertOptions, defaultInsertOptionsMappingDataServices, VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';
import {ClientService} from '../client/client.service';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import { IntegrityResolverService } from '../integrity/integrity-resolver.service';

@Injectable()
export abstract class AbstractClientAwareMappingDataService<T extends IdAware> extends AbstractClientAwareDataService<T> implements OnDestroy {

  constructor(@Inject('StorageKeyEnum') storageKey: StorageKeyEnum,
              @Inject('String') protected readonly restEndpointUri: string,
              @Inject('Array') protected readonly defaultValue: Array<T>,
              protected http: HttpClient, protected storage: StorageService, protected authenticationService: AuthenticationService, protected userService: UserService,
              clientService: ClientService, protected loggingService: LoggingService,
              integrityResolverService: IntegrityResolverService,
              public versionIntroduced = VERSION_INTRODUCED_DEFAULT,
              @Inject('Array') protected sortColumns?: Array<keyof T | ((item: T) => any)>,
              @Inject('Array') protected sortColumnOrders?: Array<'asc'|'desc'>) {
    super(storageKey, restEndpointUri, defaultValue, http, storage, authenticationService, userService, clientService, loggingService,
          integrityResolverService, versionIntroduced, sortColumns, sortColumnOrders);
  }

  public async insertOrUpdate(valueOrArray: T | Array<T>): Promise<Array<T>> {
    throw new Error('insertOrUpdate is not supported for this DataService.');
  }

  async insert(valueArrayOrFunction: Array<T> | ((storageData: Array<T>) => (Array<T> | T | undefined)) | T, clientId?: IdType, options?: DataServiceInsertOptions): Promise<Array<T>> {
    options = options ? {...options, ...defaultInsertOptionsMappingDataServices} : defaultInsertOptionsMappingDataServices;
    return super.insert(valueArrayOrFunction, clientId, options);
  }

  async update(valueArrayOrFunction: Array<T> | ((storageData: Array<T>) => (Array<T> | T | undefined)) | T, clientId?: IdType): Promise<Array<T>> {
    throw new Error('update is not supported for this DataService.');
  }

  async delete(valueArrayOrFunction: Array<T> | ((storageData: Array<T>) => (Array<T> | T | undefined)) | T, clientId?: IdType, options?: DataServiceDeleteOptions): Promise<void> {
    options = options ? {...options, ...defaultInsertOptionsMappingDataServices} : defaultInsertOptionsMappingDataServices;
    return super.delete(valueArrayOrFunction, clientId, options);
  }

}
