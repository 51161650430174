<ion-header *ngIf="protocolEntry && !(keyboardIsShown$ | async)">
  <ion-toolbar color="omg-toolbar-bg" *ngIf="isNavigationVisible" class="toolbar-small protocol-entry-edit-navigation-toolbar">
    <ng-container *ngIf="isNavigationButtonsVisible">
      <div class="navigation-buttons ion-hide-lg-up d-flex gap-2" slot="start" *ngIf="!acrossProjects">
        <ion-button id="protocol-entry-navigation-prev-button" class="protocol-entry-navigation-prev-button omg-btn-secondary" (click)="previousEntry()" [disabled]="this.previousProtocolEntry?.id | isEmpty" >
          <fa-icon [icon]="['fal', 'arrow-left']"></fa-icon>
        </ion-button>
        <app-tooltip target="protocol-entry-navigation-prev-button" placement="bottom">
          {{ 'Previous' | translate }}
        </app-tooltip>
        <ion-button id="protocol-entry-navigation-next-button" class="protocol-entry-navigation-next-button omg-btn-secondary" (click)="nextEntry()" [disabled]="this.nextProtocolEntry?.id | isEmpty">
          <fa-icon [icon]="['fal', 'arrow-right']"></fa-icon>
        </ion-button>
        <app-tooltip target="protocol-entry-navigation-next-button" placement="bottom">
          {{ 'Next' | translate }}
        </app-tooltip>
      </div>

      <div class="d-flex gap-2" slot="end" *ngIf="isEditEnabled">
        <ion-button ph-custom-id="add-subentry-header-btn" class="omg-btn-secondary" (click)="addSubEntry('headerSubButton')" *ngIf="canAddSubentries && !isSubEntry && !isProtocolLayoutShort" [disabled]="(protocolData | async)?.closedAt | isEmpty:'reverse'">
          <fa-icon [icon]="['fal', 'plus']"></fa-icon>
          <span class="padding-left-10">{{ 'Subentry' | translate }}</span>
        </ion-button>
        <ion-button ph-custom-id="action-entry-btn" id="protocol-entry-action"
                    (click)="openEntryActionsPopopver()" class="omg-btn-secondary">
          <fa-icon [icon]="['fal', 'ellipsis-h']"></fa-icon>
        </ion-button>
        <app-tooltip target="protocol-entry-action" placement="bottom">
          {{ 'actions' | translate }}
        </app-tooltip>
      </div>
    </ng-container>
  </ion-toolbar>

  <ion-toolbar color="omg-card-surface" class="toolbar-small protocol-entry-edit-action-toolbar">
    <div class="protocol-entry-edit-action-buttons d-flex gap-2 margin-left-5">

      <ion-button *ngIf="isEditEnabled || isMarkerApplied || isPdfPlanPageMarkingsApplied" (click)="openPDFFolderMarker()"
                  [disabled]="!isMarkerApplied && !isPdfPlanPageMarkingsApplied && (isCarriedEntry || ((protocolData | async)?.closedAt | isEmpty:'reverse'))"
                  class="omg-btn-secondary top-menus protocol-entry-open-pdf-folder-button" ph-custom-id="plan-marker-btn">
        <div>
          <fa-icon *ngIf="!isMarkerApplied && !isPdfPlanPageMarkingsApplied" [icon]="['fal', 'map-marker-alt']" size="lg"></fa-icon>
          <fa-icon *ngIf="isMarkerApplied || isPdfPlanPageMarkingsApplied" [icon]="['fas', 'map-marker-alt']" size="lg"></fa-icon>
          <div class="ion-hide-sm-down ion-text-center top-menu-label margin-top-10">
            <span>{{ 'marker' | translate }}</span>
          </div>
        </div>
      </ion-button>

      <ion-button *ngIf="isEditEnabled || isBimMarkerApplied" (click)="openAutodeskViewer()"
                  [disabled]="!isBimMarkerApplied && (isCarriedEntry || ((protocolData | async)?.closedAt | isEmpty:'reverse'))"
                  class="omg-btn-secondary top-menus protocol-entry-open-pdf-folder-button ion-hide-sm-down" ph-custom-id="bim-marker-btn">
        <div>
          <span class="bim-icon" [class.marker-applied]="isBimMarkerApplied">BIM</span>
          <div class="ion-hide-sm-down ion-text-center top-menu-label margin-top-10">
            <span>{{ 'marker' | translate }}</span>
          </div>
        </div>
      </ion-button>

      <ion-button *ngIf="isEditEnabled || isBimMarkerApplied" (click)="openAutodeskViewer()"
      [disabled]="!isBimMarkerApplied && (isCarriedEntry || ((protocolData | async)?.closedAt | isEmpty:'reverse'))"
      class="omg-btn-secondary top-menus protocol-entry-open-pdf-folder-button ion-hide-sm-up" ph-custom-id="bim-marker-btn">
        <div>
          <span class="bim-icon" [class.marker-applied]="isBimMarkerApplied">BIM</span>
        </div>
      </ion-button>

      <ng-container *ngIf="isEditEnabled">

        <input type="file" [accept]="acceptedMimeTypesForUpload" (change)="uploadFileEvent($event)" multiple #fileUpload
              class="ion-hide">
        <ion-button class="top-menus protocol-entry-file-upload-button ion-hide-sm-down omg-btn-secondary" (click)="fileUpload.click()"
                    [disabled]="isCarriedEntry || (protocolData | async)?.closedAt | isEmpty:'reverse'" ph-custom-id="upload-file-btn">
          <div>
            <fa-icon [icon]="['fal', 'paperclip']" size="lg"></fa-icon>
            <div class="ion-hide-sm-down ion-text-center top-menu-label margin-top-10">
              <span>{{ 'file' | translate }}</span>
            </div>
          </div>
        </ion-button>

        <ion-button class="top-menus protocol-entry-file-upload-button ion-hide-sm-down omg-btn-secondary" (click)="projectRoomAttachmentsSelector()"
                    [disabled]="isCarriedEntry || ((protocolData | async)?.closedAt | isEmpty:'reverse')" ph-custom-id="project-room-btn">
          <div>
            <fa-icon [icon]="['fal', 'cube']" size="lg"></fa-icon>
            <div class="ion-hide-sm-down ion-text-center top-menu-label margin-top-10">
              <span>{{ 'attachmentFileProjectRoomPopover.projectRoom' | translate }}</span>
            </div>
          </div>
        </ion-button>

        <ion-button class="top-menus ion-hide-sm-down omg-btn-secondary" (click)="openSketchingTool()" ph-custom-id="drawing-btn"
                    [disabled]="isCarriedEntry || ((protocolData | async)?.closedAt | isEmpty:'reverse')" [class.ion-hide]="isFourColumnWithNotEnoughSpace | async">
          <div>
            <fa-icon [icon]="['fal', 'signature']" size="lg"></fa-icon>
            <div class="ion-hide-sm-down ion-text-center top-menu-label margin-top-10">
              <span>{{ 'drawing' | translate }}</span>
            </div>
          </div>
        </ion-button>

        <ion-button (click)="startAudioRecording()" [disabled]="isCarriedEntry ||  ((protocolData | async)?.closedAt | isEmpty:'reverse') || !audioRecordingSupported"
                    class="omg-btn-secondary top-menus ion-hide-sm-down protocol-entry-start-audio-record-button" [class.ion-hide]="isFourColumnWithNotEnoughSpace | async"
                    ph-custom-id="audio-btn">
          <div>
            <fa-icon [icon]="['fal', 'microphone']" size="lg"></fa-icon>
            <div class="ion-hide-sm-down ion-text-center top-menu-label margin-top-10">
              <span>Audio</span>
            </div>
          </div>
        </ion-button>

        <ion-button class="omg-btn-secondary top-menus protocol-entry-take-picture-button" (click)="takePicture()" ph-custom-id="photo-btn"
                    [disabled]="isCarriedEntry || !mediaCaptureSupported || (protocolData | async)?.closedAt | isEmpty:'reverse'" [class.ion-hide]="isFourColumnWithNotEnoughSpace | async">
          <div>
            <fa-icon [icon]="['fal', 'camera']" size="lg"></fa-icon>
            <div class="ion-hide-sm-down ion-text-center top-menu-label margin-top-10">
              <span>{{ 'Photo' | translate }}</span>
            </div>
          </div>
        </ion-button>

        <ion-button class="omg-btn-secondary top-menus protocol-entry-take-picture-button" (click)="takePictures()" ph-custom-id="photos-btn"
                    [disabled]="isCarriedEntry || !mediaCaptureSupported || (protocolData | async)?.closedAt | isEmpty:'reverse'" [class.ion-hide]="isFourColumnWithNotEnoughSpace | async">
          <div>
            <fa-icon [icon]="['bau', 'foto-series']" size="lg"></fa-icon>

            <div class="ion-hide-sm-down ion-text-center top-menu-label margin-top-10">
              <span>{{ 'Photos' | translate }}</span>
            </div>
          </div>
        </ion-button>

        <ion-button class="top-menus ion-hide-sm-up omg-btn-secondary" (click)="openPopoverMenu($event)" [disabled]="isCarriedEntry || ((protocolData | async)?.closedAt | isEmpty:'reverse')" ph-custom-id="popover-btn">
          <div>
            <fa-icon [icon]="['fal', 'ellipsis-h']" size="lg"></fa-icon>
          </div>
        </ion-button>

        <ion-button class="top-menus omg-btn-secondary" (click)="openActions()" [disabled]="isCarriedEntry || ((protocolData | async)?.closedAt | isEmpty:'reverse')" [class.ion-hide]="!(isFourColumnWithNotEnoughSpace | async)" ph-custom-id="actions-btn">
          <div>
            <fa-icon [icon]="['fal', 'ellipsis-h']" size="lg"></fa-icon>

            <div class="ion-text-center top-menu-label margin-top-10">
              <span>{{ 'other' | translate }}</span>
            </div>
          </div>
        </ion-button>

      </ng-container>
      
    </div>
  </ion-toolbar>
</ion-header>

<ion-content color="omg-card-surface">
  <div class="entry-wrapper">
  <ng-container *ngIf="protocolEntry; else noProtocolEntry">
  <ion-grid class="no-padding-bottom padding-left-5">
    <ion-row>
      <ion-col>
        <div>
          <strong class="size-xs text-primary">
            {{ selectedProject?.name }}
            > {{ (protocolData | async)?.name }}
            <span *ngIf="protocolEntry?.parentId | isEmpty:'reverse'"> > {{ (getProtocolEntryById(protocolEntry?.parentId) | async)?.title }} </span>
          </strong>
        </div>
        <div class="margin-top-10 d-flex ion-align-items-center">
          <app-protocol-entry-short-id [isTask]="isTask" [acrossProjects]="acrossProjects" [isProtocolLayoutShort]="isProtocolLayoutShort" [protocolEntry]="protocolEntry" class="app-protocol-entry-id"></app-protocol-entry-short-id>
          <span class="text-secondary size-xs ion-padding-start">
            {{'protocolEntry.createdAt' | translate}} {{protocolEntry.createdAt | date: 'dd.MM.yyyy'}}
            <ng-container *ngIf="!(((protocolData | async)?.closedAt | isEmpty:'reverse') || isCarriedEntry || !isEditEnabled)">
              <fa-icon ph-custom-id="entry-edit-created-at" class="cursor-pointer" [icon]="['fal','pencil']" (click)="calendar.open()"></fa-icon>
              <ion-input
                #calendar="mobiscroll"
                hidden
                display="center"
                [ngModel]="protocolEntry.createdAt"
                (ngModelChange)="handleCreatedAtChanged($event)"
                class="text-primary datepicker-created-at"
                [dateFormat]="MBSC_DATE_FORMAT"
                [showWeekNumbers]="true"
                [firstSelectDay]="1"
                [firstDay]="1"
                [setText]="'button.apply' | translate"
                [cancelText]="'cancel' | translate"
                [buttons]="[ 'cancel', 'set' ]"
                [locale]="mbscLocale$ | async"
                [themeVariant]="mbscThemeVariant$ | async"
                theme="windows"
                [required]="true"
                [disabled]="!isEditEnabled"
                mbsc-datepicker [controls]="['calendar']"></ion-input>
            </ng-container>
          </span>
          <ng-container *ngIf="protocolEntry?.createdInProtocolId && protocolEntry.createdInProtocolId !== protocolId && (isBeforeCreatedInProtocol$ | async)">
            <div [id]="'protocol-entry-added-later-warning-' + instanceNumber" class="ion-margin-start omg-t-xs-body-bold pl-1 pr-1 added-later">
              {{ 'protocolEntry.createdInAnotherProtocol' | translate }}
            </div>
            <app-tooltip [target]="'protocol-entry-added-later-warning-' + instanceNumber" placement="bottom">
              {{ 'protocolEntry.createdInAnotherProtocolTooltip' | translate:{shortName: (createdInProtocolShortName$ | async)} }}
            </app-tooltip>
          </ng-container>
        </div>
      </ion-col>
      <ion-col size="4">
        <div class="d-inline-flex ion-float-end ion-align-items-center">
          <div class="text-priority-container margin-right-10 ion-hide-sm-down ion-text-end size-xs" [ngClass]="'app-priority-' + protocolEntry?.priority">
            <span *ngIf="protocolEntry?.priority | isEmpty">{{ 'no_priority' | translate }}</span>
            <span *ngIf="protocolEntry?.priority === priorityLevel.HIGH">{{ 'highPriority' | translate }}</span>
            <span *ngIf="protocolEntry?.priority === priorityLevel.MEDIUM">{{ 'mediumPriority' | translate }}</span>
            <span *ngIf="protocolEntry?.priority === priorityLevel.LOW">{{ 'lowPriority' | translate }}</span>
          </div>

          <ion-buttons class="margin-right-10">
            <ion-button ph-custom-id="entry-edit-priority-btn" [disabled]="((protocolData | async)?.closedAt | isEmpty:'reverse') || !isEditEnabled" (click)="showPriorityPopOver($event)"  [class]="'app-default-priority app-priority-' + protocolEntry?.priority">
              <fa-icon [icon]="['bau', 'flag']"></fa-icon>
            </ion-button>
          </ion-buttons>
          <ion-button ph-custom-id="entry-edit-status-btn" fill="clear" class="protocol-entry-status" [disabled]="!statusFieldActive && !isProtocolLayoutContinuous">
            <app-protocol-entry-status
              [acrossProjects]="acrossProjects"
              [protocolEntry]="protocolEntry"
              [showLabel]="false"
              [canEdit]="true"
              [isProtocolLayoutShort]="isProtocolLayoutShort"
              [isProtocolLayoutContinuous]="isProtocolLayoutContinuous"
              [isTaskProtocol]="isTask"
              (statusChange)="onChangeProtocolEntryStatus($event)"
              (isContinuousInfoChange)="onIsContinuousInfoChange($event)"
              [(statusFieldActive)]="statusFieldActive"
            ></app-protocol-entry-status>
          </ion-button>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>

  <app-protocol-entry-form
    [onlyActionableEntryTypes]="onlyActionableEntryTypes"
    [defaultEntryType]="defaultEntryType"
    [typeRequired]="typeRequired"
    [acrossProjects]="acrossProjects"
    [protocolId]="protocolId"
    [protocolEntryId]="protocolEntryId"
    [isTask]="isTask"
    [isCarriedEntry] = "isCarriedEntry"
    [(formDirty)]="protocolEntryFormDirty"
    [closeForm]="((protocolData | async)?.closedAt | isEmpty:'reverse') || isCarriedEntry"
    [readonly]="!isEditEnabled"
    (formChange)="protocolEntryFormChanged($event)"
    (additionalFieldsChange)="onAdditionalFieldsChange($event)"></app-protocol-entry-form>

  <ion-grid>
    <ion-row>
      <ion-col class="ion-padding-top protocol-entry-attachments">
        <app-protocol-entry-attachments [acrossProjects]="acrossProjects"
                                        [protocolEntry]="protocolEntry"
                                        [allowDeleting]="((protocolData | async)?.closedAt | isEmpty) && !isCarriedEntry"
                                        [allowEditMarkings]="((protocolData | async)?.closedAt | isEmpty) && !isCarriedEntry"
                                        (markingsChanged)="onMarkingsChanged($event.attachment, $event.markings)"
                                        (attachmentDeleted)="onAttachmentDeleted($event.attachment)">
        </app-protocol-entry-attachments>
        <input (change)="uploadFileEvent($event)" [accept]="acceptedMimeTypesForUpload" class="ion-hide" multiple type="file" >
      </ion-col>
    </ion-row>

    <ion-row *ngIf="!isProtocolLayoutShort">
      <ion-col class="ion-padding-top">
        <ion-row>
          <ion-item lines="none" class="cursor-pointer no-flex" (click)="toggleEntries()">
            <ion-text *ngIf="(protocolEntry?.parentId | isEmpty) && !isTask" color="primary">{{ 'Subentries' | translate }}</ion-text>
            <ion-text *ngIf="(protocolEntry?.parentId | isEmpty:'reverse') && !isTask" color="primary">{{ 'parent_entry' | translate }}</ion-text>
            <ion-text *ngIf="(protocolEntry?.parentId | isEmpty) && isTask" color="primary">{{ 'tasks.subtasks' | translate }}</ion-text>
            <ion-text *ngIf="(protocolEntry?.parentId | isEmpty:'reverse') && isTask" color="primary">{{ 'tasks.maintask' | translate }}</ion-text>
            <ion-badge color="primary" class="ion-margin-start">{{ (protocolSubEntries | async)?.length }}</ion-badge>
          </ion-item>
          <ion-buttons class="d-inline-flex">
            <ion-button ph-custom-id="add-subentry-plus-btn" class="omg-btn-text" *ngIf="canAddSubentries && !isSubEntry  && !isProtocolLayoutShort" (click)="addSubEntry('plusSubButton')" [disabled]="((protocolData | async)?.closedAt | isEmpty:'reverse')">
              <fa-icon [icon]="['fal', 'plus-circle']" size="lg"></fa-icon>
            </ion-button>
          </ion-buttons>
        </ion-row>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="showSubEntries && !isProtocolLayoutShort">
      <ion-col class="ion-padding-top protocol-entry-sub-entries" *ngIf="protocolEntry?.parentId | isEmpty">
        <app-protocol-entry *ngFor="let item of (protocolSubEntries | async)"
          [acrossProjects]="acrossProjects"
          [protocolId]="protocolId"
          [protocolEntryId]="item.id"
          [showSubEntriesCount]="false"
          [showFooterButtons]="false"
          [showActiveMarker]="false"
          [isNavigable]="isNavigationEnabled"
          [isTask]="isTask"
          [ignoreSelectMode]="true"></app-protocol-entry>
      </ion-col>
      <ion-col class="ion-padding-top protocol-entry-parent-entries" *ngIf="protocolEntry?.parentId | isEmpty:'reverse'">
        <app-protocol-entry
          [acrossProjects]="acrossProjects"
          [protocolEntryId]="protocolEntry.parentId"
          [showSubEntriesCount]="false"
          [showFooterButtons]="false"
          [showActiveMarker]="false"
          [isNavigable]="isNavigationEnabled"
          [isTask]="isTask"
          [ignoreSelectMode]="true"></app-protocol-entry>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col class="ion-padding-top protocol-entry-chats">
        <app-protocol-entry-chats [acrossProjects]="acrossProjects"
                                  [protocolEntry]="protocolEntry"
                                  [protocol]="protocolData | async"
                                  (attachmentDeleted)="onAttachmentDeleted($event.attachment)"
                                  (markingsChanged)="onMarkingsChanged($event.attachment, $event.markings)"
                                  [isCarriedEntry] = "isCarriedEntry"
                                  class="full-width"></app-protocol-entry-chats>
      </ion-col>
    </ion-row>

  </ion-grid>
  </ng-container>
  <ng-template #noProtocolEntry>
    <ion-grid class="app-add-entry">
      <ion-row>
        {{'protocol.add_entry' | translate}}
      </ion-row>
    </ion-grid>
  </ng-template>
  </div>
</ion-content>
