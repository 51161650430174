import {Injectable, OnDestroy} from '@angular/core';
import {ClientDataService} from '../data/client-data.service';
import {Observable, ReplaySubject, Subscription} from 'rxjs';
import {Client, ClientType, IdType, UpdateClientNameReq} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import {observableToPromise} from '../../utils/async-utils';
import {distinctUntilChanged, filter, map, switchMap, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

export class ProjectNotFoundError extends Error {}

const LOG_SOURCE = 'ClientService';

@Injectable({
  providedIn: 'root'
})
export class ClientService implements OnDestroy {
  private currentClient: Client|undefined;
  private currentClientIdSubject = new ReplaySubject<IdType|undefined>(1);
  public readonly currentClient$ = this.currentClientIdSubject.asObservable().pipe(
    switchMap((clientId) => this.clientDataService.getById(clientId)),
    tap((client) => this.currentClient = client)
  );
  private clients: Array<Client>|undefined;
  private readonly clientsSubscription: Subscription;
  public readonly clients$ = this.clientDataService.data;
  public readonly ownClient$: Observable<Client|undefined> = this.clientDataService.data.pipe(map((clients) => clients.find((client) => client.type === ClientType.OWN)));
  public readonly ownClientNameableDropdownName$ = this.ownClient$.pipe(
    filter((client) => Boolean(client)),
    map((client) => client.nameableDropdownName),
    distinctUntilChanged()
  );

  constructor(private clientDataService: ClientDataService, private loggingService: LoggingService, private http: HttpClient) {
    this.clientsSubscription = this.clientDataService.data.subscribe((clients) => {
      this.clients = clients;
      if (!clients?.length) {
        this.currentClient = undefined;
        this.currentClientIdSubject.next(undefined);
        return;
      }
      // If the client is not selected or when the currently selected client is not present in a new array of clients,
      // do fallback to the own client.
      if (!this.currentClient || !this.clients.some((client) => client.id === this.currentClient?.id)) {
        const ownClient = this.clients.find((client) => client.type === undefined || client.type === ClientType.OWN);
        if (!ownClient) {
          this.loggingService.warn(LOG_SOURCE, ' Unable to find ownClient in the list of clients.');
          this.currentClient = undefined;
          this.currentClientIdSubject.next(undefined);
          return;
        }
        this.currentClient = ownClient;
        this.currentClientIdSubject.next(ownClient.id);
      }
    });
  }

  public getCurrentClient(): Client|undefined {
    return this.currentClient;
  }

  public getCurrentClientMandatory(): Client {
    const client = this.currentClient;
    if (!client) {
      throw new Error('No current client set.');
    }
    return client;
  }

  private setCurrentClientValidated(newCurrentClient: Client): boolean {
    if (this.currentClient?.id === newCurrentClient.id) {
      return false;
    }
    this.currentClient = newCurrentClient;
    this.currentClientIdSubject.next(newCurrentClient.id);
    return true;
  }

  public async setCurrentClientId(newCurrentClientId: IdType) {
    const clients = this.clients || await observableToPromise(this.clients$);
    if (!clients) {
      throw new Error('Cannot set current client because clients are not yet initialized.');
    }
    const newClient = clients.find((client) => client.id === newCurrentClientId);
    if (!newClient) {
      throw new ProjectNotFoundError(`Cannot set currentClient with id ${newCurrentClientId} because it does not exist in the list of clients.`);
    }
    return this.setCurrentClientValidated(newClient);
  }

  public setCurrentClient(newCurrentClient: Client): boolean {
    if (!this.clients) {
      throw new Error('Cannot set current client because clients are not yet initialized.');
    }
    if (!this.clients.some((value) => value.id === newCurrentClient.id)) {
      throw new Error(`Cannot set current client ${newCurrentClient.id} because it is not in the list of clients.`);
    }
    return this.setCurrentClientValidated(newCurrentClient);
  }

  public setDefaultClient(): boolean {
    if (!this.clients?.length) {
      return false;
    }
    const ownClient = this.clients.find((client) => client.type === undefined || client.type === ClientType.OWN);
    if (!ownClient) {
      throw new Error('Cannot find own client in list of clients.');
    }
    return this.setCurrentClientValidated(ownClient);
  }

  public getOwnClient(): Observable<Client|undefined> {
    return this.clientDataService.getOwnClient();
  }

  public async getOwnClientMandatory(): Promise<Client> {
    const client = await observableToPromise(this.clientDataService.getOwnClient());
    if (!client) {
      throw new Error('ownClient not defined.');
    }
    return client;
  }

  ngOnDestroy(): void {
    this.clientsSubscription.unsubscribe();
  }

  async updateClientName(client: Client, newClientName: string, abortSignal?: AbortSignal): Promise<void> {
    const url = this.getUpdateClientNameUrl(client.id);
    const payload: UpdateClientNameReq = {clientName: newClientName};
    await observableToPromise(this.http.post<void>(url, payload));
  }

  private getUpdateClientNameUrl(clientId: IdType): string {
    return environment.serverUrl + `client/${clientId}/updateName`;
  }
}
