import {
  AttachmentProtocolEntry,
  AttachmentUserEmailSignature,
  IdType,
  Protocol,
  ProtocolEntry,
  ProtocolType
} from '../models';
import Translation from '../i18n/translation';
import {EntryMailData} from './entryMail.model';
import {EntryMailReq} from '../requestResponse';
import {convertISOStringToDate} from '../commonUtils';
import moment from 'moment';
import {AttachmentWithContent} from '../pdf/protocol/pdfProtocol.model';
import {EMPTY_IMAGE_BASE64, TASK_PROTOCOL_NAME, TASK_PROTOCOL_TYPE_CODE, TASK_PROTOCOL_TYPE_NAME} from '../constants';
import {convertRichTextToPlainText} from '../pdf/common-report-utils';
import _ from 'lodash';

const BR = '<br>';
const IMAGE_WIDTH_HEIGHT: Record<'SMALL' | 'MEDIUM', {width: number, height: number}> = {
  SMALL: {width: 232, height: 174},
  MEDIUM: {width: 550, height: 412}
};

export class CommonEntryMailService {
  private translation = new Translation();

  async generateHtml(data: EntryMailData, req: EntryMailReq, lng?: string): Promise<string> {
    const regExpNewLine = new RegExp('\n', 'g');
    const language = lng ?? req.language ?? data.project.language;
    const i18n = this.translation.getTranslation(language);
    if (!i18n) {
      throw new Error(`Unable to find translations for language "${lng}".`);
    }
    let html = `<html lang="${language}"><head><meta charset="utf-8"></head><body>`;
    if (req.text) {
      html += `${req.text.replace(regExpNewLine, BR)}${BR}`;
    }

    const entries = this.sortEntriesSameAsEntryIds(data.entries, req);
    for (const entry of entries) {
      const {protocol, protocolType} = this.getProtocolOfEntry(entry, data);
      const isTaskProtocol = protocol.number === 0 && protocol.name === TASK_PROTOCOL_NAME && protocolType.name === TASK_PROTOCOL_TYPE_NAME && protocolType.code === TASK_PROTOCOL_TYPE_CODE;
      const entryShortId = isTaskProtocol ? this.getEntryShortId(entry, data) : this.getProtocolEntryShortId(entry, data);
      const entryAttachments = data.attachmentsWithContent
        .filter((attachment) => attachment.attachment.protocolEntryId === entry.id && this.isImage(attachment.attachment.mimeType));
      html += BR;
      html += `${entryShortId} ${entry.title}${BR}`;
      html += entry.text ? `<div>${convertRichTextToPlainText(entry.text)}${BR}</div>` : '';
      html += this.getAdditionalFields(entry, data, i18n) + BR;
      html += this.entryAttachmentsAsHtml(entryAttachments);
    }

    if (req.mailTextUnderContent) {
      html += `${req.mailTextUnderContent.replace(regExpNewLine, BR)}${BR}`;
    }

    if (req.emailSignature) {
      html += BR;
      if (req.emailSignature.text) {
        const emailSignatureText = req.emailSignature.text.replace(regExpNewLine, BR);
        html += emailSignatureText + BR + BR + '\n';
      }
      if (req.emailSignature.includeCompanyLogo && data.attachmentUserEmailSignature) {
        html += this.entryAttachmentsAsHtml([data.attachmentUserEmailSignature], req.emailSignature.size);
      }
    }
    html += '</body></html>';

    return html;
  }

  private getProtocolOfEntry(entry: ProtocolEntry, data: EntryMailData): {protocol: Protocol, protocolType: ProtocolType} {
    const protocol = data.protocols.find((value) => value.id = entry.protocolId);
    if (!protocol) {
      throw new Error(`Unable to find protocol with id ${entry.protocolId} for protocolEntry ${entry.id}.`);
    }
    const protocolType = data.lookup.protocolTypes.get(protocol.typeId);
    if (!protocolType) {
      throw new Error(`Unable to find ProtocolType with id ${protocol.typeId} for protocol ${protocol.id}.`);
    }
    return {protocol, protocolType};
  }

  private sortEntriesSameAsEntryIds(entries: Array<ProtocolEntry>, req: EntryMailReq): Array<ProtocolEntry> {
    return _.orderBy(entries, (entry) => req.entryIds.indexOf(entry.id));
  }

  private getAdditionalFields(entry: ProtocolEntry, data: EntryMailData, i18n: Map<string, string>): string {
    const tokens = new Array<string>();

    if (entry.allCompanies) {
      tokens.push(`${i18n.get('company')}: ${i18n.get('project_team')}`);
    } else if (entry.companyId) {
      const company = data.lookup.companies.get(entry.companyId);
      if (!company) {
        throw new Error(`Company with id ${entry.companyId} not found.`);
      }
      tokens.push(`${i18n.get('company')}: ${company?.name}`);
    }

    if (entry.internalAssignmentId) {
      const name = this.getProfileName(entry.internalAssignmentId, data);
      if (name) {
        tokens.push(`${i18n.get('responsible')}: ${name}`);
      }
    }

    const entryObserverCompanies = data.observerCompanies.filter((observerCompany) => observerCompany.protocolEntryId === entry.id);
    if (entryObserverCompanies.length) {
      const companyNames = new Array<string>();
      for (const observerCompany of entryObserverCompanies) {
        const company = data.lookup.companies.get(observerCompany.companyId);
        if (!company) {
          throw new Error(`Company with id ${observerCompany.companyId} not found`);
        }
        companyNames.push(company.name);
      }
      tokens.push(`${i18n.get('observerCompanies')}: ${companyNames.join(', ')}`);
    }

    if (entry.typeId) {
      const type = data.lookup.protocolEntryTypes.get(entry.typeId);
      if (!type) {
        throw new Error(`ProtocolEntry type with id ${entry.typeId} not found`);
      }
      tokens.push(`${i18n.get('type')}: ${type.name}`);
    }

    if (entry.craftId) {
      const craft = data.lookup.crafts.get(entry.craftId);
      if (!craft) {
        throw new Error(`Craft type with id ${entry.craftId} not found`);
      }
      tokens.push(`${i18n.get('craft')}: ${craft.name}`);
    }

    if (entry.locationId) {
      const location = data.lookup.locations.get(entry.locationId);
      tokens.push(`${i18n.get('location')}: ${location?.location ?? ''}`);
    }

    if (entry.nameableDropdownId) {
      const nameableDropdown = data.lookup.nameableDropdowns.get(entry.nameableDropdownId);
      tokens.push(`${data.nameableDropdownName}: ${nameableDropdown?.name ?? ''}`);
    }

    if (entry.cost) {
      tokens.push(`${i18n.get('costWithoutTax')}: ${entry.cost}`);
    }

    if (entry.startDate) {
      const startDate = convertISOStringToDate(entry.startDate);
      tokens.push(`${i18n.get('startDate')}: ${moment(startDate).format('DD.MM.YYYY')}`);
    }

    if (entry.todoUntil) {
      const todoUntilDate = convertISOStringToDate(entry.todoUntil);
      tokens.push(`${i18n.get('todoUntil')}: ${moment(todoUntilDate).format('DD.MM.YYYY')}`);
    }

    return tokens.join(' | ');
  }

  private getProfileNames(profileIds: Array<IdType>, data: EntryMailData): Array<string> {
    const names = new Array<string>();
    for (const profileId of profileIds) {
      const profile = data.lookup.profiles.get(profileId);
      if (!profile) {
        throw new Error(`Profile with id ${profileId} not found.`);
      }
      if (profile?.addressId) {
        const address = data.lookup.addresses.get(profile.addressId);
        if (!address) {
          throw new Error(`Address with id ${profile.addressId} not found.`);
        }
        if (address.firstName || address.lastName) {
          const name = ((address.firstName ?? '')  + ' ' + (address.lastName ?? '')).trim();
          names.push(name);
        }
      }
    }
    return names;
  }

  private getProfileName(profileId: IdType, data: EntryMailData): string|undefined {
    const names = this.getProfileNames([profileId], data);
    return names.length ? names[0] : undefined;
  }

  private entryAttachmentsAsHtml(entryAttachments: AttachmentWithContent<AttachmentProtocolEntry | AttachmentUserEmailSignature>[], size: 'SMALL' | 'MEDIUM' | 'ORIGINAL' = 'SMALL') {
    let html = '';

    let styleImgMaxWidthHeight = '';
    let styleImgFixedWidthHeight = '';

    if (size !== 'ORIGINAL') {
      const width = IMAGE_WIDTH_HEIGHT[size].width;
      const height = IMAGE_WIDTH_HEIGHT[size].height;
      styleImgMaxWidthHeight = `style="max-width: ${width}px; max-height: ${height}px;"`;
      styleImgFixedWidthHeight = `style="width: ${width}px; height: ${height}px;"`;
    }

    for (const entryAttachment of entryAttachments) {
      if (entryAttachment.publicLink) {
        html += `<img src="${entryAttachment.publicLink}" ${styleImgMaxWidthHeight} />`;
      } else if (entryAttachment.contentBase64) {
        const contentBase64 = entryAttachment.contentBase64.startsWith('data:') ? entryAttachment.contentBase64 : 'data:' + entryAttachment.contentBase64;
        html += `<img src="${contentBase64}" ${styleImgMaxWidthHeight} />`;
      } else {
        html += `<img src="${EMPTY_IMAGE_BASE64}" ${styleImgFixedWidthHeight} />`;
      }
      html += BR;
    }
    return html;
  }

  protected isImage(mimeType: string): boolean {
    return mimeType.startsWith('image/');
  }

  private getEntryShortId(entry: ProtocolEntry, data: EntryMailData): string {
    let numberAsString = `${entry.number}.`;
    if (entry.parentId) {
      const parentEntry = data.entries.find((value) => value.id === entry.parentId);
      if (!parentEntry) {
        throw new Error(`ParentEntry ${entry.parentId} not found.`);
      }
      numberAsString = `${parentEntry.number}.` + numberAsString;
    }
    return numberAsString;
  }

  private getProtocolEntryShortId(protocolEntry: ProtocolEntry, data: EntryMailData): string {
    const {protocol, protocolType} = this.getProtocolOfEntry(protocolEntry, data);
    let shortId = protocolType?.code;
    shortId += _.padStart(protocol?.number + '', 2, '0');
    if (protocolEntry?.parentId) {
      const parentProtocolEntry = data.entries.find(entry => entry.id === protocolEntry.parentId);
      shortId += '.' + _.padStart(parentProtocolEntry?.number + '', 3, '0');
    }
    shortId += '.' + _.padStart(protocolEntry?.number + '', 3, '0');
    return shortId + '';
  }
}
