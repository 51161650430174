import {Injectable} from '@angular/core';
import {Nullish} from 'src/app/model/nullish';
import {observableToPromise} from 'src/app/utils/async-utils';
import {getProtocolEntryCompanyId} from 'src/app/utils/protocol-entry-company-utils';
import {IdType, ProtocolEntryCompany} from 'submodules/baumaster-v2-common';
import {ProtocolEntryCompanyDataService} from '../data/protocol-entry-company-data.service';
import {ProtocolService} from './protocol.service';

@Injectable({
  providedIn: 'root'
})
export class ProtocolEntryCompanyService {

  constructor(
    private protocolEntryCompanyDataService: ProtocolEntryCompanyDataService,
    private protocolService: ProtocolService,
  ) { }

  async saveObserverCompanies(protocolEntryId: IdType, companyIds: Nullish<IdType[]>, acrossProjects = false): Promise<void> {
    const project = await observableToPromise(this.protocolService.getProjectByEntryId(protocolEntryId));

    if (!project) {
      throw new Error(`Cannot find projectId for ${protocolEntryId}!`);
    }

    const mappedObjects = await observableToPromise(
      acrossProjects
        ? this.protocolEntryCompanyDataService.findAllByProtocolEntryIdAcrossProjects(protocolEntryId)
        : this.protocolEntryCompanyDataService.findAllByProtocolEntryId(protocolEntryId)
    );

    if (!companyIds?.length) {
      if (mappedObjects.length) {
        await this.protocolEntryCompanyDataService.delete(mappedObjects, project.id);
      }

      return;
    }

    const added: ProtocolEntryCompany[] = companyIds.filter((id) => mappedObjects.every(({ companyId }) => companyId !== id)).map((companyId) => ({
      id: getProtocolEntryCompanyId(protocolEntryId, companyId),
      protocolEntryId,
      companyId,
      changedAt: new Date().toISOString(),
    }));
    const removed = mappedObjects.filter(({ companyId }) => !companyIds.includes(companyId));

    if (added.length || removed.length) {
      await this.protocolEntryCompanyDataService.insertUpdateDelete({
        inserts: added,
        deletes: removed,
      }, project.id);
    }
  }
}
