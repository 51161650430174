import { AfterViewInit, Directive, ElementRef, forwardRef, HostListener } from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {CheckboxChangeEventDetail} from '@ionic/angular';
import {LoggingService} from 'src/app/services/common/logging.service';

const LOG_SOURCE = 'CheckboxGroupDirective';

@Directive({
  selector: '[appCheckboxGroup]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckboxGroupDirective),
    multi: true
  }]
})
export class CheckboxGroupDirective implements ControlValueAccessor, AfterViewInit {

  value: string[] = [];
  private propagateOnChange = (_: any) => { };
  private propagateOnTouched = () => { };

  constructor(private elementRef: ElementRef<HTMLElement>, private loggingService: LoggingService) { }

  @HostListener('ionChange', ['$event'])
  ionChange(event: CustomEvent<CheckboxChangeEventDetail>) {
    if (!(event.target instanceof HTMLElement) || event.target.nodeName.toLowerCase() !== 'ion-checkbox') {
      return;
    }

    const isChecked = this.value.includes(event.detail.value);

    if (isChecked !== event.detail.checked) {
      if (event.detail.checked) {
        this.value = [...this.value, event.detail.value];
      } else {
        this.value = this.value.filter((v) => v !== event.detail.value);
      }


      this.propagateOnChange(this.value);
      this.propagateOnTouched();
    }
  }

  ngAfterViewInit() {
    this.updateCheckboxes();
  }

  writeValue(obj: any): void {
    if (!obj) {
      this.value = [];
    } else if (!Array.isArray(obj)) {
      this.loggingService.warn(LOG_SOURCE, 'Invalid value passed to CheckboxGroupDirective, only valid value is an array of strings or nullish');
      this.value = [];
    } else {
      this.value = obj;
    }

    this.updateCheckboxes();
  }

  private updateCheckboxes() {
    const checkboxes = Array.from(this.elementRef.nativeElement.querySelectorAll('ion-checkbox'));

    checkboxes.forEach((checkbox) => {
      const isChecked = this.value.includes(checkbox.value);
      if (checkbox.checked !== isChecked) {
        checkbox.checked = isChecked;
      }
    });
  }

  registerOnChange(fn: any): void {
    this.propagateOnChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

}
