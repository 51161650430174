import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

@Pipe({
  name: 'safeUrl'
})
export class SafeUrlPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(value: string|null|undefined, ...args: unknown[]): SafeUrl|null {
    if (value === null || value === undefined) {
      return null;
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }

}
