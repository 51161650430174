<app-modal-header [dismissModalOnClose]="false" (close)="close()">
<ion-title>{{((isTaskProtocol$ | async) ? 'TaskEdit' : 'EntryEdit') | translate}}</ion-title>
</app-modal-header>

<ion-content>
  <app-protocol-entry-edit
    *ngIf="animationFinished; else spinner"
    [isEditEnabled]="isEditEnabled$ | async"
    [protocolId]="protocolId"
    [protocolEntryId]="protocolEntryId"
    [isNavigationVisible]="false"
    [isNavigationEnabled]="false"
    [canAddSubentries]="false"
    [isTask]="isTaskProtocol$ | async"
    [onlyActionableEntryTypes]="isTaskProtocol$ | async"
    [defaultEntryType]="taskEntryTypeOrUndefined$ | async"
    [typeRequired]="isTaskProtocol$ | async"
    [context]="context"
    class="padding-top-20"
  ></app-protocol-entry-edit>
  
  <ng-template #spinner>
    <div class="centered" slot="fixed">
      <ion-spinner></ion-spinner>
    </div>
  </ng-template>  
</ion-content>

<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" (click)="close()">{{ 'close' | translate }}</ion-button>
    <ion-button class="omg-btn-primary" (click)="goToEntry()">{{ ((isTaskProtocol$ | async) ? 'modal.goToTask' : 'modal.goToEntry') | translate }}</ion-button>
  </ion-buttons>
</app-modal-footer>
