import {ColumnConfig} from '../ColumnConfig';
import {PdfPlanVersionDistRecipientColumnSet} from './PdfPlanVersionDistRecipientColumnSet';

// If this columnSet is changed keep in mind to also reflect the changes in PdfPlanVersionDistributionAccessColumnSet and it's respective SQL queries
export const PdfPlanVersionDistColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'pdfplan_version_id', prop: 'pdfPlanVersionId'},
  {name: 'distribution_id', prop: 'distributionId'},
  {name: 'created_by_id', prop: 'createdById', def: null},
  {name: 'created_at', prop: 'createdAt', cast: 'Timestamp'},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'pdfPlanVersionDistRecipients', fk: 'versionDistId', mappedBy: PdfPlanVersionDistRecipientColumnSet, transient: true},
];
