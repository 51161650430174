import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ModalController, Platform} from '@ionic/angular';
import {
  Attachment,
  AttachmentProtocolEntry,
  IdType,
  LicenseType,
  PdfPlanMarker,
  PdfPlanMarkerProtocolEntry,
  PdfPlanPage,
  PdfPlanPageMarking,
  PdfPlanPageMarkingBase,
  PdfPlanVersion,
  Project,
  Protocol,
  ProtocolEntry,
  ProtocolType
} from 'submodules/baumaster-v2-common';
import {PdfPlanPageDataService} from 'src/app/services/data/pdf-plan-page-data.service';
import {ProtocolEntryDataService} from 'src/app/services/data/protocol-entry-data.service';
import {BehaviorSubject, of, Subscription} from 'rxjs';
import _ from 'lodash';
import {PdfPlanMarkerProtocolEntryDataService} from 'src/app/services/data/pdf-plan-marker-protocol-entry-data.service';
import {ImageCanvasComponent} from '../image-canvas/image-canvas.component';
import {AttachmentBlob} from 'src/app/model/attachments';
import {v4 as uuid4} from 'uuid';
import {ProtocolEntryService} from 'src/app/services/protocol/protocol-entry.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastDurationInMs} from 'src/app/shared/constants';
import {SystemEventService} from '../../../services/event/system-event.service';
import {FeatureEnabledService} from 'src/app/services/feature/feature-enabled.service';
import {ProtocolService} from 'src/app/services/protocol/protocol.service';
import {switchMap} from 'rxjs/operators';
import {ProjectDataService} from 'src/app/services/data/project-data.service';
import {SketchComponent} from '../sketch/sketch.component';
import {PhotoService} from '../../../services/photo/photo.service';
import {PdfPlanPageMarkingDataService} from '../../../services/data/pdf-plan-page-marking-data.service';
import {MarkerData, MarkerType, OldMarkerSize} from '../../../../../submodules/baumaster-v2-common/dist/planMarker/fabricPdfPlanMarker';
import {combineLatestAsync, observableToPromise} from '../../../utils/async-utils';
import {ToastService} from '../../../services/common/toast.service';
import {LoggingService} from '../../../services/common/logging.service';
import {convertErrorToMessage} from '../../../shared/errors';
import {getProtocolEntryStatus} from 'submodules/baumaster-v2-common/dist/planMarker/planMarkerCanvasUtils';
import {PdfPlanService} from '../../../services/pdf/pdf-plan.service';
import {PopoverService} from 'src/app/services/ui/popover.service';
import {Nullish} from 'src/app/model/nullish';
import {AlertService} from '../../../services/ui/alert.service';
import {PopoverAction} from '../../../model/popover-actions';
import {PdfPlanTreeViewComponent} from '../pdf-plan-tree-view/pdf-plan-tree-view.component';
import {PosthogService} from 'src/app/services/posthog/posthog.service';
import {LastUsedPlanVersionService} from 'src/app/services/entry/last-used-plan-version.service';
import {Router} from '@angular/router';
import {NetworkStatusService} from 'src/app/services/common/network-status.service';

const LOG_SOURCE = 'PdfPlanMarkerComponent';
const SHOW_PLANNAME_DURATION = 5000;

export enum PdfPlanMarkerComponentMode {
  PERSIST_CHANGES,
  PERSIST_CHANGES_AND_DISMISS,
  RETURN_CHANGES
}

export enum MarkerMode {
  VIEW,
  EDIT,
  ADD
}

export interface ModifiedMarkerData {
  new: Array<MarkerData>;
  updated: Array<MarkerData>;
  deleted: Array<MarkerData>;
}

@Component({
  selector: 'app-pdf-plan-marker',
  templateUrl: './pdf-plan-marker.component.html',
  styleUrls: ['./pdf-plan-marker.component.scss'],
})
export class PdfPlanMarkerComponent implements OnInit, OnDestroy {
  private modal: HTMLIonModalElement;
  readonly PdfPlanMarkerComponentMode = PdfPlanMarkerComponentMode;

  @ViewChild('imgCanvas', {static: false}) imgCanvas: ImageCanvasComponent;
  @ViewChild('planNameOverlay', {static: false}) planNameOverlay: ElementRef;

  @Input() openMode: PdfPlanMarkerComponentMode = PdfPlanMarkerComponentMode.PERSIST_CHANGES;
  @Input() showGeneralPdfPlanMarking = false;
  @Input() openedAsDialog = true;
  @Input() pdfPlanVersion: PdfPlanVersion;
  @Input() selectedProtocolEntry: ProtocolEntry|undefined;
  @Input() showFooter = true;
  @Input() showHeader = true;
  @Input() disableAddMarker = false;
  @Input() oldMarkerSize: OldMarkerSize;
  @Input() acrossProjects = false;
  @Input() sketchDisabledOnOldVersion = false;
  @Input() showCloseButton = true;
  @Input() isinProjectRoom = false;
  @Input() planWasPreSelected = false;
  @Input() featureEnabledOverride?: boolean;
  @Input() planBasedWorkflow = false;

  @Output() oldMarkerTap = new EventEmitter<MarkerData>();

  /** Contains a list of all modified (new/updated/deleted) markers. Can also be provided when opening this component for example when adding a new protocol entry that is not yet persisted. */
  @Input()
  modifiedMarkings: ModifiedMarkerData = {
    new: [],
    updated: [],
    deleted: [],
  };
  /** Contains a set of modified (new/updated/deleted) sketches. Can also be provided when opening this component for example when adding a new protocol entry that is not yet persisted. */
  @Input()
  /** Contains the modified sketching of the current pdfPlanPage and the selected ProtocolEntry.
   * If nothing was (yet) modified, this attribute is undefined and the actual value can still be found in pdfPlanMarkerProtocolEntries.
   * If a sketching was deleted, this attribute is null but the original one can still be found in pdfPlanMarkerProtocolEntries.
   * If a new sketching was added or modified (not yet persisted), this is the only place where it is stored.
   * If a sketching was deleted, this variable is set to null but the original one can still be found in pdfPlanMarkerProtocolEntries. */
  modifiedSketching: Nullish<PdfPlanPageMarkingBase | PdfPlanPageMarking>;
  /** This is a copy of "modifiedMarkings" at the time of ngInit. Important in case of PdfPlanMarkerComponentMode.RETURN_CHANGES and cancel. */
  copyOfModifiedMarkings: ModifiedMarkerData;
  /** This is a copy of "modifiedSketching" at the time of ngInit.  Important in case of PdfPlanMarkerComponentMode.RETURN_CHANGES and cancel.  */
  copyOfModifiedSketching: Nullish<PdfPlanPageMarkingBase | PdfPlanPageMarking>;


  /** Contains a list of all markers (PdfPlanMarkerProtocolEntry) of the current plan (all pages, all protocol entries), that are persisted. Undefined, if the data is not yet initialized. */
  private pdfPlanMarkerProtocolEntriesOfAllPages: Array<PdfPlanMarkerProtocolEntry>|undefined;
  /** Contains a list of all sketches (PdfPlanPageMarking) of the current plan (all pages, all protocol entries), that are persisted. Undefined, if the data is not yet initialized. */
  private pdfPlanPageMarkingsOfAllPages: Array<PdfPlanPageMarking>|undefined;
  /** Contains a list of all sketches (pdfPlanPageMarkings) of the current plan page (all protocol entries), that are persisted. Undefined, if the data is not yet initialized. */
  private pdfPlanPageMarkings: Array<PdfPlanPageMarking>|undefined;
  /** Contains a list of all markers (pdfPlanPageMarkings) of the current plan page (current protocolEntry), that are persisted. Undefined, if the data is not yet initialized. */
  private pdfPlanMarkerProtocolEntries: Array<PdfPlanMarkerProtocolEntry>|undefined;

  public pdfPlanMarkerProtocolEntriesWithoutShowAll: Array<PdfPlanMarkerProtocolEntry>|undefined;

  /** The current mode this component is in. Undefined if not yet initialized, since the initial state depends on data that may not be available at the beginning. */
  markerMode: MarkerMode | undefined;

  /** Contains all the sketches (persisted + new - deleted) of the current pdfPlanPage taking into account the property showAllMarkings. */
  sketches: Array<PdfPlanPageMarkingBase | PdfPlanPageMarking> | undefined;
  /** Contains all the markers (persisted + new - deleted) of the current pdfPlanPage taking into account the property showAllMarkings. */
  markers: MarkerData[] | undefined;
  /** Contains all the markers (persisted + new - deleted) of the current pdfPlanPage and the selected ProtocolEntry. */
  markersProtocolEntry: MarkerData[] | undefined;
  /** Contains the (merged) sketching of the current pdfPlanPage and the selected ProtocolEntry. */
  sketchingProtocolEntry: Nullish<PdfPlanPageMarkingBase | PdfPlanPageMarking>;
  /** Contains all the markers that are currently selected. Used when deleting or moving the markers. */
  selectedMarkers = new Array<MarkerData>();

  public readonly cursorMarkerDefault = 'default';
  public readonly cursorMarker = 'url("/assets/images/marker.png") 16 32, auto';
  public readonly isMobileDevice: boolean;

  public previousPagerDisabled = false;
  public nextPagerDisabled = false;

  private protocolEntryAndPlanPagesSubscription: Subscription|undefined;
  private isCurrentProtocolEntryClosedSubscription: Subscription|undefined;
  private currentProject: Project|undefined;

  public protocolEntries: ProtocolEntry[]|undefined;
  public planPages: PdfPlanPage[];
  public attachments: AttachmentProtocolEntry[];
  public currentPlanPage: PdfPlanPage;
  public currentAttachment: Attachment | AttachmentBlob;

  public protocolTypes: Map<string, ProtocolType>;
  public protocols: Protocol[];

  public showActionBtns = false;
  public loading: boolean | undefined;
  public isClosedEntry: boolean|undefined;
  public isFeatureEnabled$ = this.featureEnabledOverride !== undefined ?
    of(this.featureEnabledOverride) : this.featureEnabledService.isFeatureEnabled$(false, true, [LicenseType.VIEWER]);
  public readonly isMultiMarkerFeatureEnabled$ = this.featureEnabledService.isFeatureEnabled$(true, false, [LicenseType.BASIC, LicenseType.PROFESSIONAL]);

  private showAllMarkingsSubject = new BehaviorSubject<boolean>(false);
  public showAllMarkings$ = this.showAllMarkingsSubject.asObservable();
  public createNewMarkerFn = () => this.createNewMarker();
  private referencedPdfPlanPageIds: Array<IdType>|undefined;
  public onObjectUrlCreated: (objectUrl: string) => void;
  private objectUrls = new Array<string>();
  sketchToolOpeningOrProcessing = false;

  get showAllMarkings(): boolean {
    return this.showAllMarkingsSubject.value;
  }

  set showAllMarkings(value: boolean) {
    this.showAllMarkingsSubject.next(value);
  }

  constructor(private modalController: ModalController,
              private toastService: ToastService,
              private alertService: AlertService,
              private translateService: TranslateService,
              private pdfPlanPageDataService: PdfPlanPageDataService,
              private protocolEntryDataService: ProtocolEntryDataService,
              private protocolEntryService: ProtocolEntryService,
              private protocolService: ProtocolService,
              private cRef: ChangeDetectorRef,
              private systemEventService: SystemEventService,
              private loggingService: LoggingService,
              private pdfPlanMarkerProtocolEntryDataService: PdfPlanMarkerProtocolEntryDataService,
              private featureEnabledService: FeatureEnabledService,
              private projectDataService: ProjectDataService,
              private photoService: PhotoService,
              private pdfPlanPageMarkingDataService: PdfPlanPageMarkingDataService,
              private platform: Platform,
              private pdfPlanService: PdfPlanService,
              private popoverService: PopoverService,
              private posthogService: PosthogService,
              private lastUsedPlanVersionService: LastUsedPlanVersionService,
              private router: Router,
              private networkStatusService: NetworkStatusService) {
    this.isMobileDevice = this.platform.is('capacitor');
    this.onObjectUrlCreated = (objectUrl) => this.addObjectUrl(objectUrl);
  }

  ngOnInit() {
    if (this.featureEnabledOverride !== undefined) {
      this.isFeatureEnabled$ = of(this.featureEnabledOverride);
    }

    if (this.planBasedWorkflow) {
      this.showAllMarkings = this.lastUsedPlanVersionService.getRememberedShowAllMarkers();
    }

    this.copyOfModifiedMarkings = _.cloneDeep(this.modifiedMarkings);
    this.copyOfModifiedSketching = _.cloneDeep(this.modifiedSketching);
    if (!this.openedAsDialog) {
      this.showAllMarkings = true;
    }
    if (!this.modifiedMarkings) {
      this.modifiedMarkings = {
        new: [],
        updated: [],
        deleted: [],
      };
    }

    const project$ = this.protocolService.getProjectByEntryId(this.selectedProtocolEntry?.id).pipe(
      switchMap((project) => !project ? this.projectDataService.currentProjectObservable : of(project))
    );
    this.protocolEntryAndPlanPagesSubscription = combineLatestAsync([
      project$,
      this.acrossProjects ? this.protocolEntryDataService.dataAcrossProjects$ : this.protocolEntryDataService.data,
      this.acrossProjects ? this.pdfPlanPageDataService.getPlanPageByPlanVersionIdAcrossProjects(this.pdfPlanVersion?.id) :
        this.pdfPlanPageDataService.getPlanPageByPlanVersionId(this.pdfPlanVersion?.id),
      this.acrossProjects ? this.pdfPlanMarkerProtocolEntryDataService.dataAcrossProjects$ : this.pdfPlanMarkerProtocolEntryDataService.data,
      this.acrossProjects ? this.pdfPlanPageMarkingDataService.dataAcrossProjects$ : this.pdfPlanPageMarkingDataService.data,
      this.showAllMarkings$
    ]).subscribe(async ([project, protocolEntries, planPages, pdfPlanMarkerProtocolEntries, pdfPlanPageMarkings, showAllMarkings]) => {
      this.currentProject = project;
      if (planPages?.length > 0) {
        const ascPlanPages: PdfPlanPage[] = _.orderBy(planPages, ['pageNumber'], ['asc']);
        this.protocolEntries = protocolEntries;
        this.planPages = ascPlanPages;
        this.pdfPlanMarkerProtocolEntriesOfAllPages = pdfPlanMarkerProtocolEntries;
        const markersOfPlanPages = pdfPlanMarkerProtocolEntries?.filter((marker) => planPages.some((planPage) => planPage.id === marker.pdfPlanPageId)) ?? [];
        this.pdfPlanMarkerProtocolEntries = showAllMarkings ? markersOfPlanPages : markersOfPlanPages.filter((marker) => marker.protocolEntryId === this.selectedProtocolEntry?.id);
        this.pdfPlanPageMarkingsOfAllPages = pdfPlanPageMarkings.filter((pdfPlanPageMarking) => ascPlanPages.some((pdfPlanPage) => pdfPlanPage.id === pdfPlanPageMarking.pdfPlanPageId));
        this.pdfPlanMarkerProtocolEntriesWithoutShowAll =  markersOfPlanPages.filter((marker) => marker.protocolEntryId === this.selectedProtocolEntry?.id);

        this.referencedPdfPlanPageIds = _.compact(_.uniq(this.filterSelectedProtocolEntry(this.pdfPlanPageMarkingsOfAllPages).map((value) => value.pdfPlanPageId)
          .concat(this.filterSelectedProtocolEntry(markersOfPlanPages).map((marker) => marker.pdfPlanPageId))));
        if (!ascPlanPages.some((page) => page.id === this.currentPlanPage?.id)) {
          const firstReferencedPage = ascPlanPages.find((page) => this.referencedPdfPlanPageIds.includes(page.id));
          this.currentPlanPage = firstReferencedPage ?? _.head(ascPlanPages);
        } else {
          this.currentPlanPage = ascPlanPages.find((page) => page.id === this.currentPlanPage?.id);
        }

        this.pdfPlanPageMarkings = this.getPdfPlanPageMarkingsOfCurrentPlanPage(this.currentPlanPage.id);
        this.initSketches(showAllMarkings);
        await this.loadCurrentPageImage();
      } else {
        this.pdfPlanPageMarkingsOfAllPages = [];
        this.pdfPlanPageMarkings = [];
        this.initSketches(showAllMarkings);
      }
    });
    this.isCurrentProtocolEntryClosedSubscription = this.protocolEntryService.isProtocolEntryClosed(this.selectedProtocolEntry?.id).subscribe((isClosedEntry) => this.isClosedEntry = isClosedEntry);
    setTimeout(() => {
      this.planNameOverlay.nativeElement.style.display = 'none';
    }, SHOW_PLANNAME_DURATION);
  }

  private async initMarkerMode() {
    if (this.markerMode !== undefined && this.markerMode !== MarkerMode.VIEW) {
      return; // markerMode already initialized.
    }
    const isFeatureEnabled = await observableToPromise(this.isFeatureEnabled$);
    const isMultiMarkerEnabled = await this.isMultiMarkerEnabled();
    if (!isFeatureEnabled) {
      this.markerMode = MarkerMode.VIEW;
    } else if (this.openMode === PdfPlanMarkerComponentMode.PERSIST_CHANGES_AND_DISMISS) {
      this.markerMode = MarkerMode.VIEW;
    } else if (this.openMode === PdfPlanMarkerComponentMode.RETURN_CHANGES) {
      this.markerMode = isMultiMarkerEnabled ? MarkerMode.ADD : MarkerMode.EDIT;
    } else {
      this.markerMode = !this.disableAddMarker && !this.isClosedEntry && !this.pdfPlanMarkerProtocolEntries?.length && !this.modifiedMarkings.new.length
      && isMultiMarkerEnabled ? MarkerMode.ADD : MarkerMode.VIEW;
    }
  }

  private async initMarkers(showAllMarkings?: boolean): Promise<MarkerData[]> {
    if (showAllMarkings === undefined) {
      showAllMarkings = this.showAllMarkings;
    }
    const pdfPlanMarkerProtocolEntries = showAllMarkings ? this.pdfPlanMarkerProtocolEntries :
      this.pdfPlanMarkerProtocolEntries.filter((marker) => marker.protocolEntryId === this.selectedProtocolEntry?.id);
    const isCurrentEntryMarker = (pdfPlanMarkerProtocolEntry: PdfPlanMarkerProtocolEntry): boolean => {
      return pdfPlanMarkerProtocolEntry.protocolEntryId === this.selectedProtocolEntry?.id;
    };
    let {markers} = await this.pdfPlanService.convertPdfPlanMarkerToMarkerData(pdfPlanMarkerProtocolEntries, this.currentPlanPage.id, this.oldMarkerSize, undefined, isCurrentEntryMarker);
    markers = this.modifiedMarkings?.new?.length ? _.compact(markers.concat(this.modifiedMarkings.new)) : markers;
    markers = this.modifiedMarkings?.updated?.length ? _.differenceBy(markers, this.modifiedMarkings.updated, 'id').concat(this.modifiedMarkings.updated) : markers;
    if (this.modifiedMarkings?.deleted?.length) {
      markers = markers.filter((marker) => !this.modifiedMarkings.deleted.some((deleted) => deleted.id === marker.id || deleted === marker));
    }
    this.markers = markers;
    this.markersProtocolEntry = this.getMarkersOfSelectedProtocolEntry();
    return markers;
  }

  private initSketches(showAllMarkings?: boolean): Array<PdfPlanPageMarking | PdfPlanPageMarkingBase> {
    if (showAllMarkings === undefined) {
      showAllMarkings = this.showAllMarkings;
    }
    if (!this.pdfPlanPageMarkings) {
      this.loggingService.warn(LOG_SOURCE, 'initSketches - cannot init sketches since pdfPlanPageMarkings is not yet initialized.');
      return;
    }
    this.sketchingProtocolEntry = this.getMergedSketchingOfSelectedProtocolEntry();
    if (showAllMarkings) {
      this.sketches = this.getMergedSketchesOfAllProtocolEntries();
    } else {
      this.sketches = this.sketchingProtocolEntry ? [this.sketchingProtocolEntry] : [];
    }

    return this.sketches;
  }

  onLoadedImage() {
    this.showActionBtns = true;
  }

  private resetModified() {
    for (const newMarker of this.modifiedMarkings.new) {
      this.imgCanvas.deleteMarker(newMarker.id);
    }
    this.modifiedMarkings ={
      new: [],
      updated: [],
      deleted: [],
    };
    this.modifiedSketching = undefined;
  }

  private async loadCurrentPageImage() {
    this.checkPdfPlanPagePager();
    this.currentAttachment = this.currentPlanPage;
    this.pdfPlanPageMarkings = this.getPdfPlanPageMarkingsOfCurrentPlanPage();
    this.initSketches(this.showAllMarkings);
    await this.initMarkers();
    await this.initMarkerMode();
  }

  private async createNewMarker(): Promise<MarkerData> {
    const isLayoutShort = await observableToPromise(this.protocolService.getIsProtocolLayoutShort$(this.selectedProtocolEntry?.protocolId));
    const title = await this.protocolEntryService.getShortId(this.selectedProtocolEntry);
    const status = isLayoutShort ? getProtocolEntryStatus(this.selectedProtocolEntry) : await this.protocolEntryService.getProtocolEntryIconStatusByEntryId(this.selectedProtocolEntry?.id);
    const newMarkerData: MarkerData = {
      id: uuid4(),
      protocolEntry: this.selectedProtocolEntry,
      title,
      status,
      markerType: MarkerType.NEW,
      lockDrag: false,
      pdfPlanPageId: this.currentPlanPage.id
    };
    return newMarkerData;
  }

  checkActiveMarkerInPdfPlan(pdfPlanMarker: PdfPlanMarker) {
    const pdfPlanPageMarker = this.getPdfPlanPageMarkingGeneral();
    if (pdfPlanMarker.pdfPlanPageId !== this.currentAttachment.id && (!pdfPlanPageMarker || pdfPlanPageMarker.pdfPlanPageId !== this.currentAttachment.id)) {
      this.showActionBtns = false;
    }
  }

  async dismissModal() {
    await this.modal.dismiss();
  }

  public isDirty(): boolean {
    if (this.openMode === PdfPlanMarkerComponentMode.RETURN_CHANGES) {
      return Boolean(true);
    }
    return Boolean(this.modifiedMarkings.new.length || this.modifiedMarkings.updated.length || this.modifiedMarkings.deleted.length || this.modifiedSketching !== undefined);
  }

  async dismissModalIfNotDirty() {
    await this.cancel();
  }

  async cancel() {
    if (this.openMode === PdfPlanMarkerComponentMode.RETURN_CHANGES) {
      await this.cancelDialog();
    } else {
      if (await this.cancelEditMode()) {
        await this.dismissModal();
      }
    }
  }

  private async cancelDialog() {
    const isDirty = this.isDirty() && (!_.isEqual(this.modifiedMarkings, this.copyOfModifiedMarkings) || !_.isEqual(this.modifiedSketching, this.copyOfModifiedSketching));
    if (!isDirty || (isDirty && (await this.confirmationBeforeLeave()))) {
      this.modifiedMarkings = this.copyOfModifiedMarkings ? this.copyOfModifiedMarkings : {new: [], updated: [], deleted: []};
      this.modifiedSketching = this.copyOfModifiedSketching;
      await this.initMarkers();
      this.initSketches();
      await this.dismissModalAndReturnMarker();
    }
  }

  private async cancelEditMode(): Promise<boolean> {
    if (this.isDirty()) {
      if (await this.confirmationBeforeLeave()) {
        this.resetModified();
        await this.initMarkers();
        this.initSketches();
      } else {
        return false;
      }
    }
    this.markerMode = MarkerMode.VIEW;
    return true;
  }

  async dismissModalAndReturnMarker() {
    await this.modal.dismiss({ markers: this.markersProtocolEntry,  modifiedMarkings: this.modifiedMarkings, modifiedSketching: this.modifiedSketching,
      selectedPlanVersion: this.pdfPlanVersion, showAllMarkers: this.showAllMarkings });
  }

  async saveModifiedMarkers(): Promise<{changes: boolean, changedObjects?: Array<PdfPlanMarkerProtocolEntry>}> {
    if (!this.modifiedMarkings.new.length && !this.modifiedMarkings.updated.length && !this.modifiedMarkings.deleted.length) {
      return {changes: false};
    }
    const inserts = this.modifiedMarkings.new.map((marker) => this.markerToPdfPlanMarkerProtocolEntry(marker));
    const updates = this.modifiedMarkings.updated.map((marker) => this.mergeMarkerWithPdfPLanMarkerProtocolEntry(marker, marker.pdfPlanMarker));
    const deletes = this.pdfPlanMarkerProtocolEntries.filter((pdfPlanMarkerProtocolEntry) => this.modifiedMarkings.deleted.some((deletedMarker) => deletedMarker.id === pdfPlanMarkerProtocolEntry.id));
    await this.pdfPlanMarkerProtocolEntryDataService.insertUpdateDelete({inserts, updates, deletes}, this.currentProject.id);
    if (inserts?.length > 0) {
      try {
        const isNetworkConnected = this.networkStatusService.onlineOrUnknown;
        this.posthogService.captureEvent(this.router.url.includes('tasks') ? '[Tasks][Task] Add markers' : '[Protocols][Entry] Add markers', {
          sketchAdded: this.modifiedSketching !== undefined,
          markerAmount: inserts.length,
          editedOffline: !isNetworkConnected
        });
      } catch (error) {
        this.loggingService.error(LOG_SOURCE, `Error capturing posthog event "${error?.userMessage}" - "${error?.message}"`);
      }
    }
    this.modifiedMarkings = {
      new: [],
      updated: [],
      deleted: []
    };
    return {changes: true, changedObjects: inserts.concat(updates).filter((v) => !deletes.some((deletedValue) => deletedValue.id === v.id))};
  }

  async saveModifiedSketches(): Promise<{changes: boolean, changedObject?: PdfPlanPageMarking | null}> {
    if (this.modifiedSketching === undefined) {
      return {changes: false};
    }
    const existingSketching = this.getSketchingOfSelectedProtocolEntry();
    let changedObject: PdfPlanPageMarking | null;
    if (existingSketching && this.modifiedSketching === null) {
      changedObject = null;
      await this.pdfPlanPageMarkingDataService.delete(existingSketching, this.currentProject.id);
    } else if (!existingSketching && this.modifiedSketching) {
      const newSketching = this.pdfPlanPageMarkingDataService.toPdfPlanPageMarking(this.modifiedSketching, this.selectedProtocolEntry?.id);
      changedObject = newSketching;
      await this.pdfPlanPageMarkingDataService.insert(newSketching, this.currentProject.id);
    } else if (existingSketching && this.modifiedSketching) {
      existingSketching.markings = this.modifiedSketching.markings;
      changedObject = existingSketching;
      await this.pdfPlanPageMarkingDataService.update(existingSketching, this.currentProject.id);
    } else {
      throw new Error('saveModifiedSketches - that should never happen.');
    }
    this.modifiedSketching = undefined;
    return {changes: true, changedObject};
  }

  async saveMarker(dismissModal = true) {
    const logInstance = this.systemEventService.logAction(LOG_SOURCE, () => 'saving markers(s)');
    try {
      this.loading = true;
      if (!_.isEmpty(this.selectedProtocolEntry)) {
        const saveMarkersResult = await this.saveModifiedMarkers();
        logInstance.logCheckpoint(`saveModifiedMarkers finished with ${saveMarkersResult.changes}`);
        const saveSketchesResult = await this.saveModifiedSketches();
        logInstance.logCheckpoint(`saveModifiedSketches finished with ${saveSketchesResult.changes}`);
      }
      const existingSketching = this.getSketchingOfSelectedProtocolEntry();
      if ((this.pdfPlanMarkerProtocolEntries?.length ?? 0) === 0 && !existingSketching) {
        try {
          const isNetworkConnected = this.networkStatusService.onlineOrUnknown;
          this.posthogService.captureEvent(this.router.url.includes('tasks') ? '[Tasks][Task] Delete markers' : '[Protocols][Entry] Delete markers', {
            editedOffline: !isNetworkConnected
          });
        } catch (error) {
          this.loggingService.error(LOG_SOURCE, `Error capturing posthog event "${error?.userMessage}" - "${error?.message}"`);
        }
      }

      logInstance.success();
      if (dismissModal) {
        await this.dismissModalAndReturnMarker();
      }
    } catch (error) {
      logInstance.failure(error);
      const message = error.message;
      await this.toastService.toast(message, ToastDurationInMs.ERROR, {header: this.translateService.instant('error_saving_message')});
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - saveMarker', error?.userMessage + '-' + error?.message);
    } finally {
      this.loading = false;
    }
  }

  async updateOldMarker(marker: MarkerData) {
    try {
      const oldMarker = this.mergeMarkerWithPdfPLanMarkerProtocolEntry(marker, marker.pdfPlanMarker);
      await this.pdfPlanMarkerProtocolEntryDataService.update(oldMarker, this.currentProject.id);
    } catch (error) {
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - updateOldMarker', error?.userMessage + '-' + error?.message);
    }
  }

  private markerToPdfPlanMarkerProtocolEntry(marker: MarkerData): PdfPlanMarkerProtocolEntry {
    return {
      protocolEntryId: marker.protocolEntry.id,
      positionX: Math.floor(marker.x),
      positionY: Math.floor(marker.y),
      pdfPlanPageId: this.currentPlanPage.id,
      projectId: this.currentProject.id,
      changedAt: new Date(),

      activityId: null,
      equipmentId: null,
      materialId: null,
      id: uuid4()
    };
  }

  private mergeMarkerWithPdfPLanMarkerProtocolEntry(marker: MarkerData, pdfPlanMarkerProtocolEntry: PdfPlanMarkerProtocolEntry): PdfPlanMarkerProtocolEntry {
    if (marker.id && marker.id !== pdfPlanMarkerProtocolEntry.id) {
      throw new Error(`mergeMarkerWithPdfPLanMarkerProtocolEntry was provided with marker (${marker.id}) and pdfPlanMarkerProtocolEntry (${pdfPlanMarkerProtocolEntry.id} that do not match.`);
    }
    pdfPlanMarkerProtocolEntry.protocolEntryId = marker.protocolEntry.id;
    pdfPlanMarkerProtocolEntry.positionX = Math.floor(marker.x);
    pdfPlanMarkerProtocolEntry.positionY = Math.floor(marker.y);
    pdfPlanMarkerProtocolEntry.pdfPlanPageId = this.currentPlanPage.id;
    pdfPlanMarkerProtocolEntry.projectId = this.currentProject.id;

    return pdfPlanMarkerProtocolEntry;
  }

  getMarkersProtocolEntry(protocolEntryId: IdType): Array<PdfPlanMarker> | undefined {
    if (!this.markers) {
      return undefined;
    }
    return this.markers.map((marker) => marker.pdfPlanMarker).filter((marker: PdfPlanMarkerProtocolEntry) => marker.protocolEntryId === protocolEntryId);
  }

  getPdfPlanPageMarkingGeneral(): PdfPlanPageMarking|undefined {
    return this.pdfPlanPageMarkings.find((pdfPlanPageMarking) => !pdfPlanPageMarking.protocolEntryId);
  }

  async ngOnDestroy() {
    this.revokeObjectUrls();
    this.unsubscribeProtocolEntryPlanPage();
    this.isCurrentProtocolEntryCarriedUnsubscribe();
  }

  unsubscribeProtocolEntryPlanPage() {
    if (this.protocolEntryAndPlanPagesSubscription) {
      this.protocolEntryAndPlanPagesSubscription.unsubscribe();
      this.protocolEntryAndPlanPagesSubscription = null;
    }
  }

  isCurrentProtocolEntryCarriedUnsubscribe() {
    if (this.isCurrentProtocolEntryClosedSubscription) {
      this.isCurrentProtocolEntryClosedSubscription.unsubscribe();
      this.isCurrentProtocolEntryClosedSubscription = undefined;
    }
  }

  checkPdfPlanPagePager() {
    const currentPageNumber = this.currentPlanPage?.pageNumber + 1;
    this.previousPagerDisabled = currentPageNumber === 1;
    this.nextPagerDisabled = currentPageNumber >= this.planPages.length;
  }

  async onNewMarkersAdded(markers: Array<MarkerData>) {
    this.modifiedMarkings.new = this.modifiedMarkings.new.concat(markers);
    this.markerMode = MarkerMode.EDIT;
    this.selectedMarkers = markers;
    await this.initMarkers(this.showAllMarkings);
    this.cRef.detectChanges();
  }

  async onMarkersChanged(markers: Array<MarkerData>) {
    const markersToUpdate = new Array<MarkerData>();
    for (const marker of markers) {
      const indexInNew = _.findIndex(this.modifiedMarkings.new, {id: marker.id});
      if (indexInNew === -1) {
        markersToUpdate.push(marker);
      } else {
        // inserted and updated. Just replace the entry in inserted.
        this.modifiedMarkings.new[indexInNew] = marker;
      }
    }
    this.modifiedMarkings.updated = _.differenceBy(this.modifiedMarkings.updated, markersToUpdate, 'id').concat(markersToUpdate);
    await this.initMarkers(this.showAllMarkings);
  }

  goToPreviousPage() {
    const currentPageNumber = this.currentPlanPage?.pageNumber - 1;
    this.currentPlanPage = this.planPages[currentPageNumber];
    this.resetModified();
    this.loadCurrentPageImage();
  }

  goToNextPage() {
    const currentPageNumber = this.currentPlanPage?.pageNumber + 1;
    this.currentPlanPage = this.planPages[currentPageNumber];
    this.resetModified();
    this.loadCurrentPageImage();
  }

  startEdit() {
    if (this.markerMode !== MarkerMode.VIEW) {
      return;
    }

    if (this.markersProtocolEntry?.length) {
      this.markerMode = MarkerMode.EDIT;
    } else {
      this.markerMode = MarkerMode.ADD;
    }
  }

  async startAdd() {
    if (this.markersProtocolEntry?.length && !(await this.featureEnabledService.isFeatureEnabledOrToast(true, false, [LicenseType.BASIC, LicenseType.PROFESSIONAL]))) {
      return;
    }
    this.markerMode = MarkerMode.ADD;
  }

  private getMarkersOfSelectedProtocolEntry(): Array<MarkerData> {
    if (!this.markers?.length) {
      return [];
    }
    return this.markers.filter((marker) => marker.protocolEntry?.id === this.selectedProtocolEntry?.id);
  }

  /**
   * Deletes the current marker.
   *
   * @returns: true - if an existing marker has been scheduled for deletion.
   * false, if nothing was deleted (no marker) or marker that has been added before, has been deleted
   */
  async deleteMarker(markersToDelete?: MarkerData[]): Promise<boolean> {
    if (!markersToDelete) {
      markersToDelete = this.selectedMarkers;
      this.selectedMarkers = [];
    }
    if (!markersToDelete?.length) {
      return false;
    }
    const deletedAndNotAdded = _.differenceBy(markersToDelete, this.modifiedMarkings.new, 'id');

    // remove the markers to delete from new and updated since they are going to be deleted anyway.
    this.modifiedMarkings.new = _.differenceBy(this.modifiedMarkings.new,  markersToDelete);
    this.modifiedMarkings.updated = _.differenceBy(this.modifiedMarkings.updated, markersToDelete);

    this.modifiedMarkings.deleted = this.modifiedMarkings.deleted.concat(deletedAndNotAdded);

    await this.initMarkers(this.showAllMarkings);

    for (const markerToDelete of markersToDelete) {
      this.imgCanvas.deleteMarker(markerToDelete.id);
    }
    return true;
  }

  /**
   * Deletes the current PdfPlanPageMarking.
   *
   * @returns: true - If an existing pdfPlanPageMarking has been deleted.
   * false, if nothing deleted or pdfPlanPageMarking that has been added before, has been deleted
   */
  deleteSketching(): boolean {
    let deleted: boolean;
    const existingSketchingToDelete = this.getSketchingOfSelectedProtocolEntry();
    if (!existingSketchingToDelete && !this.modifiedSketching) {
      this.modifiedSketching = undefined;
      deleted = false;
    } else if (!existingSketchingToDelete && this.modifiedSketching) {
      this.modifiedSketching = undefined;
      deleted = true;
    } else {
      this.modifiedSketching = null;
      deleted = true;
    }
    this.initSketches(this.showAllMarkings);
    return deleted;
  }

  async deleteAll() {
    if (await this.alertService.confirm({header: 'pdfPlanMarker.confirmDeleteAll.header', message: 'pdfPlanMarker.confirmDeleteAll.message'})) {
      this.selectedMarkers = this.markersProtocolEntry;
      const markerDeleted = this.deleteMarker();
      const pdfPlanPageMarkingDeleted = this.deleteSketching();
      await this.saveMarker();
    }
  }

  onOldMarkerTap(markerData: MarkerData) {
    this.oldMarkerTap.emit(markerData);
  }

  public async openImageInSketchTool() {
    if (!this.currentPlanPage || this.sketchToolOpeningOrProcessing) {
      return;
    }
    if (this.imgCanvas && this.imgCanvas.imageScaled) {
      await this.toastService.error('imageScaledOnDevice');
      return;
    }
    const pdfPlanPageId = this.currentPlanPage.id;
    const protocolEntryId = this.selectedProtocolEntry?.id;

    let attachmentUrl: string|undefined;
    try {
      this.sketchToolOpeningOrProcessing = true;
      const blob = await this.photoService.downloadAttachment(this.currentPlanPage, 'image');
      if (!blob) {
        await this.toastService.error('pdfPlanMarker.plan_not_loaded');
        return;
      }
      const sketching = this.getMergedSketchingOfSelectedProtocolEntry();
      let markings = sketching?.markings;
      let markingsChanged = false;
      const planMarkers = this.markers.map((val) => {
          return {...val, markerType: MarkerType.OLD}; // clone object and make sure they are not selectable
        });
      attachmentUrl = URL.createObjectURL(blob);
      const modal = await this.modalController.create({
        component: SketchComponent,
        backdropDismiss: false,
        cssClass: 'full-screen-sketch',
        componentProps: {
          planMarkers,
          attachmentUrl,
          attachmentWidth: this.currentPlanPage?.width,
          attachmentHeight: this.currentPlanPage?.height,
          revokeObjectUrlOnDestroy: true,
          applyInsteadOfSave: this.openedAsDialog,
          markings,
          canErase: false,
          onMarkingsChanged: (updatedMarkings: Nullish<string>) => {
            markings = updatedMarkings;
            markingsChanged = true;
          }
        }
      });
      await modal.present();
      await modal.onDidDismiss();
      if (markingsChanged) {
        if (!markings) {
          this.deleteSketching();
          return;
        }
        if (!sketching) {
          this.modifiedSketching = {
            markings,
            pdfPlanPageId: this.currentPlanPage.id,
            name: 'default'
          };
        } else {
          const updatedSketching = _.clone(sketching);
          updatedSketching.markings = markings;
          this.modifiedSketching = updatedSketching;
        }
        this.initSketches(this.showAllMarkings);
        if (this.openMode === PdfPlanMarkerComponentMode.PERSIST_CHANGES_AND_DISMISS) {
          await this.saveModifiedSketches();
          await this.dismissModal();
        }
      }
    } catch (error) {
      const errorMessage = convertErrorToMessage(error);
      if (errorMessage === 'overlay does not exist') {
        return; // this error can be ignored
      }
      await this.toastService.error('pdfPlanMarker.error_opening_sketching');
      this.loggingService.error(LOG_SOURCE, `Error in openImageInSketchTool - ${errorMessage}`);
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - openImageInSketchTool', error);
    } finally {
      this.sketchToolOpeningOrProcessing = false;
    }
  }

  private getMergedSketching(currentEntrySketching: PdfPlanPageMarking|undefined): PdfPlanPageMarking | PdfPlanPageMarkingBase | null | undefined {
    if (this.modifiedSketching === undefined) {
      return currentEntrySketching;
    }
    return this.modifiedSketching;
  }

  private getMergedSketchesOfAllProtocolEntries(): Array<PdfPlanPageMarking | PdfPlanPageMarkingBase> {
    const currentSketching = this.getSketchingOfSelectedProtocolEntry();
    if (!currentSketching) {
      return this.pdfPlanPageMarkings;
    }
    const otherSketches = _.difference(this.pdfPlanPageMarkings, [currentSketching]);
    const mergedCurrentSketching = this.getMergedSketching(currentSketching);
    if (!mergedCurrentSketching) {
      return otherSketches;
    }
    return [mergedCurrentSketching].concat(otherSketches);
  }

  private getSketchingOfSelectedProtocolEntry(): PdfPlanPageMarking {
    return this.pdfPlanPageMarkings.find((pdfPlanPageMarking) => (this.selectedProtocolEntry && pdfPlanPageMarking.protocolEntryId === this.selectedProtocolEntry?.id) ||
      (this.showGeneralPdfPlanMarking && !this.selectedProtocolEntry && !pdfPlanPageMarking.protocolEntryId));
  }

  private getMergedSketchingOfSelectedProtocolEntry(): PdfPlanPageMarking | PdfPlanPageMarkingBase | null | undefined {
    return this.getMergedSketching(this.getSketchingOfSelectedProtocolEntry());
  }

  private filterSelectedProtocolEntry<T extends PdfPlanPageMarking | PdfPlanMarkerProtocolEntry>(values: Array<T>): Array<T> {
    if (!this.selectedProtocolEntry) {
      return [];
    }
    return values.filter((value) => value.protocolEntryId === this.selectedProtocolEntry.id);
  }

  public showAllMarkingsChanged(event: CustomEvent<{checked: boolean, value: string}>) {
    this.showAllMarkings = event.detail.checked;
    if (this.planBasedWorkflow) {
      this.lastUsedPlanVersionService.setRememberedShowAllMarkers(this.showAllMarkings);
    }
  }

  private async confirmationBeforeLeave(): Promise<boolean> {
    return await this.alertService.confirm({header: 'protocolCreation.data_loss_header', message: 'protocolCreation.data_loss_message'});
  }

  showEditMarker() {
    return !this.modifiedMarkings.new?.length && this.showActionBtns && !this.disableAddMarker;
  }

  isMarkerOrMarkingOnCurrentPage<T extends PdfPlanMarker | PdfPlanMarkerProtocolEntry | MarkerData>(marker: T): boolean {
    if (!this.planPages?.length) {
      return false;
    }
    if (this.planPages.length === 1) {
      return true;
    }
    if (!this.currentPlanPage) {
      return false;
    }
    return marker.pdfPlanPageId === this.currentPlanPage.id;
  }

  async openActionsPopover(event: PointerEvent) {
    const actions: Array<PopoverAction<string> & {visible?: boolean}> = [
      {
        role: 'deleteAll',
        label: 'pdfPlanMarker.button.deleteAll',
        icon: ['fal', 'trash-alt'],
        visible: this.openMode !== PdfPlanMarkerComponentMode.PERSIST_CHANGES_AND_DISMISS && this.markerMode === MarkerMode.VIEW,
        disabled: this.loading || this.isClosedEntry || (!this.markersProtocolEntry?.length && !this.sketchingProtocolEntry)
      },
      {
        role: 'edit',
        label: 'pdfPlanMarker.button.edit',
        icon: ['fal', 'edit'],
        visible: this.openMode !== PdfPlanMarkerComponentMode.PERSIST_CHANGES_AND_DISMISS && this.markerMode === MarkerMode.VIEW,
        disabled: this.loading || this.sketchToolOpeningOrProcessing || this.isClosedEntry
      },
      {
        role: 'editSketching',
        label: 'pdfPlanMarker.button.editSketching',
        icon: ['fal', 'signature'],
        visible: this.openMode === PdfPlanMarkerComponentMode.PERSIST_CHANGES_AND_DISMISS || this.markerMode !== MarkerMode.VIEW,
        disabled: this.loading || this.sketchToolOpeningOrProcessing || this.isClosedEntry
      },
      {
        role: 'deleteMarker',
        label: 'pdfPlanMarker.button.deleteMarker',
        icon: ['fal', 'trash-alt'],
        visible: this.openMode !== PdfPlanMarkerComponentMode.PERSIST_CHANGES_AND_DISMISS && this.markerMode === MarkerMode.ADD || this.markerMode === MarkerMode.EDIT,
        disabled: this.loading || this.sketchToolOpeningOrProcessing || this.isClosedEntry || !this.selectedMarkers?.length
      },
      {
        role: 'startAdd',
        label: 'pdfPlanMarker.button.setNewMarker',
        icon: ['fal', 'map-marker-alt'],
        visible: this.openMode !== PdfPlanMarkerComponentMode.PERSIST_CHANGES_AND_DISMISS && this.markerMode === MarkerMode.EDIT && !this.disableAddMarker,
        disabled: this.loading || this.sketchToolOpeningOrProcessing || this.isClosedEntry
      }
    ];
    const visibleActions = actions.filter((action) => action.visible === undefined || action.visible);
    const result = await this.popoverService.openActions(event, visibleActions);

    if (result !== 'backdrop' && result !== 'feature-disabled-connected' && result !== 'feature-disabled-license') {
      switch (result) {
        case 'deleteAll':
          await this.deleteAll();
          break;
        case 'edit':
          this.startEdit();
          break;
        case 'editSketching':
          await this.openImageInSketchTool();
          break;
        case 'deleteMarker':
          await this.deleteMarker();
          break;
        case 'startAdd':
          await this.startAdd();
          break;
        default:
          throw new Error('Unsupported action: ' + result);
      }
    }
  }

  private getPdfPlanPageMarkingsOfCurrentPlanPage(pdfPlanPageId?: IdType): Array<PdfPlanPageMarking>|undefined {
    return this.pdfPlanPageMarkingsOfAllPages?.filter((pdfPlanPageMarking) => pdfPlanPageMarking.pdfPlanPageId === (pdfPlanPageId ?? this.currentPlanPage.id));
  }

  public addObjectUrl(objectUrl: string) {
    this.objectUrls.push(objectUrl);
  }

  private revokeObjectUrls() {
    if (this.objectUrls.length) {
      this.objectUrls.forEach((objectUrl) => URL.revokeObjectURL(objectUrl));
      this.objectUrls = [];
    }
  }

  private async isMultiMarkerEnabled(): Promise<boolean> {
    const that = this;
    const isMultiMarkerFeatureEnabled = await observableToPromise(this.isMultiMarkerFeatureEnabled$);
    const pdfPlanMarkerProtocolEntriesExist = Boolean(this.pdfPlanMarkerProtocolEntries?.length || this.modifiedMarkings.new?.length);
    return isMultiMarkerFeatureEnabled || !pdfPlanMarkerProtocolEntriesExist;
  }

  protected readonly MarkerMode = MarkerMode;

  async selectDifferentPlan() {
    const modal = await this.modalController.create({
      component: PdfPlanTreeViewComponent,
      cssClass: 'basic-modal',
      componentProps: {
        selectedVersion: this.pdfPlanVersion
      }
    });
    await modal.present();

    const { data } = await modal.onWillDismiss();
    if (typeof data === 'undefined') {
      return;
    }
    if (this.planWasPreSelected) {
      this.posthogService.captureEvent('[PlanMarker] Changed plan after auto select', {});
    }
    this.pdfPlanVersion = data.pdfPlanVersion;
    await this.clearPlanVersionState();
    this.initPlanVersion();
  }

  initPlanVersion() {
    if (this.featureEnabledOverride !== undefined) {
      this.isFeatureEnabled$ = of(this.featureEnabledOverride);
    }

    this.copyOfModifiedMarkings = _.cloneDeep(this.modifiedMarkings);
    this.copyOfModifiedSketching = _.cloneDeep(this.modifiedSketching);
    if (!this.openedAsDialog) {
      this.showAllMarkings = true;
    }
    if (!this.modifiedMarkings) {
      this.modifiedMarkings = {
        new: [],
        updated: [],
        deleted: [],
      };
    }

    const project$ = this.protocolService.getProjectByEntryId(this.selectedProtocolEntry?.id).pipe(
      switchMap((project) => !project ? this.projectDataService.currentProjectObservable : of(project))
    );
    this.protocolEntryAndPlanPagesSubscription = combineLatestAsync([
      project$,
      this.acrossProjects ? this.protocolEntryDataService.dataAcrossProjects$ : this.protocolEntryDataService.data,
      this.acrossProjects ? this.pdfPlanPageDataService.getPlanPageByPlanVersionIdAcrossProjects(this.pdfPlanVersion?.id) :
        this.pdfPlanPageDataService.getPlanPageByPlanVersionId(this.pdfPlanVersion?.id),
      this.acrossProjects ? this.pdfPlanMarkerProtocolEntryDataService.dataAcrossProjects$ : this.pdfPlanMarkerProtocolEntryDataService.data,
      this.acrossProjects ? this.pdfPlanPageMarkingDataService.dataAcrossProjects$ : this.pdfPlanPageMarkingDataService.data,
      this.showAllMarkings$
    ]).subscribe(async ([project, protocolEntries, planPages, pdfPlanMarkerProtocolEntries, pdfPlanPageMarkings, showAllMarkings]) => {
      this.currentProject = project;
      if (planPages?.length > 0) {
        const ascPlanPages: PdfPlanPage[] = _.orderBy(planPages, ['pageNumber'], ['asc']);
        this.protocolEntries = protocolEntries;
        this.planPages = ascPlanPages;
        this.pdfPlanMarkerProtocolEntriesOfAllPages = pdfPlanMarkerProtocolEntries;
        const markersOfPlanPages = pdfPlanMarkerProtocolEntries?.filter((marker) => planPages.some((planPage) => planPage.id === marker.pdfPlanPageId)) ?? [];
        this.pdfPlanMarkerProtocolEntries = showAllMarkings ? markersOfPlanPages : markersOfPlanPages.filter((marker) => marker.protocolEntryId === this.selectedProtocolEntry?.id);
        this.pdfPlanPageMarkingsOfAllPages = pdfPlanPageMarkings.filter((pdfPlanPageMarking) => ascPlanPages.some((pdfPlanPage) => pdfPlanPage.id === pdfPlanPageMarking.pdfPlanPageId));

        this.referencedPdfPlanPageIds = _.compact(_.uniq(this.filterSelectedProtocolEntry(this.pdfPlanPageMarkingsOfAllPages).map((value) => value.pdfPlanPageId)
          .concat(this.filterSelectedProtocolEntry(markersOfPlanPages).map((marker) => marker.pdfPlanPageId))));
        if (!ascPlanPages.some((page) => page.id === this.currentPlanPage?.id)) {
          const firstReferencedPage = ascPlanPages.find((page) => this.referencedPdfPlanPageIds.includes(page.id));
          this.currentPlanPage = firstReferencedPage ?? _.head(ascPlanPages);
        } else {
          this.currentPlanPage = ascPlanPages.find((page) => page.id === this.currentPlanPage?.id);
        }

        this.pdfPlanPageMarkings = this.getPdfPlanPageMarkingsOfCurrentPlanPage(this.currentPlanPage.id);
        this.initSketches(showAllMarkings);
        await this.loadCurrentPageImage();
      } else {
        this.pdfPlanPageMarkingsOfAllPages = [];
        this.pdfPlanPageMarkings = [];
        this.initSketches(showAllMarkings);
      }
    });
    this.isCurrentProtocolEntryClosedSubscription = this.protocolEntryService.isProtocolEntryClosed(this.selectedProtocolEntry?.id).subscribe((isClosedEntry) => this.isClosedEntry = isClosedEntry);
  }

  async clearPlanVersionState() {
    this.revokeObjectUrls();
    this.unsubscribeProtocolEntryPlanPage();
    this.isCurrentProtocolEntryCarriedUnsubscribe();
  }

  closeOverlay() {
    this.planNameOverlay.nativeElement.style.display = 'none';
  }

  retryLoadCurrentPlanPage() {
    this.currentPlanPage = _.clone(this.currentPlanPage);
  }
}
