import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {IonicModule, IonModal} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {UserDataService} from 'src/app/services/data/user-data.service';
import {UsersProfileService} from 'src/app/services/user/users-profile.service';
import {DATE_FORMAT} from 'src/app/shared/constants';
import {TooltipModule} from 'src/app/shared/module/tooltip/tooltip.module';
import {UiModule} from 'src/app/shared/module/ui/ui.module';
import {switchMapOrDefault} from 'src/app/utils/async-utils';
import {IdType, TextTemplate} from 'submodules/baumaster-v2-common';

@Component({
  selector: 'app-text-template-list',
  templateUrl: './text-template-list.component.html',
  styleUrls: ['./text-template-list.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    IonicModule,
    UiModule,
    FormsModule,
    FontAwesomeModule,
    TranslateModule,
    TooltipModule
  ],
})
export class TextTemplateListComponent implements OnDestroy, OnChanges {
  readonly DATE_FORMAT = DATE_FORMAT;

  @ViewChild('preview', {
    read: IonModal
  })
  previewModal: IonModal;

  @Input()
  templates: TextTemplate[];

  @Input()
  itemIcon?: [string, string];

  @Input()
  templateType?: string;

  @Output()
  itemIconClick = new EventEmitter<IdType>();

  @Output()
  selectedTemplateIdChange = new EventEmitter<IdType>();

  @Output()
  applySelected = new EventEmitter<void>();

  @Output()
  editSelected = new EventEmitter<void>();

  @Output()
  copySelected = new EventEmitter<void>();

  private selectedTemplateIdSubject = new BehaviorSubject<IdType|undefined>(undefined);

  readonly createdBy$: Observable<string|undefined> = this.selectedTemplateIdSubject.pipe(
    map(() => this.selectedTemplate?.createdById),
    switchMapOrDefault((userId) => this.userDataService.dataGroupedById.pipe(
      map((byId) => byId[userId])
    )),
    switchMapOrDefault((user) => this.usersProfileService.getUserOwnName$(user))
  );

  get selectedTemplateId(): IdType|undefined {
    return this.selectedTemplateIdSubject.value;
  }

  set selectedTemplateId(id: IdType|undefined) {
    this.selectedTemplateIdSubject.next(id);
  }

  get selectedTemplate() {
    return this.templates.find((template) => template.id === this.selectedTemplateId);
  }

  get textTemplate() {
    return this.selectedTemplate?.textTemplate ?? '';
  }

  constructor(
    private userDataService: UserDataService,
    private usersProfileService: UsersProfileService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.templates) {
      this.selectedTemplateIdSubject.next(this.selectedTemplateId);
    }
  }

  ngOnDestroy() {
    this.previewModal?.dismiss();
  }

}
