import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {CalendarDay, User} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {AbstractClientAwareDataService} from './abstract-client-aware-data.service';
import {UserService} from '../user/user.service';
import {map} from 'rxjs/operators';
import {ClientService} from '../client/client.service';
import {TranslateService} from '@ngx-translate/core';
import {VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';

const REST_ENDPOINT_URI = 'api/data/clientCalendarDays';

@Injectable({
  providedIn: 'root'
})
export class ClientCalendarDayDataService extends AbstractClientAwareDataService<CalendarDay> {
  public readonly dataActive$ = this.dataForOwnClient$.pipe(map((calendars) => calendars?.filter((calendar) => calendar.isActive)));

  constructor(http: HttpClient, storage: StorageService, authenticationService: AuthenticationService, userService: UserService, clientService: ClientService,
              loggingService: LoggingService, integrityResolverService: IntegrityResolverService, private translateService: TranslateService) {
    super(StorageKeyEnum.CLIENT_CALENDAR_DAY, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, clientService, loggingService,
      integrityResolverService, VERSION_INTRODUCED_DEFAULT, ['calendarId', 'date']);
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }

}
