import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {PopoverController} from '@ionic/angular';
import {Observable, of} from 'rxjs';
import {PopoverAction} from 'src/app/model/popover-actions';
import {FeatureEnabledService} from 'src/app/services/feature/feature-enabled.service';
import {MIME_TYPE_EXTENSION_WHITELIST} from 'submodules/baumaster-v2-common/';

@Component({
  selector: 'app-actions-popover',
  templateUrl: './actions-popover.component.html',
  styleUrls: ['./actions-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsPopoverComponent<T extends string> {

  @Input()
  actions: PopoverAction<T>[];

  public readonly acceptedMimeTypesForUpload = MIME_TYPE_EXTENSION_WHITELIST.join(',');

  constructor(
    private popoverController: PopoverController,
    private featureEnabledService: FeatureEnabledService
  ) { }

  getIsEnabled$(action: PopoverAction<T>): Observable<boolean> {
    if (action.lookDisabled) {
      return of(false);
    }

    if (!action.permissions) {
      return of(true);
    }

    return this.featureEnabledService.isFeatureEnabled$(
      action.permissions.featureEnabled,
      action.permissions.forConnected ?? null,
      action.permissions.forLicenses ?? null,
      action.permissions.forAdmin ?? null,
      action.permissions.forReportRights ?? null,
    );
  }

  async enabledForConnected(permissions: PopoverAction<T>['permissions']) {
    return typeof permissions.forConnected === 'boolean' ? this.featureEnabledService.isFeatureEnabled(
      permissions.featureEnabled,
      permissions.forConnected,
      null
    ) : permissions.featureEnabled;
  }

  async enabledForLicense(permissions: PopoverAction<T>['permissions']) {
    return permissions.forLicenses && Array.isArray(permissions.forLicenses) ? this.featureEnabledService.isFeatureEnabled(
      permissions.featureEnabled,
      null,
      permissions.forLicenses
    ) : permissions.featureEnabled;
  }

  async execute(action: PopoverAction<T>) {
    if (action.permissions) {
      if (!(await this.enabledForConnected(action.permissions))) {
        this.popoverController.dismiss(undefined, 'feature-disabled-connected');
        return;
      }

      if (!(await this.enabledForLicense(action.permissions))) {
        this.popoverController.dismiss(undefined, 'feature-disabled-license');
        return;
      }
    }

    this.popoverController.dismiss(undefined, action.role);
  }

  async dismiss() {
    await this.popoverController.dismiss();
  }
}
