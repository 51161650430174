import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {IonRouterOutlet, ModalController} from '@ionic/angular';
import {map, pairwise, startWith, switchMap, tap} from 'rxjs/operators';
import {ProtocolEntryCreateComponent} from 'src/app/components/protocol/protocol-entry-create/protocol-entry-create.component';
import {ProtocolEntryOrOpen} from 'src/app/model/protocol';
import {ProtocolDataService} from 'src/app/services/data/protocol-data.service';
import {ProtocolEntryDataService} from 'src/app/services/data/protocol-entry-data.service';
import {EntryCreationModeService} from 'src/app/services/entry/entry-creation-mode.service';
import {FeatureEnabledService} from 'src/app/services/feature/feature-enabled.service';
import {ProtocolNavigationService} from 'src/app/services/protocol-navigation.service';
import {ProtocolEntryFilterService} from 'src/app/services/protocol/protocol-entry-filter.service';
import {observableToPromise} from 'src/app/utils/async-utils';
import {IdType, LicenseType} from 'submodules/baumaster-v2-common';
import {UnitService} from '../../../services/unit/unit.service';

@Component({
  selector: 'app-protocol-entry',
  templateUrl: './protocol-entry.page.html',
  styleUrls: ['./protocol-entry.page.scss'],
})
export class ProtocolEntryPage {

  readonly isEditEnabled$ = this.featureEnabledService.isFeatureEnabled$(false, true, [LicenseType.VIEWER]);
  filters$ = this.protocolEntryFilterService.protocolEntryFilters;
  protocolEntry$ = this.route.params.pipe(
    switchMap(({ protocolEntryId, protocolId }) => this.protocolNavigationService.getProtocolEntry(protocolEntryId, protocolId)),
    startWith(null as ProtocolEntryOrOpen),
    pairwise(),
    tap(([previousProtocolEntry, protocolEntry]) => {
      // Pre-select the protocol entry just added to the empty protocol
      // Below is harmless when the user navigates from empty protocol to non-empty protocol
      if (this.ionRouterOutlet.isActivated && !previousProtocolEntry && protocolEntry) {
        this.protocolEntryDataService.setCurrentProtocolEntry({
          protocolEntry,
        });
      }
    }),
    map(([, protocolEntry]) => protocolEntry)
  );
  protocol$ = this.route.params.pipe(
    switchMap(({ protocolId }) => this.protocolDataService.getById(protocolId))
  );

  public entryCreationModeIcon$ = this.entryCreationModeService.entryCreationModeIcon$;

  constructor(
    private route: ActivatedRoute,
    private protocolDataService: ProtocolDataService,
    private protocolNavigationService: ProtocolNavigationService,
    private protocolEntryDataService: ProtocolEntryDataService,
    private featureEnabledService: FeatureEnabledService,
    private modalController: ModalController,
    private ionRouterOutlet: IonRouterOutlet,
    private protocolEntryFilterService: ProtocolEntryFilterService,
    private entryCreationModeService: EntryCreationModeService,
    private unitService: UnitService,
  ) { }

  async addNewProtocolEntry(protocolId: IdType) {
    if (!(await observableToPromise(this.isEditEnabled$))) {
      return;
    }
    const defaultUnit = await observableToPromise(this.unitService.getUnitDefaultForProtocol$(protocolId));
    const modal = await this.modalController.create({
      component: ProtocolEntryCreateComponent,
      keyboardClose: false,
      backdropDismiss: false,
      componentProps: {
        protocolId,
        expressView: true,
        parentEntryId: undefined,
        startWithWorkflow: this.entryCreationModeService.entryCreationMode,
        defaultUnit
      }
    });
    await modal.present();
  }

  async openButtonActions(event: Event, protocolId: string) {
    if (await this.entryCreationModeService.openEntryCreationModeOptions(event)) {
      await this.addNewProtocolEntry(protocolId);
    }
  }
}
