<ng-container [formGroup]="form">
  <mbsc-datepicker
    [select]="'range'"
    [controls]="['time']"
    #range
    hidden
    [disabled]="disableTimePicker"
    [options]="rangePickerSettings"
    [min]="nextMeeting?.timeStart"
    [max]="nextMeeting?.timeEnd"
    [dateFormat]="MBSC_DATE_FORMAT"
    [showWeekNumbers]="true"
    [firstSelectDay]="1"
    [firstDay]="1"
    [setText]="'button.apply' | translate"
    [cancelText]="'cancel' | translate"
    [buttons]="[ 'cancel', 'set' ]"
    [locale]="mbscLocale$ | async"
    [themeVariant]="mbscThemeVariant$ | async"
    theme="windows"
    [rangeStartLabel]="'sendProtocol.startDate' | translate"
    [rangeEndLabel]="'sendProtocol.endDate' | translate"
    formControlName="timeStartEnd"
  >
  </mbsc-datepicker>

  <ion-item lines="none" class="item-legacy">
    <ion-label class="participant">
      <ion-text class="participant-company">
        <ng-container *ngIf="company$ | async as company">
          {{ company.name }}
        </ng-container>
      </ion-text>
      <div class="participant-name-and-email">
        <ng-container *ngIf="address$ | async as address">
          <ion-text class="participant-name">{{ address.firstName }} {{ address.lastName }}</ion-text>
          <ion-text class="participant-email">
            {{ address.email }}
          </ion-text>
        </ng-container>
      </div>
    </ion-label>
    <ion-label [class.disabled]="disableTimePicker" (click)="!disableTimePicker && range.open()">
      <app-time [class.invalid-time]="form.invalid" [label]="'Start' | translate">
        {{ toTimeString(individualNextMeeting.timeStart) }}
      </app-time>
    </ion-label>
    <ion-label [class.disabled]="disableTimePicker" (click)="!disableTimePicker && range.open()">
      <app-time [class.invalid-time]="form.invalid" [label]="'End' | translate">
        {{ toTimeString(individualNextMeeting.timeEnd) }}
      </app-time>
    </ion-label>
    <ion-button (click)="deleteClick.emit($event)" class="omg-btn-text">
      <fa-icon [icon]="['fal', 'trash-alt']"></fa-icon>
    </ion-button>
  </ion-item>
</ng-container>
