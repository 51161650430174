<app-modal-header>
  <ion-title>{{'sync.conflicts.title' | translate}}</ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <ion-grid class="grid-gap-4">
    <span *ngFor="let syncConflict of syncConflicts; let i = index" >
      <ion-row *ngIf="i===0 || syncConflicts[i].type !== syncConflicts[i-1].type">
        <ion-col>
          <ion-list-header>
            <ion-label>{{'sync.conflicts.type.' + syncConflict.type | translate}}</ion-label>
          </ion-list-header>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          {{'sync.storageKey.' + syncConflict.storageKey | translate}}
        </ion-col>
        <ion-col>
          {{
          syncConflict.localValue?.name || syncConflict.serverValue?.name ||
          syncConflict.localValue?.title || syncConflict.serverValue?.title ||
          syncConflict.localValue?.text || syncConflict.serverValue?.text ||
          syncConflict.localValue?.fileName || syncConflict.serverValue?.fileName ||
          syncConflict.id
          }}
        </ion-col>
        <ion-col *ngIf="syncConflict.type !== 'PROTOCOL_REMOVED_ENTRIES_RECOVERED'">
          {{(syncConflict.resolved ? 'sync.conflicts.resolved' : 'sync.conflicts.unresolved') | translate}}
        </ion-col>
        <ion-col *ngIf="syncConflict.type === 'PROTOCOL_REMOVED_ENTRIES_RECOVERED'">
          <a
            [routerLink]="protocolRouterLink(syncConflict.context.recoveryProtocol.id)"
            (click)="onProtocolNavigation(syncConflict.context.recoveryProtocol.projectId)">
            {{ syncConflict.context.recoveryProtocol.name }}
          </a>
        </ion-col>
      </ion-row>
    </span>
  </ion-grid>
</ion-content>
