<app-accordion [summary]="'tags.popover.object.appliedListHeader' | translate" #appliedTagsAccordion>
  <app-tag-list [interactive]="true" class="applied-list" [tags]="tags" (tagClick)="tagRemove.emit($event)">
    <ng-template appTagStartTemplate>
      <fa-icon [icon]="['fal', 'tags']"></fa-icon>
    </ng-template>
    <ng-template appTagEndTemplate>
      <fa-icon [icon]="['fal', 'times']"></fa-icon>
    </ng-template>
  </app-tag-list>
</app-accordion>
<div class="section-header">
  <ion-text color="text-secondary">{{ 'tags.popover.object.searchListHeader' | translate }}</ion-text>
  <ion-button class="omg-btn-text" (click)="editClick.emit($event)">
    <fa-icon slot="icon-only" [icon]="['fal', 'pencil']"></fa-icon>
  </ion-button>
</div>
<app-tag-list [interactive]="true" class="search-list" [tags]="filteredTags" (tagClick)="tagAdd.emit($event)">
  <ng-template appTagWrapperStartTemplate let-tag="tag">
    <ion-text color="text-dark" class="create-prefix" *ngIf="tag.id.startsWith(newIdPrefix)">{{ 'button.create' | translate }}</ion-text>
  </ng-template>
  <ng-template appTagStartTemplate let-tag="tag">
    <fa-icon class="tag-icon" [class.tag-icon-visible]="tag.id.startsWith(newIdPrefix)" [icon]="['fal', 'tags']"></fa-icon>
  </ng-template>
</app-tag-list>
