import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {CustomReportType, LicenseType, ReportType, User} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {AbstractClientAwareDataService} from './abstract-client-aware-data.service';
import {ClientService} from '../client/client.service';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';

const REST_ENDPOINT_URI = 'api/data/customReportTypes';

@Injectable({
  providedIn: 'root'
})
export class CustomReportTypeDataService extends AbstractClientAwareDataService<CustomReportType> {

  constructor(http: HttpClient, storage: StorageService, authenticationService: AuthenticationService, userService: UserService, clientService: ClientService, loggingService: LoggingService,
              integrityResolverService: IntegrityResolverService) {
    super(StorageKeyEnum.CUSTOM_REPORT_TYPE, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, clientService, loggingService,
          integrityResolverService, VERSION_INTRODUCED_DEFAULT, [(value) => value?.name?.toLowerCase()]);
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return currentUser.role === LicenseType.BASIC || currentUser.role === LicenseType.PROFESSIONAL || currentUser.assignedReportRights;
  }

  public dataByReportTypes(reportTypes: ReportType[]): Observable<Array<CustomReportType>> {
    return this.data.pipe(map((customReportTypes) =>
      customReportTypes.filter((customReportType) => reportTypes.some((reportType) => reportType.id ===  customReportType.reportTypeId))));
  }
}
