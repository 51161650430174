import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {IdType, ProtocolType, User} from 'submodules/baumaster-v2-common';
import {AbstractClientAwareDataService} from './abstract-client-aware-data.service';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {map} from 'rxjs/operators';
import {ClientService} from '../client/client.service';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {mapObjectsNameWithDeletedSuffix, observableToPromise} from 'src/app/utils/async-utils';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import _ from 'lodash';
import {isTaskProtocolType} from '../../utils/protocol-utils';
import {VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';

const REST_ENDPOINT_URI = 'api/data/protocolTypes';

@Injectable({
  providedIn: 'root'
})
export class ProtocolTypeDataService extends AbstractClientAwareDataService<ProtocolType> {
  public readonly isHidden = isTaskProtocolType;
  private readonly filterHidden = (protocolType: ProtocolType): boolean => !this.isHidden(protocolType);
  public readonly dataWithoutHidden$ = this.data.pipe(map((protocols) => protocols.filter(this.filterHidden)));
  public readonly dataWithoutHiddenAcrossClients$ = this.dataAcrossClients$.pipe(map((protocolTypes) => protocolTypes.filter(this.filterHidden)));
  public readonly dataWithoutHiddenGroupedById$: Observable<Record<IdType, ProtocolType>> = this.dataWithoutHidden$.pipe(
    map(entities => entities === null ? {} : _.keyBy(entities, 'id'))
  );
  public readonly dataWithoutHiddenAcrossClientsGroupedById$: Observable<Record<IdType, ProtocolType>> = this.dataWithoutHiddenAcrossClients$.pipe(
    map(entities => entities === null ? {} : _.keyBy(entities, 'id'))
  );
  public readonly dataWithoutHiddenForOwnClient$ = this.dataForOwnClient$.pipe(map((protocolTypes) => protocolTypes.filter(this.filterHidden)));

  public readonly dataActive$ = this.dataForOwnClient$.pipe(
    map((protocolTypes) => protocolTypes?.filter((protocolType) => protocolType.isActive || protocolType.isActive === undefined)));
  public readonly dataWithoutHiddenActive$ = this.dataActive$.pipe(map((protocolTypes) => protocolTypes.filter(this.filterHidden)));
  public readonly dataWithDeletedSuffix$ = this.data.pipe(mapObjectsNameWithDeletedSuffix(this.translateService));
  public readonly dataWithoutHiddenWithDeletedSuffix$ = this.dataWithoutHidden$.pipe(mapObjectsNameWithDeletedSuffix(this.translateService));
  public readonly taskProtocolType$: Observable<ProtocolType>|undefined = this.data.pipe(map((protocolTypes) => protocolTypes.find((protocolType) => isTaskProtocolType(protocolType))));
  constructor(
    http: HttpClient,
    storage: StorageService,
    authenticationService: AuthenticationService,
    userService: UserService,
    clientService: ClientService,
    loggingService: LoggingService,
    integrityResolverService: IntegrityResolverService,
    private translateService: TranslateService
  ) {
    super(StorageKeyEnum.PROTOCOL_TYPE, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, clientService, loggingService,
          integrityResolverService, VERSION_INTRODUCED_DEFAULT, [(value) => value?.name?.toLowerCase()]);
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }

  async updateProtocolType(protocolType: ProtocolType): Promise<void> {
    return observableToPromise(this.http.put<void>(`${this.restEndpointUrl}/${protocolType.id}?clientId=${protocolType.clientId}`, protocolType));
  }
}
