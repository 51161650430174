import {Address, Craft, Participant, Profile} from 'submodules/baumaster-v2-common';
import {AttachmentBlob} from './attachments';
import {CompanySource} from './contacts';

export interface PdfMailingListCompany extends CompanySource {
  name: string;
  crafts: Array<Craft>;
  allEmployees: PdfMailingListEmployee[];
  unitContacts?: PdfMailingListEmployee[];
  preSelectedEmployees: PdfMailingListEmployee[];
  assignedEmployees: PdfMailingListEmployee[];
  unassignedEmployees: PdfMailingListEmployee[];
  unitPdfMailingListCompany?: PdfMailingListCompany
}

export interface PdfMailingListEmployee extends Address {
  hasProtocol: boolean;
  hasReport: boolean;
  profile: Profile;
  participant: Participant;
}

export enum SendPdfWorkflowStep {
  MAILING_LIST = 'MAILING_LIST',
  PROTOCOL_CONFIGURATION = 'PROTOCOL_CONFIGURATION',
  EMAIL_SETTINGS = 'EMAIL_SETTINGS',
  ADVANCED_SETTINGS = 'ADVANCED_SETTINGS'
}

export interface PdfWorkflowStepConfig {
  currentStep: SendPdfWorkflowStep;
  previousStep: SendPdfWorkflowStep | null;
  nextStep: SendPdfWorkflowStep | null;
}

export enum WorkflowType {
  GlobalSearch,
  Protocol,
  Report,
  EntryMail
}

export interface Signer {
  code: string;
  name: string;
}

export interface SignerWithSignature extends Signer {
  signerName: string;
  signature: AttachmentBlob;
}

export const SIGNATURE_WIDTH = 600;
export const SIGNATURE_HEIGHT = 200;
