import _ from 'lodash';
import moment from 'moment';
import {Nullish} from '../model/nullish';

export const GANTT_DATE_INPUT_FORMAT = 'DD.MM.YYYY';

export function isValidDate(date: Date): boolean {
  return date.toString() === 'Invalid Date' ? false : true;
}

function dateStringToISOString(timeOrIsoDate: string): string {
  const isoDate: Date = new Date(timeOrIsoDate);
  if (isValidDate(isoDate)) {
    return isoDate.toISOString();
  }
  return null;
}

function getValidTimeString(timeString: string[], index: number): number {
  const time = timeString[index];
  if (_.isEmpty(time)) {
    return 0;
  }
  if (_.isNaN(Number(time))) {
    throw new Error('Invalid time format ' + time);
  }
  return Number(time);
}

function createDateFromTimeString(timeString: string[]): Date {
  const date = new Date();
  date.setHours(getValidTimeString(timeString, 0));
  date.setMinutes(getValidTimeString(timeString, 1));
  date.setSeconds(getValidTimeString(timeString, 2));
  date.setMilliseconds(0);
  return date;
}

export function timeStringOrDateToDate(time: string|Date|null|undefined): Date|null|undefined {
  if (time === undefined) {
    return undefined;
  }
  if (time === null) {
    return null;
  }
  if (time instanceof Date) {
    return time as Date;
  }
  if (!(typeof time === 'string')) {
    throw new Error(`timeStringOrDateToDate - unable to convert value "${time}" to a Date as the datatype is not supported.`);
  }
  return timeStringToDate(time);
}

export function timeStringToDate(timeOrString: string|Date): Date {
  if (timeOrString instanceof Date) {
    return timeOrString;
  }
  if (typeof timeOrString === 'string') {
    const timeParams = timeOrString.split(':');
    if (timeParams.length === 2 || timeParams.length === 3) {
      return createDateFromTimeString(timeParams);
    } else {
      throw new Error('Invalid time format ' + timeOrString);
    }
  }
  throw new Error(`Invalid time format of value "${timeOrString}. It's not a date and not a string`);
}

function timeStringToISOString(time: string): string {
  return timeStringToDate(time).toISOString();
}

export function trimTimeFromDate(dateOrIsoString: Date|string|null|undefined): Date|null|undefined {
  const date = convertISOStringToDate(dateOrIsoString);
  if (!date) {
    return date;
  }
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
}

export function convertDateTimeToISOString(timeOrIsoDate: string|Date|null|undefined): string|null {
  if (!timeOrIsoDate) {
    return null;
  }

  if (timeOrIsoDate instanceof Date) {
    return timeOrIsoDate.toISOString();
  } else {
    const ISOdate = dateStringToISOString(timeOrIsoDate);
    if (ISOdate !== null) {
      return ISOdate;
    }
    return timeStringToISOString(timeOrIsoDate);
  }
}

export function convertISOStringToDate(dateOrString: Date|string|undefined|null): Date|undefined|null {
  if (dateOrString === undefined) {
    return undefined;
  }
  if (dateOrString === null) {
    return null;
  }
  if (dateOrString instanceof Date) {
    return dateOrString as Date;
  }
  if (typeof dateOrString !== 'string') {
    throw new Error(`Value "${dateOrString}" is not of type Date nor String.`);
  }
  const dateAsString = dateOrString as string;
  if (dateAsString === '') {
    return null;
  }
  const dateAsDate = new Date(dateAsString);
  if (!isValidDate(dateAsDate)) {
    throw new Error(`Value "${dateAsString}" cannot be converted to a valid Date.`);
  }
  return dateAsDate;
}

export function convertDateTimeToString(timeOrIsoDate: string|Date|null|undefined, format: string): string|null {
  const date = convertISOStringToDate(timeOrIsoDate);
  if (date === null) {
    return null;
  }
  return moment(date).format(format);
}

export function parseDateTime(dateTimeString: string|Date|null|undefined, format: string): Date|null|undefined {
  if (dateTimeString === null) {
    return null;
  }
  if (dateTimeString === undefined) {
    return undefined;
  }
  if (_.isDate(dateTimeString)) {
    return dateTimeString as Date;
  }
  return moment(dateTimeString, format).toDate();
}

export function stripTimeFromDate(date: Nullish<Date>): Nullish<Date> {
  if (!date) {
    return date;
  }
  return new Date(date.setHours(0, 0, 0, 0));
}

/**
 * Sets date parts to a new date in UTC timezone.
 *
 * E.g., date is 2021-01-01, 00:00, CEST timezone (+02:00).
 * This function will return a new date, that will be 2021-01-01, 00:00, UTC timezone (+00:00).
 */
export function convertDateTimeToUTCDate(plainDate: Nullish<Date | string>): Date|null {
  const date = convertISOStringToDate(plainDate);
  if (!date) {
    return null;
  }

  return new Date(Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds()
  ));
}

export function trimTimeFromUTCDateAsLocalDate(plainDate: Nullish<Date | string>): Date|null {
  const date = convertISOStringToDate(plainDate);
  if (!date) {
    return null;
  }

  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0, 0);
}

export const getCalendarWeek = (theDate): number => {
  const date = new Date(theDate.getTime());
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
  // January 4 is always in week 1.
  const week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                        - 3 + (week1.getDay() + 6) % 7) / 7);
};

export const setToBeginOfWeek = (date: Date): Date => moment(date).startOf('week').toDate();

export const setToStartOfNextWeek = (date: Date): Date => {
  const nextWeekStart = setToBeginOfWeek(date);
  nextWeekStart.setDate(nextWeekStart.getDate() + 7);

  return nextWeekStart;
};

export const getWeekRange = (date: Date): [Date, Date] => {
  const begin = setToBeginOfWeek(new Date(date));
  const end = setToStartOfNextWeek(new Date(date));

  end.setHours(23, 59, 59, 999);

  return [begin, end];
};

export const isDateInRange = (date: Nullish<Date|string>, start: Date, end: Date) => {
  if (!date) {
    return false;
  }

  return start.getTime() <= new Date(date).getTime() && new Date(date).getTime() <= end.getTime();
};

export const differenceInDays = (value1: Nullish<Date> | Nullish<string>, value2: Nullish<Date> | Nullish<string>): number|undefined => {
  if (!value1 || !value2) {
    return undefined;
  }
  const date1 = convertISOStringToDate(value1);
  const date2 = convertISOStringToDate(value2);
  return moment(date1).diff(moment(date2), 'day');
};

export const differenceInWeekDays = (value1: Nullish<Date> | Nullish<string>, value2: Nullish<Date> | Nullish<string>, daysOff: Array<Date>): number|undefined => {
  if (!value1 || !value2) {
    return undefined;
  }
  const date1 = moment(convertISOStringToDate(value1));
  const date2 = moment(convertISOStringToDate(value2));

  const diffInAbsoluteDays = moment.duration(date1.diff(date2)).asDays();
  const one = diffInAbsoluteDays < 0 ? -1 : 1;
  while (isWeekend(date1.toDate()) || isDayOff(date1.toDate(), daysOff)) {
    date1.add(1, 'day');
  }
  while (isWeekend(date2.toDate()) || isDayOff(date2.toDate(), daysOff)) {
    date2.add(1, 'day');
  }

  const diffInDays = moment.duration(date1.diff(date2)).asDays();
  if (diffInDays === 0) {
    return 0;
  }
  let diffInWeekdays = 0;
  if (diffInDays < 0) {
    while (date1.isBefore(date2)) {
      if (!isWeekend(date1.toDate()) && !isDayOff(date1.toDate(), daysOff)) {
        diffInWeekdays--;
      }
      date1.add(1, 'day');
    }
  } else if (diffInDays > 0) {
    while (date1.isAfter(date2)) {
      if (!isWeekend(date1.toDate()) && !isDayOff(date1.toDate(), daysOff)) {
        diffInWeekdays++;
      }
      date1.add(-1, 'day');
    }
  }

  return diffInWeekdays;
};

export const addDays = (dateOrString: Nullish<Date> | Nullish<string>, daysToAdd: number): Nullish<Date> => {
  const date = convertISOStringToDate(dateOrString);
  if (!date) {
    return convertISOStringToDate(date);
  }
  return moment(date).add(daysToAdd, 'day').toDate();
};

export const addWeekDays = (dateOrString: Nullish<Date> | Nullish<string>, weekDaysToAdd: number, daysOff: Array<Date>): Nullish<Date> => {
  const date = convertISOStringToDate(dateOrString);
  if (!date) {
    return date;
  }
  const one = weekDaysToAdd < 0 ? -1 : 1;
  const result = moment(date);
  while (isWeekend(result.toDate()) || isDayOff(result.toDate(), daysOff)) {
    result.add(1, 'day');
  }
  const weeksToAdd = weekDaysToAdd < 0 ? Math.ceil(weekDaysToAdd / 5) : Math.floor(weekDaysToAdd / 5);
  const timestampBeforeAddingWeeks = result.toDate().getTime();
  const timestampBeforeAddingWeeksEndOfDay = moment(result).endOf('day').toDate().getTime();
  if (weeksToAdd) {
    result.add(weeksToAdd, 'week');
  }

  const timestampAfterAddingWeeks = result.toDate().getTime();
  const timestampAfterAddingWeeksEndOfDay = moment(result).endOf('day').toDate().getTime();
  const daysOffInWeeksAdded = weekDaysToAdd >= 0 ?
    daysOff.filter((dayOff) => !isWeekend(dayOff) && dayOff.getTime() >= timestampBeforeAddingWeeks && dayOff.getTime() <= timestampAfterAddingWeeksEndOfDay) :
    daysOff.filter((dayOff) => !isWeekend(dayOff) && dayOff.getTime() >= timestampAfterAddingWeeks && dayOff.getTime() <= timestampBeforeAddingWeeksEndOfDay);
  const remainingDaysToAdd = (weekDaysToAdd % 5) + (one * daysOffInWeeksAdded.length);
  let i = 0;
  while (i < Math.abs(remainingDaysToAdd)) {
    result.add(one, 'day');
    if (!isWeekend(result.toDate()) && !isDayOff(result.toDate(), daysOff)) {
      i++;
    }
  }
  return result.toDate();
};

function extractTimestamps(dateValues: Array<string|Date|null|undefined>): Array<number>|undefined {
  if (!dateValues?.length) {
    return undefined;
  }
  const dateTimestamps = dateValues.filter((dateValue) => !!dateValue).map((dateValue) => convertISOStringToDate(dateValue).getTime());
  return dateTimestamps;
}

export function findMinDate(...dateValues: Array<string|Date|null|undefined>): Date|undefined {
  const dateTimestamps = extractTimestamps(dateValues);
  if (!dateTimestamps?.length) {
    return undefined;
  }
  return new Date(_.min(dateTimestamps));
}

export function findMaxDate(...dateValues: Array<string|Date|null|undefined>): Date|undefined {
  const dateTimestamps = extractTimestamps(dateValues);
  if (!dateTimestamps?.length) {
    return undefined;
  }
  return new Date(_.max(dateTimestamps));
}

export function isWeekend(date: Date): boolean {
  const dayOfWeek = date.getDay();
  return dayOfWeek === 0 || dayOfWeek === 6;
}

export function isDayOff(date: Date, daysOff: Array<Date>): boolean {
  if (!daysOff.length) {
    return false;
  }
  return daysOff.some((dayOff) => convertISOStringToDate(dayOff).toDateString() === date.toDateString());
}

export function convertToGanttStartDate(dateOrString: Nullish<Date|string>): Date | undefined {
  if (!dateOrString) {
    return undefined;
  }
  const date = convertISOStringToDate(dateOrString);
  date.setHours(0, 0, 0, 0);
  return date;
}

export function convertToGanttEndDate(dateOrString: Nullish<Date|string>): Date | undefined {
  if (!dateOrString) {
    return undefined;
  }
  const date = convertISOStringToDate(dateOrString);
  date.setHours(23, 59, 59, 999);
  return date;
}

export function convertFromGanttStartDateToIsoString(dateOrString: Nullish<Date|string>): string | undefined {
  if (!dateOrString) {
    return undefined;
  }
  const date: Date = _.isDate(dateOrString) ? dateOrString as Date : parseDateTime(dateOrString, GANTT_DATE_INPUT_FORMAT);
  return date.toISOString();
}

export function convertFromGanttEndDateToIsoString(dateOrString: Nullish<Date|string>, calcInDays: boolean, daysOff: Array<Date>): string | undefined {
  if (!dateOrString) {
    return undefined;
  }
  let date: Date = _.isDate(dateOrString) ? new Date((dateOrString as Date).getTime()) : parseDateTime(dateOrString, GANTT_DATE_INPUT_FORMAT);
  if (date.getHours() === 23 && date.getMinutes() === 59 && date.getSeconds() === 59) {
    date.setHours(0, 0, 0, 0);
  } else {
    date = calcInDays ? addDays(date, -1) : addWeekDays(date, -1, daysOff);
  }

  return date.toISOString();
}

export function isEqualDateIgnoreMilliseconds(date1: Date, date2: Date): boolean {
  return Math.abs(date1.getTime() - date2.getTime()) < 1000;
}
