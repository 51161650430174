<app-modal-header>
    <ion-title class="ion-text-center">{{ 'newComment' | translate }}</ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <ion-grid class="grid-gap-4">
    <ion-row>
      <ion-col size="12">
        <ion-item>
          <ion-textarea label="{{ 'comment' | translate }}" label-placement="floating" *ngIf="viewDidEnter" [(ngModel)]="message" inputmode="text" rows="1" auto-grow="true" autofocus required="true" #messageInput spellcheck="true" autocomplete="on"></ion-textarea>
        </ion-item>
      </ion-col>
      <ion-col size="12">
        <app-attachment-slider (markingsChanged)="onMarkingsChanged($event.attachment, $event.markings)" [allowEditMarkings]="true" [attachmentsObservable]="allAttachmentsObservable" [attachments]="allAttachments"
        [allowDeleting]="true" (attachmentDeleted)="onAttachmentDeleted($event.attachment)"></app-attachment-slider>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="start">
    <ion-button class="omg-btn-secondary" (click)="fileUpload.click()">
      <fa-icon slot="start" [icon]="['fal','paperclip']"></fa-icon>
      <span class="ion-hide-xl-down">{{'file' | translate}}</span>
    </ion-button>
    <input type="file" [accept]="acceptedMimeTypesForUpload" (change)="uploadFileEvent($event)" multiple #fileUpload class="ion-hide">
    <ion-button class="omg-btn-secondary" (click)="projectRoomAttachmentsSelector()">
      <fa-icon slot="start" [icon]="['fal','cube']"></fa-icon>
      <span class="ion-hide-xl-down">{{'Attachments' | translate}}</span>
    </ion-button>
    <ion-button class="omg-btn-secondary" (click)="openSketchingTool()">
      <fa-icon slot="start" [icon]="['fal','signature']"></fa-icon>
      <span class="ion-hide-xl-down">{{'drawing' | translate}}</span>
    </ion-button>
    <ion-button class="omg-btn-secondary" (click)="takePicture()" *ngIf="takingPhotosSupported">
      <fa-icon slot="start" [icon]="['fal','camera']"></fa-icon>
      <span class="ion-hide-xl-down">{{'Photo' | translate}}</span>
    </ion-button>
    <ion-button class="omg-btn-secondary" (click)="takePictures()" *ngIf="takingPhotosSupported">
      <fa-icon slot="start" [icon]="['bau','foto-series']"></fa-icon>
      <span class="ion-hide-xl-down">{{'Photos' | translate}}</span>
    </ion-button>
  </ion-buttons>

  <ion-buttons slot="end">
    <ion-button class="omg-btn-text ion-hide-md-down" (click)="dismissModal()">{{ 'cancel' | translate }}</ion-button>
    <ion-button expand="block" class="omg-btn-primary" (click)="saveDismiss()" [disabled]="!hasChanges() || !isFormValid() || loading">
        <ion-spinner slot="start" class="spinner-center-button" *ngIf="loading"></ion-spinner>
        <span *ngIf="!editMode" class="ion-hide-sm-down">{{ 'button.create' | translate }}</span>
        <span *ngIf="editMode" class="ion-hide-sm-down">{{ 'button.save' | translate }}</span>
        <span class="ion-hide-sm-up"><fa-icon [icon]="['fal', 'paper-plane']" class="icons-size"></fa-icon></span>
    </ion-button>
  </ion-buttons>
</app-modal-footer>
