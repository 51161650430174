<svg class="mobile-safe-area" viewBox="0 0 227 30" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_304_78467)">
    <path
      d="M46.7764 23.1465V15.8681H55.9421C59.2863 15.8681 60.277 17.6645 60.277 19.3989C60.277 21.2882 59.3171 23.1465 55.6633 23.1465H46.7764ZM46.7764 13.0241V6.73618H55.5396C58.2336 6.73618 59.3175 7.91324 59.3175 9.92655C59.3175 11.6922 58.1407 13.0241 55.7255 13.0241H46.7764ZM43.499 25.9905H56.5613C61.3591 25.9905 63.5544 22.7748 63.5544 19.6157C63.5544 16.4256 61.9158 14.7553 59.379 14.1679V14.1061C61.1725 13.4569 62.5949 12.1258 62.5949 9.43097C62.5949 5.96336 60.4923 3.89215 55.7874 3.89215H43.499V25.9905Z"
      class="text-color" />
    <path
      d="M79.6551 19.5842C79.6551 22.2789 76.7133 24.1374 73.214 24.1374C70.9534 24.1374 69.436 23.0841 69.436 21.5665C69.436 19.8629 70.7057 19.2125 73.1211 18.9027L76.6204 18.4692C78.3547 18.2524 79.1907 18.0355 79.6553 17.509V19.5842L79.6551 19.5842ZM69.9625 14.8467C70.0552 12.3394 72.316 11.6273 74.9793 11.6273C77.4876 11.6273 79.6551 12.0607 79.6551 14.2896C79.6551 15.7444 78.5715 16.0541 76.8372 16.2397L73.3069 16.6112C68.1065 17.1684 66.3442 18.686 66.3442 21.6594C66.3442 23.7657 67.55 26.5479 72.9353 26.5479C75.5675 26.5479 78.6022 25.59 79.8407 23.9204C80.0572 25.7438 81.0773 26.1765 82.623 26.1765C83.3659 26.1765 84.2947 26.1147 85.0376 25.991V23.8277C84.7281 23.8896 84.2947 23.9516 83.9542 23.9516C82.9944 23.9516 82.561 23.5179 82.561 22.5578V13.8252C82.561 10.0512 78.4166 9.21671 75.103 9.21671C70.2411 9.21671 67.4573 10.7929 67.0552 14.8467L69.9625 14.8467Z"
      class="text-color" />
    <path
      d="M103.456 9.77394H100.364V19.1225C100.364 22.3108 97.856 23.9516 95.0373 23.9516C91.724 23.9516 90.2377 22.5896 90.2377 20.2059V9.77394H87.146V20.8251C87.146 24.879 90.0521 26.548 93.9538 26.548C96.2768 26.548 98.8463 25.8062 100.302 23.5491H100.364V25.9907H103.456V9.77394Z"
      class="text-color" />
    <path
      d="M121.103 22.2178L112.341 3.89204H107.731V25.9904H111.009V8.03624H111.071L119.647 25.9904H122.558L131.165 7.66479H131.227V25.9904H134.505V3.89204H129.958L121.103 22.2178Z"
      class="text-color" />
    <path
      d="M151.348 19.5842C151.348 22.2789 148.407 24.1374 144.907 24.1374C142.647 24.1374 141.129 23.0841 141.129 21.5665C141.129 19.8629 142.398 19.2125 144.814 18.9027L148.313 18.4692C150.048 18.2524 150.884 18.0355 151.348 17.509V19.5842ZM141.655 14.8467C141.748 12.3394 144.009 11.6273 146.672 11.6273C149.181 11.6273 151.348 12.0607 151.348 14.2896C151.348 15.7444 150.265 16.0541 148.53 16.2397L145 16.6112C139.799 17.1684 138.037 18.686 138.037 21.6594C138.037 23.7657 139.243 26.5479 144.628 26.5479C147.261 26.5479 150.296 25.59 151.534 23.9204C151.75 25.7438 152.77 26.1765 154.316 26.1765C155.059 26.1765 155.988 26.1147 156.731 25.991V23.8277C156.421 23.8896 155.988 23.9516 155.647 23.9516C154.688 23.9516 154.254 23.5179 154.254 22.5578V13.8252C154.254 10.0512 150.11 9.21671 146.796 9.21671C141.934 9.21671 139.15 10.7929 138.749 14.8467L141.655 14.8467Z"
      class="text-color" />
    <path
      d="M157.912 20.8857C158.251 25.5896 162.49 26.5478 166.205 26.5478C171.16 26.5478 174.345 24.7861 174.345 21.0096C174.345 18.7182 172.768 17.2628 168.683 16.7054L164.285 16.1171C162.087 15.8074 161.591 15.1882 161.591 14.1044C161.591 12.1845 163.883 11.6271 165.866 11.6271C169.086 11.6271 170.635 13.0206 170.727 14.6618H173.819C173.54 11.5035 171.222 9.21649 165.896 9.21649C161.344 9.21649 158.5 11.1636 158.5 14.445C158.5 16.9532 160.571 18.4395 162.892 18.7492L168.002 19.4304C170.479 19.771 171.068 20.4523 171.068 21.5981C171.068 22.7436 170.108 24.137 166.113 24.137C162.18 24.137 161.003 22.341 161.003 20.8857H157.912Z"
      class="text-color" />
    <path
      d="M178.559 9.77388H175.216V12.1845H178.559V22.713C178.559 25.0643 179.796 26.5479 182.827 26.5479C184.096 26.5479 185.242 26.5171 186.295 26.3315V23.7347C185.582 23.8586 184.87 23.9515 184.158 23.9515C182.58 23.9515 181.651 23.4871 181.651 22.1864V12.1845H186.295V9.77388H181.651V4.79003H178.559V9.77388Z"
      class="text-color" />
    <path
      d="M191.309 16.3985C191.773 13.4862 194.343 11.8131 197.193 11.8131C200.104 11.8131 202.395 13.4552 202.86 16.3985H191.309ZM206.137 18.8091V18.4073C206.137 11.6895 201.621 9.21671 197.347 9.21671C191.03 9.21671 187.938 13.2073 187.938 17.8819C187.938 22.5564 191.03 26.5479 197.347 26.5479C200.537 26.5479 204.157 24.9717 205.704 21.0705H202.426C201.372 23.3629 198.957 23.9516 197.193 23.9516C194.436 23.9516 191.463 22.1548 191.216 18.8091L206.137 18.8091Z"
      class="text-color" />
    <path
      d="M209.297 25.9906H212.388V17.7564C212.388 14.2274 214.525 12.432 217.589 12.432C218.426 12.432 219.014 12.5558 219.786 12.7416V9.40226C219.198 9.24748 218.796 9.21649 218.022 9.21649C215.979 9.21649 213.906 10.175 212.451 12.7416H212.388V9.77371H209.297V25.9906Z"
      class="text-color" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M28.2029 1.29001C28.7127 1.79984 28.9991 2.49142 28.9991 3.21243V26.7742C28.9991 27.4952 28.7127 28.1868 28.2029 28.6967C27.693 29.2065 27.0016 29.4929 26.2804 29.4929H2.71866C1.99765 29.4929 1.30607 29.2065 0.796241 28.6967C0.286409 28.1868 0 27.4952 0 26.7742V3.21243C0 2.49139 0.286409 1.79984 0.796241 1.28997C1.30611 0.780138 1.99765 0.49373 2.7187 0.49377H26.2806C27.0016 0.49377 27.6932 0.780177 28.2029 1.29001ZM18.2375 2.5332C18.4252 2.5332 18.5774 2.68535 18.5774 2.87303V7.1776C18.5774 7.36526 18.4252 7.51742 18.2375 7.51742H2.37859C2.19091 7.51742 2.03876 7.36526 2.03876 7.1776V3.89253C2.03876 3.14179 2.64734 2.5332 3.39809 2.5332H18.2375ZM26.6205 19.0718C26.8081 19.0718 26.9605 18.9197 26.9605 18.732V3.89253C26.9605 3.14179 26.3518 2.5332 25.601 2.5332H22.316C22.1284 2.5332 21.9761 2.68535 21.9761 2.87303V18.732C21.9761 18.9197 22.1284 19.0718 22.316 19.0718H26.6205ZM10.7614 27.4542C10.5738 27.4542 10.4216 27.3019 10.4216 27.1143V22.8097C10.4216 22.6221 10.5738 22.4698 10.7614 22.4698H26.6203C26.8081 22.4698 26.9603 22.6221 26.9603 22.8097V26.0948C26.9603 26.8456 26.3516 27.4542 25.6008 27.4542H10.7614ZM2.37859 10.9154C2.19091 10.9154 2.03876 11.0676 2.03876 11.2553V26.0948C2.03876 26.8456 2.64734 27.454 3.39809 27.454H6.68316C6.87082 27.454 7.02298 27.3019 7.02298 27.1141V11.2553C7.02298 11.0676 6.87082 10.9154 6.68316 10.9154H2.37859ZM11.1013 10.9154H17.898C18.2734 10.9154 18.5777 11.2198 18.5777 11.5951V18.3918C18.5777 18.7672 18.2734 19.0715 17.898 19.0715H11.1013C10.7259 19.0715 10.4216 18.7672 10.4216 18.3918V11.5951C10.4216 11.2198 10.7259 10.9154 11.1013 10.9154Z"
      class="brand-color" />
    <path
      d="M223.876 5.34022H224.496C224.815 5.34022 225.16 5.40244 225.16 5.80297C225.16 6.27947 224.78 6.30013 224.366 6.30013H223.876V5.34022ZM223.408 7.97658H223.876V6.6788H224.372L225.193 7.97658H225.697L224.835 6.64445C225.284 6.59619 225.629 6.3691 225.629 5.82367C225.629 5.24388 225.29 4.9616 224.572 4.9616H223.408V7.97658ZM222.282 6.45858C222.282 5.22329 223.208 4.27078 224.415 4.27078C225.607 4.27078 226.532 5.22326 226.532 6.45858C226.532 7.72118 225.607 8.67373 224.415 8.67373C223.208 8.67373 222.282 7.72118 222.282 6.45858ZM221.814 6.45858C221.814 7.94208 223 9.05234 224.415 9.05234C225.823 9.05234 227.001 7.94204 227.001 6.45858C227.001 5.00296 225.823 3.89215 224.415 3.89215C223 3.89215 221.814 5.00296 221.814 6.45858Z"
      class="text-color" />
  </g>
  <defs>
    <clipPath id="clip0_304_78467">
      <rect width="227" height="29.0121" class="text-color" transform="translate(0 0.493955)" />
    </clipPath>
  </defs>
</svg>