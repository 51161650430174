import {Pipe, PipeTransform} from '@angular/core';
import {IdAware, IdType} from '../../../submodules/baumaster-v2-common';

@Pipe({
  name: 'mapToId'
})
export class MapToIdPipe implements PipeTransform {

  transform<T extends IdAware>(value: Array<T>): Array<IdType> {
    return value.map((v) => v.id);
  }

}
