<div>{{ project.displayName }}</div>
<div slot="end" *ngIf="project.isConnected || project.isOfflineAvailable">
  <ion-text *ngIf="project.isConnected">
    <fa-icon id="{{project.id}}_connected" [icon]="['fal','link']"></fa-icon>
    <app-tooltip target="{{project.id}}_connected" [placement]="'top'" *ngIf="project?.clientAddress?.name">
      {{ 'projectSelector.connectedTooltip' | translate: {companyName: project?.clientAddress?.name} }}
    </app-tooltip>
  </ion-text>
  <ion-text class="ion-padding-start" *ngIf="project.isOfflineAvailable">
    <fa-icon id="{{project.id}}_offlineAvailable" [icon]="['fal','arrow-circle-down']"></fa-icon>
    <app-tooltip target="{{project.id}}_offlineAvailable" [placement]="'top'" *ngIf="project.size !== undefined && project.size !== null">
      {{ 'projectSelector.offlineAvailableTooltip' | translate: {size: project.size | bytes} }}
    </app-tooltip>
  </ion-text>
</div>