import _ from 'lodash';
import {PdfProtocolSendReq} from '../../../../requestResponse';
import {PdfPreview, Project, ProtocolEntry, ProtocolSortEntriesByEnum} from '../../../../models';
import {GlobalSearchPdfProtocolGenerateData, PdfProtocolGenerateData} from '../../pdfProtocol.model';
import {AbstractProtocolEntriesContent} from '../abstractProtocolEntries.content';
import {Content} from 'pdfmake/interfaces';
import {PdfHelperFunctions} from '../../../common-report-utils';
import {formatProjectNumberOptional} from '../../../../commonUtils';
import {isTaskProtocol} from '../../../../constants';

interface ProjectProtocols extends Project {
  data: PdfProtocolGenerateData[];
}

class GlobalSearchProtocolEntry extends AbstractProtocolEntriesContent {

  constructor(language: string, config: PdfProtocolSendReq, data: PdfProtocolGenerateData, pdfHelperFunctions: PdfHelperFunctions, pdfPreview?: PdfPreview) {
    super(language, config, data, pdfHelperFunctions, pdfPreview);
    data.protocol.sortEntriesBy = ProtocolSortEntriesByEnum.CREATED_AT;
  }

  writeProtocolEntries(content: Content[]) {
    if (this.isGroupBy) {
      const sortedParentProtocolEntries = this.sortProtocolEntries(this.getProtocolEntries());
      this.writeGroupByProtocolEntries(content, sortedParentProtocolEntries);
    } else {
      const parentProtocolEntries = _.filter(this.getProtocolEntries(), (protocolEntry) => _.isEmpty(protocolEntry.parentId));
      const sortedParentProtocolEntries = this.sortProtocolEntries(parentProtocolEntries);
      let count = sortedParentProtocolEntries.length;
      for (const protocolEntry of sortedParentProtocolEntries) {
        this.writeProtocolEntry(content, protocolEntry, isTaskProtocol(this.data.protocol));
        if (this.config.pdfProtocolSetting?.everyEntryOnNewPage && count > 1) {
          content.push({
            text: '',
            pageBreak: 'after'
          });
        }
        count--;
      }
    }
  }

  protected writeGroupByProtocolEntries(content: Content[], protocolEntries: ProtocolEntry[]) {
    const groupProtocolEntries = this.groupProtocolEntries(protocolEntries);
    for (const groupProtocolEntry of groupProtocolEntries) {
      const currentGroupName = groupProtocolEntry.groupName.replace(/.+__/, '');
      if (this.isForSpecificParticipantCompany(currentGroupName)) {
        continue;
      }
      content.push({
        text: currentGroupName,
        style: ['font12', 'groupName', 'textBold']
      });
      const protocolNumberComparer = (protocolEntry: ProtocolEntry) => this.getProtocolNumber(protocolEntry);
      const protocolEntryNumberComparer = (protocolEntry: ProtocolEntry) => protocolEntry.number;
      const sortedProtocolEntries = _.orderBy(groupProtocolEntry.protocolEntries, [protocolNumberComparer, protocolEntryNumberComparer], ['asc', 'asc']);
      let count = sortedProtocolEntries.length;
      for (const protocolEntry of sortedProtocolEntries) {
        this.writeProtocolEntry(content, protocolEntry, isTaskProtocol(this.data.protocol));
        if (this.config.pdfProtocolSetting?.everyEntryOnNewPage && count > 1) {
          content.push({
            text: '',
            pageBreak: 'after'
          });
        }
        count--;
      }
    }
  }

  public writeProjectHeader(content: Content[]) {
    content.push({
      style: ['marginTop20'],
      columns: [
        {
          style: ['marginTop5'],
          columns: [
            {
              text: `${formatProjectNumberOptional(this.data.project.number)} ${this.data.project.name}`,
              width: 'auto',
              style: ['font11', 'textBold'],
            },
            {
              text: `${this.i18n?.get('project')}`,
              width: '*',
              style: ['font8', 'alignRight'],
            },
          ]
        }
      ]
    });
    this.writeLine(content, undefined, 2);
  }

  public writeProtocolHeader(content: Content[])  {
    let protocolName: string;
    let protocolLabel = '';
    if (isTaskProtocol(this.data.protocol)) {
      protocolName = this.i18n?.get('tasks') ?? 'Tasks';
    } else {
      const protocolType = this.data.lookup.protocolTypes.get(this.data.protocol.typeId);
      const protocolShortId = `${protocolType?.code}${this.data.protocol.number.toString().padStart(2, '0')}`;
      protocolName = `${protocolShortId} ${this.data.protocol.name}`;
      protocolLabel = `${this.i18n?.get('protocol')}`;
    }
    content.push({
      columns: [
        {
          style: ['marginTop10'],
          columns: [
            {
              text: protocolName,
              style: ['font11'],
              width: 'auto'
            },
            {
              text: protocolLabel,
              width: '*',
              style: ['font8', 'alignRight'],
            }
          ]
        },
      ]
    });
    this.writeLine(content, undefined, 2);
    content.push({ text: '', style: ['marginBottom20']});
  }

  public writeClosingText(content: Content[]) {
    this.writeEndText(content);
  }

}

export class ProtocolEntriesContent{

  private config: PdfProtocolSendReq;
  private data: GlobalSearchPdfProtocolGenerateData;
  private pdfPreview: PdfPreview|undefined;

  constructor(config: PdfProtocolSendReq, data: GlobalSearchPdfProtocolGenerateData, protected pdfHelperFunctions: PdfHelperFunctions, pdfPreview?: PdfPreview) {
    this.config = config;
    this.data = data;
    this.pdfPreview = pdfPreview;
  }

  writeProtocolEntries(content: Content[]) {
    if (this.config.pdfProtocolSetting?.startNewPageForEntry) {
      content.push({
        text: '',
        pageBreak: 'after'
      });
    }
    const projectProtocols = this.getProjectProtocols();
    let isFirstProtocol: boolean;
    let lastPdfProtocolData = {} as PdfProtocolGenerateData;
    for (const projectProtocol of projectProtocols) {
      isFirstProtocol = true;
      for (const pdfProtocolData of projectProtocol.data) {
        const globaSearchProtocol = new GlobalSearchProtocolEntry(this.data.language, this.config, pdfProtocolData, this.pdfHelperFunctions, this.pdfPreview);
        if (isFirstProtocol) {
          globaSearchProtocol.writeProjectHeader(content);
          isFirstProtocol = false;
          lastPdfProtocolData = pdfProtocolData;
        } else {
          content.push({
            text: '',
            style: ['marginTop20']
          });
        }
        globaSearchProtocol.writeProtocolHeader(content);
        globaSearchProtocol.writeProtocolEntries(content);
      }
    }
    this.writeClosingText(content, lastPdfProtocolData);
  }

  writeClosingText(content: Content[], lastPdfProtocolData: PdfProtocolGenerateData) {
    const globaSearchProtocol = new GlobalSearchProtocolEntry(this.data.language, this.config, lastPdfProtocolData, this.pdfHelperFunctions, this.pdfPreview);
    globaSearchProtocol.writeClosingText(content);
  }


  private getProjectProtocols(): ProjectProtocols[] {
    const projectProtocols: ProjectProtocols[] = [];
    for (const pdfProtocolData of this.data.protocolDataList) {
      const projectProtocolIndex = _.findIndex(projectProtocols, (projectProtocol) =>  projectProtocol.id === pdfProtocolData.project.id);
      if (projectProtocolIndex !== -1) {
        projectProtocols[projectProtocolIndex].data.push(pdfProtocolData);
      } else {
        const newProjectProtocol = {...pdfProtocolData.project, data: [pdfProtocolData]};
        projectProtocols.push(newProjectProtocol);
      }
    }
    return projectProtocols;
  }

}
