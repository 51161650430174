<app-modal-header>
  <ion-title>{{(textTemplate?.id ? 'textTemplate.editModal.editTitle' : 'textTemplate.editModal.createTitle') | translate}}</ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <app-text-template-edit-form [prefillTextTemplate]="prefillTextTemplate" [textTemplate]="textTemplate" #form></app-text-template-edit-form>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text ion-hide-sm-down" (click)="cancel()">
      {{'cancel' | translate}}
    </ion-button>
    <ion-button class="omg-btn-secondary omg-btn-color-danger" *ngIf="onDelete && textTemplate?.id" (click)="remove()">
      <fa-icon class="ion-hide-sm-up" [icon]="['fal', 'trash-alt']"></fa-icon>
      <span class="ion-hide-sm-down">{{'delete' | translate}}</span>
    </ion-button>
    <ion-button *ngIf="form" class="omg-btn-secondary" [disabled]="form.form.invalid" (click)="apply()">
      {{'textTemplate.editModal.saveAndUse' | translate}}
    </ion-button>
  </ion-buttons>
</app-modal-footer>
