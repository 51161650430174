import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {IdType, ReportType, ReportTypeCode} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {AbstractNonClientAwareDataService} from './abstract-non-client-aware-data.service';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';

const REST_ENDPOINT_URI = 'api/data/reportTypes';

@Injectable({
  providedIn: 'root'
})
export class ReportTypeDataService extends AbstractNonClientAwareDataService<ReportType> {

  constructor(http: HttpClient, storage: StorageService, authenticationService: AuthenticationService, loggingService: LoggingService, integrityResolverService: IntegrityResolverService) {
    super(StorageKeyEnum.REPORT_TYPE, REST_ENDPOINT_URI, [], http, storage, authenticationService, loggingService,
          integrityResolverService);
  }

  getByName(reportTypeName: string): Observable<ReportType | undefined> {
    return this.data.pipe(map((reportTypes) => reportTypes.find((reportType) => reportType.name === reportTypeName)));
  }

  public getByReportTypeCode(reportTypeCodes: ReportTypeCode[]): Observable<ReportType[]> {
    return this.data.pipe(map((reportTypes) => reportTypes.filter((reportType) => reportTypeCodes.some((reportTypeCode) => reportTypeCode === reportType.name))));
  }

  getReportTypeCodeById(id: IdType): Observable<ReportTypeCode> {
    return this.data.pipe(filter((reportTypes) => reportTypes.length > 0), map((reportTypes) => {
      const reportTypeCode = reportTypes.find((type) => type.id === id)?.name as ReportTypeCode;
      if (!ReportTypeCode) {
        throw new Error(`Report type id '${id}' not found!`);
      }

      return reportTypeCode;
    }));
  }
}
