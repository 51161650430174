<app-autodesk-viewer 
  [modal]="modal"
  [selectedBimVersion]="selectedBimVersion"
  [selectedProtocolEntry]="selectedProtocolEntry"
  [markers]="markers"
  [acrossProjects]="acrossProjects"
  (markerChanges)="markerChanges($event)"
  (protocolEntryMarkerClick)="emitOpenEntryModal($event)"
  [readonly]="readonly || isClosedEntry"
  >
</app-autodesk-viewer>