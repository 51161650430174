import { Directive, HostBinding, Input } from '@angular/core';
import {Nullish} from 'src/app/model/nullish';

const DEFAULT_GAP = 1;

@Directive({
  selector: '[appLayout]'
})
export class LayoutDirective {

  /** Layout gap */
  @Input()
  appLayout: Nullish<number | ''> = DEFAULT_GAP;

  @Input()
  appLayoutColumn = false;

  @Input()
  appLayoutAlignItems: Nullish<string>;

  @Input()
  appLayoutJustifyContent: Nullish<string>;

  @HostBinding('class')
  get baseClass() {
    if (this.appLayoutColumn) {
      return 'omg-layout-column';
    }

    return 'omg-layout';
  }

  @HostBinding('style.gap')
  get gapStyle() {
    const gap = this.appLayout === '' ? DEFAULT_GAP : this.appLayout;
    return `calc(var(--omg-base-spacing) * ${gap})`;
  }

  @HostBinding('style.justify-content')
  get justifyContentStyle() {
    return this.appLayoutJustifyContent;
  }

  @HostBinding('style.align-items')
  get alignItemsStyle() {
    return this.appLayoutAlignItems;
  }

}
