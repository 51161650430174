import {ProtocolEntryLocationDataService} from './protocol-entry-location-data.service';
import {Injectable} from '@angular/core';
import {IdType, ProjectProtocolLocation, ProtocolEntryLocation, User} from 'submodules/baumaster-v2-common';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {ProjectDataService} from './project-data.service';
import {combineLatest, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {StorageKeyEnum} from '../../shared/constants';
import {LoggingService} from '../common/logging.service';
import {AbstractProjectAwareMappingDataService} from './abstract-project-aware-mapping-data.service';
import _ from 'lodash';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {mapObjectsWithDeletedSuffix} from 'src/app/utils/async-utils';
import {TranslateService} from '@ngx-translate/core';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';

const REST_ENDPOINT_URI = 'api/data/projectProtocolLocations';

@Injectable({
  providedIn: 'root'
})
export class ProjectProtocolLocationDataService extends AbstractProjectAwareMappingDataService<ProjectProtocolLocation> {

  constructor(http: HttpClient,
              storage: StorageService,
              authenticationService: AuthenticationService,
              userService: UserService,
              protected projectDataService: ProjectDataService,
              protected projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
              loggingService: LoggingService, integrityResolverService: IntegrityResolverService,
              private protocolEntryLocationService: ProtocolEntryLocationDataService,
              private translateService: TranslateService) {
    super(StorageKeyEnum.PROJECT_PROTOCOL_LOCATION, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, projectDataService, loggingService, projectAvailabilityExpirationService,
          integrityResolverService);
  }

  getProjectProtocolLocations(additionalLocationIdsToInclude?: Array<IdType>): Observable<ProtocolEntryLocation[]> {
    const additionalLocationIdsToIncludeSet = new Set(additionalLocationIdsToInclude ?? []);
    return combineLatest([this.data, this.protocolEntryLocationService.data]).pipe(
      map(([projectLocations, locations]) =>
        locations.filter((location) => additionalLocationIdsToIncludeSet.has(location.id) ||
        (projectLocations.find((projectLocation) => projectLocation.protocolentrylocationId === location.id) && location.isActive !== false)))
    );
  }

  getProjectProtocolLocationsWithDeletedSuffix(additionalLocationIdsToInclude?: Array<IdType>): Observable<ProtocolEntryLocation[]> {
    return this.getProjectProtocolLocations(additionalLocationIdsToInclude).pipe(mapObjectsWithDeletedSuffix(this.translateService, 'location', v => v.location));
  }

  getProjectProtocolLocationsInProject(projectId: IdType, additionalLocationIdsToInclude?: Array<IdType>): Observable<ProtocolEntryLocation[]> {
    const additionalLocationIdsToIncludeSet = new Set(additionalLocationIdsToInclude ?? []);
    return combineLatest([this.getDataForProject$(projectId), this.protocolEntryLocationService.dataAcrossClients$]).pipe(
      map(([projectLocations, locations]) =>
        locations.filter((location) => (projectLocations.find((projectLocation) => projectLocation.protocolentrylocationId === location.id) && location.isActive !== false) ||
        additionalLocationIdsToIncludeSet.has(location.id)))
    );
  }

  getProjectProtocolLocationsInProjectWithDeletedSuffix(projectId: IdType, additionalLocationIdsToInclude?: Array<IdType>): Observable<ProtocolEntryLocation[]> {
    if (!projectId) {
      return of([]);
    }
    return this.getProjectProtocolLocationsInProject(projectId, additionalLocationIdsToInclude).pipe(mapObjectsWithDeletedSuffix(this.translateService, 'location', v => v.location));
  }

  getByProtocolLocationAcrossProjects(protocolEntryLocation: ProtocolEntryLocation): Observable<ProjectProtocolLocation[]> {
    return this.dataByProjectId$.pipe(map((allProjectProtocolEntryLocationsMap) => _.flatten(Array.from(allProjectProtocolEntryLocationsMap.values()))
      .filter((projectProtocolEntryLocation: ProjectProtocolLocation) => projectProtocolEntryLocation.protocolentrylocationId === protocolEntryLocation.id)));
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }

}
