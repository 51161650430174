<ion-content>
  <ion-split-pane contentId="main" [style.flex-grow]="1" when="lg" (ionSplitPaneVisible)="splitPaneChange($event)">
    <ion-content class="protocol-entry-list split-pane-side">
      <app-protocol-entries #appProtocolEntries 
        [protocol]="protocol" 
        [showFilter]="isMenuVisible"></app-protocol-entries>
    </ion-content>

    <ion-content id="main" class="protocol-entry-details">
      <ion-router-outlet></ion-router-outlet>
    </ion-content>
  </ion-split-pane>
  <ion-fab *ngIf="!protocol?.closedAt" class="margin-right-10 ion-hide-lg-up omg-btn-split" slot="fixed" vertical="bottom" horizontal="end">
    <ion-button (click)="addNewProtocolEntry()" class="omg-btn-primary">
      <fa-icon slot="start" [icon]="entryCreationModeIcon$ | async"></fa-icon>
      <span>{{ 'protocolEntry.create' | translate }}</span>
    </ion-button>
    <ion-button  (click)="openButtonActions($event)" class="omg-btn-primary">
      <fa-icon slot="start" [icon]="['fal', 'chevron-up']"></fa-icon>
    </ion-button>
  </ion-fab>
</ion-content>
