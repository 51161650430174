import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TagObjectType} from 'src/app/model/tag';
import {TagService} from 'src/app/services/tags/tag.service';
import {PopoverService} from 'src/app/services/ui/popover.service';
import {IdType, TagBase} from 'submodules/baumaster-v2-common';
import {tagsPopoverEnterAnimation} from '../tags-popover/animation/tags-popover.animation';
import {TagsPopoverComponent} from '../tags-popover/tags-popover.component';

@Component({
  selector: 'app-tag-list-edit',
  templateUrl: './tag-list-edit.component.html',
  styleUrls: ['./tag-list-edit.component.scss'],
})
export class TagListEditComponent {

  @Input()
  objectId: IdType;

  @Input()
  objectType: TagObjectType;

  @Input()
  tags: TagBase[];

  @Output()
  tagsChange = new EventEmitter<TagBase[]>();

  constructor(private popoverService: PopoverService, private tagService: TagService) { }

  handleClick(event: MouseEvent) {
    if ('PointerEvent' in window) {
      return;
    }

    this.openPopover(event, 'mouse');
  }

  async openPopover(event: MouseEvent, pointerType: PointerEvent['pointerType']) {
    const {tags, objectType} = this;
    const {popover} = this.popoverService.openAndClose(pointerType !== 'touch' ? event : undefined, {
      component: TagsPopoverComponent,
      componentProps: {tags, objectType, allTags$: this.tagService.getAllTagsForObjectType$(objectType)},
      backdropDismiss: false,
      keyboardClose: false,
      cssClass: pointerType === 'touch' ? 'omg-top-popover omg-popover-wider' : 'omg-popover-wider',
      enterAnimation: tagsPopoverEnterAnimation,
    });

    const {data} = await (await popover).onDidDismiss();
    if (data && Array.isArray(data)) {
      this.tagsChange.emit(data);
    }
  }

}
