import {Pipe, PipeTransform} from '@angular/core';
import _ from 'lodash';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  transform<T>(data: Array<T>, fields: string[], orders: Array<'asc' | 'desc'>): Array<T> {
    return _.orderBy(data, fields, orders);
  }

}
