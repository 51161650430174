import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {EditorModule, TINYMCE_SCRIPT_SRC} from '@tinymce/tinymce-angular';
import {ActionsPopoverComponent} from 'src/app/components/ui/actions-popover/actions-popover.component';
import {AvatarComponent} from 'src/app/components/ui/avatar/avatar.component';
import {ChipWithLabelComponent} from 'src/app/components/ui/chip-with-label/chip-with-label.component';
import {RichTextEditorComponent} from 'src/app/components/ui/rich-text-editor/rich-text-editor.component';
import {StepIndicatorComponent} from 'src/app/components/ui/step-indicator/step-indicator.component';
import {TimeComponent} from 'src/app/components/ui/time/time.component';
import {DividerDirective} from 'src/app/directives/ui/divider.directive';
import {LayoutDirective} from 'src/app/directives/ui/layout.directive';
import {SlimToggleDirective} from '../../../directives/slim-toggle.directive';
import {EditorFocusDirective} from '../../../directives/ui/editor-focus.directive';
import {WatchDimensionsDirective} from '../../../directives/ui/watch-dimensions.directive';
import {InlineInputDirective} from '../../../directives/ui/inline-input.directive';
import {ScreenTooSmallComponent} from 'src/app/components/ui/screen-too-small/screen-too-small.component';
import {AccordionComponent} from 'src/app/components/ui/accordion/accordion.component';
import {ActionWithLoadingDirective} from '../../../directives/ui/action-with-loading.directive';
import {LoadingComponent} from 'src/app/components/ui/loading/loading.component';
import {ModalHeaderComponent} from 'src/app/components/ui/modal-header/modal-header.component';
import {ModalFooterComponent} from 'src/app/components/ui/modal-footer/modal-footer.component';
import {CancelApplyModalFrameComponent} from 'src/app/components/ui/cancel-apply-modal-frame/cancel-apply-modal-frame.component';
import {IonRadioDirective} from '../../../directives/ui/ion-radio.directive';
import {ModalStepIndicatorComponent} from 'src/app/components/ui/modal-step-indicator/modal-step-indicator.component';
import {CheckboxGroupDirective} from '../../../directives/ui/checkbox-group.directive';
import {MbscCalendarDirective} from '../../../directives/ui/mbsc-calendar.directive';
import {AvatarBauColorDirective} from 'src/app/directives/ui/avatar-bau-color.directive';

const COMPONENTS = [
  SlimToggleDirective,
  TimeComponent,
  AvatarComponent,
  ChipWithLabelComponent,
  EditorFocusDirective,
  WatchDimensionsDirective,
  RichTextEditorComponent,
  StepIndicatorComponent,
  ActionsPopoverComponent,
  LayoutDirective,
  DividerDirective,
  InlineInputDirective,
  ScreenTooSmallComponent,
  AccordionComponent,
  ActionWithLoadingDirective,
  LoadingComponent,
  ModalHeaderComponent,
  ModalFooterComponent,
  CancelApplyModalFrameComponent,
  IonRadioDirective,
  ModalStepIndicatorComponent,
  AvatarBauColorDirective,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    CheckboxGroupDirective,
    MbscCalendarDirective,
  ],
  imports: [
    CommonModule,
    EditorModule,
    IonicModule,
    TranslateModule,
    FormsModule,
    FontAwesomeModule,
  ],
  exports: [
    ...COMPONENTS,
    CheckboxGroupDirective,
    MbscCalendarDirective,
  ],
  providers: [
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
  ],
})
export class UiModule { }
