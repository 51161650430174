import {ChangeDetectorRef, Directive, OnDestroy, OnInit} from '@angular/core';
import {EditorComponent} from '@tinymce/tinymce-angular';
import {Subscription} from 'rxjs';

@Directive({
  selector: 'editor[appEditorFocus]',
  exportAs: 'appEditorFocus',
})
export class EditorFocusDirective implements OnDestroy, OnInit {

  focused = false;

  private onFocusSubscription?: Subscription;
  private onBlurSubscription?: Subscription;

  constructor(private editor: EditorComponent, private cRef: ChangeDetectorRef) {}

  private initializeListeners() {
    this.onFocusSubscription = this.editor.onFocus.subscribe(() => {
      setTimeout(() => {
        this.focused = true;
        this.cRef.detectChanges();
      }, 1);
    });
    this.onBlurSubscription = this.editor.onBlur.subscribe(() => {
      setTimeout(() => {
        this.focused = false;
        this.cRef.detectChanges();
      }, 1);
    });
  }

  private destroyListeners() {
    this.onFocusSubscription?.unsubscribe();
    this.onBlurSubscription?.unsubscribe();
  }

  ngOnInit() {
    this.initializeListeners();
  }

  ngOnDestroy() {
    this.destroyListeners();
  }

}
