import { ColumnConfig } from '../ColumnConfig';

const UniqueTemplateInProject = ['project_id'];

export const ShareVersionsEmailSettingsTemplateColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'project_id', prop: 'projectId', uniqueWith: [UniqueTemplateInProject]},
  {name: 'subject', prop: 'subject', def: null},
  {name: 'text', prop: 'text', def: null},
  {name: 'add_signature', prop: 'addSignature', def: null},
  {name: 'mail_text_under_download_link', prop: 'mailTextUnderDownloadLink', def: null},
];
