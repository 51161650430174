<div>
  <ion-item lines="none">
    <ion-toggle
      slot="start"
      [ngModel]="nextMeeting.show"
      (ngModelChange)="onShowChange($event)"
      appSlimToggle
    ></ion-toggle>
    <ion-label>{{ "sendProtocol.sendNewAppointment" | translate }}</ion-label>
  </ion-item>

  <ng-container *ngIf="nextMeeting?.show">
    <app-next-meeting-form
      [nextMeeting]="nextMeeting"
      (nextMeetingChange)="handleNextMeetingChange($event)"
    ></app-next-meeting-form>
    <app-individual-next-meetings-groups
      [participants]="participants"
      (afterViewInit)="handleMeetingsGroupsInit()"
      [nextMeeting]="nextMeeting"
      [meetings]="individualNextMeetings"
      (meetingsChange)="individualNextMeetingsChange.emit($event)"
    ></app-individual-next-meetings-groups>
  </ng-container>
</div>
