import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {StepIndicatorProps} from './step-indicator-props';

@Component({
  selector: 'app-step-indicator',
  templateUrl: './step-indicator.component.html',
  styleUrls: ['./step-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepIndicatorComponent implements StepIndicatorProps {

  steps: 0[] = [];

  @Input()
  set stepsCount(stepsCount: number) {
    this.steps = Array(stepsCount).fill(0);
  }

  @Input()
  current: number;

}
