<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col *ngIf="!isInSettings" class="d-flex">
        <ion-text [innerHTML]="'maxDevices.infoText' | translate"></ion-text>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="!isInSettings" class="mt-4">
      <ion-col class="d-flex ion-justify-content-center ion-text-center">
        <ion-text [innerHTML]="'maxDevices.selectText' | translate"></ion-text>
      </ion-col>
    </ion-row>
    <ion-row [class]="isInSettings ? 'd-flex ion-justify-content-flex-start' : 'd-flex ion-justify-content-center ion-align-self-center mt-4'">
      <ion-col *ngFor="let device of activeDevices" sizeLg="4" size="12" class="d-flex col-width" [class.ion-justify-content-center]="!isInSettings">
        <div class="device-box" [class.selected]="device.deviceUuid === selectedDeviceId" [class.selectable]="!isInSettings" (click)="changeSelection(device)">
          <div class="remove-button" (click)="removeDevice(device)">
            <fa-icon *ngIf="isInSettings && device.deviceUuid !== currentDeviceUuid" [icon]="['fal', 'times']"></fa-icon>
          </div>
          <ion-grid>
            <ion-row>
              <ion-col size="12" class="d-flex ion-justify-content-center bigger-icon">
                <fa-icon [icon]="['fal', getIcon(device)]" [rotate]="getIcon(device) === 'tablet' ? 90 : 0"></fa-icon>
              </ion-col>
              <ion-col *ngIf="!(device.deviceUuid === currentDeviceUuid)" size="12" class="d-flex ion-justify-content-center ion-no-padding date-text mt-2">
                {{ (device.lastDataSync | date: 'dd. MMM yy') + ' (' + (device.lastDataSync | date: 'HH:mm' ) + ('maxDevices.time' | translate) + ')'}}
              </ion-col>
              <ion-col *ngIf="isInSettings && device.deviceUuid === currentDeviceUuid" size="12" class="d-flex ion-justify-content-center ion-no-padding date-text brand-box mt-2">
                {{ 'maxDevices.currentDevice' | translate }}
              </ion-col>
              <ion-col size="12" class="d-flex ion-justify-content-center ion-no-padding medium-bold">
                {{ (isInSettings && device.deviceUuid === currentDeviceUuid) ? ('maxDevices.currentlyOnline' | translate) : ('maxDevices.lastOnline' | translate) }}
              </ion-col>
              <ion-col size="12" class="d-flex ion-justify-content-center ion-no-padding mt-4 medium">
                {{ ('maxDevices.deviceType' | translate) }} <span class="medium-bold ml-1">{{ getDeviceType(device) }}</span>
              </ion-col>
              <ion-col size="12" class="d-flex ion-justify-content-center ion-no-padding mt-1 medium">
                {{ ('maxDevices.device' | translate) }} <span class="medium-bold ml-1">{{ getDeviceNameAndOs(device) }}</span>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="(devModeEnabled$ | async) && showInactive" class="d-flex ion-align-self-center mt-4" [class.ion-justify-content-center]="!isInSettings">
      <ion-col *ngFor="let device of inactiveDevices" sizeLg="4" size="12" class="d-flex col-width">
        <div class="device-box">
          <ion-grid>
            <ion-row>
              <ion-col size="12" class="d-flex ion-justify-content-center bigger-icon">
                <fa-icon [icon]="['fal', getIcon(device)]" [rotate]="getIcon(device) === 'tablet' ? 90 : 0"></fa-icon>
              </ion-col>
              <ion-col *ngIf="!(device.deviceUuid === currentDeviceUuid) || !device.active" size="12" class="d-flex ion-justify-content-center ion-no-padding date-text mt-2">
                {{ (device.lastDataSync | date: 'dd. MMM yy') + ' (' + (device.lastDataSync | date: 'HH:mm' ) + ('maxDevices.time' | translate) + ')'}}
              </ion-col>
              <ion-col *ngIf="isInSettings && device.deviceUuid === currentDeviceUuid" size="12" class="d-flex ion-justify-content-center ion-no-padding date-text brand-box mt-2">
                {{ 'maxDevices.currentDevice' | translate }}
              </ion-col>
              <ion-col size="12" class="d-flex ion-justify-content-center ion-no-padding medium-bold">
                {{ (isInSettings && device.deviceUuid === currentDeviceUuid) ? ('maxDevices.currentlyOnline' | translate) : ('maxDevices.lastOnline' | translate) }}
              </ion-col>
              <ion-col size="12" class="d-flex ion-justify-content-center ion-no-padding mt-4 medium">
                {{ ('maxDevices.deviceType' | translate) }} <span class="medium-bold ml-1">{{ getDeviceType(device) }}</span>
              </ion-col>
              <ion-col size="12" class="d-flex ion-justify-content-center ion-no-padding mt-1 medium">
                {{ ('maxDevices.device' | translate) }} <span class="medium-bold ml-1">{{ getDeviceNameAndOs(device) }}</span>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="isInSettings && activeDevices?.length > 1">
      <ion-col size="12">
        <ion-button class="omg-btn-secondary omg-btn-color-danger" (click)="removeAllOther()">
          <fa-icon slot="start" [icon]="['fal', 'times']"></fa-icon>
          <span>{{ 'maxDevices.removeButton' | translate }}</span>
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="devModeEnabled$ | async">
      <ion-col>
        <ion-item lines="none">
          <ion-toggle appSlimToggle [checked]="showInactive" (ionChange)="changeShowInactive()"></ion-toggle>
          <ion-label>{{ 'maxDevices.showInactive' | translate }}</ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row class="mt-4" *ngIf="!isInSettings && isOneFirefox()">
      <ion-col class="firefox-info">
        {{ 'maxDevices.firefoxInfo' | translate }}
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>