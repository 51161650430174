import { ColumnConfig } from '../ColumnConfig';

export const UserInviteColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'email', prop: 'email', cnd: true},
  {name: 'profile_id', prop: 'profileId', cnd: true},
  {name: 'language'},
  {name: 'is_staff', prop: 'isStaff'},
  {name: 'is_client_admin', prop: 'isClientAdmin'},
  {name: 'role'},
  {name: 'assigned_report_rights', prop: 'assignedReportRights'},
  {name: 'valid_until', prop: 'validUntil', cast: 'Timestamp'},
  {name: 'created_by_id', prop: 'createdById'},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
];
