import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ClientTextTemplate, User} from 'submodules/baumaster-v2-common';
import {StorageKeyEnum} from '../../shared/constants';
import {AuthenticationService} from '../auth/authentication.service';
import {ClientService} from '../client/client.service';
import {LoggingService} from '../common/logging.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {StorageService} from '../storage.service';
import {UserService} from '../user/user.service';
import {AbstractClientAwareDataService} from './abstract-client-aware-data.service';
import {VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';

const REST_ENDPOINT_URI = 'api/data/clientTextTemplates';

@Injectable({
  providedIn: 'root'
})
export class ClientTextTemplateDataService extends AbstractClientAwareDataService<ClientTextTemplate> {
  constructor(http: HttpClient, storage: StorageService, authenticationService: AuthenticationService, userService: UserService, clientService: ClientService, loggingService: LoggingService,
              integrityResolverService: IntegrityResolverService) {
    super(StorageKeyEnum.CLIENT_TEXT_TEMPLATE, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, clientService, loggingService,
          integrityResolverService, VERSION_INTRODUCED_DEFAULT, [(dataItem: ClientTextTemplate) => dataItem.name?.toLowerCase()]);
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }
}
