import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {AbstractProjectAwareDataService} from './abstract-project-aware-data.service';
import {ProjectDataService} from './project-data.service';
import {ConstructionScheduleLink, IdType, User} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {DataServiceDeleteOptions} from './abstract-data.service';
import _ from 'lodash';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {map} from 'rxjs/operators';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';

const REST_ENDPOINT_URI = 'api/data/constructionScheduleLink';

@Injectable({
  providedIn: 'root'
})
export class ConstructionScheduleLinkDataService extends AbstractProjectAwareDataService<ConstructionScheduleLink> {

  constructor(http: HttpClient, storage: StorageService, authenticationService: AuthenticationService, userService: UserService, protected projectDataService: ProjectDataService,
              protected projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
              loggingService: LoggingService, integrityResolverService: IntegrityResolverService) {
    super(StorageKeyEnum.CONSTRUCTION_SCHEDULE_LINK, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, projectDataService, loggingService, projectAvailabilityExpirationService,
          integrityResolverService);
  }

  public async deleteByTaskIds(constructionScheduleTaskIds: IdType|Array<IdType>, projectId: IdType, options?: DataServiceDeleteOptions): Promise<void> {
    const idArray: Array<IdType> = _.isArray(constructionScheduleTaskIds) ? constructionScheduleTaskIds as Array<IdType> : [constructionScheduleTaskIds as IdType];
    await super.delete((storageData) => {
      const deleteElements = storageData.filter((value) => idArray.some((id) => id === value.source || id === value.target));
      return deleteElements;
    }, projectId, options);
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }

  getInnerLinksByTaskIds(constructionScheduleTaskIds: IdType[]) {
    const predicate = (link: ConstructionScheduleLink) => constructionScheduleTaskIds.includes(link.source) && constructionScheduleTaskIds.includes(link.target);

    return this.data.pipe(map((data) => data.filter(predicate)));
  }
}
