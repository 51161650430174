<div class="main-menu-content d-flex flex-column gap-4">
  <div *ngIf="withMenuButton && (sideBarSetting$ | async)?.pinned" class="d-flex flex-row logo-and-button">
    <app-baumaster-logo-svg></app-baumaster-logo-svg>
    <app-menu-button></app-menu-button>
  </div>

  <div *ngIf="!(sideBarSetting$ | async)?.pinned">
    <app-menu-button *ngIf="withMenuButton"></app-menu-button>
  </div>

  <app-baumaster-logo-svg *ngIf="!withMenuButton"></app-baumaster-logo-svg>

  <div class="d-flex flex-column menu-items">
    <ng-container *ngFor="let p of menuPages; let i = index">
      <div class="menu-group-with-items" [ngClass]="p.className" *ngIf="p.menus">
        <div class="menu-group">
          <ion-button class="omg-btn-primary omg-btn-color-neutral omg-btn-menu" (click)="p.expandable && menuService.toggleGroupExpanded(p)">
            <fa-icon *ngIf="p.icon" [icon]="p.icon" slot="start"></fa-icon>
            <span class="omg-btn-popover-list-label">{{(p.title$ ? (p.title$ | async) : p.title) | translate}}</span>
            <fa-icon *ngIf="p.expandable" [icon]="['fal', menuService.isGroupExpanded(p) ? 'chevron-up' : 'chevron-down']" slot="end"></fa-icon>
          </ion-button>
        </div>
        <ng-container *ngFor="let subMenu of menuService.isGroupExpanded(p) ? p.menus : []">
          <ng-container *ngTemplateOutlet="menuItemTemplate; context: {menuItem: subMenu}"></ng-container>
        </ng-container>
      </div>
      <ng-container *ngIf="!p.menus">
        <ng-container *ngTemplateOutlet="menuItemTemplate; context: {menuItem: p}"></ng-container>
      </ng-container>
      <div *ngIf="i === 2" class="project-name-number">
        <hr class="divider">
        <!-- Code below may be needed in the future when we want to introduce the project name text. For now only the divider is needed -->
        <!-- <div class="text-left" *ngIf="(sideBarSetting$ | async)?.pinned || !withMenuButton">
          <span class="text-ellipsis"><strong>{{ 'MENU.expandedProjectName' | translate:{projectNameWithNumber: ((((project$ | async)?.number) | projectNumber) + ' ' + ((project$ | async)?.name))} }}</strong></span>
        </div>
        <span class="text-center text-ellipsis" *ngIf="!(sideBarSetting$ | async)?.pinned && withMenuButton"><strong>{{ ((project$ | async)?.number) | projectNumber }}</strong></span> -->
      </div>
    </ng-container>
  </div>

  <div class="menu-footer-content">
    <ion-label class="size-xs" color="white">
      BauMaster {{environment.version}} ({{environment.name}})
    </ion-label>
  </div>

</div>

<ng-template let-menuItem="menuItem" #menuItemTemplate>
  <div class="menu-toggle" (click)="hideMenu(menuItem)" *ngIf="!menuItem.disabled$ || !(menuItem.disabled$ | async)" [id]="'menu-' + instanceId + '-item-' + menuItem.title">
    <ion-button
      *ngIf="!menuItem.actionInProgress$ || !(menuItem.actionInProgress$ | async); else actionInProgressTemplate"
      class="omg-btn-primary omg-btn-color-neutral omg-btn-menu"
      [class.omg-btn-color-brand]="menuItem.url === selectedMenu?.url"
      (click)="menuClicked(menuItem)"
      routerDirection="root"
      [routerLink]="menuItem.url ? [menuItem.url] : undefined"
    >
      <fa-icon *ngIf="menuItem.icon" [icon]="menuItem.icon" slot="start"></fa-icon>
      <span class="omg-btn-popover-list-label">
        {{(menuItem.title$ ? (menuItem.title$ | async) : menuItem.title) | translate}}
        <span *ngIf="menuItem.isNew && !renderTooltips" class="new-menu" [class.selected]="menuItem.url === selectedMenu?.url">
          {{ 'newFeature' | translate }}
        </span>
      </span>
      <fa-icon *ngIf="menuItem.externalLinkIcon" [icon]="['fal', 'external-link']" class="endIcon" slot="end"></fa-icon>
      <app-tooltip *ngIf="renderTooltips" [target]="'menu-' + instanceId + '-item-' + menuItem.title">
        {{ menuItem.title | translate }}
      </app-tooltip>
    </ion-button>
    <ng-template #actionInProgressTemplate>
      <ion-button
        class="omg-btn-primary omg-btn-color-neutral omg-btn-menu"
        [class.omg-btn-color-brand]="menuItem.url === selectedMenu?.url"
        routerDirection="root"
        [routerLink]="menuItem.url ? [menuItem.url] : undefined"
        [title]="(menuItem.titleInProgress || (menuItem.title$ ? (menuItem.title$ | async) : menuItem.title)) | translate"
      >
        <fa-icon [icon]="['fal', 'sync']" animation="spin" [spin]="true" slot="start"></fa-icon>
        <span class="omg-btn-popover-list-label">
          {{(menuItem.titleInProgress || (menuItem.title$ ? (menuItem.title$ | async) : menuItem.title)) | translate}}
        </span>
      </ion-button>
    </ng-template>
  </div>
</ng-template>
