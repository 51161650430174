import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TextTemplate} from 'submodules/baumaster-v2-common';
import {StorageKeyEnum} from '../../shared/constants';
import {AuthenticationService} from '../auth/authentication.service';
import {ClientService} from '../client/client.service';
import {LoggingService} from '../common/logging.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {StorageService} from '../storage.service';
import {UserService} from '../user/user.service';
import {DataServiceDeleteOptions, DataServiceInsertOptions, VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';
import {AbstractNonClientAwareDataService} from './abstract-non-client-aware-data.service';

const REST_ENDPOINT_URI = 'api/data/textTemplates';

@Injectable({
  providedIn: 'root'
})
export class TextTemplateDataService extends AbstractNonClientAwareDataService<TextTemplate> {
  constructor(http: HttpClient, storage: StorageService, authenticationService: AuthenticationService, userService: UserService, clientService: ClientService, loggingService: LoggingService,
              integrityResolverService: IntegrityResolverService) {
    super(StorageKeyEnum.TEXT_TEMPLATE, REST_ENDPOINT_URI, [], http, storage, authenticationService, loggingService,
          integrityResolverService, VERSION_INTRODUCED_DEFAULT, [(dataItem: TextTemplate) => dataItem.name?.toLowerCase()]);
  }

  async insertUpdateDelete(changes: {
    inserts?: Array<TextTemplate> | ((storageData: Array<TextTemplate>) => (Array<TextTemplate> | TextTemplate | undefined)) | TextTemplate;
    insertOptions?: DataServiceInsertOptions;
    updates?: Array<TextTemplate> | ((storageData: Array<TextTemplate>) => (Array<TextTemplate> | TextTemplate | undefined)) | TextTemplate;
    deletes?: Array<TextTemplate> | ((storageData: Array<TextTemplate>) => (Array<TextTemplate> | TextTemplate | undefined)) | TextTemplate;
    deleteOptions?: DataServiceDeleteOptions
  }): Promise<Array<TextTemplate>> {
    throw new Error('Modification is not supported for TextTemplateDataService since it is readonly.');
  }
  async insertOrUpdate(valueOrArray: Array<TextTemplate> | TextTemplate): Promise<Array<TextTemplate>> {
    throw new Error('Modification is not supported for TextTemplateDataService since it is readonly.');
  }
  async insert(valueArrayOrFunction: Array<TextTemplate> | ((storageData: Array<TextTemplate>) => (Array<TextTemplate> | TextTemplate | undefined)) | TextTemplate,
               options?: DataServiceInsertOptions): Promise<Array<TextTemplate>> {
    throw new Error('Modification is not supported for TextTemplateDataService since it is readonly.');
  }
  async update(valueArrayOrFunction: Array<TextTemplate> | ((storageData: Array<TextTemplate>) => (Array<TextTemplate> | TextTemplate | undefined)) | TextTemplate): Promise<Array<TextTemplate>> {
    throw new Error('Modification is not supported for TextTemplateDataService since it is readonly.');
  }
  async delete(
    valueArrayOrFunction: Array<TextTemplate> | ((storageData: Array<TextTemplate>) => (Array<TextTemplate> | TextTemplate | undefined)) | TextTemplate,
    options?: DataServiceDeleteOptions
  ): Promise<void> {
    throw new Error('Modification is not supported for TextTemplateDataService since it is readonly.');
  }
}
