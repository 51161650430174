<div class="protocol-entry-card background-var" [id]="'entry-' + protocolEntry?.id"
  [class.has-company]="!isProtocolLayoutShort && (protocolEntry?.companyId || protocolEntry?.allCompanies)"
  [class.show-subentries]="showSubEntries"
  [class.active-item]="(shouldMarkActive$ | async) && this.showActiveMarker && this.selectedProtocolEntry?.id === protocolEntry?.id"
  [class.selected-item]="!ignoreSelectMode && (protocolEntrySelectionService?.isSelectMode$ | async) && (protocolEntrySelectionService?.isSelected$(protocolEntry) | async)">
  <div (click)="isNavigable && protocolClicked()" class="protocol-entry-selection"
    [class.cursor-pointer]="isNavigable"
    [class.active-protocol-entry-selection]="this.showActiveMarker && this.selectedProtocolEntry?.id === protocolEntry?.id"
    >
    <ion-grid class="ion-no-padding">
      <ion-row>
        <ion-col size="auto" class="d-inline-flex ion-align-items-center">
          <app-protocol-entry-short-id
            [active]="this.showActiveMarker && this.selectedProtocolEntry?.id === protocolEntry?.id"
            [hideBorderLeft]="true"
            [isProtocolLayoutShort]="isProtocolLayoutShort"
            [protocolEntry]="protocolEntry"
            [acrossProjects]="acrossProjects"
            [isTask]="isTask"
          ></app-protocol-entry-short-id>
         <ion-checkbox *ngIf="!ignoreSelectMode && protocolEntrySelectionService?.isSelectMode$ | async" class="margin-left-5" [ngModel]="protocolEntrySelectionService?.isSelected$(protocolEntry) | async"></ion-checkbox>
        </ion-col>
        <ion-col class="tagline">
          <div class="avatars">
            <app-avatar *ngIf="(companyInitials$ | async) as companyInitials" [initials]="companyInitials" color="neutral-dimmed" [dark]="false"></app-avatar>
            <app-avatar *ngIf="(assignedInitials$ | async) as assignedInitials" [initials]="assignedInitials" color="primary" [overlap]="!!(companyInitials$ | async)" [dark]="false"></app-avatar>
          </div>
          <span *ngIf="(protocolEntryDateObservable | async) as entryDate" class="text-primary size-sm ion-text-end tagline__date">
            {{ 'reports.calendarWeekPrefix' | translate }}{{ entryDate.dateWeek }} / {{ $any(('days.' + entryDate.dateDay) | translate | slice:0:2) | uppercase }} / {{ entryDate.date | date:TAGLINE_DATE_FORMAT }}
          </span>
          <fa-icon [icon]="['bau','flag']" class="icon-padding-start protocol-entry-priority-flag size-m"
                   [ngClass]="'priority-' + protocolEntry?.priority"></fa-icon>
        </ion-col>
      </ion-row>
    </ion-grid>

    <div class="details">
      <div class="title">
        <ion-text color="heading-primary" class="text-wrap-two-lines heading-2 size-m protocol-entry-title">
          {{protocolEntry?.title}}
        </ion-text>
      </div>

      <div class="company" *ngIf="!isProtocolLayoutShort && (protocolEntry?.companyId || protocolEntry?.allCompanies)">
        <ion-note class="text-wrap-one-line omg-t-medium-body protocol-entry-company-id">
          <span *ngIf="!protocolEntry?.allCompanies">{{companiesById ? (companiesById[protocolEntry?.companyId] | companyNameString: (projectCompaniesByCompanyId ? !projectCompaniesByCompanyId[protocolEntry?.companyId] : false)) : ''}}</span>
          <span *ngIf="protocolEntry?.allCompanies">{{ 'project_team' | translate}}</span>
        </ion-note>
      </div>

      <div class="item the-footer"
           [class.parent-entry]="showSubEntries && !protocolEntry?.parentId"
           [class.footer-buttons-with-sub-entries]="protocolEntry?.parentId"
           [class.footer-buttons]="!showSubEntries && !protocolEntry?.parentId"
      >
        <ion-row>
          <ion-col size="8" class="ion-no-padding ion-text-start d-flex ion-align-items-end">
            <div class="protocol-entry-footer-buttons size-sm" *ngIf="showFooterButtons">
              <ion-button (click)="toggleSubEntries($event)"
                          fill="clear"
                          *ngIf="showSubEntriesCount && !isProtocolLayoutShort"
                          [class]="{
                            'entries-counter': true,
                            'subentries-open': subEntriesStatusOpen && protocolSubEntries?.length > 0,
                            'subentries-done': subEntriesStatusDone && protocolSubEntries?.length > 0
                          }">
                {{ protocolSubEntries?.length }}
              </ion-button>
              <fa-icon [icon]="['bau','chat']" class="protocol-entry-chat {{(chatObservable|async)?.length > 0 ? 'primary': 'inactive'}}"></fa-icon>
              <fa-icon [icon]="['bau','paper-clip']" class="protocol-entry-attachments {{(attachments|async)?.length > 0 ? 'primary': 'inactive'}}"></fa-icon>
              <fa-icon [icon]="['fal','map-marker-alt']" [ngClass]="(hasPdfPlanMarkingOrMarkerObservable|async) ? 'primary': 'inactive'" class="protocol-entry-pdf-plan-marker"></fa-icon>
              <span *ngIf="protocolEntry?.typeId" class="primary text-wrap-one-line protocol-entry-type">
                {{protocolEntryTypeMap ? protocolEntryTypeMap[protocolEntry.typeId]?.name : ''}}
              </span>
            </div>
          </ion-col>
          <ion-col size="4" class="ion-no-padding d-flex ion-align-items-center ion-justify-content-end">
            <ng-container *ngIf="protocolEntry?.createdInProtocolId && protocolEntry.createdInProtocolId !== protocolId && (isBeforeCreatedInProtocol$ | async)">
              <div [id]="'protocol-entry-added-later-warning-sign-' + instanceId">
                <fa-layers class="created-in-another-protocol-icon">
                  <fa-icon [fixedWidth]="true" [icon]="['fas', 'triangle']" class="tri-bg"></fa-icon>
                  <fa-icon [fixedWidth]="true" [icon]="['fal', 'exclamation-triangle']"></fa-icon>
                </fa-layers>
              </div>
              <app-tooltip [target]="'protocol-entry-added-later-warning-sign-' + instanceId" placement="bottom">
                {{ 'protocolEntry.createdInAnotherProtocolTooltip' | translate:{shortName: (createdInProtocolShortName$ | async)} }}
              </app-tooltip>
            </ng-container>
            <app-protocol-entry-status
                [isProtocolLayoutShort]="isProtocolLayoutShort"
                [isProtocolLayoutContinuous]="isProtocolLayoutContinuous"
                [isTaskProtocol]="isTaskProtocol$ | async"
                [protocolEntry]="protocolEntry"
                [canEdit]="canEditProtocolEntryStatus"
                (statusChange)="onChangeProtocolEntryStatus($event)"
                (isContinuousInfoChange)="onIsContinuousInfoChange($event)"
                [acrossProjects]="acrossProjects"></app-protocol-entry-status>
          </ion-col>
        </ion-row>
      </div>
    </div>
  </div>


  <div *ngIf="showSubEntries" [id]="protocolEntry?.id" class="subentries" [class.has-subentries]="protocolSubEntries?.length > 0 && hasSubEntriesVisible">
    <ng-container *ngFor="let item of protocolSubEntries">
      <app-protocol-entry *ngIf="subEntriesSubset ? subEntriesSubset.includes(item.id) : true"
                          [protocolId]="protocolId"
                          [protocolEntryId]="item.id"
                          [showActiveMarker]="showActiveMarker"
                          [showSubEntriesCount]="false"
                          [acrossProjects]="acrossProjects"
          ></app-protocol-entry>
    </ng-container>
  </div>
</div>
