import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {Client, IdType, Project, Protocol, ProtocolEntry, ProtocolLayout, ProtocolOpenEntry, ProtocolSortEntriesByEnum, ProtocolType, TASK_PROTOCOL_NAME} from 'submodules/baumaster-v2-common';
import {combineLatestAsync, observableToPromise, switchMapOrDefault} from '../../utils/async-utils';
import {PROTOCOL_LAYOUT_NAME_CONTINUOUS, PROTOCOL_LAYOUT_NAME_SHORT, PROTOCOL_LAYOUT_NAME_STANDARD} from '../../shared/constants';
import {ProtocolTypeDataService} from '../data/protocol-type-data.service';
import {ProtocolDataService} from '../data/protocol-data.service';
import _ from 'lodash';
import {ProtocolEntryDataService} from '../data/protocol-entry-data.service';
import {ProtocolLayoutDataService} from '../data/protocol-layout-data.service';
import {combineLatest, Observable, of} from 'rxjs';
import {distinctUntilKeyChanged, map, switchMap, take} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {ProjectDataService} from '../data/project-data.service';
import {ProtocolEntryOrOpen, ProtocolWithTypeAndLayout} from '../../model/protocol';
import {SystemEventService} from '../event/system-event.service';
import {ProjectService} from '../project/project.service';
import {ClientService} from '../client/client.service';
import {isTaskProtocol} from '../../utils/protocol-utils';
import {v4 as uuidv4} from 'uuid';
import {NetworkStatusService} from '../common/network-status.service';
import {Nullish} from 'src/app/model/nullish';
import {protocolShortIdWithDeps} from 'src/app/utils/protocol-entry-utils';
import ProjectForDisplay from '../../model/ProjectForDisplay';

const LOG_SOURCE = 'ProtocolService';

@Injectable({
  providedIn: 'root'
})
export class ProtocolService {

  public protocolsWithTypeAndLayout$: Observable<Array<ProtocolWithTypeAndLayout>> =
    combineLatestAsync([this.protocolDataService.dataWithoutHidden$, this.protocolTypeDataService.dataWithoutHidden$, this.protocolLayoutDataService.data])
    .pipe(map(([protocols, protocolTypes, protocolLayouts]) => {
      return protocols.map((protocol) => {
        const protocolType = protocolTypes.find((value) => value.id === protocol.typeId);
        if (!protocolType) {
          throw new Error(`ProtocolType with id ${protocol.typeId} cannot be found for Protocol with id ${protocol.id}.`);
        }
        const protocolLayout = protocolLayouts.find((value) => value.id === protocolType?.layoutId);
        if (!protocolLayout) {
          throw new Error(`ProtocolLayout with id ${protocolType.layoutId} cannot be found for Protocol with id ${protocol.id}.`);
        }
        return {
          ...protocol,
          displayName: this.protocolWithTypeAndLayoutToDisplayName(protocol, protocolType, protocolLayout),
          protocolType,
          protocolLayout
        } as ProtocolWithTypeAndLayout;
      });
    }));
  public openProtocolsWithTypeAndLayout$ = this.protocolsWithTypeAndLayout$.pipe(
    map((protocols) => protocols.filter((protocol) => _.isEmpty(protocol.closedAt))));

  constructor(private protocolTypeDataService: ProtocolTypeDataService,
              private protocolDataService: ProtocolDataService,
              private protocolEntryDataService: ProtocolEntryDataService,
              private protocolLayoutDataService: ProtocolLayoutDataService,
              private translateService: TranslateService,
              private projectDAtaService: ProjectDataService,
              private projectService: ProjectService,
              private clientService: ClientService,
              private systemEventService: SystemEventService,
              private networkStatusService: NetworkStatusService,
              @Inject(LOCALE_ID) private locale: string) {
  }

  public async hasCarriedOverEntries(protocol: Protocol): Promise<boolean> {
    return observableToPromise(this.protocolEntryDataService.getProtocolEntryOrOpenByProtocolId(protocol.id).pipe(
      map((entries) => this.containsCarriedOverEntries(protocol.id, entries)),
      take(1)
    ));
  }

  public containsCarriedOverEntries(protocolId: IdType, entries: ProtocolEntryOrOpen[]): boolean {
    return entries.some((entry) => entry.originalProtocolId && entry.originalProtocolId !== protocolId);
  }

  public async isContinuousProtocol(protocol: Protocol): Promise<boolean> {
    return this.isContinuousProtocolType(protocol.typeId);
  }

  public async isStandardProtocol(protocol: Protocol): Promise<boolean> {
    return this.isStandardProtocolType(protocol.typeId);
  }

  private getProtocolLayoutForProtocolType(protocolTypeId: IdType): Observable<ProtocolLayout|undefined> {
    return this.protocolTypeDataService.getById(protocolTypeId)
      .pipe(switchMap((protocolType) => protocolType?.layoutId ? this.protocolLayoutDataService.getById(protocolType.layoutId) : of(undefined)));
  }

  public async isContinuousProtocolType(typeId: IdType): Promise<boolean> {
    const protocolLayout = await observableToPromise(this.getProtocolLayoutForProtocolType(typeId));
    return protocolLayout?.name === PROTOCOL_LAYOUT_NAME_CONTINUOUS;
  }

  public async isStandardProtocolType(typeId: IdType): Promise<boolean> {
    const protocolLayout = await observableToPromise(this.getProtocolLayoutForProtocolType(typeId));
    return protocolLayout?.name === PROTOCOL_LAYOUT_NAME_STANDARD;
  }

  public async isFirstOfTypeCreation(typeId: IdType) {
    const protocolsOfType = await observableToPromise(this.protocolDataService.findProtocolsOfType(typeId));
    return protocolsOfType.length === 0;
  }

  public async isFirstOfTypeEdit(typeId: IdType) {
    const protocolsOfType = await observableToPromise(this.protocolDataService.findProtocolsOfType(typeId));
    return protocolsOfType.length === 1;
  }

  public async getPreviousContinuousProtocol(protocol: Protocol): Promise<Protocol | null> {
    const otherProtocolsOfSameType = (await observableToPromise(this.protocolDataService.findProtocolsOfType(protocol.typeId))).filter((o) => o.id !== protocol.id);
    const otherProtocolsOfSameTypeSorted = _.sortBy(otherProtocolsOfSameType, 'number');
    const lastProtocol: Protocol = _.last(otherProtocolsOfSameTypeSorted);
    return lastProtocol;
  }

  public isBeforeCreatedInProtocol$(createdInProtocolId: Nullish<IdType>, currentProtocol$: Observable<Protocol>, acrossProjects = false): Observable<boolean> {
    if (!createdInProtocolId) {
      return of(false);
    }

    return combineLatestAsync([
      (acrossProjects ? this.protocolDataService.getByIdAcrossProjects(createdInProtocolId) : this.protocolDataService.getById(createdInProtocolId)).pipe(
        distinctUntilKeyChanged('number')
      ),
      currentProtocol$.pipe(
        distinctUntilKeyChanged('number')
      )
    ]).pipe(
      map(([{number: createdInProtocolNumber}, {number: protocolNumber}]) => createdInProtocolNumber > protocolNumber)
    );
  }

  public async getPreviousContinuousProtocolsBetween(fromProtocolId: IdType, toProtocolId: IdType, options?: {includeFrom?: boolean; includeTo?: boolean}): Promise<Protocol[]>;
  public async getPreviousContinuousProtocolsBetween(fromProtocol: Protocol, toProtocol: Protocol): Promise<Protocol[]>;
  public async getPreviousContinuousProtocolsBetween(
    fromProtocolOrId: IdType|Protocol,
    toProtocolOrId: IdType|Protocol,
    {
      includeFrom = true,
      includeTo = false
    }: {includeFrom?: boolean; includeTo?: boolean} = {}
  ) {
    let fromProtocol: Protocol;
    let toProtocol: Protocol;
    if (typeof fromProtocolOrId === 'string') {
      fromProtocol = await observableToPromise(this.protocolDataService.getById(fromProtocolOrId));
    } else {
      fromProtocol = fromProtocolOrId;
    }
    if (typeof toProtocolOrId === 'string') {
      toProtocol = await observableToPromise(this.protocolDataService.getById(toProtocolOrId));
    } else {
      toProtocol = toProtocolOrId;
    }

    if (!fromProtocol) {
      throw new Error('From protocol not found');
    }

    if (!toProtocol) {
      throw new Error('To protocol not found');
    }

    if (fromProtocol.typeId !== toProtocol.typeId) {
      throw new Error('From protocol type is different from to protocol type');
    }

    const compareStart: (protocol: Protocol) => boolean = includeFrom ? (protocol) => fromProtocol.number <= protocol.number : (protocol) => fromProtocol.number < protocol.number;
    const compareEnd: (protocol: Protocol) => boolean = includeTo ? (protocol) => toProtocol.number >= protocol.number : (protocol) => toProtocol.number > protocol.number;

    const protocolsBetweenFromTo = _.sortBy(await observableToPromise(this.protocolDataService.findProtocolsOfType(fromProtocol.typeId)), 'number')
      .filter((protocol) => compareStart(protocol) && compareEnd(protocol));

    return protocolsBetweenFromTo;
  }

  public async createOpenEntriesForContinuousProtocolsRange(protocols: Protocol[], protocolEntry: ProtocolEntry): Promise<Array<ProtocolOpenEntry> | null> {
    const protocolIds = protocols.map(({id}) => id);
    this.systemEventService.logEvent(LOG_SOURCE + '.copyOpenEntriesForContinuousProtocolsRange', `copyOpenEntriesForContinuousProtocolsRange called for protocols ${protocolIds}`);
    if (!protocolIds.length) {
      this.systemEventService.logEvent(LOG_SOURCE + '.copyOpenEntriesForContinuousProtocolsRange', `protocols ${protocolIds} is empty. Returning.`);
      return null;
    }
    const protocolTypeIds = new Set(protocols.map(({typeId}) => typeId));
    if (protocolTypeIds.size !== 1) {
      const error = new Error(`Protocols ${protocolIds} don't have the same type id (${Array.from(protocolTypeIds.values())})!`);
      this.systemEventService.logErrorEvent(
        LOG_SOURCE + '.copyOpenEntriesForContinuousProtocolsRange', error
      );
      throw error;
    }
    if (!(await this.isContinuousProtocol(protocols[0]))) {
      const error = new Error(`Protocols ${protocolIds} are not a continuous protocols!`);
      this.systemEventService.logErrorEvent(LOG_SOURCE + '.copyOpenEntriesForContinuousProtocolsRange', error);
      throw error;
    }

    const protocolOpenEntries: ProtocolOpenEntry[] = [];
    for (const protocol of protocols) {
      const protocolOpenEntry: ProtocolOpenEntry = {
        id: protocol.id + protocolEntry.id,
        protocolId: protocol.id,
        protocolEntryId: protocolEntry.id,
        changedAt: new Date().toISOString()
      };
      protocolOpenEntries.push(protocolOpenEntry);
    }
    this.systemEventService.logEvent(LOG_SOURCE + '.copyOpenEntriesForContinuousProtocolsRange', `finished (${protocolIds}).`);
    return protocolOpenEntries;
  }

  public async getPreviousProtocolIfContinuous(protocol: Protocol): Promise<Protocol | null> {
    if (!protocol) {
      return null;
    }
    if (await this.isContinuousProtocol(protocol)) {
      return this.getPreviousContinuousProtocol(protocol);
    }
    return null;
  }

  public async copyOpenEntriesForContinuousProtocol(protocol: Protocol): Promise<Array<ProtocolOpenEntry> | null> {
    this.systemEventService.logEvent(LOG_SOURCE + '.copyOpenEntriesForContinuousProtocol', `copyOpenEntriesForContinuousProtocol called for protocol ${protocol?.id}`);
    if (!(await this.isContinuousProtocol(protocol))) {
      this.systemEventService.logEvent(LOG_SOURCE + '.copyOpenEntriesForContinuousProtocol', `protocol ${protocol?.id} is not a continuous protocol. Returning.`);
      return null;
    }
    this.systemEventService.logEvent(LOG_SOURCE + '.copyOpenEntriesForContinuousProtocol', `before getPreviousContinuousProtocol(${protocol?.id})`);
    const lastProtocol = await this.getPreviousContinuousProtocol(protocol);
    this.systemEventService.logEvent(LOG_SOURCE + '.copyOpenEntriesForContinuousProtocol', `after getPreviousContinuousProtocol(${protocol?.id}). lastProtocol is ${lastProtocol?.id}`);
    if (!lastProtocol) {
      return [];
    }
    this.systemEventService.logEvent(LOG_SOURCE + '.copyOpenEntriesForContinuousProtocol', `before protocolEntryDataService.getUnfinishedEntriesOrOpenAndTheirParentsByProtocolId(${protocol?.id}).`);
    const openProtocolEntries = await observableToPromise(this.protocolEntryDataService.getUnfinishedEntriesOrOpenAndTheirParentsByProtocolId(
      lastProtocol.id,
      this.getIsProtocolLayoutShort$(protocol.id)
    ));
    this.systemEventService.logEvent(LOG_SOURCE + '.copyOpenEntriesForContinuousProtocol',
      `after protocolEntryDataService.getUnfinishedEntriesOrOpenAndTheirParentsByProtocolId(${protocol?.id}). openProtocolEntries.length=${openProtocolEntries.length}`);
    if (openProtocolEntries.length === 0) {
      return [];
    }
    const protocolOpenEntries = new Array<ProtocolOpenEntry>();
    for (const openProtocolEntry of openProtocolEntries) {
      const protocolOpenEntry: ProtocolOpenEntry = {
        id: protocol.id + openProtocolEntry.id,
        protocolId: protocol.id,
        protocolEntryId: openProtocolEntry.id,
        changedAt: new Date().toISOString()
      };
      protocolOpenEntries.push(protocolOpenEntry);
    }
    this.systemEventService.logEvent(LOG_SOURCE + '.copyOpenEntriesForContinuousProtocol', `finished protocolEntryDataService.getUnfinishedEntriesOrOpenAndTheirParentsByProtocolId(${protocol?.id}).`);
    return protocolOpenEntries;
  }

  async getByProtocolTypeByProtocolId(protocolId: IdType): Promise<ProtocolType> {
    const protocol = await this.getProtocolById(protocolId);
    return this.getProtocolTypeById(protocol?.typeId);
  }

  async getByProtocolTypeByProtocolIdAcrossProjects(protocolId: IdType): Promise<{protocol: Protocol|undefined, protocolType: ProtocolType|undefined}> {
    const protocol = await observableToPromise(this.protocolDataService.getByIdAcrossProjects(protocolId));
    const protocolType = await observableToPromise(this.protocolTypeDataService.getByIdAcrossClients(protocol?.typeId));
    return {protocol, protocolType};
  }

  getByProtocolTypeByProtocolIdAcrossProjects$(protocolId: IdType): Observable<{protocol: Protocol|undefined, protocolType: ProtocolType|undefined}> {
    return this.protocolDataService.getByIdAcrossProjects(protocolId).pipe(
      switchMap((protocol) => this.protocolTypeDataService.getByIdAcrossClients(protocol?.typeId).pipe(
        map((protocolType) => ({ protocol, protocolType }))
      ))
    );
  }

  async getProtocolById(protocolId: IdType): Promise<Protocol> {
    return observableToPromise(this.protocolDataService.getByIdAcrossProjects(protocolId));
  }

  getProtocolById$(protocolId: IdType): Observable<Protocol|undefined> {
    return this.protocolDataService.getById(protocolId);
  }

  async getProtocolTypeById(protocolTypeId: IdType): Promise<ProtocolType> {
    return observableToPromise(this.protocolTypeDataService.getByIdAcrossClients(protocolTypeId));
  }

  async getProtocolShortName(protocol: Protocol): Promise<string> {
    const protocolType = await this.getProtocolTypeById(protocol.typeId);
    return protocolType.code + '-' + _.padStart('' + (protocol?.number ?? ''), 2, '0');
  }

  getProtocolShortName$(protocol: Nullish<Protocol>, acrossClients = false): Observable<string> {
    if (!protocol) {
      return of(undefined);
    }
    return (acrossClients ? this.protocolTypeDataService.getByIdAcrossClients(protocol.typeId) : this.protocolTypeDataService.getById(protocol.typeId)).pipe(
      map((type) => protocolShortIdWithDeps(this.locale, type, protocol))
    );
  }

  getProtocolLayoutByProtocolId(protocolId: IdType, acrossProject: boolean = false): Observable<ProtocolLayout | undefined> {
    if (acrossProject) {
      return combineLatestAsync([this.protocolDataService.dataByProjectId$, this.protocolTypeDataService.dataAcrossClients$,
        this.protocolLayoutDataService.dataAcrossClients$]).pipe(
        map(([protocols, protocolTypes, protocolLayouts]) => {
          const protocol = _.flatten(Array.from(protocols.values())).find(p => p.id === protocolId);
          if (protocol === undefined) {
            return undefined;
          }
          const currentProtocolType = protocolTypes.find((protocolType) => protocolType.id === protocol.typeId);
          return protocolLayouts.find(layout => layout.id === currentProtocolType?.layoutId);
        })
      );
    }
    return combineLatestAsync([this.protocolDataService.getById(protocolId), this.protocolTypeDataService.data, this.protocolLayoutDataService.data]).pipe(
      map(([protocol, protocolTypes, protocolLayouts]) => {
        if (protocol === undefined) {
          return undefined;
        }
        const currentProtocolType = protocolTypes.find((protocolType) => protocolType.id === protocol.typeId);
        return protocolLayouts.find(layout => layout.id === currentProtocolType?.layoutId);
      })
    );
  }

  getProtocolLayoutByTypeId(protocolTypeId: IdType, acrossProject: boolean = false): Observable<ProtocolLayout | undefined> {
    if (acrossProject) {
      return combineLatestAsync([this.protocolTypeDataService.getByIdAcrossClients(protocolTypeId), this.protocolLayoutDataService.dataAcrossClients$]).pipe(
        map(([protocolType, protocolLayouts]) => {
          return protocolLayouts.find(layout => layout.id === protocolType?.layoutId);
        })
      );
    }
    return combineLatestAsync([this.protocolTypeDataService.getById(protocolTypeId), this.protocolLayoutDataService.data]).pipe(
      map(([protocolType, protocolLayouts]) => {
        return protocolLayouts.find(layout => layout.id === protocolType?.layoutId);
      })
    );
  }

  public isValidForSendPdfProtocol(protocolId: IdType, filteredProtocolEntries?: ProtocolEntry[]): Observable<{ valid: boolean, invalidProtocolEntries?: Array<ProtocolEntry>, message?: string }> {
    return combineLatest([this.protocolDataService.getById(protocolId),
      this.getProtocolLayoutByProtocolId(protocolId),
      this.protocolEntryDataService.getProtocolEntryOrOpenByProtocolId(protocolId),
      this.projectDAtaService.currentProjectObservable,
      this.projectService.projectsForDisplay$,
      this.clientService.clients$,
      this.networkStatusService.onlineOrUnknown$]).pipe(
      switchMap(async ([protocol, protocolLayout, protocolEntries,
                         project, projectsWithOffline, clients, networkConnected]: [Protocol, ProtocolLayout, ProtocolEntryOrOpen[], Project, ProjectForDisplay[], Client[], boolean]) => {
        if (!protocol) {
          return {valid: false};
        }
        const protocolEntryList = filteredProtocolEntries !== undefined && filteredProtocolEntries?.length > 0 ? filteredProtocolEntries : protocolEntries;

        const client = clients.find(c => c.id === project.clientId);
        if (protocolEntryList.length === 0) {
          return {
            valid: false,
            message: this.translateService.instant('pdfProtocol.noProtocolEntry')
          };
        }

        const isLayoutShort = protocolLayout.name === PROTOCOL_LAYOUT_NAME_SHORT;
        if (isLayoutShort) {
          return {valid: true};
        }

        const protocolEntriesWithMissingInfo = this.getProtocolEntriesWithMissingInfo(protocolEntryList, client);
        if (protocolEntriesWithMissingInfo.length > 0 && _.isEmpty(protocol.closedAt)) {
          return {
            valid: false,
            invalidProtocolEntries: protocolEntriesWithMissingInfo
          };
        }

        return {
          valid: true
        };
      })
    );
  }

  getProtocolEntriesWithMissingInfo(protocolEntries: ProtocolEntry [], client: Client): ProtocolEntry[] {
    return protocolEntries.filter((protocolEntry) => {
      if (client.companyFieldRequired) {
        return _.isEmpty(protocolEntry.title) || (_.isEmpty(protocolEntry.companyId) && !protocolEntry.allCompanies);
      }
      return _.isEmpty(protocolEntry.title);
    });
  }

  async getProtocolNextNumberByProject(protocolType: IdType, projectId: IdType) {
    const protocols = await observableToPromise(this.protocolDataService.dataWithoutHiddenByProjectId$
      .pipe(map((dataByProjectId) => dataByProjectId.get(projectId))));
    return this.getProtocolNextNumber(protocolType, protocols);
  }

  getProtocolNextNumber(protocolType: IdType, protocols: Array<Protocol> | undefined) {
    if (!protocols?.length) {
      return 1;
    }
    const filteredProtocol = _.maxBy(protocols.filter((protocol) => protocol.typeId === protocolType), 'number');
    return _.get(filteredProtocol, 'number', 0) + 1;
  }

  public hasProjectProtocolOfType(protocolTypeId: IdType, projectId: IdType): Observable<boolean> {
    return this.protocolDataService.dataWithoutHiddenByProjectId$.pipe(map((dataByProjectId) => {
      const protocols = dataByProjectId.get(projectId);
      if (!protocols?.length) {
        return false;
      }
      return protocols.some((protocol) => protocol.typeId === protocolTypeId);
    }));
  }

  async isOwnClientProtocol(protocolOrId: Protocol | IdType): Promise<boolean> {
    const protocol: Protocol = typeof protocolOrId === 'string' ? await observableToPromise(this.protocolDataService.getByIdAcrossProjects(protocolOrId)) : protocolOrId;

    return observableToPromise(this.clientService.ownClient$.pipe(map((client) => client?.id === protocol.ownerClientId)));
  }

  public getSortEntriesByValues(client?: Client): Array<{uniqueId: string; label: string; }> {
    const values = [
      { uniqueId: ProtocolSortEntriesByEnum.CREATED_AT, label: this.translateService.instant('protocolGroupBy.created_at')},
      { uniqueId: ProtocolSortEntriesByEnum.CREATED_AT_DATE, label: this.translateService.instant('protocolGroupBy.created_at_date')},
      { uniqueId: ProtocolSortEntriesByEnum.COMPANY, label: this.translateService.instant('protocolGroupBy.company')},
      { uniqueId: ProtocolSortEntriesByEnum.CRAFT, label: this.translateService.instant('protocolGroupBy.category')}
    ];
    if (client?.nameableDropdownName) {
      values.push({ uniqueId: ProtocolSortEntriesByEnum.NAMEABLE_DROPDOWN, label: client.nameableDropdownName});
    }
    return values;
  }

  public getSortEntriesByValuesForLayoutShort(): Array<{uniqueId: string; label: string; }> {
    const values = [
      { uniqueId: ProtocolSortEntriesByEnum.CREATED_AT, label: this.translateService.instant('protocolGroupBy.created_at')},
      { uniqueId: ProtocolSortEntriesByEnum.CREATED_AT_DATE, label: this.translateService.instant('protocolGroupBy.created_at_date')}
    ];
    return values;
  }

  public protocolsWithTypeAndLayoutById$(protocolId: IdType): Observable<ProtocolWithTypeAndLayout|undefined> {
    return this.protocolsWithTypeAndLayout$.pipe(map((protocols) => protocols.find((protocol) => protocol.id === protocolId)));
  }

  private protocolWithTypeAndLayoutToDisplayName(protocol: Protocol, protocolType: ProtocolType, protocolLayout: ProtocolLayout): string {
    const protocolLayoutName = this.translateService.instant(`protocolLayoutShort.${protocolLayout.name}`);
    return `${protocolType.code}-${protocol.number.toString().padStart(2, '0')}  ${protocol.name} (${protocolLayoutName})`;
  }

  isTaskProtocolAcrossProjects(protocolId: IdType): Observable<boolean> {
    return this.protocolDataService.getByIdAcrossProjects(protocolId).pipe(
      map((protocol) => protocol ? isTaskProtocol(protocol) : false)
    );
  }

  getProjectByProtocolId(protocolId: IdType): Observable<Project|undefined> {
    return this.protocolDataService.getByIdAcrossProjects(protocolId).pipe(
      switchMapOrDefault((protocol) => this.projectDAtaService.getByIdAcrossClients(protocol.projectId))
    );
  }

  getProjectByEntryId(entryId: IdType): Observable<Project|undefined> {
    return this.protocolEntryDataService.getByIdAcrossProjects(entryId).pipe(
      switchMapOrDefault((entry) => this.getProjectByProtocolId(entry.protocolId))
    );
  }

  canOpenProtocol$(protocolId: IdType): Observable<boolean|undefined> {
    return combineLatestAsync([this.protocolDataService.data, this.protocolTypeDataService.data, this.protocolLayoutDataService.data])
      .pipe(map(([protocols, protocolTypes, protocolLayouts]) => {
        const protocol = protocols.find((v) => v.id === protocolId);
        if (!protocol) {
          return undefined;
        }
        if (!protocol.closedAt) {
          return false;
        }
        const protocolType = protocolTypes.find((v) => v.id === protocol.typeId);
        const protocolLayout = protocolLayouts.find((v) => v.id === protocolType?.layoutId);
        if (!protocolType || !protocolLayout) {
          return undefined;
        }
        const isContinuousProtocol = protocolLayout.name === PROTOCOL_LAYOUT_NAME_CONTINUOUS;
        if (!isContinuousProtocol) {
          return true;
        }
        const otherProtocolsOfSameType = protocols.filter((v) => v.typeId === protocol.typeId && v.id !== protocol.id);
        if (!otherProtocolsOfSameType.length) {
          return true;
        }
        const otherProtocolsOfSameTypeOpen = otherProtocolsOfSameType.some((v) => !v.closedAt);
        if (otherProtocolsOfSameTypeOpen) {
          return false;
        }
        const isLastProtocolOfSameType = !otherProtocolsOfSameType.some((v) => v.number > protocol.number);
        return isLastProtocolOfSameType;
      }));
  }

  public getIsProtocolLayoutShort$(protocolId: IdType, acrossProject: boolean = false): Observable<boolean|undefined> {
    return this.getProtocolLayoutByProtocolId(protocolId, acrossProject)
      .pipe(map((protocolLayout) => protocolLayout ? protocolLayout.name === PROTOCOL_LAYOUT_NAME_SHORT : undefined));
  }

  public getIsProtocolLayoutStandard$(protocolId: IdType, acrossProject: boolean = false): Observable<boolean|undefined> {
    return this.getProtocolLayoutByProtocolId(protocolId, acrossProject)
      .pipe(map((protocolLayout) => protocolLayout ? protocolLayout.name === PROTOCOL_LAYOUT_NAME_STANDARD : undefined));
  }

  public protocolOpenByProtocolEntryId$(): Observable<Map<IdType, boolean>> {
    return combineLatestAsync([this.protocolDataService.dataWithoutHiddenGroupedById$, this.protocolEntryDataService.data])
      .pipe(map(([protocolsById, protocolEntries]) => {
        const protocolOpenByProtocolEntryId = new Map<IdType, boolean>();
        for (const protocolEntry of protocolEntries) {
          const protocol = protocolsById[protocolEntry.protocolId];
          if (!protocol) {
            continue;
          }
          const protocolOpen = !protocol.closedAt;
          protocolOpenByProtocolEntryId.set(protocolEntry.id, protocolOpen);
        }
        return protocolOpenByProtocolEntryId;
    }));
  }

  public getTaskProtocolForProject$(projectId: IdType): Observable<Protocol|undefined> {
    return this.protocolDataService.getDataForProject$(projectId)
      .pipe(map((protocols) => protocols.find((protocol) => isTaskProtocol(protocol))));
  }

  public async createTaskProtocol(projectId: IdType, clientId: IdType): Promise<Protocol> {
    const existingTaskProtocolOfNewProject = await observableToPromise(this.getTaskProtocolForProject$(projectId));
    if (existingTaskProtocolOfNewProject) {
      throw new Error(`createTaskProtocol for project ${projectId} failed because as task Protocol (${existingTaskProtocolOfNewProject.id}) already exists.`);
    }
    const protocolType = await observableToPromise(this.protocolTypeDataService.taskProtocolType$);
    if (!protocolType) {
      throw new Error(`createTaskProtocol for project ${projectId} failed because there is no taskProtocolType for that client.`);
    }
    const protocol: Protocol = {
      id: uuidv4(),
      number: 0,
      name: TASK_PROTOCOL_NAME,
      projectId,
      typeId: protocolType.id,
      closedAt: null,
      ownerClientId: clientId,
      changedAt: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      includesVat: false,
    };
    await this.protocolDataService.insert(protocol, projectId);
    return protocol;
  }
}
