import { Injectable } from '@angular/core';
import { FirebaseAnalytics } from '@awesome-cordova-plugins/firebase-analytics/ngx';
import { Platform } from '@ionic/angular';
import * as WebFirebaseApp from 'firebase/app';
import * as WebFirebaseAnalytics from 'firebase/analytics';
import { hasWebAnalyticsConfig, WebAnalyticsConfig } from '../../../configs/web-analytics-config';

// eslint-disable-next-line  @typescript-eslint/no-empty-interface
interface IFirebaseAnalytics extends FirebaseAnalytics {}

class WebFirebaseAnalyticsAdapter implements IFirebaseAnalytics {
  constructor(private analyticsInstance: WebFirebaseAnalytics.Analytics) {}
  async logEvent(name: string, params: any): Promise<any> {
    return WebFirebaseAnalytics.logEvent(this.analyticsInstance, name, params);
  }
  async setUserId(id: string): Promise<any> {
    return WebFirebaseAnalytics.setUserId(this.analyticsInstance, id);
  }
  async setUserProperty(name: string, value: string): Promise<any> {
    return WebFirebaseAnalytics.setUserProperties(this.analyticsInstance, {
      [name]: value,
    });
  }
  async setEnabled(enabled: boolean): Promise<any> {
    return WebFirebaseAnalytics.setAnalyticsCollectionEnabled(this.analyticsInstance, enabled);
  }
  async setCurrentScreen(name: string): Promise<any> {
    return WebFirebaseAnalytics.setCurrentScreen(this.analyticsInstance, name);
  }
  async resetAnalyticsData(): Promise<void> {
    WebFirebaseAnalytics.setUserId(this.analyticsInstance, null);
  }
}

@Injectable({
  providedIn: 'root'
})
export class FirebaseAnalyticsFacadeService implements IFirebaseAnalytics {

  private analytics: IFirebaseAnalytics;
  private enabled = true;

  constructor(private firebaseAnalytics: FirebaseAnalytics, private platform: Platform) {
    if ((this.platform.is('ios') || this.platform.is('android')) && this.platform.is('capacitor')) {
      this.analytics = this.firebaseAnalytics;
    } else if (hasWebAnalyticsConfig) {
      this.initializeAnalytics();
    } else {
      this.enabled = false;
    }
  }

  get isEnabled() { return this.enabled; }

  private initializeAnalytics() {
    const app = WebFirebaseApp.initializeApp(WebAnalyticsConfig);
    this.analytics = new WebFirebaseAnalyticsAdapter(WebFirebaseAnalytics.getAnalytics(app));
  }

  logEvent(name: string, params: any): Promise<any> {
    return this.analytics.logEvent(name, params);
  }
  setUserId(id: string): Promise<any> {
    return this.analytics.setUserId(id);
  }
  setUserProperty(name: string, value: string): Promise<any> {
    return this.analytics.setUserProperty(name, value);
  }
  setEnabled(enabled: boolean): Promise<any> {
    return this.analytics.setEnabled(enabled);
  }
  setCurrentScreen(name: string): Promise<any> {
    return this.analytics.setCurrentScreen(name);
  }
  resetAnalyticsData(): Promise<void> {
    return this.analytics.resetAnalyticsData();
  }
}
