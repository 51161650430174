import {Pipe, PipeTransform} from '@angular/core';
import {EntryMailSendWorkflowStep} from '../../model/entry-mail-send';

@Pipe({
  name: 'entryMailStepTranslationKey',
  pure: true,
})
export class EntryMailStepTranslationKeyPipe implements PipeTransform {

  transform(currentStep: EntryMailSendWorkflowStep): string {
    switch (currentStep) {
      case EntryMailSendWorkflowStep.MAILING_LIST: return 'sendProtocol.mailingList.title';
      case EntryMailSendWorkflowStep.EMAIL_SETTINGS: return 'sendProtocol.emailSettings.title';
      default: return '';
    }
  }

}
