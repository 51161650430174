import {Injectable} from '@angular/core';
import {ModalController, Platform} from '@ionic/angular';
import {interval, Subscription} from 'rxjs';
import {LoggingService} from './logging.service';
import {AppUpdate, AppUpdateInfo} from '@capawesome/capacitor-app-update';
import {UpdateModalComponent} from 'src/app/components/common/update-popover/update-modal.component';
import { StorageKeyEnum } from 'src/app/shared/constants';
import { StorageService } from '../storage.service';

const LOG_SOURCE = 'AppUpdateService';
const STORAGE_KEY = StorageKeyEnum.UPDATE_REMIND_DATE;
const CHECK_FOR_UPDATES_INTERVAL_IN_MS = 30 * 60 * 1000;
const TIME_UNTIL_NEXT_UPDATE_REMINDER = 48 * 60 * 60 * 1000;

@Injectable({
  providedIn: 'root'
})
export class AppUpdateService {

  private checkForUpdatesSubscription: Subscription|undefined;
  private updateWorkflowInProgress = false;

  constructor(private platform: Platform, private loggingService: LoggingService, private modalController: ModalController, private storage: StorageService) { }

  public async startCheckForUpdates() {
    if (!this.platform.is('capacitor')) {
      this.loggingService.info(LOG_SOURCE, 'No need to check for native app updates on a pwa.');
      return;
    }
    this.loggingService.info(LOG_SOURCE, 'startCheckForUpdates');
    this.checkForUpdatesSubscription?.unsubscribe();
    let appUpdateInfo: AppUpdateInfo;
    this.checkForUpdatesSubscription = interval(CHECK_FOR_UPDATES_INTERVAL_IN_MS).subscribe(async () => {
      this.loggingService.info(LOG_SOURCE, 'checking for updates.');
      appUpdateInfo = (await AppUpdate.getAppUpdateInfo());
      if (appUpdateInfo?.updateAvailability === 2 && !this.updateWorkflowInProgress && await this.shouldShowReminder()) {
        this.updateWorkflowInProgress = true;
        await this.openUpdateModal(appUpdateInfo);
      }
      this.loggingService.info(LOG_SOURCE, 'checking for updates complete.');
    });
    appUpdateInfo = (await AppUpdate.getAppUpdateInfo());
    if (appUpdateInfo.updateAvailability === 2 && !this.updateWorkflowInProgress && await this.shouldShowReminder()) {
      this.updateWorkflowInProgress = true;
      await this.openUpdateModal(appUpdateInfo);
    }
  }

  public stopCheckForUpdates() {
    this.loggingService.info(LOG_SOURCE, 'stopCheckForUpdates');
    this.checkForUpdatesSubscription?.unsubscribe();
  }

  public async openUpdateModal(appUpdateInfo?: AppUpdateInfo) {
    const modal = await this.modalController.create({
      component: UpdateModalComponent,
      backdropDismiss: false,
      cssClass: 'update-modal',
      componentProps: {
        isPwa: false,
        updateInfo: appUpdateInfo
      }
    });
    await modal.present();

    const {role} = await modal.onWillDismiss();

    if (role === 'update') {
      if (appUpdateInfo.immediateUpdateAllowed) {
        await AppUpdate.performImmediateUpdate();
      } else {
        await AppUpdate.openAppStore();
      }
    } else if (role === 'cancel') {
      await this.storage.set(STORAGE_KEY, new Date().getTime());
    }
    this.updateWorkflowInProgress = false;
  }

  private async shouldShowReminder(): Promise<boolean> {
    const timestamp = await this.storage.get(STORAGE_KEY);
    if (timestamp) {
      const difference = (new Date().getTime()) - timestamp;
      return difference >= TIME_UNTIL_NEXT_UPDATE_REMINDER;
    } else {
      return true;
    }
  }
}
