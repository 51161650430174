import { Pipe, PipeTransform } from '@angular/core';
import {protocolIndexNumberToString} from 'submodules/baumaster-v2-common';

@Pipe({
  name: 'protocolIndexNumber'
})
export class ProtocolIndexNumberPipe implements PipeTransform {

  transform(value: number|undefined|null, ...args: unknown[]): string|undefined|null {
    return protocolIndexNumberToString(value);
  }

}
