import {AttachmentProtocolEntry, IdType, PdfPlanMarkerProtocolEntry, Protocol, ProtocolEntry, ProtocolEntryCompany, ProtocolEntryStatus} from 'submodules/baumaster-v2-common';
import {observableToPromise} from '../../utils/async-utils';
import {v4 as uuid4} from 'uuid';
import {Injectable} from '@angular/core';
import {ProtocolEntryDataService} from '../data/protocol-entry-data.service';
import {AttachmentEntryDataService} from '../data/attachment-entry-data.service';
import {PhotoService} from '../photo/photo.service';
import _ from 'lodash';
import {TranslateService} from '@ngx-translate/core';
import {ProtocolEntryAttachmentsCopy} from '../../model/attachments';
import {CopyProtocolEntrySetting, ProtocolEntryCopyResult} from '../../model/copy';
import {LoggingService} from '../common/logging.service';
import {ProtocolService} from '../protocol/protocol.service';
import {CompanyService} from '../company/company.service';
import {PROTOCOL_LAYOUT_NAME_SHORT, PROTOCOL_LAYOUT_NAME_STANDARD} from '../../shared/constants';
import {ProtocolEntryCompanyDataService} from '../data/protocol-entry-company-data.service';
import {getProtocolEntryCompanyId} from 'src/app/utils/protocol-entry-company-utils';
import {PdfPlanMarkerProtocolEntryDataService} from '../data/pdf-plan-marker-protocol-entry-data.service';

const LOG_SOURCE = 'CopyProtocolEntryService';

@Injectable({
  providedIn: 'root'
})
export class CopyProtocolEntryService {

  constructor(private protocolEntryDataService: ProtocolEntryDataService, private attachmentEntryDataService: AttachmentEntryDataService, private protocolService: ProtocolService,
              private companyService: CompanyService,
              private photoService: PhotoService, private translateService: TranslateService, private loggingService: LoggingService,
              private protocolEntryCompanyDataService: ProtocolEntryCompanyDataService,
              private pdfPlanMarkerProtocolEntryDataService: PdfPlanMarkerProtocolEntryDataService) {
  }

  public async startCopy(protocolEntry: ProtocolEntry, destinationProtocol: Protocol, setting: CopyProtocolEntrySetting, abortController: AbortController,
                         callback?: (protocolEntry: ProtocolEntry, numberCopiedAttachments: number, numberOfAttachments: number, text?: string) => void): Promise<ProtocolEntryCopyResult> {
    this.loggingService.debug(LOG_SOURCE, 'start copying protocol entry');
    let result: ProtocolEntryCopyResult;
    if (!protocolEntry.parentId) {
      result = await this.copyMainEntry(protocolEntry, destinationProtocol, setting, abortController, callback);
    }
    if (protocolEntry.parentId) {
      result = await this.copySubEntry(protocolEntry, destinationProtocol, setting, abortController, callback);
    }
    if (typeof callback === 'function') {
      callback(protocolEntry, 0, 0, this.translateService.instant('copyWorkflow.savingCopies'));
    }

    return result;
  }

  public async startCopyMultiple(protocolEntries: Array<ProtocolEntry>, destinationProtocol: Protocol, setting: CopyProtocolEntrySetting, abortController: AbortController,
                                 callback?: (protocolEntry: ProtocolEntry, numberCopiedAttachments: number, numberOfAttachments: number, text?: string) => void):
    Promise<ProtocolEntryCopyResult> {
    this.loggingService.debug(LOG_SOURCE, 'start copying protocol entries');
    const result = await this.copyEntries(protocolEntries, destinationProtocol, setting, abortController, callback);

    if (typeof callback === 'function') {
      callback(protocolEntries[protocolEntries.length - 1], 0, 0, this.translateService.instant('copyWorkflow.savingCopies'));
    }

    return result;
  }

  async copyMainEntry(protocolEntry: ProtocolEntry, destinationProtocol: Protocol, setting: CopyProtocolEntrySetting, abortController: AbortController,
                      callback?: (protocolEntry: ProtocolEntry, numberCopiedAttachments: number, numberOfAttachments: number, text?: string) => void): Promise<ProtocolEntryCopyResult> {
    const copiedEntries = new Array<ProtocolEntry>();
    const copiedAttachments = new Array<ProtocolEntryAttachmentsCopy>();
    const copiedMarkers = new Array<PdfPlanMarkerProtocolEntry>();

    const parentEntryCopy = await this.doCopyProtocolEntry(protocolEntry, destinationProtocol, setting, copiedEntries, null, callback);
    const destinationProtocolLayout = await observableToPromise(this.protocolService.getProtocolLayoutByTypeId(destinationProtocol.typeId));
    const isCopyingToShort = destinationProtocolLayout.name === PROTOCOL_LAYOUT_NAME_SHORT;
    const copiedProtocolEntryCompanies: ProtocolEntryCompany[] = isCopyingToShort
      ? []
      : (await observableToPromise(this.protocolEntryCompanyDataService.findAllByProtocolEntryId(protocolEntry.id)))
        .map((protocolEntryCompany) => ({
          ...protocolEntryCompany,
          id: getProtocolEntryCompanyId(parentEntryCopy.id, protocolEntryCompany.companyId),
          protocolEntryId: parentEntryCopy.id,
          changedAt: new Date().toISOString(),
        }));
    copiedEntries.push(parentEntryCopy);

    if (setting.copyAttachments) {
      const attachmentCopies = await this.doCopyAttachmentProtocolEntry(protocolEntry, destinationProtocol, parentEntryCopy, setting, abortController, callback);
      copiedAttachments.push(attachmentCopies);
    }

    if (setting.copyMarkers) {
      const markerCopies = await this.doCopyMarkersProtocolEntry(protocolEntry, destinationProtocol, parentEntryCopy, setting, abortController);
      for (const markerCopy of markerCopies) {
        copiedMarkers.push(markerCopy);
      }
    }

    if (setting.copySubEntries) {
      const subEntries = await observableToPromise(this.protocolEntryDataService.getSubEntriesByParentEntryId(protocolEntry.id));
      for (const subEntry of subEntries) {
        const subEntryCopy = await this.doCopyProtocolEntry(subEntry, destinationProtocol, setting, copiedEntries, parentEntryCopy, callback);
        copiedEntries.push(subEntryCopy);
        if (setting.copyAttachments) {
          const attachmentCopies = await this.doCopyAttachmentProtocolEntry(subEntry, destinationProtocol, subEntryCopy, setting, abortController, callback);
          copiedAttachments.push(attachmentCopies);
        }
        if (setting.copyMarkers) {
          const markerCopies = await this.doCopyMarkersProtocolEntry(subEntry, destinationProtocol, subEntryCopy, setting, abortController);
          for (const markerCopy of markerCopies) {
            copiedMarkers.push(markerCopy);
          }
        }
      }
    }
    return {copiedEntries, copiedAttachments, newParentEntry: parentEntryCopy, copiedEntryCompanies: copiedProtocolEntryCompanies, copiedMarkers} as ProtocolEntryCopyResult;
  }

  async copySubEntry(protocolEntry: ProtocolEntry, destinationProtocol: Protocol, setting: CopyProtocolEntrySetting, abortController: AbortController,
                     callback?: (protocolEntry: ProtocolEntry, numberCopiedAttachments: number, numberOfAttachments: number, text?: string) => void): Promise<ProtocolEntryCopyResult> {
    const copiedEntries = new Array<ProtocolEntry>();
    const copiedAttachments = new Array<ProtocolEntryAttachmentsCopy>();
    const copiedMarkers = new Array<PdfPlanMarkerProtocolEntry>();

    let parentEntry: ProtocolEntry;

    if (setting.copyParentEntry) {
      parentEntry = await observableToPromise(this.protocolEntryDataService.getById(protocolEntry.parentId));
    }
    const parentEntryCopy = await this.doCopyProtocolEntry(parentEntry ? parentEntry : protocolEntry, destinationProtocol, setting, copiedEntries, null, callback);
    copiedEntries.push(parentEntryCopy);
    if (setting.copyAttachments) {
      const attachmentCopies = await this.doCopyAttachmentProtocolEntry(parentEntry ? parentEntry : protocolEntry, destinationProtocol, parentEntryCopy, setting, abortController, callback);
      copiedAttachments.push(attachmentCopies);
    }
    if (setting.copyMarkers) {
      const markerCopies = await this.doCopyMarkersProtocolEntry(protocolEntry, destinationProtocol, parentEntryCopy, setting, abortController);
      for (const markerCopy of markerCopies) {
        copiedMarkers.push(markerCopy);
      }
    }

    if (setting.copyParentEntry) {
      const subEntryCopy = await this.doCopyProtocolEntry(protocolEntry, destinationProtocol, setting, copiedEntries, parentEntryCopy, callback);
      copiedEntries.push(subEntryCopy);
      if (setting.copyAttachments) {
        const attachmentCopies = await this.doCopyAttachmentProtocolEntry(protocolEntry, destinationProtocol, subEntryCopy, setting, abortController, callback);
        copiedAttachments.push(attachmentCopies);
      }
      if (setting.copyMarkers) {
        const markerCopies = await this.doCopyMarkersProtocolEntry(protocolEntry, destinationProtocol, subEntryCopy, setting, abortController);
        for (const markerCopy of markerCopies) {
          copiedMarkers.push(markerCopy);
        }
      }
    }
    return {copiedEntryCompanies: [], copiedEntries, copiedAttachments, newParentEntry: parentEntryCopy, copiedMarkers} as ProtocolEntryCopyResult;
  }

  async copyEntries(protocolEntries: Array<ProtocolEntry>, destinationProtocol: Protocol, setting: CopyProtocolEntrySetting, abortController: AbortController,
                    callback?: (protocolEntry: ProtocolEntry, numberCopiedAttachments: number, numberOfAttachments: number, text?: string) => void): Promise<ProtocolEntryCopyResult> {
    const copiedEntries = new Array<ProtocolEntry>();
    const copiedAttachments = new Array<ProtocolEntryAttachmentsCopy>();
    const copiedProtocolEntryCompanies: ProtocolEntryCompany[] = [];
    const copiedMarkers = new Array<PdfPlanMarkerProtocolEntry>();

    setting = {...setting, copyParentEntry: true, copySubEntries: false}; // do not let doCopyProtocolEntry copy the parent/child entries. This function copyEntries is doing it explicitly.

    const allParentEntries = protocolEntries.filter((protocolEntry) => !protocolEntry.parentId);
    const allChildEntries = protocolEntries.filter((protocolEntry) => !!protocolEntry.parentId);
    const allChildEntriesByParentId: Record<string, Array<ProtocolEntry>> = _.groupBy(allChildEntries, 'parentId');
    const destinationProtocolLayout = await observableToPromise(this.protocolService.getProtocolLayoutByTypeId(destinationProtocol.typeId));
    const isCopyingToShort = destinationProtocolLayout.name === PROTOCOL_LAYOUT_NAME_SHORT;
    for (const protocolEntry of allParentEntries) {
      const childEntries: Array<ProtocolEntry> = allChildEntriesByParentId[protocolEntry.id] || [];
      setting = {...setting, copyParentEntry: false, copySubEntries: false};
      const parentEntryCopy = await this.doCopyProtocolEntry(protocolEntry, destinationProtocol, setting, copiedEntries, null, callback);
      if (!isCopyingToShort) {
        copiedProtocolEntryCompanies.push(...(await observableToPromise(this.protocolEntryCompanyDataService.findAllByProtocolEntryId(protocolEntry.id)))
        .map((protocolEntryCompany) => ({
          ...protocolEntryCompany,
          id: getProtocolEntryCompanyId(parentEntryCopy.id, protocolEntryCompany.companyId),
          protocolEntryId: parentEntryCopy.id,
          changedAt: new Date().toISOString(),
        })));
      }
      copiedEntries.push(parentEntryCopy);
      if (setting.copyAttachments) {
        const attachmentCopies = await this.doCopyAttachmentProtocolEntry(protocolEntry, destinationProtocol, parentEntryCopy, setting, abortController, callback);
        copiedAttachments.push(attachmentCopies);
      }
      if (setting.copyMarkers) {
        const markerCopies = await this.doCopyMarkersProtocolEntry(protocolEntry, destinationProtocol, parentEntryCopy, setting, abortController);
        for (const markerCopy of markerCopies) {
          copiedMarkers.push(markerCopy);
        }
      }
      setting = {...setting, ...{copyParentEntry: true, copySubEntries: true}};
      for (const childEntry of childEntries) {
        const childEntryCopy = await this.doCopyProtocolEntry(childEntry, destinationProtocol, setting, copiedEntries, parentEntryCopy, callback);
        copiedEntries.push(childEntryCopy);
        if (setting.copyAttachments) {
          const attachmentCopies = await this.doCopyAttachmentProtocolEntry(childEntry, destinationProtocol, childEntryCopy, setting, abortController, callback);
          copiedAttachments.push(attachmentCopies);
        }
        if (setting.copyMarkers) {
          const markerCopies = await this.doCopyMarkersProtocolEntry(childEntry, destinationProtocol, childEntryCopy, setting, abortController);
          for (const markerCopy of markerCopies) {
            copiedMarkers.push(markerCopy);
          }
        }
      }
    }

    setting = {...setting, copyParentEntry: true, copySubEntries: true};
    const childEntriesWithoutParent = allChildEntries.filter((childEntry) => !allParentEntries.some((parentEntry) => parentEntry.id === childEntry.parentId));
    for (const protocolEntry of childEntriesWithoutParent) {
      const protocolEntryCopy = await this.doCopyProtocolEntry(protocolEntry, destinationProtocol, setting, copiedEntries, null, callback);
      if (!isCopyingToShort) {
        copiedProtocolEntryCompanies.push(...(await observableToPromise(this.protocolEntryCompanyDataService.findAllByProtocolEntryId(protocolEntry.id)))
        .map((protocolEntryCompany) => ({
          ...protocolEntryCompany,
          id: getProtocolEntryCompanyId(protocolEntryCopy.id, protocolEntryCompany.companyId),
          protocolEntryId: protocolEntryCopy.id,
          changedAt: new Date().toISOString(),
        })));
      }
      copiedEntries.push(protocolEntryCopy);
      if (setting.copyAttachments) {
        const attachmentCopies = await this.doCopyAttachmentProtocolEntry(protocolEntry, destinationProtocol, protocolEntryCopy, setting, abortController, callback);
        copiedAttachments.push(attachmentCopies);
      }
      if (setting.copyMarkers) {
        const markerCopies = await this.doCopyMarkersProtocolEntry(protocolEntry, destinationProtocol, protocolEntryCopy, setting, abortController);
        for (const markerCopy of markerCopies) {
          copiedMarkers.push(markerCopy);
        }
      }
    }

    return {copiedEntries, copiedAttachments, newParentEntry: copiedEntries[0], copiedEntryCompanies: copiedProtocolEntryCompanies, copiedMarkers} as ProtocolEntryCopyResult;
  }

  private convertTitleFromShortToNonShort(title: string|undefined|null): {title: string|undefined|null, text?: string|null} {
    if (!title) {
      return {title};
    }
    const newTitle = title.replace(/[\n\r]/g, ' ').replace(/[\n]/g, ' ');
    if (newTitle.length <= 65) {
      return {title: newTitle};
    }
    return {title: newTitle.substring(0, 65), text: title};
  }

  async doCopyProtocolEntry(protocolEntry: ProtocolEntry, destinationProtocol: Protocol, setting: CopyProtocolEntrySetting, copiedEntries: ProtocolEntry[], parentProtocolEntry?: ProtocolEntry,
                            callback?: (protocolEntry: ProtocolEntry, numberCopiedAttachments: number, numberOfAttachments: number, text?: string) => void): Promise<ProtocolEntry> {
    const sourceProtocolLayout = await observableToPromise(this.protocolService.getProtocolLayoutByProtocolId(protocolEntry.protocolId));
    const destinationProtocolLayout = await observableToPromise(this.protocolService.getProtocolLayoutByTypeId(destinationProtocol.typeId));
    const isCopyingFromNotShortToShort = sourceProtocolLayout.id !== destinationProtocolLayout.id && destinationProtocolLayout.name === PROTOCOL_LAYOUT_NAME_SHORT;
    const isCopyingFromShortToNonShort = sourceProtocolLayout.id !== destinationProtocolLayout.id && sourceProtocolLayout.name === PROTOCOL_LAYOUT_NAME_SHORT;
    const isCopyingToShort = destinationProtocolLayout.name === PROTOCOL_LAYOUT_NAME_SHORT;
    const isCopyingFromStandard = sourceProtocolLayout.name === PROTOCOL_LAYOUT_NAME_STANDARD;
    const isCopyingToStandard = destinationProtocolLayout.name === PROTOCOL_LAYOUT_NAME_STANDARD;
    const copyUnit = isCopyingFromStandard && isCopyingToStandard && setting.protocol.projectId === destinationProtocol.projectId;
    const entryCopy: ProtocolEntry = {} as ProtocolEntry;
    entryCopy.id = uuid4();
    entryCopy.protocolId = destinationProtocol.id;
    entryCopy.createdById = protocolEntry.createdById ?? null;
    if (isCopyingFromShortToNonShort) {
      const {title, text} = this.convertTitleFromShortToNonShort(protocolEntry.title);
      entryCopy.title = title;
      entryCopy.text = text;
    } else {
      entryCopy.title = protocolEntry.title;
      entryCopy.text = isCopyingToShort ? undefined : protocolEntry.text;
    }
    if (isCopyingFromNotShortToShort) {
      entryCopy.status = !protocolEntry.status || (protocolEntry.status !== ProtocolEntryStatus.OPEN && protocolEntry.status !== ProtocolEntryStatus.DONE) ?
        ProtocolEntryStatus.OPEN : protocolEntry.status;
    } else {
      entryCopy.status = protocolEntry.status;
    }
    entryCopy.typeId = isCopyingFromNotShortToShort ? undefined : protocolEntry.typeId;
    entryCopy.parentId = null;
    entryCopy.allCompanies = false;
    if (isCopyingFromNotShortToShort || !protocolEntry.parentId) {  // copy main entry
      entryCopy.number = setting.protocolEntryNumber !== undefined ? setting.protocolEntryNumber : await this.getNextNumber(destinationProtocol, undefined, copiedEntries);
    } else if (protocolEntry.parentId && !setting.copyParentEntry && !setting.copySubEntries) {  // copy sub entry to a new main entry
      entryCopy.number = setting.protocolEntryNumber !== undefined ? setting.protocolEntryNumber : await this.getNextNumber(destinationProtocol, undefined, copiedEntries);
      entryCopy.parentId = null;
    } else if (protocolEntry.parentId && parentProtocolEntry) { // copy sub entry after the main entry got copied
      entryCopy.number = await this.getNextNumber(destinationProtocol, parentProtocolEntry.id, copiedEntries);
      entryCopy.parentId = parentProtocolEntry.id;
    } else if (protocolEntry.parentId && !parentProtocolEntry) {
      entryCopy.number = await this.getNextNumber(destinationProtocol, undefined, copiedEntries);
      entryCopy.parentId = null;
    }
    if (setting.copyDetails) {
      if (isCopyingFromNotShortToShort) {
        const ownCompany = await observableToPromise(this.companyService.getOwnCompany$());
        if (!ownCompany) {
          throw new Error(`Unable to copy ProtocolEntry because ownCompany cannot be found.`);
        }
        const isSameCompany = protocolEntry.companyId && protocolEntry.companyId === ownCompany.id;
        entryCopy.companyId = ownCompany.id;
        if (setting.copyContacts && isSameCompany) {
          entryCopy.internalAssignmentId = protocolEntry.internalAssignmentId;
        }
      } else {
        if (setting.copyContacts) {
          entryCopy.allCompanies = protocolEntry.allCompanies;
          entryCopy.companyId = protocolEntry.companyId;
          entryCopy.internalAssignmentId = protocolEntry.internalAssignmentId;
        }
      }
      entryCopy.priority = protocolEntry.priority;
      entryCopy.nameableDropdownId = isCopyingFromNotShortToShort ? undefined : protocolEntry.nameableDropdownId;
      entryCopy.cost = isCopyingFromNotShortToShort ? undefined : protocolEntry.cost;
      entryCopy.craftId = isCopyingFromNotShortToShort ? undefined : protocolEntry.craftId;
      entryCopy.locationId = isCopyingFromNotShortToShort ? undefined : protocolEntry.locationId;
      entryCopy.unitId = copyUnit && protocolEntry.unitId ? protocolEntry.unitId : null;
      entryCopy.startDate = protocolEntry.startDate;
      entryCopy.todoUntil = protocolEntry.todoUntil;
      entryCopy.reminderAt = protocolEntry.reminderAt;
    }
    entryCopy.changedAt = new Date().toISOString();
    entryCopy.createdAt = setting.copyCreationDate ? protocolEntry.createdAt : new Date().toISOString();
    if (typeof callback === 'function') {
      callback(protocolEntry, 0, 0);
    }
    return entryCopy;
  }

  async doCopyAttachmentProtocolEntry(protocolEntry: ProtocolEntry, destinationProtocol: Protocol, entryCopy: ProtocolEntry, setting: CopyProtocolEntrySetting, abortController: AbortController,
                                      callback?: (protocolEntry: ProtocolEntry, numberCopiedAttachments: number, numberOfAttachments: number, text?: string) => void):
    Promise<ProtocolEntryAttachmentsCopy> {
    const attachments = await observableToPromise(this.attachmentEntryDataService.getByProtocolEntry(protocolEntry.id));
    const attachmentsCopy: AttachmentProtocolEntry[] = [];
    const blobs: Blob[] = [];
    for (const attachment of attachments) {
      if (!attachment.filePath) {
        if (setting.ignoreMissingAttachments) {
          continue;
        }
        throw new Error(`Attachment for ProtocolEntry with id ${attachment.id} does not exist.`);
      }
      const blobFile: Blob = await this.photoService.downloadAttachment(attachment, 'image', false, abortController);
      if (!blobFile) {
        throw new Error(this.translateService.instant('copyWorkflow.errorAttachmentNotExit', {fileName: attachment.fileName}));
      }
      const attachmentCopy: AttachmentProtocolEntry = {
        id: uuid4(),
        hash: uuid4(),
        projectId: destinationProtocol.projectId,
        protocolEntryId: entryCopy.id,
        mimeType: attachment.mimeType,
        markings: attachment.markings,
        fileName: attachment.fileName,
        fileExt: attachment.fileExt,
        changedAt: attachment.changedAt,
        createdAt: attachment.createdAt,
        createdById: attachment.createdById
      };

      attachmentsCopy.push(attachmentCopy);
      blobs.push(blobFile);
      if (typeof callback === 'function') {
        callback(protocolEntry, attachmentsCopy.length, attachments.length);
      }
    }
    return {attachments: attachmentsCopy, blobs} as ProtocolEntryAttachmentsCopy;
  }

  async getNextNumber(destinationProtocol: Protocol, parentEntryId?: IdType, copiedEntries?: ProtocolEntry[]): Promise<number> {
    let entries: ProtocolEntry [];
    if (parentEntryId) {
      entries = copiedEntries?.filter(entry => entry.parentId === parentEntryId);
    } else {
      entries = await observableToPromise(this.protocolEntryDataService.getByProtocolId(destinationProtocol.id));
      if (copiedEntries?.length) {
        entries = entries.concat(copiedEntries);
      }
      entries = entries.filter(entry => !entry.parentId);
    }
    if (!entries?.length) {
      return 1;
    }
    const numbers = _.sortBy(entries.map((protocolEntry) => protocolEntry.number));
    return numbers[numbers.length - 1] + 1;
  }

  async analyzeCopyMultipleEntries(protocolEntries: Array<ProtocolEntry>): Promise<{parentWithoutAnyChild: boolean; childWithoutParent: boolean}> {
    const sourceProtocolIds = [...new Set(protocolEntries.map((protocolEntry) => protocolEntry.protocolId))];
    let sourceProtocolEntries = new Array<ProtocolEntry>();
    for (const sourceProtocolId of sourceProtocolIds) {
      sourceProtocolEntries = sourceProtocolEntries.concat(await observableToPromise(this.protocolEntryDataService.getByProtocolId(sourceProtocolId)));
    }

    const allSourceChildProtocolEntries = sourceProtocolEntries.filter((protocolEntry) => !!protocolEntry.parentId);
    const allSourceChildProtocolEntriesByParentId: Record<string, Array<ProtocolEntry>> = _.groupBy(allSourceChildProtocolEntries, 'parentId');

    const parentProtocolEntries = protocolEntries.filter((protocolEntry) => !protocolEntry.parentId);
    const childProtocolEntries = protocolEntries.filter((protocolEntry) => !!protocolEntry.parentId);
    const childProtocolEntriesByParentId: Record<string, Array<ProtocolEntry>> = _.groupBy(childProtocolEntries, 'parentId');

    let parentWithoutAnyChild = false;
    let childWithoutParent = false;

    for (const parentProtocolEntry of parentProtocolEntries) {
      const sourceChildrenLength = allSourceChildProtocolEntriesByParentId[parentProtocolEntry.id]?.length ?? 0;
      const childrenLength = childProtocolEntriesByParentId[parentProtocolEntry.id]?.length ?? 0;
      if (sourceChildrenLength && !childrenLength) {
        parentWithoutAnyChild = true;
        break;
      }
    }

    for (const childProtocolEntry of childProtocolEntries) {
      const foundParent = parentProtocolEntries.some((parentProtocolEntry) => parentProtocolEntry.id === childProtocolEntry.parentId);
      if (!foundParent) {
        childWithoutParent = true;
        break;
      }
    }

    return { parentWithoutAnyChild, childWithoutParent };
  }

  async doCopyMarkersProtocolEntry(protocolEntry: ProtocolEntry, destinationProtocol: Protocol, entryCopy: ProtocolEntry, setting: CopyProtocolEntrySetting, abortController: AbortController):
    Promise<Array<PdfPlanMarkerProtocolEntry>> {
    const pdfPlanMarkerProtocolEntries = await observableToPromise(this.pdfPlanMarkerProtocolEntryDataService.getByProtocolEntry(protocolEntry.id));
    const markersCopy: PdfPlanMarkerProtocolEntry[] = [];
    for (const pdfPlanMarker of pdfPlanMarkerProtocolEntries) {
      const markerCopy: PdfPlanMarkerProtocolEntry = {
        id: uuid4(),
        positionX: pdfPlanMarker.positionX,
        positionY: pdfPlanMarker.positionY,
        pdfPlanPageId: pdfPlanMarker.pdfPlanPageId,
        changedAt: new Date(),
        projectId: destinationProtocol.projectId,
        protocolEntryId: entryCopy.id
      };
      markersCopy.push(markerCopy);
    }
    return markersCopy;
  }
}
