import type {TranslateService} from '@ngx-translate/core';
import _ from 'lodash';
import {Observable} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {IdAware, IdType, Project, ProtocolEntry} from 'submodules/baumaster-v2-common';
import {Nullish} from '../model/nullish';
import {combineLatestAsync} from './async-utils';
import {compareObjectsWithPrimitiveValues} from './compare-utils';
import {filterStreamByProjects} from './object-utils';

export type IdAndActiveAware = IdAware & { isActive?: boolean | undefined };

export const isActive = <T extends {isActive?: boolean | undefined}>(obj: T) => obj.isActive === undefined || obj.isActive === true;

export const activeOrPresentFilter = <T extends IdAndActiveAware, U = ProtocolEntry>(data: T[], entries: U[], field: keyof U): T[] =>
  data.filter((obj) => obj.isActive || entries.some((entry) => entry[field] === obj.id));

export const activeOrPresent = <T extends IdAndActiveAware, U = ProtocolEntry>(
  data$: Observable<T[]>,
  entries$: Observable<U[]>,
  field: keyof U
): Observable<T[]> => combineLatestAsync([data$, entries$]).pipe(map(([data, entries]) => activeOrPresentFilter(data, entries, field)));

function mapWithSuffixes<
  TObject extends IdAndActiveAware & Record<TObjectNameField, string>,
  TEntry extends Partial<Record<TEntryFKField, Nullish<IdType> | Nullish<IdType[]>>>,
  TActive extends {projectId: IdType;} & Record<TActiveFKField, IdType>,
  TActiveFKField extends keyof TActive,
  TObjectNameField extends keyof TObject,
  TEntryFKField extends keyof TEntry
>(
  objectsActiveInProjects: TActive[],
  activeFKField: TActiveFKField,
  entries: TEntry[],
  entryFKFields: TEntryFKField[],
  objects: TObject[],
  objectNameField: TObjectNameField,
  removedSuffix: string,
  deletedSuffix: string
) {
  const activeObjectsInProjects = new Set<IdType>(objectsActiveInProjects.map((value) => value[activeFKField]));
  const usedObjectsInEntries = new Set<IdType>(_.flattenDeep(entries.map((entry) => entryFKFields.map((field) => entry[field])
  )));

  const mapped = objects.map((obj) => {
    let name: string = obj[objectNameField];
    if (isActive(obj) && !activeObjectsInProjects.has(obj.id)) {
      name = `${obj[objectNameField]} ${removedSuffix}`;
    }
    if (!isActive(obj)) {
      name = `${obj[objectNameField]} ${deletedSuffix}`;
    }
    return ({
      ...obj,
      [objectNameField]: name,
      isDeletedOrNotInProject: !isActive(obj) || !activeObjectsInProjects.has(obj.id),
    });
  });
  return {mapped, activeObjectsInProjects, usedObjectsInEntries};
}

/**
 * Combines observables to produce a list of objects, which are either active in project, or used in object.
 *
 * Also adds proper suffix (either removed from project suffix or removed from client suffix) to the name
 */
export const activeOrPresentWithSuffixes = <
  TObject extends IdAndActiveAware & Record<TObjectNameField, string>,
  TEntry extends Partial<Record<TEntryFKField, Nullish<IdType> | Nullish<IdType[]>>>,
  TActive extends {projectId: IdType} & Record<TActiveFKField, IdType>,
  TActiveFKField extends keyof TActive,
  TObjectNameField extends keyof TObject,
  TEntryFKField extends keyof TEntry
>(
  objects$: Observable<TObject[]>,
  objectsActiveInProjects$: Observable<TActive[]>,
  projects$: Observable<Project[]>,
  entries$: Observable<TEntry[]>,
  activeFKField: TActiveFKField,
  objectNameField: TObjectNameField,
  entryFKFields: TEntryFKField[],
  translateService: TranslateService
): Observable<(TObject & {isDeletedOrNotInProject: boolean})[]> => combineLatestAsync([
  objects$,
  objectsActiveInProjects$.pipe(filterStreamByProjects(projects$)),
  entries$,
  translateService.get(['removed_suffix', 'deleted']).pipe(
    distinctUntilChanged(compareObjectsWithPrimitiveValues),
  )
]).pipe(
  map(([
    objects,
    objectsActiveInProjects,
    entries,
    {removed_suffix: removedSuffix, deleted: deletedSuffix}
  ]) => {
    const {mapped, activeObjectsInProjects, usedObjectsInEntries} = mapWithSuffixes(
      objectsActiveInProjects,
      activeFKField,
      entries,
      entryFKFields,
      objects,
      objectNameField,
      removedSuffix,
      deletedSuffix
    );

    return _.orderBy(mapped.filter(({ id }) =>
      activeObjectsInProjects.has(id) || usedObjectsInEntries.has(id)
    ), (obj) => obj.isDeletedOrNotInProject ? 1 : 0);
  })
);

/**
 * Combines observables to produce a list of objects, which are used in object.
 *
 * Also adds proper suffix (either removed from project suffix or removed from client suffix) to the name
 */
export const presentWithSuffixes = <
  TObject extends IdAndActiveAware & Record<TObjectNameField, string>,
  TEntry extends Partial<Record<TEntryFKField, Nullish<IdType> | Nullish<IdType[]>>>,
  TActive extends {projectId: IdType} & Record<TActiveFKField, IdType>,
  TActiveFKField extends keyof TActive,
  TObjectNameField extends keyof TObject,
  TEntryFKField extends keyof TEntry
>(
  objects$: Observable<TObject[]>,
  objectsActiveInProjects$: Observable<TActive[]>,
  projects$: Observable<Project[]>,
  entries$: Observable<TEntry[]>,
  activeFKField: TActiveFKField,
  objectNameField: TObjectNameField,
  entryFKFields: TEntryFKField[],
  translateService: TranslateService
): Observable<(TObject & {isDeletedOrNotInProject: boolean})[]> => combineLatestAsync([
  objects$,
  objectsActiveInProjects$.pipe(filterStreamByProjects(projects$)),
  entries$,
  translateService.get(['removed_suffix', 'deleted']).pipe(
    distinctUntilChanged(compareObjectsWithPrimitiveValues),
  )
]).pipe(
  map(([
    objects,
    objectsActiveInProjects,
    entries,
    {removed_suffix: removedSuffix, deleted: deletedSuffix}
  ]) => {
    const {mapped, activeObjectsInProjects, usedObjectsInEntries} = mapWithSuffixes(
      objectsActiveInProjects,
      activeFKField,
      entries,
      entryFKFields,
      objects,
      objectNameField,
      removedSuffix,
      deletedSuffix
    );

    return _.orderBy(mapped.filter(({ id }) =>
      activeObjectsInProjects.has(id) && usedObjectsInEntries.has(id)
    ), (obj) => obj.isDeletedOrNotInProject ? 1 : 0);
  })
);
