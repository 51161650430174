import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import _ from 'lodash';
import {Observable} from 'rxjs';
import {distinctUntilChanged, map, shareReplay} from 'rxjs/operators';
import {Nullish} from 'src/app/model/nullish';
import {mapObjectNameWithDeletedSuffix, mapObjectsNameWithDeletedSuffix} from 'src/app/utils/async-utils';
import {IdType, ProtocolEntryType, User} from 'submodules/baumaster-v2-common';
import {StorageKeyEnum} from '../../shared/constants';
import {AuthenticationService} from '../auth/authentication.service';
import {ClientService} from '../client/client.service';
import {LoggingService} from '../common/logging.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {StorageService} from '../storage.service';
import {UserService} from '../user/user.service';
import {AbstractClientAwareDataService} from './abstract-client-aware-data.service';
import {isEntryTypeTask} from '../../utils/protocol-entry-utils';
import {VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';

const REST_ENDPOINT_URI = 'api/data/protocolEntryTypes';

@Injectable({
  providedIn: 'root'
})
export class ProtocolEntryTypeDataService extends AbstractClientAwareDataService<ProtocolEntryType> {
  public readonly dataWithDeletedSuffix = this.data.pipe(mapObjectsNameWithDeletedSuffix(this.translateService));
  public readonly dataActive$ = this.dataForOwnClient$.pipe(map((protocolEntryTypes) =>
    protocolEntryTypes?.filter((protocolEntryType) => protocolEntryType.isActive || protocolEntryType.isActive === undefined)));
  public readonly dataActiveStatusFieldActive$ = this.dataActive$.pipe(
    map((types) => types.filter((type) => type.statusFieldActive))
  );
  public readonly dataStatusFieldActive$ = this.data.pipe(
    map((types) => types.filter((type) => type.statusFieldActive))
  );

  public readonly taskEntryType$: Observable<ProtocolEntryType | undefined> = this.data.pipe(
    map((types) => types.find(isEntryTypeTask)),
    distinctUntilChanged<ProtocolEntryType>(_.isEqual),
    shareReplay({
      refCount: true,
      bufferSize: 1
    })
  );

  constructor(http: HttpClient, storage: StorageService, authenticationService: AuthenticationService, userService: UserService, clientService: ClientService,
              loggingService: LoggingService, integrityResolverService: IntegrityResolverService, private translateService: TranslateService) {
    super(StorageKeyEnum.PROTOCOL_ENTRY_TYPE, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, clientService, loggingService,
      integrityResolverService, VERSION_INTRODUCED_DEFAULT, [(value) => value?.name?.toLowerCase()]);
  }

  getByIdAcrossClientsWithDeletedSuffix(id: IdType): Observable<Nullish<ProtocolEntryType>> {
    return this.getByIdAcrossClients(id).pipe(mapObjectNameWithDeletedSuffix(this.translateService));
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }
}
