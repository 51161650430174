import {ColumnConfig} from '../ColumnConfig';
import {UnitColumnSet} from './UnitColumnSet';

const ProjectIndexUniq = ['project_id', 'index'];

export const UnitLevelColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'index', prop: 'index', cnd: true, uniqueWith: [ProjectIndexUniq]},
  {name: 'project_id', prop: 'projectId', cnd: true, uniqueWith: [ProjectIndexUniq]},
  {name: 'name', prop: 'name'},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'created_at', prop: 'createdAt', cast: 'Timestamp'},
  {name: 'units', fk: 'unitLevelId', mappedBy: UnitColumnSet, transient: true},
];
