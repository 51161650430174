<div class="d-flex ion-justify-content-between ion-align-items-center">
  <div class="omg-modal-section-header d-flex gap-2">
    <fa-icon [icon]="['fal', 'thumbtack']"></fa-icon>
    <span [innerHTML]="'companyOrder.manage.pinnedCompaniesHeader' | translate"></span>
  </div>
  <ion-button class="omg-btn-secondary" (click)="unpinAll()" [style.visibility]="pinnedCompanies.length === 0 ? 'hidden' : undefined">
    <span>{{ 'companyOrder.manage.unpinAll' | translate }}</span>
    <fa-icon slot="end" [icon]="['fal', 'times']"></fa-icon>
  </ion-button>
</div>

<ion-list class="pinned-area" lines="none">
  <div class="ion-text-center empty-text" @emptyTop *ngIf="pinnedCompanies.length === 0" [innerHTML]="'companyOrder.manage.noPinnedCompanies' | translate"></div>
  <ion-reorder-group [disabled]="false" (ionItemReorder)="doReorder($event)">
    <div class="reorder-item" @pinnedTop *ngFor="let company of pinnedCompanies">
      <ion-item class="like-button">
        <ion-reorder slot="start">
          <fa-icon [icon]="['fal', 'grip-lines']"></fa-icon>
        </ion-reorder>
        {{ company | companyNameString }}
        <ion-button class="omg-btn-text" slot="end" (click)="unpinCompany(company)">
          <fa-icon [icon]="['fal', 'times']"></fa-icon>
        </ion-button>
      </ion-item>
    </div>
  </ion-reorder-group>
</ion-list>

<div class="omg-modal-section-header d-flex gap-2">
  <fa-icon [icon]="['fal', 'sort-alpha-down']"></fa-icon>
  <span [innerHTML]="'companyOrder.manage.unpinnedCompaniesHeader' | translate"></span>
</div>

<ion-list class="unpinned-area" lines="none">
  <ion-item @pinned *ngFor="let company of unpinnedCompanies">
    <ion-button class="omg-btn-secondary" slot="start" (click)="pinCompany(company)">
      <fa-icon [icon]="['fal', 'thumbtack']"></fa-icon>
    </ion-button>
    {{ company | companyNameString }}
  </ion-item>
</ion-list>
