<div class="content">
  <ion-content>
    <ion-list>
      <ion-radio-group [(ngModel)]="selectedTemplateId" (ngModelChange)="selectedTemplateIdChange.emit(selectedTemplateId)">
        <ion-item class="template-item" *ngFor="let template of templates">
          <ion-radio label-placement="end" justify="start" [value]="template.id">
            {{template.name}}
          </ion-radio>
          <fa-icon class="template-item-icon" *ngIf="itemIcon" [icon]="itemIcon" slot="end" (click)="$event.stopPropagation(); itemIconClick.emit(template.id)"></fa-icon>
        </ion-item>
      </ion-radio-group>
    </ion-list>
  </ion-content>
  <div class="template-preview" *ngIf="selectedTemplateId && selectedTemplate">
    <strong class="color-fix">{{ 'textTemplate.preview.namePrefix' | translate:{name:selectedTemplate.name} }}</strong>

    <app-rich-text-editor
      [label]="''"
      [ngModel]="textTemplate"
      [disabled]="true"
      id="text-template-preview-rich-text-editor"
      (click)="applySelected.emit()"
    ></app-rich-text-editor>

    <app-tooltip target="text-template-preview-rich-text-editor" placement="bottom">
      {{templateType === 'global' ? ('textTemplate.preview.previewGlobalTooltip' | translate) : ('textTemplate.preview.previewTooltip' | translate)}}
    </app-tooltip>

    <div class="omg-t-xs-body-bold color-fix position-fix" *ngIf="selectedTemplate.createdById">
      <div class="gradient"></div>
      <div>{{'textTemplate.preview.createdBy' | translate}}: {{createdBy$|async}}</div>
      <div>{{'textTemplate.preview.createdAt' | translate}}: {{selectedTemplate.createdAt | date:DATE_FORMAT}}</div>
      <div>{{'textTemplate.preview.lastChangedAt' | translate}}: {{selectedTemplate.changedAt | date:DATE_FORMAT}}</div>
    </div>
    <div class="omg-t-xs-body-bold color-fix position-fix" *ngIf="!selectedTemplate.createdById">
      <div class="gradient"></div>
      {{'textTemplate.preview.createdByBaumaster'|translate}}
    </div>
  </div>
  <div class="template-preview-small color-fix position-fix" *ngIf="selectedTemplateId && selectedTemplate" (click)="preview.present()">
    <strong>{{selectedTemplate.name}}</strong>
    <fa-icon [icon]="['fal', 'chevron-up']"></fa-icon>
  </div>
</div>
<ion-modal #preview [handle]="false" [cssClass]="'text-template-preview-modal'" [initialBreakpoint]="1" [breakpoints]="[1]">
  <ng-template>
    <ion-header (click)="preview.dismiss()" class="cursor-pointer">
      <ion-toolbar>
        <div class="d-flex ion-justify-content-between ion-align-items-start gap-2">
          <strong>{{ 'textTemplate.preview.namePrefix' | translate:{name:selectedTemplate.name} }}</strong>
          <fa-icon [icon]="['fal', 'chevron-down']"></fa-icon>
        </div>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <app-rich-text-editor
        [label]="''"
        [ngModel]="textTemplate"
        [disabled]="true"
      ></app-rich-text-editor>
      <div slot="fixed" class="author color-fix" *ngIf="selectedTemplate.createdById">
        <div>{{'textTemplate.preview.createdBy' | translate}}: {{createdBy$|async}}</div>
        <div>{{'textTemplate.preview.createdAt' | translate}}: {{selectedTemplate.createdAt | date:DATE_FORMAT}}</div>
        <div>{{'textTemplate.preview.lastChangedAt' | translate}}: {{selectedTemplate.changedAt | date:DATE_FORMAT}}</div>
      </div>
      <div slot="fixed" class="baumaster-template color-fix" *ngIf="!selectedTemplate.createdById">
        {{'textTemplate.preview.createdByBaumaster'|translate}}
      </div>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button *ngIf="selectedTemplate.createdById" class="omg-btn-secondary" (click)="preview.dismiss(); editSelected.emit()">
            <fa-icon slot="start" [icon]="['fal', 'pencil']"></fa-icon>
            <span>{{ 'textTemplate.preview.edit' | translate }}</span>
          </ion-button>
          <ion-button *ngIf="!selectedTemplate.createdById" class="omg-btn-secondary" (click)="preview.dismiss(); copySelected.emit()">
            <fa-icon slot="start" [icon]="['fal', 'arrow-to-bottom']"></fa-icon>
          </ion-button>
          <ion-button class="omg-btn-primary" (click)="preview.dismiss(); applySelected.emit()">
            {{'textTemplate.modal.button.applyTemplate' | translate}}
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-footer>
  </ng-template>
</ion-modal>