import {Component, Inject, Input, LOCALE_ID, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {UiModule} from '../../../shared/module/ui/ui.module';
import {TranslateModule} from '@ngx-translate/core';
import {RxFor} from '@rx-angular/template/for';
import {FormsModule} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {IdType, Protocol, ProtocolType} from 'submodules/baumaster-v2-common';
import {ProtocolEntryDataService} from '../../../services/data/protocol-entry-data.service';
import {ProtocolEntryOrOpen} from '../../../model/protocol';
import {map} from 'rxjs/operators';
import {combineLatestAsync, switchMapOrDefault} from '../../../utils/async-utils';
import {ProtocolDataService} from '../../../services/data/protocol-data.service';
import {getProtocolEntryShortId} from '../../../utils/protocol-entry-utils';
import {ProtocolTypeDataService} from '../../../services/data/protocol-type-data.service';
import {RxLet} from '@rx-angular/template/let';
import _ from 'lodash';
import {isTaskProtocolType} from '../../../utils/protocol-utils';

interface ProtocolEntryOrOpenWithShortId extends ProtocolEntryOrOpen {
  shortId: string;
}

@Component({
  selector: 'app-select-main-entry-modal',
  templateUrl: './select-main-entry-modal.component.html',
  styleUrls: ['./select-main-entry-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    UiModule,
    TranslateModule,
    RxFor,
    FormsModule,
    RxLet
  ]
})
export class SelectMainEntryModalComponent  implements OnInit {
  private readonly modal: HTMLIonModalElement;
  readonly searchSubject = new BehaviorSubject('');
  private searchLowerCaseTrim$ = this.searchSubject.pipe(map((search) => search.trim().toLocaleLowerCase()));

  @Input()
  protocolId: IdType;
  @Input()
  protocolEntryIdToExclude?: IdType;
  @Input()
  title?: string;
  @Input()
  subtitle?: string;
  @Input()
  warningMessages?: string[];
  @Input()
  confirmButtonLabel?: string;

  private entries$: Observable<Array<ProtocolEntryOrOpen>>;
  private protocol$: Observable<Protocol | undefined>;
  private protocolsById$: Observable<Record<IdType, Protocol>>;
  private protocolType$: Observable<ProtocolType|undefined>;
  public entriesSearch$: Observable<Array<ProtocolEntryOrOpenWithShortId>>;
  targetEntryId: IdType|undefined;


  constructor(@Inject(LOCALE_ID) private locale: string,
              private protocolEntryDataService: ProtocolEntryDataService,
              private protocolDataService: ProtocolDataService,
              private protocolTypeDataService: ProtocolTypeDataService,
              ) { }

  ngOnInit() {
    this.protocol$ = this.protocolDataService.getById(this.protocolId);
    this.protocolsById$ = this.protocolDataService.dataGroupedById;
    this.protocolType$ = this.protocol$.pipe(switchMapOrDefault((protocol) => this.protocolTypeDataService.getById(protocol.typeId)));
    this.entries$ = this.protocolEntryDataService.getParentEntriesOrOpenByProtocolId(this.protocolId)
      .pipe(map((entries) => this.protocolEntryIdToExclude ? entries.filter((entry) => entry.id !== this.protocolEntryIdToExclude) : entries));
    this.entriesSearch$ = combineLatestAsync([this.searchLowerCaseTrim$, this.entries$, this.protocol$, this.protocolType$, this.protocolsById$])
      .pipe(map(([search, entries, protocol, protocolType, protocolsById]) => {
        const isTaskProtocol = isTaskProtocolType(protocolType);
        const entriesWithShortId: Array<ProtocolEntryOrOpenWithShortId> = entries.map((entry) => {
          const protocolForShortId = protocolsById[entry.originalProtocolId ?? entry.protocolId] ?? protocol;
          return {...entry, shortId: getProtocolEntryShortId(protocolForShortId, protocolType, entry.number, this.locale)};
        });
        const entriesFiltered = entriesWithShortId.filter((entry) => entry.title?.toLocaleLowerCase()?.includes(search) || entry.shortId?.toLocaleLowerCase()?.includes(search))
        if (this.targetEntryId && !entriesFiltered.some((entry) => entry.id === this.targetEntryId)) {
          this.targetEntryId = undefined;
        }

        return isTaskProtocol ? _.orderBy(entriesFiltered, ['number', 'name', 'id']) : _.orderBy(entriesFiltered, ['shortId', 'name', 'id']);
      }));
  }

  close() {
    this.modal.dismiss();
  }

  confirmTarget() {
    if (this.targetEntryId) {
      this.modal.dismiss(this.targetEntryId, 'confirm-target');
    }
  }

}
