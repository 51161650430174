import {state, style, transition, trigger, useAnimation} from '@angular/animations';
import {DOCUMENT} from '@angular/common';
import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostBinding, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {concealAnimation, revealAnimation} from 'src/app/animations/reveal.animation';
import {tooltipPlacementCalculator} from './tooltip-placement-calculator';
import {TooltipPlacement} from './tooltip-placement.model';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  animations: [
    trigger('reveal', [
      state('hidden', style({
        visibility: 'hidden'
      })),
      state('visible', style({
        visibility: 'visible'
      })),
      transition('hidden => visible', useAnimation(revealAnimation)),
      transition('visible => hidden', useAnimation(concealAnimation))
    ]),
  ]
})
export class TooltipComponent implements OnChanges, AfterViewInit, OnInit, OnDestroy {

  @Input()
  target: string;

  @Input()
  placement: TooltipPlacement = 'right';

  @ViewChild('tooltipEl', {
    read: ElementRef,
    static: true
  }) tooltipEl: ElementRef<HTMLIonCardElement>;

  get targetEl() {
    return this.document.getElementById(this.target);
  }

  shown = false;
  callbacksSet = false;

  top: number | undefined;
  left: number | undefined;
  right: number | undefined;
  bottom: number | undefined;
  @HostBinding('style.top')
  get topPx() { return this.top ? `${this.top}px` : undefined; }
  @HostBinding('style.left')
  get leftPx() { return this.left ? `${this.left}px` : undefined; }
  @HostBinding('style.right')
  get rightPx() { return this.right ? `${this.right}px` : undefined; }
  @HostBinding('style.bottom')
  get bottomPx() { return this.bottom ? `${this.bottom}px` : undefined; }

  get tooltipWidth(): number | undefined {
    const width = this.tooltipEl?.nativeElement?.clientWidth;
    if (!width) {
      return undefined;
    }

    return width + 20;
  }

  get tooltipHeight(): number | undefined {
    const height = this.tooltipEl?.nativeElement?.clientHeight;
    if (!height) {
      return undefined;
    }

    return height + 20;
  }

  @HostBinding('style.transform')
  get transform() {
    if (this.placement === 'bottom' || this.placement === 'top') {
      const additionalTranslate = this.placement === 'top' ? 'translateY(-100%) ' : '';
      const leftClipping = this.getLeftClippingDetails();
      if (leftClipping.willClip) {
        return `${additionalTranslate}translateX(${leftClipping.translateX})`;
      }

      const rightClipping = this.getRightClippingDetails();
      if (rightClipping.willClip) {
        return `${additionalTranslate}translateX(${rightClipping.translateX})`;
      }

      return `${additionalTranslate}translateX(-50%)`;
    } else if (this.placement === 'left') {
      return 'translate(-100%, -50%)';
    } else {
      return 'translateY(-50%)';
    }
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private elementRef: ElementRef,
    private cdRef: ChangeDetectorRef
  ) { }

  private getLeftClippingDetails() {
    const leftBoundAt = (this.left || ((this.right ?? 0) - (this.tooltipWidth ?? 0))) ?? 0;
    const leftBoundAfterTranslate = leftBoundAt - (this.tooltipWidth ?? 0) / 2;

    return {
      willClip: leftBoundAfterTranslate < 0,
      translateX: `-${leftBoundAt}px`,
    };
  }

  private getRightClippingDetails() {
    const windowWidth = this.document.defaultView.innerWidth;
    const rightBoundAt = (this.right || ((this.left ?? 0) + (this.tooltipWidth ?? 0))) ?? windowWidth;
    const rightBoundAfterTranslate = rightBoundAt - (this.tooltipWidth ?? 0) / 2;

    return {
      willClip: rightBoundAfterTranslate > windowWidth,
      translateX: `${windowWidth - rightBoundAt}px`,
    };
  }

  private mouseEnterListener = () => {
    this.openTooltip();
  };

  private mouseLeaveListener = () => {
    this.closeTooltip();
  };

  private touchStartListener = () => {
    this.removeTargetListeners(this.target);
    this.closeTooltip();
  };

  private async closeTooltip() {
    if (!this.shown) {
      return;
    }
    this.shown = false;
    this.cdRef.markForCheck();
    this.cdRef.detectChanges();
  }

  private async openTooltip() {
    if (this.shown) {
      return;
    }

    this.shown = true;
    const position = tooltipPlacementCalculator(this.placement, this.targetEl.getBoundingClientRect());

    this.top = position.top;
    this.left = position.left;
    this.right = position.right;
    this.bottom = position.bottom;
    this.cdRef.markForCheck();
  }

  private setupTargetListeners() {
    const { targetEl } = this;
    if (!targetEl) {
      return;
    }

    targetEl.addEventListener('mouseenter', this.mouseEnterListener);
    targetEl.addEventListener('mouseleave', this.mouseLeaveListener);
    targetEl.addEventListener('touchstart', this.touchStartListener);
    this.callbacksSet = true;
  }

  private removeTargetListeners(target: string) {
    const targetEl = this.document.getElementById(target);
    if (!targetEl) {
      return;
    }

    targetEl.removeEventListener('mouseenter', this.mouseEnterListener);
    targetEl.removeEventListener('mouseleave', this.mouseLeaveListener);
    targetEl.removeEventListener('touchstart', this.touchStartListener);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.target) {
      this.callbacksSet = false;
      this.setupTargetListeners();
      if (changes.target.previousValue && changes.target.previousValue !== changes.target.currentValue) {
        this.removeTargetListeners(changes.target.previousValue);
      }
    }
  }

  ngOnInit() {
    this.document.body.appendChild(this.elementRef.nativeElement);
  }

  ngOnDestroy() {
    this.document.body.removeChild(this.elementRef.nativeElement);
  }

  ngAfterViewInit() {
    if (!this.callbacksSet) {
      this.setupTargetListeners();
    }
  }

}
