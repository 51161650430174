import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {SIGNATURE_HEIGHT, SIGNATURE_WIDTH, Signer, SignerWithSignature} from '../../../model/send-protocol';
import {SketchComponent} from '../../common/sketch/sketch.component';
import {PhotoService} from '../../../services/photo/photo.service';
import {AlertController, ModalController} from '@ionic/angular';
import _ from 'lodash';
import {TranslateService} from '@ngx-translate/core';
import {ToastService} from '../../../services/common/toast.service';
import {Nullish} from '../../../model/nullish';

const SIGNER_NAME_MAX_LENGTH = 255;

function findSigner<T extends Signer>(signer: T): (signerSearch: T) => boolean {
  return (signerSearch: T) => signerSearch.code === signer.code;
}

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss'],
})
export class SignatureComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public readonly = false;
  @Input()
  public showSignatureWhenSigned = false;
  @Input()
  public signers = new Array<Signer>();
  @Input()
  public signersWithSignature = new Array<SignerWithSignature>();
  @Output()
  public signersWithSignatureChange = new EventEmitter<Array<SignerWithSignature>>();
  @Output()
  public allSignedChange = new EventEmitter<boolean>();
  public signersWithSignatureChangeByCode: Record<string, SignerWithSignature> = {};
  @Input()
  public showSendButtons = false;
  @Input()
  public showCloseCheckbox = false;
  @Output()
  public requestCloseEmitter = new EventEmitter<void>();
  @Output()
  public requestRenderEmitter = new EventEmitter<void>();

  public closeProtocolAfterSend = false;

  constructor(private modalController: ModalController, private alertController: AlertController, private translateService: TranslateService,
              private toastService: ToastService, private photoService: PhotoService) {
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.signers) {
      this.removeSignersWithSignatureNotInSigners();
      this.populateSignersWithSignatureChangeByCode();
    }
    if (changes.signersWithSignature) {
      this.populateSignersWithSignatureChangeByCode();
    }
  }

  ngOnDestroy(): void {
  }

  private assertNotReadonly() {
    if (this.readonly) {
      throw new Error('Unable to change anything in readonly mode.');
    }
  }

  public async sign(signer: Signer) {
    this.assertNotReadonly();

    const signerName = await this.showAlertName();
    if (!signerName) {
      return; // cancelled
    }

    const blob = this.photoService.createEmptyImage(SIGNATURE_WIDTH, SIGNATURE_HEIGHT);
    const attachmentUrl = URL.createObjectURL(blob);
    const modal = await this.modalController.create({
      component: SketchComponent,
      backdropDismiss: true,
      componentProps: {
        attachmentUrl,
        signature: true,
        onMarkingsChanged: async (markings: Nullish<string>) => {
          if (markings) {
            await this.addReplaceSignature(signer, signerName, blob, markings);
          } else {
            this.removeSignature(signer);
          }
        }
      }
    });
    await modal.present();
    modal.onDidDismiss().finally(() => URL.revokeObjectURL(attachmentUrl));
  }

  private async showAlertName(): Promise<string|undefined> {
    const alert = await this.alertController.create(
      {
        header: this.translateService.instant('pdfReport.signature.signerNameTitle'),
        inputs: [
          {type: 'text', id: 'signerName', name: 'signerName', min: 1, max: SIGNER_NAME_MAX_LENGTH, placeholder: this.translateService.instant('pdfReport.signature.signerName'), tabindex: 0}
        ],
        buttons: [
          {
            text: this.translateService.instant('cancel'),
            role: 'cancel'
          },
          {
            text: this.translateService.instant('okay'),
            role: 'ok'
          }]
      });
    await alert.present();
    const result = await alert.onDidDismiss();
    if (result.role !== 'ok') {
      return undefined;
    }
    const signerName = result.data?.values?.signerName;
    if (!signerName?.length) {
      await this.toastService.info('pdfReport.signature.signerNameMandatory');
      return undefined;
    }
    if (signerName.length > SIGNER_NAME_MAX_LENGTH) {
      signerName.subsring(0, SIGNER_NAME_MAX_LENGTH);
    }
    return signerName;
  }

  private addReplaceSignature(signer: Signer, signerName: string, blob: Blob, markings: string): SignerWithSignature {
    const attachment = this.photoService.createAttachmentBlob(blob, `${signer.code}.jpg`, markings);
    const signerWithSignature: SignerWithSignature = {
      ...signer, signerName, signature: attachment
    };
    const index = this.signersWithSignature.findIndex(findSigner(signer));
    if (index >= 0) {
      this.signersWithSignature[index] = signerWithSignature;
    } else {
      this.signersWithSignature.push(signerWithSignature);
    }
    this.signersWithSignatureUpdated();

    return signerWithSignature;
  }

  public removeSignature(signer: Signer): boolean {
    this.assertNotReadonly();
    const index = this.signersWithSignature.findIndex(findSigner(signer));
    if (index === -1) {
      return false;
    }
    this.signersWithSignature.splice(index, 1);
    this.signersWithSignatureUpdated();
    return true;
  }

  public hasSigned(signer: Signer): boolean {
    return this.signersWithSignature.some(findSigner(signer));
  }

  public hasAllSigned(): boolean {
    const signersNotSigned = this.signers.filter((signer) => !this.hasSigned(signer));
    return signersNotSigned.length === 0;
  }

  private removeSignersWithSignatureNotInSigners() {
    if (!this.signersWithSignature?.length) {
      return;
    }
    const filtered = this.signersWithSignature.filter((signerWithSignature) => this.signers.some(findSigner(signerWithSignature)));
    if (filtered.length !== this.signersWithSignature.length) {
      this.signersWithSignatureUpdated(filtered);
    }
  }

  private signersWithSignatureUpdated(updatedSignersWithSignature?: Array<SignerWithSignature>) {
    if (updatedSignersWithSignature) {
      this.signersWithSignature = updatedSignersWithSignature;
    }
    this.populateSignersWithSignatureChangeByCode();
    this.signersWithSignatureChange.emit(this.signersWithSignature);
    this.allSignedChange.emit(this.hasAllSigned());
  }

  private populateSignersWithSignatureChangeByCode() {
    this.signersWithSignatureChangeByCode = _.keyBy(this.signersWithSignature, 'code');
  }

  public checkCloseProtocol() {
    this.closeProtocolAfterSend = !this.closeProtocolAfterSend;
  }

  public triggerNext() {
    this.requestCloseEmitter.emit();
  }

}
