import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModalController, NavParams, Platform} from '@ionic/angular';
import {Address, Attachment, IdType, isAttachmentReport, LicenseType, Profile, Project, Protocol, UserPublic} from 'submodules/baumaster-v2-common';
import {ImageUriPipe} from '../../../pipes/image-uri.pipe';
import {PhotoService} from '../../../services/photo/photo.service';
import {fabric} from 'fabric';
import {Image} from 'fabric/fabric-impl';
import {SketchComponent} from '../sketch/sketch.component';
import {LoggingService} from '../../../services/common/logging.service';
import {UserPublicDataService} from '../../../services/data/user-public-data.service';
import {AddressDataService} from '../../../services/data/address-data.service';
import {ProfileDataService} from '../../../services/data/profile-data.service';
import {Observable, of, Subscription} from 'rxjs';
import {ProjectDataService} from '../../../services/data/project-data.service';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {AttachmentBlob} from '../../../model/attachments';
import {calculateOptimalDimension, CanvasDimension, enclose} from '../../../utils/canvas-utils';
import {AuthenticationService} from '../../../services/auth/authentication.service';
import _ from 'lodash';
import {getIsContentAvailable, isAttachmentBlob, isAudio, isChatAttachment, isCompressed, isExcel, isImage, isPdf, isPowerPoint, isVideo, isWord} from '../../../utils/attachment-utils';
import {SystemEventService} from '../../../services/event/system-event.service';
import {ProtocolEntryService} from '../../../services/protocol/protocol-entry.service';
import {ProtocolDataService} from '../../../services/data/protocol-data.service';
import {ProtocolEntryDataService} from '../../../services/data/protocol-entry-data.service';
import {mergeMap} from 'rxjs/operators';
import {PdfViewerComponent} from '../../pdf/pdf-viewer/pdf-viewer.component';
import {FeatureEnabledService} from 'src/app/services/feature/feature-enabled.service';
import {ToastService} from 'src/app/services/common/toast.service';
import {observableToPromise} from 'src/app/utils/async-utils';
import {UserService} from 'src/app/services/user/user.service';
import {LicenseService} from 'src/app/services/auth/license.service';
import * as Hammer from 'hammerjs';
import {PopoverService} from 'src/app/services/ui/popover.service';
import {Nullish} from '../../../model/nullish';
import {AlertService} from 'src/app/services/ui/alert.service';
import {v4} from 'uuid';
import {loadImageEnsureWidthHeight} from '../../../../../submodules/baumaster-v2-common/dist/planMarker/planMarkerCanvasUtils';
import {Navigation, Pagination} from 'swiper/modules';
import {SwiperContainer} from 'swiper/swiper-element';
import {NetworkStatusService} from '../../../services/common/network-status.service';

const LOG_SOURCE = 'AttachmentFullScreenViewerComponent';
const ZOOMSPEED = 1.12, MIN_SCALE = 1, MAX_SCALE = 24, DOUBLE_TAP_ZOOM = 4;
const PADDING_BOTTOM_ATTACHMENTS = 80;

interface PositionAxis {
  x: number;
  y: number;
}
export interface SlideViewParam {
  isImageFile: boolean;
  isAudioFile: boolean;
  isVideoFile: boolean;
  isPdfFile: boolean;
  isChatFile: boolean;
  isWordFile: boolean;
  isExcelFile: boolean;
  isPowerPointFile: boolean;
  isCompressedFile: boolean;
  isContentAvailable: boolean;
  isCanvasReady: boolean;
  isAttachmentProject: boolean;
  isAttachmentReport: boolean;
  loadingImageFailed: boolean;
  showPreview: boolean;
  isMediaLoaded: boolean;
  loadingMediaFailed: boolean;
}

@Component({
  selector: 'app-attachment-full-screen-viewer',
  templateUrl: './attachment-full-screen-viewer.component.html',
  styleUrls: ['./attachment-full-screen-viewer.component.scss'],
  providers: [TranslatePipe]
})

export class AttachmentFullScreenViewerComponent implements OnInit, OnDestroy {

  private modal: HTMLIonModalElement;
  readonly swiperModules = [Navigation, Pagination];
  readonly instanceId = v4();
  public selectedAttachment: Attachment | AttachmentBlob;
  public attachments: Array<Attachment | AttachmentBlob>;
  public attachmentsObservable: Observable<Array<Attachment | AttachmentBlob>>;
  public selectedProject: Project;
  public onMarkingsChanged: ((attachment: Attachment | AttachmentBlob, markings: Nullish<string>) => void) | undefined;
  public onAttachmentDeleted: ((attachment: Attachment | AttachmentBlob) => void) | undefined;
  @ViewChild('fullScreenSwiperContainer', {static: true}) swiper: ElementRef<SwiperContainer> | undefined;
  public currentSlideIndex: number;
  public userById: Observable<Record<IdType, UserPublic>>;
  public profileById: Observable<Record<IdType, Profile>>;
  public addressById: Observable<Record<IdType, Address>>;
  public profileByAttachedToUserId: Observable<Map<IdType, Profile>>;
  public cachedFullSizeImages: { [key in number]: { thumbnail: Promise<string|null|undefined>, image: Promise<string|null|undefined> } } = {};
  public slideOptions = {
    speed: 200
  };
  public showEntryLink = false;
  public ionSlideReachStart = false;
  public ionSlideReachEnd = false;
  public canvas: fabric.Canvas;
  public slideViewConfig: { [key in string]: SlideViewParam };
  public isSlidesReady = false;
  public changingAttachmentEnabled: boolean;
  public forceDisableDownload = false;
  public enabledForViewer = true;
  private authenticationSubscription: Subscription | undefined;
  private authenticatedUserId: IdType | undefined;
  private backButtonSubscription: Subscription | undefined;
  private attachmentsSubscription: Subscription | undefined;
  private currentProtocolEntryCarriedSubscription: Subscription | undefined;
  private isCarriedEntry: boolean;
  private originalProtocol: Protocol | undefined;
  private originalProtocolSubscription: Subscription | undefined;
  private objectUrls = new Array<string>();
  public onObjectUrlCreated: (objectUrl: string) => void;
  private slidesContainerElement: HTMLElement;
  private factor = 0;
  private scale = 1;
  private lastScale = 1;
  private currentX = 0;
  private currentY = 0;
  private currentZX = 0;
  private currentZY = 0;
  private lastX = 0;
  private lastY = 0;
  private canvasHammer: any;
  public canvasWidth: number;
  public canvasHeight: number;
  private pinchCenter: PositionAxis|null = null;
  private imageWidth: number|undefined;
  private imageHeight: number|undefined;
  private viewportWidth: number|undefined;
  private viewportHeight: number|undefined;
  private currentBackgroundImage: Image|undefined;
  online$: Observable<boolean|undefined>;

  constructor(public platform: Platform, private modalController: ModalController, private navParams: NavParams,
              private imageUriPipe: ImageUriPipe, private photoService: PhotoService,
              private loggingService: LoggingService, private toastService: ToastService,
              private userPublicDataService: UserPublicDataService,
              private profileDataService: ProfileDataService, private addressDataService: AddressDataService,
              private projectDataService: ProjectDataService,
              private authenticationService: AuthenticationService, private systemEventService: SystemEventService,
              private protocolEntryService: ProtocolEntryService, private protocolDataService: ProtocolDataService,
              private protocolEntryDataService: ProtocolEntryDataService,
              private translateService: TranslateService,
              private featureEnabledService: FeatureEnabledService,
              private userService: UserService,
              private licenseService: LicenseService,
              private altertService: AlertService,
              private popoverService: PopoverService,
              private networkStatusService: NetworkStatusService) {
    this.onObjectUrlCreated = (objectUrl) => this.addObjectUrl(objectUrl);
    this.online$ = this.networkStatusService.online$;
  }

  async dismiss() {
    await this.modal.dismiss();
  }

  async openPopoverMenu(event) {
    const result = await this.popoverService.openActions(event, [
      {
        role: 'edit',
        label: 'edit',
        icon: ['fal', 'pencil'],
        lookDisabled: this.onMarkingsChanged === undefined,
        permissions: {
          featureEnabled: false,
          forConnected: true,
          forLicenses: [LicenseType.VIEWER]
        }
      },
      {
        role: 'delete',
        label: 'delete',
        icon: ['fal', 'trash-alt'],
        lookDisabled: this.onAttachmentDeleted === undefined,
        permissions: {
          featureEnabled: false,
          forConnected: true,
          forLicenses: [LicenseType.VIEWER]
        }
      },
      {
        role: 'download',
        label: 'download',
        icon: ['fal', 'download'],
        disabled: this.forceDisableDownload
      },
      ...(this.selectedAttachment.markings ? [{
        role: 'download-with-markings' as const,
        label: 'download-with-markings',
        icon: ['fal', 'signature'] as [string, string],
        disabled: this.forceDisableDownload
      }] : [])
    ]);
    if (result !== 'backdrop' && result !== 'feature-disabled-connected' && result !== 'feature-disabled-license') {
      if (this.slideViewConfig[this.selectedAttachment.id].isVideoFile || this.slideViewConfig[this.selectedAttachment.id].isAudioFile) {
        this.stopPlayingMedia(this.selectedAttachment);
      }
      await this.handleMenuAction(result);
    }
  }

  async handleMenuAction(action: string) {
    switch (action) {
      case 'edit':
        if (this.onMarkingsChanged === undefined) {
          await this.toastService.infoWithMessageAndHeader('toast.disabled.header', 'toast.disabled.message');
        } else {
          await this.openEditMode();
        }
        break;
      case 'delete':
        if (this.onAttachmentDeleted === undefined) {
          await this.toastService.infoWithMessageAndHeader('toast.disabled.header', 'toast.disabled.message');
        } else {
          await this.deleteFile();
        }
        break;
      case 'download':
      case 'download-with-markings':
        if (this.forceDisableDownload) {
          await this.toastService.infoWithMessageAndHeader('toast.disabled.header', 'toast.disabled.message');
        } else {
          await this.downloadFile(action === 'download-with-markings');
        }
        break;
      default:
        throw new Error('Unsupported action: ' + action);
    }
  }

  async openEditMode() {
    if (!this.enabledForViewer) {
      if (!(await this.featureEnabledService.isFeatureEnabled(false, true, [LicenseType.VIEWER]))) {
        return;
      }
    }
    if (this.showEntryLink && !this.slideViewConfig[this.selectedAttachment.id]?.isAttachmentProject && !this.slideViewConfig[this.selectedAttachment.id]?.isAttachmentReport) {
      if (await this.confirmDialog('project_room.editAttachmentEntry', 'modal.goToEntry')) {
        await this.goToEntry(this.selectedAttachment.id);
      }
      return;
    } else if (this.showEntryLink && this.slideViewConfig[this.selectedAttachment.id]?.isAttachmentReport) {
      if (await this.confirmDialog('project_room.editAttachmentReport', 'modal.goToReport')) {
        await this.goToReport(this.selectedAttachment.id);
      }
      return;
    }
    const modal = await this.modalController.create({
      component: SketchComponent,
      backdropDismiss: false,
      cssClass: 'full-screen-sketch',
      componentProps: {
        attachmentUrl: await this.cachedFullSizeImages[this.selectedAttachment.id].image,
        markings: this.selectedAttachment.markings,
        onMarkingsChanged: (markings: Nullish<string>) => {
          this.onMarkingsChanged(this.selectedAttachment, markings);
          this.updateSlide();
        }
      }
    });
    return await modal.present();
  }

  async deleteFile() {
    if (!this.enabledForViewer) {
      if (!this.onAttachmentDeleted || !(await this.featureEnabledService.isFeatureEnabled(false, true, [LicenseType.VIEWER]))) {
        return;
      }
    }
    if (this.showEntryLink && !this.slideViewConfig[this.selectedAttachment.id]?.isAttachmentProject && !this.slideViewConfig[this.selectedAttachment.id]?.isAttachmentReport) {
      if (await this.confirmDialog('project_room.deleteAttachmentEntry', 'modal.goToEntry')) {
        await this.goToEntry(this.selectedAttachment.id);
      }
      return;
    } else if (this.showEntryLink && this.slideViewConfig[this.selectedAttachment.id]?.isAttachmentReport) {
      if (await this.confirmDialog('project_room.deleteAttachmentReport', 'modal.goToReport')) {
        await this.goToReport(this.selectedAttachment.id);
      }
      return;
    }
    const result = await this.altertService.confirm({
      header: this.translateService.instant('alert.deleteAttachment.header'),
      message: this.translateService.instant('alert.deleteAttachment.message'),
      confirmButton: {
        color: 'danger',
        fill: 'solid',
      },
      confirmLabel: 'delete',
      cancelButton: {
        fill: 'clear',
      },
      cancelLabel: 'cancel',
    });
    if (result) {
      this.performDeleteSelectedAttachment();
    }
  }

  private async confirmDialog(message: string, confirmLabel: string, cancelLabel = 'close', header?: string): Promise<boolean> {
    return await this.altertService.confirm(
      {
        header,
        message,
        confirmButton: {
          color: 'primary',
          fill: 'solid',
        },
        confirmLabel,
        cancelButton: {
          fill: 'clear',
        },
        cancelLabel
      });
  }

  private async performDeleteSelectedAttachment() {
    const attachmentToDelete = this.selectedAttachment;
    this.onAttachmentDeleted(attachmentToDelete);
    // TODO Closing the dialog after deleting is really just a quick and dirty fix. Attachments need to be changed to an Observable to implement it properly.
    // cachedFullSizeImages also needs to be implemented as an Observable
    await this.dismiss();
  }

  async downloadFile(withMarkings = false) {
    if (withMarkings) {
      const canvasWidthBefore = this.canvas.width;
      const canvasHeightBefore = this.canvas.height;
      const canvasZoomBefore = this.canvas.getZoom();
      this.canvas.setWidth(this.imageWidth);
      this.canvas.setHeight(this.imageHeight);
      this.canvas.setZoom(1);
      const objectUrl = this.canvas.toDataURL({
        format: 'jpeg'
      });
      const linkElement = document.createElement('a');
      linkElement.href = objectUrl;
      const filename = (this.selectedAttachment.fileName?.replace(/\.[a-zA-Z]+$/g, '.jpg')) ?? `${this.selectedAttachment.id}.jpg`;
      linkElement.setAttribute('download', filename);
      linkElement.click();
      linkElement.remove();
      this.canvas.setWidth(canvasWidthBefore);
      this.canvas.setHeight(canvasHeightBefore);
      this.canvas.setZoom(canvasZoomBefore);
      return;
    }
    await this.photoService.downloadAttachmentToDevice(this.selectedAttachment);
  }

  private resetCanvasIfPresent() {
    if (this.canvas) {
      this.scale = 1;
      this.updateLastScale();
      this.currentX = 0;
      this.currentY = 0;
      this.currentZX = 0;
      this.currentZY = 0;
      this.lastX = 0;
      this.lastY = 0;
      this.updateAllowTouchMove();
      this.canvasHammer.destroy();
      this.canvas.clear();
      this.canvas.removeListeners();
      this.canvas.dispose();
      this.canvas = null;
    }
  }

  async onSlideChanged(currentSlideIndex: number) {
    this.resetCanvasIfPresent();
    if (this.slideViewConfig[this.selectedAttachment.id]?.isVideoFile || this.slideViewConfig[this.selectedAttachment.id]?.isAudioFile) {
      this.stopPlayingMedia(this.selectedAttachment);
    }
    this.currentSlideIndex = currentSlideIndex ?? (this.swiper?.nativeElement ? this.swiper.nativeElement.swiper.activeIndex : 0);
    this.selectedAttachment = this.attachments[this.currentSlideIndex];
    this.changingAttachmentEnabled = this.isChangingAttachmentEnabled(this.selectedAttachment);
    this.updateSlideButtons();
    await this.updateSlide();
  }

  async initCanvas(attachment: Attachment | AttachmentBlob, imageUrl: string) {
    if (!this.cachedFullSizeImages[attachment.id]) {
      return;
    }
    let img: Image;
    try {
      const {image, imageScaled} = await loadImageEnsureWidthHeight(imageUrl,
        'width' in attachment ? attachment.width : undefined, 'width' in attachment ? attachment.height : undefined);
      img = image;
      if (imageScaled) {
        this.loggingService.warn(LOG_SOURCE, `initCanvas - attachment with imageUrl could not be loaded in full width/height`);
      }
      if (_.isEmpty(image.getElement())) {
        if (this.slideViewConfig[attachment.id]) {
          this.slideViewConfig[attachment.id].loadingImageFailed = true;
        }
      }
    } catch (error) {
      if (this.slideViewConfig[attachment.id]) {
        this.slideViewConfig[attachment.id].loadingImageFailed = true;
      }
      this.loggingService.error(LOG_SOURCE, error);
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - initCanvas', error?.userMessage + '-' + error?.message);
      return;
    }

    this.currentBackgroundImage = img;

    const sketchingContainerElement = document.getElementById('slides-container' + this.instanceId);
    if (!sketchingContainerElement) {
      return;
    }
    this.resetCanvasIfPresent();
    this.viewportWidth = sketchingContainerElement.offsetWidth;
    this.viewportHeight = sketchingContainerElement.offsetHeight - PADDING_BOTTOM_ATTACHMENTS;
    const canvasId = 'canvas-' + attachment.id;
    this.imageWidth = img.width;
    this.imageHeight = img.height;
    const canvasDimension: CanvasDimension = calculateOptimalDimension(this.imageWidth, this.imageHeight, this.viewportWidth, this.viewportHeight);
    this.canvasWidth = canvasDimension.width;
    this.canvasHeight = canvasDimension.height;
    this.factor = canvasDimension.zoomFactor;
    this.canvas = new fabric.Canvas(canvasId, {width: this.canvasWidth, height: this.canvasHeight });
    this.canvas.setZoom(this.factor);
    this.canvas.selection = false;
    if (attachment.markings && attachment.markings !== '') {
      let data = typeof attachment.markings === 'string' ? JSON.parse(attachment.markings) : attachment.markings;
      if (typeof data === 'string') {
        // markings are being stored as string in the database, even though is a jsonb datatype. This allows to work with both types of data.
        data = JSON.parse(data);
      }
      data.fabricData.backgroundImage = null;
      this.canvas.loadFromJSON(data.fabricData, this.canvas.requestRenderAll.bind(this.canvas));
    }
    this.canvas.setBackgroundImage(img, this.canvas.requestRenderAll.bind(this.canvas));
    this.preventCanvasObjectsFromBeingSelectedAndMovedAround();
    this.bindMouseWheel();
    this.bindTouchEvents();
    setTimeout(() => {
      if (this.canvas) {
        this.canvas.requestRenderAll();
        const canvasElement = document.getElementById(canvasId);
        if (canvasElement) {
          canvasElement.style.display = 'block';
          if (this.slideViewConfig[this.selectedAttachment.id]) {
            this.slideViewConfig[this.selectedAttachment.id].isCanvasReady = true;
          }
        }
      }
    });
  }

  async ngOnInit() {
    this.selectedAttachment = this.navParams.data.selectedAttachment;
    this.attachmentsObservable = this.navParams.data.attachmentsObservable;
    this.currentSlideIndex = this.navParams.data.index;
    this.onMarkingsChanged = this.navParams.data.onMarkingsChanged;
    this.onAttachmentDeleted = this.navParams.data.onAttachmentDeleted;
    if (this.navParams.data.forceDisableDownload !== undefined) {
      this.forceDisableDownload = this.navParams.data.forceDisableDownload;
    }
    this.userById = this.userPublicDataService.dataGroupedById;
    this.profileById = this.profileDataService.dataGroupedById;
    this.addressById = this.addressDataService.dataGroupedById;
    this.profileByAttachedToUserId = this.profileDataService.dataGroupedByAttachedToUserId;
    this.selectedProject = await this.projectDataService.getCurrentProject();
    if (this.navParams.data.navigateToEntry) {
      this.showEntryLink = true;
    }
    const ionModalElement = this.modal;
    ionModalElement.cssClass = 'sketch-modal';
    this.authenticationSubscription = this.authenticationService.authenticatedUserId$.subscribe((authenticatedUserId) => this.authenticatedUserId = authenticatedUserId);
    this.backButtonSubscription = this.platform.backButton.subscribe(async () => {
      await this.dismiss();
      this.loggingService.debug(LOG_SOURCE, 'Dismissed attachment viewer modal.');
    });
    this.attachmentsSubscription = this.attachmentsObservable.subscribe(data => {
      this.attachments = data;
      this.initSlideViewConfig(data);
      this.isSlidesReady = true;
      if (this.swiper?.nativeElement) {
        setTimeout(async () => {
          await this.updateSlide();
          this.swiper.nativeElement.swiper.update();
        }, 0);
      }
    });
    this.currentProtocolEntryCarriedSubscription = await this.protocolEntryService.isCurrentProtocolEntryCarried()
      .subscribe((isCarriedEntry) => {
        this.isCarriedEntry = isCarriedEntry;
        this.changingAttachmentEnabled = this.isChangingAttachmentEnabled(this.selectedAttachment);
      });
    this.originalProtocolSubscription = this.protocolEntryDataService.getCurrentProtocolEntry().pipe(mergeMap((activeProtocolEntry) => {
      return activeProtocolEntry ? this.protocolDataService.getById(activeProtocolEntry.protocolEntry.createdInProtocolId ?? activeProtocolEntry.protocolEntry.protocolId) : of(undefined);
    })).subscribe((protocol) => {
      this.originalProtocol = protocol;
      this.changingAttachmentEnabled = this.isChangingAttachmentEnabled(this.selectedAttachment);
    });
  }

  async initSlideViewConfig(attachments: Array<Attachment | AttachmentBlob>) {
    this.slideViewConfig = {};
    attachments.forEach(attachment => {
      this.slideViewConfig[attachment.id] = {
        isImageFile: isImage(attachment),
        isContentAvailable: getIsContentAvailable(attachment),
        isAudioFile: isAudio(attachment),
        isVideoFile: isVideo(attachment),
        isChatFile: isChatAttachment(attachment),
        isPdfFile: isPdf(attachment),
        isWordFile: isWord(attachment),
        isExcelFile: isExcel(attachment),
        isPowerPointFile: isPowerPoint(attachment),
        isCompressedFile: isCompressed(attachment),
        isCanvasReady: false,
        isAttachmentProject: !_.has(attachment, 'protocolEntryId') && !_.has(attachment, 'reportCompanyId'),
        isAttachmentReport: isAttachmentReport(attachment),
        loadingImageFailed: false,
        showPreview: false,
        isMediaLoaded: false,
        loadingMediaFailed: false
      };
    });
    await this.updateSlideButtons();
  }

  async ionViewDidEnter() {
    setTimeout(async () => {
      if (!this.swiper?.nativeElement) {
        this.loggingService.warn(LOG_SOURCE, 'ionViewDidEnter - swiper not yet initialized');
        return;
      }
      this.updateSlideButtons();
      await this.updateSlide();
      this.swiper.nativeElement.swiper.update();
      this.slidesContainerElement = document.getElementById('slides-container' + this.instanceId);
    }, 0);

  }

  async loadAttachment(): Promise<string | null | undefined> {
    this.loggingService.debug(LOG_SOURCE, 'loadAttachment');
    const currentAttachmentId = this.selectedAttachment.id;
    try {
      if (this.cachedFullSizeImages[currentAttachmentId]?.image) {
        return this.cachedFullSizeImages[currentAttachmentId].image;
      }
      this.cachedFullSizeImages[currentAttachmentId] = {thumbnail: null, image: null};
      const cachedBlob = isAttachmentBlob(this.selectedAttachment) ? undefined : await this.photoService.getAttachmentFromCache(this.selectedAttachment, 'image');
      if (cachedBlob) {
        this.loggingService.debug(LOG_SOURCE, 'load attachment from cache response');
        this.cachedFullSizeImages[currentAttachmentId].image = new Promise<string>((resolve) => {
          if (cachedBlob === null) {
            resolve(null);
          }
          if (cachedBlob === undefined) {
            resolve(undefined);
          }
          const objectUrl = URL.createObjectURL(cachedBlob);
          this.objectUrls.push(objectUrl);
          resolve(objectUrl);
        });
      } else {
        this.loggingService.debug(LOG_SOURCE, 'load attachment from server');
        this.cachedFullSizeImages[currentAttachmentId].thumbnail = this.imageUriPipe.transform(this.selectedAttachment, 'thumbnail', this.onObjectUrlCreated);
        this.cachedFullSizeImages[currentAttachmentId].image = this.imageUriPipe.transform(this.selectedAttachment, 'image', this.onObjectUrlCreated);
      }
    } catch (error) {
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - loadImage', error?.userMessage + '-' + error?.message);
    }
    return this.cachedFullSizeImages[currentAttachmentId].image;
  }

  async slidePrev() {
    this.currentSlideIndex = Math.max(0, this.currentSlideIndex - 1);
    this.onSlideChanged(this.currentSlideIndex);
  }

  async slideNext() {
    this.currentSlideIndex = Math.min(this.attachments?.length ?? 0, this.currentSlideIndex + 1);
    this.onSlideChanged(this.currentSlideIndex);
  }

  public async showPdfPreview(attachment: Attachment) {
    let pdfBlob;
    if (isAttachmentBlob(attachment)) {
      pdfBlob = await this.photoService.getAttachmentFromCache(attachment, 'image');
    } else {
      pdfBlob = await this.photoService.downloadAttachment(attachment, 'image');
    }
    await this.openPreviewDialog(await pdfBlob.arrayBuffer(), pdfBlob);
  }

  private async openPreviewDialog(pdfArrayBuffer: ArrayBuffer, pdfBlob: Blob) {
    const modal = await this.modalController.create({
      component: PdfViewerComponent,
      cssClass: 'pdf-workflow-modal',
      componentProps: {pdfObjectUrl: pdfArrayBuffer}
    });
    await modal.present();
    modal.onDidDismiss().then(() => {
      pdfArrayBuffer = null;
      pdfBlob = null;
    });
  }

  private updateSlideButtons() {
    if (!this.attachments.length) {
      this.ionSlideReachEnd = false;
      this.ionSlideReachStart = false;
      return;
    }
    this.ionSlideReachEnd = this.currentSlideIndex === this.attachments.length - 1;
    this.ionSlideReachStart = this.currentSlideIndex === 0;
  }

  ngOnDestroy(): void {
    if (this.canvas) {
      this.canvas.setDimensions({width: 0, height: 0});
      this.canvas.clear();
      this.canvas.dispose();
      this.canvas = null;
    }
    if (this.authenticationSubscription) {
      this.authenticationSubscription.unsubscribe();
      this.authenticationSubscription = undefined;
    }
    this.unsubscribeBackButton();
    this.unsubscribeAttachments();
    this.currentProtocolEntryCarriedUnsubscribe();
    this.originalProtocolUnsubscribe();
    this.revokeObjectUrls();
  }

  private unsubscribeBackButton() {
    if (this.backButtonSubscription) {
      this.backButtonSubscription.unsubscribe();
      this.backButtonSubscription = undefined;
    }
  }

  private unsubscribeAttachments() {
    if (this.attachmentsSubscription) {
      this.attachmentsSubscription.unsubscribe();
      this.attachmentsSubscription = undefined;
    }
  }

  private currentProtocolEntryCarriedUnsubscribe() {
    if (this.currentProtocolEntryCarriedSubscription) {
      this.currentProtocolEntryCarriedSubscription.unsubscribe();
      this.currentProtocolEntryCarriedSubscription = undefined;
    }
  }

  private originalProtocolUnsubscribe() {
    if (this.originalProtocolSubscription) {
      this.originalProtocolSubscription.unsubscribe();
      this.originalProtocolSubscription = undefined;
    }
  }

  public isChangingAttachmentEnabled(attachment: Attachment | AttachmentBlob): boolean {
    if (this.isCarriedEntry) {
      const protocolClosedAt: Date = typeof this.originalProtocol?.closedAt === 'string' ? new Date(this.originalProtocol.closedAt) : this.originalProtocol?.closedAt;
      const attachmentCreatedAt: Date = typeof attachment.createdAt === 'string' ? new Date(attachment.createdAt) : attachment.createdAt;
      return attachment.hasOwnProperty('chatId') && protocolClosedAt < attachmentCreatedAt;
    }

    if (!attachment.hasOwnProperty('chatId') || !attachment.hasOwnProperty('createdById') || !_.get(attachment, 'createdById')) {
      return true;
    }
    return this.authenticatedUserId && this.authenticatedUserId === _.get(attachment, 'createdById');
  }

  public addObjectUrl(objectUrl: string) {
    this.loggingService.debug(LOG_SOURCE, 'objectUrlAdded');
    this.objectUrls.push(objectUrl);
  }

  private revokeObjectUrls() {
    if (this.objectUrls.length) {
      this.objectUrls.forEach((objectUrl) => URL.revokeObjectURL(objectUrl));
      this.objectUrls = [];
    }
  }

  private async updateSlide() {
    const attachmentUrl = await this.loadAttachment();
    if (attachmentUrl && this.slideViewConfig[this.selectedAttachment.id]?.isImageFile) {
      await this.initCanvas(this.selectedAttachment, attachmentUrl);
    }
    if (this.slideViewConfig[this.selectedAttachment.id]?.isVideoFile || this.slideViewConfig[this.selectedAttachment.id]?.isAudioFile) {
      this.loadMedia(this.selectedAttachment);
    }
    if ((await observableToPromise(this.licenseService.currentUserLicense$)) === LicenseType.VIEWER) {
      this.enabledForViewer = (await observableToPromise(this.userService.currentUser$)).id === this.selectedAttachment.createdById;
      if ((await observableToPromise(this.userService.currentUser$)).assignedReportRights && isAttachmentReport(this.selectedAttachment)) {
        this.enabledForViewer = true;
      }
    }
  }

  private loadMedia(attachment: Attachment | AttachmentBlob) {
    this.loggingService.debug(LOG_SOURCE, 'loading media file');
    const mediaElement = document.getElementById(attachment.id) as HTMLMediaElement;
    mediaElement.style.maxHeight = this.slidesContainerElement?.offsetHeight * 0.8 + 'px';
    mediaElement.style.maxWidth = this.slidesContainerElement?.offsetWidth * 0.8 + 'px';

    if (!this.slideViewConfig[attachment.id].isMediaLoaded) {
      mediaElement.load();
    }

    mediaElement.onloadeddata = () => {
      mediaElement.style.display = 'block';
      this.slideViewConfig[attachment.id].isMediaLoaded = true;
    };

    mediaElement.onloadstart = () => {
      // ios fix: onloadeddata event won't be fired in ios
      if (this.platform.is('ios')) {
        setTimeout(() => {
          if (!this.slideViewConfig[attachment.id].loadingMediaFailed) {
            mediaElement.style.display = 'block';
            this.slideViewConfig[attachment.id].isMediaLoaded = true;
          }
        }, 100);
      }
    };

    mediaElement.onerror = (error) => {
      this.loggingService.warn(LOG_SOURCE, `loading media file failed: id=${attachment.id} - mime type=${attachment.mimeType}, error=${error}`);
      mediaElement.style.display = 'none';
      this.slideViewConfig[attachment.id].loadingMediaFailed = true;
    };
  }

  private stopPlayingMedia(attachment: Attachment | AttachmentBlob) {
    const mediaElement: HTMLMediaElement = document.getElementById(attachment.id) as HTMLMediaElement;
    if (mediaElement && !mediaElement.paused) {
      mediaElement.pause();
    }
  }

  async goToEntry(attachmentId: IdType) {
    await this.navParams.data.navigateToEntry(attachmentId);
  }

  async goToReport(attachmentId: IdType) {
    await this.navParams.data.navigateToReport(attachmentId);
  }

  bindMouseWheel() {
    this.canvas.on('mouse:wheel', (event) => {
      const coord = this.getCoords(event);
      const delta = _.get(event, 'e.deltaY', 0);

      this.currentZX = (coord.x + this.currentX) / this.scale;
      this.currentZY = (coord.y + this.currentY) / this.scale;

      this.zoomAround((delta < 0 ? ZOOMSPEED : 1 / ZOOMSPEED), coord.x, coord.y);
      this.updateLastScale();
      this.updateLastPos();
    });
  }


  bindTouchEvents() {
    const upperElement = _.get(this.canvas, 'upperCanvasEl');
    this.canvasHammer = new Hammer.Manager(upperElement);
    this.canvasHammer.add([new Hammer.Pinch(), new Hammer.Tap({event: 'doubletap', taps: 2}), new Hammer.Pan()]);

    this.canvasHammer.on('pinch', (event) => {
      if (this.pinchCenter === null) {
        this.pinchCenter = this.rawCenter(event);
        this.currentZX = (this.pinchCenter.x + this.currentX) / this.scale;
        this.currentZY = (this.pinchCenter.y + this.currentY) / this.scale;
      }
      this.zoomAround(event.scale, this.pinchCenter.x, this.pinchCenter.y);
    });

    this.canvasHammer.on('pinchend', (event) => {
      this.updateLastScale();
      this.updateLastPos();
      this.pinchCenter = null;
    });

    this.canvasHammer.on('pan', (event) => {
      this.pointToCanvas(this.lastX - event.deltaX, this.lastY - event.deltaY);
      if (this.currentBackgroundImage) {
        const {dx, dy} = enclose(this.canvas, this.currentBackgroundImage);
        this.currentX -= dx;
        this.currentY -= dy;
      }
    });

    this.canvasHammer.on('panend', () => {
      this.updateLastPos();
    });

    this.canvasHammer.on('doubletap', (event) => {
      if (this.scale > 1) {
        const x = this.canvasWidth / 2;
        const y = this.canvasHeight / 2;
        this.currentZX = (x + this.currentX) / this.scale;
        this.currentZY = (y + this.currentY) / this.scale;
        this.zoomAround(1 / MAX_SCALE, x, y);
        this.updateLastScale();
        this.updateLastPos();
      } else {
        const coord = this.getCoords(event);
        this.currentZX = (coord.x + this.currentX) / this.scale;
        this.currentZY = (coord.y + this.currentY) / this.scale;
        this.zoomAround(DOUBLE_TAP_ZOOM, coord.x, coord.y);
        this.updateLastScale();
        this.updateLastPos();
      }
    });
  }

  rawCenter(event): PositionAxis {
    const canvasId = 'canvas-' + this.selectedAttachment?.id;
    const pos = document.getElementById(canvasId).getBoundingClientRect();
    const centerX = _.get(event, 'center.x', 0), centerY = _.get(event, 'center.y', 0);
    return {x: (centerX - pos.left), y: (centerY - pos.top)}

  }

  getCoords(event): PositionAxis {
    const canvasId = 'canvas-' + this.selectedAttachment?.id;
    const pos = document.getElementById(canvasId).getBoundingClientRect();
    const scrollLeft = window.pageXOffset ? window.pageXOffset : document.body.scrollLeft;
    const scrollTop = window.pageYOffset ? window.pageYOffset : document.body.scrollTop;
    let x, y;

    if (_.get(event, 'e.clientX', false)) {
      x = _.get(event, 'e.clientX', 0);
      y = _.get(event, 'e.clientY', 0);
    } else if (_.get(event, 'center', false)) {
      x = _.get(event, 'center.x', 0);
      y = _.get(event, 'center.y', 0);
    } else if ((_.get(event, 'e.touches', [])).length > 0) {
      x = _.get(event, 'touches[0].clientX', 0);
      y = _.get(event, 'touches[0].clientY', 0);
    } else {
      return;
    }

    return {x: (x - pos.left + scrollLeft), y: (y - pos.top + scrollTop)};
  }

  zoomAround(scaleBy: number, rawZoomX: number, rawZoomY: number) {
    this.scale = this.restrictScale(this.lastScale * scaleBy);
    this.updateAllowTouchMove();
    const newZoom = this.scale * this.factor;
    this.canvas.setZoom(newZoom);
    const newWidth = Math.min(this.canvasWidth * this.scale, this.viewportWidth);
    const newHeight = Math.min(this.canvasHeight * this.scale, this.viewportHeight);
    this.canvas.setWidth(newWidth);
    this.canvas.setHeight(newHeight);
    if (newWidth < this.viewportWidth && newHeight === this.viewportHeight) {
      this.pointToCanvas(0, ((this.currentZY * this.scale) - rawZoomY));
    } else if (newHeight < this.viewportHeight && newWidth === this.viewportWidth) {
      this.pointToCanvas(((this.currentZX * this.scale) - rawZoomX), 0);
    } else if (newWidth < this.viewportWidth && newHeight < this.viewportHeight) {
      this.pointToCanvas(0,0);
    } else {
      this.pointToCanvas(((this.currentZX * this.scale) - rawZoomX),((this.currentZY * this.scale) - rawZoomY));
    }
    if (this.currentBackgroundImage) {
      const {dx, dy} = enclose(this.canvas, this.currentBackgroundImage);
      this.currentX -= dx;
      this.currentY -= dy;
    }
    this.canvas.requestRenderAll();
  }

  restrictScale(scale: number): number {
    return this.clamp(scale, MIN_SCALE, MAX_SCALE);
  }

  async updateAllowTouchMove() {
    if (!this.swiper?.nativeElement) {
      return;
    }
    if (this.scale !== 1) {
      this.swiper.nativeElement.swiper.allowTouchMove = false;
    } else {
      this.swiper.nativeElement.swiper.allowTouchMove = true;
    }
  }

  clamp(num: number, min: number, max: number): number {
    return Math.max(min, Math.min(num, max));
  }

  pointToCanvas(tx, ty) {
    this.currentX = this.clamp(tx, 0, this.canvasWidth * this.scale - this.canvasWidth);
    this.currentY = this.clamp(ty, 0, this.canvasHeight * this.scale - this.canvasHeight);
    this.canvas.absolutePan(new fabric.Point(this.currentX, this.currentY));
  }

  updateLastPos() {
    this.lastX = this.currentX;
    this.lastY = this.currentY;
  }

  updateLastScale() {
    this.lastScale = this.scale;
  }

  private preventCanvasObjectsFromBeingSelectedAndMovedAround() {
    this.canvas.forEachObject((element) => {
      element.selectable = false;
      element.evented = false;
    });
  }

  zoom(zoomIn: boolean) {
    const x = this.canvasWidth / 2;
    const y = this.canvasHeight / 2;
    this.currentZX = (x + this.currentX) / this.scale;
    this.currentZY = (y + this.currentY) / this.scale;
    this.zoomAround(zoomIn ? ZOOMSPEED : 1 / ZOOMSPEED, x, y);
    this.updateLastScale();
    this.updateLastPos();
  }

  async retryDownload(attachmentId: IdType) {
    if (!this.cachedFullSizeImages[attachmentId]) {
      return;
    }
    delete this.cachedFullSizeImages[attachmentId];
    await this.loadAttachment();
  }

}
