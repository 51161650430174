<app-modal-header>
  <ion-title>
    {{ title }}
  </ion-title>

  <div class="sub-header d-flex flex-column gap-2">
    <ion-segment class="omg" [(ngModel)]="mode">
      <ion-segment-button value="PROJECT_TEAM">
        <ion-label>{{ 'project_room.pdf_plan_holders.history.segmentProjectTeam' | translate }}</ion-label>
      </ion-segment-button>
      <ion-segment-button value="HISTORY">
        <ion-label>{{ 'project_room.pdf_plan_holders.history.segmentHistory' | translate }}</ion-label>
      </ion-segment-button>
    </ion-segment>

    <div class="d-flex gap-1 full-width">
      <div class="half-in-flex pr-1">
        <ion-button expand="block" class="omg-btn-secondary versionButton" (click)="pdfPlanVersionSelectable._click()">
          <span class="versionButtonText one-line-ellipsis">
            {{ 'project_room.pdf_plan_holders.history.versionButton' | translate:{version: selectedVersionSubject.value?.name + (selectedVersionSubject.value?.index ? ' | ' + selectedVersionSubject.value.index + ' | ' : ' | ') + (selectedVersionSubject.value?.createdAt | date: 'dd.MM.yyyy')} }}
          </span>
          <fa-icon slot="end" [icon]="['fal', 'caret-down']"></fa-icon>
          <ionic-selectable #pdfPlanVersionSelectable
            [hidden]="true"
            item-content
            class="text-primary ion-hide"
            [items]="planVersions$ | async"
            [ngModel]="selectedVersionSubject.value"
            (onChange)="onVersionChange($event)"
            itemValueField="id"
            itemTextField="name"
            searchPlaceholder="{{'search' | translate}}"
            searchFailText="{{'noItemsFound' | translate}}"
            [canSearch]="true"
            appSelectableCommon
            #fs="appSelectableCommon"
            [shouldFocusSearchbar]="fs.isDesktop">
            <ng-template ionicSelectableItemIconTemplate let-selected="isItemSelected">
              <app-selectable-item-icon [selected]="selected"></app-selectable-item-icon>
            </ng-template>
            <ng-template ionicSelectableItemTemplate let-item="item">
              <span class="one-line-ellipsis">{{ item.name + (item?.index ? ' | ' + item.index + ' | ' : ' | ') + (item.createdAt | date: 'dd.MM.yyyy') }}</span>
            </ng-template>
            <ng-template ionicSelectableHeaderTemplate>
              <app-selectable-header [title]="'project_room.pdf_plan_holders.history.versionPickerTitle' | translate"></app-selectable-header>
            </ng-template>
            <ng-template ionicSelectableFooterTemplate>
              <app-selectable-footer></app-selectable-footer>
            </ng-template>
          </ionic-selectable>
        </ion-button>
      </div>
      <div *ngIf="mode === 'PROJECT_TEAM'" class="half-in-flex pr-1">
        <ion-searchbar #autofocus [(ngModel)]="searchText" (ngModelChange)="searchTextChanged()" debounce="200" class="slim-searchbar" [placeholder]="'project_room.pdf_plan_holders.history.searchPlaceholder' | translate"></ion-searchbar>
      </div>
    </div>
  </div>
</app-modal-header>

<ion-content>
  <div *ngIf="mode === 'PROJECT_TEAM'">
    <ion-grid class="table ion-hide-md-down">
      <ion-row class="table-header text-grey">
        <ion-col class="table-col" (click)="changeSortOrder('companyName')" size="3">
          <b>{{ "project_room.pdf_plan_holders.history.columns.company" | translate }}
          <fa-icon *ngIf="sortType === 'companyName'" class="pl-1" size="sm" [icon]="sortMode === 'asc' ? ['fal', 'long-arrow-up'] : ['fal', 'long-arrow-down']"></fa-icon></b>
        </ion-col>
        <ion-col class="table-col" (click)="changeSortOrder('personName')" size="3"><b>
          {{ "project_room.pdf_plan_holders.history.columns.person" | translate }}
          <fa-icon *ngIf="sortType === 'personName'" class="pl-1" size="sm" [icon]="sortMode === 'asc' ? ['fal', 'long-arrow-up'] : ['fal', 'long-arrow-down']"></fa-icon></b>
        </ion-col>
        <ion-col class="table-col" (click)="changeSortOrder('sentAt')" size="2">
          <b>{{ "project_room.pdf_plan_holders.history.columns.sentAt" | translate }}<br>{{ "project_room.pdf_plan_holders.history.columns.sentAtInfo" | translate }}
          <fa-icon *ngIf="sortType === 'sentAt'" class="pl-1" size="sm" [icon]="sortMode === 'asc' ? ['fal', 'long-arrow-up'] : ['fal', 'long-arrow-down']"></fa-icon></b>
        </ion-col>
        <ion-col class="table-col" (click)="changeSortOrder('downloadedAt')" size="2">
          <b>{{ "project_room.pdf_plan_holders.history.columns.downloadedAt" | translate }}<br>{{ "project_room.pdf_plan_holders.history.columns.downloadedAtInfo" | translate }}
          <fa-icon *ngIf="sortType === 'downloadedAt'" class="pl-1" size="sm" [icon]="sortMode === 'asc' ? ['fal', 'long-arrow-up'] : ['fal', 'long-arrow-down']"></fa-icon></b>
        </ion-col>
        <ion-col class="table-col" (click)="changeSortOrder('viewedAt')" size="2">
          <b>{{ "project_room.pdf_plan_holders.history.columns.viewedAt" | translate }}<br>{{ "project_room.pdf_plan_holders.history.columns.viewedAtInfo" | translate }}
          <fa-icon *ngIf="sortType === 'viewedAt'" class="pl-1" size="sm" [icon]="sortMode === 'asc' ? ['fal', 'long-arrow-up'] : ['fal', 'long-arrow-down']"></fa-icon></b>
        </ion-col>
      </ion-row>
  
      <ng-container *ngFor="let accessData of filteredVersionAccessData; let odd = odd; let outerIndex = index; trackBy: trackByProfile">
        <ion-row class="table-row" [class.odd]="odd">
          <ion-col size="3" class="one-line-ellipsis table-col" appEllipsisTooltip>{{ accessData?.companyName }}</ion-col>
          <ion-col size="3" class="one-line-ellipsis table-col" appEllipsisTooltip>{{ accessData?.personName }}<fa-icon class="pl-1" [icon]="['fal','link']" *ngIf="accessData.connectedUser"></fa-icon></ion-col>
          <ion-col class="table-col" size="2">
            <span [id]="'sentAt' + outerIndex + index" *ngFor="let sentAtAndEmail of accessData?.sentAtAndEmail; let index = index">
              {{ sentAtAndEmail.sentAt | date: 'dd.MM.yyyy' }}<br>
              <app-tooltip *ngIf="sentAtAndEmail?.email" [target]="'sentAt' + outerIndex + index" [placement]="'top'">
                {{ sentAtAndEmail.email }}
              </app-tooltip>
            </span>

          </ion-col>
          <ion-col class="table-col" size="2">
            {{ accessData?.downloadedAt | date: 'dd.MM.yyyy' }}
            <fa-icon *ngIf="accessData?.downloadedVia === 'email'" [icon]="['fal', 'envelope']"></fa-icon>
            <fa-icon *ngIf="accessData?.downloadedVia === 'app'" [icon]="['fal', 'download']"></fa-icon>
          </ion-col>
          <ion-col class="table-col" size="2">
            {{ accessData?.viewedAt | date: 'dd.MM.yyyy' }}
            <fa-icon *ngIf="accessData?.viewedAt" size="sm" [icon]="['fal', 'eye']"></fa-icon>
          </ion-col>
        </ion-row>
      </ng-container>
    </ion-grid>
  </div>

  <div *ngIf="mode === 'PROJECT_TEAM'" class="ion-hide-md-up">
    <h2 class="ion-text-center">
      {{ 'project_room.pdf_plan_holders.history.screenTooSmall' | translate }}
    </h2>
  </div>
  <div *ngIf="mode === 'HISTORY'">
    <ion-grid *ngIf="distributionsGroupedByWeek?.length > 0" class="ion-no-padding table">
      <ion-row *ngFor="let group of distributionsGroupedByWeek; let index = index;">
        <ion-col size="12" class="ion-no-padding heading-container">
            <div class="omg-modal-header">
              <span [id]="'cw-heading' + index" class="text-grey">{{ ('project_room.pdf_plan_holders.history.calendarWeek' | translate) + ' ' + group.key + ' / ' + (group.value[0].createdAt | date: 'yyyy')}}</span>
              <app-tooltip [target]="'cw-heading' + index" [placement]="'right'">
                {{ getWeekRangeFormatted(group.value[0]) }}
              </app-tooltip>
            </div>
        </ion-col>
        <ion-col size="12" *ngFor="let sentEvent of group.value">
          <div class="history-event-container">
            <div class="icon-container">
              <fa-icon [icon]="['fal', 'envelope']"></fa-icon>
            </div>
            <div class="text-container flex-grow-1">
              <span class="text-primary">{{ 'project_room.pdf_plan_holders.history.emailEvent' | translate }}</span>
              <span *ngIf="sentEvent?.creatorName" class="text-grey small-text">{{ sentEvent.creatorName }}</span>
              <span class="text-grey small-text">{{ sentEvent.createdAt | date: 'dd.MM.yyyy HH:mm' }}</span>
            </div>
            <ion-button size="small" class="omg-btn-secondary pl-4" (click)="openEmailDetails(sentEvent)">
              <fa-icon [icon]="['fal', 'envelope']" slot="start"></fa-icon>
              <span class="ion-hide-sm-down">{{ 'project_room.pdf_plan_holders.history.showMail' | translate }}</span>
            </ion-button>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
    <div *ngIf="!(distributionsGroupedByWeek?.length > 0)" class="d-flex ion-justify-content-center ion-align-items-center empty-info-container">
      <div class="brian-img">
        <img width src="/assets/images/brian_hello.png"/>
      </div>
      <div size="12" class="omg-t-header-1-bold ion-text-center">
        {{ "project_room.pdf_plan_holders.history.emptyTitle" | translate }}
      </div>
      <div size="12" class="omg-f-italic grey-text omg-t-body ion-text-center max-width-text">
        {{ "project_room.pdf_plan_holders.history.emptyText" | translate }}
      </div>
    </div>
  </div>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" (click)="dismiss()">{{ "close" | translate }}</ion-button>
    <ion-button *ngIf="isDesktop && mode === 'PROJECT_TEAM'" class="omg-btn-secondary" (click)="exportDataAsCsv()">{{ "project_room.pdf_plan_holders.history.csvButton" | translate }}</ion-button>
  </ion-buttons>
</app-modal-footer>
