import {Injectable} from '@angular/core';
import {SwUpdate, VersionReadyEvent} from '@angular/service-worker';
import {AlertController, ModalController, Platform} from '@ionic/angular';
import {interval, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {LoggingService} from './logging.service';
import {filter} from 'rxjs/operators';
import { UpdateModalComponent } from 'src/app/components/common/update-popover/update-modal.component';

const LOG_SOURCE = 'PwaService';
const CHECK_FOR_UPDATES_INTERVAL_IN_MS = 30 * 60 * 1000;

@Injectable({
  providedIn: 'root'
})
export class PwaService {
  private checkForUpdatesSubscription: Subscription|undefined;
  private updatesAvailableSubscription: Subscription|undefined;
  private updatesUnrecoverableSubscription: Subscription|undefined;
  private updateModal: HTMLIonModalElement|undefined;

  constructor(private platform: Platform, private alertCtrl: AlertController, private updates: SwUpdate, private translateService: TranslateService,
              private loggingService: LoggingService, private modalController: ModalController) {
  }

  public async startCheckForUpdates() {
    if (this.platform.is('capacitor')) {
      this.loggingService.info(LOG_SOURCE, 'No need to check for updates on a native app.');
      return;
    }
    this.loggingService.info(LOG_SOURCE, 'startCheckForUpdates');
    this.checkForUpdatesSubscription?.unsubscribe();
    this.checkForUpdatesSubscription = interval(CHECK_FOR_UPDATES_INTERVAL_IN_MS).subscribe(() => {
      this.loggingService.info(LOG_SOURCE, 'checking for updates.');
      this.updates.checkForUpdate();
      this.loggingService.info(LOG_SOURCE, 'checking for updates complete.');
    });
    await this.updates.checkForUpdate();
  }

  public stopCheckForUpdates() {
    this.loggingService.info(LOG_SOURCE, 'stopCheckForUpdates');
    this.checkForUpdatesSubscription?.unsubscribe();
  }

  /**
   * If a new version of the app is available (finished downloading in the background), the user is prompted for an instant refresh of the page.
   * For more information see: https://angular.io/guide/service-worker-communications
   */
  public refreshIfNewVersion() {
    if (this.platform.is('capacitor')) {
      return; // Refresh not applicable for native apps.
    }
    this.updatesAvailableSubscription?.unsubscribe();
    this.updatesAvailableSubscription = this.updates.versionUpdates.pipe(filter((event): event is VersionReadyEvent => event.type === 'VERSION_READY')).subscribe(async (event) => {
      const refresh = await this.showRefreshAfterUpdateModal();
      if (refresh) {
        await this.updates.activateUpdate();
        document.location.reload();
      }
    });
    this.updatesUnrecoverableSubscription?.unsubscribe();
    this.updatesUnrecoverableSubscription = this.updates.unrecoverable.subscribe(event => {
      document.location.reload();
    });
  }

  private async showRefreshAfterUpdateModal(): Promise<boolean> {
    if (!this.updateModal) {
      await this.spawnRefreshAfterUpdateModal();
    }
  
    return (await this.updateModal.onWillDismiss()).role === 'update';
  }

  private async spawnRefreshAfterUpdateModal(): Promise<void> {
    if (this.updateModal) {
      return;
    }
  
    const modal = await this.modalController.create({
      component: UpdateModalComponent,
      backdropDismiss: false,
      cssClass: 'update-modal',
      componentProps: {
        isPwa: true
      }
    });
  
    if (this.updateModal) {
      modal.remove();
      return;
    }
    this.updateModal = modal;
    await modal.present();
  }
}
