import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {filter, map, startWith, takeUntil} from 'rxjs/operators';
import {SplitPanePageService} from 'src/app/services/common/split-pane-page.service';
import {ProtocolTypeDataService} from 'src/app/services/data/protocol-type-data.service';
import {Breakpoints, DeviceService} from 'src/app/services/ui/device.service';
import {IdType} from 'submodules/baumaster-v2-common';
import {PdfPlanFolderDataService} from '../../services/data/pdf-plan-folder-data.service';
import {PdfPlanDataService} from '../../services/data/pdf-plan-data.service';
import {ReportService} from '../../services/report/report.service';
import {combineLatestAsync, observableToPromise} from '../../utils/async-utils';
import {ALL_COLUMNS_WIDTH_PX, ALL_COLUMNS_WIDTH_WITH_PINNED_MENU_PX} from './variables/breakpoints';
import {IonSearchbar, Platform} from '@ionic/angular';
import {PdfPlanHolderEditModeService} from 'src/app/services/project-room/pdf-plan-holder-edit-mode.service';
import {PdfPlanVersionDataService} from '../../services/data/pdf-plan-version-data.service';
import {ALL_FOLDERS_PAGE_SLUG, DATE_FORMAT} from 'src/app/shared/constants';
import {PlanAnalyticsService} from 'src/app/services/project-room/plan-analytics.service';
import {BimVersionDataService} from 'src/app/services/data/bim-version-data.service';
import {DatePipe} from '@angular/common';
import {SideBarSettingsService} from 'src/app/services/sidebar/sidebar-settings.service';

const URL_PART_PDF_PLAN_FOLDERS = '/project-room/pdf-plan-folders/';
const URL_PART_BIM_VERSION = '/project-room/bim-version/';
const URL_PART_BIM_MIGRATE_VERSION = '/project-room/bim-migrate-version/';
const URL_PART_PDF_PLAN_FOLDER_LIST = '/project-room/pdf-plan-folders';
const URL_PART_PDF_PLAN_MARKER = '/project-room/pdf-plan-markers/';
const URL_PART_PDF_PROTOCOLS = '/project-room/pdf-protocols/';
const URL_PART_PDF_REPORTS = '/project-room/pdf-reports/';
const URL_PART_BIM = '/project-room/bim';

@Component({
  selector: 'app-project-room',
  templateUrl: './project-room.page.html',
  styleUrls: ['./project-room.page.scss'],
  providers: [SplitPanePageService, DatePipe]
})
export class ProjectRoomPage implements OnInit, OnDestroy {
  @ViewChild('autofocus', { static: false }) searchbar: IonSearchbar;

  private destroy$ = new Subject<void>();

  title = 'project_room.title';
  defaultPage = 'project-room';
  search = '';

  get isSelector() {
    return this.router.url === '/project-room';
  }

  get isPdfPlanFolderPage() {
    return this.router.url.startsWith(URL_PART_PDF_PLAN_FOLDERS);
  }

  get isBimVersionPage() {
    return this.router.url.startsWith(URL_PART_BIM_VERSION);
  }

  get isBimMigrateVersionPage() {
    return this.router.url.startsWith(URL_PART_BIM_MIGRATE_VERSION);
  }

  get isPdfPlanFolderListPage() {
    return this.router.url.startsWith(URL_PART_PDF_PLAN_FOLDER_LIST) && !this.isPdfPlanFolderPage;
  }

  get isPdfPlanMarkerPage() {
    return this.router.url.startsWith(URL_PART_PDF_PLAN_MARKER);
  }

  get isPdfProtocolsPage() {
    return this.router.url.startsWith(URL_PART_PDF_PROTOCOLS);
  }

  get isPdfReportsPage() {
    return this.router.url.startsWith(URL_PART_PDF_REPORTS);
  }

  get isBimPage() {
    return this.router.url.startsWith(URL_PART_BIM);
  }

  get isProjectAttachmentsPage() {
    return this.router.url.startsWith('/project-room/attachments');
  }

  get isSubPage() {
    return this.isPdfPlanMarkerPage || this.isPdfProtocolsPage;
  }

  get isPageWithoutSearchbar() {
    return this.isSelector || this.isPdfPlanFolderListPage || this.isPdfPlanMarkerPage || this.isProjectAttachmentsPage;
  }

  isAbovePdfPlan$ = combineLatestAsync([
    this.sideBarSettingsService.sideBarSetting$,
    this.deviceService.isAboveMediaQuery(`(min-width: ${ALL_COLUMNS_WIDTH_WITH_PINNED_MENU_PX}px)`),
    this.deviceService.isAboveMediaQuery(`(min-width: ${ALL_COLUMNS_WIDTH_PX}px)`)
  ]).pipe(map(([sideBarSetting, isAbovePdfPlanAndPinned, isAbovePdfPlanNotPinned]) => sideBarSetting.pinned ? isAbovePdfPlanAndPinned : isAbovePdfPlanNotPinned));

  isSplitScreenDisabled$ = combineLatestAsync([
    this.isAbovePdfPlan$,
    this.deviceService.isAboveBreakpoint(Breakpoints.xl),
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      startWith(undefined)
    )
  ]).pipe(map(([isAbovePdfPlan, isAboveXl]) => (!isAbovePdfPlan && this.isPdfPlanFolderPage) || this.isBimVersionPage || (!isAboveXl && this.isBimPage) || this.isBimMigrateVersionPage));

  isPdfPlanHolderEditMode$ = this.pdfPlanHolderEditModeService.editMode$;

  constructor(private router: Router,
              private route: ActivatedRoute,
              public splitPaneService: SplitPanePageService,
              private translateService: TranslateService,
              private pdfPlanFolderDataService: PdfPlanFolderDataService,
              private pdfPlanDataService: PdfPlanDataService,
              private pdfPlanVersionDataService: PdfPlanVersionDataService,
              private protocolTypeDataService: ProtocolTypeDataService,
              private reportService: ReportService,
              private platform: Platform,
              private deviceService: DeviceService,
              private pdfPlanHolderEditModeService: PdfPlanHolderEditModeService,
              private planAnalyticsService: PlanAnalyticsService,
              private bimVersionDataService: BimVersionDataService,
              private datePipe: DatePipe,
              private sideBarSettingsService: SideBarSettingsService) {
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(
      (params) => {
        if (params.q !== this.search) {
          this.search = params.q;
        }
      }
    );
    this.router.events.pipe(takeUntil(this.destroy$), filter((event) => event instanceof NavigationEnd)).subscribe(
      async (event: NavigationEnd) => {
        if (!event.url.startsWith('/project-room')) {
          return;
        }
        if (this.isPdfPlanMarkerPage) {
          const {title, pdfPlanFolderId} = await this.getPdfPlanMarkerPageTitle();
          this.title = title;
          this.defaultPage = 'project-room/pdf-plan-folders/' + pdfPlanFolderId;
        } else if (this.isPdfPlanFolderPage) {
          this.title = await this.getPdfPlanFolderPageTitle();
          this.defaultPage = await observableToPromise(this.isAbovePdfPlan$) ? 'project-room' : 'project-room/pdf-plan-folders';
        } else if (this.isPdfProtocolsPage) {
          this.title = await this.getPdfProtocolsPageTitle();
          this.defaultPage = 'project-room/pdf-protocols';
        } else if (this.isPdfReportsPage) {
          this.title = await this.getPdfReportsPageTitle();
          this.defaultPage = 'project-room/pdf-reports';
        } else if (this.isBimVersionPage) {
          this.title = await this.getBimVersionPageTitle();
          this.defaultPage = 'project-room/bim';
        } else if (this.isBimMigrateVersionPage) {
          this.title = 'BIM';
          this.defaultPage = 'project-room/bim';
        } else if (this.isBimPage) {
          this.title = 'BIM';
          this.defaultPage = this.deviceService.isAboveBreakpoint(Breakpoints.xxl) ? 'project-room' :  'project-room/bim';
        }
        else if (!this.isSelector) {
          const pageSlug = event.url.split('/')[2].replace(/-/g, '_').split('?')[0];
          this.title = `project_room.${pageSlug}`;
          this.defaultPage = 'project-room';
        } else {
          this.title = 'project_room.title';
          this.defaultPage = 'project-room';
        }
      }
    );
  }

  ngOnInit() {
  }

  ionViewDidEnter() {
    if (this.platform.is('desktop')) {
      this.searchbar?.setFocus();
    }
  }

  handleSplitPaneChange(event: CustomEvent<{ visible: boolean }>) {
    if (event.currentTarget !== event.target) {
      return;
    }
    this.splitPaneService.handleSplitVisible(event);
  }

  handleSearchChange(event: CustomEvent<{ value: string }>) {
    if (this.isPdfPlanFolderPage || this.isPdfPlanFolderListPage) {
      this.planAnalyticsService.planSearchChanged(event.detail.value || '');
    }

    this.router.navigate([], {
      queryParams: {
        q: event.detail.value || undefined,
      },
      replaceUrl: this.search !== undefined,
    });
  }

  async getPdfPlanVersionIdFromPlanMarkerPage(): Promise<string|undefined> {
    const index = this.router.url.indexOf(URL_PART_PDF_PLAN_MARKER);
    if (index === -1) {
      return;
    }
    return this.router.url.substring(index + URL_PART_PDF_PLAN_MARKER.length).split('?')[0];
  }

  async getPdfProtocolsPageTitle(): Promise<string | undefined> {
    const index = this.router.url.indexOf(URL_PART_PDF_PROTOCOLS);
    if (index === -1) {
      return;
    }
    const protocolTypeId = this.router.url.substring(index + URL_PART_PDF_PROTOCOLS.length);
    const protocolType = await observableToPromise(this.protocolTypeDataService.getById(protocolTypeId));
    return this.translateService.instant('project_room.pdf_protocols_page.title', {protocolTypeName: protocolType.name});
  }

  async getPdfReportsPageTitle(): Promise<string | undefined> {
    const index = this.router.url.indexOf(URL_PART_PDF_REPORTS);
    if (index === -1) {
      return;
    }
    const reportTypeId = this.router.url.substring(index + URL_PART_PDF_REPORTS.length);
    const reportType = await observableToPromise(this.reportService.getReportTypeOrCustomTypeById(reportTypeId));
    return reportType?.name;
  }

  async getPdfPlanFolderPageTitle(): Promise<string | undefined> {
    const index = this.router.url.indexOf(URL_PART_PDF_PLAN_FOLDERS);
    if (index === -1) {
      return;
    }
    const pdfPlanFolderId = this.router.url.substring(index + URL_PART_PDF_PLAN_FOLDERS.length);
    if (pdfPlanFolderId === ALL_FOLDERS_PAGE_SLUG) {
      return this.translateService.instant('project_room.pdf_plan_folder.all_plans');
    }
    const pdfPlanFolder = await observableToPromise(this.pdfPlanFolderDataService.getById(pdfPlanFolderId));
    return this.translateService.instant('project_room.pdf_plan_holders.title', {pdfPlanFolderName: pdfPlanFolder.name});
  }

  async getPdfPlanMarkerPageTitle(): Promise<{title: string, pdfPlanFolderId: IdType} | undefined> {
    const pdfPlanVersionId = await this.getPdfPlanVersionIdFromPlanMarkerPage();
    const pdfPlanVersion = await observableToPromise(this.pdfPlanVersionDataService.getById(pdfPlanVersionId));
    const pdfPlanFolderId = `${(await observableToPromise(this.router.routerState.root.queryParams))?.wasOnAllPage}` === 'true'
      ? 'all'
      : (await observableToPromise(this.pdfPlanDataService.getById(pdfPlanVersion?.pdfPlanId)))?.folderId;

    return {
      title: this.translateService.instant('project_room.pdf_plan_marker.title', {pdfPlanHolderName: pdfPlanVersion?.name}),
      pdfPlanFolderId,
    };
  }

  async getBimVersionPageTitle(): Promise<string|undefined> {
    const index = this.router.url.indexOf(URL_PART_BIM_VERSION);
    if (index === -1) {
      return;
    }

    const bimVersionId = this.router.url.substring(index + URL_PART_BIM_VERSION.length);
    const bimVersion = await observableToPromise(this.bimVersionDataService.getById(bimVersionId));
    if (!bimVersion) {
      return;
    }

    return `${bimVersion.name} | ${this.datePipe.transform(bimVersion.createdAt, DATE_FORMAT)}`;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
