import {ColumnConfig} from '../ColumnConfig';
import {ActivityColumnSet} from './ActivityColumnSet';
import {ProjectProtocolLocationColumnSet} from './ProjectProtocolLocationColumnSet';
import {ProtocolEntryColumnSet} from './ProtocolEntryColumnSet';
import {PdfPlanVersionColumnSet} from './PdfPlanVersionColumnSet';

export const ProtocolEntryLocationColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'location'},
  {name: 'client_id', prop: 'clientId', cnd: true},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'is_active', prop: 'isActive', def: true},
  {name: 'activities', fk: 'locationId', mappedBy: ActivityColumnSet, transient: true},
  {name: 'projectProtocolLocations', fk: 'protocolentrylocationId', mappedBy: ProjectProtocolLocationColumnSet, transient: true},
  {name: 'protocolEntries', fk: 'locationId', mappedBy: ProtocolEntryColumnSet, transient: true},
  {name: 'pdfPlanVersions', fk: 'locationId', mappedBy: PdfPlanVersionColumnSet, transient: true},
];
