import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {UserDataService} from '../services/data/user-data.service';

@Injectable({
  providedIn: 'root'
})
export class ClientAdminGuard  {

  isClientAdmin$: Observable<boolean>;
  constructor(private userDataService: UserDataService, private router: Router) {
    this.isClientAdmin$ = this.userDataService.currentUser$.pipe(
      filter((user) => !!user),
      map(user => !!user.isClientAdmin)
    );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isClientAdmin$.pipe(map((isClientAdmin) => isClientAdmin ? true : this.router.parseUrl('/')));
  }

}
