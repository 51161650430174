import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-no-filtered-items',
  templateUrl: './no-filtered-items.component.html',
  styleUrls: ['./no-filtered-items.component.scss'],
  standalone: true,
  imports: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoFilteredItemsComponent {
  @Input()
  text: string;
}
