import {Injectable} from '@angular/core';
import {ENTRY_CARD_SETTINGS_DEFAULT} from '../../model/entry-card-model';
import {StorageKeyEnum} from '../../shared/constants';
import {BaseEntryCardSettingsService} from './base-entry-card-settings.service';

@Injectable({
  providedIn: 'root'
})
export class EntryCardSettingsService extends BaseEntryCardSettingsService {
  constructor() {
    super( StorageKeyEnum.ENTRY_CARD_SETTINGS, ENTRY_CARD_SETTINGS_DEFAULT);
  }

}
