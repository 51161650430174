import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bytes'
})
export class BytesPipe implements PipeTransform {

  intl: Intl.NumberFormat;

  constructor() {
    this.setLocale();
  }

  setLocale(locale?: string) {
    this.intl = new Intl.NumberFormat(locale, {
      maximumFractionDigits: 2,
      useGrouping: false,
    });
  }

  transform(value: number): string {
    if (value == null || isNaN(value)) {
      return '';
    }

    const suffixes = ['GB', 'MB', 'KB', 'B'];
    let currentSuffix = suffixes.pop();

    while (suffixes.length) {
      if (value < 1024) {
        return `${this.intl.format(value)} ${currentSuffix}`;
      }

      value /= 1024;
      currentSuffix = suffixes.pop();
    }

    return `${this.intl.format(value)} ${currentSuffix}`;
  }

}
