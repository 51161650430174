import {fromEvent, Observable, race} from 'rxjs';
import {map} from 'rxjs/operators';
import {AbortError} from '../shared/errors';

const createObservableFromAbortSignal = (abortSignal: AbortSignal) => fromEvent(abortSignal, 'abort').pipe(map(() => {
  throw new AbortError();
}));

export const abortableObservable = <T>(observable: Observable<T>, abortSignal?: AbortSignal) => {
  const observables = [
    observable,
    ...(abortSignal ? [createObservableFromAbortSignal(abortSignal)] : [])
  ];

  return race(...observables).pipe(map((result) => result));
};
