import {ColumnConfig} from '../ColumnConfig';
import {TwoFactorDeviceRecoveryColumnSet} from './TwoFactorDeviceRecoveryColumnSet';
import {TwoFactorDeviceTotpColumnSet} from './TwoFactorDeviceTotpColumnSet';

export const TwoFactorDeviceColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'name', prop: 'name'},
  {name: 'created_at', prop: 'createdAt', cast: 'Timestamp'},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'activated_at', prop: 'activatedAt', cast: 'Timestamp', def: null},
  {name: 'status', prop: 'status'},
  {name: 'activation_code', prop: 'activationCode', def: null},
  {name: 'user_id', prop: 'userId'},
  {name: 'method', prop: 'method'},
  {name: 'twoFactorDeviceTotps', fk: 'two_factor_device_id', mappedBy: TwoFactorDeviceTotpColumnSet, transient: true},
  {name: 'twoFactorDeviceRecoveries', fk: 'two_factor_device_id', mappedBy: TwoFactorDeviceRecoveryColumnSet, transient: true},
];
