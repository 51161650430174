import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ProtocolDataService } from 'src/app/services/data/protocol-data.service';
import { Protocol } from 'submodules/baumaster-v2-common';

@Component({
  selector: 'app-protocol-entries-list',
  templateUrl: './protocol-entries-list.page.html',
  styleUrls: ['./protocol-entries-list.page.scss'],
})
export class ProtocolEntriesListPage implements OnInit {

  protocol$: Observable<Protocol>;

  constructor(
    private route: ActivatedRoute,
    private protocolDataService: ProtocolDataService,
  ) {
    this.protocol$ = this.route.params.pipe(
      switchMap(({ protocolId }) => this.protocolDataService.getById(protocolId))
    );
  }

  ngOnInit() {
  }

}
