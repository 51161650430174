import {Injectable} from '@angular/core';
import {combineLatestAsync, observableToPromise} from 'src/app/utils/async-utils';
import {
  AttachmentChat,
  AttachmentProtocolEntry,
  IdAware,
  IdType,
  Protocol,
  ProtocolEntry,
  ProtocolEntryCompany,
  ProtocolEntryDefaultValue,
  ProtocolEntryIconStatus,
  ProtocolEntryType,
  ProtocolSortEntriesByEnum,
  ProtocolType
} from 'submodules/baumaster-v2-common';
import {ProtocolEntryDataService} from '../data/protocol-entry-data.service';
import _ from 'lodash';
import {ProtocolService} from './protocol.service';
import {ProtocolEntryTypeDataService} from '../data/protocol-entry-type-data.service';
import {combineLatest, Observable, of} from 'rxjs';
import {distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import {AttachmentEntryDataService} from '../data/attachment-entry-data.service';
import {AttachmentChatDataService} from '../data/attachment-chat-data.service';
import {ProtocolEntryOrOpen} from 'src/app/model/protocol';
import {ProtocolDataService} from '../data/protocol-data.service';
import {CompanyDataService} from '../data/company-data.service';
import {CraftDataService} from '../data/craft-data.service';
import {NameableDropdownDataService} from '../data/nameable-dropdown-data.service';
import {ProtocolEntryChatDataService} from '../data/protocol-entry-chat-data.service';
import {PdfPlanMarkerProtocolEntryDataService} from '../data/pdf-plan-marker-protocol-entry-data.service';
import {getProtocolEntryIconStatus} from '../../../../submodules/baumaster-v2-common/dist/planMarker/planMarkerCanvasUtils';
import {SelectedProtocolService} from './selected-protocol.service';
import {UntypedFormGroup} from '@angular/forms';
import {convertDateTimeToISOString, convertISOStringToDate, trimTimeFromDate} from 'src/app/utils/date-utils';
import {costStringToNumber} from 'src/app/utils/protocol-entry-utils';
import {ATTACHMENT_DEFAULT_SORT_COLUMNS, ATTACHMENT_DEFAULT_SORT_ORDER} from '../../shared/constants';
import {ProtocolEntryCompanyDataService} from '../data/protocol-entry-company-data.service';
import {PdfPlanPageMarkingDataService} from '../data/pdf-plan-page-marking-data.service';
import {haveObjectsEqualProperties} from 'src/app/utils/object-utils';
import {getProtocolEntryTotalHeight} from 'src/app/utils/protocol-entry-height-calculator';
import {ProjectCompanyDataService} from '../data/project-company-data.service';
import {ProtocolOpenEntryDataService} from '../data/protocol-open-entry-data.service';
import {BimMarkerDataService} from '../data/bim-marker-data.service';
import {AttachmentBimMarkerScreenshotDataService} from '../data/attachment-bim-marker-screenshot-data.service';
import {v4 as uuidv4} from 'uuid';

export interface ProtocolEntryHeight {
  id: IdType;
  height: number;
}

export interface ParentProtocolEntryHeight extends ProtocolEntryHeight {
  subEntriesHeight: ProtocolEntryHeight[];
}

@Injectable({
  providedIn: 'root'
})
export class ProtocolEntryService {

  constructor(private protocolService: ProtocolService,
              private protocolDataService: ProtocolDataService,
              private protocolEntryTypeDataService: ProtocolEntryTypeDataService,
              private protocolEntryDataService: ProtocolEntryDataService,
              private pdfPlanMarkerProtocolEntryDataService: PdfPlanMarkerProtocolEntryDataService,
              private protocolEntryChatDataService: ProtocolEntryChatDataService,
              private attachmentEntryDataService: AttachmentEntryDataService,
              private companyDataService: CompanyDataService,
              private projectCompanyDataService: ProjectCompanyDataService,
              private craftDataService: CraftDataService,
              private nameableDropdownDataService: NameableDropdownDataService,
              private attachmentChatDataService: AttachmentChatDataService,
              private selectedProtocolService: SelectedProtocolService,
              private protocolEntryCompanyDataService: ProtocolEntryCompanyDataService,
              private pdfPlanPageMarkingDataService: PdfPlanPageMarkingDataService,
              private protocolOpenEntryDataService: ProtocolOpenEntryDataService,
              private bimMarkerDataService: BimMarkerDataService,
              private attachmentBimMarkerScreenshotDataService: AttachmentBimMarkerScreenshotDataService,
              ) {
  }

  private async parseShortId(protocolEntry: ProtocolEntry, protocolType: ProtocolType|undefined, protocol: Protocol|undefined): Promise<string> {
    let shortId = protocolType?.code;
    shortId += _.padStart('' + (protocol?.number ?? ''), 2, '0');
    if (protocolEntry?.parentId) {
      const parentProtocolEntry: ProtocolEntry = await this.getProtocolEntry(protocolEntry?.parentId);
      shortId += '.' + _.padStart('' + (parentProtocolEntry?.number ?? ''), 3, '0');
    }
    shortId += '.' + _.padStart('' + (protocolEntry?.number ?? ''), 3, '0');
    return shortId;
  }

  async getShortId(protocolEntry: ProtocolEntry): Promise<string> {
    const protocolType: ProtocolType = await this.protocolService.getByProtocolTypeByProtocolId(protocolEntry?.protocolId);
    const protocol: Protocol = await this.protocolService.getProtocolById(protocolEntry?.protocolId);
    return this.parseShortId(protocolEntry, protocolType, protocol);
  }

  async getShortIdAcrossProjects(protocolEntry: ProtocolEntry): Promise<string> {
    const {protocol, protocolType} = await this.protocolService.getByProtocolTypeByProtocolIdAcrossProjects(protocolEntry?.protocolId);
    return this.parseShortId(protocolEntry, protocolType, protocol);
  }

  getShortIdAcrossProjects$(protocolEntry: ProtocolEntry): Observable<string> {
    return this.protocolService.getByProtocolTypeByProtocolIdAcrossProjects$(protocolEntry?.protocolId).pipe(
      switchMap(({protocol, protocolType}) => this.parseShortId(protocolEntry, protocolType, protocol))
    );
  }

  async getProtocolEntry(protocolEntryId: IdType): Promise<ProtocolEntry> {
    return observableToPromise(this.protocolEntryDataService.getByIdAcrossProjects(protocolEntryId));
  }

  getProtocolEntryIconStatusByEntry(protocolEntry: ProtocolEntry, protocolEntryType: ProtocolEntryType|undefined): ProtocolEntryIconStatus {
    return this.getIconStatus(protocolEntry, protocolEntryType);
  }

  async getProtocolEntryIconStatusByEntryId(protocolEntryId: IdType): Promise<ProtocolEntryIconStatus> {
    const protocolEntry: ProtocolEntry = await this.getProtocolEntry(protocolEntryId);
    const protocolEntryType: ProtocolEntryType = await this.getProtocolEntryTypeById(protocolEntry?.typeId);
    return this.getIconStatus(protocolEntry, protocolEntryType);
  }

  getProtocolEntryIconStatusByEntryId$(protocolEntryId: IdType): Observable<ProtocolEntryIconStatus> {
    return this.protocolEntryDataService.getByIdAcrossProjects(protocolEntryId).pipe(
      switchMap((protocolEntry) => this.protocolEntryTypeDataService.getByIdAcrossClients(protocolEntry?.typeId).pipe(
        map((protocolEntryType) => this.getIconStatus(protocolEntry, protocolEntryType))
      ))
    );
  }

  public getIconStatus(protocolEntry: ProtocolEntry|undefined, protocolEntryType: ProtocolEntryType | undefined): ProtocolEntryIconStatus {
    return getProtocolEntryIconStatus(protocolEntry, protocolEntryType);
  }

  async getProtocolEntryTypeById(protocolTypeId: IdType): Promise<ProtocolEntryType> {
    return observableToPromise(this.protocolEntryTypeDataService.getByIdAcrossClients(protocolTypeId));
  }

  public getEntryAndChatAttachmentsAcrossProjects(protocolEntryId: IdType): Observable<Array<AttachmentProtocolEntry | AttachmentChat>> {
    function mergeAttachments(attachmentProtocolEntries: Array<AttachmentProtocolEntry>, attachmentChats: Array<AttachmentChat>): Array<AttachmentProtocolEntry | AttachmentChat> {
      return _.orderBy(_.concat(attachmentProtocolEntries, attachmentChats), ATTACHMENT_DEFAULT_SORT_COLUMNS, ATTACHMENT_DEFAULT_SORT_ORDER) as Array<AttachmentProtocolEntry | AttachmentChat>;
    }

    return combineLatestAsync([
      this.attachmentEntryDataService.getByProtocolEntryAcrossProjects(protocolEntryId),
      this.attachmentChatDataService.getByProtocolEntryAcrossProjects(protocolEntryId)
    ]).pipe(map(([attachmentProtocolEntries, attachmentChats]) => mergeAttachments(attachmentProtocolEntries, attachmentChats)));

  }

  public getEntryAndChatAttachments(protocolEntryId: IdType): Observable<Array<AttachmentProtocolEntry | AttachmentChat>> {
    function mergeAttachments(attachmentProtocolEntries: Array<AttachmentProtocolEntry>, attachmentChats: Array<AttachmentChat>): Array<AttachmentProtocolEntry | AttachmentChat> {
      return _.orderBy(_.concat(attachmentProtocolEntries, attachmentChats), ATTACHMENT_DEFAULT_SORT_COLUMNS, ATTACHMENT_DEFAULT_SORT_ORDER) as Array<AttachmentProtocolEntry | AttachmentChat>;
    }

    return combineLatest([this.attachmentEntryDataService.getByProtocolEntry(protocolEntryId), this.attachmentChatDataService.getByProtocolEntry(protocolEntryId)])
      .pipe(map(([attachmentProtocolEntries, attachmentChats]) => mergeAttachments(attachmentProtocolEntries, attachmentChats)));

  }

  async sortProtocolEntriesByProtocolId(protocolEntries: ProtocolEntryOrOpen[], protocolId: IdType): Promise<ProtocolEntryOrOpen[]> {
    const protocol = await observableToPromise(this.protocolDataService.getById(protocolId));
    return this.sortProtocolEntriesByProtocolSortEntry(protocolEntries, protocol);
  }

  async sortProtocolEntriesByProtocolSortEntry(protocolEntries: ProtocolEntryOrOpen[], protocol: Protocol): Promise<ProtocolEntryOrOpen[]> {
    return await this.sortProtocolEntriesBySortEntryValue(protocolEntries, protocol.sortEntriesBy);
  }

  async sortProtocolEntriesBySortEntryValue(
    protocolEntries: ProtocolEntryOrOpen[],
    sortEntriesBy: string|null|undefined,
    {
      skipSortByOpen = false
    }: {skipSortByOpen?: boolean} = {}
  ): Promise<ProtocolEntryOrOpen[]> {
    const isOpenEntryComparer = (protocolEntryOrOpen: ProtocolEntryOrOpen) => protocolEntryOrOpen.isOpenEntry || Boolean(protocolEntryOrOpen.createdInProtocolId);
    const protocolNumberComparer = (protocols: Array<Protocol>): (value: any) => any => {
      const protocolsById: {[key in IdType]: Array<Protocol>} = _.groupBy(protocols, 'id');
      return (protocolEntry: ProtocolEntryOrOpen) => {
        const protocolId = protocolEntry.isOpenEntry ? protocolEntry.originalProtocolId : protocolEntry.protocolId;
        return protocolsById[protocolId]?.length ? protocolsById[protocolId][0].number :  undefined;
      };
    };
    const protocolCreatedAtComparer = (protocols: Array<Protocol>): (value: any) => any => {
      const protocolsById: {[key in IdType]: Array<Protocol>} = _.groupBy(protocols, 'id');
      return (protocolEntry: ProtocolEntryOrOpen) => {
        const protocolId = protocolEntry.isOpenEntry ? protocolEntry.originalProtocolId : protocolEntry.protocolId;
        return protocolsById[protocolEntry.protocolId]?.length ? convertISOStringToDate(protocolsById[protocolId][0].createdAt).getDate() :  undefined;
      };
    };
    const protocolEntryNumberComparer = (protocolEntry: ProtocolEntryOrOpen) => protocolEntry.number;
    const protocolEntryCreatedAtComparer = (protocolEntry: ProtocolEntryOrOpen) => convertISOStringToDate(protocolEntry.createdAt).getTime();
    const protocolEntryParentComparer = (protocolEntry: ProtocolEntryOrOpen) => !!protocolEntry.parentId;
    const customFieldComparer = <C extends IdAware>(lookupData: Array<C>, sortField: keyof C, protocolEntryField: 'companyId' | 'craftId' | 'nameableDropdownId'): (value: any) => any => {
      const lookupDataById = _.groupBy(lookupData, 'id');
      return (protocolEntry: ProtocolEntryOrOpen) => lookupDataById[protocolEntry[protocolEntryField]] ? lookupDataById[protocolEntry[protocolEntryField]][0][sortField] : undefined;
    };

    let sortedProtocolEntries: ProtocolEntryOrOpen[];
    const protocolList = await observableToPromise(this.protocolDataService.dataWithoutHidden$);
    switch (sortEntriesBy) {
      case ProtocolSortEntriesByEnum.COMPANY:
        const companies = await observableToPromise(this.companyDataService.data);
        const projectCompaniesById = await observableToPromise(this.projectCompanyDataService.dataByCompanyId$);
        const companyOrderComparer = (protocolEntry: ProtocolEntry) => {
          const projectCompany = projectCompaniesById?.[protocolEntry.companyId];

          if (protocolEntry.allCompanies) {
            return Infinity;
          }

          return projectCompany?.sortOrder;
        };
        sortedProtocolEntries = _.orderBy(protocolEntries, [...(skipSortByOpen ? [] : [isOpenEntryComparer]), companyOrderComparer,
                                                           customFieldComparer(companies, 'name', 'companyId'),
                                                           protocolNumberComparer(protocolList), protocolEntryParentComparer, protocolEntryNumberComparer],
                                                           ['desc', 'asc', 'asc', 'asc']);
        break;

      case ProtocolSortEntriesByEnum.CRAFT:
        const crafts = await observableToPromise(this.craftDataService.data);
        sortedProtocolEntries = _.orderBy(protocolEntries, [...(skipSortByOpen ? [] : [isOpenEntryComparer]),
                                                            customFieldComparer(crafts, 'name', 'craftId'),
                                                            protocolNumberComparer(protocolList),
                                                            protocolEntryParentComparer, protocolEntryNumberComparer],
                                                            ['desc', 'asc', 'asc', 'asc']);
        break;

      case ProtocolSortEntriesByEnum.NAMEABLE_DROPDOWN:
        const dropdowns = await observableToPromise(this.nameableDropdownDataService.data);
        sortedProtocolEntries = _.orderBy(protocolEntries, [...(skipSortByOpen ? [] : [isOpenEntryComparer]),
                                                            customFieldComparer(dropdowns, 'name', 'nameableDropdownId'),
                                                            protocolNumberComparer(protocolList), protocolEntryParentComparer, protocolEntryNumberComparer],
                                                            ['desc', 'asc', 'asc', 'asc']);
        break;

      case ProtocolSortEntriesByEnum.CREATED_AT_DATE:
        sortedProtocolEntries = _.orderBy(protocolEntries, [...(skipSortByOpen ? [] : [isOpenEntryComparer]), protocolCreatedAtComparer(protocolList), protocolNumberComparer(protocolList),
          protocolEntryParentComparer, protocolEntryCreatedAtComparer, protocolEntryNumberComparer], ['desc', 'asc', 'asc', 'asc', 'asc', 'asc']);
        break;


      default:
        sortedProtocolEntries = _.orderBy(protocolEntries, [...(skipSortByOpen ? [] : [isOpenEntryComparer]), protocolNumberComparer(protocolList),
                                                            protocolEntryParentComparer, protocolEntryNumberComparer], ['desc', 'asc', 'asc', 'asc']);
        break;
    }
    return sortedProtocolEntries;
  }

  public async assertProtocolEntryWithOpenEntriesDeletable(protocolEntry: ProtocolEntry, subEntries: ProtocolEntry[] | undefined): Promise<void> {
    const protocolOpenEntries = await observableToPromise(this.protocolOpenEntryDataService.getAllByProtocolEntryIds([
      protocolEntry.id,
      ...(subEntries?.map(({id}) => id) ?? [])
    ]));
    if (protocolOpenEntries.length) {
      const protocols = await observableToPromise(this.protocolDataService.getByIds(_.uniq([
        protocolEntry.createdInProtocolId ?? protocolEntry.protocolId,
        ...(subEntries?.map(({createdInProtocolId, protocolId}) => createdInProtocolId ?? protocolId) ?? [])
      ])));

      if (protocols.some((protocol) => !!protocol.closedAt)) {
        throw new Error(`Cannot delete protocol entry ${protocolEntry.id} with it's children ${subEntries?.map(({id}) => id) ?? []}, as at least one entry is a part of already closed protocol`);
      }
    }
  }

  public async deleteProtocolEntry(protocolEntry: ProtocolEntry, projectId: IdType): Promise<void> {
    const subEntries = await observableToPromise(this.protocolEntryDataService.getSubEntriesByParentEntryId(protocolEntry.id));
    await this.assertProtocolEntryWithOpenEntriesDeletable(protocolEntry, subEntries);
    if (subEntries?.length) {
      for (const subEntry of subEntries) {
        await this.deleteReferenceDataFromProtocolEntry(subEntry, projectId);
      }
      await this.protocolEntryDataService.delete(subEntries, projectId);
    }
    await this.deleteReferenceDataFromProtocolEntry(protocolEntry, projectId);
    await this.protocolEntryDataService.delete(protocolEntry, projectId);
  }

  private async deleteReferenceDataFromProtocolEntry(protocolEntry: ProtocolEntry, projectId: IdType): Promise<void> {
    const chatAttachments = await observableToPromise(this.attachmentChatDataService.getByProtocolEntry(protocolEntry.id));
    const comments = await observableToPromise(this.protocolEntryChatDataService.getByProtocolEntry(protocolEntry.id));
    const attachments = await observableToPromise(this.attachmentEntryDataService.getByProtocolEntry(protocolEntry.id));
    const markers = await observableToPromise(this.pdfPlanMarkerProtocolEntryDataService.getByProtocolEntry(protocolEntry.id));
    const bimMarkers = await observableToPromise(this.bimMarkerDataService.getByEntryId$(protocolEntry.id));
    const attachmentBimMarkerScreenshots = await observableToPromise(this.attachmentBimMarkerScreenshotDataService
      .getByBimMarkerIds$(bimMarkers.map((bimMarker) => bimMarker.id)));
    const protocolEntryCompanies = await observableToPromise(this.protocolEntryCompanyDataService.findAllByProtocolEntryId(protocolEntry.id));
    const pdfPlanPageMarkings = await observableToPromise(this.pdfPlanPageMarkingDataService.getByProtocolEntry(protocolEntry.id));
    const protocolOpenEntries = await observableToPromise(this.protocolOpenEntryDataService.getAllByProtocolEntryIds([protocolEntry.id]));
    if (protocolOpenEntries.length) {
      await this.protocolOpenEntryDataService.delete(protocolOpenEntries, projectId);
    }
    if (chatAttachments.length) {
      await this.attachmentChatDataService.delete(chatAttachments, projectId);
    }
    if (comments.length) {
      await this.protocolEntryChatDataService.delete(comments, projectId);
    }
    if (attachments.length) {
      await this.attachmentEntryDataService.delete(attachments, projectId);
    }
    if (markers.length) {
      await this.pdfPlanMarkerProtocolEntryDataService.delete(markers, projectId);
    }
    if (protocolEntryCompanies.length) {
      await this.protocolEntryCompanyDataService.delete(protocolEntryCompanies, projectId);
    }
    if (pdfPlanPageMarkings.length) {
      await this.pdfPlanPageMarkingDataService.delete(pdfPlanPageMarkings, projectId);
    }
    if (attachmentBimMarkerScreenshots.length) {
      await this.attachmentBimMarkerScreenshotDataService.delete(attachmentBimMarkerScreenshots, projectId);
    }
    if (bimMarkers.length) {
      await this.bimMarkerDataService.delete(bimMarkers, projectId);
    }
  }

  public isProtocolEntryClosed(protocolEntryId: IdType|undefined): Observable<boolean|undefined> {
    return this.protocolEntryDataService.getById(protocolEntryId).pipe(
      switchMap((entry) => this.protocolDataService.getById(entry?.createdInProtocolId ?? entry?.protocolId)),
      map((protocol) => protocol ? Boolean(protocol.closedAt) : undefined)
    );
  }

  public isCurrentProtocolEntryClosed(): Observable<boolean|undefined> {
    return this.protocolEntryDataService.getCurrentProtocolEntry().pipe(
      map((activeProtocolEntry) => {
        if (!activeProtocolEntry) {
          return undefined;
        }

        return activeProtocolEntry.protocolEntry.id;
      }),
      switchMap((id) => this.isProtocolEntryClosed(id))
    );
  }

  public isCurrentProtocolEntryCarried(): Observable<boolean|undefined> {
    return combineLatestAsync([this.protocolEntryDataService.getCurrentProtocolEntry().pipe(
      switchMap((activeProtocolEntry) => {
        if (!activeProtocolEntry) {
          return of(undefined as ProtocolEntry);
        }

        return this.protocolEntryDataService.getById(activeProtocolEntry.protocolEntry.id);
      })
    ), this.selectedProtocolService.getCurrentProtocol()])
      .pipe(map(([protocolEntry, currentProtocol]) => {
        if (!protocolEntry || !currentProtocol) {
          return undefined;
        }
        return currentProtocol.id !== (protocolEntry.createdInProtocolId ?? protocolEntry.protocolId);
      }
    ));
  }

  public async deleteProtocolEntries(protocolEntries: ProtocolEntry[], projectId: IdType): Promise<void> {
    const childrenProtocolEntries = protocolEntries.filter(entry => entry.parentId);
    const parentProtocolEntries = protocolEntries.filter(entry => !entry.parentId);

    // First remove all children entries...
    await Promise.all(childrenProtocolEntries.map(entry => this.deleteProtocolEntry(entry, projectId)));
    // ...because deleteProtocolEntry will remove all children
    // of the parent protocol entry
    await Promise.all(parentProtocolEntries.map(entry => this.deleteProtocolEntry(entry, projectId)));
  }

  public async editProtocolEntries(protocolEntries: ProtocolEntry[], protocolInput: Partial<ProtocolEntry>, projectId: IdType): Promise<void> {
    await this.protocolEntryDataService.update(protocolEntries.map((entry) => ({
      ...entry,
      ...protocolInput,
    })), projectId);
  }

  getPartialProtocolEntryFromFormGroup(form: UntypedFormGroup, nameableDropdownId: IdType): Partial<ProtocolEntry> {
    const protocolsInput: Partial<ProtocolEntry> = this.getPartialProtocolEntryFromRawData(form.getRawValue());

    protocolsInput.nameableDropdownId = nameableDropdownId;

    return protocolsInput;
  }

  getPartialProtocolEntryFromRawData(rawData: any): Partial<ProtocolEntry> {
    const protocolsInput: Partial<ProtocolEntry> = {};

    if (rawData.hasOwnProperty('company')) {
      protocolsInput.allCompanies = rawData.company?.id === null;
      protocolsInput.companyId = rawData.company?.id;
    }
    if (rawData.hasOwnProperty('title')) {
      protocolsInput.title = rawData.title;
    }
    if (rawData.hasOwnProperty('text')) {
      protocolsInput.text = rawData.text;
    }
    if (rawData.hasOwnProperty('internalAssignmentId')) {
      protocolsInput.internalAssignmentId = rawData.internalAssignmentId?.id;
    }
    if (rawData.hasOwnProperty('nameableDropdownId')) {
      protocolsInput.nameableDropdownId = rawData.nameableDropdownId;
    }
    if (rawData.hasOwnProperty('cost')) {
      protocolsInput.cost = costStringToNumber(rawData.cost);
    }
    if (rawData.hasOwnProperty('craft')) {
      protocolsInput.craftId = rawData.craft?.id;
    }
    if (rawData.hasOwnProperty('location')) {
      protocolsInput.locationId = rawData.location?.id;
    }
    if (rawData.hasOwnProperty('startDate')) {
      protocolsInput.startDate = convertDateTimeToISOString(trimTimeFromDate(rawData.startDate));
    }
    if (rawData.hasOwnProperty('todoUntil')) {
      protocolsInput.todoUntil = convertDateTimeToISOString(trimTimeFromDate(rawData.todoUntil));
    }
    if (rawData.hasOwnProperty('reminderAt')) {
      protocolsInput.reminderAt = convertDateTimeToISOString(rawData.reminderAt);
    }
    if (rawData.hasOwnProperty('type')) {
      protocolsInput.typeId = rawData.type?.id;
    }
    if (rawData.hasOwnProperty('status')) {
      protocolsInput.status = rawData.status;
    }
    if (rawData.hasOwnProperty('isContinuousInfo')) {
      protocolsInput.isContinuousInfo = rawData.isContinuousInfo;
    }
    if (rawData.hasOwnProperty('priority')) {
      protocolsInput.priority = rawData.priority;
    }
    if (rawData.hasOwnProperty('unit')) {
      protocolsInput.unitId = rawData.unit?.id;
    }

    if (rawData.createdAt) {
      protocolsInput.createdAt = convertDateTimeToISOString(rawData.createdAt);
    }

    return protocolsInput;
  }

  getProtocolEntriesHeights$(protocolId: IdType, isShort: boolean, acrossProjects = false): Observable<Map<IdType, ParentProtocolEntryHeight>> {
    return (acrossProjects
      ? this.protocolEntryDataService.getProtocolEntryOrOpenByProtocolId(protocolId)
      : this.protocolEntryDataService.getProtocolEntryOrOpenByProtocolIdAcrossProjects(protocolId)
    ).pipe(
      distinctUntilChanged((a, b) => {
        const aMap = new Map(a.map((pe) => [pe.id, pe]));

        return b.every((pe) => aMap.has(pe.id) && haveObjectsEqualProperties(
          aMap.get(pe.id),
          pe,
          ['allCompanies', 'companyId']
        ));
      }),
      map((protocolEntries) => this.calculateProtocolEntriesHeights(protocolEntries, isShort))
    );
  }

  calculateProtocolEntriesHeights(protocolEntries: Array<ProtocolEntryOrOpen|ProtocolEntry>, isShortProtocol: boolean) {
    const children: Record<IdType, Array<ProtocolEntryOrOpen|ProtocolEntry>> = _.groupBy(protocolEntries, 'parentId');

    return new Map(protocolEntries.map((entry) => {
      return [entry.id, {
        id: entry.id,
        height: getProtocolEntryTotalHeight(entry, isShortProtocol),
        subEntriesHeight: (children[entry.id] ?? []).map((child) => ({
          id: child.id,
          height: getProtocolEntryTotalHeight(child, isShortProtocol),
        })),
      }];
    }));
  }

  async getNextNumber(protocol: Protocol, parentEntryId?: IdType) {
    const entries = await observableToPromise(parentEntryId ? this.protocolEntryDataService.getSubEntriesByParentEntryId(parentEntryId) : this.protocolEntryDataService.getByProtocolId(protocol.id));

    if (!entries?.length) {
      return 1;
    }

    const numbers = _.sortBy(entries.map((entry) => entry.number));

    return numbers[numbers.length - 1] + 1;
  }

  public async updateEntriesInProtocolWithDefaultValues(defaultValues: ProtocolEntryDefaultValue, protocolId: IdType, projectId: IdType) {
    const entries = await observableToPromise(this.protocolEntryDataService.getByProtocolId(protocolId));
    const protocol = await observableToPromise(this.protocolDataService.getById(protocolId));
    if (protocol.closedAt) {
      throw new Error(`Cannot fill in default values for entries of protocol(${protocol.id}) as it was already closed at ${protocol.closedAt}`);
    }
    const keysToSkip = ['id', 'protocolId', 'createdAt', 'changedAt', 'observerCompanyIds'];

    let updatedEntries = new Array<ProtocolEntry>();
    for (const entry of entries) {
      let updatedEntry: ProtocolEntry|undefined;
      for (const key in defaultValues) {
        if (
          (defaultValues.hasOwnProperty(key) &&
          !keysToSkip.includes(key) &&
          (entry[key] === null || entry[key] === undefined || entry[key] === '') &&
          defaultValues[key] !== null && defaultValues[key] !== undefined &&
          ((key === 'internalAssignmentId' && entry['companyId'] === defaultValues['companyId']) || key !== 'internalAssignmentId')) ||
          (key === 'allCompanies' && defaultValues[key] && !entry[key] && (entry['companyId'] === null || entry['companyId'] === undefined))
        ) {
          if (!updatedEntry) {
            updatedEntry = {...entry};
          }
          updatedEntry[key] = defaultValues[key];
        }
      }
      if (updatedEntry) {
        updatedEntries.push(updatedEntry);
      }
    }
    if (defaultValues.observerCompanyIds?.length) {
      const companyIds = defaultValues.observerCompanyIds;
      const entryCompaniesToCreate: Array<ProtocolEntryCompany> = [];
      for (const entry of entries) {
        if (entry.parentId) {
          continue;
        }
        const observerCompanies = await observableToPromise(this.protocolEntryCompanyDataService.findAllByProtocolEntryId(entry.id));
        if (observerCompanies?.length) {
          continue;
        } else {
          companyIds.forEach(companyId => {
            entryCompaniesToCreate.push({id: uuidv4(), protocolEntryId: entry.id, companyId: companyId, changedAt: new Date().toISOString()});
          });
        }
      }
      await this.protocolEntryCompanyDataService.insert(entryCompaniesToCreate, projectId);
    }
    await this.protocolEntryDataService.update(updatedEntries, projectId);
  }
}
