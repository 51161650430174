import {Component, OnInit} from '@angular/core';
import {PopoverController} from '@ionic/angular';
import {map} from 'rxjs/operators';
import {LicenseType} from 'submodules/baumaster-v2-common';
import {FeatureEnabledService} from '../../../services/feature/feature-enabled.service';

@Component({
  selector: 'app-protocol-entry-continuous-info-popover',
  templateUrl: './protocol-entry-continuous-info-popover.component.html',
  styleUrls: ['./protocol-entry-continuous-info-popover.component.scss'],
})
export class ProtocolEntryContinuousInfoPopoverComponent implements OnInit {

  public isOwnClient?: boolean;

  private isViewer$ = this.featureEnabledService.isFeatureEnabled$(true, null, [LicenseType.VIEWER]);

  public allowChanging$ = this.isViewer$.pipe(
    map((isViewer) => {
      return !isViewer && this.isOwnClient;
    })
  );

  constructor(
    private popoverCtr: PopoverController,
    private featureEnabledService: FeatureEnabledService,
  ) {
  }

  async changeIsContinuousInfo(isContinuousInfo: boolean) {
    await this.popoverCtr.dismiss({
      newIsContinuousInfo: isContinuousInfo
    });
  }

  ngOnInit(): void {
  }
}
