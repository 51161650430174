<ion-content color="omg-card-surface">
  <ng-container *rxLet="
    hasTasksStatus$; let hasTasksStatus;
    suspense: loading
  ">
    <app-entry-card-list
      *rxIf="hasTasksStatus !== 'no-entries'; renderCallback: taskCardListRendered; parent: true"
      [class.ion-hide-lg-up]="hasTasksStatus === 'empty-results'"
      [selectedEntryId]="currentTaskId$ | async"
      (entryClick)="navigateToTask($event.entry)"
      (threeDotsClick)="entryActions($event.event, $event.entry)"
    ></app-entry-card-list>
    <app-no-filtered-tasks class="ion-hide-lg-up" *rxIf="hasTasksStatus === 'empty-results'" slot="fixed"></app-no-filtered-tasks>
    <div class="placeholder ion-hide-lg-down" *rxIf="hasTasksStatus === 'empty-results'" slot="fixed"></div>
    <app-no-tasks *rxIf="hasTasksStatus === 'no-entries'" slot="fixed" (ctaClick)="createTask()" [showCta]="isEditEnabled$ | async"></app-no-tasks>
  </ng-container>
  <ng-template #loading><ion-spinner></ion-spinner></ng-template>
  
  <ion-fab *ngIf="isEditEnabled$ | async" class="ion-hide-lg-up omg-btn-split" slot="fixed" horizontal="end" vertical="bottom">
    <ion-button (click)="createTask()" class="omg-btn-primary">
      <fa-icon slot="start" [icon]="entryCreationModeIcon$ | async"></fa-icon>
      <span>{{ 'tasks.toolbar.actions.addTask' | translate }}</span>
    </ion-button>
    <ion-button  (click)="openButtonActions($event)" class="omg-btn-primary">
      <fa-icon slot="start" [icon]="['fal', 'chevron-up']"></fa-icon>
    </ion-button>
  </ion-fab>
</ion-content>
