import {PdfProtocolSendReq} from '../../../../requestResponse';
import {AttachmentProtocolSignature, IdType, PdfPreview} from '../../../../models';
import {AttachmentWithContent, PdfProtocolGenerateData} from '../../pdfProtocol.model';
import {AbstractProtocolEntriesContentNew} from './abstractProtocolEntries.content.new';
import {ColumnProperties, Content, ContentImage, ContentSvg, ContentTable, ContentText, Table, TableCell, TableLayout} from 'pdfmake/interfaces';
import _ from 'lodash';
import {PdfHelperFunctions, sanitizeBase64Image} from '../../../common-report-utils';
import {SvgIcons} from '../../pdfSvgIcons';
import {canvasLine, formatDate} from '../abstractPdf.content';
import {splitWithZeroWidthSpace} from '../../pdfutils';

const SIGNATURES_PER_ROW = 3;
const COLUMN_WIDTH = 163;
export class ProtocolSignaturesContentNew extends AbstractProtocolEntriesContentNew {
  constructor(config: PdfProtocolSendReq, data: PdfProtocolGenerateData, pdfHelperFunctions: PdfHelperFunctions, pdfPreview?: PdfPreview) {
    super(data.project.language, config, data, pdfHelperFunctions, pdfPreview);
  }

  protected tableLayout: TableLayout = {
    hLineWidth: function (i: number, node: ContentTable) {
      return (i === 0 || i === node.table.body.length) ? 0.5 : 0;
    },
    hLineColor() { return '#4F5964'; },
    vLineWidth: function (i: number, node: ContentTable) {
      return (i === 0 || i === node.table.widths?.length) ? 0.5 : 0;
    },
    vLineColor() { return '#4F5964'; },
    paddingLeft() { return 4; },
    paddingRight() { return 4; },
    paddingTop() { return 4; },
    paddingBottom: function (i: number, node: ContentTable) {
      return i === 0 ? 0 : 4;
    }
  };

  protected tableLayoutInnerForHeading: TableLayout = {
    hLineWidth() {
      return 0;
    },
    vLineWidth() {
      return 0;
    },
    paddingLeft() { return 0; },
    paddingRight() { return 0; },
    paddingTop() { return 0; },
    paddingBottom() { return 0; }
  };

  writeSignatures(content: Content[]) {
    if (!this.data.attachmentProtocolSignatures?.length) {
      return;
    }

    const innerTable: Table = {
      headerRows: 0,
      widths: ['auto', 'auto'],
      body: [
        [
          {
            svg: this.changeSvgIconFill(SvgIcons.signatures, this.config.pdfProtocolSetting?.protocolColor + ''),
            fit: [12, 12]
          },
          {
            text: `${this.i18n?.get('signatures')}`,
            style: ['font9', 'marginLeft3', 'protocolFontColor', 'textBold'],
          },
        ],
      ]
    };

    const tblSignatures: Table = {
      headerRows: 1,
      widths: [163, 163, 165],
      dontBreakRows: true,
      keepWithHeaderRows: 3,
      body: [
        [
          {
            colspan: 3,
            table: innerTable,
            layout: this.tableLayoutInnerForHeading
          }, {}, {}
        ]
      ]
    };
    const signedDate = this.data.attachmentProtocolSignatures[0].attachment.changedAt;
    if (signedDate) {
      this.writeSignedDate(tblSignatures.body, signedDate);
    }
    this.writeSignatureLines(tblSignatures.body);

    content.push({
      table: tblSignatures,
      layout: this.tableLayout,
      style: ['marginTop10']
    });
  }

  private writeSignedDate(content: TableCell[][], date: Date|string) {
    content.push([
        {
          text: `${this.i18n?.get('signedAt')} ${formatDate(date)}`,
          width: '*',
          style: ['font9']
        }, {}, {}
      ]);
  }

  private writeSignatureLines(content: TableCell[][]) {
    if (!this.data.attachmentProtocolSignatures?.length) {
      return;
    }
    const attachmentProtocolSignaturesRows = _.chunk(this.orderAttachmentProtocolSignatures(this.data.attachmentProtocolSignatures), SIGNATURES_PER_ROW);
    for (const attachmentProtocolSignaturesRow of attachmentProtocolSignaturesRows) {
      const tableColumns: TableCell[] = [];
      for (const attachmentProtocolSignature of attachmentProtocolSignaturesRow) {
        const profile = attachmentProtocolSignature.attachment.profileId ? this.data.lookup.profiles.get(attachmentProtocolSignature?.attachment.profileId) : undefined;
        const address = profile?.addressId ? this.data.lookup.addresses.get(profile?.addressId) : undefined;
        const company = profile?.companyId ? this.data.lookup.companies.get(profile.companyId) : undefined;
        const signerName = profile && address ? this.sanitizeValue(address.firstName) + ' ' + this.sanitizeValue(address.lastName) : attachmentProtocolSignature.attachment.name;
        const signerCompanyName = company?.name ?? undefined;
        const columns = new Array<Content|ContentSvg|ContentImage|ContentText & ColumnProperties>();
        if (attachmentProtocolSignature.contentBase64) {
          columns.push(
            {
              alignment: 'left',
              image: sanitizeBase64Image(attachmentProtocolSignature.contentBase64),
              fit: [COLUMN_WIDTH, 66],
              width: COLUMN_WIDTH
            }
          );
        } else {
          columns.push(
            {
              alignment: 'left',
              svg: attachmentProtocolSignature.contentBase64 ? '' : SvgIcons.noImagePlaceholder,
              fit: [COLUMN_WIDTH, 66],
              width: COLUMN_WIDTH
            }
          );
        }
        columns.push(canvasLine(COLUMN_WIDTH));
        columns.push({alignment: 'left', fontSize: 8, text: splitWithZeroWidthSpace(signerName ?? '', this.lng), width: '33%', style: []});
        columns.push({alignment: 'left', fontSize: 8, text: signerCompanyName ? '(' + splitWithZeroWidthSpace(signerCompanyName, this.lng) + ')' : '', width: '33%'});
        tableColumns.push({columns: [columns]});
      }

      if (tableColumns.length < SIGNATURES_PER_ROW) {
        for (let i = 0; i <= SIGNATURES_PER_ROW - tableColumns.length; i++) {
          tableColumns.push({});
        }
      }
      content.push(tableColumns);
    }
  }

  private convertToAttachmentProtocolSignatureWithSortProperties(attachmentProtocolSignature: AttachmentWithContent<AttachmentProtocolSignature>):
    {id: IdType, companyName?: string, firstName?: string|null, lastName?: string|null, name?: string|null} {
    const protocolSignature = attachmentProtocolSignature.attachment;
    if (protocolSignature.profileId) {
      const profile = this.data.lookup.profiles.get(protocolSignature.profileId);
      if (profile && profile.addressId) {
        const address = this.data.lookup.addresses.get(profile.addressId);
        const company = this.data.lookup.companies.get(profile.companyId);
        if (address && company) {
          return {id: protocolSignature.id, companyName: company.name, firstName: address.firstName, lastName: address.lastName};
        }
      }
    }
    return {id: protocolSignature.id, name: protocolSignature.name};
  }

  private orderAttachmentProtocolSignatures(attachmentProtocolSignatures: Array<AttachmentWithContent<AttachmentProtocolSignature>>): Array<AttachmentWithContent<AttachmentProtocolSignature>> {
    const sortedValues = _.orderBy(attachmentProtocolSignatures
      .map((attachmentProtocolSignature) => this.convertToAttachmentProtocolSignatureWithSortProperties(attachmentProtocolSignature), ['companyName', 'firstName', 'lastName', 'name', 'id']));
    const sortedAttachmentProtocolSignatures = attachmentProtocolSignatures.sort((a, b) => {
      const indexA = sortedValues.findIndex((value) => value.id === a.attachment.id);
      const indexB = sortedValues.findIndex((value) => value.id === b.attachment.id);
      return indexA - indexB;
    });
    return sortedAttachmentProtocolSignatures;
  }
}
