import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {distinctUntilChanged, filter, map, shareReplay, startWith, switchMap} from 'rxjs/operators';
import {IdType} from 'submodules/baumaster-v2-common';
import {AbstractEntryListService} from '../entry/abstract-entry-list.service';
import {EntryService} from '../entry/entry.service';

@Injectable()
export class TasksPageService extends AbstractEntryListService {

  readonly currentUrl$ = this.router.events.pipe(
    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
    startWith(this.router.url),
    map(() => this.router.url)
  );
  readonly taskProtocolId$: Observable<IdType>;
  readonly lastTaskId$: Observable<string> = this.currentUrl$.pipe(
    filter((url) => url.startsWith('/tasks') && url.includes('/entry')),
    shareReplay({refCount: true, bufferSize: 1})
  );
  readonly currentTaskId$: Observable<string|undefined> = this.currentUrl$.pipe(
    startWith(this.router.url),
    map((url) => url.startsWith('/tasks') && url.includes('/entry') ? url.slice(url.indexOf('/entry') + 7) : undefined),
    shareReplay({refCount: true, bufferSize: 1})
  );

  constructor(
    private entryService: EntryService,
    private router: Router
  ) {
    const taskProtocolId$ = entryService.taskProtocol$.pipe(
      filter((protocol) => Boolean(protocol)),
      map((protocol) => protocol.id),
      distinctUntilChanged(),
      shareReplay({refCount: true, bufferSize: 1})
    );
    super(taskProtocolId$.pipe(
      switchMap((protocolId) => this.entryService.getEntriesForProtocols$([protocolId], [protocolId])),
      shareReplay({refCount: true, bufferSize: 1})
    ));
    this.taskProtocolId$ = taskProtocolId$;
  }
}
