import { Attachment, ColumnUniqueConfig, IdAware } from 'submodules/baumaster-v2-common';
import { DataDependency } from '../model/data-dependency';
import {StorageKeyEnum} from './constants';
import {Translatable} from '../model/translatable';

export class ErrorWithUserMessage extends Error {
  public readonly userMessage: string;

  constructor(message: string, userMessage: string) {
    super(message);
    this.userMessage = userMessage;
  }
}

export class NetworkError extends Error {
  constructor(message: string) {
    super(message);
  }
}

export class NetworkTimeoutError extends NetworkError {
  constructor(message: string) {
    super(message);
  }
}

export class TimeoutError extends NetworkError {
  constructor(message: string) {
    super(message);
  }
}

export class AutodeskFileStillProcessingError extends Error {
  constructor(message: string) {
    super(message);
  }
}

export class AbortError extends Error {
  constructor(message?: string) {
    super(message ?? 'aborted');
  }
}

export function convertErrorToMessage(e: any): string {
  let errorMessage = e?.message || e;
  if (typeof errorMessage !== 'string') {
    try {
      errorMessage = JSON.stringify(e);
    } catch (_e) {
      errorMessage = 'Unknown';
    }
  }
  return errorMessage;
}

export class IntegrityError extends Error {
  constructor(message: string) {
    super(message);
  }
}

export class MissingDependencyError<T extends IdAware = IdAware> extends IntegrityError {
  constructor(public objectToCheck: T, public dataDependency: DataDependency) {
    super(`Dependency with id ${objectToCheck[dataDependency.sourceKeyPath]} (key "${dataDependency.sourceKeyPath}") ` +
          `was not found in ${dataDependency.destinationKeyPath}(${dataDependency.destinationStorageKey})`);
  }
}

export class DependencyStillBoundError<T extends IdAware = IdAware> extends IntegrityError {
  constructor(public objectToCheck: T, public dataDependency: DataDependency) {
    super(`Dependency with id ${objectToCheck[dataDependency.sourceKeyPath]} (key "${dataDependency.sourceKeyPath}") ` +
          `was found in ${dataDependency.destinationKeyPath}(${dataDependency.destinationStorageKey})`);
  }
}

export class UniqueConstraintError<T extends IdAware = IdAware> extends IntegrityError {
  constructor(
    public storageKey: StorageKeyEnum,
    public uniqueConstraint: ColumnUniqueConfig,
    public objectToCheck: T,
    public firstDuplicate: T
  ) {
    super(`[${storageKey}] Unique constraint check failed for constraint (${uniqueConstraint.join(', ')}); ` +
          `wanted to insert/update ${objectToCheck.id}, but found ${firstDuplicate.id}`);
  }
}

export type AttachmentKind = 'entry' | 'entryChat' | 'projectImage' | 'protocolSignature' | 'clientPdfStartBanner' | 'clientPdfEndBanner' | 'clientLogo' | 'projectBanner';

export class UnprocessableImageForPdfGenerationError extends Error {
  get translationKey() {
    return `errors.unprocessableImage.${this.kind}.message`;
  }
  get translationParams() {
    return {fileName: this.attachment.fileName};
  }
  get translatable(): Translatable {
    return {
      key: this.translationKey,
      params: this.translationParams,
    };
  }

  constructor(public attachment: Attachment, public kind: AttachmentKind) {
    super(`Attachment "${attachment.id}" is ${attachment.mimeType}, but content is not JPEG/JPG/PNG, therefore PDF generation is not possible`);
  }
}

export class UnprocessableFileForPdfGenerationError extends Error {
  get translationKey() {
    if (!this.kind) {
      return 'errors.unprocessableImage.unsupportedImage';
    }
    return `errors.unprocessableImage.${this.kind}.message`;
  }
  get translationParams() {
    return {fileName: this.file.name};
  }
  get translatable(): Translatable {
    return {
      key: this.translationKey,
      params: this.translationParams,
    };
  }

  constructor(public file: File, public kind?: AttachmentKind|undefined) {
    super(`File "${file.name}" is ${file.type}, but content is not JPEG/JPG/PNG, therefore PDF generation is not possible`);
  }
}

export class UnauthorizedError extends Error {}

export class OfflineError extends NetworkError {

  constructor(message: string) {
    super('OfflineError - ' + message);
  }
}
