<span class="app-protocol-entry-id"
      [class]="{
        'status-open': protocolEntry?.status === protocolEntryStatus.OPEN && statusFieldActive,
        'status-done': protocolEntry?.status === protocolEntryStatus.DONE && statusFieldActive,
        'status-waiting': protocolEntry?.status === protocolEntryStatus.COMPANY_DONE && statusFieldActive,
        'hideBorderLeft': hideBorderLeft,
        'protocol-closed': ((createdInProtocolData || protocolData) | async)?.closedAt | isEmpty:'reverse',
        'active': active
        }">
    <span *ngIf="!isTask" class="app-protocol-type-code">{{getProtocolTypeCode((protocolTypesById | async), (protocolData | async)) }}{{(protocolData | async)?.number | number:'2.0'}}</span>
    <span *ngIf="protocolEntry?.parentId | isEmpty:'reverse'"
          class="app-protocol-entry-parent-id"><span *ngIf="!isTask">.</span>{{ (getParentProtocolEntryId(protocolEntry?.parentId) | async)?.number | number: '3.0'}}</span>
    <span class="app-protocol-entry-number"><span *ngIf="!isTask || protocolEntry?.parentId | isEmpty:'reverse'">.</span>{{protocolEntry?.number | number: '3.0'}}</span>
</span>
