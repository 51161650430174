import {UnauthorizedError} from '../shared/errors';

export const replyOnUnauthorized = async <T>(fn: () => Promise<T>, {retryCount = 2, error}: {retryCount?: number; error?: unknown} = {}): Promise<T> => {
  if (retryCount > 0) {
    try {
      return await fn();
    } catch (e) {
      if (e instanceof UnauthorizedError) {
        return await replyOnUnauthorized(fn, {retryCount: retryCount - 1, error: error ?? e});
      }

      throw e;
    }
  }

  throw (error ?? new Error('replyOnUnauthorized failed (no error supplied)'));
};
