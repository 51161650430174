import {Component, OnDestroy, OnInit} from '@angular/core';
import {AlertController, ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {skipWhile, take} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {AuthenticationService} from '../../../services/auth/authentication.service';
import {TermsAndConditionsService} from '../../../services/common/terms-and-conditions.service';
import {UserDataService} from '../../../services/data/user-data.service';
import {LogoutService} from '../../../services/logout.service';
import {UserflowService} from '../../../services/userflow/userflow.service';
import {observableToPromise} from '../../../utils/async-utils';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
})
export class TermsAndConditionsComponent implements OnInit, OnDestroy {
  private readonly termsAndConditionsVersion: number;
  public accepted: boolean|undefined;
  private modal: HTMLIonModalElement;
  public buttonActionInProgress = false;
  public userStorageInitialized = false;
  private userStorageInitializedSubscription: Subscription;
  public termsOfUseUrl = environment.termsOfUseUrl;
  public privacyPolicyUrl = environment.privacyPolicyUrl;

  constructor(private translateService: TranslateService, private userDataService: UserDataService, private logoutService: LogoutService, private authenticationService: AuthenticationService,
              private modalController: ModalController, private alertController: AlertController,
              private termsAndConditionsService: TermsAndConditionsService, private userflowService: UserflowService) {
    this.termsAndConditionsVersion = this.termsAndConditionsService.latestVersion;
    this.userStorageInitializedSubscription = this.userDataService.storageInitializedObservable.subscribe((value) => {
      this.userStorageInitialized = value.has(null);
    });
  }

  async ngOnInit() {
    this.setCanDismiss();
  }

  ngOnDestroy(): void {
    this.userStorageInitializedSubscription.unsubscribe();
  }

  async accept() {
    try {
      this.buttonActionInProgress = true;
      if (!this.userStorageInitialized) {
        await observableToPromise(this.userDataService.storageInitializedObservable.pipe(
          skipWhile((value) => !value.has(null)),
          take(1)
        ));
      }
      const authUserId = await observableToPromise(this.authenticationService.authenticatedUserId$);
      if (!authUserId) {
        await this.logout();
        return;
      }
      await this.termsAndConditionsService.accept(authUserId, this.termsAndConditionsVersion);
      this.accepted = true;
      this.userflowService.startUserflowIntro();
      await this.dismissModal();
    } finally {
      this.buttonActionInProgress = false;
    }
  }

  async logout() {
    try {
      this.buttonActionInProgress = true;
      this.accepted = false;
      await this.dismissModal();
    } finally {
      this.buttonActionInProgress = false;
    }
  }

  dismissModal() {
    return this.modal.dismiss();
  }

  private canDismiss = async () => {
    if (this.accepted === undefined) {
      const alert = await this.alertController.create({
        message: this.translateService.instant('termsAndConditions.messageCannotCloseWithoutAction')
      });
      await alert.present();
      return false;
    }
    if (this.accepted) {
      return true;
    }
    const loggedOut = await this.logoutService.logout();
    if (loggedOut) {
      return true;
    }

    return false;
  };

  private setCanDismiss() {
    this.modal.canDismiss = this.canDismiss;
  }

}
