import {Component, Input, OnInit} from '@angular/core';
import {PopoverController} from '@ionic/angular';
import {BehaviorSubject} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {ProtocolEntryTypeDataService} from 'src/app/services/data/protocol-entry-type-data.service';
import {IdType, ProtocolEntryStatus} from 'submodules/baumaster-v2-common';
import {ProtocolEntryStatusPopoverComponent} from '../protocol-entry-status-popover/protocol-entry-status-popover.component';
import {ProtocolEntryContinuousInfoPopoverComponent} from '../protocol-entry-continuous-info-popover/protocol-entry-continuous-info-popover.component';

@Component({
  selector: 'app-protocol-entry-mass-edit',
  templateUrl: './protocol-entry-mass-edit.component.html',
  styleUrls: ['./protocol-entry-mass-edit.component.scss'],
})
export class ProtocolEntryMassEditComponent implements OnInit {
  private modal: HTMLIonModalElement;
  public readonly protocolEntryStatus = ProtocolEntryStatus;

  @Input()
  protocolId: IdType;

  @Input()
  isClosedOrHasCarriedOverEntries = false;

  @Input()
  isShortProtocolLayout = false;

  @Input()
  isContinuousProtocolLayout = false;

  @Input()
  isTaskProtocol = false;

  @Input()
  entriesCount = 0;

  @Input()
  hasNonActionableItems = false;

  @Input()
  hasActionableItems = false;

  @Input()
  hasChildEntries = false;

  @Input()
  onlyActionableEntryTypes = false;

  @Input()
  typeRequired = false;

  data$ = new BehaviorSubject<any>({});

  get data() {
    return this.data$.getValue();
  }

  hasActionableTypeSelected = false;

  hasActionableTypeSelected$ = this.protocolEntryTypeDataService.data.pipe(
    switchMap((protocolEntryTypes) => this.data$.pipe(
      map((data) => protocolEntryTypes.some((entryType) => entryType.id === data.type?.id && entryType.statusFieldActive)),
      tap((value) => {
        if (this.hasActionableTypeSelected !== value) {
          this.hasActionableTypeSelected = value;
          if (value) {
            // Type changed to actionable
            this.setOpenStatus();
          } else {
            // Type changed to non-actionable
            this.resetStatus();
          }
        }
      })
    ))
  );

  constructor(
    private protocolEntryTypeDataService: ProtocolEntryTypeDataService,
    private popoverCtrl: PopoverController
  ) { }

  ngOnInit() {}

  getFieldsToEdit() {
    if (this.isClosedOrHasCarriedOverEntries) {
      return [
        'startDate',
        'todoUntil',
        'status',
        'isContinuousInfo'
      ];
    }
    if (this.isShortProtocolLayout) {
      return [
        'internalAssignmentId',
        'startDate',
        'todoUntil',
        'priority',
        'status',
        'createdAt',
      ];
    }
    const otherFields = [
      'company',
      'internalAssignmentId',
      'nameableDropdownId',
      'type',
      'craft',
      'location',
      'startDate',
      'todoUntil',
      'cost',
      'priority',
      'status',
      'isContinuousInfo',
      'createdAt',
      'observerCompanies'
    ];
    return !this.isShortProtocolLayout && !this.isContinuousProtocolLayout ? [...otherFields, 'unit'] : otherFields;
  }

  setOpenStatus() {
    this.updateData({
      status: ProtocolEntryStatus.OPEN,
    });
  }

  resetStatus() {
    const { status, ...data } = this.data;
    this.data$.next(data);
  }

  updateData(data) {
    this.data$.next({
      ...this.data,
      ...data
    });
  }

  getUpdatedObject(): any {
    const fieldsToEdit = this.getFieldsToEdit();
    const updatedObject = Object.entries(this.data)
      .filter(([key, value]) => fieldsToEdit.includes(key) && value !== undefined && value !== null)
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {} as any);

    // Clear status if previously any of the entries had it
    if (updatedObject.type && !this.hasActionableTypeSelected && this.hasActionableItems) {
      updatedObject.status = ProtocolEntryStatus.OPEN;
    }

    return updatedObject;
  }

  async statusPopover(event) {
    const popover = await this.popoverCtrl.create({
      component: ProtocolEntryStatusPopoverComponent,
      componentProps: {
        isProtocolLayoutShort: this.isShortProtocolLayout,
        isTaskProtocol: this.isTaskProtocol,
        isOwnClient: true, // Mass edit is currently only available for own client entries
      },
      event,
      translucent: true
    });

    await popover.present();

    const { data } = await popover.onDidDismiss();

    if (data) {
      this.updateData({
        status: data.newStatus,
      });
    }
  }

  async showContinuousInfoPopover(event) {
    if (!this.isContinuousProtocolLayout) {
      return;
    }

    const popover = await this.popoverCtrl.create({
      component: ProtocolEntryContinuousInfoPopoverComponent,
      componentProps: {
        isProtocolLayoutShort: this.isShortProtocolLayout,
        isTaskProtocol: this.isTaskProtocol,
        currentStatus: this.protocolEntryStatus,
        isOwnClient: true, // Mass edit is currently only available for own client entries
      },
      event,
      translucent: true
    });

    await popover.present();

    const { data } = await popover.onDidDismiss();

    if (data) {
      this.updateData({
        isContinuousInfo: data.newIsContinuousInfo,
      });
    }
  }

  dismiss() {
    this.modal.dismiss(undefined, 'cancel');
  }

  accept() {
    this.modal.dismiss(this.getUpdatedObject());
  }

}
