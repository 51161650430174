import {Attachment, ErrorCodeType, ErrorResponse, IdType} from 'submodules/baumaster-v2-common';
import {AttachmentBlob, OfflineAttachmentType} from '../model/attachments';
import {from} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {fetchWithTimeout} from './fetch-utils';
import mime from 'mime';
import {convertErrorToMessage, NetworkError, UnauthorizedError} from '../shared/errors';
import {ChangedValue} from '../services/sync/sync-utils';
import {Directory, Encoding, Filesystem} from '@capacitor/filesystem';
import _ from 'lodash';
import {Capacitor} from '@capacitor/core';
import async, {AsyncResultCallback, QueueObject} from 'async';
import {replyOnUnauthorized} from './unauthorized-reply-utils';
import {TokenGetter} from './token-holder';
import {convertISOStringToDate} from './date-utils';

const CACHE_NAME_MEDIA = 'media';
const CACHE_NAME_MEDIA_UPLOAD_QUEUE = 'media-upload-queue';
const CACHE_NAME_MEDIA_UPLOAD_ERROR = 'media-upload-error';
const MAX_UPLOAD_ATTEMPTS = 10;
const ATTACHMENT_CONCURRENT_DOWNLOADS = 10;
const ATTACHMENT_CONCURRENT_DOWNLOADS_FILESYSTEM = 5;
const ATTACHMENT_DOWNLOAD_MAX_SIZE_IN_BYTES = 10 * 1024 * 1024; // Duplicates value from commonAttachmentUtils but needs to be declared here, otherwise it would fail when being used by Worker.
const LOCAL_FILE_FETCH_TIMEOUT_IN_MS = 30 * 1000;
const URL_WITH_ATTEMPTS_SEPARATOR = '\t';
const MIN_AGE_IN_MS_BEFORE_DELETING_ATTACHMENTS = 4 * 24 * 60 * 60 * 1000;

export type DataSyncMode = 'SYNC' | 'NONE';
export type AttachmentSyncMode = 'THUMBNAIL' | 'THUMBNAIL_AND_IMAGE_FOR_OPEN' | 'THUMBNAIL_AND_IMAGE' | 'NONE';
export type AttachmentPathProperty = 'filePath' | 'thumbnailPath' | 'bigThumbnailPath' | 'mediumThumbnailPath';

export interface SyncMode {
  data: DataSyncMode;
  attachment: AttachmentSyncMode;
}

export interface BlobFilenameBundle {
  blob: Blob;
  fileName: string;
}

export interface BlobWidthHeightBundle {
  blob: Blob;
  width: number;
  height: number;
}

export interface BlobDownloaded {
  blob: Blob;
  downloaded: boolean;
}

export type FileAccessLocation = 'media' | 'upload' | 'uploadError';

export interface FileAccessFileInfo {
  path: string;
  version: string;
  location: FileAccessLocation;
  clientOrProjectId?: IdType|null;
}

export type FileAccessProcessorResult = 'success' | 'failedIncreaseAttempt' | 'failed';

export type FileAccessUtilClassName = 'CacheStorageFileAccessUtil' | 'FilesystemFileAccessUtil';

const CapacitorErrorMessagePartsNotExisting = ['File does not exist', 'Directory does not exist', 'there is no such file', 'because either the former doesn'];

const CapacitorErrorMessagePartsNotExistingReadFileByPath = ['Load failed'];

export abstract class AbstractFileAccessUtil {
  public readonly mediaUrl: string;
  public readonly className: FileAccessUtilClassName;
  public readonly webWorkerSupported: boolean;
  public readonly affectsStorageQuota: boolean;
  public readonly filesStoredPerClientOrProject: boolean;
  public readonly filesFasterThanReadFile: boolean;
  public readonly attachmentConcurrentDownloads: number;

  protected constructor(className: FileAccessUtilClassName, webWorkerSupported: boolean, affectsStorageQuota: boolean, filesStoredPerClientOrProject: boolean, filesFasterThanReadFile: boolean,
                        attachmentConcurrentDownloads: number, mediaUrl: string) {
    this.className = className;
    this.webWorkerSupported = webWorkerSupported;
    this.affectsStorageQuota = affectsStorageQuota;
    this.filesStoredPerClientOrProject = filesStoredPerClientOrProject;
    this.filesFasterThanReadFile = filesFasterThanReadFile;
    this.attachmentConcurrentDownloads = attachmentConcurrentDownloads;
    this.mediaUrl = mediaUrl;
  }

  // main file operations implemented in concrete classes
  public abstract deleteAllFiles(location: FileAccessLocation, abortSignal?: AbortSignal): Promise<boolean>;
  public abstract readFile(info: FileAccessFileInfo, abortSignal?: AbortSignal): Promise<Blob|undefined>;
  public abstract readFileIgnoreVersion(info: FileAccessFileInfo, abortSignal?: AbortSignal): Promise<{blob: Blob, version: string|undefined}|undefined>;
  public abstract writeFile(info: FileAccessFileInfo, content: Blob|string, abortSignal?: AbortSignal): Promise<void>;
  public abstract readTextFile(info: FileAccessFileInfo, abortSignal?: AbortSignal): Promise<string|undefined>;
  public abstract writeTextFile(info: FileAccessFileInfo, data: string, mimeType: string, abortSignal?: AbortSignal): Promise<void>;
  public abstract deleteFile(info: FileAccessFileInfo, abortSignal?: AbortSignal): Promise<boolean>;
  public abstract fileExists(info: FileAccessFileInfo, abortSignal?: AbortSignal): Promise<boolean>;
  public abstract moveFile(infoSource: FileAccessFileInfo, infoTarget: FileAccessFileInfo, abortSignal?: AbortSignal): Promise<boolean>;
  public abstract numberOfFiles(location: FileAccessLocation, abortSignal?: AbortSignal): Promise<number>;
  public abstract files(location: FileAccessLocation, abortSignal?: AbortSignal): Promise<string[]>;
  public abstract filesForClientOrProject(location: FileAccessLocation, clientOrProjectId?: IdType, abortSignal?: AbortSignal): Promise<string[]>;
  public abstract moveFilesFromUploadErrorToUpload(abortSignal?: AbortSignal): Promise<number>;
  public abstract moveToErrorQueue(path: string, abortSignal?: AbortSignal): Promise<[null|string, boolean]>;
  public abstract fileSize(info: FileAccessFileInfo, abortSignal?: AbortSignal): Promise<number|undefined>;
  public abstract filesProcessor(location: 'upload' | 'uploadError', deleteAfterProcessing: boolean, ignoreVersion: boolean,
                                 processorFunc: (url: string|undefined, blob: Blob, attempt: number, abortSignal?: AbortSignal) => Promise<FileAccessProcessorResult>): Promise<undefined|string[]>;
  public abstract getTotalSizeOfLocation(location: FileAccessLocation, abortSignal?: AbortSignal): Promise<number|undefined>;
  public abstract deleteAll(abortSignal?: AbortSignal): Promise<void>;
  protected abstract getLocalPath(info: FileAccessFileInfo): string;

  public async getTotalSizeOfAllLocations(abortSignal?: AbortSignal): Promise<number|undefined> {
    if (abortSignal?.aborted) {
      return undefined;
    }
    let totalSize = 0;
    totalSize += (await this.getTotalSizeOfLocation('media', abortSignal)) || 0;
    totalSize += (await this.getTotalSizeOfLocation('upload', abortSignal)) || 0;
    totalSize += (await this.getTotalSizeOfLocation('uploadError', abortSignal)) || 0;
    return totalSize;
  }


  // main attachment operations
  private async readAttachmentByTypeOrProperty(attachment: Attachment, attachmentType: OfflineAttachmentType | AttachmentPathProperty, location: FileAccessLocation = 'media',
                                               abortSignal?: AbortSignal):
    Promise<{blob: Blob, attachmentPathProperty: AttachmentPathProperty}|undefined> {
    if (abortSignal?.aborted) {
      return undefined;
    }
    const attachmentPathWithProperty = getAttachmentPath(attachment, attachmentType);
    if (!attachmentPathWithProperty) {
      throw new Error(`Attachment with id ${attachment.id} does not have a path of type ${attachmentType}`);
    }
    const clientOrProjectId = this.getClientOrProjectIdFromAttachment(attachment);
    const blob = await this.readFile({path: attachmentPathWithProperty.attachmentPath, version: versionForAttachment(attachment), location, clientOrProjectId}, abortSignal);
    if (abortSignal?.aborted) {
      return undefined;
    }
    if (!blob) {
      return undefined;
    }
    return {blob, attachmentPathProperty: attachmentPathWithProperty.attachmentPathProperty};
  }

  private async readAttachmentByTypeOrPropertyIgnoreVersion(attachment: Attachment, attachmentType: OfflineAttachmentType | AttachmentPathProperty, location: FileAccessLocation = 'media'):
    Promise<{blob: Blob, version: string|undefined, attachmentPath: string, attachmentPathProperty: AttachmentPathProperty}|undefined> {
    const attachmentPathWithProperty = getAttachmentPath(attachment, attachmentType);
    if (!attachmentPathWithProperty) {
      throw new Error(`Attachment with id ${attachment.id} does not have a path of type ${attachmentType}`);
    }
    const clientOrProjectId = this.getClientOrProjectIdFromAttachment(attachment);
    const readFileResult = await this.readFileIgnoreVersion({path: attachmentPathWithProperty.attachmentPath, version: versionForAttachment(attachment), location, clientOrProjectId});
    if (!readFileResult) {
      return undefined;
    }
    return {blob: readFileResult.blob, version: readFileResult.version,
      attachmentPath: attachmentPathWithProperty.attachmentPath, attachmentPathProperty: attachmentPathWithProperty.attachmentPathProperty};
  }

  public async readAttachmentByType(attachment: Attachment, attachmentType: OfflineAttachmentType|AttachmentPathProperty, location: FileAccessLocation = 'media', abortSignal?: AbortSignal):
    Promise<{blob: Blob, attachmentPathProperty: AttachmentPathProperty}|undefined> {
    if (abortSignal?.aborted) {
      return;
    }
    return await this.readAttachmentByTypeOrProperty(attachment, attachmentType, location, abortSignal);
  }

  public async readAttachment(attachment: Attachment, attachmentPathProperty: AttachmentPathProperty, location: FileAccessLocation = 'media'): Promise<Blob|undefined> {
    const result = await this.readAttachmentByTypeOrProperty(attachment, attachmentPathProperty, location);
    return result?.blob || undefined;
  }

  public async readAttachmentIgnoreVersion(attachment: Attachment, attachmentPathProperty: AttachmentPathProperty, location: FileAccessLocation = 'media'):
    Promise<{blob: Blob|undefined, version?: string}> {
    try {
      const result = await this.readAttachmentByTypeOrProperty(attachment, attachmentPathProperty, location);
      if (result?.blob) {
        return {blob: result.blob};
      }
    } catch (error) {
      // iOS may throw an Error instead of returning an empty result. So check for alternative files.
    }

    const resultIgnoreVersion = await this.readAttachmentByTypeOrPropertyIgnoreVersion(attachment, attachmentPathProperty, location);
    if (!resultIgnoreVersion?.blob) {
      return {blob: undefined};
    }
    return {blob: resultIgnoreVersion?.blob, version: resultIgnoreVersion.version};
  }

  public async writeAttachment(attachment: Attachment, attachmentType: AttachmentPathProperty, content: Blob|string, location: FileAccessLocation = 'media'): Promise<void> {
    const attachmentPathWithProperty = getAttachmentPath(attachment, attachmentType);
    if (!attachmentPathWithProperty) {
      throw new Error(`Attachment with id ${attachment.id} does not have a path of type ${attachmentType}`);
    }
    const clientOrProjectId = this.getClientOrProjectIdFromAttachment(attachment);
    await this.writeFile({path: attachmentPathWithProperty.attachmentPath, version: versionForAttachment(attachment), location, clientOrProjectId}, content);
  }

  public async deleteAttachment(attachment: Attachment, attachmentType: AttachmentPathProperty, strictMatch: boolean, location: FileAccessLocation = 'media'): Promise<boolean> {
    const attachmentPathWithProperty = getAttachmentPath(attachment, attachmentType, strictMatch);
    if (!attachmentPathWithProperty) {
      return false;
    }
    const clientOrProjectId = this.getClientOrProjectIdFromAttachment(attachment);
    return await this.deleteFile({path: attachmentPathWithProperty.attachmentPath, version: versionForAttachment(attachment), location, clientOrProjectId});
  }

  public async changeAttachmentVersion(attachment: Attachment, attachmentType: AttachmentPathProperty, oldChangedAt: string|Date, newChangedAt: string|Date,
                                       location: FileAccessLocation = 'media'): Promise<boolean> {
    const attachmentPathWithProperty = getAttachmentPath(attachment, attachmentType, true);
    if (!attachmentPathWithProperty) {
      return false;
    }
    const clientOrProjectId = this.getClientOrProjectIdFromAttachment(attachment);
    const oldVersion = oldChangedAt ? changedAtToVersion(oldChangedAt) : versionForAttachment(attachment);
    const newVersion = changedAtToVersion(newChangedAt);
    if (oldVersion === newVersion) {
      return false;
    }
    await this.moveFile({path: attachmentPathWithProperty.attachmentPath, version: oldVersion, location, clientOrProjectId},
      {path: attachmentPathWithProperty.attachmentPath, version: newVersion, location, clientOrProjectId});
    return true;
  }

  public async changeAttachmentVersionForUploadIfExists(attachment: Attachment, attachmentType: AttachmentPathProperty, oldChangedAt: string|Date, newChangedAt: string|Date,
                                                        location: 'upload' | 'uploadError'): Promise<boolean> {
    const attachmentPathWithProperty = getAttachmentPath(attachment, attachmentType, true);
    if (!attachmentPathWithProperty) {
      return false;
    }
    const clientOrProjectId = this.getClientOrProjectIdFromAttachment(attachment);
    const oldVersion = oldChangedAt ? changedAtToVersion(oldChangedAt) : versionForAttachment(attachment);
    const newVersion = changedAtToVersion(newChangedAt);
    if (oldVersion === newVersion) {
      return false;
    }
    const sourceInfo = {path: attachmentPathWithProperty.attachmentPath, version: oldVersion, location, clientOrProjectId};
    const targetInfo = {path: attachmentPathWithProperty.attachmentPath, version: newVersion, location, clientOrProjectId};
    const url = this.getServerUrlForAttachment(attachment, 'filePath');
    const content = url;
    if (!(await this.fileExists(sourceInfo))) {
      return false;
    }
    await this.writeTextFile(targetInfo, content, location);
    await this.deleteFile(sourceInfo);
  }

  public async attachmentExists(attachment: Attachment, attachmentType: OfflineAttachmentType | AttachmentPathProperty, strictMatch = false, location: FileAccessLocation = 'media',
                                abortSignal?: AbortSignal): Promise<boolean> {
    if (abortSignal?.aborted) {
      return false;
    }
    const attachmentPathWithProperty = getAttachmentPath(attachment, attachmentType, strictMatch);
    if (!attachmentPathWithProperty) {
      console.warn(`Attachment with id ${attachment.id} does not have a path of type ${attachmentType}`);
      return false;
    }
    const clientOrProjectId = this.getClientOrProjectIdFromAttachment(attachment);
    return await this.fileExists({path: attachmentPathWithProperty.attachmentPath, version: versionForAttachment(attachment), location, clientOrProjectId});
  }

  public async attachmentSize(attachment: Attachment, attachmentType: OfflineAttachmentType | AttachmentPathProperty, strictMatch = false,
                              location: FileAccessLocation = 'media'): Promise<number|undefined> {
    const attachmentPathWithProperty = getAttachmentPath(attachment, attachmentType, strictMatch);
    if (!attachmentPathWithProperty) {
      console.warn(`Attachment with id ${attachment.id} does not have a path of type ${attachmentType}`);
      return undefined;
    }
    const clientOrProjectId = this.getClientOrProjectIdFromAttachment(attachment);
    return await this.fileSize({path: attachmentPathWithProperty.attachmentPath, version: versionForAttachment(attachment), location, clientOrProjectId});
  }

  public getServerUrlForAttachment(attachment: Attachment, attachmentType: AttachmentPathProperty): string|undefined {
    const path = getAttachmentPath(attachment, attachmentType)?.attachmentPath;
    if (!path) {
      return undefined;
    }
    return this.getServerUrl({path, version: versionForAttachment(attachment), location: 'media'});
  }

  public getServerUrl(info: FileAccessFileInfo): string {
    return this.mediaUrl + info.path + '?version=' + info.version;
  }

  public getLocalPathForAttachment(attachment: Attachment, attachmentType: OfflineAttachmentType | AttachmentPathProperty, location: FileAccessLocation = 'media'): string|undefined {
    const attachmentPathWithProperty = getAttachmentPath(attachment, attachmentType);
    if (!attachmentPathWithProperty) {
      return undefined;
    }
    const clientOrProjectId = this.getClientOrProjectIdFromAttachment(attachment);
    const version = versionForAttachment(attachment);
    return this.getLocalPath({path: attachmentPathWithProperty.attachmentPath, version, location, clientOrProjectId});
  }

  // helper functions

  /* eslint-disable @typescript-eslint/dot-notation */
  private getClientOrProjectIdFromAttachment(attachment: Attachment): IdType|null {
    let clientOrProjectId: IdType|undefined|null;
    if ('projectId' in attachment && typeof attachment['projectId'] === 'string') {
      clientOrProjectId = attachment['projectId'];
    } else if ('clientId' in attachment && typeof attachment['clientId'] === 'string') {
      clientOrProjectId = attachment['clientId'];
    } else if ('userId' in attachment) {
      return null;
    } else {
      throw new Error(`Attachment with id ${attachment.id} does not have projectId or clientId property.`);
    }
    if (!clientOrProjectId) {
      throw new Error(`Attachment with id ${(attachment as Attachment).id} does not have projectId or clientId property but it is null or undefined.`);
    }
    return clientOrProjectId;
  }
  /* eslint-enable @typescript-eslint/dot-notation */

  protected getExtension(filename: string): string|undefined {
    if (!filename) {
      throw new Error(`filename expected, but not provided.`);
    }
    const extensionIndex = filename.lastIndexOf('.');
    if (extensionIndex === -1) {
      return undefined;
    }
    return filename.substring(extensionIndex + 1);
  }
}

export interface FileAccessUtilWithDefault {
  fileAccessUtil: AbstractFileAccessUtil;
  usedDefault: boolean;
}

export class CacheStorageFileAccessUtil extends AbstractFileAccessUtil {
  protected static readonly CACHE_NAME_BY_FILE_ACCESS_LOCATION: {[key in FileAccessLocation]: string} = {
    media: CACHE_NAME_MEDIA,
    upload: CACHE_NAME_MEDIA_UPLOAD_QUEUE,
    uploadError: CACHE_NAME_MEDIA_UPLOAD_ERROR
  };

  constructor(mediaUrl: string) {
    super('CacheStorageFileAccessUtil', true, true, false, false, ATTACHMENT_CONCURRENT_DOWNLOADS, mediaUrl);
  }

  public async readFile(info: FileAccessFileInfo, abortSignal?: AbortSignal): Promise<Blob|undefined> {
    if (abortSignal?.aborted) {
      return undefined;
    }
    const cache = await this.openCache(info.location);
    const request = this.createRequestFromUrl(this.getServerUrl(info));
    const response = await cache.match(request, {ignoreVary: true, ignoreMethod: true});
    if (abortSignal?.aborted) {
      return undefined;
    }
    if (!response) {
      return undefined;
    }
    return await response.blob();
  }

  public async readFileIgnoreVersion(info: FileAccessFileInfo, abortSignal?: AbortSignal): Promise<{blob: Blob, version: string|undefined}|undefined> {
    if (abortSignal?.aborted) {
      return undefined;
    }
    const cache = await this.openCache(info.location);
    const request = this.createRequestFromUrl(this.getServerUrl(info));
    const requests = await cache.keys(request, {ignoreSearch: true});
    if (abortSignal?.aborted) {
      return undefined;
    }
    if (!requests.length) {
      return undefined;
    }
    const matchingRequest = requests[0];
    const response = await cache.match(matchingRequest);
    if (abortSignal?.aborted) {
      return undefined;
    }
    let version: string|undefined;
    const indexOfVersion = matchingRequest.url.indexOf('?version=');
    if (indexOfVersion >= 0) {
      version = matchingRequest.url.substring(indexOfVersion + '?version='.length);
    }
    const blob = await response.blob();
    return {blob, version};
  }

  public async readTextFile(info: FileAccessFileInfo, abortSignal?: AbortSignal): Promise<string|undefined> {
    if (abortSignal?.aborted) {
      return undefined;
    }
    const cache = await this.openCache(info.location);
    const request = this.createRequestFromUrl(this.getServerUrl(info));
    const response = await cache.match(request);
    if (!response) {
      return undefined;
    }
    if (abortSignal?.aborted) {
      return undefined;
    }
    return await response.text();
  }

  public async writeFile(info: FileAccessFileInfo, content: Blob|string, abortSignal?: AbortSignal): Promise<void> {
    if (abortSignal?.aborted) {
      return;
    }
    const cache = await this.openCache(info.location);
    const request = this.createRequestFromUrl(this.getServerUrl(info));
    let response: Response;
    if (typeof content === 'string') {
      response = new Response(content, {status: 200, headers: {MimeType: 'text/plain', 'Content-Type': 'text/plain', 'Content-Length': content.length.toString()}});
    } else {
      const blob = content as Blob;
      response = new Response(blob, {status: 200, headers: {MimeType: blob.type, 'Content-Type': blob.type, 'Content-Length': blob.size.toString()}});
    }
    if (abortSignal?.aborted) {
      return;
    }
    await cache.put(request, response);
  }

  public async writeTextFile(info: FileAccessFileInfo, data: string, mimeType: string, abortSignal?: AbortSignal): Promise<void> {
    if (abortSignal?.aborted) {
      return;
    }
    const cache = await this.openCache(info.location);
    const request = this.createRequestFromUrl(this.getServerUrl(info));
    const response = new Response(data, {status: 200, headers: {MimeType: mimeType, 'Content-Type': mimeType, 'Content-Length': data.length.toString()}});
    if (abortSignal?.aborted) {
      return;
    }
    await cache.put(request, response);
  }

  public async deleteFile(info: FileAccessFileInfo, abortSignal?: AbortSignal): Promise<boolean> {
    if (abortSignal?.aborted) {
      return false;
    }
    const cache = await this.openCache(info.location);
    return await cache.delete(this.getServerUrl(info));
  }

  public async fileExists(info: FileAccessFileInfo, abortSignal?: AbortSignal): Promise<boolean> {
    if (abortSignal?.aborted) {
      return false;
    }
    const cache = await this.openCache(info.location);
    const cacheMatch = await cache.match(this.getServerUrl(info));
    return !!cacheMatch;
  }

  public async moveFile(infoSource: FileAccessFileInfo, infoTarget: FileAccessFileInfo, abortSignal?: AbortSignal): Promise<boolean> {
    if (abortSignal?.aborted) {
      return false;
    }
    if (this.getServerUrl(infoSource) === this.getServerUrl(infoTarget)) {
      return false;
    }
    const blob = await this.readFile(infoSource, abortSignal);
    if (abortSignal?.aborted) {
      return false;
    }
    if (!blob) {
      console.warn(`Unable to rename file ${infoSource.path} with version ${infoSource.version} in ${infoSource.location} as it does not exist`);
      return false;
    }
    await this.writeFile(infoTarget, blob, abortSignal);
    if (abortSignal?.aborted) {
      return false;
    }
    await this.deleteFile(infoSource, abortSignal);
    if (abortSignal?.aborted) {
      return false;
    }
    return true;
  }

  public async numberOfFiles(location: FileAccessLocation, abortSignal?: AbortSignal): Promise<number> {
    if (abortSignal?.aborted) {
      return 0;
    }
    const cache = await this.openCache(location);
    return (await cache.keys()).length;
  }

  public async files(location: FileAccessLocation, abortSignal?: AbortSignal): Promise<string[]> {
    if (abortSignal?.aborted) {
      return [];
    }
    const cache = await this.openCache(location);
    const keys = await cache.keys();
    return keys.map((key) => key.url);
  }

  public async filesForClientOrProject(location: FileAccessLocation, clientOrProjectId?: IdType, abortSignal?: AbortSignal): Promise<string[]> {
    if (abortSignal?.aborted) {
      return [];
    }
    return await this.files(location, abortSignal);
  }

  public async moveToErrorQueue(path: string, abortSignal?: AbortSignal): Promise<[null|string, boolean]> {
    throw new Error('not implemented');
  }

  public async moveFilesFromUploadErrorToUpload(abortSignal?: AbortSignal): Promise<number> {
    if (abortSignal?.aborted) {
      return 0;
    }
    const uploadErrorQueueCache = await this.openCache('uploadError');
    const uploadQueueCache = await this.openCache('upload');

    const keys = await uploadErrorQueueCache.keys();
    for (const request of keys) {
      if (abortSignal?.aborted) {
        return 0;
      }
      const response = await uploadErrorQueueCache.match(request);
      await uploadQueueCache.put(request, response);
      await uploadErrorQueueCache.delete(request);
    }
    return keys.length;
  }

  public async filesProcessor(location: 'upload' | 'uploadError', deleteAfterProcessing: boolean, ignoreVersion = false,
                              processorFunc: (url: string|undefined, blob: Blob, attempt: number) => Promise<FileAccessProcessorResult>, abortSignal?: AbortSignal): Promise<undefined|string[]> {
    if (abortSignal?.aborted) {
      return undefined;
    }
    const cacheMedia = await this.openCache(CACHE_NAME_MEDIA);
    const cache = await this.openCache(location);
    const keys = await cache.keys();
    const warningMessages = new Array<string>();
    for (const key of keys) {
      if (abortSignal?.aborted) {
        return undefined;
      }
      let response = await cacheMedia.match(key);
      if (!response && ignoreVersion) {
        // Attachment with matching version not found, try without search param (which contains the version).
        response = await cacheMedia.match(key, {ignoreSearch: true});
      }
      if (!response && deleteAfterProcessing) {
        const errorMessage = `Unable to upload as request with url "${key.url} no longer exists in the cache "${location}".`;
        console.warn(errorMessage);
        warningMessages.push(errorMessage);
        await cache.delete(key);
        continue;
      }
      const url = key.url;
      const attempt = +(key.headers.get('attempt') || '1');
      const blob = await response.blob();
      if (abortSignal?.aborted) {
        return undefined;
      }
      try {
        const processorResult = await processorFunc(url, blob, attempt);
        if (processorResult === 'success') {
          if (deleteAfterProcessing) {
            await cache.delete(key);
          }
        } else if (processorResult === 'failed') {
          // left empty intentionally
        } else if (processorResult === 'failedIncreaseAttempt') {
          await this.increaseAttemptOrMoveToErrorQueue(key, attempt, abortSignal);
        } else {
          throw new Error(`Unsupported processorResult "${processorResult}".`);
        }
      } catch (e) {
        if (!abortSignal?.aborted) {
          await this.increaseAttemptOrMoveToErrorQueue(key, attempt, abortSignal);
        }
      }
    }
    return warningMessages?.length ? warningMessages : undefined;
  }

  public async deleteAllFiles(location: FileAccessLocation, abortSignal?: AbortSignal): Promise<boolean> {
    const cacheName = this.getCacheNameByLocation(location);
    if (abortSignal?.aborted) {
      return;
    }
    return await caches.delete(cacheName);
  }

  public async getTotalSizeOfLocation(location: FileAccessLocation, abortSignal?: AbortSignal): Promise<number|undefined> {
    if (abortSignal?.aborted) {
      return undefined;
    }
    const cacheName = this.getCacheNameByLocation(location);
    if (!(await caches.has(cacheName))) {
      return undefined;
    }
    const cache = await caches.open(cacheName);
    let totalSize = 0;
    for (const request of await cache.keys()) {
      if (abortSignal?.aborted) {
        return undefined;
      }
      const response = await cache.match(request);
      totalSize += (await this.getSizeOfCacheResponse(response)) || 0;
    }
    return totalSize;
  }

  public async fileSize(info: FileAccessFileInfo, abortSignal?: AbortSignal): Promise<number|undefined> {
    if (abortSignal?.aborted) {
      return undefined;
    }
    const file = await this.readFile(info, abortSignal);
    if (abortSignal?.aborted) {
      return undefined;
    }
    return file ? file.size : undefined;
  }

  public async deleteAll(abortSignal?: AbortSignal) {
    for (const cacheName of Object.values(CacheStorageFileAccessUtil.CACHE_NAME_BY_FILE_ACCESS_LOCATION)) {
      if (abortSignal?.aborted) {
        return;
      }
      await caches.delete(cacheName);
    }
  }

  protected getLocalPath(info: FileAccessFileInfo): string {
    return this.getServerUrl(info);
  }

  private async increaseAttemptOrMoveToErrorQueue(request: Request, attempt: number, abortSignal?: AbortSignal) {
    if (abortSignal?.aborted) {
      return;
    }
    const newAttempt = attempt + 1;
    const uploadQueueCache = await this.openCache('upload');
    if (newAttempt > MAX_UPLOAD_ATTEMPTS) {
      const uploadErrorQueueCache = await this.openCache('uploadError');
      await uploadQueueCache.delete(request);
      await uploadErrorQueueCache.put(request, new Response(null, {status: 500, statusText: 'Upload failed'}));
    } else {
      await uploadQueueCache.delete(request);
      request.headers.set('attempt', newAttempt.toString());
      await uploadQueueCache.put(request, new Response(null, {status: 500, statusText: 'Upload failed'}));
    }
  }

  private getCacheNameByLocation(location: FileAccessLocation): string {
    const cacheName = CacheStorageFileAccessUtil.CACHE_NAME_BY_FILE_ACCESS_LOCATION[location];
    if (!cacheName) {
      throw new Error(`Unable to find cache for FileAccessLocation ${location}.`);
    }
    return cacheName;
  }

  private async openCache(location: FileAccessLocation): Promise<Cache> {
    return caches.open(this.getCacheNameByLocation(location));
  }

  private createRequestFromUrl(url: string): Request {
    return new Request(url);
  }

  private async getSizeOfCacheResponse(response: Response): Promise<number> {
    const contentLength = response.headers.get('Content-Length');
    if (contentLength) {
      return +contentLength;
    }
    const blob = await response.blob();
    return blob.size;
  }

}

export class FilesystemFileAccessUtil extends AbstractFileAccessUtil {
  private static readonly synchronizedFileAccessQueue: QueueObject<() => Promise<any>> = async.queue<any>(async (task: () => Promise<any>, callback: AsyncResultCallback<any>) => {
    try {
      const result = await task();
      callback(undefined, result);
    } catch (error) {
      callback(error);
    }
  }, 1);

  protected static readonly FOLDER_NAME_BY_FILE_ACCESS_LOCATION: {[key in FileAccessLocation]: string} = {
    media: 'media',
    upload: 'media-upload-queue',
    uploadError: 'media-upload-error'
  };
  private readonly chunkSizeInBytes = 614400; // (600KB) must be a multiple of 6

  constructor(mediaUrl: string) {
    super('FilesystemFileAccessUtil', false, false, true, false,
      ATTACHMENT_CONCURRENT_DOWNLOADS_FILESYSTEM, mediaUrl);
  }

  public async runInSynchronizedFileAccess<R>(functionToCall: () => Promise<R>, abortSignal?: AbortSignal): Promise<R> {
    return new Promise<R>((resolve, reject) => {
      FilesystemFileAccessUtil.synchronizedFileAccessQueue.push<R>(() => {
        if (abortSignal?.aborted) {
          reject(new Error('Aborted'));
          return;
        }
        return functionToCall();
      }, (error, result) => {
        if (error) {
          reject(error);
        } else {
          resolve(result);
        }
      });
      if (abortSignal) {
        abortSignal.onabort = () => {
          FilesystemFileAccessUtil.synchronizedFileAccessQueue.remove((dataContainer) => dataContainer.data === functionToCall);
          reject(new Error('Aborted'));
        };
      }
    });
  }

  public async readFile(info: FileAccessFileInfo, abortSignal?: AbortSignal): Promise<Blob|undefined> {
    if (abortSignal?.aborted) {
      return undefined;
    }
    const path = this.getPath(info);
    return await this.readFileByPath(path, abortSignal);
  }

  public async readFileIgnoreVersion(info: FileAccessFileInfo, abortSignal?: AbortSignal): Promise<{blob: Blob, version: string|undefined}|undefined> {
    if (abortSignal?.aborted) {
      return undefined;
    }
    const {folder, filenameWithoutVersion, fileExtWithDot} = this.getPathInfo(info);
    const files = await this.filesInPath(folder, abortSignal);
    const file = files.find((value) => value && value.toLowerCase().startsWith(filenameWithoutVersion.toLowerCase() + '_') && value.toLowerCase().endsWith(fileExtWithDot.toLowerCase()));
    if (!file) {
      return undefined;
    }
    const blob = await this.readFileByPath(folder + file, abortSignal);
    const version = file.substring(filenameWithoutVersion.length + 1, file.toLowerCase().lastIndexOf(fileExtWithDot));
    return {blob, version};
  }

  public async writeFile(info: FileAccessFileInfo, content: Blob|string, abortSignal?: AbortSignal, type?: 'binary' | 'text'): Promise<void> {
    if (abortSignal?.aborted) {
      return;
    }
    const path = this.getPath(info);
    return await this.writeFileByPath(path, content, abortSignal, type);
  }

  public async readTextFile(info: FileAccessFileInfo, abortSignal?: AbortSignal): Promise<string | undefined> {
    if (abortSignal?.aborted) {
      return undefined;
    }
    const path = this.getPath(info);
    return await this.readTextFileByPath(path, abortSignal);
  }

  public async writeTextFile(info: FileAccessFileInfo, data: string, mimeType: string, abortSignal?: AbortSignal): Promise<void> {
    if (abortSignal?.aborted) {
      return;
    }
    const path = this.getPath(info);
    await this.writeTextFileByPath(path, data, abortSignal);
  }

  public async writeFileByPath(path: string, content: Blob|string, abortSignal?: AbortSignal, type?: 'binary' | 'text'): Promise<void> {
    if (abortSignal?.aborted) {
      return;
    }
    const isContentBlob = !(typeof content === 'string');
    if (isContentBlob) {
      if (type !== undefined && type !== 'binary') {
        throw new Error(`Inconsistent arguments for writeFileByPath(${path}). Content was a Blob but type was ${type}.`);
      }
      const blob = content as Blob;
      const blobSize = blob.size;
      await this.writeFileInChunks(path, blob, abortSignal);
      if (abortSignal?.aborted) {
        return;
      }
      const fileSizeOnDisk = await this.fileSizeByPath(path, abortSignal);
      if (!equalWithDeviation(fileSizeOnDisk, blob.size)) {
        throw new Error(`writeFileByPath(${path}) was written in chunks but the size on disk (${fileSizeOnDisk}) does not match the blob size (${blobSize}).`);
      }
    } else {
      if (type !== undefined && type !== 'text') {
        throw new Error(`Inconsistent arguments for writeFileByPath(${path}). Content was a string but type was ${type}.`);
      }
      const data = content as string;
      await this.runInSynchronizedFileAccess(async () => {
        await Filesystem.writeFile({directory: Directory.Data, path, encoding: Encoding.UTF8, data, recursive: true});
      }, abortSignal);
    }
  }

  private async writeFileInChunks(path: string, blob: Blob, abortSignal?: AbortSignal) {
    if (abortSignal?.aborted) {
      return;
    }
    await this.convertToBase64Chunks(blob, this.chunkSizeInBytes, async (value: string, first: boolean): Promise<void> => {
      await this.runInSynchronizedFileAccess(async () => {
        if (first) {
          await Filesystem.writeFile({directory: Directory.Data, path, data: value, recursive: true});
        } else {
          await Filesystem.appendFile({directory: Directory.Data, path, data: value});
        }
      }, abortSignal);
    });
  }

  private async convertToBase64Chunks(blob: Blob, chunkSize: number, chunk: (value: string, first?: boolean) => Promise<void>): Promise<void> {
    if (chunkSize % 6) {
      // eslint-disable-next-line
      throw {error: 'Chunksize must be a multiple of 6!'};
    } else {
      const blobSize: number = blob.size;
      while (blob.size > chunkSize) {
        const value: string = await convertBlobToBase64(blob.slice(0, chunkSize));
        await chunk(blobSize === blob.size ? value : value.split(',')[1], blobSize === blob.size);
        blob = blob.slice(chunkSize);
      }
      const lastValue: string = await convertBlobToBase64(blob.slice(0, blob.size));
      await chunk(lastValue.split(',')[1], blobSize === blob.size);
    }
  }

  public async deleteFile(info: FileAccessFileInfo, abortSignal?: AbortSignal): Promise<boolean> {
    try {
      if (abortSignal?.aborted) {
        return false;
      }
      await this.runInSynchronizedFileAccess(async () => {
        await Filesystem.deleteFile({directory: Directory.Data, path: this.getPath(info)});
      }, abortSignal);
      return true;
    } catch (e) {
      if (this.isNoSuchFileOrDirectoryError(e)) {
        return false;
      }  else {
        throw e;
      }
    }
  }

  public async fileExists(info: FileAccessFileInfo, abortSignal?: AbortSignal): Promise<boolean> {
    try {
      if (abortSignal?.aborted) {
        return false;
      }
      const statResult = await this.runInSynchronizedFileAccess(async () => {
        return await Filesystem.stat({directory: Directory.Data, path: this.getPath(info)});
      }, abortSignal);
      if (!statResult) {
        return false;
      }
      return true;
    } catch (e) {
      if (this.isNoSuchFileOrDirectoryError(e)) {
        return false;
      } else {
        throw e;
      }
    }
  }

  public async getTotalSizeOfLocation(location: FileAccessLocation, abortSignal?: AbortSignal): Promise<number | undefined> {
    if (abortSignal?.aborted) {
      return undefined;
    }
    const files = await this.files(location, abortSignal);
    if (abortSignal?.aborted) {
      return undefined;
    }
    let totalSize = 0;
    for (const file of files) {
      if (abortSignal?.aborted) {
        return undefined;
      }
      const size = await this.fileSizeByPath(file, abortSignal);
      if (size !== undefined) {
        totalSize += size;
      }
    }
    return totalSize;
  }

  public async moveFile(infoSource: FileAccessFileInfo, infoTarget: FileAccessFileInfo, abortSignal?: AbortSignal): Promise<boolean> {
    try {
      if (abortSignal?.aborted) {
        return false;
      }
      const pathSource = this.getPath(infoSource);
      const pathTarget = this.getPath(infoTarget);
      if (pathSource === pathTarget) {
        return false;
      }

      await this.runInSynchronizedFileAccess(async () => {
        await Filesystem.rename({from: pathSource, directory: Directory.Data, to: pathTarget, toDirectory: Directory.Data});
      }, abortSignal);
      return true;
    } catch (e) {
      if (this.isNoSuchFileOrDirectoryError(e)) {
        return false;
      }  else {
        throw e;
      }
    }
  }

  public async fileSize(info: FileAccessFileInfo, abortSignal?: AbortSignal): Promise<number|undefined> {
    if (abortSignal?.aborted) {
      return undefined;
    }
    return await this.fileSizeByPath(this.getPath(info), abortSignal);
  }

  public async deleteAll(abortSignal?: AbortSignal) {
    for (const path of Object.values(FilesystemFileAccessUtil.FOLDER_NAME_BY_FILE_ACCESS_LOCATION)) {
      try {
        if (abortSignal?.aborted) {
          return;
        }
        await this.runInSynchronizedFileAccess(async () => {
          await Filesystem.rmdir({directory: Directory.Data, path, recursive: true});
        }, abortSignal);
      } catch (e) {
        if (this.isNoSuchFileOrDirectoryError(e)) {
          continue;
        }  else {
          throw e;
        }
      }
    }
  }

  public async numberOfFiles(location: FileAccessLocation, abortSignal?: AbortSignal): Promise<number> {
    if (abortSignal?.aborted) {
      return 0;
    }
    let numberOfFiles = 0;
    for (const folder of await this.getRootFolders(location, abortSignal)) {
      try {
        const resultFiles = await this.runInSynchronizedFileAccess(async () => {
          return await Filesystem.readdir({directory: Directory.Data, path: folder});
        }, abortSignal);
        if (abortSignal?.aborted) {
          return 0;
        }
        numberOfFiles += resultFiles.files.length;
      } catch (e) {
        if (this.isNoSuchFileOrDirectoryError(e)) {
          continue;
        }  else {
          throw e;
        }
      }
    }
    return numberOfFiles;
  }

  public async files(location: FileAccessLocation, abortSignal?: AbortSignal): Promise<string[]> {
    try {
      if (abortSignal?.aborted) {
        return [];
      }
      let files = new Array<string>();
      for (const path of await this.getRootFolders(location, abortSignal)) {
        try {
          const resultFolders = await this.runInSynchronizedFileAccess(async () => {
            return await Filesystem.readdir({directory: Directory.Data, path});
          }, abortSignal);
          if (abortSignal?.aborted) {
            return [];
          }
          files = files.concat(resultFolders.files.map((file) => path + '/' + file.name));
        } catch (e) {
          if (this.isNoSuchFileOrDirectoryError(e)) {
            continue;
          } else {
            throw e;
          }
        }
      }
      return files;
    } catch (e) {
      throw new Error(`Error in FilesystemFileAccessUtil.files(${location}). ${convertErrorToMessage(e)}`);
    }
  }

  public async filesForClientOrProject(location: FileAccessLocation, clientOrProjectId?: IdType, abortSignal?: AbortSignal): Promise<string[]> {
    if (abortSignal?.aborted) {
      return [];
    }
    const locationPath = FilesystemFileAccessUtil.FOLDER_NAME_BY_FILE_ACCESS_LOCATION[location];
    const path = locationPath + '/' + this.getClientOrProjectFolderName(clientOrProjectId);
    try {
      const resultFolders = await this.runInSynchronizedFileAccess(async () => {
        return await Filesystem.readdir({directory: Directory.Data, path});
      }, abortSignal);
      if (abortSignal?.aborted) {
        return [];
      }
      return resultFolders.files.map((file) => path + '/' + file.name);
    } catch (e) {
      if (this.isNoSuchFileOrDirectoryError(e)) {
        return [];
      }  else {
        throw e;
      }
    }
  }

  public async moveToErrorQueue(path: string, abortSignal?: AbortSignal): Promise<[null|string, boolean]> {
    try {
      if (abortSignal?.aborted) {
        return null;
      }
      return [null, await this.moveFileByPath(path, this.getPathOfDifferentLocation(path, 'uploadError'), abortSignal)];
    } catch (e) {
      const message = `Unable to move to the upload error queue; error: '${convertErrorToMessage(e)}'`;
      console.error(message);
      return [message, false];
    }
  }

  public async deleteAllFiles(location: FileAccessLocation, abortSignal?: AbortSignal): Promise<boolean> {
    try {
      if (abortSignal?.aborted) {
        return;
      }
      const path = this.getFolderNameByLocation(location);
      await this.runInSynchronizedFileAccess(async () => {
        await Filesystem.rmdir({directory: Directory.Data, path, recursive: true});
      }, abortSignal);
    } catch (e) {
      if (this.isNoSuchFileOrDirectoryError(e)) {
        return false;
      }  else {
        throw e;
      }
    }
  }

  public async moveFilesFromUploadErrorToUpload(abortSignal?: AbortSignal): Promise<number> {
    if (abortSignal?.aborted) {
      return 0;
    }
    const files = await this.files('uploadError', abortSignal);
    if (abortSignal?.aborted) {
      return 0;
    }
    for (const filePathUploadError of files) {
      if (abortSignal?.aborted) {
        return 0;
      }
      const blob = await this.readFileByPath(filePathUploadError, abortSignal);
      const filePathUpload = this.getPathOfDifferentLocation(filePathUploadError, 'upload');
      const urlWithAttempts = await blob.text();
      const url = urlWithAttempts.split(URL_WITH_ATTEMPTS_SEPARATOR)[0];
      const data = url;
      await this.writeFileByPath(filePathUpload, data, abortSignal, 'text');
      await this.deleteFileByPath(filePathUploadError, abortSignal);
    }
    return files.length;
  }

  private async deleteFileIfEmptyAndIgnoreErrors(filePath: string, abortSignal?: AbortSignal): Promise<boolean> {
    try {
      if (abortSignal?.aborted) {
        return false;
      }
      const fileSize = await this.fileSizeByPath(filePath, abortSignal);
      if (abortSignal?.aborted) {
        return false;
      }
      console.warn(`File ${filePath}, fileSize=${fileSize}.`);
      if (fileSize === 0) {
        await this.deleteFileByPath(filePath, abortSignal);
      }
      return true;
    } catch (e) {
      console.error(`Error deleteFileIfEmptyAndIgnoreErrors fileByPath (${filePath}). ${convertErrorToMessage(e)}`);
      return false;
    }
  }

  public async filesProcessor(location: 'upload' | 'uploadError', deleteAfterProcessing: boolean, ignoreVersion = false,
                              processorFunc: (url: string|undefined, blob: Blob, attempt: number) => Promise<FileAccessProcessorResult>, abortSignal?: AbortSignal): Promise<undefined|string[]> {
    if (abortSignal?.aborted) {
      return undefined;
    }
    const files = await this.files(location, abortSignal);
    if (abortSignal?.aborted) {
      return undefined;
    }
    const warningMessages = new Array<string>();
    for (const file of files) {
      if (abortSignal?.aborted) {
        return undefined;
      }
      let urlAttemptObj: {url: string, attempt: number} | undefined;
      try {
        urlAttemptObj = await this.readFileByPathWithUrlAndAttempt(file, abortSignal);
        if (abortSignal?.aborted) {
          return undefined;
        }
        if (!urlAttemptObj) {
          const errorMessage = `Unable to upload as request with path "${file} no longer exists in the cache "${location}".`;
          console.warn(errorMessage);
          warningMessages.push(errorMessage);
          continue;
        }
      } catch (e) {
        const errorMessage = `Error reading fileByPath (${file}). ${convertErrorToMessage(e)}`;
        console.error(errorMessage);
        warningMessages.push(errorMessage);
        if (errorMessage === 'Load failed') {
          await this.deleteFileIfEmptyAndIgnoreErrors(file, abortSignal);
        }
        continue;
      }
      const {url, attempt} = urlAttemptObj;
      const mediaFilePath = this.getPathOfDifferentLocation(file, 'media');
      let blob: Blob;
      try {
        try {
          blob = await this.readFileByPath(mediaFilePath, abortSignal);
          if (abortSignal?.aborted) {
            return undefined;
          }
        } catch (error) {
          // readFileByPath my throw an Exception if the file was not found. So try to read it without version.
        }
        if (!blob && ignoreVersion) {
          const readFileByPathResult = await this.readFileByPathIgnoreVersion(mediaFilePath, 'media', abortSignal);
          if (abortSignal?.aborted) {
            return undefined;
          }
          if (readFileByPathResult?.blob) {
            blob = readFileByPathResult?.blob;
          }
        }
        if (!blob && deleteAfterProcessing) {
          const errorMessage = `Unable to upload as request with url "${url} no longer exists in the cache "${location}".`;
          console.warn(errorMessage);
          warningMessages.push(errorMessage);
          await this.deleteFileByPath(file, abortSignal);
          if (abortSignal?.aborted) {
            return undefined;
          }
          continue;
        }
      } catch (e) {
        const errorMessage = `Error reading media file from path (${mediaFilePath}). Continue with next file. ${convertErrorToMessage(e)}`;
        console.error(errorMessage);
        warningMessages.push(errorMessage);
        continue;
      }
      try {
        const processorResult = await processorFunc(url, blob, attempt);
        if (processorResult === 'success') {
          if (deleteAfterProcessing) {
            await this.deleteFileByPath(file, abortSignal);
          }
        } else if (processorResult === 'failed') {
        } else if (processorResult === 'failedIncreaseAttempt') {
          await this.increaseAttemptOrMoveToErrorQueue(file, url, blob.type, attempt, abortSignal);
        } else {
          throw new Error(`Unsupported processorResult "${processorResult}".`);
        }
      } catch (e) {
        try {
          await this.increaseAttemptOrMoveToErrorQueue(file, url, blob.type, attempt, abortSignal);
        } catch (errorIncreaseAttempt) {
          const errorMessage = `Error in increaseAttemptOrMoveToErrorQueue for file "${file}" and url ${url}`;
          console.error(errorMessage);
          warningMessages.push(errorMessage);
          continue;
        }
      }
    }
    return warningMessages?.length ? warningMessages : undefined;
  }

  protected getLocalPath(info: FileAccessFileInfo): string {
    return this.getPath(info);
  }

  private async moveFileByPath(pathFrom: string, pathTo: string, abortSignal?: AbortSignal): Promise<boolean> {
    if (abortSignal?.aborted) {
      return false;
    }
    if (pathFrom === pathTo) {
      return false;
    }
    try {
      const blob = await this.readFileByPath(pathFrom, abortSignal);
      if (abortSignal?.aborted) {
        return false;
      }
      if (!blob) {
        return false;
      }
      await this.writeFileByPath(pathTo, blob, abortSignal);
      if (abortSignal?.aborted) {
        return false;
      }
      await this.deleteFileByPath(pathFrom, abortSignal);
      return true;
    } catch (e) {
      if (this.isNoSuchFileOrDirectoryError(e)) {
        return false;
      }  else {
        throw e;
      }
    }
  }

  private async readTextFileByPath(path: string, abortSignal?: AbortSignal): Promise<string | undefined> {
    try {
      if (abortSignal?.aborted) {
        return undefined;
      }
      const result = await this.runInSynchronizedFileAccess(async () => {
        return await Filesystem.readFile({directory: Directory.Data, path, encoding: Encoding.UTF8});
      }, abortSignal);
      return result.data as string;
    } catch (e) {
      if (this.isNoSuchFileOrDirectoryError(e)) {
        return undefined;
      } else {
        throw e;
      }
    }
  }

  private async writeTextFileByPath(path: string, data: string, abortSignal?: AbortSignal): Promise<string | undefined> {
    try {
      if (abortSignal?.aborted) {
        return undefined;
      }
      const result = await this.runInSynchronizedFileAccess(async () => {
        return await Filesystem.writeFile({directory: Directory.Data, path, encoding: Encoding.UTF8, data});
      }, abortSignal);
      return result.uri;
    } catch (e) {
      if (this.isNoSuchFileOrDirectoryError(e)) {
        return undefined;
      } else {
        throw e;
      }
    }
  }

  private async filesInPath(path: string, abortSignal?: AbortSignal): Promise<string[]> {
    try {
      if (abortSignal?.aborted) {
        return [];
      }
      return await this.runInSynchronizedFileAccess(async (): Promise<string[]> => {
        return (await Filesystem.readdir({directory: Directory.Data, path})).files.map((fileInfo) => fileInfo.name);
      }, abortSignal);
    } catch (e) {
      if (this.isNoSuchFileOrDirectoryError(e)) {
        return [];
      }  else {
        throw new Error(`Error in filesInPath("${path}") - ${convertErrorToMessage(e)}`);
      }
    }
  }

  private async readFileByPath(path: string, abortSignal?: AbortSignal): Promise<Blob|undefined> {
    let fileSrc: string|undefined;
    let response: Response|undefined;
    try {
      if (abortSignal?.aborted) {
        return undefined;
      }
      const uriResult = await this.runInSynchronizedFileAccess(async () => {
        return await Filesystem.getUri({directory: Directory.Data, path});
      }, abortSignal);
      fileSrc = Capacitor.convertFileSrc(   uriResult.uri);
      if (abortSignal?.aborted) {
        return undefined;
      }
      response = await fetchWithTimeout(fileSrc, abortSignal, LOCAL_FILE_FETCH_TIMEOUT_IN_MS);
      if (response.status === 404) {
        return undefined;
      } else if (!response.ok) {
        if (response.status === 0) {
          // on iOS Videos are stored in the local file system but are returned with status 0. Nevertheless, try to download the blob otherwise the sync will always try to download the file again.
          console.warn(`fetching data with uri ${uriResult} from local filesystem failed with status ${response.status} and message "${response.statusText}"`);
        } else {
          throw new Error(`Error reading file from filesystem with uri "${uriResult.uri}". ${response.status} ${response.statusText}`);
        }
      }
      if (abortSignal?.aborted) {
        return undefined;
      }
      return await response.blob();
    } catch (e) {
      if (this.isNoSuchFileOrDirectoryError(e)) {
        return undefined;
      } else if (this.isNoSuchFileOrDirectoryErrorForReadFileByPath(e)) {
        return undefined;
      } else {
        throw new Error(`Error in readFileByPath("${path}") - fileSrc="${
          fileSrc ?? 'undefined'
        }", response.status=${response?.status ?? 'undefined'}, response.statusText="${
          response?.statusText ?? undefined
        }" ${convertErrorToMessage(e)}`);
      }
    }
  }

  private async readFileByPathIgnoreVersion(path: string, location: FileAccessLocation, abortSignal?: AbortSignal): Promise<{blob: Blob, version: string}|undefined> {
    // The value of argument version does not matter here, because it's only relevant for the return property path, which we don't use.
    if (abortSignal?.aborted) {
      return undefined;
    }
    const {filenameWithoutVersion, fileExtWithDot} = this.getPathInfo({path, version: '123456', location});
    const folder = path.substring(0, path.lastIndexOf('/'));
    const files = await this.filesInPath(folder, abortSignal);
    if (abortSignal?.aborted) {
      return undefined;
    }
    const file = files.find((value) => value && value.toLowerCase().startsWith(filenameWithoutVersion.toLowerCase() + '_') && value.toLowerCase().endsWith(fileExtWithDot.toLowerCase()));
    if (!file) {
      return undefined;
    }
    const blob = await this.readFileByPath(folder + file, abortSignal);
    if (abortSignal?.aborted) {
      return undefined;
    }
    if (!blob) {
      return undefined;
    }
    const version = file.substring(filenameWithoutVersion.length + 1, file.toLowerCase().lastIndexOf(fileExtWithDot));
    return {blob, version};
  }

  private async deleteFileByPath(path: string, abortSignal?: AbortSignal): Promise<boolean> {
    try {
      if (abortSignal?.aborted) {
        return false;
      }
      await this.runInSynchronizedFileAccess(async () => {
        await Filesystem.deleteFile({directory: Directory.Data, path});
      }, abortSignal);
      return true;
    } catch (e) {
      if (this.isNoSuchFileOrDirectoryError(e)) {
        return false;
      }  else {
        throw e;
      }
    }
  }

  private async fileSizeByPath(path: string, abortSignal?: AbortSignal): Promise<number|undefined> {
    try {
      if (abortSignal?.aborted) {
        return undefined;
      }
      const readFileResult = await this.runInSynchronizedFileAccess(async () => {
        return await Filesystem.stat({directory: Directory.Data, path});
      }, abortSignal);
      return readFileResult.size;
    } catch (e) {
      if (this.isNoSuchFileOrDirectoryError(e)) {
        return undefined;
      }  else {
        throw e;
      }
    }
  }

  private isNoSuchFileOrDirectoryError(error: any): boolean {
    const errorMessage: string|undefined = error?.message;
    if (!errorMessage) {
      return false;
    }
    return CapacitorErrorMessagePartsNotExisting.some((notExistingErrorMessage) => errorMessage.includes(notExistingErrorMessage));
  }

  private isNoSuchFileOrDirectoryErrorForReadFileByPath(error: any): boolean {
    const errorMessage: string|undefined = error?.message;
    if (!errorMessage) {
      return false;
    }
    return CapacitorErrorMessagePartsNotExistingReadFileByPath.some((notExistingErrorMessage) => errorMessage.includes(notExistingErrorMessage));
  }

  private async readFileByPathWithUrlAndAttempt(path: string, abortSignal?: AbortSignal): Promise<{url: string, attempt: number} | undefined> {
    if (abortSignal?.aborted) {
      return undefined;
    }
    const blobWithUrlText = await this.readTextFileByPath(path, abortSignal);
    if (abortSignal?.aborted) {
      return undefined;
    }
    if (!blobWithUrlText) {
      return undefined;
    }
    const blobWithUrlParts = blobWithUrlText.split(URL_WITH_ATTEMPTS_SEPARATOR);
    const url = blobWithUrlParts[0];
    const attempt = blobWithUrlParts.length >= 2 ? +blobWithUrlParts[1] : 1;
    return {url, attempt};
  }

  private async increaseAttemptOrMoveToErrorQueue(path: string, url: string, mimeType: string, attempt: number, abortSignal?: AbortSignal) {
    if (abortSignal?.aborted) {
      return;
    }
    const newAttempt = attempt + 1;
    if (newAttempt > MAX_UPLOAD_ATTEMPTS) {
      await this.moveFileByPath(path, this.getPathOfDifferentLocation(path, 'uploadError'), abortSignal);
    } else {
      await this.writeFileByPathWithUrlAndAttempt(path, url, mimeType, newAttempt, abortSignal);
    }
  }

  private async writeFileByPathWithUrlAndAttempt(path: string, url: string, mimeType: string, attempt: number, abortSignal?: AbortSignal) {
    if (abortSignal?.aborted) {
      return;
    }
    const content = `${url}${URL_WITH_ATTEMPTS_SEPARATOR}${attempt + 1}`;
    await this.writeFileByPath(path, content, abortSignal, 'text');
  }

  private async getRootFolders(location: FileAccessLocation, abortSignal?: AbortSignal): Promise<string[]> {
    if (abortSignal?.aborted) {
      return [];
    }
    const getRooterFoldersFn = async (): Promise<string[]> => {
      if (abortSignal?.aborted) {
        return [];
      }
      const locationPath = FilesystemFileAccessUtil.FOLDER_NAME_BY_FILE_ACCESS_LOCATION[location];
      const resultRoot = await this.runInSynchronizedFileAccess(async () => {
        return await Filesystem.readdir({directory: Directory.Data, path: locationPath});
      }, abortSignal);
      return resultRoot.files.map((folder) => this.stripTrailingSlashOrBackslash(locationPath) + '/' + this.stripTrailingSlashOrBackslash(folder.name));
    };

    try {
      return await getRooterFoldersFn();
    } catch (e) {
      if (this.isNoSuchFileOrDirectoryError(e)) {
        await this.createRootFolders();
        return await getRooterFoldersFn();
      } else {
        throw e;
      }
    }
  }

  private stripTrailingSlashOrBackslash(path: string): string {
    if (path?.length && (path.endsWith('/') || path.endsWith('\\'))) {
      return path.substring(0, path.length - 1);
    }
    return path;
  }

  private async createRootFolders() {
    for (const path of Object.values(FilesystemFileAccessUtil.FOLDER_NAME_BY_FILE_ACCESS_LOCATION)) {
      try {
        await this.runInSynchronizedFileAccess(async () => {
          await Filesystem.mkdir({directory: Directory.Data, path, recursive: true});
        });
      } catch (e) {
        console.error(`Error creating directory "${path}": ${convertErrorToMessage(e)}`);
      }
    }
  }

  private getFolderNameByLocation(location: FileAccessLocation): string {
    const folder = FilesystemFileAccessUtil.FOLDER_NAME_BY_FILE_ACCESS_LOCATION[location];
    if (!folder) {
      throw new Error(`Unable to find folder for FileAccessLocation ${location}.`);
    }
    return this.stripTrailingSlashOrBackslash(folder);
  }

  private getClientOrProjectFolderName(clientOrProjectId?: IdType): string {
    return clientOrProjectId || 'nonClientAware';
  }

  private getPathInfo(info: FileAccessFileInfo): {folder: string, path: string, filenameWithoutVersion: string, fileExtWithDot: string} {
    const extIndex = info.path.lastIndexOf('.');
    const ext = extIndex === -1 ? '' : info.path.substring(extIndex + 1);
    const pathWithoutExt = extIndex === -1 ? info.path : info.path.substring(0, extIndex);
    const pathIndex = pathWithoutExt.lastIndexOf('/');
    const name = pathIndex === -1 ? pathWithoutExt : pathWithoutExt.substring(pathIndex + 1);
    const extWithDot = ext && ext !== '' ? '.' + ext : '';
    const clientOrProjectOrDefault = this.getClientOrProjectFolderName(info.clientOrProjectId);
    const folder = `${this.getFolderNameByLocation(info.location)}/${clientOrProjectOrDefault}/`;
    return {
      folder,
      path: `${folder}${name}_${info.version}${extWithDot}`,
      filenameWithoutVersion: name,
      fileExtWithDot: extWithDot
    };
  }

  private getPath(info: FileAccessFileInfo): string {
    return this.getPathInfo(info).path;
  }

  private getPathOfDifferentLocation(path: string, location: FileAccessLocation): string {
    const locationPath = FilesystemFileAccessUtil.FOLDER_NAME_BY_FILE_ACCESS_LOCATION[location];
    const firstSlash = path.indexOf('/');
    if (firstSlash === -1) {
      throw new Error(`Invalid path "${path}".`);
    }
    return locationPath + path.substring(firstSlash);
  }
}

function changedAtToVersion(changedAt: Date|string): string {
  const changedAtDate: Date = _.isDate(changedAt) ? changedAt as Date : new Date(changedAt);
  return '' + changedAtDate.getTime();
}

function versionForAttachment(attachment: Attachment): string {
  return changedAtToVersion(attachment.changedAt);
}

export interface AttachmentPathWithProperty {
  attachmentPath: string;
  attachmentPathProperty: AttachmentPathProperty;
}

function getFirstAttachmentPathWithProperty(attachment, ...attachmentPathProperties: Array<AttachmentPathProperty>): AttachmentPathWithProperty | null {
  for (const attachmentPathProperty of attachmentPathProperties) {
    if (attachment[attachmentPathProperty]) {
      return {attachmentPath: attachment[attachmentPathProperty], attachmentPathProperty};
    }
  }
  return null;
}

export function getAttachmentPath(attachment: Attachment, attachmentType: OfflineAttachmentType | AttachmentPathProperty, strictMatch = false):
  {attachmentPath: string, attachmentPathProperty: AttachmentPathProperty}|null {
  switch (attachmentType) {
    case 'image':
      return strictMatch
        ? getFirstAttachmentPathWithProperty(attachment, 'filePath')
        : getFirstAttachmentPathWithProperty(attachment, 'filePath', 'mediumThumbnailPath', 'thumbnailPath', 'bigThumbnailPath');
    case 'thumbnail':
      return strictMatch ? getFirstAttachmentPathWithProperty(attachment, 'mediumThumbnailPath', 'thumbnailPath', 'bigThumbnailPath') :
        getFirstAttachmentPathWithProperty(attachment, 'mediumThumbnailPath', 'thumbnailPath', 'bigThumbnailPath', 'filePath');
      break;
    case 'filePath': return getFirstAttachmentPathWithProperty(attachment, 'filePath');
    case 'bigThumbnailPath': return getFirstAttachmentPathWithProperty(attachment, 'bigThumbnailPath');
    case 'thumbnailPath': return getFirstAttachmentPathWithProperty(attachment, 'thumbnailPath');
    case 'mediumThumbnailPath': return getFirstAttachmentPathWithProperty(attachment, 'mediumThumbnailPath');
    default:
      throw new Error(`attachmentType ${attachmentType} not supported.`);
  }
}

function getMediaUriFromAttachment(attachment: Attachment, attachmentType: OfflineAttachmentType|AttachmentPathProperty, strictMatch = false):
    {mediaUri: string, attachmentPathProperty: AttachmentPathProperty}|null {
  const {attachmentPath, attachmentPathProperty} = getAttachmentPath(attachment, attachmentType, strictMatch);
  if (!attachmentPath) {
    return null;
  }
  return {mediaUri: attachmentPath + '?version=' + versionForAttachment(attachment), attachmentPathProperty};
}

async function getAttachmentFromCache(attachment: Attachment, attachmentType: OfflineAttachmentType, fileAccessUtil: AbstractFileAccessUtil): Promise<Blob|undefined> {
  const result = await fileAccessUtil.readAttachmentByType(attachment, attachmentType);
  return result?.blob || undefined;
}

export type HttpMethod = 'GET' | 'HEAD' | 'POST' | 'PUT' | 'DELETE' | 'CONNECT' | 'OPTIONS' | 'TRACE' | 'PATCH';

export function createRequestFromUrl(url: string, authenticationToken: string, deviceUuid: string, method: HttpMethod = 'GET', body?: BodyInit | null, contentType?: string): Request {
  const headers = {
    Authorization: 'Token ' + authenticationToken,
    'Device-Uuid': deviceUuid
  };
  if (contentType) {
    _.set(headers, 'Accept', contentType);
    _.set(headers, 'Content-Type', contentType);
  }
  return new Request(url, {method, body, headers});
}

export function abortControllerOrAbortSignalToSignal(abortControllerOrAbortSignal?: AbortController|AbortSignal): AbortSignal|undefined {
  if (!abortControllerOrAbortSignal) {
    return undefined;
  }
  if ('signal' in abortControllerOrAbortSignal) {
    return (abortControllerOrAbortSignal as AbortController).signal;
  }
  return abortControllerOrAbortSignal as AbortSignal;
}

async function downloadAttachment(attachment: Attachment, attachmentType: OfflineAttachmentType|AttachmentPathProperty, tokenGetter: TokenGetter, deviceUuid: string,
                                  fileAccessUtil: AbstractFileAccessUtil,
                                  storeInCacheStorage = false, forceDownloadFile = false, abortControllerOrAbortSignal?: AbortController|AbortSignal): Promise<BlobDownloaded|null> {
  const abortSignal = abortControllerOrAbortSignalToSignal(abortControllerOrAbortSignal);
  if (abortSignal?.aborted) {
    return null;
  }
  if (!forceDownloadFile) {
    const blob = (await fileAccessUtil.readAttachmentByType(attachment, attachmentType, 'media', abortSignal))?.blob;
    if (abortSignal?.aborted) {
      return null;
    }
    if (blob) {
      return {
        blob,
        downloaded: false
      };
    }
  }
  const {mediaUri, attachmentPathProperty} = getMediaUriFromAttachment(attachment, attachmentType);
  const url = fileAccessUtil.mediaUrl + mediaUri;
  const authenticationToken = await tokenGetter.getTokenPromise();
  const request = createRequestFromUrl(url, authenticationToken, deviceUuid);
  const response = await fetchWithTimeout(request, abortControllerOrAbortSignal);
  if (!response.ok) {
    if (response.status === 404) {
      return null;
    }
    if (response.status === 401) {
      tokenGetter.invalidateToken(authenticationToken);
      throw new UnauthorizedError(`Download attachment from url "${url}" failed with statusCode ${response.status} and statusText "${response.statusText}"`);
    }
    throw new Error(`Download attachment from url "${url}" failed with statusCode ${response.status} and statusText "${response.statusText}"`);
  }
  if (!storeInCacheStorage || fileSizeExceedsMaxLimit(attachment, attachmentType)) {
    return {
      blob: await response.blob(),
      downloaded: true
    };
  }
  const blobDownloaded = await response.blob();
  await fileAccessUtil.writeAttachment(attachment, attachmentPathProperty, blobDownloaded);
  return {
    blob: blobDownloaded,
    downloaded: true
  };
}

function fileSizeExceedsMaxLimit(attachment: Attachment, attachmentType: OfflineAttachmentType|AttachmentPathProperty, strictMatch = false): boolean {
  const attachmentPathWithProperty = getAttachmentPath(attachment, attachmentType, strictMatch);
  if (!attachmentPathWithProperty) {
    return false;
  }
  return attachmentPathWithProperty.attachmentPathProperty === 'filePath' && attachment.fileSize !== undefined && attachment.fileSize > ATTACHMENT_DOWNLOAD_MAX_SIZE_IN_BYTES;
}

async function downloadAttachmentIfNotExisting(attachment: Attachment, attachmentType: OfflineAttachmentType|AttachmentPathProperty, tokenGetter: TokenGetter, deviceUuid: string,
                                               fileAccessUtil: AbstractFileAccessUtil, forceDownloadFile = false, abortController?: AbortController): Promise<boolean> {
  if (fileSizeExceedsMaxLimit(attachment, attachmentType)) {
    return false;
  }
  if (!forceDownloadFile) {
    const exists = await fileAccessUtil.attachmentExists(attachment, attachmentType);
    if (exists) {
      return false;
    }
  }
  const result = await replyOnUnauthorized(async () => {
    return await downloadAttachment(attachment, attachmentType, tokenGetter, deviceUuid, fileAccessUtil, true, true, abortController);
  });
  return result?.downloaded ?? false;
}

export async function isAttachmentScheduledForUpload(attachment: Attachment, fileAccessUtil: AbstractFileAccessUtil): Promise<boolean> {
  if (!attachment.filePath) {
    return false;
  }
  return await fileAccessUtil.attachmentExists(attachment, 'filePath', true, 'upload')
    || await fileAccessUtil.attachmentExists(attachment, 'filePath', true, 'uploadError');
}

export async function isAttachmentScheduledForUploadOrRecentlyCreated(attachment: Attachment, fileAccessUtil: AbstractFileAccessUtil, createdAfter: Date): Promise<boolean> {
  if (!attachment.filePath) {
    return false;
  }
  if (await isAttachmentScheduledForUpload(attachment, fileAccessUtil)) {
    return true;
  }
  const createdAt = convertISOStringToDate(attachment.createdAt);
  if (createdAt.getTime() >= Date.now() - MIN_AGE_IN_MS_BEFORE_DELETING_ATTACHMENTS) {
    return true;
  }
  if (createdAt.getTime() >= createdAfter.getTime()) {
    return true;
  }
  return false;
}

async function syncFiles(attachment: Attachment, tokenGetter: TokenGetter, deviceUuid: string, fileAccessUtil: AbstractFileAccessUtil, attachmentSyncMode: AttachmentSyncMode,
                         dataSyncStarted: Date, localFiles: Set<string> | undefined, forceDownloadThumbnails = false): Promise<boolean> {
  const filePathSet = !!attachment.filePath;
  const thumbnailAttachmentPathResult = getAttachmentPath(attachment, 'thumbnail', true);
  const thumbnailAttachmentPath = thumbnailAttachmentPathResult?.attachmentPath;
  const thumbnailAttachmentPathProperty = thumbnailAttachmentPathResult?.attachmentPathProperty;
  const thumbnailPathSet = !!thumbnailAttachmentPath;
  let changedAny = false;
  // if localFiles is not provided (undefined) we have to call downloadFile that checks whether the file is available.
  let isFileInLocalCache: boolean | undefined;
  let isThumbnailInLocalCache: boolean | undefined;
  if (localFiles) {
    const localPathFile = fileAccessUtil.getLocalPathForAttachment(attachment, 'filePath');
    isFileInLocalCache = localFiles.has(localPathFile);
    if (thumbnailAttachmentPathProperty) {
      const localPathThumbnail = fileAccessUtil.getLocalPathForAttachment(attachment, thumbnailAttachmentPathProperty);
      isThumbnailInLocalCache = localFiles.has(localPathThumbnail);
    }
  }

  switch (attachmentSyncMode) {
    case 'THUMBNAIL_AND_IMAGE':
    case 'THUMBNAIL_AND_IMAGE_FOR_OPEN':
      if (filePathSet && !isFileInLocalCache) {
        const forceDownload = isFileInLocalCache !== undefined;
        const downloaded = await downloadAttachmentIfNotExisting(attachment, 'filePath', tokenGetter, deviceUuid, fileAccessUtil, forceDownload);
        changedAny = changedAny || downloaded;
      }
      if (thumbnailPathSet && !isThumbnailInLocalCache) {
        const forceDownload = forceDownloadThumbnails || isThumbnailInLocalCache !== undefined;
        const downloaded = await downloadAttachmentIfNotExisting(attachment, thumbnailAttachmentPathProperty, tokenGetter, deviceUuid, fileAccessUtil, forceDownload);
        changedAny = changedAny || downloaded;
      }
      return changedAny;
    case 'THUMBNAIL':
      if (thumbnailPathSet && !isThumbnailInLocalCache) {
        const forceDownload = forceDownloadThumbnails || isThumbnailInLocalCache !== undefined;
        const downloaded = await downloadAttachmentIfNotExisting(attachment, thumbnailAttachmentPathProperty, tokenGetter, deviceUuid, fileAccessUtil, forceDownload);
        changedAny = changedAny || downloaded;
        if (filePathSet && (isFileInLocalCache === true || isFileInLocalCache === undefined)
          && !(await isAttachmentScheduledForUploadOrRecentlyCreated(attachment, fileAccessUtil, dataSyncStarted))) {
          const deleted = await fileAccessUtil.deleteAttachment(attachment, 'filePath', true);
          changedAny = changedAny || deleted;
        }
      } else if (filePathSet && !isFileInLocalCache) {
        const forceDownload = isFileInLocalCache !== undefined;
        const downloaded = await downloadAttachmentIfNotExisting(attachment, 'filePath', tokenGetter, deviceUuid, fileAccessUtil, forceDownload);
        changedAny = changedAny || downloaded;
      }
      return changedAny;
    case 'NONE':
      const fileScheduledForUpload = filePathSet && (await isAttachmentScheduledForUploadOrRecentlyCreated(attachment, fileAccessUtil, dataSyncStarted));
      if (!fileScheduledForUpload) {
        let deleted1, deleted2, deleted3, deleted4 = false;
        if (isFileInLocalCache === true || isFileInLocalCache === undefined) {
          deleted1 = await fileAccessUtil.deleteAttachment(attachment, 'filePath', true);
        }
        if (isThumbnailInLocalCache === true || isThumbnailInLocalCache === undefined) {
          deleted2 = await fileAccessUtil.deleteAttachment(attachment, 'mediumThumbnailPath', true);
          deleted3 = await fileAccessUtil.deleteAttachment(attachment, 'bigThumbnailPath', true);
          deleted4 = await fileAccessUtil.deleteAttachment(attachment, 'thumbnailPath', true);
        }
        changedAny = changedAny || deleted1 || deleted2 || deleted3 || deleted4;
      }
      return changedAny;
    default:
      throw new Error(`Unsupported attachmentSyncMode ${attachmentSyncMode}`);
  }
}

export async function deleteAttachmentFromUploadQueue(attachment: Attachment, fileAccessUtil: AbstractFileAccessUtil): Promise<boolean> {
  const deleted1 = await fileAccessUtil.deleteAttachment(attachment, 'filePath', true, 'upload');
  const deleted2 = await fileAccessUtil.deleteAttachment(attachment, 'filePath', true, 'uploadError');
  return deleted1 || deleted2;
}

export async function deleteAttachmentFromCacheIfNotScheduledForUpload(attachment: Attachment, fileAccessUtil: AbstractFileAccessUtil): Promise<boolean> {
  if (await isAttachmentScheduledForUpload(attachment, fileAccessUtil)) {
    return false;
  }
  return await deleteAttachmentFromCache(attachment, fileAccessUtil);
}

export async function deleteAttachmentFromCache(attachment: Attachment, fileAccessUtil: AbstractFileAccessUtil): Promise<boolean> {
  const deleted1 = await fileAccessUtil.deleteAttachment(attachment, 'filePath', true);
  const deleted2 = await fileAccessUtil.deleteAttachment(attachment, 'mediumThumbnailPath', true);
  const deleted3 = await fileAccessUtil.deleteAttachment(attachment, 'bigThumbnailPath', true);
  const deleted4 = await fileAccessUtil.deleteAttachment(attachment, 'thumbnailPath', true);

  return deleted1 || deleted2 || deleted3 || deleted4;
}

async function deleteThumbnailAttachmentFromCache(attachment: Attachment, fileAccessUtil: AbstractFileAccessUtil): Promise<boolean> {
  const deleted1 = await fileAccessUtil.deleteAttachment(attachment, 'mediumThumbnailPath', true);
  const deleted2 = await fileAccessUtil.deleteAttachment(attachment, 'bigThumbnailPath', true);
  const deleted3 = await fileAccessUtil.deleteAttachment(attachment, 'thumbnailPath', true);
  return deleted1 || deleted2 || deleted3;
}

async function scheduleUploadMediaFile(attachment: Attachment, authenticationToken: string, fileAccessUtil: AbstractFileAccessUtil, ignoreVersion = false): Promise<boolean> {
  let blob: Blob|undefined;
  let versionFound: string|undefined;
  if (ignoreVersion) {
    const readAttachmentIgnoreVersion = await fileAccessUtil.readAttachmentIgnoreVersion(attachment, 'filePath');
    if (readAttachmentIgnoreVersion?.blob) {
      blob = readAttachmentIgnoreVersion.blob;
      versionFound = readAttachmentIgnoreVersion.version;
    }
  } else {
    blob = await fileAccessUtil.readAttachment(attachment, 'filePath');
  }
  if (!blob) {
    console.warn(`Unable to schedule attachment with id "${attachment.id} for upload, as it does not exist.`);
    return false;
  }

  if (fileAccessUtil.className === 'FilesystemFileAccessUtil' && ignoreVersion && versionFound && versionFound !== changedAtToVersion(attachment.changedAt)) {
    console.log(`scheduleUploadMediaFile - found attachment attachment ${attachment.id} with version "${versionFound}". Changing it to  to "${attachment.changedAt}"`);
    const versionChanged = await fileAccessUtil.changeAttachmentVersion(attachment, 'filePath', new Date(+versionFound), attachment.changedAt, 'media');
    console.log(`scheduleUploadMediaFile - found attachment attachment ${attachment.id} with version "${versionFound}". Changing it to  to "${attachment.changedAt}" , versionChanged=${versionChanged}`);
  }

  const url = fileAccessUtil.getServerUrlForAttachment(attachment, 'filePath');
  const content = url;
  await fileAccessUtil.writeAttachment(attachment, 'filePath', content, 'upload');
  return true;
}

export interface UploadFilesResult {
  totalCount: number;
  successfulCount: number;
  errorCount: number;
  errorMessages: Array<string>;
  errorCountNotNetworkError: number;
  errorMessagesNotNetworkError: Array<string>;
  errorCountMovedToErrorQueue: number;
  errorMessagesMovedToErrorQueue: Array<string>;
  warningMessages: Array<string>;
}

function getFilenameFromUrl(url: string): string {
  const indexPath = url.lastIndexOf('/');
  const indexStartFilename = indexPath === -1 ? 0 : indexPath + 1;
  const indexVersion = url.indexOf('?version');
  return indexVersion !== -1 ? url.substring(indexStartFilename, indexVersion) : url;
}

async function uploadFilesFromMediaQueue(fileAccessUtil: AbstractFileAccessUtil, tokenGetter: TokenGetter, deviceUuid: string, ignoreAttachmentVersion = false): Promise<UploadFilesResult> {
  const result: UploadFilesResult = {
    totalCount: 0,
    successfulCount: 0,
    errorCount: 0,
    errorMessages: [],
    errorCountNotNetworkError: 0,
    errorMessagesNotNetworkError: [],
    errorCountMovedToErrorQueue: 0,
    errorMessagesMovedToErrorQueue: [],
    warningMessages: []
  };
  const warningMessages = await fileAccessUtil.filesProcessor('upload', true, ignoreAttachmentVersion, async (url, blob, attempt): Promise<FileAccessProcessorResult> => {
    try {
      await replyOnUnauthorized(async () => {
        return await tokenGetter.runAndInvalidateOnError(async (token) => await uploadAttachment(url, blob, token, deviceUuid));
      });
      result.successfulCount++;
      return 'success';
    } catch (error) {
      console.error(`attachment-utils - uploadFilesFromMediaQueue - Error uploading request with url "${url}. "${error.message}".`);
      result.errorCount++;
      result.errorMessages.push(error?.message);
      if (!(error instanceof NetworkError)) {
        result.errorCountNotNetworkError++;
        result.errorMessagesNotNetworkError.push(error?.message);
      }
      const countAsFailedAttempt = !(error instanceof NetworkError);
      return countAsFailedAttempt ? 'failedIncreaseAttempt' : 'failed';
    } finally {
      result.totalCount++;
    }
  });

  if (warningMessages?.length) {
    result.warningMessages = warningMessages;
  }

  return result;
}

async function uploadAttachmentWithFormData(url: string, blob: Blob, token: string, deviceUuid, abortSignal?: AbortSignal): Promise<Response> {
  if (typeof FormData === 'undefined') {
    const formDataPolyfill = await import('formdata-polyfill');
  }
  const formData = new FormData();
  if (!blob.type || blob.type === '' || blob.type === 'application/octet-stream') {
    // iOS 13.3 and below, cannot set the mime type properly.
    const mimeType = mime.getType(getFilenameFromUrl(url));
    if (mimeType) {
      blob = blob.slice(0, blob.size, mimeType);
    }
  }
  formData.append('file', blob);
  formData.append('fileSize', `${blob.size}`);

  // @ts-ignore
  const body: any = formData._blob ? formData._blob() : formData;
  const uploadRequest = new Request(url, {method: 'POST', body, headers: {Authorization: token, 'Device-Uuid': deviceUuid}});
  return await fetchWithTimeout(uploadRequest, abortSignal);
}

export function ensureMimeTypeSet<T extends Blob|File>(blob: T, filename: string): T {
  if (blob.type && blob.type !== '') {
    return blob;
  }
  let mimeType = mime.getType(filename);
  if (!mimeType || mimeType === '') {
    throw new Error(`MimeType not set in blob.type and unable to determine the mimeType based on the filename "${filename}".`);
  }
  // dwg and dxf files may be represented with multiple mime types. 'image' mime types trigger special behaviour in BauMaster (preview is shown, sketching tool is enabled).
  // We therefore need to make sure, that the "application/*" variant of the mime type is being used.
  if (mimeType === 'image/x-dwg' || mimeType === 'image/vnd.dwg') {
    mimeType = 'application/acad';
  }
  if (mimeType === 'image/x-dxf' || mimeType === 'image/vnd.dxf') {
    mimeType = 'application/dxf';
  }
  const newBlob = blob.slice(0, blob.size, mimeType);
  if ('name' in blob) {
    _.set(newBlob, 'name', (blob as File).name);
  }
  if ('lastModified' in blob) {
    _.set(newBlob, 'lastModified', (blob as File).lastModified);
  }
  return newBlob as T;
}

export async function uploadAttachment(url: string, blob: Blob, authenticationToken: string, deviceUuid: string, abortSignal?: AbortSignal): Promise<boolean> {
  const token = authenticationToken.startsWith('Token ') ? authenticationToken : 'Token ' + authenticationToken;
  const response = await uploadAttachmentWithFormData(url, blob, token, deviceUuid, abortSignal);
  if (!response.ok) {
    if (response.status === 400) {
      const errorResponse: ErrorResponse<ErrorCodeType> = await response.json();
      // @ts-ignore
      if (errorResponse.errorCode === 'VERSION_CHANGED') {
        return false;
      }
      throw new Error(`Error uploading attachment with url ${url}. StatusCode ${response.status}, StatusText: "${response.statusText}"`);
    } else if (response.status === 401) {
      throw new UnauthorizedError(`Error uploading attachment with url ${url}. StatusCode ${response.status}, StatusText: "${response.statusText}"`);
    } else {
      throw new Error(`Error uploading attachment with url ${url}. StatusCode ${response.status}, StatusText: "${response.statusText}"`);
    }
  }
}

async function syncAttachmentsConcurrently(tokenGetter: TokenGetter, deviceUuid: string, fileAccessUtil: AbstractFileAccessUtil, attachmentSyncMode: AttachmentSyncMode,
                                           attachments: Array<Attachment>, dataSyncStarted: Date, newAttachments?: Array<Attachment>, changedAttachments?: Array<ChangedValue<Attachment>>,
                                           deletedAttachments?: Array<Attachment>, clientOrProjectId?: IdType): Promise<boolean> {
  let changedAny = false;
  const localFiles: Set<string>|undefined = fileAccessUtil.filesFasterThanReadFile ? new Set(await fileAccessUtil.filesForClientOrProject('media', clientOrProjectId)) : undefined;
  if (changedAttachments && changedAttachments.length) {
    const changedFilesObservable = from(changedAttachments)
      .pipe(
        mergeMap(async (changedAttachment) => {
          const attachmentOnServer = !!changedAttachment.serverValue.filePath;
          const changed = await syncFiles(changedAttachment.serverValue, tokenGetter, deviceUuid, fileAccessUtil, attachmentSyncMode, dataSyncStarted, localFiles, true);
          changedAny = changedAny || changed;
          if (attachmentOnServer && new Date(changedAttachment.localValue.changedAt).getTime() !== new Date(changedAttachment.serverValue.changedAt).getTime()) {
            const deleted = await deleteAttachmentFromCacheIfNotScheduledForUpload(changedAttachment.localValue, fileAccessUtil);
            changedAny = changedAny || deleted;
          }
        }, fileAccessUtil.attachmentConcurrentDownloads),
      );

    await changedFilesObservable.toPromise();
  }
  if (deletedAttachments && deletedAttachments.length) {
    const deleteFilesObservable = from(deletedAttachments)
      .pipe(
        mergeMap(async (deletedAttachment) => {
          const deleted = await deleteAttachmentFromCache(deletedAttachment, fileAccessUtil);
          changedAny = changedAny || deleted;
        }, fileAccessUtil.attachmentConcurrentDownloads),
      );

    await deleteFilesObservable.toPromise();
  }
  if (attachments && attachments.length) {
    const downloadFilesObservable = from(attachments)
      .pipe(
        mergeMap(async (attachment) => {
            const changed = await syncFiles(attachment, tokenGetter, deviceUuid, fileAccessUtil, attachmentSyncMode, dataSyncStarted, localFiles);
            changedAny = changedAny || changed;
          }
          , fileAccessUtil.attachmentConcurrentDownloads),
      );

    await downloadFilesObservable.toPromise();
  }
  return changedAny;
}

function toUrlWithVersion(mediaUrl: string, attachment: Attachment, attachmentProperty: AttachmentPathProperty): string | null {
  const propertyValue: string | null = attachment[attachmentProperty];
  if (!propertyValue) {
    return null;
  }
  return mediaUrl + propertyValue + '?version=' + versionForAttachment(attachment);
}

export async function deleteAttachmentsFromCache(attachments: Array<Attachment>, fileAccessUtil: AbstractFileAccessUtil) {
  if (attachments && attachments.length) {
    for (const attachment of attachments) {
      await deleteAttachmentFromCache(attachment, fileAccessUtil);
    }
  }
}

// This is a workaround because "cordova-plugin-file" messes up FileReader.
// Without this, it would work in browser but fails (actually, the method call never finishes) on iOS and Android.
// Solution comes from here https://github.com/ionic-team/capacitor/issues/1564
export function getFileReader(): FileReader {
  const fileReader = new FileReader();
  const zoneOriginalInstance = (fileReader as any).__zone_symbol__originalInstance;
  return zoneOriginalInstance || fileReader;
}

const convertBlobToBase64 = (blob: Blob) => new Promise<string>((resolve, reject) => {
  const reader = getFileReader();
  reader.onerror = reject;
  reader.onload = () => {
    if (typeof reader.result === 'string') {
      resolve(reader.result);
    }
    reject(`Reader returned result of type ${typeof reader.result} but only string is supported.`);
  };
  reader.readAsDataURL(blob);
});

function convertBase64ToBlob(base64: string, mimeType = 'image/jpeg'): Blob {
  const rawData = atob(base64);
  const bytes = new Array(rawData.length);
  for (let x = 0; x < rawData.length; x++) {
    bytes[x] = rawData.charCodeAt(x);
  }
  const arr = new Uint8Array(bytes);
  return new Blob([arr], {type: mimeType});
}

export const convertBlobToText = (blob: Blob) => new Promise<string>((resolve, reject) => {
  const reader = getFileReader();
  reader.onerror = reject;
  reader.onload = () => {
    if (typeof reader.result === 'string') {
      resolve(reader.result);
    }
    reject(`Reader returned result of type ${typeof reader.result} but only string is supported.`);
  };
  reader.readAsText(blob);
});

const convertBlobToBinaryString = (blob: Blob) => new Promise<string>((resolve, reject) => {
  const reader = getFileReader();
  reader.onerror = reject;
  reader.onload = () => {
    if (typeof reader.result === 'string') {
      resolve(reader.result);
    }
    reject(`Reader returned result of type ${typeof reader.result} but only string is supported.`);
  };
  reader.readAsBinaryString(blob);
});

export function convertBinaryStringToBlob(binaryString: string, mimeType = 'image/jpeg'): Blob {
  const bytes = new Array(binaryString.length);
  for (let x = 0; x < binaryString.length; x++) {
    bytes[x] = binaryString.charCodeAt(x);
  }
  const arr = new Uint8Array(bytes);
  return new Blob([arr], {type: mimeType});
}

export async function convertFileViaBinaryStringToFile(file: File): Promise<File> {
  const binaryString = await convertBlobToBinaryString(file);
  const newBlob = convertBinaryStringToBlob(binaryString, file.type);
  _.set(newBlob, 'name', file.name);
  _.set(newBlob, 'lastModified', file.lastModified);
  return newBlob as File; // new File([file], filename) does not work when deployed (probably due to polyfills)
}

export function convertTextToBlob(text: string, mimeType = 'image/jpeg'): Blob {
  const arr = new TextEncoder().encode(text);
  return new Blob([arr], {type: mimeType});
}

function isAttachmentBlob(attachment: Attachment | AttachmentBlob): attachment is AttachmentBlob {
  return !!('blob' in attachment && attachment.blob && ('size' in attachment.blob));
}

function isImageBlob(blob: Blob): boolean {
  return blob.type && blob.type.startsWith('image/');
}

function isImage(attachment: Attachment | AttachmentBlob): boolean {
  return attachment?.mimeType && attachment.mimeType.startsWith('image/');
}

function isAudio(attachment: Attachment | AttachmentBlob): boolean {
  return attachment?.mimeType && attachment.mimeType.startsWith('audio/');
}

function isVideo(attachment: Attachment | AttachmentBlob): boolean {
  return attachment?.mimeType && attachment.mimeType.startsWith('video/');
}

function isPdf(attachment: Attachment | AttachmentBlob): boolean {
  return attachment?.mimeType && attachment.mimeType.startsWith('application/pdf');
}

function isWord(attachment: Attachment | AttachmentBlob): boolean {
  return attachment?.mimeType && (attachment.mimeType.startsWith('application/msword') || attachment.mimeType.startsWith('application/vnd.openxmlformats-officedocument.wordprocessingml.document'));
}

function isExcel(attachment: Attachment | AttachmentBlob): boolean {
  return attachment?.mimeType && (attachment.mimeType.startsWith('application/ms-excel') || attachment.mimeType.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'));
}

function isPowerPoint(attachment: Attachment | AttachmentBlob): boolean {
  return attachment?.mimeType &&
    (attachment.mimeType.startsWith('application/vnd.ms-powerpoint') || attachment.mimeType.startsWith('application/vnd.openxmlformats-officedocument.presentationml.presentation'));
}

function isCompressed(attachment: Attachment | AttachmentBlob): boolean {
  return attachment?.mimeType && (attachment.mimeType.startsWith('application/x-7z-compressed') || attachment.mimeType.startsWith('application/zip')
    || attachment.mimeType.startsWith('application/x-tar') || attachment.mimeType.startsWith('application/vnd.rar') );
}

function isChatAttachment(attachment: Attachment | AttachmentBlob): boolean {
  return attachment.hasOwnProperty('chatId');
}

function getIsImageAvailable(attachment: Attachment | AttachmentBlob): boolean {
  return !!('filePath' in attachment && attachment.filePath) || isAttachmentBlob(attachment);
}

function getIsThumbnailAvailable(attachment: Attachment | AttachmentBlob): boolean {
  return !!(('mediumThumbnailPath' in attachment && attachment.mediumThumbnailPath) ||
    ('thumbnailPath' in attachment && attachment.thumbnailPath) ||
    ('bigThumbnailPath' in attachment && attachment.bigThumbnailPath));
}

function getIsContentAvailable(attachment: Attachment | AttachmentBlob): boolean {
  return attachment && (getIsImageAvailable(attachment) || getIsThumbnailAvailable(attachment));
}

export function isQuotaExceededError(error: any): boolean {
  let errorMessage: string;
  if (typeof error === 'string') {
    errorMessage = error as string;
  } else if (error.message && typeof error.message === 'string') {
    errorMessage = error.message as string;
  } else {
    errorMessage = '' + error;
  }
  errorMessage = errorMessage.toLowerCase();
  return errorMessage.includes('quota exceeded') || errorMessage.includes('out of quota') || errorMessage.includes('variable caches');
}

// This is the same function as in commonAttachmentUtils.ts but can't be imported and used (for some reason).a
function equalWithDeviation(value: number, compareValue: number, deviationFactor = 0.05, deviationAbsoluteMin = 10, deviationAbsoluteMax = 100): boolean {
  const deviationValue = Math.min(Math.max(compareValue * deviationFactor, deviationAbsoluteMin), deviationAbsoluteMax);
  const lowerCompareValue = compareValue - deviationValue;
  const upperCompareValue = compareValue + deviationValue;
  return value >= lowerCompareValue && value <= upperCompareValue;
}

export {syncAttachmentsConcurrently, scheduleUploadMediaFile, uploadFilesFromMediaQueue, convertBlobToBase64, convertBase64ToBlob,
  downloadAttachment, getAttachmentFromCache, deleteThumbnailAttachmentFromCache, isImageBlob,
  isImage, isAttachmentBlob, isAudio, isVideo, isPdf, isWord, isExcel, isPowerPoint, isCompressed, isChatAttachment, getIsContentAvailable};

export const MIME_TYPES_PDF_PLAN = ['application/pdf'];
export const MIME_TYPES_MS_PROJECT = ['application/vnd.ms-project', 'application/msproj', 'application/msproject', 'application/x-msproject', 'application/x-ms-project',
  'application/x-dos_ms_project', 'application/mpp'];
