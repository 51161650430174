import {Inject, Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {
  DataServiceDeleteOptions,
  DataServiceInsertOptions,
  defaultDeleteOptionsMappingDataServices,
  defaultInsertOptionsMappingDataServices,
  VERSION_INTRODUCED_DEFAULT
} from './abstract-data.service';
import {ProjectDataService} from './project-data.service';
import {IdAware, IdType} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {AbstractProjectAwareDataService} from './abstract-project-aware-data.service';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';

@Injectable()
export abstract class AbstractProjectAwareMappingDataService<T extends IdAware> extends AbstractProjectAwareDataService<T> implements OnDestroy {
  constructor(@Inject('StorageKeyEnum') storageKey: StorageKeyEnum,
              @Inject('String') protected readonly restEndpointUri: string,
              @Inject('Array') protected readonly defaultValue: Array<T>,
              protected http: HttpClient, protected storage: StorageService, protected authenticationService: AuthenticationService, protected userService: UserService,
              protected projectDataService: ProjectDataService, protected loggingService: LoggingService,
              protected projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
              integrityResolverService: IntegrityResolverService,
              public versionIntroduced = VERSION_INTRODUCED_DEFAULT,
              @Inject('Array') protected sortColumns?: Array<keyof T | ((item: T) => any)>,
              @Inject('Array') protected sortColumnOrders?: Array<'asc'|'desc'>) {
    super(storageKey, restEndpointUri, defaultValue, http, storage, authenticationService, userService, projectDataService, loggingService, projectAvailabilityExpirationService,
          integrityResolverService, versionIntroduced, sortColumns, sortColumnOrders);
  }

  public async insertOrUpdate(valueOrArray: T | Array<T>): Promise<Array<T>> {
    throw new Error('insertOrUpdate is not supported for this DataService.');
  }

  async insert(valueArrayOrFunction: Array<T> | ((storageData: Array<T>) => (Array<T> | T | undefined)) | T, projectId: IdType, options?: DataServiceInsertOptions): Promise<Array<T>> {
    options = options ? {...options, ...defaultInsertOptionsMappingDataServices} : defaultInsertOptionsMappingDataServices;
    return super.insert(valueArrayOrFunction, projectId, options);
  }

  async update(valueArrayOrFunction: Array<T> | ((storageData: Array<T>) => (Array<T> | T | undefined)) | T, projectId: IdType): Promise<Array<T>> {
    throw new Error('update is not supported for this DataService.');
  }

  async delete(valueArrayOrFunction: Array<T> | ((storageData: Array<T>) => (Array<T> | T | undefined)) | T, projectId: IdType, options?: DataServiceDeleteOptions): Promise<void> {
    options = options ? {...options, ...defaultInsertOptionsMappingDataServices} : defaultInsertOptionsMappingDataServices;
    return super.delete(valueArrayOrFunction, projectId, options);
  }

  public async insertUpdateDelete(changes: {
                                    inserts?: T | Array<T> | ((storageData: Array<T>) => T|Array<T>|undefined),
                                    insertOptions?: DataServiceInsertOptions
                                    updates?: T | Array<T> | ((storageData: Array<T>) => T|Array<T>|undefined),
                                    deletes?: T | Array<T> | ((storageData: Array<T>) => T|Array<T>|undefined),
                                    deleteOptions?: DataServiceDeleteOptions
                                  },
                                  projectId: IdType): Promise<Array<T>> {
    changes.insertOptions = changes.insertOptions ? {...changes.insertOptions, ...defaultInsertOptionsMappingDataServices} : defaultInsertOptionsMappingDataServices;
    changes.deleteOptions = changes.deleteOptions ? {...changes.deleteOptions, ...defaultDeleteOptionsMappingDataServices} : defaultDeleteOptionsMappingDataServices;
    return await super.insertUpdateDeleteInternal(changes, projectId);
  }

}
