<app-modal-header>
  <ion-title>{{'colorPicker.modal.title' | translate}}</ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <div class="d-flex ion-wrap gap-4 ion-align-items-center">
    <button *ngFor="let col of colors" class="color" [class.selected]="compareColors(col, color)" [ngStyle]="{'--circle-color': col}" (click)="selectColor(col)"></button>
  </div>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" (click)="dismiss()">
      {{'cancel' | translate}}
    </ion-button>
    <ion-button class="omg-btn-primary" (click)="apply()">
      {{'done' | translate}}
    </ion-button>
  </ion-buttons>
</app-modal-footer>
