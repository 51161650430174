import {PdfProtocolSendReq} from '../../../../requestResponse';
import {PdfPreview} from '../../../../models';
import {PdfProtocolGenerateData} from '../../pdfProtocol.model';
import {AbstractProtocolEntriesContent} from '../abstractProtocolEntries.content';
import {Content} from 'pdfmake/interfaces';
import _ from 'lodash';
import {MaybeMinimizeProtocolEntry} from '../abstractPdf.content';
import {PdfHelperFunctions} from '../../../common-report-utils';
import {isTaskProtocol} from '../../../../constants';

export class ProtocolEntriesContent extends AbstractProtocolEntriesContent {
  constructor(config: PdfProtocolSendReq, data: PdfProtocolGenerateData, pdfHelperFunctions: PdfHelperFunctions, pdfPreview?: PdfPreview) {
    super(data.project.language, config, data, pdfHelperFunctions, pdfPreview);
  }

  private writeAnyProtocolEntries(content: Content[], protocolEntries: MaybeMinimizeProtocolEntry[], ignorePageBreak = false, isOwnEntriesAndAppendCarried = false) {
    if (!ignorePageBreak && this.config.pdfProtocolSetting?.startNewPageForEntry) {
      content.push({
        text: '',
        pageBreak: 'after'
      });
    }
    if (this.isGroupBy) {
      const sortedParentProtocolEntries = this.sortProtocolEntries(protocolEntries);
      this.writeGroupByProtocolEntries(content, sortedParentProtocolEntries, isTaskProtocol(this.data.protocol), isOwnEntriesAndAppendCarried);
    } else {
      const parentProtocolEntries = _.filter(protocolEntries, (protocolEntry) => _.isEmpty(protocolEntry.parentId));
      const sortedParentProtocolEntries = this.sortProtocolEntries(parentProtocolEntries);
      let count = sortedParentProtocolEntries.length;
      for (const protocolEntry of sortedParentProtocolEntries) {
        this.writeProtocolEntry(content, protocolEntry, isTaskProtocol(this.data.protocol));
        if (this.config.pdfProtocolSetting?.everyEntryOnNewPage && count > 1) {
          content.push({
            text: '',
            pageBreak: 'after'
          });
        }
        count--;
      }
    }
  }

  writeProtocolEntries(content: Content[]) {
    if (this.config.pdfProtocolSetting?.appendCarriedOverEntries) {
      this.writeAnyProtocolEntries(content, this.getOwnProtocolEntries(), false, true);
      this.writeTextHeader(content, `${this.i18n?.get('carried_over_entries')}`);
      this.writeAnyProtocolEntries(content, this.isGroupBy ? this.getCarriedOverProtocolEntriesGroupBy() : this.getCarriedOverProtocolEntriesNotGroup(), true, false);
    } else {
      this.writeAnyProtocolEntries(content, this.getProtocolEntries());
    }
    this.writeEndText(content);
  }

}
