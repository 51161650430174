import { ColumnConfig } from '../ColumnConfig';
import { skipColumn } from './skipColumn';

const UniqueCompaniesInEntryUniq = ['protocol_entry_id', 'company_id'];

export const ProtocolEntryCompanyColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true, skip: skipColumn},
  {name: 'protocol_entry_id', prop: 'protocolEntryId', compositeKey: true, uniqueWith: [UniqueCompaniesInEntryUniq]},
  {name: 'company_id', prop: 'companyId', compositeKey: true, uniqueWith: [UniqueCompaniesInEntryUniq]},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'}
];
