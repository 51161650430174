import {ColumnConfig} from '../ColumnConfig';
import {skipColumn} from './skipColumn';

const PdfPlanPageEntryUniq = ['pdfplanpage_id', 'name', 'protocolentry_id'];

export const PdfPlanPageMarkingColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true, skip: skipColumn},
  {name: 'pdfplanpage_id', prop: 'pdfPlanPageId', compositeKey: true, uniqueWith: [PdfPlanPageEntryUniq]},
  {name: 'name', prop: 'name', def: null, compositeKey: true, uniqueWith: [PdfPlanPageEntryUniq]},
  {name: 'protocolentry_id', prop: 'protocolEntryId', def: null, compositeKey: true, uniqueWith: [PdfPlanPageEntryUniq]},
  {name: 'markings', prop: 'markings'},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'created_at', prop: 'createdAt', cast: 'Timestamp'}
];
