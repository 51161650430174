<form [formGroup]="protocolConfigurationForm" action="#" class="omg-form" appLicenseType #lt="appLicenseType">
  <ion-grid class="ion-no-padding">
    <ion-row *ngIf="workflowType === WorkflowTypeEnum.GlobalSearch">
      <ion-col class="pb-4">
        <ion-item>
          <ion-input label="{{ 'sendProtocol.protocolConfig.reportName' | translate }}" label-placement="floating" #input formControlName="reportName" type="text" maxlength="45" class="omg-required"></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-accordion-group [multiple]="true" [value]="['general', 'design', 'templates']" class="mt-2">
      <ion-accordion toggleIconSlot="start" [value]="'general'">
        <ion-item slot="header" lines="none">
          <ion-label>{{ "sendProtocol.protocolConfig.sections.content_general" | translate }}</ion-label>
        </ion-item>
          <ion-row slot="content" class="hide-overflow-x">
            <ion-col size="12" class="omg-form-checkbox-grid row-gap-3 ml-2">
              <ion-item class="omg-form-checkbox">
                <ion-checkbox label-placement="end" justify="start" formControlName="showClientAddress">
                  {{ 'sendProtocol.protocolConfig.showClientAddress' | translate }}
                </ion-checkbox>
               </ion-item>
               <ion-item class="omg-form-checkbox" *ngIf="workflowType === WorkflowTypeEnum.Protocol" (click)="checkWeatherSetup()">
                <ion-checkbox label-placement="end" justify="start" formControlName="showWeather">
                  {{ 'sendProtocol.protocolConfig.showWeather' | translate }}
                </ion-checkbox>
             </ion-item>
              <ion-item *ngIf="workflowType === WorkflowTypeEnum.Protocol" class="omg-form-checkbox">
                <ion-checkbox label-placement="end" justify="start" formControlName="showHeaderBannerOnEveryPage">
                  {{ 'sendProtocol.protocolConfig.showHeaderBannerOnEveryPage' | translate }}
                </ion-checkbox>
              </ion-item>
              <ion-item *ngIf="workflowType === WorkflowTypeEnum.Protocol" class="omg-form-checkbox">
                <ion-checkbox label-placement="end" justify="start" formControlName="showFooterBannerOnEveryPage">
                  {{ 'sendProtocol.protocolConfig.showFooterBannerOnEveryPage' | translate }}
                </ion-checkbox>
              </ion-item>
              <ion-item class="omg-form-checkbox">
                <ion-checkbox label-placement="end" justify="start" (ionChange)="onLetterheadChange($event)" formControlName="showLetterhead">
                  {{ 'sendProtocol.protocolConfig.letterhead.checkbox' | translate }}
                </ion-checkbox>
              </ion-item>
            </ion-col>
            <ion-col size="12">
              <div *ngIf="showLetterHeadOptions">
                <ion-buttons slot="start" class="ml-2 mt-2 d-flex ion-wrap">
                  <ion-button class="omg-btn-secondary" [class.omg-btn-color-selected]="importMode === 'manual'" (click)="changeMode('manual')">{{'sendProtocol.protocolConfig.letterhead.manualButton' | translate}}</ion-button>
                  <ion-button class="omg-btn-secondary" [class.omg-btn-color-selected]="importMode === 'company'" (click)="changeMode('company')">{{'sendProtocol.protocolConfig.letterhead.importButton' | translate}}</ion-button>
                  <ion-button (click)="changeMode('unitContact'); unitContactChange(selectedUnitContact)" *ngIf="isUnitsEnabled && unitProfileAddresses?.length > 0" class="omg-btn-secondary" [class.omg-btn-color-selected]="importMode === 'unitContact'">{{'sendProtocol.protocolConfig.letterhead.unitImportButton' | translate}}</ion-button>
                </ion-buttons>
              </div>
              <ion-item *ngIf="importMode === 'company'" class="d-flex fix-height ml-2 mt-2">
                <ion-label position="floating">
                  <fa-icon [fixedWidth]="true" [icon]="['fal','building']"></fa-icon> {{ 'sendProtocol.protocolConfig.letterhead.selectCompany' | translate }}
                </ion-label>
                <app-selectable
                    id="companyIonicSelectable"
                    [items]="companyData | async"
                    itemValueField="id"
                    itemTextField="name"
                    (onChange)="companyChange($event)"
                    [isEnabled]="true"
                    [itemTemplate]="companyItemTemplate"
                    [valueTemplate]="companyValueTemplate"
                    [messageString]="'selectable.company' | translate"
                >
                  <ng-template #companyItemTemplate let-company="item" let-showProjectItems="showProjectItems">
                    <fa-icon class="ion-padding-end" [icon]="['fal','bullhorn']" *ngIf="company.id === null"></fa-icon>
                    <ion-label [class.bold]="company.id === null">{{company | companyNameString:showProjectItems && company.isRemoved}}</ion-label>
                  </ng-template>
                  <ng-template #companyValueTemplate let-company="value" let-showProjectItems="showProjectItems">
                    {{company | companyNameString:showProjectItems && company.isRemoved}}
                  </ng-template>
                </app-selectable>
              </ion-item>
              <ion-item *ngIf="importMode === 'unitContact' && isUnitsEnabled && unitProfileAddresses?.length" class="d-flex fix-height ml-2 mt-2">
                <ion-label position="floating">
                  <fa-icon [fixedWidth]="true" [icon]="['fal','user']"></fa-icon> 
                  {{ 'sendProtocol.protocolConfig.letterhead.unitImportButton' | translate }}
                </ion-label>
                <app-selectable
                    id="unitContactIonicSelectable"
                    [items]="unitProfileAddresses"
                    [(ngModel)]="selectedUnitContact"
                    [ngModelOptions]="{ standalone: true }"
                    (onChange)="unitContactChange($event)"
                    itemValueField="id"
                    [isEnabled]="true"
                    [itemTemplate]="unitContactItemTemplate"
                    [valueTemplate]="unitContactValueTemplate"
                    [messageString]="'sendProtocol.protocolConfig.letterhead.unitImportButton' | translate"
                >
                  <ng-template #unitContactItemTemplate let-address="item">
                    <ion-label>
                      {{ address?.firstName }} {{ address?.lastName }}
                    </ion-label>
                  </ng-template>

                  <ng-template #unitContactValueTemplate let-address="value">
                    {{ address?.firstName }} {{ address?.lastName }}
                  </ng-template>
                </app-selectable>
              </ion-item>
      
              <div class="letterhead-box ml-2 mt-2" *ngIf="showLetterHeadOptions">
                <ion-item class="letterhead-text" lines="none">
                  <ion-textarea label="{{ importMode !== 'manual' ? ('sendProtocol.protocolConfig.letterhead.textLabelPreview' | translate) : ('sendProtocol.protocolConfig.letterhead.textLabelInput' | translate)}}"
                                label-placement="floating"
                                #letterHeadTextArea (input)="onTextareaInput($event)" [readonly]="importMode !== 'manual'" auto-grow="false" formControlName="letterheadText" [rows]="MAX_ROWS_LETTERHEAD" type="text"></ion-textarea>
                </ion-item>
              </div>
            </ion-col>
          </ion-row>
      </ion-accordion>
      <ion-accordion *ngIf="workflowType === WorkflowTypeEnum.Protocol" class="mt-3" toggleIconSlot="start" [value]="'design'">
        <ion-item slot="header" lines="none">
          <ion-label>{{ 'sendProtocol.protocolConfig.sections.design' | translate }}</ion-label>
        </ion-item>
          <ion-row slot="content" class="hide-overflow-x">
            <ion-radio-group formControlName="useNewLayout" class="d-flex gap-2 full-width">
              <ion-col size="6" class="pl-2">
                <ion-item class="omg-form-checkbox">
                  <ion-radio label-placement="end" justify="start" [value]="false">
                    {{'sendProtocol.protocolConfig.oldLayout' | translate}}
                  </ion-radio>
                </ion-item>
              </ion-col>
              <ion-col size="6">
                <ion-item class="omg-form-checkbox">
                  <ion-radio label-placement="end" justify="start" [value]="true">
                    {{'sendProtocol.protocolConfig.newLayout' | translate}}
                  </ion-radio>
                </ion-item>
              </ion-col>
            </ion-radio-group>
          </ion-row>
      </ion-accordion>
      <ion-accordion class="mt-3" toggleIconSlot="start" [value]="'templates'">
        <ion-item slot="header" lines="none">
          <ion-label>{{ 'sendProtocol.protocolConfig.sections.content' | translate }}</ion-label>
        </ion-item>
          <ion-row slot="content" class="pb-8 hide-overflow-x">
            <ion-col size="2" size-sm="1" size-md="2" size-lg="1" class="pr-3 d-flex flex-column gap-2">
              <app-pdf-workflow-top-text-svg></app-pdf-workflow-top-text-svg>
              <app-pdf-workflow-bottom-text-svg></app-pdf-workflow-bottom-text-svg>
            </ion-col>
            <ion-col size="10" size-md="10" size-sm="11" class="pl-3 d-flex flex-column gap-2">
              <ion-textarea label="{{ 'sendProtocol.protocolConfig.additionalText' | translate }}" labelPlacement="floating" helperText=" " autoGrow="true" formControlName="additionalText" rows="1" type="text"></ion-textarea>
              <app-rich-text-editor
                [labelColor]="undefined"
                [label]="'sendProtocol.protocolConfig.startingText' | translate"
                formControlName="startingText"
              >
              </app-rich-text-editor>
              <div *ngIf="featureTextTemplatesEnabled$ | async" class="d-flex flex-grow-1 gap-2">
                <ion-button
                    (click)="getTextTemplate('startingText')"
                    class="omg-btn-secondary apply-text-template-button"
                >
                  <fa-icon slot="start" [icon]="['fal', 'star']"></fa-icon>
                  <span>
                      <span class="ion-hide-sm-down">{{'textTemplate.modal.applyTextTemplate' | translate}}</span>
                      <span class="ion-hide-sm-up">{{'textTemplate.modal.applyTextTemplateShort' | translate}}</span>
                    </span>
                </ion-button>
                <ion-button
                    (click)="saveAsTextTemplate('startingText')"
                    class="omg-btn-secondary save-as-text-template-button"
                    *ngIf="form.controls.startingText.value"
                >
                  <fa-icon slot="start" [icon]="['fal', 'star']"></fa-icon>
                  <span>
                      <span class="ion-hide-sm-down">{{'textTemplate.modal.saveAsTextTemplate' | translate}}</span>
                      <span class="ion-hide-sm-up">{{'textTemplate.modal.saveAsTextTemplateShort' | translate}}</span>
                    </span>
                </ion-button>
              </div>
              <app-rich-text-editor
                [labelColor]="undefined"
                [label]="'sendProtocol.protocolConfig.finalInformation' | translate"
                formControlName="endingText"
              >
              </app-rich-text-editor>
              <div *ngIf="featureTextTemplatesEnabled$ | async" class="d-flex flex-grow-1 gap-2">
                <ion-button
                    (click)="getTextTemplate('endingText')"
                    class="omg-btn-secondary apply-text-template-button"
                >
                  <fa-icon slot="start" [icon]="['fal', 'star']"></fa-icon>
                  <span>
                      <span class="ion-hide-sm-down">{{'textTemplate.modal.applyTextTemplate' | translate}}</span>
                      <span class="ion-hide-sm-up">{{'textTemplate.modal.applyTextTemplateShort' | translate}}</span>
                    </span>
                </ion-button>
                <ion-button
                    (click)="saveAsTextTemplate('endingText')"
                    class="omg-btn-secondary save-as-text-template-button"
                    *ngIf="form.controls.endingText.value"
                >
                  <fa-icon slot="start" [icon]="['fal', 'star']"></fa-icon>
                  <span>
                      <span class="ion-hide-sm-down">{{'textTemplate.modal.saveAsTextTemplate' | translate}}</span>
                      <span class="ion-hide-sm-up">{{'textTemplate.modal.saveAsTextTemplateShort' | translate}}</span>
                    </span>
                </ion-button>
              </div>
            </ion-col>
          </ion-row>
      </ion-accordion>
      <ion-accordion class="mt-3" toggleIconSlot="start" [value]="'contacts'">
        <ion-item slot="header" lines="none">
          <ion-label>{{ 'sendProtocol.protocolConfig.sections.contactsList' | translate }}</ion-label>
        </ion-item>
          <ion-row slot="content" class="hide-overflow-x">
            <ion-col class="d-flex omg-form-checkbox-grid row-gap-3 ml-2">
              <ion-item class="omg-form-checkbox">
                <ion-checkbox label-placement="end" justify="start" formControlName="showParticipantCol">
                  {{ 'sendProtocol.protocolConfig.showParticipants' | translate }}
                </ion-checkbox>
               </ion-item>
               <ion-item class="omg-form-checkbox">
                <ion-checkbox label-placement="end" justify="start" formControlName="compactContactList">
                  {{ 'sendProtocol.protocolConfig.comapactContactList' | translate }}
                </ion-checkbox>
               </ion-item>
               <ion-item class="omg-form-checkbox" *ngIf="workflowType === WorkflowTypeEnum.Protocol">
                <ion-checkbox label-placement="end" justify="start" formControlName="showAllContactsInProject">
                  {{ 'sendProtocol.protocolConfig.showAllContactsInProject' | translate }}
                </ion-checkbox>
               </ion-item>
            </ion-col>
          </ion-row>
      </ion-accordion>
      <ion-accordion class="mt-3" toggleIconSlot="start" [value]="'entryWhat'">
        <ion-item slot="header" lines="none" class="ion-no-padding">
          <ion-label>{{ 'sendProtocol.protocolConfig.sections.entryLayout' | translate }}</ion-label>
        </ion-item>
          <ion-row slot="content" class="hide-overflow-x">
            <ion-col size="12" class="omg-form-checkbox-grid row-gap-3 ml-2">
              <ion-item class="omg-form-checkbox">
                <ion-checkbox label-placement="end" justify="start" formControlName="showComments">
                  {{ 'sendProtocol.protocolConfig.showComments' | translate }}
                </ion-checkbox>
               </ion-item>
               <ion-item class="omg-form-checkbox">
                <ion-checkbox label-placement="end" justify="start" formControlName="showAttachmentDownloadLink">
                  {{ 'sendProtocol.protocolConfig.showAttachmentDownloadLink' | translate }}
                </ion-checkbox>
               </ion-item>
               <ion-item class="omg-form-checkbox" *ngIf="showPhotosOnCommentsOption">
                <ion-checkbox label-placement="end" justify="start" formControlName="showPhotosOnComments">
                  {{ 'sendProtocol.protocolConfig.showPhotosOnComments' | translate }}
                </ion-checkbox>
               </ion-item>
               <ion-item class="omg-form-checkbox">
                <ion-checkbox label-placement="end" justify="start" formControlName="showStatusAsCheckbox">
                  {{ 'sendProtocol.protocolConfig.showStatusAsCheckbox' | translate }}
                </ion-checkbox>
               </ion-item>
               <ion-item class="omg-form-checkbox">
                <ion-checkbox label-placement="end" justify="start" formControlName="showPlanmarker">
                  {{ 'sendProtocol.protocolConfig.showPlanmarker' | translate }}
                </ion-checkbox>
               </ion-item>
               <ion-item class="omg-form-checkbox">
                <ion-checkbox label-placement="end" justify="start" formControlName="highlightEntryTitles">
                  {{ 'sendProtocol.protocolConfig.highlightEntryTitles' | translate }}
                </ion-checkbox>
               </ion-item>
               <ion-item class="omg-form-checkbox">
                <ion-checkbox label-placement="end" justify="start" formControlName="showBimMarker">
                  {{ 'sendProtocol.protocolConfig.showBimMarker' | translate }}
                </ion-checkbox>
              </ion-item>
            </ion-col>

            <ion-col size="12" size-lg="6" class="d-flex gap-4 flex-column pl-2">
              <div class="omg-t-body-bold pt-3">
                {{ 'sendProtocol.protocolConfig.sections.imageSize' | translate }}
              </div>
              <ion-radio-group formControlName="imagesSize" class="d-flex gap-2 ion-wrap">
                <ion-item class="omg-form-checkbox flex-item" *ngFor="let size of imageSizes; trackBy: trackById">
                  <ion-radio label-placement="end" justify="start" [value]="size.id">
                    {{size.name}}
                  </ion-radio>
                </ion-item>
              </ion-radio-group>
            </ion-col>

            <ion-col size="12" size-lg="6" class="d-flex gap-4 flex-column pl-2">
              <div class="omg-t-body-bold pt-3">
                {{ 'sendProtocol.protocolConfig.sections.color' | translate }}
              </div>
              <div class="omg-form-color-picker d-flex gap-4">
                <app-color-picker-button [colors]="colors" formControlName="projectColor"></app-color-picker-button>
                <ion-label>{{ 'sendProtocol.protocolConfig.projectColor' | translate }}</ion-label>
              </div>
            </ion-col>
          </ion-row>
      </ion-accordion>
      <ion-accordion class="mt-3" toggleIconSlot="start" [value]="'entryWhere'">
        <ion-item slot="header" lines="none" class="ion-no-padding">
          <ion-label>{{ 'sendProtocol.protocolConfig.sections.entryPlacement' | translate }}</ion-label>
        </ion-item>
          <ion-row slot="content" class="hide-overflow-x">
            <ion-col size="12" class="omg-form-checkbox-grid row-gap-3 ml-2">
              <ion-item class="omg-form-checkbox">
                <ion-checkbox label-placement="end" justify="start" formControlName="placeEntriesOnNewPage">
                  {{ 'sendProtocol.protocolConfig.placeEntriesOnNewPage' | translate }}
                </ion-checkbox>
               </ion-item>
               <ion-item class="omg-form-checkbox">
                <ion-checkbox label-placement="end" justify="start" formControlName="noSplitEntryInfo">
                  {{ 'sendProtocol.protocolConfig.noSplitEntryInfo' | translate }}
                </ion-checkbox>
               </ion-item>
               <ion-item class="omg-form-checkbox">
                <ion-checkbox label-placement="end" justify="start" formControlName="everyEntryOnNewPage">
                  {{ 'sendProtocol.protocolConfig.everyEntryOnNewPage' | translate }}
                </ion-checkbox>
               </ion-item>
               <ion-item class="omg-form-checkbox" *ngIf="showHideMainEntryOption">
                <ion-checkbox label-placement="end" justify="start" formControlName="hideMainEntry">
                  {{ 'sendProtocol.protocolConfig.hideMainEntry' | translate }}
                </ion-checkbox>
               </ion-item>
               <ion-item class="omg-form-checkbox" *ngIf="showAppendCarriedOverEntriesOption">
                <ion-checkbox label-placement="end" justify="start" formControlName="appendCarriedOverEntries">
                  {{ 'sendProtocol.protocolConfig.appendCarriedOverEntries' | translate }}
                </ion-checkbox>
               </ion-item>
               <ion-item class="omg-form-checkbox" *ngIf="hasFilteredEntries">
                <ion-checkbox label-placement="end" justify="start" formControlName="printFilteredEntriesOnly">
                  {{ 'sendProtocol.protocolConfig.printFilteredEntriesOnly' | translate }}
                </ion-checkbox>
               </ion-item>
            </ion-col>
          </ion-row>
      </ion-accordion>
      <ion-accordion class="mt-3" toggleIconSlot="start" [value]="'entriesDetail'">
        <ion-item slot="header" lines="none" class="ion-no-padding">
          <ion-label>{{ 'sendProtocol.protocolConfig.sections.entryDetails' | translate }}</ion-label>
        </ion-item>
          <ion-row slot="content" appCheckboxGroup formControlName="printEntryDetails" class="hide-overflow-x">
            <ion-col size="12" class="omg-form-checkbox-grid row-gap-3 ml-2">
              <ion-item class="omg-form-checkbox">
                <ion-checkbox label-placement="end" justify="start" [ngModel]="!this.hideDescription" [ngModelOptions]="{ standalone: true }" (ngModelChange)="changeHideDescription()">
                  {{ 'sendProtocol.protocolConfig.hideDescription' | translate }}
                </ion-checkbox>
               </ion-item>
              <ion-item class="omg-form-checkbox" *ngFor="let item of entryDetailsItems">
               <ion-checkbox label-placement="end" justify="start" [value]="item.detail">
                 {{item.label}}
               </ion-checkbox>
              </ion-item>
            </ion-col>
          </ion-row>
      </ion-accordion>
    </ion-accordion-group>
  </ion-grid>
</form>
