<app-modal-header>
  <ion-title> {{ 'resetPassword.send.title' | translate }}  </ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <form class="omg-form" [formGroup]="resetPasswordForm" action="#">
    <ion-grid class="grid-gap-4">
      <ion-row>
        <ion-col size="12" >
          {{'resetPassword.send.description' | translate}}
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12" >
          <ion-item class="username">
            <ion-input label="{{ 'resetPassword.send.username' | translate }}" label-placement="floating" type="email" formControlName="username" required #usernameInput enterkeyhint="next" maxlength="150"></ion-input>
          </ion-item>
          <span *ngIf="resetPasswordForm.controls.username.errors?.required && resetPasswordForm.controls.username.dirty" class="error-message"> {{ 'formValidation.emptyField' | translate:{field: 'resetPassword.send.username' | translate} }}</span>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <div class="error-message" *ngIf="resetPasswordFailedMessageKey">{{ resetPasswordFailedMessageKey | translate }}</div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button (click)="cancel()" class="omg-btn-text">{{ 'cancel' | translate }}</ion-button>
    <ion-button id="resetPasswordButton" (click)="resetPassword()" class="omg-btn-primary" [disabled]="!resetPasswordForm.dirty || !resetPasswordForm?.valid || sending">
      <ion-spinner slot="start" class="spinner-center-button" *ngIf="sending"></ion-spinner>
      <span>{{'resetPassword.send.resetPassword' | translate }}</span>
    </ion-button>
  </ion-buttons> 
</app-modal-footer>
