import {DOCUMENT} from '@angular/common';
import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {fromEvent, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-tags-searchbar-popover',
  templateUrl: './tags-searchbar-popover.component.html',
  styleUrls: ['./tags-searchbar-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagsSearchbarPopoverComponent implements AfterViewInit, OnDestroy {
  private destroy$ = new Subject<void>();

  @Input()
  placeholder: string;
  @Input()
  actionButtonDisabled: boolean;
  @Input()
  actionButtonIcon: [string, string];
  @Output()
  actionButtonClick = new EventEmitter<void>();
  @Output()
  inputChange = new EventEmitter<CustomEvent<{ value: string; }>>();
  @Output()
  willBackdropClick = new EventEmitter<CustomEvent<FocusEvent>>();
  @Output()
  searchbarBlur = new EventEmitter<CustomEvent<FocusEvent>>();
  @Output()
  searchbarFocus = new EventEmitter<CustomEvent<FocusEvent>>();
  @Output()
  searchbarEnterDown = new EventEmitter<KeyboardEvent>();

  @ViewChild('autofocus', {
    read: ElementRef,
    static: true,
  })
  autofocus: ElementRef<HTMLIonInputElement>;

  constructor(@Inject(DOCUMENT) private document: Document) { }

  ngAfterViewInit() {
    fromEvent(this.autofocus.nativeElement, 'keydown').pipe(
      takeUntil(this.destroy$)
    ).subscribe((event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.stopPropagation();
        this.autofocus.nativeElement.blur();
      }
    });
  }

  handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.searchbarEnterDown.emit(event);
    }
  }

  setFocus() {
    this.autofocus?.nativeElement.setFocus();
  }

  setBlur() {
    this.autofocus?.nativeElement.blur();
  }

  hasFocus() {
    if (!this.autofocus) {
      return false;
    }
    return this.autofocus.nativeElement === this.document.activeElement || this.autofocus.nativeElement.contains(this.document.activeElement);
  }

  clearInput() {
    if (!this.autofocus) {
      return;
    }
    this.autofocus.nativeElement.value = '';
  }

  setInput(str: string) {
    if (!this.autofocus) {
      return;
    }
    this.autofocus.nativeElement.value = str;
  }

  blurred(event: CustomEvent<FocusEvent>) {
    this.searchbarBlur.emit(event);
    if ((event.detail.relatedTarget as HTMLElement)?.tagName === 'ion-popover'.toUpperCase()) {
      this.willBackdropClick.emit(event);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
