<app-modal-header>
  <ion-title>
    {{ 'project_room.pdf_plan_holders.history.columns.email' | translate }}
  </ion-title>

  <div class="sub-header d-flex flex-column gap-2">
    <ion-segment class="omg mode-selector" [(ngModel)]="mode">
      <ion-segment-button value="EMAIL">
        <ion-label>{{ 'project_room.pdf_plan_holders.history.segmentEmail' | translate }}</ion-label>
      </ion-segment-button>
      <ion-segment-button value="RECIPIENT_LIST">
        <ion-label>{{ 'project_room.pdf_plan_holders.history.segmentRecipient' | translate }}</ion-label>
      </ion-segment-button>
      <ion-segment-button value="PLANS">
        <ion-label>{{ 'project_room.pdf_plan_holders.history.segmentPlans' | translate }}</ion-label>
      </ion-segment-button>
    </ion-segment>
    

    <ion-row *ngIf="mode !== 'EMAIL'" class="ion-no-padding">
      <ion-col *ngIf="mode === 'RECIPIENT_LIST'" class="ion-no-padding" size="12">
        <ion-searchbar #autofocus [(ngModel)]="searchTextRecipient" (ngModelChange)="searchTextRecipientChanged()" debounce="200" class="slim-searchbar" [placeholder]="'search' | translate"></ion-searchbar>
      </ion-col>

      <ion-col *ngIf="mode === 'PLANS'" class="ion-no-padding" size="12">
        <ion-searchbar #autofocus [(ngModel)]="searchTextPlan" debounce="200" class="slim-searchbar" [placeholder]="'search' | translate"></ion-searchbar>
      </ion-col>
    </ion-row>
  </div>
</app-modal-header>

<ion-content>
  <ion-grid *ngIf="mode === 'EMAIL' && isNetworkConnected" class="ion-no-padding email-frame-padding">
    <ion-row>
      <ion-col class="ion-no-padding">
        <div class="email-frame">
          <div (click)="collapsedRecipients = !collapsedRecipients" class="email-frame__row cursor-pointer" [ngClass]="{'email-frame__row--no-wrap email-frame__row--overflow-gradient': collapsedRecipients}">
            <div class="email-frame__row__label">
              {{ 'sendProtocol.emailSettings.receipents' | translate }}:
            </div>
            <div *ngFor="let recipient of filteredRecipients" class="email-frame__row__chip">
              {{ recipient?.email }}
            </div>
          </div>
          <div class="email-frame__row">
            <div class="email-frame__row__label">
              {{ 'sendProtocol.emailSettings.sender' | translate }}:
            </div>
            <span class="email-frame__row__text" *ngIf="(addressCreator$ | async) as address">
              {{ address?.firstName }} {{ address?.lastName }}
              <span class="email-frame__row__text--small">({{ senderEmailAddress }})</span>
            </span>
          </div>
          <div class="pt-2 pb-2 pl-2" *ngIf="loading && isNetworkConnected">
            <ion-spinner></ion-spinner>{{'syncMissingAttachments.loading' | translate}}
          </div>
          <ng-container *rxLet="mailHistory$; let data">
            <div class="email-frame__row">
              <div class="email-frame__row__label">
                {{ 'sendProtocol.emailSettings.subject' | translate }}:
              </div>
              <span class="email-frame__row__text" *ngIf="!loading && !data.error">
                {{ data.subject }}
              </span>
            </div>
            <div class="email-frame__body">
              <div *ngIf="data.error">{{'project_room.pdf_plan_holders.history.errorMail' | translate}}</div>
              <div *ngIf="!loading && !data.error">
                <div [innerHTML]="data.body | safeHtml"></div>
                <app-tooltip target="download-btn-tooltip" placement="right">
                  {{ 'sendProtocol.emailSettings.downloadButtonTooltip' | translate }}
                </app-tooltip>
              </div>
            </div>
          </ng-container>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>

  <app-offline-info *ngIf="mode === 'EMAIL' && !isNetworkConnected" class="ion-no-padding email-frame-padding" [showRetry]="false" [showHint]="false" [onlineOnlyText]="true">
  </app-offline-info>

  <div *ngIf="mode === 'RECIPIENT_LIST'">
    <ion-grid class="table">
      <ion-row class="table-header text-grey">
        <ion-col class="table-col" (click)="changeSortOrder('companyName')" size="4">
          <b>{{ "project_room.pdf_plan_holders.history.columns.company" | translate }}
          <fa-icon *ngIf="sortType === 'companyName'" class="pl-1" size="sm" [icon]="sortMode === 'asc' ? ['fal', 'long-arrow-up'] : ['fal', 'long-arrow-down']"></fa-icon></b>
        </ion-col>
        <ion-col class="table-col" (click)="changeSortOrder('personName')" size="4">
          <b>{{ "project_room.pdf_plan_holders.history.columns.person" | translate }}
          <fa-icon *ngIf="sortType === 'personName'" class="pl-1" size="sm" [icon]="sortMode === 'asc' ? ['fal', 'long-arrow-up'] : ['fal', 'long-arrow-down']"></fa-icon></b>
        </ion-col>
        <ion-col class="table-col" (click)="changeSortOrder('email')" size="4">
          <b>{{ "project_room.pdf_plan_holders.history.columns.email" | translate }}
          <fa-icon *ngIf="sortType === 'email'" class="pl-1" size="sm" [icon]="sortMode === 'asc' ? ['fal', 'long-arrow-up'] : ['fal', 'long-arrow-down']"></fa-icon></b>
        </ion-col>
      </ion-row>
  
      <ng-container *ngFor="let recipient of filteredRecipients; let odd = odd;">
        <ion-row class="table-row" [class.odd]="odd">
          <ion-col size="4" class="one-line-ellipsis table-col" appEllipsisTooltip>{{ recipient?.companyName }}</ion-col>
          <ion-col size="4" class="one-line-ellipsis table-col" appEllipsisTooltip>{{ recipient?.personName }}</ion-col>
          <ion-col size="4" class="one-line-ellipsis table-col" appEllipsisTooltip>{{ recipient?.email }}</ion-col>
        </ion-row>
      </ng-container>
    </ion-grid>
  </div>

  <div *ngIf="mode === 'PLANS'">
    <ion-grid class="table">
      <ion-row class="table-header text-grey">
        <ion-col class="table-col" size="12"><b>{{ "project_room.pdf_plan_holders.history.columns.versionName" | translate }}</b></ion-col>
      </ion-row>
  
      <ng-container *ngFor="let version of filteredPlanVersions$ | async; let odd = odd;">
        <ion-row class="table-row" [class.odd]="odd">
          <ion-col size="12" class="one-line-ellipsis table-col" appEllipsisTooltip>{{ version.name + (version?.index ? ' | ' + version.index + ' | ' : ' | ') + (version.createdAt | date: 'dd.MM.yyyy') }}</ion-col>
        </ion-row>
      </ng-container>
    </ion-grid>
  </div>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" (click)="dismiss()">{{ "close" | translate }}</ion-button>
  </ion-buttons>
</app-modal-footer>
