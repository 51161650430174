import {Portal, PortalModule, TemplatePortal} from '@angular/cdk/portal';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, computed, signal} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {compact, groupBy, keyBy} from 'lodash';
import {SelectorUnit, SelectorUnitLevel} from 'src/app/model/selector-unit';
import {UiModule} from 'src/app/shared/module/ui/ui.module';
import {IdType, UNIT_NAME_BREADCRUMBS_SEPARATOR} from 'submodules/baumaster-v2-common';
import {UnitSelectEvent, UnitSelectorListComponent} from './unit-selector-list/unit-selector-list.component';
import {SelectorUnitWithPathAndLevel, SelectorUnitsByLevelAndParent, UnitSelectorModalFooterContext} from './unit-selector-model';
import {UnitInLevelSelectEvent, UnitSelectorTreeComponent} from './unit-selector-tree/unit-selector-tree.component';


@Component({
  selector: 'app-unit-selector-modal',
  templateUrl: './unit-selector-modal.component.html',
  styleUrls: ['./unit-selector-modal.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    UiModule,
    TranslateModule,
    PortalModule,
    UnitSelectorTreeComponent,
    UnitSelectorListComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnitSelectorModalComponent implements OnInit {

  @Input()
  selectorUnits: SelectorUnit[] = [];

  @Input()
  selectorUnitLevels: SelectorUnitLevel[] = [];
  
  @Input()
  preselectUnit: SelectorUnit|undefined;

  @Input()
  canInitiallyReset = false;

  @Input()
  applyLabel = 'button.apply';

  @Input()
  applyIcon: [string, string] = ['fal', 'check'];

  modal: HTMLIonModalElement;

  protected selectedUnitByLevel = signal<Record<IdType, SelectorUnitWithPathAndLevel|undefined>>({});
  protected canReset = signal(false);

  protected toolbarPortal: Portal<unknown>|undefined;
  protected footerPortal: TemplatePortal<UnitSelectorModalFooterContext>|undefined;
  protected selectorUnitsByLevelAndParent: SelectorUnitsByLevelAndParent = {};
  protected selectorUnitsWithPathAndLevel: SelectorUnitWithPathAndLevel[] = [];

  protected selectedPath = computed<string>(() => {
    const selectedUnitByLevel = this.selectedUnitByLevel();
    const selectedUnitsArray = this.selectorUnitLevels.map((level) => selectedUnitByLevel[level.id]);

    if (selectedUnitsArray.every((v) => !v)) {
      return undefined;
    }

    const longest = compact(selectedUnitsArray.reverse())[0];
    const pathSegments = longest.path.map((u) => u.name);
    pathSegments.push(longest.name);

    return pathSegments.join(UNIT_NAME_BREADCRUMBS_SEPARATOR);
  });

  protected viewMode = signal<'tree' | 'list'>('tree');

  constructor(private cdRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.groupSelectorUnitsByLevelAndParent();
    this.preselectUnitIfPresent();
    this.canReset.set(this.canInitiallyReset);
  }
  
  private preselectUnitIfPresent() {
    if (!this.preselectUnit) {
      return;
    }

    const preselectUnit = this.selectorUnitsWithPathAndLevel.find((unit) => unit.id === this.preselectUnit.id);
    if (preselectUnit) {
      this.handleUnitSelect({unit: preselectUnit});
    }
  }
  
  private exhaustPath(unitById: Record<IdType, SelectorUnit>, unit: SelectorUnit, ancestors: SelectorUnit[] = []): SelectorUnit[] {
    if (!unit.parentId) {
      return ancestors;
    }
    const parent = unitById[unit.parentId];
    if (!parent) {
      return ancestors;
    }

    return this.exhaustPath(unitById, parent, [parent, ...ancestors]);
  }

  private groupSelectorUnitsByLevelAndParent() {
    const unitByParentAndLevel: SelectorUnitsByLevelAndParent = {};
    const unitsWithUnifiedParentId = this.selectorUnits.map<SelectorUnit>((u) => ({...u, parentId: u.parentId ?? undefined}));

    const unitById = keyBy(unitsWithUnifiedParentId, 'id');

    const selectorUnitLevelById = keyBy(this.selectorUnitLevels, 'id');
    this.selectorUnitsWithPathAndLevel = unitsWithUnifiedParentId.map<SelectorUnitWithPathAndLevel>((unit) => {
      const path = this.exhaustPath(unitById, unit);
      return {
        ...unit,
        path,
        level: selectorUnitLevelById[unit.unitLevelId],
        previousLevel: selectorUnitLevelById[path[path.length - 1]?.unitLevelId],
      };
    });
    const unitByLevelId = groupBy(this.selectorUnitsWithPathAndLevel, 'unitLevelId');

    for (const level of this.selectorUnitLevels) {
      const unitsInLevelByParentId = groupBy(unitByLevelId[level.id] ?? [], 'parentId');
      unitByParentAndLevel[level.id] = unitsInLevelByParentId ?? {};
    }

    this.selectorUnitsByLevelAndParent = unitByParentAndLevel;
  }

  protected setToolbarPortal(portal: typeof this.toolbarPortal) {
    this.toolbarPortal = portal;
    this.cdRef.detectChanges();
  }

  protected setFooterPortal(portal: typeof this.footerPortal) {
    portal.context = {
      apply: this.apply.bind(this),
      reset: this.reset.bind(this),
      cancel: this.cancel.bind(this),
      applyLabel: this.applyLabel,
      applyIcon: this.applyIcon,
    };
    this.footerPortal = portal;
    this.cdRef.detectChanges();
  }

  protected handleUnitInLevelSelect(event: UnitInLevelSelectEvent) {
    this.canReset.set(false);

    this.selectedUnitByLevel.update(({ [event.level.id]: selectedInLevel, ...rest }) => {
      if (selectedInLevel && selectedInLevel.id === event.unit.id) {
        let removeFrom = this.selectorUnitLevels.findIndex((l) => l.id === event.level.id);
        if (removeFrom === -1) {
          return rest;
        }

        // Current level is already deselected; start from next level
        removeFrom++;

        for (let i = removeFrom; i < this.selectorUnitLevels.length; i++) {
          delete rest[this.selectorUnitLevels[i].id];
        }

        return rest;
      }

      const selectedUnitByLevel = {...rest, [event.level.id]: event.unit};
      const selectedUnitsArray = this.selectorUnitLevels.map((l) => selectedUnitByLevel[l.id]);

      for (let i = 0; i < selectedUnitsArray.length; i++) {
        const selectedUnit = selectedUnitsArray[i];
        const level = this.selectorUnitLevels[i];
        if (!selectedUnit) {
          continue;
        }

        // Both checks ensures path segments before, and one after new selected unit are deselected, if are not part of newly selected unit path
        const isNotPartOfAncestorsPath = level.index < event.level.index && !event.unit.path.some((ancestor) => ancestor.id === selectedUnit.id);
        const isNotChildOfNewlySelectedUnit = level.index === event.level.index + 1 && event.unit.id !== selectedUnit.parentId;

        if (isNotPartOfAncestorsPath || isNotChildOfNewlySelectedUnit) {
          delete selectedUnitByLevel[level.id];
          continue;
        }

        this.removeSelectedDescendantsIfNotPartOfCurrentSelection(level, event, selectedUnit, selectedUnitsArray, selectedUnitByLevel);
      }

      return selectedUnitByLevel;
    });
  }

  private removeSelectedDescendantsIfNotPartOfCurrentSelection(
    level: SelectorUnitLevel,
    event: UnitInLevelSelectEvent,
    selectedUnit: SelectorUnitWithPathAndLevel,
    selectedUnitsArray: SelectorUnitWithPathAndLevel[],
    selectedUnitByLevel: Record<IdType, SelectorUnitWithPathAndLevel>
  ) {
    if (level.index > event.level.index + 1) {
      for (let pathSegment = event.level.index; pathSegment < selectedUnit.path.length; pathSegment++) {
        const selectedInPathSegment = selectedUnitsArray[pathSegment];
        if (!selectedInPathSegment) {
          continue;
        }
        if (selectedUnit.path[pathSegment].id !== selectedInPathSegment.id) {
          delete selectedUnitByLevel[level.id];
          break;
        }
      }
    }
  }

  protected handleUnitSelect(event: UnitSelectEvent) {
    this.canReset.set(false);
    this.selectedUnitByLevel.set({
      [event.unit.unitLevelId]: event.unit,
      ...event.unit.path.reduce(
        (acc, unit) => ({...acc, [unit.unitLevelId]: this.selectorUnitsWithPathAndLevel.find((u) => u.id === unit.id)}),
        {}
      )
    });
  }

  cancel() {
    this.modal.dismiss(undefined, 'cancel');
  }

  reset() {
    this.modal.dismiss(undefined, 'reset');
  }

  apply() {
    const compactedSelectedUnits = compact(this.selectorUnitLevels.map((level) => this.selectedUnitByLevel()[level.id]));
    const selectedUnit = this.selectorUnits.find((unit) => compactedSelectedUnits[compactedSelectedUnits.length - 1]?.id === unit.id);
    if (selectedUnit) {
      this.modal.dismiss(selectedUnit, 'save');
    } else {
      this.cancel();
    }
  }

}
