<app-modal-header>
    <div class="waring" *ngIf="imageScaled">{{'imageScaledOnDevice' | translate}}</div>
</app-modal-header>

<ion-content id="sketch-content" scrollY="false">
  <div class="sketch-wrapper">

    <div #canvasSketchContainer class="sketchcontainer" id="sketchContainer">
  
      <div id="inputTextDiv" class="inputTextDiv">
        <div class="input-container">
          <div>
            <input value="{{viewportMeta.textToAdd}}" (input)="setInputValue($event)">
          </div>
          <div>
            <ion-button slot="start" class="omg-btn-text ion-float-left" (click)="rejectFabricPromise()">
              {{ 'cancel' | translate }}
            </ion-button>
            <ion-button slot="end" class="omg-btn-primary ion-float-end" (click)="resolveFabricPromise()">
              {{ 'okay' | translate }}
            </ion-button>
          </div>
        </div>
      </div>
  
      <div class="sketch-container">
        <div id="hammer" class="canv-container">
          <canvas id="canvas" [class.border-canvas]="signature"></canvas>
        </div>
      </div>
    </div>

    <ion-fab [activated]="fabActivated" horizontal="start" slot="fixed" vertical="bottom" *ngIf="!signature">
      <ion-fab-button color="bg-light">
        <fa-icon [icon]="['fas', 'circle']" [styles]="{'color': viewportMeta.modesVars.color}"></fa-icon>
      </ion-fab-button>


      <ion-fab-list side="top">
        <ion-fab-button data-click-action="changeColor" [attr.data-click-argument]="color.hex" *ngFor="let color of viewportMeta.colors" [style]="{'--background': color.hex}"
                        [ngStyle]="{'active': viewportMeta.modesVars.color === color.hex}"
                        (click)="changeColor(color.hex)">

        </ion-fab-button>
      </ion-fab-list>

      <ion-fab-list side="end">
        <ion-range [min]="viewportMeta.modesVars.swmin"
                   [max]="viewportMeta.modesVars.swmax"
                   [step]="viewportMeta.modesVars.swstep"
                   (ionChange)="changeSize($event)"
                   [value]="strokeWidth"
                   [ngClass]="viewportMeta.modesVars.colorClass"
                   class="draw-item slider">
          <ion-icon slot="start" size="small" name="text" [style]="{color: viewportMeta.modesVars.color}" *ngIf="currentMode === 'text'"></ion-icon>
          <ion-icon slot="end" name="text" [style]="{color: viewportMeta.modesVars.color}" *ngIf="currentMode === 'text'"></ion-icon>
          <ion-icon slot="start" size="small" name="ellipse" [style]="{color: viewportMeta.modesVars.color}" *ngIf="currentMode !== 'text'"></ion-icon>
          <ion-icon slot="end" name="ellipse" [style]="{color: viewportMeta.modesVars.color}" *ngIf="currentMode !== 'text'"></ion-icon>
        </ion-range>
      </ion-fab-list>
    </ion-fab>

    <ion-fab [activated]="fabActivated" horizontal="end" slot="fixed" vertical="bottom" *ngIf="!signature">
      <ion-fab-button color="bg-light">
        <fa-icon [icon]="['far', 'pen']" [styles]="{'color': viewportMeta.modesVars.color}"
                 *ngIf="currentMode === 'freeDraw'"></fa-icon>
        <fa-icon [icon]="['far', 'minus']" [styles]="{'color': viewportMeta.modesVars.color}"
                 *ngIf="currentMode === 'line'"></fa-icon>
        <fa-icon [icon]="['far', 'long-arrow-right']" [styles]="{'color': viewportMeta.modesVars.color}"
                 *ngIf="currentMode === 'arrow'"></fa-icon>
        <fa-icon [icon]="['far', 'arrows-h']" [styles]="{'color': viewportMeta.modesVars.color}"
                 *ngIf="currentMode === 'dualArrow'"></fa-icon>
        <fa-icon [icon]="['far', 'text']" [styles]="{'color': viewportMeta.modesVars.color}"
                 *ngIf="currentMode === 'text'"></fa-icon>
        <fa-icon [icon]="['far', 'eraser']" [styles]="{'color': viewportMeta.modesVars.color}"
                 *ngIf="currentMode === 'erase'"></fa-icon>
        <fa-icon [icon]="['far', 'mouse-pointer']" [styles]="{'color': viewportMeta.modesVars.color}"
                 *ngIf="currentMode === 'mouse'"></fa-icon>
        <fa-icon [icon]="['bau', 'point-up']" [styles]="{'color': viewportMeta.modesVars.color}"
                 *ngIf="currentMode === 'move'"></fa-icon>
      </ion-fab-button>

      <ion-fab-list side="start">
        <ion-fab-button id="button-mouse" color="bg-light" (click)="switchMode('mouse')" data-click-action="switchMode" data-click-argument="mouse">
          <fa-icon [icon]="['far', 'mouse-pointer']"
                   [ngStyle]="{'color': currentMode === 'mouse' ? viewportMeta.modesVars.color: ''}"></fa-icon>
        </ion-fab-button>
        <app-tooltip target="button-mouse" [placement]="'top'">
          {{ 'sketchingtool.mouse' | translate }}
        </app-tooltip>
        <ion-fab-button id="button-move" color="bg-light" (click)="switchMode('move')" data-click-action="switchMode" data-click-argument="move">
          <fa-icon [icon]="['bau', 'point-up']"
                   [ngStyle]="{'color': currentMode === 'move' ? viewportMeta.modesVars.color: ''}"></fa-icon>
        </ion-fab-button>
        <app-tooltip target="button-move" [placement]="'top'">
          {{ 'sketchingtool.move' | translate }}
        </app-tooltip>
      </ion-fab-list>
      <ion-fab-list side="top">
        <ion-fab-button *ngIf="canErase" id="button-eraser" color="bg-light" (click)="switchMode('erase')" data-click-action="switchMode" data-click-argument="erase">
          <fa-icon [icon]="['far', 'eraser']"
                   [ngStyle]="{'color': currentMode === 'erase' ? viewportMeta.modesVars.color: ''}"></fa-icon>
        </ion-fab-button>
        <app-tooltip target="button-eraser" [placement]="'left'">
          {{ 'sketchingtool.eraser' | translate }}
        </app-tooltip>
        <ion-fab-button id="button-draw" color="bg-light" (click)="switchMode('freeDraw')" data-click-action="switchMode" data-click-argument="freeDraw">
          <fa-icon [icon]="['far', 'pen']"
                   [ngStyle]="{'color': currentMode === 'freeDraw' ? viewportMeta.modesVars.color: ''}"></fa-icon>
        </ion-fab-button>
        <app-tooltip target="button-draw" [placement]="'left'">
          {{ 'sketchingtool.free_draw' | translate }}
        </app-tooltip>
        <ion-fab-button id="button-text" color="bg-light" (click)="switchMode('text')" data-click-action="switchMode" data-click-argument="text">
          <fa-icon [icon]="['far', 'text']"
                   [ngStyle]="{'color': currentMode === 'text' ? viewportMeta.modesVars.color: ''}"></fa-icon>
        </ion-fab-button>
        <app-tooltip target="button-text" [placement]="'left'">
          {{ 'sketchingtool.text' | translate }}
        </app-tooltip>
        <ion-fab-button id="button-dArrow" color="bg-light" (click)="switchMode('dualArrow')" data-click-action="switchMode" data-click-argument="dualArrow">
          <fa-icon [icon]="['far', 'arrows-h']"
                   [ngStyle]="{'color': currentMode === 'dualArrow' ? viewportMeta.modesVars.color: ''}"></fa-icon>
        </ion-fab-button>
        <app-tooltip target="button-dArrow" [placement]="'left'">
          {{ 'sketchingtool.dual_arrow' | translate }}
        </app-tooltip>
        <ion-fab-button id="button-arrow" color="bg-light" (click)="switchMode('arrow')" data-click-action="switchMode" data-click-argument="arrow">
          <fa-icon [icon]="['far', 'long-arrow-right']"
                   [ngStyle]="{'color': currentMode === 'arrow' ? viewportMeta.modesVars.color: ''}"></fa-icon>
        </ion-fab-button>
        <app-tooltip target="button-arrow" [placement]="'left'">
          {{ 'sketchingtool.arrow' | translate }}
        </app-tooltip>
        <ion-fab-button id="button-line" color="bg-light" (click)="switchMode('line')" data-click-action="switchMode" data-click-argument="line">
          <fa-icon [icon]="['far', 'minus']"
                   [ngStyle]="{'color': currentMode === 'line' ? viewportMeta.modesVars.color: ''}"></fa-icon>
        </ion-fab-button>
        <app-tooltip target="button-line" [placement]="'left'">
          {{ 'sketchingtool.line' | translate }}
        </app-tooltip>
      </ion-fab-list>
    </ion-fab>
    <div class="widget-container">
      <ion-grid>
        <ion-row>
          <ion-col size="2">
          </ion-col>
          <ion-col size="8" class="ion-text-center padding-top-15 position-bottom">
            <fa-icon [icon]="['far', 'reply']" (click)="undo()"></fa-icon>
            <fa-icon [icon]="['far', 'share']" (click)="redo()" class="padding-left-20"></fa-icon>
            <fa-icon id="button-delete" [icon]="['far', 'trash']" *ngIf="viewportMeta.objectsSelected" (click)="deleteSelectedObjects()" class="padding-left-20"></fa-icon>
            <app-tooltip *ngIf="viewportMeta.objectsSelected" target="button-delete" [placement]="'top'">
              {{ 'sketchingtool.delete' | translate }}
            </app-tooltip>
          </ion-col>
          <ion-col size="2" class="ion-text-end">
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>

</ion-content>

<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button (click)="close()" class="omg-btn-text" slot="start">
      {{ 'cancel' | translate }}
    </ion-button>
    <ion-button #saveButton (click)="save()" [disabled]="true" class="omg-btn-primary" slot="end">
      {{(applyInsteadOfSave ? 'button.apply' : 'button.save') | translate}}
    </ion-button>
  </ion-buttons>
</app-modal-footer>
