import { Pipe, PipeTransform } from '@angular/core';
import {ICON_SPLIT_CHARACTER} from 'src/app/components/project-room/pdf-plan-folder-icon-picker/pdf-plan-folder-icon-picker.component';
import {Nullish} from 'src/app/model/nullish';

@Pipe({
  name: 'iconToArray',
  pure: true,
})
export class IconToArrayPipe implements PipeTransform {

  transform(value: Nullish<string>): Nullish<[string, string]> {
    if (!value || !value.includes(ICON_SPLIT_CHARACTER)) {
      return undefined;
    }

    const [left, right] = value.split(ICON_SPLIT_CHARACTER);

    return [left, right];
  }

}
