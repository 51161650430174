import {Protocol} from './models';

export const TASK_PROTOCOL_TYPE_NAME = 'Task';
export const TASK_PROTOCOL_TYPE_CODE = 'TA';
export const TASK_PROTOCOL_NAME = 'Tasks';

export const isTaskProtocol = (protocol: Protocol): boolean => protocol.number === 0 && protocol.name === TASK_PROTOCOL_NAME;

export const EMPTY_IMAGE_BASE64 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=';
export const EMPTY_IMAGE_SVG_BASE64 ='data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 512 512\'%3E%3Cpath fill=\'%23cccccc\' d=\'M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm16 336c0 8.822-7.178 16-16 16H48c-8.822 0-16-7.178-16-16V112c0-8.822 7.178-16 16-16h416c8.822 0 16 7.178 16 16v288zM112 232c30.928 0 56-25.072 56-56s-25.072-56-56-56-56 25.072-56 56 25.072 56 56 56zm0-80c13.234 0 24 10.766 24 24s-10.766 24-24 24-24-10.766-24-24 10.766-24 24-24zm207.029 23.029L224 270.059l-31.029-31.029c-9.373-9.373-24.569-9.373-33.941 0l-88 88A23.998 23.998 0 0 0 64 344v28c0 6.627 5.373 12 12 12h360c6.627 0 12-5.373 12-12v-92c0-6.365-2.529-12.47-7.029-16.971l-88-88c-9.373-9.372-24.569-9.372-33.942 0zM416 352H96v-4.686l80-80 48 48 112-112 80 80V352z\'/%3E%3C/svg%3E';
export const MAX_TASKS_SENDING = 20;
export const MAX_TASK_IMAGES_SENDING = 100;

export const ONLY_ADMIN_CAN_CLOSE_PROTOCOL_CLIENT_IDS = ['623044af-5c49-4549-8677-252a16f48fca', '826eb03d-0d19-4791-99a7-65eb686c8d29'];
export const HIDE_PROTOCOL_DATE_ON_PDF_CLIENT_IDS = ['623044af-5c49-4549-8677-252a16f48fca', '826eb03d-0d19-4791-99a7-65eb686c8d29'];

export const PDF_NODE_ID_HEADER_SEARCH_STRING = 'entryInfoHeader';
export const PDF_NODE_ID_HEADER_TABLE_SEARCH_STRING = 'entryTableHeader';
export const PDF_NODE_ID_DESC_SEARCH_STRING = 'entryInfo';
export const PDF_NODE_ID_ENTRY_TITLE = 'entryTitle';
export const PDF_NODE_ID_SUBENTRY_HEADER = 'subentriesHeader';
export const PDF_NODE_ID_BIM_HEADER = 'bimHeader';
export const PDF_NODE_ID_BIM_CONTENT = 'bimContent';
export const PDF_NODE_ID_PLAN_HEADER = 'planHeader';
export const PDF_NODE_ID_PLAN_CONTENT = 'planContent';
export const PDF_NODE_ID_IMAGE_HEADER = 'imageHeader';
export const SUPERUSER_DEVICE_UUID = 'ab85216c-8cf9-4faa-b771-2c297d839efb';

export const SUPPORTED_LANGUAGES = ['de', 'en', 'fr'] as const;

export const UNIT_NAME_BREADCRUMBS_SEPARATOR = ' > ';
export const UNIT_OWNER_TENANT_COMPANY_ID = 'UNIT_OWNER_TENANT_COMPANY_ID';

const CLIENT_ID_SALZBURG_WOHNBAU = 'dcc73ff7-c056-4764-94a6-a5f54a6c7a58';
const CLIENT_IDS_UNIT_FEATURE_ENABLED_PROD = [CLIENT_ID_SALZBURG_WOHNBAU, '74cf683f-516f-4ccd-acb1-6e67216476c9', 'aa682643-43ef-4d4b-a3d9-0fa8f3ecb261', '98df8cb0-b2cc-40ae-9578-eb9130e892df', '21362c3b-55bc-4459-9264-b0e8504c82ff', 'e4e8eac5-26bb-41d8-9776-f5260348c60c', '018f6feb-f95e-4c4e-9a9f-cb1dbd15df64', '8eb0088d-9087-42f4-989b-621116adea27'];
const CLIENT_IDS_UNIT_FEATURE_ENABLED_STAGING = ['84556df7-8720-4024-93ef-a6ed69ad665b', '8f69718b-0c11-4248-a24d-a38cc3b67fcb', '96977296-fbd3-499f-b5a9-26313041c9d3'];
const CLIENT_IDS_UNIT_FEATURE_ENABLED_LOCALHOST = ['a86a6619-3e4d-4ff6-b613-2858d460b001'];
export const CLIENT_IDS_UNIT_FEATURE_ENABLED = [...CLIENT_IDS_UNIT_FEATURE_ENABLED_PROD, ...CLIENT_IDS_UNIT_FEATURE_ENABLED_STAGING, ...CLIENT_IDS_UNIT_FEATURE_ENABLED_LOCALHOST];
export const CLIENT_IDS_UNIT_IMPORT_ENABLED = [CLIENT_ID_SALZBURG_WOHNBAU, ...CLIENT_IDS_UNIT_FEATURE_ENABLED_STAGING, ...CLIENT_IDS_UNIT_FEATURE_ENABLED_LOCALHOST];
