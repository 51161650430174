<ion-list>
  <ion-item [disabled]="!(allowOpenOrCompanyDone$ | async)" button (click)="changeStatus(protocolEntryStatus.OPEN)">
    <img src="/assets/images/bm-status-open.svg" class="protocol-status-img"/>
    <span class="protocol-status-text">{{'protocolEntry.status.open' | translate}}</span>
  </ion-item>

  <ion-item [disabled]="!(allowOpenOrCompanyDone$ | async)" (click)="changeStatus(protocolEntryStatus.COMPANY_DONE)" *ngIf="!isProtocolLayoutShort" button>
    <img src="/assets/images/bm-status-on-hold.svg" class="protocol-status-img"/>
    <span class="protocol-status-text">{{'protocolEntry.status.waiting' | translate}}</span>
  </ion-item>

  <ion-item [disabled]="!(allowDone$ | async)" button (click)="changeStatus(protocolEntryStatus.DONE)">
    <img src="/assets/images/bm-status-done.svg" class="protocol-status-img"/>
    <span class="protocol-status-text">{{'protocolEntry.status.done' | translate}}</span>
  </ion-item>

</ion-list>
