import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UiModule} from '../../../shared/module/ui/ui.module';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-offline-info',
  templateUrl: './offline-info.component.html',
  styleUrls: ['./offline-info.component.scss'],
  standalone: true,
  imports: [
    UiModule,
    CommonModule,
    IonicModule,
    TranslateModule,
    FontAwesomeModule,
  ]
})
export class OfflineInfoComponent implements OnInit {

  @Input()
  showTitle = true;

  @Input()
  showRetry = false;

  @Input()
  showHint = true;

  @Input()
  onlineOnlyText = false;

  @Output()
  retry = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {}
}
