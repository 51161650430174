import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Optional, Output, SimpleChanges, ViewChild} from '@angular/core';
import {ModalController, Platform} from '@ionic/angular';
import {TranslatePipe} from '@ngx-translate/core';
import {Observable, of, Subject} from 'rxjs';
import {debounceTime, takeUntil, tap} from 'rxjs/operators';
import {Nullish} from 'src/app/model/nullish';
import {PageDidEnterLifecycleService} from 'src/app/services/common/page-did-enter-lifecycle.service';
import {Attachment} from 'submodules/baumaster-v2-common';
import {AttachmentBlob} from '../../../model/attachments';
import {ImageUriPipe} from '../../../pipes/image-uri.pipe';
import {LoggingService} from '../../../services/common/logging.service';
import {AttachmentFullScreenViewerComponent} from '../attachment-full-screen-viewer/attachment-full-screen-viewer.component';
import {Navigation, Pagination} from 'swiper/modules';
import {SwiperContainer} from 'swiper/swiper-element';

const LOG_SOURCE = 'AttachmentSliderComponent';

@Component({
  selector: 'app-attachment-slider',
  templateUrl: './attachment-slider.component.html',
  styleUrls: ['./attachment-slider.component.scss'],
  providers: [TranslatePipe, ImageUriPipe]
})
export class AttachmentSliderComponent implements OnChanges, OnDestroy, AfterViewInit {
  private destroy$ = new Subject<void>();
  @Input() public attachments: Nullish<Array<Attachment | AttachmentBlob>>;
  @Input() public attachmentsObservable: Observable<Array<Attachment | AttachmentBlob>>;
  @Input() public allowEditMarkings: boolean;
  @Input() public allowDeleting: boolean;
  @Input() public backgroundCss = '';
  @Output() public markingsChanged = new EventEmitter<{ attachment: Attachment | AttachmentBlob, markings: Nullish<string> }>();
  @Output() public attachmentDeleted = new EventEmitter<{ attachment: Attachment | AttachmentBlob }>();

  @ViewChild('swiperContainer', {static: true}) swiper: ElementRef<SwiperContainer> | undefined;
  readonly swiperModules = [Navigation, Pagination];
  public theAttachmentsObservable: Observable<Array<Attachment | AttachmentBlob>>;

  constructor(private loggingService: LoggingService, private platform: Platform, private modalController: ModalController,
              @Optional() private pageDidEnterLifecycleService?: PageDidEnterLifecycleService) {
    if (this.pageDidEnterLifecycleService) {
      this.pageDidEnterLifecycleService.pageDidEnter$.pipe(takeUntil(this.destroy$))
        .subscribe(this.updateSwiper);
    } else {
      this.loggingService.error(LOG_SOURCE, 'PageDidEnterLifecycleService is not available in the scope, it can lead to improper operation of the slider! ' +
        'Make sure the page/modal that is using the app-attachment-slider has PageDidEnterLifecycleService in providers!');
    }

    this.platform.resize
      .pipe(
        debounceTime(100),
        takeUntil(this.destroy$)
      )
      .subscribe(this.updateSwiper);
  }

  private updateSwiper = () => setTimeout(() => {
    this.loggingService.debug(LOG_SOURCE, 'updateSwipe called');
    this.swiper.nativeElement.swiper.update();
  }, 20);

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    this.loggingService.debug(LOG_SOURCE, 'ngOnChanges');
    if (changes.attachments) {
      this.loggingService.debug(LOG_SOURCE, `ngOnChanges(attachment.length=${changes.attachments.currentValue?.length}) - firstChange=${changes.attachments.firstChange}`);
    }

    if (changes.attachmentsObservable) {
      const attachments$ = this.attachmentsObservable ?? of([]);
      this.theAttachmentsObservable = attachments$.pipe(
        tap(this.updateSwiper)
      );
    }
  }

  ngAfterViewInit() {
    this.loggingService.debug(LOG_SOURCE, 'ngAfterViewInit');
    setTimeout(this.updateSwiper, 200);
  }

  ngOnDestroy(): void {
    this.loggingService.debug(LOG_SOURCE, 'ngOnDestroy');
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onAttachmentDeleted(attachment: Attachment | AttachmentBlob) {
    this.attachmentDeleted.emit({attachment});
  }

  async openFullScreen(selectedAttachment, index) {
    const modal = await this.modalController.create({
      component: AttachmentFullScreenViewerComponent,
      backdropDismiss: false,
      componentProps: {
        selectedAttachment,
        index,
        attachmentsObservable: this.attachmentsObservable,
        onMarkingsChanged: this.allowEditMarkings ? async (attachment: Attachment | AttachmentBlob, markings: Nullish<string>) => {
          this.onMarkingsChanged(attachment, markings);
          await modal.dismiss();
        } : undefined,
        onAttachmentDeleted: this.allowDeleting ? (attachment: Attachment | AttachmentBlob) => this.onAttachmentDeleted(attachment) : undefined
      }
    });
    return await modal.present();
  }

  private onMarkingsChanged(attachment: Attachment | AttachmentBlob, markings: Nullish<string>) {
    this.markingsChanged.emit({attachment, markings});
  }
}
