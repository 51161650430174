import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {DEFAULT_DEV_MODE_SETTINGS, DevModeSettings} from '../../model/dev-mode';

@Injectable({
  providedIn: 'root'
})
export class DevModeService {
  private enabledSubject = new BehaviorSubject<boolean>(false);
  public enabled$ = this.enabledSubject.asObservable();
  private settingsSubject = new BehaviorSubject<DevModeSettings>(DEFAULT_DEV_MODE_SETTINGS);
  public settings$ = this.settingsSubject.asObservable();

  get enabled(): boolean {
    return this.enabledSubject.value;
  }

  set enabled(enabled: boolean) {
    this.enabledSubject.next(enabled);
    if (!enabled) {
      this.settingsSubject.next(DEFAULT_DEV_MODE_SETTINGS);
    }
  }

  get settings(): DevModeSettings {
    return this.settingsSubject.value;
  }

  set settings(settings: DevModeSettings) {
    this.settingsSubject.next(settings);
  }

  constructor() { }
}
