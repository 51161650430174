import {ColumnConfig} from '../ColumnConfig';

export const UnitProfileColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'unit_id', prop: 'unitId', cnd: true},
  {name: 'profile_id', prop: 'profileId', cnd: true},
  {name: 'name', prop: 'name', def: null},
  {name: 'is_default', prop: 'isDefault', def: false},
  {name: 'is_active', prop: 'isActive', def: true},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'created_at', prop: 'createdAt', cast: 'Timestamp'},
];
