import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, UntypedFormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {debounceTime, Subject, takeUntil} from 'rxjs';
import {CompanySource} from 'src/app/model/contacts';
import {LoggingService} from 'src/app/services/common/logging.service';
import {ToastService } from 'src/app/services/common/toast.service';
import {ContactService} from 'src/app/services/contact/contact.service';
import {NotificationConfigDataService} from 'src/app/services/data/notification-config-data.service';
import {NotificationConfigRecipientDataService} from 'src/app/services/data/notification-config-recipient-data.service';
import {ProjectDataService} from 'src/app/services/data/project-data.service';
import {SystemEventService} from 'src/app/services/event/system-event.service';
import {AlertService} from 'src/app/services/ui/alert.service';
import {convertErrorToMessage} from 'src/app/shared/errors';
import {combineLatestAsync} from 'src/app/utils/async-utils';
import {getUserNameFromAddress} from 'src/app/utils/user-name-utils';
import {NotificationConfig, NotificationConfigRecipient, Project} from 'submodules/baumaster-v2-common/';
import {v4 as uuidv4} from 'uuid';
import _ from 'lodash';
import {UserDataService} from 'src/app/services/data/user-data.service';
import {PosthogService} from 'src/app/services/posthog/posthog.service';
import {UserService} from 'src/app/services/user/user.service';

const LOG_SOURCE = 'NotificationConfigModalComponent';

interface ContactItem {
  group: string;
  id: string;
  name: string;
}

@Component({
  selector: 'app-notification-config-modal',
  templateUrl: './notification-config-modal.component.html',
  styleUrls: ['./notification-config-modal.component.scss'],
})
export class NotificationConfigModalComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  private modal: HTMLIonModalElement;
  private notificationConfig: NotificationConfig | undefined;
  private notificationConfigRecipients: Array<NotificationConfigRecipient> | undefined;
  private saveInProgress = false;

  public form: UntypedFormGroup = this.formBuilder.group({
    pushNotification: [true],
    protocolEntryNew: [true],
    protocolEntryNewSub: [true],
    protocolEntryChatNew: [true],
    protocolEntryUpdateStatus: [true],
    protocolEntryUpdateTodoUntil: [true],
    protocolEntryUpdateAssignee: [true],
    protocolEntryToResponsible: [true],
    protocolEntryToCreator: [true],
    documentPdfPlanNew: [true],
    documentBimPlanNew: [true],
    projectUserAdded: [true],
  });

  currentProject: Project | undefined;
  sendToWhom: 'projectTeam' | 'ownCompanyProjectTeam' = 'projectTeam';
  configRecipientEnabled = false;
  contacts: Array<ContactItem>;
  profileIds: Array<string> | undefined;
  isCurrentUserAdmin$ = this.userService.isCurrentUserAdmin$;

  constructor(
    private projectDataService: ProjectDataService,
    private formBuilder: FormBuilder,
    private notificationConfigDataService: NotificationConfigDataService,
    private notificationConfigRecipientDataService: NotificationConfigRecipientDataService,
    private contactService: ContactService,
    private translateService: TranslateService,
    private systemEventService: SystemEventService,
    private loggingService: LoggingService,
    private toastService: ToastService,
    private alertService: AlertService,
    private userDataService: UserDataService,
    private posthogService: PosthogService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.profileIds = [];
    this.isCurrentUserAdmin$.pipe(takeUntil(this.destroy$)).subscribe((isCurrentUserAdmin) => {
      if (isCurrentUserAdmin) {
        this.form.enable();
      } else {
        this.form.disable();
      }
    });
    combineLatestAsync([
      this.projectDataService.currentProjectObservable,
      this.notificationConfigDataService.dataByProjectId$,
      this.contactService.getSortedCompaniesActiveOnly(),
      this.notificationConfigRecipientDataService.dataByConfigId$,
      this.userDataService.data
    ])
      .pipe(takeUntil(this.destroy$), debounceTime(0))
      .subscribe(([currentProject, notificationConfigByProjectId, companies, notificationConfigRecipientsByConfigId, users]) => {
        const notificationConfig = notificationConfigByProjectId[currentProject?.id];
        this.currentProject = currentProject;
        companies = companies.filter(company => company.projectCompany);
        const userProfileIds = users.map(user => user.profileId);
        companies = companies.map(company => ({
          ...company,
          employees: company.employees.filter(employee => employee.projectProfile && (userProfileIds.includes(employee.profile.id) || employee.profile.attachedToUserId))
        }))
        this.notificationConfig = notificationConfig?.[0];
        const notificationConfigRecipients = this.notificationConfig?.id ? (notificationConfigRecipientsByConfigId[this.notificationConfig.id] ?? []) : []
        if (this.notificationConfig) {
          this.form.patchValue(this.notificationConfig);
          this.sendToWhom = this.notificationConfig.documentToProjectTeam ? 'projectTeam' : 'ownCompanyProjectTeam';
        }
        this.notificationConfigRecipients = notificationConfigRecipients;
        this.contacts = this.getContacts(companies);
        if (this.notificationConfigRecipients?.length > 0 && !this.saveInProgress) {
          this.configRecipientEnabled = true;
          this.profileIds = this.notificationConfigRecipients.map(recipient => recipient.profileId);
        }
    });

    const controlsToWatch = [
      'pushNotification',
      'protocolEntryNew',
      'protocolEntryNewSub',
      'protocolEntryChatNew',
      'protocolEntryUpdateStatus',
      'protocolEntryUpdateTodoUntil',
      'protocolEntryUpdateAssignee',
    ];

    controlsToWatch.forEach(controlName => {
      this.form.get(controlName)?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
        if (this.isWhatColumnChangesEmpty()) {
          this.form.get('protocolEntryToCreator')?.disable();
          this.form.get('protocolEntryToResponsible')?.disable();
        } else {
          this.form.get('protocolEntryToCreator')?.enable();
          this.form.get('protocolEntryToResponsible')?.enable();
        }
      });
    });

    this.posthogService.captureEvent('[Notification][Config] Settings opened', {});
  }

  getContacts(data: CompanySource[]) {
    return _.flatten(data.map((company) => company.employees.map((employee) => ({
      group: company.name,
      id: employee.profile.id,
      name: getUserNameFromAddress(this.translateService, employee, employee.profile?.isActive)
    }))));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  close() {
    this.modal.dismiss();
  }

  async save() {
    try {
      this.saveInProgress = true;
      if (this.form.dirty) {
        const rawData = this.form.getRawValue();
        if (this.notificationConfig) {
          this.notificationConfig = {
            ...this.notificationConfig,
            pushNotification: rawData.pushNotification,
            protocolEntryNew: rawData.protocolEntryNew,
            protocolEntryNewSub: rawData.protocolEntryNewSub,
            protocolEntryChatNew: rawData.protocolEntryChatNew,
            protocolEntryUpdateStatus: rawData.protocolEntryUpdateStatus,
            protocolEntryUpdateTodoUntil: rawData.protocolEntryUpdateTodoUntil,
            protocolEntryUpdateAssignee: rawData.protocolEntryUpdateAssignee,
            protocolEntryToResponsible: rawData.protocolEntryToResponsible,
            protocolEntryToCreator: rawData.protocolEntryToCreator,
            documentPdfPlanNew: rawData.documentPdfPlanNew,
            documentBimPlanNew: rawData.documentBimPlanNew,
            documentToProjectTeam: this.sendToWhom === 'projectTeam',
            documentToOwnCompanyProjectTeam: this.sendToWhom === 'ownCompanyProjectTeam',
            projectUserAdded: rawData.projectUserAdded
          }
          await this.notificationConfigDataService.update(this.notificationConfig);
        } else {
          this.notificationConfig = {
            id: uuidv4(),
            projectId: this.currentProject.id,
            pushNotification: rawData.pushNotification,
            protocolEntryNew: rawData.protocolEntryNew,
            protocolEntryNewSub: rawData.protocolEntryNewSub,
            protocolEntryChatNew: rawData.protocolEntryChatNew,
            protocolEntryUpdateStatus: rawData.protocolEntryUpdateStatus,
            protocolEntryUpdateTodoUntil: rawData.protocolEntryUpdateTodoUntil,
            protocolEntryUpdateAssignee: rawData.protocolEntryUpdateAssignee,
            protocolEntryToResponsible: rawData.protocolEntryToResponsible,
            protocolEntryToCreator: rawData.protocolEntryToCreator,
            documentPdfPlanNew: rawData.documentPdfPlanNew,
            documentBimPlanNew: rawData.documentBimPlanNew,
            documentToProjectTeam: this.sendToWhom === 'projectTeam',
            documentToOwnCompanyProjectTeam: this.sendToWhom === 'ownCompanyProjectTeam',
            projectUserAdded: rawData.projectUserAdded,
            createdAt: new Date(),
            changedAt: new Date()
          }
          await this.notificationConfigDataService.insert(this.notificationConfig);
        }
      }

      if (this.configRecipientEnabled) {
        const filteredProfileIdsForInsert = this.profileIds?.filter(profileId => !this.notificationConfigRecipients.some(recipient => profileId === recipient.profileId));
        const filteredProfileIdsForDelete = this.notificationConfigRecipients.filter(recipient => !this.profileIds.some(profileId => profileId === recipient.profileId))
          .map(recipient => recipient.profileId);
        if (filteredProfileIdsForInsert?.length > 0) {
          const newConfigRecipients: Array<NotificationConfigRecipient> = filteredProfileIdsForInsert.map(profileId => {
            const createdAt = new Date().toISOString();
            const notificationConfigRecipient: NotificationConfigRecipient = {
              id: uuidv4(),
              notificationRecipientType: 'ProtocolEntry',
              notificationConfigId: this.notificationConfig.id,
              profileId,
              createdAt,
              changedAt: createdAt
            };
            return notificationConfigRecipient;
          });
          await this.notificationConfigRecipientDataService.insert(newConfigRecipients);
        }
        if (filteredProfileIdsForDelete?.length > 0 || !this.profileIds) {
          const deletedConfigRecipients: Array<NotificationConfigRecipient> = _.compact(filteredProfileIdsForDelete.map(profileId => {
            return this.notificationConfigRecipients.find(recipient => recipient.profileId === profileId);
          }));
          await this.notificationConfigRecipientDataService.delete(deletedConfigRecipients);
        }
      } else {
        if (this.notificationConfigRecipients?.length > 0) {
          await this.notificationConfigRecipientDataService.delete(this.notificationConfigRecipients);
        }
      }
      await this.modal.dismiss();
    } catch (error) {
      this.systemEventService.logErrorEvent(LOG_SOURCE, `Failed to save; reason: ${convertErrorToMessage(error)}`);
      this.loggingService.error(LOG_SOURCE, `Failed to save; reason: ${convertErrorToMessage(error)}`);
      this.toastService.savingError();
    } finally {
      this.saveInProgress = false;
    }
  }

  onWhomChanged(value: 'projectTeam' | 'ownCompanyProjectTeam') {
    this.sendToWhom = value;
    this.form.markAsDirty();
  }

  pushEnabledChanged(pushNotificationEnabled: boolean) {
    const controls = [
      'protocolEntryNew',
      'protocolEntryNewSub',
      'protocolEntryChatNew',
      'protocolEntryUpdateStatus',
      'protocolEntryUpdateTodoUntil',
      'protocolEntryUpdateAssignee',
      'protocolEntryToResponsible',
      'protocolEntryToCreator',
      'documentPdfPlanNew',
      'documentBimPlanNew',
      'projectUserAdded',
    ];

    if (pushNotificationEnabled) {
      controls.forEach(controlName => {
        if (this.isWhatColumnChangesEmpty() && (controlName === 'protocolEntryToCreator' || controlName === 'protocolEntryToResponsible')) {
          return;
        }
        this.form.get(controlName)?.enable();
      });
    } else {
      controls.forEach(controlName => {
        this.form.get(controlName)?.disable();
      });
    }
  }

  isWhatColumnChangesEmpty() {
    return !(this.form.get('protocolEntryNew').value || this.form.get('protocolEntryNewSub').value || this.form.get('protocolEntryChatNew').value || this.form.get('protocolEntryUpdateStatus').value ||
             this.form.get('protocolEntryUpdateTodoUntil').value || this.form.get('protocolEntryUpdateAssignee').value);
  }

  isWhatColumnDocumentsEmpty() {
    return !(this.form.get('documentPdfPlanNew').value || this.form.get('documentBimPlanNew').value);
  }

  isWhomColumnChangesEmpty() {
    return !(this.form.get('protocolEntryToResponsible').value || this.form.get('protocolEntryToCreator').value || this.configRecipientEnabled);
  }

  async alertIfContacts() {
    if (this.configRecipientEnabled && this.profileIds?.length > 0) {
      const confirm = await this.alertService.confirm({
        header: 'notification_settings.recipientAlertHeader',
        message: {key: 'notification_settings.recipientAlertMessage', params: {count: (this.profileIds?.length ?? 0).toString()}},
        confirmLabel: 'understood',
        cancelLabel: 'cancel',
        confirmButton: {
          color: 'text-primary',
          fill: 'solid',
        },
        cancelButton: {
          color: 'alternative'
        }
      });
      this.configRecipientEnabled = !confirm
    }
  }

  getGroupText = (item: any, itemIndex: number, items: any[]) => {
    if (itemIndex === 0 || item?.group !== items[itemIndex - 1]?.group) {
      return item.group;
    }
    return null;
  }
}
