import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {v4} from 'uuid';

@Component({
  selector: 'app-no-content-svg',
  templateUrl: './no-content-svg.component.html',
  styleUrls: ['./no-content-svg.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoContentSvgComponent {
  readonly id = v4();
  @Input() public messageString: string;
}
