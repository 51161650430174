<app-modal-footer>
  <ion-grid class="omg-form">
    <ion-row class="ion-padding-start">
      <ion-col *ngFor="let signer of signers">
        <ion-label class="heading-primary size-sm">{{signer.name}}</ion-label><br>
        <ion-button *ngIf="!hasSigned(signer)" class="omg-btn-secondary margin-top-5" (click)="sign(signer)" [disabled]="readonly">{{'pdfReport.signature.signature' | translate}}</ion-button>
        <ion-item *ngIf="hasSigned(signer)" lines="none">
          <fa-icon  *ngIf="!showSignatureWhenSigned" [icon]="['fal', 'check']" class="success ion-padding-horizontal"></fa-icon>
          <ion-label *ngIf="!showSignatureWhenSigned">
            <span>{{ 'pdfReport.signature.signed' | translate }}</span>
          </ion-label>
          <app-attachment-viewer class="margin-top-5" *ngIf="showSignatureWhenSigned && signersWithSignatureChangeByCode[signer.code]?.signature" size="small" [attachment]="signersWithSignatureChangeByCode[signer.code]?.signature"></app-attachment-viewer>
          <ion-button class="omg-btn-text margin-left-10" (click)="removeSignature(signer)" [disabled]="readonly">
            <fa-icon [icon]="['fal', 'times-circle']"></fa-icon>
          </ion-button>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-primary" (click)="triggerNext()">
      {{'next' | translate}}
    </ion-button>
  </ion-buttons>
</app-modal-footer>

