<ion-header>
  <ion-toolbar>
    <ng-content></ng-content>
    <ion-buttons *ngIf="showCloseButton" slot="end">
      <ion-button class="omg-btn-text close-button" (click)="handleClose()">
        <fa-icon [icon]="['fal', 'times']"></fa-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
