import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {AlertController, IonInput, ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {LoggingService} from 'src/app/services/common/logging.service';
import {ProjectDataService} from 'src/app/services/data/project-data.service';
import {IdType, NameableDropdown, NameableDropdownItem} from 'submodules/baumaster-v2-common';
import {NameableDropdownDataService} from 'src/app/services/data/nameable-dropdown-data.service';
import {NameableDropdownItemDataService} from 'src/app/services/data/nameable-dropdown-item-data.service';
import {takeUntil} from 'rxjs/operators';
import _ from 'lodash';
import {observableToPromise} from 'src/app/utils/async-utils';
import {v4 as uuid4} from 'uuid';
import {SystemEventService} from '../../../services/event/system-event.service';
import {ClientService} from '../../../services/client/client.service';
import {FeatureEnabledService} from 'src/app/services/feature/feature-enabled.service';
import {ToastService} from 'src/app/services/common/toast.service';

const LOG_SOURCE = 'AdditionalFieldComponent';

@Component({
  selector: 'app-additional-field',
  templateUrl: './additional-field.component.html',
  styleUrls: ['./additional-field.component.scss'],
})
export class AdditionalFieldComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() additionalFieldId: IdType | null = null;
  @ViewChild('inputName', {static: false}) inputName: IonInput;
  @Input() projectId: IdType | undefined;
  @Input() prePopulateMainFieldWithText?: string;
  @Input() createForSelectable = false;

  public additionalFieldForm: UntypedFormGroup = this.formBuilder.group({
    name: ['', [Validators.required, this.uniqueName()]],
  });
  public addToProject = true;
  public loading = false;

  private destroy$ = new Subject<void>();
  private nameableDropdowns: NameableDropdown[] | undefined;
  public nameableDropdown: NameableDropdown | undefined;
  private modal: HTMLIonModalElement;

  notConnected$ = this.featureEnabledService.isFeatureEnabled$(false, true, null);

  constructor(private formBuilder: UntypedFormBuilder,
              private translateService: TranslateService,
              private modalController: ModalController,
              private alertCtrl: AlertController,
              private systemEventService: SystemEventService,
              private loggingService: LoggingService,
              private toastService: ToastService,
              private projectDataService: ProjectDataService,
              private clientService: ClientService,
              private nameableDropdownDataService: NameableDropdownDataService,
              private nameableDropdownItemDataService: NameableDropdownItemDataService,
              private featureEnabledService: FeatureEnabledService) {
  }

  private uniqueName(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formName: string | null = control.value;
      if (_.isEmpty(formName) || this.nameableDropdown?.name === formName) {
        return null;
      }

      const nameExisting = _.filter(this.nameableDropdowns, nameableDropdown => nameableDropdown.name === formName && (nameableDropdown.isActive || nameableDropdown.isActive === undefined));
      if (!_.isEmpty(nameExisting)) {
        return {nameExist: true};
      }

      if (this.additionalFieldId) { // edit mode
        const recordDeletedExist = _.filter(this.nameableDropdowns, nameableDropdown => nameableDropdown.name === formName && nameableDropdown.isActive === false);
        if (!_.isEmpty(recordDeletedExist)) {
          return {nameExistAndInactive: true};
        }
      }
      return null;
    };
  }

  ngAfterViewInit() {
    setTimeout(async () => {
      await this.inputName.setFocus();
    }, 500);
  }

  async ngOnInit() {
    this.nameableDropdownDataService.dataForOwnClient$
      .pipe(takeUntil(this.destroy$))
      .subscribe(nameableDropdowns => {
        this.nameableDropdowns = nameableDropdowns;
      });

    if (this.additionalFieldId) {
      this.pathNameableDropdown();
    } else if (this.prePopulateMainFieldWithText) {
      this.additionalFieldForm.controls.name.setValue(this.prePopulateMainFieldWithText);
      this.additionalFieldForm.controls.name.markAsDirty();
    }
    this.setCanDismiss();
  }

  private canDismiss = async (data?: any, role?: string) => {
    if (role === 'delete') {
      return true;
    }

    if (!this.additionalFieldForm?.dirty) {
      return true;
    }

    const alert = await this.alertCtrl.create({
      header: this.translateService.instant('protocolCreation.data_loss_header'),
      message: this.translateService.instant('protocolCreation.data_loss_message'),
      buttons: [
        {
          text: this.translateService.instant('no'),
          role: 'cancel'
        },
        {
          text: this.translateService.instant('yes'),
          role: 'dismiss'
        }
      ]
    });
    await alert.present();

    return (await alert.onWillDismiss()).role === 'dismiss';
  };

  private setCanDismiss() {
    this.modal.canDismiss = this.canDismiss;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private pathNameableDropdown() {
    this.nameableDropdownDataService.getByIdForOwnClient(this.additionalFieldId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(nameableDropdown => {
        this.additionalFieldForm.patchValue(nameableDropdown);
        this.nameableDropdown = nameableDropdown;
      });
  }

  async saveNameableDropdown() {
    try {
      this.loading = true;

      const rawData = this.additionalFieldForm.getRawValue();
      const nameableDropdownData = {
        name: rawData.name
      };

      if (!this.additionalFieldId) {
        let nameableDropdown: NameableDropdown = this.nameableDropdowns.find((data) => data.name === nameableDropdownData.name);
        if (nameableDropdown) {
          await this.undoLogicalDelete(nameableDropdown);
          return;
        }
        const client = await this.clientService.getOwnClientMandatory();
        nameableDropdown = {
          id: uuid4(),
          clientId: client.id,
          changedAt: new Date().toISOString(),
          isActive: true,
          ...nameableDropdownData
        };

        await this.createNameableDropdown(nameableDropdown);
      } else {
        const nameableDropdown: NameableDropdown = {...this.nameableDropdown, ...nameableDropdownData};
        await this.updateNameableDropdown(nameableDropdown);
      }

    } catch (error) {
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - save nameabledropdown ', error?.userMessage + '-' + error?.message);
      this.loggingService.error(LOG_SOURCE, `Error save nameabledropdown. "${error?.userMessage}" - "${error?.message}"`);
      await this.toastService.savingError();
      throw error;
    } finally {
      this.loading = false;
    }
  }

  async createNameableDropdown(nameableDropdown: NameableDropdown) {
    await this.nameableDropdownDataService.insert(nameableDropdown, nameableDropdown.clientId);
    await this.addNameableDropdownToProject(nameableDropdown);
    this.additionalFieldForm.markAsPristine();
    await this.dismissModal('ok', nameableDropdown);
  }

  async addNameableDropdownToProject(nameableDropdown: NameableDropdown) {
    if (this.addToProject && await observableToPromise(this.notConnected$)) {
      const projectId = this.projectId ?? (await this.projectDataService.getCurrentProject()).id;
      const projectNameableDropdown: NameableDropdownItem = {
        id: projectId + nameableDropdown.id,
        projectId,
        nameabledropdownId: nameableDropdown.id,
        changedAt: new Date().toISOString()
      };
      await this.nameableDropdownItemDataService.insert(projectNameableDropdown, projectId);
    }
  }

  async updateNameableDropdown(nameableDropdown: NameableDropdown) {
    const alert = await this.alertCtrl.create({
      message: this.translateService.instant('nameableDropdownForm.editMessage'),
      buttons: [
        {
          text: this.translateService.instant('no'),
          role: 'cancel'
        },
        {
          text: this.translateService.instant('yes'),
          handler: async () => {
            this.additionalFieldForm.markAsPristine();
            await this.nameableDropdownDataService.update(nameableDropdown, nameableDropdown.clientId);
            await this.dismissModal('ok', nameableDropdown);
          }
        }
      ]
    });
    await alert.present();
  }

  dismissModal(role?: 'ok' | 'cancel', data?: NameableDropdown) {
    return this.modal.dismiss(data, role);
  }

  toggleAddToProject() {
    this.addToProject = !this.addToProject;
  }

  async deleteNameableDropdown(nameableDropdown: NameableDropdown) {
    const alert = await this.alertCtrl.create({
      message: this.translateService.instant('nameableDropdownForm.confirmDelete'),
      buttons: [
        {
          text: this.translateService.instant('no'),
          role: 'cancel'
        },
        {
          text: this.translateService.instant('yes'),
          handler: async () => {
            await this.doLogicalDelete(nameableDropdown);
            await this.toastService.info('nameableDropdownForm.deleteSuccessful');
            await this.modal.dismiss(undefined, 'delete');
          }
        }
      ]
    });
    await alert.present();
  }

  async doLogicalDelete(nameableDropdown: NameableDropdown) {
    nameableDropdown.isActive = false;
    await this.nameableDropdownDataService.update(nameableDropdown, nameableDropdown.clientId);
    const allProjectNameableDropdownItems = await observableToPromise(this.nameableDropdownItemDataService.getByNameableDropdownAcrossProjects(nameableDropdown));
    allProjectNameableDropdownItems.forEach((projectNameableDropdownItem) => this.nameableDropdownItemDataService.delete(projectNameableDropdownItem, projectNameableDropdownItem.projectId));
  }

  async undoLogicalDelete(nameableDropdown: NameableDropdown) {
    nameableDropdown.isActive = true;
    await this.nameableDropdownDataService.update(nameableDropdown, nameableDropdown.clientId);
    await this.addNameableDropdownToProject(nameableDropdown);
    this.additionalFieldForm.markAsPristine();
    await this.dismissModal('ok', nameableDropdown);
  }
}
