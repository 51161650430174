import {IdType} from 'submodules/baumaster-v2-common';
import _ from 'lodash';
import {TEMPORARILY_PROJECT_EXPIRATION_IN_MS} from '../shared/constants';
import {SyncStrategy} from '../services/sync/sync-utils';
import {observableToPromise} from './observable-to-promise';
import {convertErrorToMessage} from '../shared/errors';
import {LoggingService} from '../services/common/logging.service';
import {ToastService} from '../services/common/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {ProjectAvailability, ProjectAvailabilityExpirationService} from '../services/project/project-availability-expiration.service';
import {DataServiceFactoryService} from '../services/data/data-service-factory.service';
import {DevModeService} from '../services/common/dev-mode.service';
import {SyncService} from '../services/sync/sync.service';
import {ProjectDataService} from '../services/data/project-data.service';

const LOG_SOURCE = 'ProjectAvailabilityUtils';

export interface ProjectAvailableOptions {
  temporarily: boolean;
  throwErrorIfMakingProjectsAvailableFailed: boolean;
  useModal: boolean;
  modalAllowInBackground: boolean;
  modalAllowCancel: boolean;
  modalConfirmWhenCancel: boolean;
  showToastWhenDone: boolean;
  ensureDataNotOlderThanMs?: number;
  throwErrorIfSyncForEnsureDataNotOlderThanFails: boolean
}

export const ProjectAvailableDefaultOptions: ProjectAvailableOptions = {
  temporarily: false,
  throwErrorIfMakingProjectsAvailableFailed: false,
  useModal: true,
  modalAllowInBackground: true,
  modalAllowCancel: true,
  modalConfirmWhenCancel: false,
  showToastWhenDone: false,
  throwErrorIfSyncForEnsureDataNotOlderThanFails: false
};

export type MakeProjectsOfflineAvailableResult =  {projectIdsUpdatedExpirationDate: IdType[], projectIdsStorageNotInitialized: IdType[]} | undefined;
export type MakeProjectsOfflineAvailableModalResult = {success: boolean} | {success: undefined, makeProjectsOfflineAvailablePromise?: Promise<MakeProjectsOfflineAvailableResult>};

export class ProjectAvailabilityUtils {
  constructor(private projectAvailabilityExpirationService: ProjectAvailabilityExpirationService, private dataServiceFactoryService: DataServiceFactoryService, private syncService: SyncService,
              private projectDataService: ProjectDataService, private devModeService: DevModeService,
              private loggingService: LoggingService, private toastService: ToastService, private translateService: TranslateService) {
  }

  async makeProjectsOfflineAvailable(projectIds: Array<IdType>, optionsPartial?: Partial<ProjectAvailableOptions>, additionalProjectIdsToSync?: IdType[],
                                     abortSignal?: AbortSignal): Promise<MakeProjectsOfflineAvailableResult> {
    this.loggingService.debug(LOG_SOURCE, 'makeProjectsOfflineAvailable called.');
    const options = {...ProjectAvailableDefaultOptions, ...optionsPartial};
    const projectAvailabilityBefore = _.clone(this.projectAvailabilityExpirationService.projectAvailability);
    let projectIdsUpdatedExpirationDate: IdType[] | undefined;
    let projectIdsStorageNotInitialized: IdType[] | undefined;

    try {
      if (abortSignal?.aborted) {
        return undefined;
      }
      const notAvailableProjectIds = projectIds.filter((projectId) => !this.projectAvailabilityExpirationService.isProjectAvailable(projectId));
      projectIdsStorageNotInitialized = projectIds.filter((projectId) => !this.dataServiceFactoryService.isProjectAwareStorageDataInitialized(projectId));
      let runSync = false;
      if (notAvailableProjectIds.length > 0) {
        this.loggingService.info(LOG_SOURCE, `makeProjectsOfflineAvailable - calling storeProjectExpirationDate for projects ${notAvailableProjectIds}`);
        projectIdsUpdatedExpirationDate = notAvailableProjectIds;
        let newExpirationDate: Date|undefined;
        if (options.temporarily) {
          const expireInMs = this.devModeService.enabled ? this.devModeService.settings.temporarilyProjectExpirationInMs : TEMPORARILY_PROJECT_EXPIRATION_IN_MS;
          newExpirationDate = new Date(Date.now() + expireInMs);
        }
        const activeProjects = await observableToPromise(this.projectDataService.dataAcrossClientsActive$);
        await this.projectAvailabilityExpirationService.storeProjectExpirationDateAndInit(notAvailableProjectIds, activeProjects.map((p) => p.id), newExpirationDate);
        runSync = true;
      } else if (projectIdsStorageNotInitialized.length) {
        this.loggingService.info(LOG_SOURCE, 'makeProjectsOfflineAvailable - Only running sync since some storage data is not yet initialized. ' +
          'Either because application just started or storage expire date was set but sync did not yet run.');
        runSync = true;
      } else if (additionalProjectIdsToSync?.length) {
        this.loggingService.info(LOG_SOURCE, 'makeProjectsOfflineAvailable - Only running sync since some storage data is Outdated.');
        runSync = true;
      }
      if (abortSignal?.aborted) {
        if (projectIdsUpdatedExpirationDate?.length) {
          await this.restoreOldProjectAvailability(projectAvailabilityBefore, projectIdsUpdatedExpirationDate);
        }
        return undefined;
      }
      if (runSync) {
        const projectIdsToSync = _.compact([...notAvailableProjectIds, ...projectIdsStorageNotInitialized]);
        const projectIdsToSyncWithAdditional = _.compact([...projectIdsToSync, ...(additionalProjectIdsToSync || [])]);
        const syncResult = await this.syncService.startSync(SyncStrategy.PROJECTS_WITH_CHANGES,
          {doNotWaitForAttachmentSync: true, additionalProjectIdsToSync: projectIdsToSyncWithAdditional});
        let syncResultErrorMessage: string|undefined;
        if (syncResult === 'NO_NETWORK') {
          syncResultErrorMessage = this.translateService.instant('projectLoader.noInternet');
        } else if (syncResult === 'ALREADY_IN_PROGRESS') {
          syncResultErrorMessage = this.translateService.instant('projectLoader.syncErrorAlreadyInProgress');
        } else if (syncResult === 'FINISHED_WITH_ERRORS') {
          syncResultErrorMessage = this.translateService.instant('projectLoader.syncErrorWithErrors');
        }
        if (syncResultErrorMessage) {
          if (options.throwErrorIfSyncForEnsureDataNotOlderThanFails || projectIdsToSync.length) {
            throw new Error(syncResultErrorMessage);
          }
          this.loggingService.warn(LOG_SOURCE,
            `makeProjectsOfflineAvailable - failed syncing additionalProjectIds (${additionalProjectIdsToSync ?? []}) with error "${syncResultErrorMessage}. Ignoring it`);
        }
      }
      if (abortSignal?.aborted) {
        if (projectIdsUpdatedExpirationDate?.length) {
          await this.restoreOldProjectAvailability(projectAvailabilityBefore, projectIdsUpdatedExpirationDate);
        }
        return undefined;
      }
      this.loggingService.debug(LOG_SOURCE, 'makeProjectsOfflineAvailable finished successfully.');
      if (options.showToastWhenDone) {
        if (projectIdsStorageNotInitialized.length === 1) {
          const projectId = projectIdsStorageNotInitialized[0];
          const project = await observableToPromise(this.projectDataService.getById(projectId));
          this.toastService.info(this.translateService.instant('projectLoader.successToastOneProject', {projectName: project?.name}));
        } else if (projectIdsStorageNotInitialized.length > 1) {
          this.toastService.info(this.translateService.instant('projectLoader.successToastManyProjects', {count: projectIdsStorageNotInitialized.length}));
        }
      }
      return {projectIdsUpdatedExpirationDate, projectIdsStorageNotInitialized};
    } catch (error) {
      const errorMessage = convertErrorToMessage(error);
      this.loggingService.error(LOG_SOURCE, `makeProjectsOfflineAvailable failed with error ${errorMessage}`);
      if (projectIdsUpdatedExpirationDate?.length) {
        await this.restoreOldProjectAvailability(projectAvailabilityBefore, projectIdsUpdatedExpirationDate);
      }
      if (options.showToastWhenDone) {
        if (!projectIdsStorageNotInitialized?.length || projectIdsStorageNotInitialized.length === 1) {
          this.toastService.error(this.translateService.instant('projectLoader.errorToastOneProject', {errorMessage}), {message: errorMessage});
        } else {
          this.toastService.error(this.translateService.instant('projectLoader.errorToastManyProjects', {errorMessage}), {message: errorMessage});
        }
      }
      if (options.throwErrorIfMakingProjectsAvailableFailed) {
        throw new Error(`Making project(s) ${projectIds} offline Available failed with error ${errorMessage}.`);
      }
      return undefined;
    }
  }

  async restoreOldProjectAvailability(projectAvailabilityBefore: ProjectAvailability, projectIds: IdType[]) {
    const activeProjects = await observableToPromise(this.projectDataService.dataAcrossClientsActive$);
    const acitveProjectIds = activeProjects.map((p) => p.id);
    for (const projectId of projectIds) {
      const expirationTimestampStringBefore = projectAvailabilityBefore[projectId];
      if (!expirationTimestampStringBefore) {
        await this.projectAvailabilityExpirationService.storeProjectExpirationDateAndInit(projectId, acitveProjectIds, null, true);
      } else {
        await this.projectAvailabilityExpirationService.storeProjectExpirationDateAndInit(projectId, acitveProjectIds, new Date(expirationTimestampStringBefore), true);
      }
    }
  }
}
