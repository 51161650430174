import {Component, OnInit} from '@angular/core';
import {AlertController} from '@ionic/angular';
import {AuthenticationService} from '../../../services/auth/authentication.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {LoggingService} from '../../../services/common/logging.service';
import {TranslateService} from '@ngx-translate/core';

const LOG_SOURCE = 'ResetPasswordComponent';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  private modal: HTMLIonModalElement;
  public sending = false;
  public resetPasswordFailedMessageKey: string|undefined;
  public resetPasswordForm: UntypedFormGroup;

  constructor(private alertController: AlertController, private fb: UntypedFormBuilder,
              private translateService: TranslateService,
              private authenticationService: AuthenticationService, private loggingService: LoggingService) {
    this.resetPasswordForm = this.fb.group({
      username: ['', Validators.required]
    });
  }

  ngOnInit() {}

  async resetPassword() {
    if (this.sending || !this.resetPasswordForm.valid || !this.resetPasswordForm.dirty) {
      return;
    }
    try {
      this.sending = true;
      this.resetPasswordFailedMessageKey = undefined;
      await this.authenticationService.resetPasswordSend(this.resetPasswordForm.get('username').value);
      await this.showSuccessfulMessageAndDismissModal();
    } catch (error) {
      this.resetPasswordFailedMessageKey = error?.status === 404 ? 'resetPassword.send.error.notFound' : 'resetPassword.send.error.general';
      this.loggingService.error(LOG_SOURCE, `resetPasswordSend failed with reason "${error?.message}"`);
    } finally {
      this.sending = false;
    }
  }

  private async showSuccessfulMessageAndDismissModal() {
    const alert = await this.alertController.create({
      message: this.translateService.instant('resetPassword.send.successful'),
      buttons: [{
        text: this.translateService.instant('ok'),
        role: 'ok'
      }]
    });
    await alert.present();
    await this.modal.dismiss();
  }

  async cancel() {
    await this.modal.dismiss();
  }
}
