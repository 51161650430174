import {DOCUMENT} from '@angular/common';
import {Component, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PopoverController, ViewDidEnter} from '@ionic/angular';
import {BehaviorSubject, combineLatest, fromEvent, Observable, of, Subject} from 'rxjs';
import {map, switchMap, takeUntil} from 'rxjs/operators';
import {Nullish} from 'src/app/model/nullish';
import {TagObjectType} from 'src/app/model/tag';
import {TagService} from 'src/app/services/tags/tag.service';
import {TagsPopoverService} from 'src/app/services/tags/tags-popover.service';
import {observableToPromise} from 'src/app/utils/async-utils';
import {getBackdropFromElementShadowRoot} from 'src/app/utils/ionic-utils';
import {TagBase} from 'submodules/baumaster-v2-common';
import {ObjectTagListPopoverComponent} from '../object-tag-list-popover/object-tag-list-popover.component';
import {TagsSearchbarPopoverComponent} from '../tags-searchbar-popover/tags-searchbar-popover.component';

const WILL_BACKDROP_CLICK_TIMEOUT = 250;
const SEARCHBAR_FOCUS_TIMEOUT = 50;
@Component({
  selector: 'app-tags-popover',
  templateUrl: './tags-popover.component.html',
  styleUrls: ['./tags-popover.component.scss'],
  providers: [TagsPopoverService],
})
export class TagsPopoverComponent implements OnInit, OnDestroy, ViewDidEnter {
  popover: HTMLIonPopoverElement;

  private destroy$ = new Subject<void>();
  private viewModeSubject = new BehaviorSubject<'search' | 'manage' | 'edit'>('search');
  viewMode$ = this.viewModeSubject.asObservable();
  searchButtonIcon$ = this.viewMode$.pipe(
    map((viewMode) => {
      if (viewMode === 'edit') {
        return ['fal', 'check'];
      }

      return ['fal', 'times'];
    })
  );
  get viewMode() {
    return this.viewModeSubject.value;
  }

  search$ = this.tagsPopoverService.search$;
  searchbarFocus = false;

  private tagsSubject = new BehaviorSubject<TagBase[]>([]);
  @Input()
  set tags(tags: TagBase[]) {
    this.tagsSubject.next(tags);
  }
  get tags() {
    return this.tagsSubject.value;
  }

  @Input()
  objectType: TagObjectType;
  @Input()
  allTags$: Observable<TagBase[]>;

  @ViewChild(TagsSearchbarPopoverComponent, {
    read: TagsSearchbarPopoverComponent,
    static: true,
  })
  searchbar: TagsSearchbarPopoverComponent;

  @ViewChild(ObjectTagListPopoverComponent, {
    read: ObjectTagListPopoverComponent,
    static: false,
  })
  objectTagList: ObjectTagListPopoverComponent;

  filteredTags$: Observable<TagBase[]>;
  filteredSearchTags$: Observable<TagBase[]>;

  tagToEdit: Nullish<TagBase>;

  tagAlreadyExists$ = this.viewMode$.pipe(switchMap((mode) => mode === 'edit' ? combineLatest([
    this.allTags$,
    this.tagsPopoverService.search$
  ]).pipe(
    map(([allTags, search]) => allTags.some((tag) => tag.id !== this.tagToEdit.id && tag.name === search))
  ) : of(false)));
  searchbarActionDisabled$ = this.tagAlreadyExists$;

  preventBackdropClick = false;

  constructor(
    private popoverController: PopoverController,
    private tagService: TagService,
    private tagsPopoverService: TagsPopoverService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  async ngOnInit() {
    this.tagsPopoverService.search$.pipe(takeUntil(this.destroy$)).subscribe(
      (search) => this.objectTagList?.onSearchChange(search)
    );
    this.filteredTags$ = this.tagsPopoverService.getTagsWithFilter$(this.allTags$);
    this.filteredSearchTags$ = this.tagsPopoverService.getTagsWithFilterAndNew$(this.filteredTags$, this.tagsSubject);
    await this.initBackdropListeners();
  }

  private async initBackdropListeners() {
    const popoverRef = this.popover;
    const backdrop = getBackdropFromElementShadowRoot(popoverRef);
    if (!backdrop) {
      return;
    }
    fromEvent(backdrop, 'click', { capture: true }).pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      // Backdrop click, that happened soon after the searchbar blur;
      // prevent it for better UX on mobile
      if (this.preventBackdropClick) {
        this.preventBackdropClick = false;
        return;
      }
      // Android devices for any reason focuses on searchbar when clicked on backdrop;
      // setting blur here will prevent weird keyboard opening
      this.searchbar?.setBlur();
      this.dismiss();
    });
    fromEvent(this.document.defaultView, 'keydown').pipe(
      takeUntil(this.destroy$)
    ).subscribe((event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        this.dismiss();
      }
    });
  }

  private dismiss() {
    this.popoverController.dismiss(this.tags, 'save');
  }

  ionViewDidEnter() {
    setTimeout(() => {
      this.searchbar.setFocus();
    }, SEARCHBAR_FOCUS_TIMEOUT);
  }

  handleInputChange(event: CustomEvent<{value: string}>) {
    this.tagsPopoverService.search = event.detail.value;

    if (this.viewMode === 'edit') {
      this.tagToEdit = { ...this.tagToEdit, name: event.detail.value };
    }
  }

  handleSearchbarActionClick = async () => {
    await this.toggleManage();
  };

  handleWillBackdropClick() {
    this.preventBackdropClick = true;
    setTimeout(() => this.preventBackdropClick = false, WILL_BACKDROP_CLICK_TIMEOUT);
  }

  removeTag(tag: TagBase) {
    if (this.isTagSelected(tag)) {
      this.tags = this.tags.filter(({ id }) => id !== tag.id);
    }
  }

  deleteTag = async (tag: TagBase) => {
    await this.tagService.deleteTag(tag, this.objectType);
    this.leaveEditMode();
  };

  async addTag(tag: TagBase) {
    if (tag.id.startsWith(this.tagsPopoverService.newIdPrefix)) {
      tag.id = tag.id.replace(this.tagsPopoverService.newIdPrefix, '');
      tag = await this.createTag(tag);
    }

    if (!this.isTagSelected(tag)) {
      this.tags = [...this.tags, tag];
    }

    this.clearSearch();
  }

  editTag(tag: TagBase) {
    this.tagToEdit = tag;
    this.viewModeSubject.next('edit');
    this.searchbar.setInput(tag.name);
  }

  isTagSelected(tag: TagBase) {
    return this.tags.some(({id}) => id === tag.id);
  }

  handleEnterDown = async () => {
    if (this.viewMode === 'search') {
      const [firstTag] = await observableToPromise(this.filteredSearchTags$) ?? [];
      if (firstTag) {
        await this.addTag(firstTag);
      }
    }
  };

  private async createTag(tag: TagBase) {
    return await this.tagService.createTag(tag, this.objectType);
  }

  clearSearch() {
    this.tagsPopoverService.search = '';
    this.searchbar.clearInput();
  }

  async toggleManage() {
    switch (this.viewMode) {
      case 'edit':
        return await this.saveTag();
      case 'search':
      case 'manage':
        return this.dismiss();
      default:
        throw new Error(`Unknown transition from '${this.viewMode}'`);
    }
  }

  private async saveTag() {
    const {tagToEdit} = this;

    await this.tagService.updateTag(tagToEdit, this.objectType);
    this.tags = this.tags.map((tag) => tag.id === tagToEdit.id ? {
      ...tag,
      ...tagToEdit,
    } : tag);
    this.leaveEditMode();
  }

  private leaveEditMode() {
    this.tagToEdit = undefined;

    this.viewModeSubject.next('search');
    this.clearSearch();
  }

  goToManage() {
    this.viewModeSubject.next('manage');
  }

  ngOnDestroy() {
    this.viewModeSubject.complete();
    this.destroy$.next();
    this.destroy$.complete();
  }

}
