<app-modal-header>
  <ion-title>{{ 'project_room.pdf_plan_holders.filters.modal.title' | translate }}</ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <app-pdf-plan-filters></app-pdf-plan-filters>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-secondary" (click)="clearAndClose()">{{ 'project_room.pdf_plan_holders.filters.reset_filter' | translate }}</ion-button>
    <ion-button class="omg-btn-primary" (click)="save()">{{ 'project_room.pdf_plan_holders.filters.apply_filter' | translate }}</ion-button>
  </ion-buttons>
</app-modal-footer>
