<div class="d-flex flex-column centered ion-align-items-center ion-justify-content-center gap-4 p-4">
  <img class="brian-tools" src="/assets/images/brian_tools.png" />
  <div class="ion-text-center">
    <div class="omg-t-header-2-bold">{{(isClientAdmin ? 'appData.noProjects.admin.header' : 'appData.noProjects.nonAdmin.header') | translate}}</div>
    <div class="omg-t-body white-space-pre-line" *ngIf="!isClientAdmin">
      {{'appData.noProjects.nonAdmin.description' | translate}}
    </div>
    <div class="omg-t-body white-space-pre-line" *ngIf="isClientAdmin">
      {{'appData.noProjects.admin.description' | translate}}
    </div>
  </div>
  <ion-button *ngIf="isClientAdmin" class="omg-btn-primary" [routerLink]="['/projects']">
    <fa-icon slot="start" [icon]="['fal', 'plus']"></fa-icon>
    <span>{{'appData.noProjects.admin.create' | translate}}</span>
  </ion-button>
</div>
