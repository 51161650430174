import {ColumnConfig} from '../ColumnConfig';
import {AttachmentColumnSet} from './AttachmentColumnSet';
import {PdfPlanPageColumnSet} from './PdfPlanPageColumnSet';
import {PdfPreviewColumnSet} from './PdfPreviewColumnSet';
import {PdfProtocolSettingColumnSet} from './PdfProtocolSettingColumnSet';
import {ProtocolColumnSet} from './ProtocolColumnSet';
import {ProtocolEntryChatColumnSet} from './ProtocolEntryChatColumnSet';
import {ReportWeekColumnSet} from './ReportWeekColumnSet';
import {UserConnectionInviteColumnSet} from './UserConnectionInviteColumnSet';
import {UserInviteColumnSet} from './UserInviteColumnSet';
import {UserOfflineProjectColumnSet} from './UserOfflineProjectColumnSet';
import {NextMeetingColumnSet} from './NextMeetingColumnSet';
import {IndividualNextMeetingColumnSet} from './IndividualNextMeetingColumnSet';
import {UserEmailSignatureColumnSet} from './UserEmailSignatureColumnSet';
import {AttachmentUserEmailSignatureColumnSet} from './AttachmentUserEmailSignatureColumnSet';
import {AppTokenColumnSet} from './AppTokenColumnSet';
import {TwoFactorDeviceColumnSet} from './TwoFactorDeviceColumnSet';
import {UserDeviceOfflineProjectColumnSet} from './UserDeviceOfflineProjectColumnSet';
import {BimMarkerColumnSet} from './BimMarkerColumnSet';
import {CompanyColumnSet} from './CompanyColumnSet';
import {ProfileColumnSet} from './ProfileColumnSet';
import {ConstructionScheduleLinkColumnSet} from './ConstructionScheduleLinkColumnSet';
import {ConstructionScheduleTaskColumnSet} from './ConstructionScheduleTaskColumnSet';
import {ProjectColumnSet} from './ProjectColumnSet';

export const UserColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'username', cnd: true},
  {name: 'password'},
  {name: 'email'},
  {name: 'profile_id', prop: 'profileId', cnd: true},
  {name: 'language'},
  {name: 'is_staff', prop: 'isStaff'},
  {name: 'is_client_admin', prop: 'isClientAdmin'},
  {name: 'role'},
  {name: 'assigned_report_rights', prop: 'assignedReportRights'},
  {name: 'first_name', prop: 'firstName'},
  {name: 'last_name', prop: 'lastName'},
  {name: 'is_active', prop: 'isActive'},
  {name: 'is_superuser', prop: 'isSuperuser'},
  {name: 'date_joined', prop: 'dateJoined', cast: 'Timestamp'},
  {name: 'newsletter'},
  {name: 'my_projects_active', prop: 'myProjectsActive'},
  {name: 'terms_and_conditions_version', prop: 'termsAndConditionsVersion', def: null},
  {name: 'last_login', prop: 'lastLogin', cast: 'Timestamp', def: null},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'is_technical_user', prop: 'isTechnicalUser'},
  {name: 'attachmentChats', fk: 'createdById', mappedBy: AttachmentColumnSet, transient: true},
  {name: 'attachmentProjects', fk: 'createdById', mappedBy: AttachmentColumnSet, transient: true},
  {name: 'attachmentProtocolEntries', fk: 'createdById', mappedBy: AttachmentColumnSet, transient: true},
  {name: 'attachmentReportCompanies', fk: 'createdById', mappedBy: AttachmentColumnSet, transient: true},
  {name: 'pdfPlanAttachments', fk: 'createdById', mappedBy: AttachmentColumnSet, transient: true},
  {name: 'attachmentClients', fk: 'createdById', mappedBy: AttachmentColumnSet, transient: true},
  {name: 'attachmentProjectImages', fk: 'createdById', mappedBy: AttachmentColumnSet, transient: true},
  {name: 'attachmentReportActivities', fk: 'createdById', mappedBy: AttachmentColumnSet, transient: true},
  {name: 'attachmentReportEquipments', fk: 'createdById', mappedBy: AttachmentColumnSet, transient: true},
  {name: 'attachmentReportMaterials', fk: 'createdById', mappedBy: AttachmentColumnSet, transient: true},
  {name: 'attachmentReportSignatures', fk: 'createdById', mappedBy: AttachmentColumnSet, transient: true},
  {name: 'attachmentProtocolSignatures', fk: 'createdById', mappedBy: AttachmentColumnSet, transient: true},
  {name: 'pdfPlanPages', fk: 'createdById', mappedBy: PdfPlanPageColumnSet, transient: true},
  {name: 'pdfPreviews', fk: 'createdById', mappedBy: PdfPreviewColumnSet, transient: true},
  {name: 'pdfProtocolSettings', fk: 'userId', mappedBy: PdfProtocolSettingColumnSet, transient: true},
  {name: 'protocolEntryChats', fk: 'createdById', mappedBy: ProtocolEntryChatColumnSet, transient: true},
  {name: 'protocols', fk: 'createdById', mappedBy: ProtocolColumnSet, transient: true},
  {name: 'reportWeeks', fk: 'createdById', mappedBy: ReportWeekColumnSet, transient: true},
  {name: 'userConnectionInvites', fk: 'createdById', mappedBy: UserConnectionInviteColumnSet, transient: true},
  {name: 'userInvites', fk: 'createdById', mappedBy: UserInviteColumnSet, transient: true},
  {name: 'userOfflineProjects', fk: 'userId', mappedBy: UserOfflineProjectColumnSet, transient: true},
  {name: 'userDeviceOfflineProjects', fk: 'userId', mappedBy: UserDeviceOfflineProjectColumnSet, transient: true},
  {name: 'nextMeetings', fk: 'createdById', mappedBy: NextMeetingColumnSet, transient: true},
  {name: 'individualNextMeetings', fk: 'createdById', mappedBy: IndividualNextMeetingColumnSet, transient: true},
  {name: 'userEmailSignatures', fk: 'id', mappedBy: UserEmailSignatureColumnSet, transient: true},
  {name: 'attachmentUserEmailSignatures', fk: 'userId', mappedBy: AttachmentUserEmailSignatureColumnSet, transient: true},
  {name: 'appTokens', fk: 'userId', mappedBy: AppTokenColumnSet, transient: true},
  {name: 'createdAppTokens', fk: 'createdById', mappedBy: AppTokenColumnSet, transient: true},
  {name: 'twoFactorDevices', fk: 'userId', mappedBy: TwoFactorDeviceColumnSet, transient: true},
  {name: 'bimMarkers', fk: 'createdById', mappedBy: BimMarkerColumnSet, transient: true},
  {name: 'companies', fk: 'createdById', mappedBy: CompanyColumnSet, transient: true},
  {name: 'profiles', fk: 'createdById', mappedBy: ProfileColumnSet, transient: true},
  {name: 'projects', fk: 'createdById', mappedBy: ProjectColumnSet, transient: true},
  {name: 'constructionScheduleLinks', fk: 'createdById', mappedBy: ConstructionScheduleLinkColumnSet, transient: true},
  {name: 'constructionScheduleTasks', fk: 'createdById', mappedBy: ConstructionScheduleTaskColumnSet, transient: true},
];
