import {Inject, Injectable, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable, ReplaySubject} from 'rxjs';
import {AuthenticateRefreshTokenReq, AuthenticateRes, IdType, ResetPasswordConfirmReq, ResetPasswordSendReq, ResetPasswordSendRes, TwoFactorAuthenticate} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {observableToPromise} from '../../utils/async-utils';
import {StorageService} from '../storage.service';
import {AttachmentSettingService} from '../attachment/attachmentSetting.service';
import { ModalController } from '@ionic/angular';
import { LoginAsComponent } from 'src/app/components/admin/login-as/login-as.component';
import {AuthDetails, AuthTokenWithExpireAt} from 'src/app/model/auth';
import {AlertService} from '../ui/alert.service';
import {Router} from '@angular/router';
import {distinctUntilChanged, map} from 'rxjs/operators';

const STORAGE_KEY = StorageKeyEnum.AUTHENTICATION;
const LOG_SOURCE = 'AuthenticationService';

export interface LoginOptions {
  staySignedIn: boolean;
  twoFactor: TwoFactorAuthenticate;
  automaticallyChangeFileAccessUtil: boolean;
  skipImpersonate: boolean;
}

// Per https://stackoverflow.com/questions/12666127/what-range-of-dates-are-permitted-in-javascript/12666128#12666128
const MAX_DATE = 8640000000000000;

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  public readonly LOGIN_URL = environment.serverUrl + 'auth/api-token-auth/';
  public readonly IMPERSONATE_LOGIN_URL = environment.serverUrl + 'auth/api-token-impersonate/';
  public readonly RESET_PASSWORD_SEND_URL = environment.serverUrl + 'auth/resetPassword/send';
  public readonly RESET_PASSWORD_CONFIRM_URL = environment.serverUrl + 'auth/resetPassword/confirm';
  public static readonly REFRESH_TOKEN_URL = `${environment.serverUrl}auth/api-refresh-token-auth/`;
  private readonly dataSubject = new ReplaySubject<AuthDetails|null>(1);
  public readonly data: Observable<AuthDetails|null> = this.dataSubject.asObservable();
  public readonly isAuthenticated$: Observable<boolean> = this.data.pipe(
    map((auth) => !!auth),
    distinctUntilChanged()
  );
  public readonly authenticatedUserId$: Observable<IdType|undefined> = this.data.pipe(
    map((auth) => auth?.userId ?? undefined),
    distinctUntilChanged()
  );
  public readonly dataToken: Observable<AuthTokenWithExpireAt|undefined> = this.data.pipe(map((auth) => {
    if (!auth) {
      return undefined;
    }

    return {
      token: auth.token,
      expireAt: 'refreshToken' in auth ? new Date(auth.tokenExpiresAt) : new Date(MAX_DATE),
    };
  }));
  private readonly httpOptions = {
    headers: {
      Accept: 'application/json; version=6'
    }
  };

  private _modalController: ModalController;

  get modalController(): ModalController {
    if (!this._modalController) {
      this._modalController = this.injector.get(ModalController);
    }

    return this._modalController;
  }

  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private loggingService: LoggingService,
    private attachmentSettingService: AttachmentSettingService,
    private alertService: AlertService,
    private router: Router,
    @Inject(Injector) private injector: Injector
  ) {
    this.loggingService.debug(LOG_SOURCE, 'constructor called');
    this.initDataSubject();
  }

  private async initDataSubject(): Promise<AuthDetails|null> {
    const auth = await this.getStorageData();
    this.dataSubject.next(auth);
    return auth;
  }

  private async loginAs(username: string, adminAuth: AuthenticateRes): Promise<AuthenticateRes> {
    const loginObservable = this.http.post<AuthenticateRes>(this.IMPERSONATE_LOGIN_URL, {
      username,
    }, {
      ...this.httpOptions,
      headers: {
        ...this.httpOptions.headers,
        Authorization: `Token ${adminAuth.token}`,
      },
    });

    // TODO check for error status code 400 and parse CustomError response
    return await loginObservable.toPromise();
  }

  private async loginOrImpersonate(auth: AuthenticateRes, email: string): Promise<AuthenticateRes> {
    if (!auth.isSuperAdmin) {
      return auth;
    }

    const modal = await this.modalController.create({
      component: LoginAsComponent,
      componentProps: {
        email,
        loginCallback: (username) => {
          if (username === email) {
            this.loggingService.info(LOG_SOURCE, `Superuser "${username}" logged in as itself (not impersonated).`);
            return auth;
          }
          return this.loginAs(username, auth);
        },
      }
    });
    await modal.present();

    const { data, role } = await modal.onDidDismiss<AuthenticateRes>();

    if (role === 'cancel' || role === 'backdrop') {
      const error = new (class extends Error {
        status = 400;
        error = {
          errorCode: 'SUPERUSER_LOGIN_CANCELLED'
        };
      })();

      throw error;
    }

    if (!data) {
      return auth;
    }
    if (data.userId === auth.userId && data.token === auth.token) {
      return auth; // This is actually not impersonated, as a superuser is logging in as himself.
    }
    return data;
  }

  public async replaceToken(newToken: AuthenticateRes) {
    await this.setStorageData(newToken);
    this.dataSubject.next(newToken);
    this.loggingService.debug(LOG_SOURCE, 'replaceToken - replaced data with a new token');
  }

  public async login(username: string, password: string, {
    automaticallyChangeFileAccessUtil = true,
    staySignedIn = true,
    twoFactor,
    skipImpersonate = false,
  }: Partial<LoginOptions> = {}): Promise<AuthenticateRes> {
    const payload = {username, password, twoFactor};
    const loginObservable = this.http.post<AuthenticateRes>(this.LOGIN_URL, payload, this.httpOptions);

    // TODO check for error status code 400 and parse CustomError response
    const auth = skipImpersonate ? await loginObservable.toPromise() : await this.loginOrImpersonate(await loginObservable.toPromise(), username);
    if (automaticallyChangeFileAccessUtil) {
      await this.attachmentSettingService.changeFileAccessUtil(this.attachmentSettingService.getDefaultFileAccessUtilForDevice().className);
    }
    if (staySignedIn) {
      await this.setStorageData(auth);
    } else {
      await this.removeStorageData();
    }

    await this.storage.remove(StorageKeyEnum.CURRENT_PROJECT);

    this.dataSubject.next(auth);

    if (twoFactor && twoFactor.method === 'recovery_code') {
      this.showRegenerateCodesAlert();
    }

    return auth;
  }

  private async showRegenerateCodesAlert() {
    const navigateToSecurity = await this.alertService.confirm({
      header: 'twoFactor.usedRecoveryCode.header',
      message: 'twoFactor.usedRecoveryCode.message',
      confirmLabel: 'twoFactor.usedRecoveryCode.confirmLabel',
      confirmButton: {fill: 'outline'},
      cancelLabel: 'close',
      dismissOnBackButtonOrNavigation: false,
    });

    if (navigateToSecurity) {
      this.router.navigate(['/the-settings/security-settings']);
    }
  }

  public async logout() {
    this.loggingService.debug(LOG_SOURCE, 'logout called');
    this.loggingService.debug(LOG_SOURCE, `logout - before storage.remove(${STORAGE_KEY})`);
    const nullAuthPromise = new Promise<void>((res) => {
      // Force to run auth null in the next loop
      // Multiple subscribers have sync handlers which should run after the storage clear is scheduled
      setTimeout(() => {
        this.dataSubject.next(null);
        res();
      });
    });
    await this.storage.clear();
    const fileAccessUtil = await this.attachmentSettingService.getFileAccessUtil();
    await fileAccessUtil.deleteAll();
    this.loggingService.debug(LOG_SOURCE, `logout - before storage.remove(${STORAGE_KEY})`);
    await nullAuthPromise;
  }

  public async resetPasswordSend(username: string): Promise<ResetPasswordSendRes> {
    const payload: ResetPasswordSendReq = {username};
    return await observableToPromise(this.http.post<ResetPasswordSendRes>(this.RESET_PASSWORD_SEND_URL, payload, this.httpOptions));
  }

  public async resetPasswordConfirm(resetPasswordLink: string, password: string): Promise<void> {
    const payload: ResetPasswordConfirmReq = {
      password,
      resetPasswordLink
    };
    return await observableToPromise(this.http.post<void>(this.RESET_PASSWORD_CONFIRM_URL, payload, this.httpOptions));
  }

  public getAccessToken(body: AuthenticateRefreshTokenReq): Observable<AuthenticateRes> {
    return this.http.post<AuthenticateRes>(AuthenticationService.REFRESH_TOKEN_URL, body);
  }

  private async getStorageData(): Promise<AuthDetails | null> {
    return await this.storage.get(STORAGE_KEY);
  }

  private async setStorageData(auth: AuthenticateRes): Promise<void> {
    this.loggingService.debug(LOG_SOURCE, `setStorageData called.`);
    this.loggingService.debug(LOG_SOURCE, `setStorageData - before storage.set(${STORAGE_KEY})`, auth);
    await this.storage.set(STORAGE_KEY, auth);
    this.loggingService.debug(LOG_SOURCE, `setStorageData - after storage.set(${STORAGE_KEY})`, auth);
  }

  private async removeStorageData(): Promise<void> {
    this.loggingService.debug(LOG_SOURCE, `login - before storage.remove(${STORAGE_KEY})`);
    await this.storage.remove(STORAGE_KEY);
    this.loggingService.debug(LOG_SOURCE, `login - after storage.remove(${STORAGE_KEY})`);
  }

}
