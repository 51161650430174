import {Inject, Injectable, OnDestroy} from '@angular/core';
import {Attachment, IdType} from 'submodules/baumaster-v2-common';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {LoggingService} from '../common/logging.service';
import {Observable, Subscription} from 'rxjs';
import {StorageKeyEnum} from '../../shared/constants';
import {TranslateService} from '@ngx-translate/core';
import {DataServiceDeleteOptions, DataServiceInsertOptions, VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';
import {AttachmentDataHelperServiceImpl} from './attachment-data-helper.service';
import _ from 'lodash';
import {AbstractNonClientAwareDataService} from './abstract-non-client-aware-data.service';
import {StorageService} from '../storage.service';
import {AttachmentSettingService} from '../attachment/attachmentSetting.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {SystemEventService} from '../event/system-event.service';
import {DevModeService} from '../common/dev-mode.service';

@Injectable()
export abstract class AbstractNonClientAwareAttachmentDataService<T extends Attachment> extends AbstractNonClientAwareDataService<T> implements OnDestroy {
  private dataSubscription: Subscription;
  private attachmentDataHelperService: AttachmentDataHelperServiceImpl<T>;

  constructor(@Inject('StorageKeyEnum') storageKey: StorageKeyEnum,
              @Inject('String') protected readonly restEndpointUri: string,
              @Inject('Array') protected readonly defaultValue: Array<T>,
              http: HttpClient, storage: StorageService,
              authenticationService: AuthenticationService,
              loggingService: LoggingService, protected systemEventService: SystemEventService, protected devModeService: DevModeService,
              integrityResolverService: IntegrityResolverService,
              protected translateService: TranslateService, protected attachmentSettingService: AttachmentSettingService,
              public versionIntroduced = VERSION_INTRODUCED_DEFAULT,
              @Inject('Array') protected sortColumns?: Array<keyof T | ((item: T) => any)>,
              @Inject('Array') protected sortColumnOrders?: Array<'asc'|'desc'>) {
    super(storageKey, restEndpointUri, defaultValue, http, storage, authenticationService, loggingService,
          integrityResolverService, versionIntroduced, sortColumns, sortColumnOrders);
    this.attachmentDataHelperService = new AttachmentDataHelperServiceImpl(this, this.translateService, this.attachmentSettingService.fileAccessUtil$, this.systemEventService,
      this.devModeService, this.loggingService);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.dataSubscription?.unsubscribe();
  }

  public getAttachmentsByIds(attachmentIds: Array<IdType>): Observable<Array<T>|null> {
    return super.getByIds(attachmentIds);
  }

  private setGeneratedFilePathForValueOrArray(valueOrArray: T | Array<T>) {
    if (_.isArray(valueOrArray)) {
      (valueOrArray as Array<T>).forEach((value) => this.setGeneratedFilePath(value));
    } else {
      this.setGeneratedFilePath(valueOrArray as T);
    }
  }

  protected abstract setGeneratedFilePath(attachment: T);

  protected async updatedChangedAt(ids: Array<IdType>, newChangedAt: Date|string, clientId?: IdType,
                                   changedCallback?: (changedItem: T, oldChangedAt: Date|string) => Promise<void>): Promise<Array<T>> {
    const changedItemCallback = await this.attachmentDataHelperService.updatedChangedAt(ids, newChangedAt, clientId, changedCallback);
    return await super.updatedChangedAt(ids, newChangedAt, clientId, changedItemCallback);
  }

  public async insertOrUpdate(value: T, projectId?: IdType, blob?: Blob): Promise<Array<T>> {
    try {
      this.setGeneratedFilePathForValueOrArray(value);
      await this.attachmentDataHelperService.insertOrUpdate(value, projectId, blob);
      const result = await super.insertOrUpdate(value);
      this.systemEventService.logEvent(this.logSource + '.insertOrUpdate', () => this.logSource + ` - insertOrUpdate of attachment(s) (${this.valueOrArrayAsIdString(value)}) successful`);
      return result;
    } catch (e) {
      this.systemEventService.logErrorEvent(() => this.logSource + ` - insertOrUpdate of attachment(s) (${this.valueOrArrayAsIdString(value)}) failed`, e);
      throw e;
    }
  }

  public async insert(valueOrArray: T | Array<T>, options?: DataServiceInsertOptions, blobOrArray?: Blob | Array<Blob>): Promise<Array<T>> {
      try {
      this.setGeneratedFilePathForValueOrArray(valueOrArray);
      await this.attachmentDataHelperService.insert(valueOrArray, undefined, options, blobOrArray);
      const result = await super.insert(valueOrArray, options);
      this.systemEventService.logEvent(this.logSource + '.insert', () => this.logSource + ` - insert of attachment(s) (${this.valueOrArrayAsIdString(valueOrArray)}) successful`);
      return result;
    } catch (e) {
      this.systemEventService.logErrorEvent(() => this.logSource + ` - insert of attachment(s) (${this.valueOrArrayAsIdString(valueOrArray)}) failed`, e);
      throw e;
    }
  }

  public async update(valueOrArray: T | Array<T>, clientId?: IdType): Promise<Array<T>> {
    try {
      await this.attachmentDataHelperService.update(valueOrArray);
      const result = await super.update(valueOrArray);
      this.systemEventService.logEvent(this.logSource + '.update', () => this.logSource + ` - update of attachment(s) (${this.valueOrArrayAsIdString(valueOrArray)}) successful`);
      return result;
    } catch (e) {
      this.systemEventService.logErrorEvent(() => this.logSource + ` - update of attachment(s) (${this.valueOrArrayAsIdString(valueOrArray)}) failed`, e);
      throw e;
    }
  }

  public async delete(valueOrArray: T | Array<T>, options?: DataServiceDeleteOptions): Promise<void> {
    try {
      await this.attachmentDataHelperService.delete(valueOrArray);
      await super.delete(valueOrArray, options);
      this.systemEventService.logEvent(this.logSource + '.delete', () => this.logSource + ` - delete of attachment(s) (${this.valueOrArrayAsIdString(valueOrArray)}) successful`);
    } catch (e) {
      this.systemEventService.logErrorEvent(() => this.logSource + ` - delete of attachment(s) (${this.valueOrArrayAsIdString(valueOrArray)}) failed`, e);
      throw e;
    }
  }

  protected async removeStorageData(): Promise<any> {
    await super.removeStorageData();
  }

  public attachmentFilesChangedExternally(): void {
    this.dataSubject.next(this.dataSubject.value);
  }

}
