import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {AbstractClientAwareDataService} from './abstract-client-aware-data.service';
import {IdType, Profile, User} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {ClientService} from '../client/client.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import _ from 'lodash';
import {flattenMap} from '../../utils/data-utils';

const REST_ENDPOINT_URI = 'api/data/profiles/';

const filterDefaultType = (profile: Profile): boolean => !profile.type;

@Injectable({
  providedIn: 'root'
})
export class ProfileDataService extends AbstractClientAwareDataService<Profile> {
  public readonly dataGroupedByAttachedToUserId: Observable<Map<IdType, Profile>> = this.data.pipe(
    map(entities => {
      const profileMap = new Map<IdType, Profile>();
      if (entities === null) {
        return profileMap;
      }
      entities.filter((entry) => entry.attachedToUserId).forEach((entry) => profileMap.set(entry.attachedToUserId, entry));
      return profileMap;
    })
  );

  public readonly dataWithDefaultType$ = this.data.pipe(map((profiles) => profiles.filter(filterDefaultType)));
  public readonly dataForOwnClientWithDefaultType$ = this.dataForOwnClient$.pipe(map((profiles) => profiles.filter(filterDefaultType)));
  public readonly dataByClientIdWithDefaultType$ = this.dataByClientId$.pipe(map((dataByClientId) => {
    const newMap = new Map<IdType, Array<Profile>>();
    for (const clientId of dataByClientId.keys()) {
      newMap.set(clientId, dataByClientId.get(clientId).filter(filterDefaultType));
    }
    return newMap;
  }));
  public readonly dataAcrossClientsWithDefaultType$ = this.dataByClientIdWithDefaultType$.pipe(map(flattenMap));
  public readonly dataGroupedByIdWithDefaultType$: Observable<Record<IdType, Profile>> = this.dataWithDefaultType$.pipe(
    map(entities => entities === null ? {} : _.keyBy(entities, 'id'))
  );

  constructor(http: HttpClient, storage: StorageService, authenticationService: AuthenticationService, userService: UserService, clientService: ClientService,
              loggingService: LoggingService, integrityResolverService: IntegrityResolverService) {
    super(StorageKeyEnum.PROFILE, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, clientService, loggingService,
          integrityResolverService);
  }

  getByAttachedUserId(id: IdType): Observable<Profile|undefined> {
    return this.data.pipe(
      map((profiles) => profiles.find((profile) => profile.attachedToUserId === id))
    );
  }

  getAllByUserAcrossClients(user: User): Observable<Profile[]|undefined> {
    return this.dataAcrossClients$.pipe(
      map((profiles) => profiles.filter((profile) => profile.attachedToUserId === user.id || profile.id === user.profileId))
    );
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }
}
