import {Injectable} from '@angular/core';

export type TriggerEntrySaveFunction = () => Promise<void>;

@Injectable({
  providedIn: 'root'
})
export class TriggerEntrySaveService {

  private saveFunctions = new Array<TriggerEntrySaveFunction>();

  constructor() { }

  registerSaveFunction(saveFunction: TriggerEntrySaveFunction) {
    this.saveFunctions.push(saveFunction);
  }

  unregisterSaveFunction(saveFunction: TriggerEntrySaveFunction) {
    const index = this.saveFunctions.indexOf(saveFunction);
    if (index === -1) {
      return;
    }
    this.saveFunctions.splice(index, 1);
  }

  unregisterAllSaveFunctions() {
    this.saveFunctions.splice(0);
  }

  async triggerSave(): Promise<void> {
    for (const saveFunction of this.saveFunctions) {
      await saveFunction();
    }
  }
}
