import {Injectable} from '@angular/core';
import {PdfPlanFolder, User} from 'submodules/baumaster-v2-common';
import {AbstractProjectAwareDataService} from './abstract-project-aware-data.service';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {ProjectDataService} from './project-data.service';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';
import {VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';

const REST_ENDPOINT_URI = 'api/data/pdfPlanFolders';

@Injectable({
  providedIn: 'root'
})
export class PdfPlanFolderDataService  extends AbstractProjectAwareDataService<PdfPlanFolder> {

  constructor(http: HttpClient, storage: StorageService,
              authenticationService: AuthenticationService,
              userService: UserService,
              protected projectDataService: ProjectDataService,
              protected projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
              loggingService: LoggingService, integrityResolverService: IntegrityResolverService) {
    super(StorageKeyEnum.PDF_PLAN_FOLDER, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, projectDataService, loggingService, projectAvailabilityExpirationService,
          integrityResolverService, VERSION_INTRODUCED_DEFAULT, [(value) => value?.name?.toLowerCase()]);
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }
}
