import {NetworkError, NetworkTimeoutError, TimeoutError} from '../shared/errors';

export const PING_TIMEOUT_IN_MS = 10 * 1000;
export const DEFAULT_FETCH_TIMEOUT_IN_MS = 5 * 60 * 1000;
export const DEFAULT_PDF_GENERATION_TIMEOUT_IN_MS = 15 * 60 * 1000;
export const TIMEOUT_ERROR = 'Timeout';
export const NETWORK_ERRORS = new Set<string>(
  ['Network request failed', 'Network error', 'NetworkError when attempting to fetch resource.', 'Failed to fetch', 'Timeout has occurred', 'OfflineError']);

export function createTimeoutPromise(timeoutInMs: number): Promise<void> {
  return new Promise<void>((resolve, reject) => setTimeout(
    () => reject(new TimeoutError(TIMEOUT_ERROR)),
    timeoutInMs
  ));
}

export async function fetchWithTimeout(requestOrUrl: Request | string, abortControllerOrSignal?: AbortController | AbortSignal, timeout = DEFAULT_FETCH_TIMEOUT_IN_MS): Promise<Response> {
  try {
    let signal: AbortSignal|undefined;
    if (abortControllerOrSignal) {
      if ('signal' in abortControllerOrSignal) {
        // Explicit abort definition, due to the TypeScript bug: https://github.com/microsoft/TypeScript/issues/49609
        const abortController = abortControllerOrSignal as (AbortController & {abort(reason?: any): void});
        signal = abortController.signal;
        signal.addEventListener('abort', abortController.abort);
      } else {
        signal = abortControllerOrSignal as AbortSignal;
      }
    }

    return await Promise.race([
      fetch(requestOrUrl, signal ? {signal} : {}),
      new Promise<Response>((_, reject) => setTimeout(
        () => reject(new NetworkTimeoutError(TIMEOUT_ERROR)),
        timeout
      )),
    ]);
  } catch (e) {
    if (NETWORK_ERRORS.has(e.message)) {
      throw new NetworkError(e.message);
    }
    throw e;
  }
}

