import {IconDefinition, IconName, IconPrefix} from '@fortawesome/fontawesome-common-types';

export const bauCardExpanded: IconDefinition = {
  prefix: 'bau' as IconPrefix,
  iconName: 'card-expanded' as IconName,
  icon: [
    16,
    16,
    [],
    null,
    // eslint-disable-next-line max-len
    'M2.5 5c0-.28.22-.5.5-.5h10c.28 0 .5.22.5.5s-.22.5-.5.5H3c-.28 0-.5-.22-.5-.5ZM3 6.5c-.28 0-.5.22-.5.5s.22.5.5.5h10c.28 0 .5-.22.5-.5s-.22-.5-.5-.5H3ZM2.5 9c0-.28.22-.5.5-.5h5c.28 0 .5.22.5.5s-.22.5-.5.5H3c-.28 0-.5-.22-.5-.5ZM13.67 3.29c.37 0 .67-.29.67-.64s-.3-.64-.67-.64-.67.29-.67.64.3.64.67.64ZM2.5 3c0-.28.22-.5.5-.5h1.5c.28 0 .5.22.5.5s-.22.5-.5.5h-1.5c-.28 0-.5-.22-.5-.5ZM4 11h-1c-.55 0-1 .45-1 1v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1c0-.55-.45-1-1-1ZM4 13h-1v-1h1v1ZM8 11h-1c-.55 0-1 .45-1 1v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1c0-.55-.45-1-1-1ZM8 13h-1v-1h1v1ZM12 11h-1c-.55 0-1 .45-1 1v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1c0-.55-.45-1-1-1ZM12 13h-1v-1h1v1ZM15 0H2C.9 0 0 .9 0 2v13c0 .55.45 1 1 1h14c.55 0 1-.45 1-1V1c0-.55-.45-1-1-1ZM15 14.7c0 .16-.14.3-.3.3H1.3c-.16 0-.3-.14-.3-.3V2c0-.55.45-1 1-1h12.7c.16 0 .3.14.3.3v13.4Z'
  ]
};
