<div class="new-tag" *ngIf="isNew">
  {{ 'new' | translate }}
</div>
<div class="main-grid">
  <span class="field-id" *ngIf="entryCardSettings.showId && protocolEntryNumber !== null && protocolEntryNumber !== undefined">
    <span class="badged-id" [ngClass]="badgeColorClass">{{ protocolEntryNumber }}</span>
  </span>
  <span class="field-id" *ngIf="entryCardSettings.showId && taskNumber !== null && taskNumber !== undefined">Nr. {{ taskNumber }}</span>
  <span class="field-checkbox d-flex ion-align-items-center gap-2" (click)="$event.stopPropagation()" *ngIf="showSelectCheckbox || checkboxTemplate || isProtocolClosed || !!createdInProtocol">
    <fa-icon *ngIf="isProtocolClosed" [icon]="['fal', 'lock-alt']"></fa-icon>
    <ng-container *ngIf="!!createdInProtocol">
      <div [id]="'protocol-entry-added-later-warning-sign-' + instanceId">
        <fa-layers class="created-in-another-protocol-icon">
          <fa-icon [fixedWidth]="true" [icon]="['fas', 'triangle']" class="tri-bg"></fa-icon>
          <fa-icon [fixedWidth]="true" [icon]="['fal', 'exclamation-triangle']"></fa-icon>
        </fa-layers>
      </div>
      <app-tooltip [target]="'protocol-entry-added-later-warning-sign-' + instanceId" placement="bottom">
        {{ 'protocolEntry.createdInAnotherProtocolTooltip' | translate:{shortName: createdInProtocol} }}
      </app-tooltip>
    </ng-container>
    @if (showThreeDots) {
    <ion-button class="omg-btn-text three-dots" (click)="$event.stopPropagation(); threeDotsClick.emit($event)">
      <fa-icon slot="icon-only" [icon]="['far', 'ellipsis-h']"></fa-icon>
    </ion-button>
    }
    <ion-checkbox label-placement="end" justify="start" *ngIf="!checkboxTemplate && showSelectCheckbox" [ngModel]="checkboxSelected" (ngModelChange)="checkboxSelectedChange.emit($event)" class="omg-checkbox"></ion-checkbox>
    <ng-container *ngTemplateOutlet="checkboxTemplate"></ng-container>
  </span>
  <span class="field-title">
    <span class="field-title-text">{{ titleText || '-' }}</span>
  </span>
  <span *ngIf="entryCardSettings.showText" class="field-text">{{ text || '-' }}</span>
</div>
<div class="detailed-grid">
  <span class="field-company">{{ company || '-' }}</span>
  <span *ngIf="entryCardSettings.showCraft" class="field-craft">
    <fa-icon class="text-secondary" [icon]="['fal', 'tools']"></fa-icon>
    <span class="ellipsis">{{ craft || '-' }}</span>
  </span>
  <span *ngIf="entryCardSettings.showType" class="field-type">
    <fa-icon class="text-secondary" [icon]="['fal', 'tag']"></fa-icon>
    <span class="ellipsis">{{ type || '-' }}</span>
  </span>
  <span *ngIf="entryCardSettings.showPriorityDate" class="field-date-and-priority">
    <span class="field-priority">
      <fa-icon [icon]="['bau', 'flag']" [ngClass]="priorityClasses"></fa-icon>
    </span>
    <span class="field-date ellipsis" *ngIf="date">
      {{ 'reports.calendarWeekPrefix' | translate }} {{ dateWeek }} | {{ date | date:TAGLINE_DATE_FORMAT }}
    </span>
    <span class="field-date ellipsis" *ngIf="!date">-</span>
  </span>
  <span *ngIf="entryCardSettings.showAttachmentsSub" class="field-icon-details">
    <span  class="field-has-marker">
      <fa-icon [icon]="['fal', 'map-marker-alt']" [class.highlighted]="hasMarker"></fa-icon>
    </span>
    <span class="field-has-attachments">
      <fa-icon [icon]="['fal', 'paperclip']" [class.highlighted]="hasAttachments"></fa-icon>
    </span>
    <span class="field-has-chat">
      <fa-icon [icon]="['fal', 'comment-alt-lines']" [class.highlighted]="hasChat"></fa-icon>
    </span>
    <span class="field-subentries-count">
      <fa-icon [icon]="['fal6', 'diagram-subtask']" [class.highlighted]="(subEntriesCount || 0) > 0"></fa-icon>
      <span class="field-subentries-count-text text-secondary">
        {{ (subEntriesCount || 0) }}
      </span>
    </span>
  </span>
  <span class="field-status" (click)="$event.stopPropagation(); statusClick.emit($event)">
    <fa-icon [icon]="statusIcon" [ngClass]="statusClasses"></fa-icon>
  </span>
  <span *ngIf="entryCardSettings.showCraft || entryCardSettings.showType || entryCardSettings.showPriorityDate || entryCardSettings.showAttachmentsSub" class="field-placeholder"></span>
</div>
<div class="footer-template"><ng-container *ngTemplateOutlet="footerTemplate"></ng-container></div>
