import {Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {map} from 'rxjs/operators';
import {PdfPlanWithDeletable} from 'src/app/model/pdf-plan-with-deletable';
import {ProjectDataService} from 'src/app/services/data/project-data.service';
import {PdfPlanHolderActionsService} from 'src/app/services/project-room/pdf-plan-holder-actions.service';
import {PdfPlanHolderEditModeService} from 'src/app/services/project-room/pdf-plan-holder-edit-mode.service';
import {PdfPlansFilterModalService} from 'src/app/services/project-room/pdf-plans-filter-modal.service';
import {PdfPlansFilterService} from 'src/app/services/project-room/pdf-plans-filter.service';
import {PdfPlanHolderSelectionService} from 'src/app/services/project-room/pdf-plan-holder-selection.service';
import {PdfPlanHolderService} from 'src/app/services/project-room/pdf-plan-holder.service';
import {observableToPromise} from 'src/app/utils/async-utils';
import {PlanAnalyticsService} from 'src/app/services/project-room/plan-analytics.service';

@Component({
  selector: 'app-pdf-plan-folder-toolbar',
  templateUrl: './pdf-plan-folder-toolbar.component.html',
  styleUrls: ['./pdf-plan-folder-toolbar.component.scss'],
})
export class PdfPlanFolderToolbarComponent implements OnInit {
  @Input()
  searchbarTemplate: TemplateRef<any>;
  @Input()
  search: string;
  @Output()
  searchFocus = new EventEmitter<void>();

  isHoldersEditMode$ = this.pdfPlanHolderEditModeService.editMode$;
  multiselectMode$ = this.pdfPlanHolderSelectionService.multiselectMode$;
  anySelected$ = this.pdfPlanHolderSelectionService.selectedSet$.pipe(map((set) => set.size > 0));
  anySelectedAndAllDeletable$ = this.pdfPlanHolderSelectionService.selected$.pipe(
    map((holders) => holders.length > 0 && holders.every((holder) => holder.latestPdfPlanVersionDeletable))
  );
  hasFilter$ = this.pdfPlansFilterService.hasFilter$;

  constructor(
    private pdfPlanHolderEditModeService: PdfPlanHolderEditModeService,
    private pdfPlanHolderSelectionService: PdfPlanHolderSelectionService,
    private pdfPlanHolderActionsService: PdfPlanHolderActionsService,
    private pdfPlanHolderService: PdfPlanHolderService,
    private projectDataService: ProjectDataService,
    private pdfPlansFilterModalService: PdfPlansFilterModalService,
    private pdfPlansFilterService: PdfPlansFilterService,
    public analytics: PlanAnalyticsService
  ) { }

  selectedCount() {
    return this.pdfPlanHolderSelectionService.selectedCount();
  }

  ngOnInit() {}

  openFilters() {
    this.pdfPlansFilterModalService.openFilters();
  }

  toggleMultiselect() {
    if (this.pdfPlanHolderSelectionService.multiselectMode) {
      this.pdfPlanHolderSelectionService.leaveMultiselectMode();
    } else {
      this.pdfPlanHolderSelectionService.enterMultiselectMode();
    }
  }

  async share() {
    if (await this.pdfPlanHolderActionsService.openSharePdfPlans(await this.getSelectedHolders())) {
      this.pdfPlanHolderSelectionService.leaveMultiselectMode();
    }
  }

  edit() {
    this.pdfPlanHolderEditModeService.enterEditMode();
  }

  async download() {
    await this.pdfPlanHolderActionsService.downloadMultiplePdfPlans(await this.getSelectedHolders(), (await this.projectDataService.getMandatoryCurrentProject()).id);
    this.pdfPlanHolderSelectionService.leaveMultiselectMode();
  }

  async move() {
    if (await this.pdfPlanHolderActionsService.openChangePdfPlansFolder(await this.getSelectedHolders())) {
      this.pdfPlanHolderSelectionService.leaveMultiselectMode();
    }
  }

  async delete() {
    await this.pdfPlanHolderService.deleteLatestPdfPlanVersions(await this.getSelectedHolders());
    this.pdfPlanHolderSelectionService.leaveMultiselectMode();
  }


  private async getSelectedHolders(): Promise<PdfPlanWithDeletable[]> {
    return await observableToPromise(this.pdfPlanHolderSelectionService.selected$);
  }

}
