import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {WorkflowType} from 'src/app/model/send-protocol';
import {FeatureEnabledService} from 'src/app/services/feature/feature-enabled.service';
import {UserService} from 'src/app/services/user/user.service';
import {LicenseType, Participant, Protocol, Report} from 'submodules/baumaster-v2-common';
import {NextMeetingComponent} from '../next-meeting/next-meeting.component';
import {NextMeetingForm, IndividualNextMeetingForm} from '../next-meeting/next-meeting.interface';
import {PdfProtocolSignatureForm} from '../pdf-protocol-signatures/pdf-protocol-signatures.interface';
import {StaffDataService} from 'src/app/services/data/staff-data.service';
import {ReportMonthGroup, ReportWeekGroup} from 'src/app/model/report-group';

@Component({
  selector: 'app-pdf-advanced-settings',
  templateUrl: './pdf-advanced-settings.component.html',
  styleUrls: ['./pdf-advanced-settings.component.scss'],
})
export class PdfAdvancedSettingsComponent implements OnInit {

  readonly WorkflowTypeEnum = WorkflowType;

  @Input()
  showOnlyTemplateOptions = false;
  @Input()
  workflowType: WorkflowType;
  @Input()
  form: UntypedFormGroup;

  @Input()
  protocol?: Protocol;
  @Input()
  report?: Report;

  @Input()
  participants: Participant[] = [];

  @Input()
  nextMeeting?: NextMeetingForm;
  @Output()
  nextMeetingChange = new EventEmitter<NextMeetingForm>();

  @Input()
  individualNextMeetings: IndividualNextMeetingForm[] = [];
  @Output()
  individualNextMeetingsChange = new EventEmitter<IndividualNextMeetingForm[]>();

  @Input()
  showSignatures?: boolean;
  @Output()
  showSignaturesChange = new EventEmitter<boolean>();
  @Input()
  showReportSignatures?: boolean;
  @Input()
  showStaffTimeBlocks?: boolean;
  @Input()
  appendActivityAttachments?: boolean;
  @Input()
  appendOtherAttachments?: boolean;
  @Input()
  appendMaterialAttachments?: boolean;
  @Input()
  appendEquipmentAttachments?: boolean;
  @Input()
  reportGroup?: ReportWeekGroup | ReportMonthGroup;
  @Output()
  showReportSignaturesChange = new EventEmitter<boolean>();
  @Output()
  showStaffTimeBlocksChange = new EventEmitter<boolean>();
  @Output()
  appendActivityAttachmentsChange = new EventEmitter<boolean>();
  @Output()
  appendOtherAttachmentsChange = new EventEmitter<boolean>();
  @Output()
  appendMaterialAttachmentsChange = new EventEmitter<boolean>();
  @Output()
  appendEquipmentAttachmentsChange = new EventEmitter<boolean>();

  @Input()
  pdfProtocolSignatures: PdfProtocolSignatureForm[] = [];
  @Output()
  pdfProtocolSignaturesChange = new EventEmitter<PdfProtocolSignatureForm[]>();

  @ViewChild(NextMeetingComponent, {
    static: false
  }) nextMeetingComponent: NextMeetingComponent;

  public readonly isSignatureEnabled$ = this.featureEnabledService.isFeatureEnabled$(false, true, [LicenseType.VIEWER, LicenseType.LIGHT]).pipe(
    switchMap((isEnabled) => isEnabled ? of(true) : this.userService.currentUser$.pipe(
      map((user) => user.assignedReportRights)
    ))
  );

  isUsingTimeBlocks$: Observable<boolean> | undefined;

  constructor(private featureEnabledService: FeatureEnabledService, private userService: UserService, private staffDataService: StaffDataService) { }

  ngOnInit(): void {
    if (this.reportGroup) {
      this.isUsingTimeBlocks$ = this.staffDataService.getByReportIds(this.reportGroup.reports.map(report => report.id)).pipe(map(staffs => staffs.some(staff => staff?.useTimeBlocks)));
    } else if (this.report) {
      this.isUsingTimeBlocks$ = this.staffDataService.getByReportId(this.report.id).pipe(map(staffs => staffs.some(staff => staff?.useTimeBlocks)));
    }
  }

  toggleIndividualProtocol() {
    const individualProtocol = this.form.get('individualProtocol').value;
    this.form.get('individualProtocol').setValue(!individualProtocol, { emitEvent: false });
    setTimeout(() => this.form.get('individualProtocol').markAsDirty());
  }
}
