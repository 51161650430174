import {Injectable} from '@angular/core';
import {AttachmentBimMarkerScreenshot, generateFilePathsForAttachmentBimMarkerScreenshot, IdType, User} from 'submodules/baumaster-v2-common';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {ProjectDataService} from './project-data.service';
import {LoggingService} from '../common/logging.service';
import {ATTACHMENT_DEFAULT_SORT_COLUMNS, ATTACHMENT_DEFAULT_SORT_ORDER, StorageKeyEnum} from '../../shared/constants';
import {AbstractProjectAwareAttachmentDataService} from './abstract-project-aware-attachment-data.service';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {AttachmentSettingService} from '../attachment/attachmentSetting.service';
import {SystemEventService} from '../event/system-event.service';
import {DevModeService} from '../common/dev-mode.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import _ from 'lodash';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';
import {VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';


const REST_ENDPOINT_URI = 'api/data/attachmentBimMarkerScreenshots';

@Injectable({
  providedIn: 'root'
})
export class AttachmentBimMarkerScreenshotDataService extends AbstractProjectAwareAttachmentDataService<AttachmentBimMarkerScreenshot> {

  readonly dataByBimMarkerId$: Observable<Record<IdType, AttachmentBimMarkerScreenshot[]>> = this.data.pipe(
    map((markerScreenshots) => _.groupBy(markerScreenshots ?? [], 'bimMarkerId'))
  );

  readonly dataByBimMarkerIdAcrossProjects$: Observable<Record<IdType, AttachmentBimMarkerScreenshot[]>> = this.dataAcrossProjects$.pipe(
    map((markerScrennshots) => _.groupBy(markerScrennshots ?? [], 'bimMarkerId'))
  );

  constructor(http: HttpClient, storage: StorageService, authenticationService: AuthenticationService, userService: UserService,
              protected projectDataService: ProjectDataService, loggingService: LoggingService, systemEventService: SystemEventService,
              protected devModeService: DevModeService, projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
              integrityResolverService: IntegrityResolverService, protected translateService: TranslateService,
              protected attachmentSettingService: AttachmentSettingService,) {
    super(StorageKeyEnum.ATTACHMENT_BIM_MARKER_SCREENSHOT, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, projectDataService, loggingService, systemEventService,
          devModeService, integrityResolverService, translateService, attachmentSettingService, projectAvailabilityExpirationService, VERSION_INTRODUCED_DEFAULT,
          ATTACHMENT_DEFAULT_SORT_COLUMNS, ATTACHMENT_DEFAULT_SORT_ORDER);
  }

  protected setGeneratedFilePath(attachment: AttachmentBimMarkerScreenshot) {
    attachment.filePath = generateFilePathsForAttachmentBimMarkerScreenshot(attachment).filePath;
  }

  getByBimMarkerId$(bimMarkerId: IdType, acrossProjects = false) {
    const data$ = acrossProjects ? this.dataByBimMarkerId$ : this.dataByBimMarkerIdAcrossProjects$;
    return data$.pipe(map((byBimId) => byBimId[bimMarkerId] ?? []));
  }

  getByBimMarkerIds$(bimMarkerIds: IdType[], acrossProjects = false) {
    const data$ = acrossProjects ? this.dataByBimMarkerId$ : this.dataByBimMarkerIdAcrossProjects$;
    return data$.pipe(map((byMarkerId) => _.flatten(bimMarkerIds.map((markerId) => byMarkerId[markerId] ?? []))));
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }
}
