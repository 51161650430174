<form class="omg-form" [formGroup]="form">
  <ion-grid class="grid-gap-4">
    <ion-row>
      <ion-col size="12">
        <ion-item>
          <ion-input label="{{'textTemplate.edit.labels.name' | translate}}" label-placement="floating" formControlName="name" class="omg-required"></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <app-rich-text-editor [required]="true" [label]="'textTemplate.edit.labels.textTemplate' | translate" formControlName="textTemplate"></app-rich-text-editor>
      </ion-col>
    </ion-row>
  </ion-grid>
</form>
