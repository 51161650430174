import {ColumnConfig} from '../ColumnConfig';
import {PdfPlanVersionColumnSet} from './PdfPlanVersionColumnSet';

export const PdfPlanColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'folder_id', prop: 'folderId'},
  {name: 'active'},
  {name: 'created_by_id', prop: 'createdById', def: null},
  {name: 'created_at', prop: 'createdAt', cast: 'Timestamp'},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'pdfPlanVersions', fk: 'pdfPlanId', mappedBy: PdfPlanVersionColumnSet, transient: true}
];
