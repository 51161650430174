import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../auth/authentication.service';
import { AbstractProjectAwareDataService } from './abstract-project-aware-data.service';
import { IdType, LicenseType, Staff, User } from 'submodules/baumaster-v2-common';
import { LoggingService } from '../common/logging.service';
import { StorageKeyEnum } from '../../shared/constants';
import { UserService } from '../user/user.service';
import { StorageService } from '../storage.service';
import { IntegrityResolverService } from '../integrity/integrity-resolver.service';
import { ProjectDataService } from './project-data.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';
import {VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';

const REST_ENDPOINT_URI = 'api/data/staffs/';

@Injectable({
  providedIn: 'root'
})
export class StaffDataService extends AbstractProjectAwareDataService<Staff> {
  constructor(http: HttpClient, storage: StorageService, authenticationService: AuthenticationService, userService: UserService, protected projectDataService: ProjectDataService,
              protected projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
              loggingService: LoggingService, integrityResolverService: IntegrityResolverService) {
      super(StorageKeyEnum.STAFF, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, projectDataService, loggingService, projectAvailabilityExpirationService,
        integrityResolverService, VERSION_INTRODUCED_DEFAULT, [(staff) => staff.staffingTypeId, 'id']);
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return currentUser.role === LicenseType.BASIC || currentUser.role === LicenseType.PROFESSIONAL || currentUser.assignedReportRights;
  }

  getByReportId(reportId: IdType): Observable<Staff[]> {
    return this.data.pipe(
      map((staffs) => staffs.filter((staff) => staff.reportId === reportId))
    );
  }

  getByReportIds(reportIds: IdType[]): Observable<Staff[]> {
    return this.data.pipe(
      map((staffs) => staffs.filter((staff) => reportIds.includes(staff.reportId)))
    );
  }

  getActiveByReportId(reportId: IdType): Observable<Staff[]> {
    return this.data.pipe(
      map((staffs) => staffs.filter((staff) => staff.reportId === reportId && !staff.isDeleted))
    );
  }
}
