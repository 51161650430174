import { ColumnConfig } from '../ColumnConfig';

const NotificationConfigRecipientUnique = ['notification_config_id', 'notification_recipient_type', 'profile_id'];

export const NotificationConfigRecipientColumnSet: Array<ColumnConfig> = [
  { name: 'id', prop: 'id', cnd: true },
  { name: 'notification_recipient_type', prop: 'notificationRecipientType', uniqueWith: [NotificationConfigRecipientUnique] },
  { name: 'notification_config_id', prop: 'notificationConfigId', uniqueWith: [NotificationConfigRecipientUnique] },
  { name: 'profile_id', prop: 'profileId', uniqueWith: [NotificationConfigRecipientUnique] },
  { name: 'created_at', prop: 'createdAt', cast: 'Timestamp' },
  { name: 'changed_at', prop: 'changedAt', cast: 'Timestamp' }
];
