import {ColumnConfig} from '../ColumnConfig';
import {skipColumn} from './skipColumn';

const UniqueCompaniesInProjectUniq = ['project_id', 'calendar_id'];

export const ProjectClientCalendarColumnSet: Array<ColumnConfig> = [
  {name: 'id', cnd: true, skip: skipColumn},
  {name: 'project_id', prop: 'projectId', compositeKey: true, uniqueWith: [UniqueCompaniesInProjectUniq]},
  {name: 'calendar_id', prop: 'calendarId', compositeKey: true, uniqueWith: [UniqueCompaniesInProjectUniq]},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'}
];
