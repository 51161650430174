import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {Address, Company, Profile} from 'submodules/baumaster-v2-common';
import {AddressDataService} from '../data/address-data.service';
import {CompanyDataService} from '../data/company-data.service';
import {ProfileDataService} from '../data/profile-data.service';
import {UserService} from './user.service';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {

  currentUserProfile$: Observable<Profile | null> = this.userService.currentUser$.pipe(
    switchMap((user) => {
      if (!user) {
        return of(null);
      }

      return this.userService.isCurrentUserConnected$.pipe(
        switchMap((isCurrentUserConnected) =>
          isCurrentUserConnected ?
          this.profileDataService.getByAttachedUserId(user.id) :
          this.profileDataService.getById(user.profileId)
        ),
        map((profile) => profile ?? null)
      );
    })
  );

  currentUserOwnProfile$: Observable<Profile | null> = this.userService.currentUser$.pipe(
    switchMap((user) => {
      if (!user) {
        return of(null);
      }

      return this.profileDataService.getByIdForOwnClient(user.profileId);
    })
  );

  currentUserProfilesAcrossClients$: Observable<Profile[]|null> = this.userService.currentUser$.pipe(
    switchMap((user) => {
      if (!user) {
        return of(null);
      }

      return this.profileDataService.getAllByUserAcrossClients(user);
    })
  );

  currentUserOwnAddress$: Observable<Address | null> = this.currentUserOwnProfile$.pipe(
    switchMap((profile) => {
      if (!profile) {
        return of(null);
      }

      return this.addressDataService.dataForOwnClient$.pipe(
        map((addresses) => profile ? addresses?.find((address) => address.id === profile.addressId) : null)
      );
    })
  );

  currentUserOwnCompany$: Observable<Company | null> = this.currentUserOwnProfile$.pipe(
    switchMap((profile) => {
      if (!profile) {
        return of(null);
      }

      return this.companyDataService.dataForOwnClient$.pipe(
        map((companies) => profile ? companies?.find((company) => company.id === profile.companyId) : null)
      );
    })
  );

  constructor(
    private userService: UserService,
    private profileDataService: ProfileDataService,
    private addressDataService: AddressDataService,
    private companyDataService: CompanyDataService
  ) {}
}
