import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {ActionSheetController, AlertController, IonContent, IonToggle, ModalController, Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {IonicSelectableComponent} from 'ionic-selectable';
import {Observable, Subscription} from 'rxjs';
import {LoggingService} from 'src/app/services/common/logging.service';
import {ProjectDataService} from 'src/app/services/data/project-data.service';
import {ProtocolTypeDataService} from 'src/app/services/data/protocol-type-data.service';
import {SystemEventService} from 'src/app/services/event/system-event.service';
import {ProtocolSortingService} from 'src/app/services/protocol/protocol-sorting.service';
import {WIDTH_LIMIT_SPLIT_SCREEN} from 'src/app/shared/constants';
import {IdType, Protocol, ProtocolType} from 'submodules/baumaster-v2-common';
import {v4} from 'uuid';
import {ProtocolCreateComponent} from '../protocol-create/protocol-create.component';
import {Nullish} from 'src/app/model/nullish';
import {getProtocolEntryPagePath} from 'src/app/utils/router-utils';
import {ProtocolFilterService} from 'src/app/services/protocol/protocol-filter.service';
import {trackById} from 'src/app/utils/track-by-id';
import {ProtocolListService} from 'src/app/services/protocol/protocol-list.service';
import {combineLatestAsync} from 'src/app/utils/async-utils';
import {filter, skip, take} from 'rxjs/operators';
import {SelectedProtocolService} from 'src/app/services/protocol/selected-protocol.service';
import {RxVirtualScrollViewportComponent} from '@rx-angular/template/experimental/virtual-scrolling';
import {convertErrorToMessage} from '../../../shared/errors';

const LOG_SOURCE = 'ProtocolList';

@Component({
  selector: 'app-protocol-list',
  templateUrl: './protocol-list.component.html',
  styleUrls: ['./protocol-list.component.scss'],
})
export class ProtocolListComponent implements OnInit, OnDestroy {
  @ViewChild('content', {static: false}) content: IonContent;
  @ViewChild('ionToggleClosed', {static: false}) ionToggleClosed: IonToggle;
  @ViewChild(RxVirtualScrollViewportComponent, {
    static: false
  })
  viewport: RxVirtualScrollViewportComponent;

  readonly instanceId = v4();

  readonly trackById = trackById;
  readonly singleItemHeight = 127;

  public protocolTypeSelector: IonicSelectableComponent;
  public filteredProtocols: Array<Protocol> | null;
  private protocolAndProtocolTypeSubscription: Subscription | undefined;
  private currentProjectSubscription: Subscription | undefined;
  private protocolTypeSubscription: Subscription | undefined;
  private backButtonSubscription: Subscription | undefined;
  private currentProtocolSubscription: Subscription | undefined;
  private currentProtocolViewSubscription: Subscription | undefined;
  public protocolTypeData: Observable<Map<string, ProtocolType> | null>;
  public protocolTypeFilterObservable = this.protocolFilterService.selectedProtocolType$;
  public closedProtocolCount: number|undefined;

  get protocolTypeFilter(): ProtocolType | null {
    return this.protocolFilterService.selectedProtocolType;
  }

  set protocolTypeFilter(protocolType) {
    this.protocolFilterService.selectedProtocolType = protocolType;
  }

  selectedProtocol?: Nullish<Protocol>;

  get protocolsBase() {
    return this.router.url.includes('protocols-search') ? '/protocols-search' : '/protocols';
  }

  constructor(private actionSheetController: ActionSheetController,
              private alertController: AlertController,
              public modalController: ModalController,
              private router: Router,
              private platform: Platform,
              private projectDataService: ProjectDataService,
              private protocolTypeDataService: ProtocolTypeDataService,
              private protocolSortingService: ProtocolSortingService,
              private systemEventService: SystemEventService,
              private loggingService: LoggingService,
              private translateService: TranslateService,
              private protocolFilterService: ProtocolFilterService,
              private protocolListService: ProtocolListService,
              private selectedProtocolService: SelectedProtocolService) {
  }

  async ngOnInit() {
    this.currentProtocolSubscription = this.selectedProtocolService.getActiveProtocol().subscribe(
      (activeProtocol) => {
        const { protocol, suppressAutoScroll } = activeProtocol ?? {};
        const oldSelectedProtocolId = this.selectedProtocol?.id;
        this.selectedProtocol = protocol ?? null;
        if (protocol?.id && protocol?.id !== oldSelectedProtocolId && !suppressAutoScroll) {
          this.scrollToProtocol(protocol.id, false);
        }
      }
    );
    this.loggingService.debug(LOG_SOURCE, 'ngOnInit called.');
    try {
      this.currentProjectSubscription = this.projectDataService.currentProjectObservable.pipe(skip(1)).subscribe((project) => {
        this.clearProtocolTypeFilter();
      });

      this.protocolTypeSubscription = this.protocolTypeDataService.dataWithoutHidden$.subscribe((protocolTypes) => {
        this.loggingService.debug(LOG_SOURCE, `protocolTypeData changed - size = ${protocolTypes.length}`);
        const currentProtocolTypeFilter = this.protocolTypeFilter;
        if (currentProtocolTypeFilter && !protocolTypes.find((protocolType) => protocolType.id === currentProtocolTypeFilter.id)) {
          this.loggingService.debug(LOG_SOURCE, `currentProtocolTypeFilter "${currentProtocolTypeFilter.name}" (${currentProtocolTypeFilter.id}) is not in the list of active filters anymore.`);
          this.clearProtocolTypeFilter();
        }
      });

      this.protocolAndProtocolTypeSubscription = this.protocolListService.protocolsWithClosedCount$
        .subscribe(async ({protocols, closedCount}) => {
          this.loggingService.debug(LOG_SOURCE, 'combineLatest - Protocol,  ProtocolType or protocolTypeFilter has changed.');
          this.filteredProtocols = protocols;
          this.closedProtocolCount = closedCount;
        });
    } catch (error) {
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - ngOnInit', error?.userMessage + '-' + error?.message);
    }
  }

  scrollOnEnter() {
    this.currentProtocolViewSubscription?.unsubscribe();
    this.currentProtocolViewSubscription = combineLatestAsync([
      this.selectedProtocolService.getCurrentProtocol(),
      this.protocolListService.protocolsWithClosedCount$
    ]).pipe(
      filter(([protocol, {protocols}]) => protocol?.id && protocols.length > 0),
      take(1)
    ).subscribe(
      ([protocol]) => {
        this.scrollToProtocol(protocol?.id);
      }
    );
  }

  private async scrollToProtocol(id: IdType, checkRange = true) {
    try {
      const protocolIndex = this.filteredProtocols?.findIndex((protocol) => protocol.id === id) ?? -1;
      if (protocolIndex === -1) {
        return;
      }

      this.viewport?.scrollToIndex(protocolIndex);
    } catch (error) {
      this.loggingService.warn(LOG_SOURCE, `scrollToProtocol failed. ${convertErrorToMessage(error)}`);
    }
  }

  private showProtocolsClosed(showClosedProtocols: boolean) {
    this.protocolFilterService.showClosedProtocols = showClosedProtocols;
    if (this.ionToggleClosed) {
      this.ionToggleClosed.checked = showClosedProtocols;
    }
  }

  private clearProtocolTypeFilter() {
    if (this.protocolTypeFilter !== null) {
      this.protocolFilterService.selectedProtocolType = null;
      this.protocolTypeSelector?.clear();
    }
  }

  ngOnDestroy(): void {
    this.protocolAndProtocolTypeUnsubscribe();
    this.unsubscribeCurrentProjectSubscription();
    this.unsubscribeProtocolTypeSubscription();
  }

  ionViewWillEnter() {
    this.loggingService.debug(LOG_SOURCE, 'ionViewWillEnter called.');
  }

  ionViewDidEnter() {
    this.scrollOnEnter();
    this.backButtonUnsubscribe();
    this.backButtonSubscription = this.platform.backButton.subscribeWithPriority(1, () => {
      this.loggingService.debug(LOG_SOURCE, 'skip the back button');
    });
    this.loggingService.debug(LOG_SOURCE, 'ionViewDidEnter called.');
  }

  ionViewWillLeave() {
    this.loggingService.debug(LOG_SOURCE, 'ionViewWillLeave called.');
  }

  ionViewDidLeave() {
    this.backButtonUnsubscribe();
    this.loggingService.debug(LOG_SOURCE, 'ionViewDidLeave called.');
  }

  async showNotImplementedDialog(actionName: string) {
    const alert = await this.alertController.create({
      message: `${this.translateService.instant('underConstruction')}`,
      buttons: ['OK']
    });

    await alert.present();
  }

  actionSheetEvent(protocol: Protocol, event?: any) {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
    this.showActionSheet(protocol);
  }

  async showActionSheet(protocol: Protocol) {
    const actionSheet = await this.actionSheetController.create({
      header: `Protokoll ${protocol.name}`,
      buttons: [
        {
          text: 'Abschließen',
          icon: 'checkmark',
          handler: () => {
            this.showNotImplementedDialog('Abschließen');
          }
        },
        {
          text: 'Senden',
          icon: 'send',
          handler: () => {
            this.showNotImplementedDialog('Senden');
          }
        },
        {
          text: 'Bearbeiten',
          icon: 'create',
          handler: () => {
            this.showNotImplementedDialog('Bearbeiten');
          }
        },
        {
          text: 'Teilen',
          icon: 'share-social',
          handler: () => {
            this.showNotImplementedDialog('Teilen');
          }
        },
      ]
    });
    await actionSheet.present();
  }

  async addNewProtocol() {
    const modal = await this.modalController.create({
      component: ProtocolCreateComponent,
      keyboardClose: false,
      cssClass: 'protocol-create',
      componentProps: {
        protocolType: this.protocolTypeFilter
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    await this.gotToProtocolEntryList(data?.id);
  }

  async toggleClosed(event) {
    this.showProtocolsClosed(event.detail?.checked);
  }

  async gotToProtocolEntryList(protocolId: IdType|undefined) {
    if (typeof(protocolId) !== 'undefined') {
      await this.router.navigate(getProtocolEntryPagePath(protocolId, 'first'));
    }
  }

  isScreenLg(): boolean {
    return this.platform.width() >= WIDTH_LIMIT_SPLIT_SCREEN;
  }

  unsubscribeCurrentProjectSubscription() {
    this.loggingService.debug(LOG_SOURCE, 'unsubscribeCurrentProjectSubscription called.');
    if (this.currentProjectSubscription) {
      this.currentProjectSubscription.unsubscribe();
      this.currentProjectSubscription = undefined;
    }
  }

  unsubscribeProtocolTypeSubscription() {
    this.loggingService.debug(LOG_SOURCE, 'unsubscribeProtocolTypeSubscription called.');
    if (this.protocolTypeSubscription) {
      this.protocolTypeSubscription.unsubscribe();
      this.protocolTypeSubscription = undefined;
    }
  }

  unsubscribeCurrentProtocolEntrySubscription() {
    this.loggingService.debug(LOG_SOURCE, 'unsubscribeCurrentProtocolEntrySubscription called.');
    this.currentProtocolViewSubscription?.unsubscribe();
    if (this.currentProtocolSubscription) {
      this.currentProtocolSubscription.unsubscribe();
      this.currentProtocolSubscription = undefined;
    }
  }

  protocolAndProtocolTypeUnsubscribe() {
    this.loggingService.debug(LOG_SOURCE, 'protocolAndProtocolTypeUnsubscribe called.');
    if (this.protocolAndProtocolTypeSubscription) {
      this.loggingService.debug(LOG_SOURCE, 'protocolAndProtocolTypeSubscription.unsubscribe called.');
      this.protocolAndProtocolTypeSubscription.unsubscribe();
      this.protocolAndProtocolTypeSubscription = undefined;
    }
  }

  backButtonUnsubscribe() {
    if (this.backButtonSubscription) {
      this.backButtonSubscription.unsubscribe();
      this.backButtonSubscription = undefined;
    }
  }

  onProtocolTypeChange(protocolType: ProtocolType|null) {
    this.loggingService.debug(LOG_SOURCE, `onProtocolTypeChange - protocolType=${protocolType?.name}, protocolType.id = ${protocolType?.id}`);
    this.protocolFilterService.selectedProtocolType = protocolType;
  }

  async search() {
    const alert = await this.alertController.create({
      message: `${this.translateService.instant('underConstruction')}`,
      buttons: ['OK']
    });
    await alert.present();
  }

  async toggleSortOrder() {
    await this.protocolSortingService.toggleSortOrder();
  }
}
