<div class="d-flex flex-column centered ion-align-items-center ion-justify-content-center gap-4 p-4">
  <img class="brian-download" src="/assets/images/brian_download.png" />
  <div class="ion-text-center">
    <div class="omg-t-header-2-bold">{{'appData.initialSync.header' | translate}}</div>
    <div class="omg-t-body omg-f-italic text-secondary white-space-pre-line">
      {{'appData.initialSync.description' | translate}}
    </div>
  </div>
  <fa-icon *ngIf="(dataSyncInProgress$ | async)?.inProgress" class="text-secondary" size="2x" [icon]="['far', 'spinner']" animation="spin"></fa-icon>
  <ion-button *ngIf="(schedulerStartedPromise | async) && !(dataSyncInProgress$ | async)?.inProgress" class="omg-btn-primary" (click)="startSync()">
    {{'projectLoader.tryAgain' | translate}}
  </ion-button>
  <ion-button class="omg-btn-secondary omg-btn-color-danger" (click)="logout.emit()">
    {{'MENU.logout' | translate}}
  </ion-button>
</div>