import {ColumnConfig} from '../ColumnConfig';

const UniqueTokenPermission = ['app_token_id', 'permission_key'];

export const AppTokenPermissionColumnSet: Array<ColumnConfig> = [
  {name: 'id', cnd: true},
  {name: 'app_token_id', prop: 'appTokenId', cnd: true, uniqueWith: [UniqueTokenPermission]},
  {name: 'permission_key', prop: 'permissionKey', cnd: true, uniqueWith: [UniqueTokenPermission]},
  {name: 'permission'},
  {name: 'created_at', prop: 'createdAt', cast: 'Timestamp'},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
];
