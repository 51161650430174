import {Injectable} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {BimViewerComponent} from 'src/app/components/autodesk/bim-viewer-component/bim-viewer.component';
import {BimViewerComponentParam} from '../../model/bim-plan-with-deletable';

@Injectable({
  providedIn: 'root'
})
export class BimViewerModalService {

  constructor(private modalController: ModalController) {}

  async createModal(componentProps: BimViewerComponentParam): Promise<HTMLIonModalElement> {
    const modal = await this.modalController.create({
      component: BimViewerComponent,
      cssClass: 'full-screen-modal-xxl',
      componentProps,
    });
    return modal;
  }

  async openModal(componentProps: BimViewerComponentParam): Promise<HTMLIonModalElement> {
    const modal = await this.createModal(componentProps);
    await modal.present();

    return modal;
  }
}
