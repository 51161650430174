<app-modal-header>
  <ion-title class="ion-text-center">
    {{'offlineInfo.title' | translate}}
  </ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <app-offline-info [showHint]="showHint" [showTitle]="true" [showRetry]="false"></app-offline-info>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-secondary" (click)="close()">{{'close' | translate}}</ion-button>
    <ion-button *ngIf="showRetry" class="omg-btn-primary" (click)="retry()">{{'offlineInfo.retryLabel' | translate}}</ion-button>
  </ion-buttons>
</app-modal-footer>