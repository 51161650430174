
<ng-container [formGroup]="parent.control">
  <ion-row>
    <ion-col size="12">
      <div class="omg-modal-header">
        {{ "companyForm.address_divider" | translate }}
      </div>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="6">
      <ion-item [disabled]="readonly">
        <ion-input maxlength="60" label="{{ 'companyForm.address.street1' | translate }}" label-placement="floating" [readonly]="readonly" formControlName="street1" type="text"></ion-input>
      </ion-item>
    </ion-col>
    <ion-col size="6">
      <ion-item [disabled]="readonly">
        <ion-input maxlength="60" label="{{ 'companyForm.address.street2' | translate }}" label-placement="floating" [readonly]="readonly" formControlName="street2" type="text"></ion-input>
      </ion-item>
    </ion-col>
    <ion-col size="6">
      <ion-item [disabled]="readonly">
        <ion-input maxlength="10" label="{{ 'companyForm.address.zipCode' | translate }}" label-placement="floating" [readonly]="readonly" formControlName="zipCode" type="text"></ion-input>
      </ion-item>
    </ion-col>
    <ion-col size="6">
      <ion-item [disabled]="readonly">
        <ion-input maxlength="60" label="{{ 'companyForm.address.city' | translate }}" label-placement="floating" [readonly]="readonly" formControlName="city" type="text"></ion-input>
      </ion-item>
    </ion-col>
    <ion-col>
      <ion-item [disabled]="readonly">
        <ion-label position="floating" class="text-primary">{{ 'clientDataSettings.country' | translate }}</ion-label>
        <ionic-selectable #countrySelectable
          item-content
          class="text-primary"
          [items]="countries$ | async"
          formControlName="country"
          searchPlaceholder="{{'search' | translate}}"
          searchFailText="{{'noItemsFound' | translate}}"
          [canSearch]="true"
          (onOpen)="onOpen($event)"
          (onClose)="onClose($event)"
          appSelectableCommon
          #fs="appSelectableCommon"
          [shouldFocusSearchbar]="fs.isDesktop">
          <ng-template ionicSelectableItemIconTemplate let-selected="isItemSelected">
            <app-selectable-item-icon [selected]="selected"></app-selectable-item-icon>
          </ng-template>
          <ng-template ionicSelectableValueTemplate let-value="value">
            {{value ?? parent.control?.value?.country ?? ''}}
          </ng-template>
          <ng-template ionicSelectableHeaderTemplate>
            <app-selectable-header></app-selectable-header>
          </ng-template>
          <ng-template ionicSelectableFooterTemplate>
            <app-selectable-footer></app-selectable-footer>
          </ng-template>
        </ionic-selectable>
      </ion-item>
    </ion-col>
  </ion-row>
</ng-container>