import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {AlertController, IonInput, Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import _ from 'lodash';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {LoggingService} from 'src/app/services/common/logging.service';
import {ToastService} from 'src/app/services/common/toast.service';
import {CraftDataService} from 'src/app/services/data/craft-data.service';
import {ProjectCraftDataService} from 'src/app/services/data/project-craft-data.service';
import {ProjectDataService} from 'src/app/services/data/project-data.service';
import {FeatureEnabledService} from 'src/app/services/feature/feature-enabled.service';
import {convertErrorToMessage} from 'src/app/shared/errors';
import {observableToPromise} from 'src/app/utils/async-utils';
import {Craft, DEFAULT_CRAFT_COLOR, IdType, ProjectCraft} from 'submodules/baumaster-v2-common';
import {v4 as uuid4} from 'uuid';
import {ClientService} from '../../../services/client/client.service';
import {SystemEventService} from '../../../services/event/system-event.service';

const LOG_SOURCE = 'CraftComponent';

@Component({
  selector: 'app-craft',
  templateUrl: './craft.component.html',
  styleUrls: ['./craft.component.scss'],
})
export class CraftComponent implements OnInit, OnDestroy, AfterViewInit {

  readonly DEFAULT_CRAFT_COLOR = DEFAULT_CRAFT_COLOR;

  @Input() craftId: IdType | null = null;
  @Input() projectId: IdType | undefined;
  @Input() prePopulateMainFieldWithText?: string;
  @Input() createForSelectable = false;
  @ViewChild('inputName', {static: false}) inputName: IonInput;

  public craftForm: UntypedFormGroup = this.formBuilder.group({
    name: ['', [Validators.required, this.uniqueName()]],
    color: [DEFAULT_CRAFT_COLOR],
  });
  public addToProject = true;
  public loading = false;

  private destroy$ = new Subject<void>();
  private crafts: Craft[] | undefined;
  public craft: Craft | undefined;
  public projectCraft: ProjectCraft | undefined;
  private modal: HTMLIonModalElement;

  notConnected$ = this.featureEnabledService.isFeatureEnabled$(false, true, null);

  constructor(private formBuilder: UntypedFormBuilder,
              private translateService: TranslateService,
              private alertCtrl: AlertController,
              private systemEventService: SystemEventService,
              private loggingService: LoggingService,
              private toastService: ToastService,
              private projectDataService: ProjectDataService,
              private clientService: ClientService,
              private craftDataService: CraftDataService,
              private projectCraftDataService: ProjectCraftDataService,
              private featureEnabledService: FeatureEnabledService,
              private platform: Platform) {
  }

  private uniqueName(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formName: string | null = control.value;
      if (_.isEmpty(formName) || this.craft?.name === formName) {
        return null;
      }

      const nameExisting = _.filter(this.crafts, craft => craft.name === formName && (craft.isActive || craft.isActive === undefined));
      if (!_.isEmpty(nameExisting)) {
        return {nameExist: true};
      }

      if (this.craftId){ // edit mode
        const recordDeletedExist = _.filter(this.crafts, craft => craft.name === formName && craft.isActive === false);
        if (!_.isEmpty(recordDeletedExist)) {
          return {nameExistAndInactive: true};
        }
      }
      return null;
    };
  }

  ngAfterViewInit() {
    setTimeout(async () => {
      await this.inputName.setFocus();
    }, 500);
  }

  async ngOnInit() {
    this.craftDataService.dataForOwnClient$
      .pipe(takeUntil(this.destroy$))
      .subscribe(crafts => {
        this.crafts = crafts;
      });

    if (this.craftId) {
      this.patchCraft();
    } else if (this.prePopulateMainFieldWithText) {
      this.craftForm.controls.name.setValue(this.prePopulateMainFieldWithText);
      this.craftForm.controls.name.markAsDirty();
    }
    this.setCanDismiss();
  }

  private canDismiss = async (data?: any, role?: string) => {
    if (role === 'delete') {
      return true;
    }

    if (!this.craftForm?.dirty) {
      return true;
    }

    const alert = await this.alertCtrl.create({
      header: this.translateService.instant('protocolCreation.data_loss_header'),
      message: this.translateService.instant('protocolCreation.data_loss_message'),
      buttons: [
        {
          text: this.translateService.instant('no'),
          role: 'cancel'
        },
        {
          text: this.translateService.instant('yes'),
          role: 'dismiss'
        }
      ]
    });
    await alert.present();

    return (await alert.onWillDismiss()).role === 'dismiss';
  };

  private setCanDismiss() {
    this.modal.canDismiss = this.canDismiss;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private patchCraft() {
    this.craftDataService.getByIdForOwnClient(this.craftId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(craft => {
        this.craftForm.patchValue(craft);
        this.craft = craft;
      });
  }

  async saveCraft() {
    try {
      this.loading = true;
      const rawData = this.craftForm.getRawValue();
      const craftData = {
        name: rawData.name,
        color: rawData.color
      };

      if (!this.craftId) {
        let craft: Craft = this.crafts.find((data) => data.name === rawData.name);
        if (craft) {
          await this.undoLogicalDelete(craft);
          return;
        }
        const client = await this.clientService.getOwnClientMandatory();
        craft = {
          id: uuid4(),
          clientId: client.id,
          changedAt: new Date().toISOString(),
          isActive: true,
          ...craftData
        };
        await this.createCraft(craft);
      } else {
        const craft: Craft = {...this.craft, ...craftData};
        await this.updateCraft(craft);
      }

    } catch (error) {
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - save craft ', error?.userMessage + '-' + error?.message);
      this.loggingService.error(LOG_SOURCE, `Error save craft. "${error?.userMessage}" - "${error?.message}"`);
      await this.toastService.errorWithMessageAndHeader('error_saving_message', convertErrorToMessage(error));
      throw error;
    } finally {
      this.loading = false;
    }
  }

  private async createCraft(craft: Craft) {
    await this.craftDataService.insert(craft, craft.clientId);
    await this.addCraftToProject(craft);
    this.craftForm.markAsPristine();
    await this.dismissModal('ok', craft);
  }

  private async addCraftToProject(craft: Craft) {
    if (this.addToProject && await observableToPromise(this.notConnected$)) {
      const projectId = this.projectId ?? (await this.projectDataService.getCurrentProject()).id;
      const projectCraft: ProjectCraft = {
        id: projectId + craft.id,
        projectId,
        craftId: craft.id,
        changedAt: new Date().toISOString(),
      };
      await this.projectCraftDataService.insert(projectCraft, projectId);
    }
  }

  private async updateCraft(craft: Craft) {
    const alert = await this.alertCtrl.create({
      message: this.translateService.instant('craftForm.editMessage'),
      buttons: [
        {
          text: this.translateService.instant('no'),
          role: 'cancel'
        },
        {
          text: this.translateService.instant('yes'),
          handler: async () => {
            await this.performUpdate(craft);
          }
        }
      ]
    });
    await alert.present();
  }

  private async performUpdate(craft: Craft) {
    this.craftForm.markAsPristine();
    await this.dismissModal('ok', craft);
    await this.craftDataService.update(craft, craft.clientId);
  }

  dismissModal(role?: 'ok' | 'cancel', data?: Craft) {
    return this.modal.dismiss(data, role);
  }

  toggleAddToProject() {
    this.addToProject = !this.addToProject;
  }

  async deleteCraft(craft: Craft) {
    const alert = await this.alertCtrl.create({
      message: this.translateService.instant('craftForm.confirmDelete'),
      buttons: [
        {
          text: this.translateService.instant('no'),
          role: 'cancel'
        },
        {
          text: this.translateService.instant('yes'),
          handler: async () => {
            await this.doLogicalDelete(craft);
            await this.toastService.info('craftForm.deleteSuccessful');
            await this.modal.dismiss(undefined, 'delete');
          }
        }
      ]
    });
    await alert.present();
  }

  async doLogicalDelete(craft: Craft) {
    craft.isActive = false;
    await this.craftDataService.update(craft, craft.clientId);
    const allProjectCrafts = await observableToPromise(this.projectCraftDataService.getByCraftAcrossProjects(craft));
    allProjectCrafts.forEach((projectCraft) => this.projectCraftDataService.delete(projectCraft, projectCraft.projectId));
  }

  async undoLogicalDelete(craft: Craft) {
    craft.isActive = true;
    await this.craftDataService.update(craft, craft.clientId);
    await this.addCraftToProject(craft);
    this.craftForm.markAsPristine();
    await this.dismissModal('ok', craft);
  }

  isIosNative(): boolean {
    return this.platform.is('ios') && this.platform.is('capacitor');
  }
}
