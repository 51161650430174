import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {IdAware, IdType} from 'submodules/baumaster-v2-common';

@Injectable()
export abstract class AbstractSelectionService<T extends IdAware> {

  private multiselectModeSubject = new BehaviorSubject<boolean>(false);
  multiselectMode$ = this.multiselectModeSubject.asObservable();

  private selectedSubject = new BehaviorSubject<Set<IdType>>(new Set());
  selectedSet$ = this.selectedSubject.asObservable();
  abstract selected$: Observable<T[]>;

  protected get selected(): Set<IdType> {
    return this.selectedSubject.value;
  }

  get multiselectMode(): boolean {
    return this.multiselectModeSubject.value;
  }

  protected set multiselectMode(mode: boolean) {
    this.multiselectModeSubject.next(mode);
  }

  constructor() {
    this.selectedSet$.subscribe(() => this.autoToggleMultiselectMode());
  }

  protected getSelectedObservable(): Observable<T[]> {
    throw new Error('Not implemented');
  }

  private propagateSubject() {
    this.selectedSubject.next(this.selected);
  }

  private autoToggleMultiselectMode() {
    if (this.anySelected() !== this.multiselectMode) {
      this.multiselectMode = this.anySelected();
    }
  }

  toggleSelected(obj: IdAware) {
    if (this.selected.has(obj.id)) {
      this.selected.delete(obj.id);
    } else {
      this.selected.add(obj.id);
    }
    this.propagateSubject();
  }

  unselectAll() {
    this.selected.clear();
    this.propagateSubject();
  }

  unselect(objects: IdAware[]) {
    objects.forEach(({ id }) => this.selected.delete(id));
    this.propagateSubject();
  }

  select(objects: IdAware[]) {
    this.selected.clear();
    objects.forEach(({ id }) => this.selected.add(id));
    this.propagateSubject();
  }

  enterMultiselectMode() {
    this.multiselectMode = true;
  }

  leaveMultiselectMode() {
    this.unselectAll();
    this.multiselectMode = false;
  }

  anySelected() {
    return this.selected.size > 0;
  }

  selectedCount() {
    return this.selected.size;
  }
}
