<form class="omg-form d-flex flex-column gap-7" [formGroup]="loginForm" (ngSubmit)="login()" novalidate>
  <div class="d-flex flex-column gap-4">
    <div class="omg-attention omg-attention-danger" *ngIf="loginNotSuccessful">{{ loginNotSuccessfulMsg | translate }}</div>

    <div class="d-flex flex-column gap-2">
      <ion-label class="omg-t-body-bold">{{ 'LOGIN.username' | translate }}</ion-label>
      <ion-input [disabled]="!!forcedUsername" type="email" formControlName="username" class="text-primary legacy" required #usernameInput
                 enterkeyhint="next" (keyup.enter)="submitForm()"
                 fill="outline"></ion-input>
    </div>

    <div class="d-flex flex-column gap-2">
      <ion-label class="omg-t-body-bold"> {{ 'LOGIN.password' | translate }}</ion-label>
      <ion-input [type]="isUnMask ? 'text' : 'password'" formControlName="password" class="text-primary legacy" required
        #passwordInput autocomplete="off" autocorrect="off" enterkeyhint="done" clearOnEdit="false"
        (keyup.enter)="submitForm()"
        fill="outline">
        <ion-button (click)="toggleUnMask()" size="large" class="omg-btn-text omg-btn-color-neutral ion-justify-content-end toggle-unmask-button">
          <ion-icon [name]="isUnMask ? 'eye-outline' : 'eye-off-outline'"></ion-icon>
        </ion-button>
      </ion-input>
      <div class="d-flex ion-justify-content-between ion-align-items-center">
        <div>
          <ion-item *ngIf="platform.is('desktop')" lines="none">
           <ion-checkbox class="omg-checkbox" formControlName="staySignedIn" label-placement="end" justify="start">
             {{ 'LOGIN.remember_me' | translate }}
           </ion-checkbox>
          </ion-item>
        </div>
        <div class="ion-text-end">
          <a href="#" (click)="resetPassword($event)" class="omg-link">
            <span class="size-sm">{{ 'LOGIN.forgot_password' | translate }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div>
    <ion-button size="block" class="omg-btn-primary" autofocus="autofocus" type="submit"
      [disabled]="!loginForm.valid || loginInProgress">{{ 'LOGIN.login_btn' | translate
      }}</ion-button>
  </div>
</form>