import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AsyncPipe, NgIf} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {UiModule} from '../../../shared/module/ui/ui.module';
import {EntryCardComponent} from '../entry-card/entry-card.component';
import {LicenseType, ProtocolEntryIconStatus} from 'submodules/baumaster-v2-common';
import {ReactiveFormsModule, UntypedFormBuilder} from '@angular/forms';
import {EntryCardSettingsService} from '../../../services/entry/entry-card-settings.service';
import {Subject} from 'rxjs';
import {ENTRY_CARD_SETTINGS_DEFAULT, EntryCardSettings} from '../../../model/entry-card-model';
import {AlertService} from '../../../services/ui/alert.service';
import {observableToPromise} from '../../../utils/async-utils';
import { PosthogService } from 'src/app/services/posthog/posthog.service';
import {FeatureEnabledService} from '../../../services/feature/feature-enabled.service';

@Component({
  selector: 'app-entry-card-settings',
  templateUrl: './entry-card-settings.component.html',
  styleUrls: ['./entry-card-settings.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    IonicModule,
    TranslateModule,
    UiModule,
    EntryCardComponent,
    ReactiveFormsModule
  ],
})
export class EntryCardSettingsComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();
  private modal: HTMLIonModalElement;
  ProtocolEntryIconStatus = ProtocolEntryIconStatus;
  readonly date = new Date();
  readonly isEditEnabled$ = this.featureEnabledService.isFeatureEnabled$(false, true, [LicenseType.VIEWER, LicenseType.LIGHT]);

  readonly form = this.fb.group({
    showId: [true],
    showTitleText: [true],
    showText: [true],
    showInitials: [true],
    showCraft: [true],
    showType: [true],
    showPriorityDate: [true],
    showAttachmentsSub: [true],
  });
  entryCardSettings: EntryCardSettings = ENTRY_CARD_SETTINGS_DEFAULT;

  @Input()
  useProtocolEntryNumber = false;

  constructor(
    private entryCardSettingsService: EntryCardSettingsService,
    private fb: UntypedFormBuilder,
    private alertService: AlertService,
    private posthogService: PosthogService,
    private featureEnabledService: FeatureEnabledService
  ) { }

  async ngOnInit() {
    this.setCanDismiss();
    this.form.valueChanges.subscribe((values) => {
      this.entryCardSettings = values;
    });
    const entryCardSettings = await observableToPromise(this.entryCardSettingsService.entryCardSettings$);
    this.form.patchValue(entryCardSettings);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async save() {
    if (this.form.dirty && this.form.valid) {
      await this.entryCardSettingsService.updateEntryCardSettings(this.entryCardSettings);
      this.form.markAsPristine();
      await this.modal.dismiss();
      this.posthogService.captureEvent('Saved Task Card Settings', {
        number: this.entryCardSettings.showId,
        craft: this.entryCardSettings.showCraft,
        description: this.entryCardSettings.showText,
        type: this.entryCardSettings.showType,
        initials: this.entryCardSettings.showInitials,
        priorityDate: this.entryCardSettings.showPriorityDate,
        attachmentsSubs: this.entryCardSettings.showAttachmentsSub,
      });
    }
  }

  private canDismiss = async (data?: any, role?: string) => {
    if (!this.form.dirty) {
      return true;
    }

    return await this.alertService.confirm(
      {
        header: 'protocolCreation.data_loss_header',
        message: 'protocolCreation.data_loss_message', confirmLabel: 'yes', cancelLabel: 'no'
      });
  };

  private setCanDismiss() {
    this.modal.canDismiss = this.canDismiss;
  }

  async cancel() {
    await this.modal.dismiss();
  }
}
