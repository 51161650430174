import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ProtocolType } from 'submodules/baumaster-v2-common';

@Injectable({
  providedIn: 'root'
})
export class ProtocolFilterService {

  private protocolTypeSubject = new BehaviorSubject<ProtocolType|null>(null);
  selectedProtocolType$ = this.protocolTypeSubject.asObservable();

  get selectedProtocolType() { return this.protocolTypeSubject.value; }
  set selectedProtocolType(protocolType: ProtocolType) { this.protocolTypeSubject.next(protocolType); }

  private showClosedProtocolsSubject = new BehaviorSubject<boolean>(true);
  showClosedProtocols$ = this.showClosedProtocolsSubject.asObservable();

  get showClosedProtocols() { return this.showClosedProtocolsSubject.value; }
  set showClosedProtocols(showClosedProtocols: boolean) { this.showClosedProtocolsSubject.next(showClosedProtocols); }

  constructor() { }
}
