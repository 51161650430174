import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {TooltipComponent} from 'src/app/components/ui/tooltip/tooltip.component';

@NgModule({
  declarations: [TooltipComponent],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports: [TooltipComponent]
})
export class TooltipModule { }
