<app-modal-header>
  <ion-title> {{ 'protocolCreation.title' | translate }}  </ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <app-protocol-form #appProtocolForm
    [(formDirty)]="protocolFormDirty"
    [(form)]="protocolForm"
    (protocolTypeChange)="protocolTypeChanged($event)"
    [protocolData]="protocolData"
    (navigateAway)="handleNavigateAway($event)"
    ></app-protocol-form>
</ion-content>

<app-modal-footer>
    <ion-buttons slot="end" class="omg-form">
      <ion-button (click)="dismissModal()" class="omg-btn-text">{{ 'cancel' | translate }}</ion-button>
      <ion-button (click)="saveProtocol()" class="omg-btn-primary" [disabled]="!protocolFormDirty || !protocolForm?.valid || loading">
        <ion-spinner slot="start" class="spinner-center-button" *ngIf="loading"></ion-spinner>
        <span>{{'button.create' | translate }}</span></ion-button>
    </ion-buttons>
</app-modal-footer>
