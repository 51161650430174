<app-modal-header>
  <ion-title> {{ 'unitContacts.createTitle' | translate }}  </ion-title>
  <div class="ion-text-center attention-text-container pt-3 pb-3">
    <ion-label class="omg-attention omg-attention-warning omg-t-body">
      {{'unitContacts.createForProject' | translate: {project: currentProject?.name} }}
    </ion-label>
  </div>
</app-modal-header>

<ion-content class="with-padding">
  <app-unit-contact-form [isEditMode]="false" [prefillLastName]="prefillLastName" [hideUnitsSelector]="hideUnitsSelector"></app-unit-contact-form>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="end" class="omg-form">
    <ion-button class="omg-btn-text" (click)="dismissModal()">
      {{ 'cancel' | translate }}
    </ion-button>
    <ion-button class="omg-btn-primary" [disabled]="!unitContactFormComponent?.isValid() || loading" (click)="createNewContact()">
      {{ 'button.create' | translate }}
    </ion-button>
  </ion-buttons>
</app-modal-footer>
