import {Component, OnInit} from '@angular/core';
import {IdType, LicenseType, ProtocolEntryStatus} from 'submodules/baumaster-v2-common';
import {NavParams, PopoverController} from '@ionic/angular';
import {UserService} from 'src/app/services/user/user.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {FeatureEnabledService} from 'src/app/services/feature/feature-enabled.service';
import {UserProfileService} from '../../../services/user/user-profile.service';
import {combineLatestAsync} from '../../../utils/async-utils';

@Component({
  selector: 'app-protocol-entry-status-popover',
  templateUrl: './protocol-entry-status-popover.component.html',
  styleUrls: ['./protocol-entry-status-popover.component.scss'],
})
export class ProtocolEntryStatusPopoverComponent implements OnInit {

  public protocolEntryStatus = ProtocolEntryStatus;
  public isProtocolLayoutShort: boolean;
  public isTaskProtocol: boolean;
  public currentStatus?: ProtocolEntryStatus;
  public assignmentId?: IdType;
  public isOwnClient?: boolean;

  private assignedToCurrentProfileType$: Observable<'OWN' | 'CONNECTED' | 'NONE'> = this.userProfileService.currentUserProfilesAcrossClients$.pipe(
    map((profiles) => {
      const profile = profiles?.find((theProfile) => theProfile.id === this.assignmentId);
      if (!profile) {
        return 'NONE';
      }

      return profile.attachedToUserId ? 'CONNECTED' : 'OWN';
    })
  );

  public connectedAssignment$ = this.assignedToCurrentProfileType$.pipe(map((type) => type === 'CONNECTED'));
  public ownAssignment$ = this.assignedToCurrentProfileType$.pipe(map((type) => type === 'OWN'));
  private isViewer$ = this.featureEnabledService.isFeatureEnabled$(true, null, [LicenseType.VIEWER]);
  private isLight$ = this.featureEnabledService.isFeatureEnabled$(true, null, [LicenseType.LIGHT]);
  public isLikeViewer$ = combineLatestAsync([this.isViewer$, this.isLight$])
    .pipe((map(([isViewer, isLight]) => this.isTaskProtocol ? isViewer || isLight : isViewer)));

  public allowOpenOrCompanyDone$ = combineLatestAsync([
    this.isLikeViewer$,
    this.connectedAssignment$,
    this.ownAssignment$,
  ]).pipe(
    map(([isViewer, connectedAssignment, ownAssignment]) => {
      if (this.isOwnClient) {
        // Protocol is a part of my own client
        if (isViewer) {
          // I'm a viewer, so I can only change status to open or company done when:
          //  1. I'm assigned to this entry AND current entry status IS NOT done, or
          //  2. It is a short layout
          return (ownAssignment && this.currentStatus !== ProtocolEntryStatus.DONE) || this.isProtocolLayoutShort;
        }
        // I'm not a viewer, so I can change the status however I want
        return true;
      }
      if (connectedAssignment) {
        // Protocol is a part of connected client AND I'm assigned to the entry
        // I can only change status to open or company done when:
        //  1. Current status IS NOT done, or
        //  2. It is a short layout
        return this.currentStatus !== ProtocolEntryStatus.DONE || this.isProtocolLayoutShort;
      }
      // Protocol is a part of a connected client AND I am NOT assigned to the entry
      return false;
    })
  );
  public allowDone$ = this.isLikeViewer$.pipe(
    map((isViewer) => {
      // I can set status to done if:
      //   1. Protocol layout is short, OR
      //   2. I am not viewer AND an entry is a part of my own client
      return this.isProtocolLayoutShort || (!isViewer && this.isOwnClient);
    })
  );

  constructor(
    private popoverCtr: PopoverController,
    private navParams: NavParams,
    private userService: UserService,
    private userProfileService: UserProfileService,
    private featureEnabledService: FeatureEnabledService,
  ) {
  }

  async changeStatus(status) {
    await this.popoverCtr.dismiss({
      newStatus: status
    });
  }

  ngOnInit(): void {
    this.isProtocolLayoutShort = this.navParams.data.isProtocolLayoutShort;
    this.isTaskProtocol = this.navParams.data.isTaskProtocol;
  }
}
