import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {filter, map, shareReplay, switchMap} from 'rxjs/operators';
import {ClientType, UpdateUsernameReq, User} from 'submodules/baumaster-v2-common';
import {AuthenticationService} from '../auth/authentication.service';
import {ClientService} from '../client/client.service';
import {UserDataService} from '../data/user-data.service';
import {observableToPromise} from '../../utils/async-utils';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  currentUser$: Observable<User | null> = this.authService.authenticatedUserId$.pipe(
    switchMap((authenticatedUserId) => {
      if (!authenticatedUserId) {
        return of(null);
      }
      return this.userDataService
        .getById(authenticatedUserId)
        .pipe(map((user) => user ?? null));
    }),
    shareReplay(1)
  );

  isCurrentUserConnected$: Observable<boolean> = this.clientService.currentClient$.pipe(
    filter(v => !!v),
    map((client) => client.type === ClientType.CONNECTED)
  );

  isCurrentUserAdmin$: Observable<boolean> = this.currentUser$.pipe(
    map((user) => user?.isClientAdmin === true)
  );

  hasCurrentUserReportRights$: Observable<boolean> = this.currentUser$.pipe(
    map((user) => user?.assignedReportRights)
  );

  constructor(
    private authService: AuthenticationService,
    private userDataService: UserDataService,
    private clientService: ClientService,
    private http: HttpClient
  ) {}

  async updateUsername(user: User, newUsername: string, abortSignal: AbortSignal): Promise<void> {
    const url = this.getUpdateUsernameUrl();
    const payload: UpdateUsernameReq = {username: newUsername};
    await observableToPromise(this.http.post<void>(url, payload));
  }

  private getUpdateUsernameUrl(): string {
    return environment.serverUrl + `auth/updateUsername`;
  }
}
