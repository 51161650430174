import {IconDefinition, IconName, IconPrefix} from '@fortawesome/fontawesome-common-types';

export const bauPointUp: IconDefinition = {
  prefix: 'bau' as IconPrefix,
  iconName: 'point-up' as IconName,
  icon: [
    512,
    512,
    [],
    null,
    // eslint-disable-next-line max-len
    'M31.1 338.9v46.97c0 32.1 17.1 61.65 44.63 77.12l55.83 31.35C153.1 505.9 176.4 512 199.8 512h107.9c77.37 0 140.3-64.6 140.3-144V276c0-39.92-31.19-72.56-70.04-73.95C364.3 184.6 343.3 174 321.2 174h-13.53c-3.557 0-7.102 .2734-10.61 .8145C284.6 165.2 269.4 160 253.5 160h-13.53V72c0-39.7-32.3-72-71.1-72C128.3 0 95.1 32.3 95.1 72v169.7L63.43 270.1C43.46 287.5 31.1 312.2 31.1 338.9zM79.1 338.9c0-12.31 5.281-23.77 14.5-31.39l17.5-15.69l-.0058 44.17C111.1 344.8 119.2 352 127.1 352s16-7.156 16-16L143.1 72c0-13.23 10.78-24 23.1-24c13.22 0 24 10.77 24 24v142c0 9.578 7.941 16.04 16.15 16.04c6.433 0 12.31-4.017 14.73-10.17C223.3 218.8 228.3 208 239.1 208h13.53c20.97 0 17.92 19.58 34.27 19.58c8.211 0 9.847-5.583 19.88-5.583h13.53c25.55 0 18.27 28.23 38.66 28.23c.1566 0 .3126-.0027 .4679-.0081L375.4 250c13.59 0 24.62 11.66 24.62 26V368c0 52.94-41.41 96-92.31 96H199.8c-15.25 0-30.41-3.984-43.87-11.52l-55.78-31.34c-12.44-6.984-20.16-20.5-20.16-35.27V338.9z',
  ]
};
