import {IconDefinition, IconName, IconPrefix} from '@fortawesome/fontawesome-common-types';

export const bauStatusDone: IconDefinition = {
  prefix: 'bau' as IconPrefix,
  iconName: 'status-done' as IconName,
  icon: [
    32,
    32,
    [],
    null,
    // eslint-disable-next-line max-len
    'M16,32A16,16,0,1,1,32,16,16,16,0,0,1,16,32ZM16,2.13A13.87,13.87,0,1,0,29.87,16,13.88,13.88,0,0,0,16,2.13ZM16,4A12,12,0,1,0,28,16,12,12,0,0,0,16,4Zm7.2,9.71-8.17,8a1,1,0,0,1-.7.29,1,1,0,0,1-.7-.29L8.8,17a1,1,0,0,1,0-1.42,1,1,0,0,1,1.41,0l4.13,4,7.47-7.31a1,1,0,0,1,1.4,1.42Z',
  ]
};
