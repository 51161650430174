import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {IdType, Protocol} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import {LastUsedProtocolService} from './last-used-protocol-data.service';
import {UserflowService} from '../userflow/userflow.service';
import {ProtocolDataService} from '../data/protocol-data.service';
import {observableToPromise} from 'src/app/utils/async-utils';
import {ActiveProtocol, ProtocolWithTypeAndLayout} from 'src/app/model/protocol';
import {map, switchMap} from 'rxjs/operators';
import {ProtocolService} from './protocol.service';
import {isTaskProtocol} from 'src/app/utils/protocol-utils';

const LOG_SOURCE = 'SelectedProtocolService';

@Injectable({
  providedIn: 'root'
})
export class SelectedProtocolService {

  private readonly activeProtocol = new BehaviorSubject<ActiveProtocol | null>(null);
  private readonly activeProtocolObservable = this.activeProtocol.asObservable();

  get currentProtocol() {
    return this.activeProtocol.value?.protocol;
  }
  private readonly currentProtocolObservable: Observable<Protocol | null> = this.activeProtocolObservable.pipe(
    map((active) => active?.protocol ?? null)
  );

  constructor(
    private loggingService: LoggingService,
    private userflowService: UserflowService,
    private lastUsedProtocolDataService: LastUsedProtocolService,
    private protocolDataService: ProtocolDataService,
    private protocolService: ProtocolService
  ) { }

  public async setCurrentProtocol(
    protocolOrId: IdType|Protocol|null|undefined,
    {
      suppressAutoScroll = false
    }: Partial<Omit<ActiveProtocol, 'protocol'>> = {}
  ): Promise<void> {
    let protocol: Protocol|null|undefined;
    if (protocolOrId && typeof protocolOrId === 'string') {
      protocol = await observableToPromise(this.protocolDataService.getByIdAcrossProjects(protocolOrId));
    } else {
      protocol = protocolOrId as Protocol|null|undefined;
    }
    this.loggingService.debug(LOG_SOURCE, `setCurrentProtocol called for "${protocol?.name}" (${protocol?.id}) `);
    if (this.currentProtocol?.id !== protocol?.id) {
      this.activeProtocol.next(protocol ? { protocol, suppressAutoScroll } : null);
      this.userflowService.updateUserFlow({
        istGesperrtesProtokoll: protocol && protocol.closedAt ? true : false
      });
    }

    if (protocol && !isTaskProtocol(protocol)) {
      await this.lastUsedProtocolDataService.touchProtocol(protocol);
    }
  }

  public getCurrentProtocol(): Observable<Protocol | null> {
    return this.currentProtocolObservable;
  }

  public getCurrentProtocolWithTypeAndLayout(): Observable<ProtocolWithTypeAndLayout | null> {
    return this.currentProtocolObservable.pipe(switchMap((currentProtocol) => currentProtocol ? this.protocolService.protocolsWithTypeAndLayoutById$(currentProtocol.id) : of(null) ));
  }

  public getActiveProtocol(): Observable<ActiveProtocol | null> {
    return this.activeProtocolObservable;
  }
}
