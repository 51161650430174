import {Injectable} from '@angular/core';
import {IonRouterOutlet} from '@ionic/angular';
import {Observable, of, Subject} from 'rxjs';
import {debounce, filter, map, switchMap, take, takeUntil} from 'rxjs/operators';
import {IdAware, IdType} from 'submodules/baumaster-v2-common';
import {Nullish} from '../model/nullish';
import {ProtocolEntryOrOpen} from '../model/protocol';
import {AbstractProjectAwareDataService} from './data/abstract-project-aware-data.service';
import {ProtocolDataService} from './data/protocol-data.service';
import {ProtocolEntryDataService} from './data/protocol-entry-data.service';
import {ProtocolEntryFilterService} from './protocol/protocol-entry-filter.service';

@Injectable({
  providedIn: 'root'
})
export class ProtocolNavigationService {

  private watchIdCancelMap = new Map<AbstractProjectAwareDataService<any>, Map<IdType, Subject<void>>>();

  constructor(
    private protocolEntryDataService: ProtocolEntryDataService,
    private protocolDataService: ProtocolDataService,
    private protocolEntryFilterService: ProtocolEntryFilterService
  ) { }

  private getProtocolEntryByProtocolId(protocolId: IdType, last = false): Observable<ProtocolEntryOrOpen|null> {
    return this.protocolDataService.getById(protocolId).pipe(
      take(1),
      switchMap((protocol) =>
        this.protocolEntryFilterService.getFilteredAndSortedProtocolEntries(protocol).pipe(
          map(({ parents }) => parents?.[last ? parents?.length - 1 : 0] ?? null)
        )
      )
    );
  }

  getProtocolEntry(protocolEntryId: Nullish<IdType>|'last'|'first', protocolId: Nullish<IdType>): Observable<Nullish<ProtocolEntryOrOpen>> {
    if (!protocolEntryId || !protocolId) {
      return of(null);
    }

    if (['first', 'last'].includes(protocolEntryId) && protocolId) {
      return this.getProtocolEntryByProtocolId(protocolId, protocolEntryId === 'last');
    }

    return this.protocolEntryDataService.getProtocolEntryOrOpenById(protocolEntryId);
  }

  private getWatchCancelSubject<T extends IdAware>(service: AbstractProjectAwareDataService<T>, id: IdType): Subject<void> {
    const maybeSubject = this.watchIdCancelMap.get(service)?.get(id);
    if (maybeSubject) {
      return maybeSubject;
    }

    if (!this.watchIdCancelMap.has(service)) {
      this.watchIdCancelMap.set(service, new Map());
    }

    const subject = new Subject<void>();
    this.watchIdCancelMap.get(service).set(id, subject);

    return subject;
  }

  private removeCancelSubject<T extends IdAware>(service: AbstractProjectAwareDataService<T>, id: IdType): void {
    this.watchIdCancelMap.get(service)?.delete(id);
  }

  private watchIdDeleted<T extends IdAware>(service: AbstractProjectAwareDataService<T>, id: IdType, ionRouterOutlet?: IonRouterOutlet): Observable<unknown> {
    const subject = this.getWatchCancelSubject(service, id);
    return service.getByIdAcrossProjects(id).pipe(
      // Emit deletion event only when the router is active or when will become active
      debounce(() => (ionRouterOutlet?.isActivated ?? true) ? of(true) :
        ionRouterOutlet.activateEvents.pipe(take(1), map(() => true))
      ),
      // Emit value only when the protocol is not found
      filter((v) => !Boolean(v)),
      // Cancellable watch
      takeUntil(subject.pipe(take(1))),
      // Do it once
      take(1)
    );
  }

  private cancelWatchIdDeleted<T extends IdAware>(service: AbstractProjectAwareDataService<T>, id: IdType) {
    const subject = this.getWatchCancelSubject(service, id);

    subject.next();
    subject.complete();

    this.removeCancelSubject(service, id);
  }

  /**
   * Emits value when the protocol has been deleted on the server
   */
  watchProtocolDeleted(protocolId: IdType, ionRouterOutlet?: IonRouterOutlet): Observable<unknown> {
    return this.watchIdDeleted(this.protocolDataService, protocolId, ionRouterOutlet);
  }

  /**
   * Emits value when the protocol entry has been deleted on the server
   */
  watchProtocolEntryDeleted(protocolEntryId: IdType, ionRouterOutlet?: IonRouterOutlet): Observable<unknown> {
    return this.watchIdDeleted(this.protocolEntryDataService, protocolEntryId, ionRouterOutlet);
  }

  /**
   * Cancels all pending watch observables for given protocol entry id
   */
  cancelWatchProtocolEntryDeleted(protocolEntryId: IdType): void {
    return this.cancelWatchIdDeleted(this.protocolEntryDataService, protocolEntryId);
  }
}
