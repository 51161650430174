import {ColumnConfig} from '../ColumnConfig';
import {AttachmentBimMarkerScreenshotColumnSet} from './AttachmentBimMarkerScreenshotColumnSet';

export const BimMarkerColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id'},
  {name: 'created_at', prop: 'createdAt', cast: 'Timestamp'},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'created_by_id', prop: 'createdById'},
  {name: 'viewer_id', prop: 'viewerId'},
  {name: 'position_x', prop: 'positionX'},
  {name: 'position_y', prop: 'positionY'},
  {name: 'position_z', prop: 'positionZ'},
  {name: 'object_bounds', prop: 'objectBounds'},
  {name: 'object_center', prop: 'objectCenter'},
  {name: 'object_ids', prop: 'objectIds', cast: 'Jsonb'},
  {name: 'camera', prop: 'camera', cast: 'Jsonb'},
  {name: 'bim_version_id', prop: 'bimVersionId'},
  {name: 'protocol_entry_id', prop: 'protocolEntryId'},
  {name: 'source_bim_marker_id', prop: 'sourceBimMarkerId', def: null},
  {name: 'migration_status', prop: 'migrationStatus', def: null},
  {name: 'attachmentBimMarkerScreenshots', fk: 'bimMarkerId', mappedBy: AttachmentBimMarkerScreenshotColumnSet, transient: true},
];
