import {decodeHTML} from 'entities';
import {Content} from 'pdfmake/interfaces';
import sanitizeHtml from 'sanitize-html';
import {Address} from '../models';
import _ from 'lodash';

export function sanitizeBase64Image(base64Image: string): string {
  if (!(/^data:/.test(base64Image))) {
    return `data:${base64Image}`;
  }
  return base64Image;
}

export const wrappedOrEmpty = (str?: string|null): string => {
  if (!str) {
    return '';
  }

  return `(${str})`;
};

export interface ConvertHtmlToPdfmakeOptions {
  fontSize?: number;
}

export interface PdfHelperFunctions {
  convertHtmlToPdfmake(text: string | undefined | null, options?: ConvertHtmlToPdfmakeOptions): Content;
}

export const RICH_TEXT_INLINE_TAGS = ['a', 'br', 'span', 'strong', 'em'];
export const RICH_TEXT_TAGS = [...RICH_TEXT_INLINE_TAGS, 'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'pre', 'ul', 'ol', 'li'];
const UNDERLINE_COLOR_ISSUE_DUPLICATE_PART = '<span style="text-decoration:underline">';
const UNDERLINE_COLOR_ISSUE_REGEXP = new RegExp('<span style="text-decoration:underline"><span style="color:#([0-9a-fA-F]{6});text-decoration:underline">', 'g');
const UNDERLINE_COLOR_ISSUE_MAX_ITERATIONS = 1000;

const colorRegexps: RegExp[] = [
  // HEX format (e.g. '#00aaff')
  /^#(0x)?[0-9a-f]+$/i,
  // RGB format (e.g. 'rgb(0, 170, 255)')
  /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/
];

export const sanitizeRichTextInput = (value: string): string => sanitizeHtml(value, {
  allowedTags: RICH_TEXT_TAGS,
  allowedAttributes: {
    '*': ['style', 'href', 'target', 'rel', 'title'],
  },
  allowedStyles: {
    '*': {
      color: colorRegexps,
      'background-color': colorRegexps,
      'text-align': [/^left$/, /^right$/, /^center$/, /^justify$/],
      'padding-left': [/^\d+(?:px)$/],
      'padding-right': [/^\d+(?:px)$/],
      'text-decoration': [/^underline$/, /^line-through$/],
      'list-style-type': [/^none$/],
    },
  }
});

export const sanitizeRichTextInputForMailDisplay = (value: string): string => sanitizeHtml(value, {
  allowedTags: RICH_TEXT_TAGS,
  allowedAttributes: {
    '*': ['style', 'target', 'rel', 'title'],
  },
}).replace(/<a/g, '<a href="#"');

export const parseRichTextInput = (value: string): string => sanitizeHtml(value, {
  allowedAttributes: false,
  allowedTags: false,
});

const endingBlockElementRegex = new RegExp(`(${RICH_TEXT_TAGS
  .filter((tag) => !RICH_TEXT_INLINE_TAGS.includes(tag))
  .map((tag) => `</${tag}>`).join('|')})`
, 'g');
const brElementRegex = /<br\s*\/>/g;

const elementRegex = /<[^<>]+>/g;
const moreThanNewLinesRegex = (limit: number) => new RegExp(`(\n{${limit}})\n+`, 'g');

// decodeHTML takes care of HTML special chars, like &nbsp; or &auml;
export const convertRichTextToPlainText = (value: string): string => decodeHTML(value
  .replace(/\n/g, '')
  .replace(endingBlockElementRegex, '\n')
  .replace(brElementRegex, '\n')
  .replace(elementRegex, '')
  .replace(moreThanNewLinesRegex(2), '$1'));

export function isRichText(text: string|null|undefined): boolean {
  if (!text) {
    return false;
  }
  for (const richTextTag of RICH_TEXT_TAGS) {
    if (text.includes('<' + richTextTag)) {
      return true;
    }
  }
  return false;
}

export function convertToRichText(text: string|null|undefined): string|null|undefined {
  if (!text) {
    return text;
  }
  if (isRichText(text)) {
    return sanitizeRichTextInput(text);
  }
  const containsCrLf = text.indexOf('\r\n') >= 0;
  if (containsCrLf) {
    return '<p>' + text.replace(/([\r][\n])/g, '</p>\n<p>') + '</p>';
  }
  return '<p>' + text.replace(/[\n]/g, '</p>\n<p>') + '</p>';
}

export function fixUnderLineColorIssue(text: string): string {
  if (!text) {
    return text;
  }
  let match = text.match(UNDERLINE_COLOR_ISSUE_REGEXP);
  if (!match?.length) {
    return text;
  }
  let newText = text;
  let iteration = 0;
  while (match?.length) {
    const index = newText.indexOf(match[0]);
    if (index === -1) {
      throw new Error(`fixUnderLineColorIssue - something wrong with the implementation since indexOf did not find the regexp match ${match[0]}`);
    }
    newText = newText.substring(0, index) + '<span>' + newText.substring(index + UNDERLINE_COLOR_ISSUE_DUPLICATE_PART.length);
    match = newText.match(UNDERLINE_COLOR_ISSUE_REGEXP);
    iteration++;
    if (iteration > UNDERLINE_COLOR_ISSUE_MAX_ITERATIONS) {
      throw new Error(`fixUnderLineColorIssue stopped after ${iteration} iterations to avoid an endless loop.`);
    }
  }
  return newText;
}

export function formatOneLineAddress(address: Address|undefined|null): string {
  if (!address) {
    return '';
  }

  const street = `${address.street1 || ''} ${address.street2 || ''}`.trim();
  const city = `${address.zipCode || ''} ${address.city || ''}`.trim();
  const country = `${address.country || ''}`.trim();

  return _.compact([street, city, country]).join(', ');
}
