<ng-template [ngIf]="isContentAvailable && isImage">
  <fa-icon *ngIf="showSelectedImageIcon" [icon]="['fas', 'check-circle']" class="attachment-selected-icon"></fa-icon>
  <ng-container *rxLet="isShowThumbnail ? (attachment | imageUri: 'thumbnail': onObjectUrlCreated: abortSignal | async) : (attachment | imageUri: 'image': onObjectUrlCreated: abortSignal | async); let attachmentImageUri">
    <ion-img id="thumbnail_{{attachment?.id}}" *ngIf="isShowThumbnail && attachmentImageUri" [src]="attachmentImageUri" [class]="size" class="attachment-image"></ion-img>
    <ng-container *ngIf="!isShowThumbnail && attachmentImageUri">
      <ion-img id="image_{{attachment?.id}}" *ngIf="!attachment.markings || attachment.markings === ''; else fullSizeImageCanvas" [src]="attachmentImageUri" class="attachment-image" [class]="size"></ion-img>
      <ng-template #fullSizeImageCanvas>
        <div #imageCanvas [attr.src]="attachment.markings | canvas : attachment : {size:size, element: imageCanvas}: attachmentImageUri" class="attachment-image attachment-image-canvas" [class]="size"></div>
      </ng-template>
    </ng-container>
    <div *ngIf="isChatImage && showChatImageIcon" class="entry-icon-container"><ion-icon name="chatbox-outline" class="entry-icon"></ion-icon></div>

    <div *ngIf="attachmentImageUri === undefined" class="attachment-bundle ion-no-padding">
      <fa-icon [icon]="['fal', 'wifi-slash']" class="loading-content-icon ion-no-padding inactive offline-icon"></fa-icon>
    </div>
    <div *ngIf="attachmentImageUri === null" class="no-image-icon-container" [class]="size">
      <fa-icon [icon]="['fal', 'spinner']" class="loading-content-icon ion-no-padding inactive" [class]="size"></fa-icon>
    </div>
  </ng-container>
</ng-template>

<div *ngIf="isContentAvailable && !isImage" [class]="size" class="attachment-bundle ion-no-padding">
  <fa-icon *ngIf="showSelectedImageIcon" [icon]="['fas', 'check-circle']" class="attachment-selected-icon"></fa-icon>
  <div class="icon-date-bundle">
    <ion-icon name="{{!isAudio ? 'document-attach-outline' : 'musical-notes'}}" class="attachment-icon ion-no-padding" [class]="size"></ion-icon>
    <span class="attachment-date">{{attachment.createdAt | date: 'dd.MM.yyyy'}}</span>
  </div>
  <div class="attachment-label ion-no-padding">{{attachment.fileName}}</div>
</div>

<div *ngIf="isContentAvailable === false" class="no-image-icon-container" [class]="size">
  <fa-icon [icon]="['far', 'image']" class="no-image-icon ion-no-padding inactive" [class]="size"></fa-icon>
</div>

<div *ngIf="isContentAvailable === undefined" class="no-image-icon-container" [class]="size">
  <fa-icon [icon]="['fal', 'spinner']" class="loading-content-icon ion-no-padding inactive" [class]="size"></fa-icon>
</div>

