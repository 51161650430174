import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {ReportService} from '../../../services/report/report.service';
import {environment} from 'src/environments/environment';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PosthogService } from 'src/app/services/posthog/posthog.service';
import { ModalController } from '@ionic/angular';
import { filter } from 'rxjs/operators';

const BIM_ROUTE = '/project-room/bim';

@Component({
  selector: 'app-project-room-selector-list',
  templateUrl: './project-room-selector-list.component.html',
  styleUrls: ['./project-room-selector-list.component.scss'],
})
export class ProjectRoomSelectorListComponent implements OnInit {

  hasReportRights$: Observable<boolean>;
  hideBimSection = environment.hideBimSection;
  isActive = false;

  constructor(private reportService: ReportService, private router: Router, private posthogService: PosthogService, private modalController: ModalController) { }

  ngOnInit() {
    this.hasReportRights$ = this.reportService.hasPermissionForReport();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.highlightActiveItem();
    });
  }

  async navigateToBIM() {
    await this.router.navigate([BIM_ROUTE]);
  }

  private highlightActiveItem() {
    const currentRoute = this.router.url;
    if (currentRoute.startsWith(BIM_ROUTE)) {
      this.isActive = true;
    } else {
      this.isActive = false;
    }
  }

}
