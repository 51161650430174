<app-modal-header>
  <ion-title>{{'pdfProtocol.signature.title' | translate}}</ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <app-pdf-protocol-signature *ngFor="let pdfProtocolSignature of pdfProtocolSignatures; let i = index; trackBy: trackByProfileOrName" [pdfProtocolSignature]="pdfProtocolSignature" (pdfProtocolSignatureChange)="handlePdfProtocolSignatureChange($event, i)" [showCheckInsteadOfImage]="true"></app-pdf-protocol-signature>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" (click)="cancel()" >{{'cancel' | translate}}</ion-button>
    <ion-button class="omg-btn-primary" (click)="save()" [disabled]="!anythingSigned">{{'button.save' | translate}}</ion-button>
  </ion-buttons>
</app-modal-footer>
