<app-modal-header>
  <ion-title>{{ 'notification_settings.title' | translate }}</ion-title>
  <div class="ion-text-center no-internet-text-container" *ngIf="!(isCurrentUserAdmin$ | async)">
    <div class="omg-attention omg-attention-warning ion-text-center flex-column">
      {{'notification_settings.non_admin_info' | translate}}
    </div>
  </div>
</app-modal-header>

<ion-content class="with-padding">
  <form class="omg-form" [formGroup]="form" action="#">
    <ion-grid>
      <ion-row>
        <ion-col size="12">
          <span class="omg-t-header-3-bold">
            {{ 'notification_settings.heading' | translate:{project: currentProject?.name} }}
          </span>
        </ion-col>
        <ion-col size="12">
          <span [innerHTML]="'notification_settings.infoText' | translate"></span>
        </ion-col>
        <ion-col size="12" class="pb-2">
          <ion-item class="omg omg-form-checkbox">
            <ion-checkbox formControlName="pushNotification" (ngModelChange)="pushEnabledChanged($event)" label-placement="end" justify="start">
              {{ 'notification_settings.enablePush' | translate }}
            </ion-checkbox>
          </ion-item>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="12" class="pt-2">
          <span class="omg-t-header-3-bold">
            {{ 'notification_settings.heading2' | translate }}
          </span>
        </ion-col>
        <ion-col size="6">
          <span class="omg-modal-section-header">
            {{ 'notification_settings.newCreated' | translate }}
          </span>
        </ion-col>
        <ion-col size="6">
          <span class="omg-modal-section-header">
            {{ 'notification_settings.whom' | translate }}
          </span>
          <fa-icon
            class="grey-text"
            [icon]="['fal', 'info-circle']"
            appInfoPopoverClick
            [infoTitle]="'notification_settings.infoPopoverHeader'"
            [infoText]="'notification_settings.infoPopoverText'">
          </fa-icon>
        </ion-col>
        <ion-col size="6">
          <ion-item class="omg omg-form-checkbox">
            <ion-checkbox formControlName="protocolEntryNew" label-placement="end" justify="start">
              {{ 'notification_settings.newEntry' | translate }}
            </ion-checkbox>
          </ion-item>
        </ion-col>
        <ion-col size="6">
          <ion-item class="omg omg-form-checkbox">
            <ion-checkbox formControlName="protocolEntryToResponsible" label-placement="end" justify="start">
              {{ 'notification_settings.responsible' | translate }}
            </ion-checkbox>
          </ion-item>
        </ion-col>
        <ion-col size="6">
          <ion-item class="omg omg-form-checkbox">
            <ion-checkbox formControlName="protocolEntryNewSub" label-placement="end" justify="start">
              {{ 'notification_settings.newSubEntry' | translate }}
            </ion-checkbox>
          </ion-item>
        </ion-col>
        <ion-col size="6">
          <ion-item class="omg omg-form-checkbox">
            <ion-checkbox formControlName="protocolEntryToCreator" label-placement="end" justify="start">
              {{ 'notification_settings.creator' | translate }}
            </ion-checkbox>
          </ion-item>
        </ion-col>
        <ion-col size="6" class="pb-1">
          <ion-item class="omg omg-form-checkbox">
            <ion-checkbox formControlName="protocolEntryChatNew" label-placement="end" justify="start">
              {{ 'notification_settings.newComment' | translate }}
            </ion-checkbox>
          </ion-item>
        </ion-col>
        <ion-col size="6">
          <ion-item class="omg omg-form-checkbox">
            <ion-checkbox (click)="alertIfContacts()"  label-placement="end" justify="start" [(ngModel)]="configRecipientEnabled" [ngModelOptions]="{standalone: true}" [disabled]="!form.get('pushNotification').value || isWhatColumnChangesEmpty() || !(isCurrentUserAdmin$ | async)">
              {{ ('notification_settings.selectedContacts' | translate) + (profileIds ? ' (' + profileIds.length + ')' : '') }}
            </ion-checkbox>
          </ion-item>
        </ion-col>
        <ion-col size="6" class="pt-5">
          <span class="omg-modal-section-header">
            {{ 'notification_settings.newChange' | translate }}
          </span>
        </ion-col>
        <ion-col size="6">
          <ionic-selectable #contactSelectable
            item-content
            class="text-primary ion-hide"
            [items]="contacts"
            [(ngModel)]="profileIds"
            [ngModelOptions]="{standalone: true}"
            itemValueField="id"
            itemTextField="name"
            searchPlaceholder="{{'search' | translate}}"
            searchFailText="{{'noItemsFound' | translate}}"
            [canSearch]="true"
            [isMultiple]="true"
            [canClear]="true"
            [shouldStoreItemValue]="true"
            appSelectableCommon
            #fs="appSelectableCommon"
            [shouldFocusSearchbar]="fs.isDesktop"
            [hasVirtualScroll]="true"
            [virtualScrollHeaderFn]="getGroupText"
            [disabled]="!(isCurrentUserAdmin$ | async)">
            <ng-template ionicSelectableItemIconTemplate let-selected="isItemSelected">
              <app-selectable-item-icon [selected]="selected"></app-selectable-item-icon>
            </ng-template>
            <ng-template ionicSelectableValueTemplate let-value="value">
              <app-selectable-value [value]="value"></app-selectable-value>
            </ng-template>
            <ng-template ionicSelectableHeaderTemplate>
              <app-selectable-header [title]="'notification_settings.selectContacts' | translate"></app-selectable-header>
            </ng-template>
            <ng-template ionicSelectableFooterTemplate>
              <app-selectable-footer></app-selectable-footer>
            </ng-template>
          </ionic-selectable>
          <ion-button (click)="contactSelectable._click()" class="omg-btn-secondary pl-7 contact-btn" [disabled]="!configRecipientEnabled || !form.get('pushNotification').value || isWhatColumnChangesEmpty()">
            <span>{{ 'notification_settings.chooseContacts' | translate }}</span>
          </ion-button>
        </ion-col>
        <ion-col size="12">
          <ion-item class="omg omg-form-checkbox">
            <ion-checkbox formControlName="protocolEntryUpdateStatus" label-placement="end" justify="start">
              {{ 'notification_settings.statusChanged' | translate }}
            </ion-checkbox>
          </ion-item>
        </ion-col>
        <ion-col size="12">
          <ion-item class="omg omg-form-checkbox">
            <ion-checkbox formControlName="protocolEntryUpdateTodoUntil" label-placement="end" justify="start">
              {{ 'notification_settings.todoUntil' | translate }}
            </ion-checkbox>
          </ion-item>
        </ion-col>
        <ion-col size="12">
          <ion-item class="omg omg-form-checkbox">
            <ion-checkbox formControlName="protocolEntryUpdateAssignee" label-placement="end" justify="start">
              {{ 'notification_settings.responsibleChanged' | translate }}
            </ion-checkbox>
          </ion-item>
        </ion-col>
        <ion-col *ngIf="isWhomColumnChangesEmpty() && !isWhatColumnChangesEmpty()" size="6" class="mt-3">
          <span class="omg-attention omg-attention-danger" [innerHTML]="'notification_settings.noRecipientSelected' | translate"></span>          
        </ion-col>
      </ion-row>


      <ion-row>
        <ion-col size="12" class="pt-2">
          <span class="omg-t-header-3-bold">
            {{ 'notification_settings.heading3' | translate }}
          </span>
        </ion-col>
        <ion-col size="6">
          <span class="omg-modal-section-header">
            {{ 'notification_settings.newUpload' | translate }}
          </span>
        </ion-col>
        <ion-col size="6">
          <span class="omg-modal-section-header">
            {{ 'notification_settings.whom' | translate }}
          </span>
        </ion-col>
        <ion-col size="6">
          <ion-item class="omg omg-form-checkbox pb-1">
            <ion-checkbox formControlName="documentPdfPlanNew" label-placement="end" justify="start">
              {{ 'notification_settings.newPdfPlan' | translate }}
            </ion-checkbox>
          </ion-item>
          <ion-item class="omg omg-form-checkbox pt-1 pb-2">
            <ion-checkbox formControlName="documentBimPlanNew" label-placement="end" justify="start">
              {{ 'notification_settings.newBimModel' | translate }}
            </ion-checkbox>
          </ion-item>
        </ion-col>
        <ion-col size="6">
          <ion-radio-group (ngModelChange)="onWhomChanged($event)" [ngModel]="sendToWhom" [ngModelOptions]="{standalone: true}" [disabled]="!(isCurrentUserAdmin$ | async)">
            <ion-item class="omg omg-form-checkbox pb-1" lines="none">
              <ion-radio [disabled]="!form.get('pushNotification').value || isWhatColumnDocumentsEmpty()" label-placement="end" justify="start" [disabled]="!(isCurrentUserAdmin$ | async)" [value]="'projectTeam'">{{ 'notification_settings.wholeTeam' | translate }}</ion-radio>
            </ion-item>
            <ion-item class="omg omg-form-checkbox pt-1" lines="none">
              <ion-radio [disabled]="!form.get('pushNotification').value || isWhatColumnDocumentsEmpty()" label-placement="end" justify="start" [disabled]="!(isCurrentUserAdmin$ | async)" [value]="'ownCompanyProjectTeam'">{{ 'notification_settings.onlyOwnWorkers' | translate }}</ion-radio>
            </ion-item>
          </ion-radio-group>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="12" class="pt-2">
          <span class="omg-t-header-3-bold">
            {{ 'notification_settings.heading4' | translate }}
          </span>
        </ion-col>
        <ion-col size="6">
          <span class="omg-modal-section-header">
            {{ 'notification_settings.newProjectTeam' | translate }}
          </span>
        </ion-col>
        <ion-col size="6">
          <span class="omg-modal-section-header">
            {{ 'notification_settings.whom' | translate }}
          </span>
        </ion-col>
        <ion-col size="6">
          <ion-item class="omg omg-form-checkbox">
            <ion-checkbox formControlName="projectUserAdded" label-placement="end" justify="start">
              {{ 'notification_settings.userAddedToProject' | translate }}
            </ion-checkbox>
          </ion-item>
        </ion-col>
        <ion-col size="6">
          <ion-label>
            {{ 'notification_settings.newUserAdded' | translate }}
          </ion-label>
        </ion-col>
      </ion-row>

    </ion-grid>
  </form>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" (click)="close()">{{ 'cancel' | translate }}</ion-button>
    <ion-button class="omg-btn-primary" *ngIf="isCurrentUserAdmin$ | async" (click)="save()" [disabled]="isWhomColumnChangesEmpty() && !isWhatColumnChangesEmpty()">{{ 'button.save' | translate }}</ion-button>
  </ion-buttons>
</app-modal-footer>
