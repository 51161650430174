import {Pipe, PipeTransform} from '@angular/core';
import {formatProjectNumberOptional} from 'submodules/baumaster-v2-common/';

@Pipe({
  name: 'projectNumber'
})
export class ProjectNumberPipe implements PipeTransform {

  transform(value?: string|number): string {
    if (value == null) {
      return '';
    }

    return `${formatProjectNumberOptional(value)}`;
  }

}
