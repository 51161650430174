import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Address, Attachment, AttachmentChat, IdType, Profile, Project, Protocol, ProtocolEntry, ProtocolEntryChat, UserPublic} from 'submodules/baumaster-v2-common';
import {Observable, Subscription} from 'rxjs';
import {ProfileDataService} from '../../../services/data/profile-data.service';
import {UserPublicDataService} from '../../../services/data/user-public-data.service';
import {AddressDataService} from '../../../services/data/address-data.service';
import {AttachmentChatDataService} from '../../../services/data/attachment-chat-data.service';
import {AlertController, ModalController} from '@ionic/angular';
import {AttachmentBlob} from '../../../model/attachments';
import {ProtocolEntryChatCreateComponent} from '../protocol-entry-chat-create/protocol-entry-chat-create.component';
import {AuthenticationService} from '../../../services/auth/authentication.service';
import {ProtocolEntryChatService} from '../../../services/protocol/protocol-entry-chat-service';
import {TranslateService} from '@ngx-translate/core';
import {LoggingService} from '../../../services/common/logging.service';
import {ProtocolService} from 'src/app/services/protocol/protocol.service';
import {ProjectDataService} from 'src/app/services/data/project-data.service';
import {ToastService} from 'src/app/services/common/toast.service';
import {convertErrorToMessage} from 'src/app/shared/errors';
import {PosthogService} from 'src/app/services/posthog/posthog.service';
import {Router} from '@angular/router';
import {NetworkStatusService} from 'src/app/services/common/network-status.service';

const LOG_SOURCE = 'ProtocolEntryChatComponent';

@Component({
  selector: 'app-protocol-entry-chat',
  templateUrl: './protocol-entry-chat.component.html',
  styleUrls: ['./protocol-entry-chat.component.scss']
})

export class ProtocolEntryChatComponent implements OnInit, OnChanges, OnDestroy {

  @Input() acrossProjects = true;
  @Input() protocolEntry: ProtocolEntry;
  @Input() protocol: Protocol;
  @Input() chat: ProtocolEntryChat;
  @Input() allowEditMarkings: boolean;
  @Input() allowDeleting: boolean;
  @Input() isCarriedEntry: boolean;
  @Output() public markingsChanged = new EventEmitter<{ attachment: Attachment | AttachmentBlob, markings: string }>();
  @Output() public attachmentDeleted = new EventEmitter<{ attachment: Attachment | AttachmentBlob }>();

  public allowEditOrDelete: boolean;
  public selectedProject: Project;
  public attachmentObservable: Observable<Array<AttachmentChat>>;
  public attachments: Array<AttachmentChat>;
  public userById: Record<IdType, UserPublic> | undefined;
  public profileById: Record<IdType, Profile> | undefined;
  public addressById: Record<IdType, Address> | undefined;
  public profileByAttachedToUserId: Map<IdType, Profile> | undefined;
  public isOwner: boolean;
  public isCarriedChat: boolean;
  public loading: boolean;
  private projectSubscription: Subscription;
  private authenticationSubscription: Subscription;
  private attachmentSubscription: Subscription;
  private userByIdSubscription: Subscription;
  private profileByIdSubscription: Subscription;
  private addressByIdSubscription: Subscription;
  private profileByAttachedToUserIdSubscription: Subscription;

  constructor(private attachmentChatDataService: AttachmentChatDataService,
              private profileDataService: ProfileDataService,
              private userPublicDataService: UserPublicDataService,
              private addressDataService: AddressDataService,
              private authenticationService: AuthenticationService,
              private protocolEntryChatService: ProtocolEntryChatService,
              private translateService: TranslateService,
              private alertController: AlertController,
              private loggingService: LoggingService,
              private toastService: ToastService,
              private modalController: ModalController,
              private protocolService: ProtocolService,
              private projectDataService: ProjectDataService,
              private posthogService: PosthogService,
              private router: Router,
              private networkStatusService: NetworkStatusService) {
  }

  async ngOnInit() {
    this.userByIdSubscription = (this.acrossProjects
      ? this.userPublicDataService.dataAcrossClientsGroupedById
      : this.userPublicDataService.dataGroupedById
    ).subscribe((userById) => this.userById = userById);
    this.profileByIdSubscription = (this.acrossProjects
      ? this.profileDataService.dataAcrossClientsGroupedById
      : this.profileDataService.dataGroupedById
    ).subscribe((profileById) => this.profileById = profileById);
    this.addressByIdSubscription = (this.acrossProjects
      ? this.addressDataService.dataAcrossClientsGroupedById
      : this.addressDataService.dataGroupedById
    ).subscribe((addressById) => this.addressById = addressById);
    this.profileByAttachedToUserIdSubscription = this.profileDataService.dataGroupedByAttachedToUserId.subscribe((profileById) => this.profileByAttachedToUserId = profileById);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.protocolEntry) {
      this.projectSubscription?.unsubscribe();
      this.projectSubscription = (this.acrossProjects
        ? this.protocolService.getProjectByEntryId(this.protocolEntry.id)
        : this.projectDataService.currentProjectObservable
      ).subscribe(
        (project) => this.selectedProject = project
      );
    }
    this.attachmentObservable = (this.acrossProjects
      ? this.attachmentChatDataService.getByProtocolEntryAndChatAcrossProjects(this.protocolEntry.id, this.chat.id)
      : this.attachmentChatDataService.getByProtocolEntryAndChat(this.protocolEntry.id, this.chat.id)
    );
    this.attachmentSubscription = this.attachmentObservable.subscribe(attachments => this.attachments = attachments);
    this.authenticationSubscription = this.authenticationService.authenticatedUserId$.subscribe((authenticatedUserId) => {
      this.isOwner = authenticatedUserId === this.chat.createdById;
      this.isCarriedChat = this.isCarried();
      this.allowEditOrDelete = this.isOwner;
    });
  }

  ngOnDestroy(): void {
    this.authenticationSubscription?.unsubscribe();
    this.attachmentSubscription?.unsubscribe();
    this.userByIdSubscription?.unsubscribe();
    this.profileByIdSubscription?.unsubscribe();
    this.addressByIdSubscription?.unsubscribe();
    this.profileByAttachedToUserIdSubscription?.unsubscribe();
    this.projectSubscription?.unsubscribe();
  }

  public onMarkingsChanged(attachment: Attachment | AttachmentBlob, markings: string) {
    this.markingsChanged.emit({attachment, markings});
  }

  public onAttachmentDeleted(attachment: Attachment | AttachmentBlob) {
    this.attachmentDeleted.emit({attachment});
  }

  private async performDeleteComment() {
    try {
      this.loading = true;
      this.loggingService.debug(LOG_SOURCE, 'Removing protocolEntryChat');
      await this.protocolEntryChatService.deleteProtocolEntryChat(this.chat, this.selectedProject.id, this.protocolEntry.id);
      this.loggingService.debug(LOG_SOURCE, 'Removing of protocolEntryChat was successful');
      try {
        const isNetworkConnected = this.networkStatusService.onlineOrUnknown;
        this.posthogService.captureEvent(this.router.url.includes('tasks') ? '[Tasks][Task] Comment deleted' : '[Protocols][Entry] Comment deleted', {
          editedOffline: !isNetworkConnected
        });
      } catch (error) {
        this.loggingService.error(LOG_SOURCE, `Error capturing posthog event "${error?.userMessage}" - "${error?.message}"`);
      }
    } catch (e) {
      await this.toastService.errorWithMessageAndHeader('error_deleting_message', convertErrorToMessage(e));
    } finally {
      this.loading = false;
    }
  }

  public async deleteComment() {
    const alert = await this.alertController.create({
      header: this.translateService.instant('alert.deleteProtocolEntryChat.header'),
      message: this.translateService.instant('alert.deleteProtocolEntryChat.message'),
      buttons: [
        {
          text: this.translateService.instant('no'),
          role: 'cancel'
        },
        {
          text: this.translateService.instant('yes'),
          handler: async () => {
            await this.performDeleteComment();
          }
        }
      ]
    });
    await alert.present();
  }

  public async editComment() {
    const modal = await this.modalController.create({
      component: ProtocolEntryChatCreateComponent,
      backdropDismiss: true,
      cssClass: 'half-screen-modal',
      componentProps: {
        acrossProjects: this.acrossProjects,
        editMode: true,
        protocolEntry: this.protocolEntry,
        chat: this.chat,
        markingsChanged: (attachment: Attachment | AttachmentBlob, markings: string) => this.onMarkingsChanged(attachment, markings),
        attachmentDeleted: (attachment: Attachment | AttachmentBlob) => this.onAttachmentDeleted(attachment)
      }
    });
    return await modal.present();
  }

  private isCarried() {
    if (!this.isCarriedEntry) {
      return false;
    }
    const chatCreatedAt: Date = typeof this.chat.createdAt === 'string' ? new Date(this.chat.createdAt) : this.chat.createdAt;
    const protocolCreatedAt: Date = typeof this.protocol.createdAt === 'string' ? new Date(this.protocol.createdAt) : this.protocol.createdAt;
    return this.isCarriedEntry && chatCreatedAt < protocolCreatedAt;
  }


}
