import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureEnabledDirective } from '../../../directives/feature-enabled.directive';
import { LicenseTypeDirective } from '../../../directives/license-type.directive';



@NgModule({
  declarations: [FeatureEnabledDirective, LicenseTypeDirective],
  imports: [
    CommonModule
  ],
  exports: [FeatureEnabledDirective, LicenseTypeDirective],
})
export class LicenseModule { }
