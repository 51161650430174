import {Injectable} from '@angular/core';
import {IdType} from 'submodules/baumaster-v2-common/';
import {Subject} from 'rxjs';

const LOG_SOURCE = 'UnitPreselectService';

@Injectable({
  providedIn: 'root'
})
export class UnitPreselectService {

  constructor() { }

  private lastUsedUnitEntryMap = new Map<IdType, IdType>();
  private lastUsedUnitTaskMap = new Map<IdType, IdType>();

  private lastUsedChangedSubject = new Subject<void>();
  public lastUsedChanged$ = this.lastUsedChangedSubject.asObservable();

  public setLastUsedUnitIdEntry(lastUsedUnitId: IdType, projectId: IdType) {
    this.lastUsedUnitEntryMap.set(projectId, lastUsedUnitId);
    this.lastUsedChangedSubject.next();
  }

  public async setLastUsedUnitIdTask(lastUsedUnitId: IdType, projectId: IdType) {
    this.lastUsedUnitTaskMap.set(projectId, lastUsedUnitId);
    this.lastUsedChangedSubject.next();
  }

  public getLastUsedUnitIdEntryByProjectId(projectId: IdType): IdType | undefined {
    return this.lastUsedUnitEntryMap.get(projectId) ?? undefined;
  }

  public getLastUsedUnitIdTaskByProjectId(projectId: IdType): IdType | undefined {
    return this.lastUsedUnitTaskMap.get(projectId) ?? undefined;
  }
}
