import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import _ from 'lodash';
import {AuthenticationService} from '../auth/authentication.service';
import {AbstractClientAwareDataService} from './abstract-client-aware-data.service';
import {IdType, StaffingType, User} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {ClientService} from '../client/client.service';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {map} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { mapObjectsNameWithDeletedSuffix } from 'src/app/utils/async-utils';
import { TranslateService } from '@ngx-translate/core';
import {VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';

const REST_ENDPOINT_URI = 'api/data/staffingTypes/';

@Injectable({
  providedIn: 'root'
})
export class StaffingTypeDataService extends AbstractClientAwareDataService<StaffingType> {
  public readonly dataActive$ = this.data.pipe(map((staffTypes) => staffTypes.filter((staffType) => staffType.isActive === undefined || staffType.isActive)));
  public readonly dataActiveGroupedById: Observable<Record<IdType, StaffingType>> = this.dataActive$.pipe(
    map(entities => entities === null ? {} : _.keyBy(entities, 'id'))
  );
  public readonly dataWithDeletedSuffix$ = this.data.pipe(mapObjectsNameWithDeletedSuffix(this.translateService));
  public readonly dataGroupedByIdWithDeletedSuffix$ = this.dataWithDeletedSuffix$.pipe(
    map(entities => entities === null ? {} : _.keyBy(entities, 'id'))
  );

  constructor(http: HttpClient, storage: StorageService, authenticationService: AuthenticationService, userService: UserService, clientService: ClientService, loggingService: LoggingService,
              integrityResolverService: IntegrityResolverService, private translateService: TranslateService) {
    super(StorageKeyEnum.STAFFING_TYPE, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, clientService, loggingService,
          integrityResolverService, VERSION_INTRODUCED_DEFAULT, [(item: StaffingType) => item.name?.toLowerCase()]);
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }
}
