import {TranslateService} from '@ngx-translate/core';
import _ from 'lodash';
import {Address} from 'submodules/baumaster-v2-common';

export const getUserNameFromAddress = (translateService: TranslateService, address: Address, isActive?: boolean | undefined, isRemoved?: boolean | undefined): string => {
  if (!address) {
    return '';
  }
  let userNameStr = '';
  if (address.firstName) {
    userNameStr = address.firstName;
  }
  if (address.lastName) {
    if (!_.isEmpty(userNameStr)) {
      userNameStr = userNameStr + ' ';
    }
    userNameStr = userNameStr + address.lastName;
  }
  if (isActive === false) {
    userNameStr = userNameStr + ' ' + translateService.instant('deleted');
  } else if (isRemoved === true) {
    userNameStr = userNameStr + ' ' + translateService.instant('removed_suffix');
  }
  return userNameStr;
};
