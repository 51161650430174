import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Platform, ViewDidEnter} from '@ionic/angular';
import {map, Subscription} from 'rxjs';
import {PdfPlanFolderService} from 'src/app/services/project-room/pdf-plan-folder.service';
import {PdfPlanHolderEditModeService} from 'src/app/services/project-room/pdf-plan-holder-edit-mode.service';
import {PdfPlanHolderSelectionService} from 'src/app/services/project-room/pdf-plan-holder-selection.service';
import {ProjectRoomPage} from '../project-room.page';
import {SINGLE_COLUMN_WIDTH_PX, SINGLE_COLUMN_WIDTH_WITH_PINNED_MENU_PX} from '../variables/breakpoints';
import {SideBarSettingsService} from 'src/app/services/sidebar/sidebar-settings.service';

@Component({
  selector: 'app-project-room-pdf-plan-folders',
  templateUrl: './project-room-pdf-plan-folders.page.html',
  styleUrls: ['./project-room-pdf-plan-folders.page.scss'],
})
export class ProjectRoomPdfPlanFoldersPage implements OnInit, OnDestroy, ViewDidEnter {

  readonly foldersListMediaQuery$ = this.sideBarSettingsService.sideBarSetting$.pipe(
    map(sideBarSetting => sideBarSetting.pinned ? `(min-width: ${SINGLE_COLUMN_WIDTH_WITH_PINNED_MENU_PX}px)` : `(min-width: ${SINGLE_COLUMN_WIDTH_PX}px)`)
  );

  get isPlanFolderPage() {
    return this.router.url.startsWith('/project-room/pdf-plan-folders/');
  }

  isHoldersEditMode$ = this.pdfPlanHolderEditModeService.editMode$;

  eventsSubscription?: Subscription;

  constructor(
    private pdfPlanFolderService: PdfPlanFolderService,
    private router: Router,
    private platform: Platform,
    private pdfPlanHolderEditModeService: PdfPlanHolderEditModeService,
    private pdfPlanHolderSelectionService: PdfPlanHolderSelectionService,
    private sideBarSettingsService: SideBarSettingsService,
    @Optional() private projectRoomPage: ProjectRoomPage
  ) { }

  ngOnInit() {
    this.eventsSubscription = this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        if (this.pdfPlanHolderSelectionService.multiselectMode) {
          this.pdfPlanHolderSelectionService.leaveMultiselectMode();
        }
      }
    });
  }

  ngOnDestroy() {
    this.eventsSubscription?.unsubscribe();
  }

  addNewFolder() {
    this.pdfPlanFolderService.addNewFolder();
  }

  ionViewDidEnter() {
    if (this.platform.is('desktop')) {
      this.projectRoomPage?.searchbar?.setFocus();
    }
  }

}
