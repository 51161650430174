import {Observable, Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {AbortError} from '../shared/errors';
import {createTimeoutPromise} from './fetch-utils';

export function observableToPromise<T>(observable: Observable<T>, abortSignal?: AbortSignal): Promise<T> {
  let subscription: Subscription | undefined;
  const promise = new Promise<T>((resolve, reject) => {
    if (abortSignal) {
      abortSignal.onabort = () => {
        subscription?.unsubscribe();
        reject(new AbortError());
      };
    }
    subscription = observable.pipe(take(1)).subscribe(value => resolve(value),
      error => reject(error));
  });
  promise.finally(() => subscription?.unsubscribe());
  return promise;
}

export function observableToPromiseWithTimeout<T>(observable: Observable<T>, abortSignal?: AbortSignal, timeoutInMs = 3000): Promise<T> {
  return Promise.race([observableToPromise(observable, abortSignal), createTimeoutPromise(timeoutInMs)]) as Promise<T>;
}