import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[appSlimToggle]'
})
export class SlimToggleDirective {

  @HostBinding('class.bau-slim-toggle')
  get isSlimToggle() { return true; }

  constructor() { }

}
