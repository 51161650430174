<div
  #lt="appLicenseType"
  appLicenseType
  [appFeatureEnabled]="false"
  [appFeatureEnabledForConnected]="true"
  [appFeatureEnabledForLicenses]="[lt.LicenseType.VIEWER]"
  #notConnectedEnabled="appFeatureEnabled"
  class="omg-boundary folder-toolbar"
  [appLayout]="0.5"
  appLayoutJustifyContent="var(--header-justify-content)"
  appLayoutAlignItems="center"
>
  <ion-text class="ion-hide-md-down" *ngIf="(isHoldersEditMode$ | async); else folderToolbar">
    <span [innerHTML]="'project_room.pdf_plan_folder.toolbar.edit_mode_explanation' | translate"></span>
  </ion-text>
  <ng-template #folderToolbar>
    <app-pdf-plan-folder-picker #picker="appPdfPlanFolderPickerComponent"></app-pdf-plan-folder-picker>
    <ion-button [style.visibility]="(multiselectMode$ | async) ? 'hidden' : undefined" [hidden]="isHoldersEditMode$ | async" class="folder-select omg-btn-secondary" (click)="picker.open()">
      <fa-icon slot="start" [icon]="((picker.folder$ | async)?.icon | iconToArray) || ['fal', 'folder']"></fa-icon>
      <span class="label">{{ (picker.folder$ | async)?.name }}</span>
      <fa-icon slot="end" [icon]="['fal', 'chevron-down']"></fa-icon>
    </ion-button>
    <div class="folder-searchbar-container">
      <ng-container *ngIf="!(multiselectMode$ | async)">
        <ng-container *ngTemplateOutlet="searchbarTemplate"></ng-container>
      </ng-container>
    </div>
    <ion-button class="omg-btn-secondary ion-hide-lg-up" [class.omg-btn-color-selected]="search" *ngIf="!(multiselectMode$ | async)" (click)="searchFocus.emit()">
      <fa-icon [class.search-button-icon]="!search" [icon]="['fal', 'search']"></fa-icon>
    </ion-button>
    <ion-button class="omg-btn-secondary" [class.omg-btn-color-selected]="(hasFilter$ | async )" (click)="analytics.planFilterOpened(); openFilters()" *ngIf="!(multiselectMode$ | async)">
      <fa-icon [icon]="['fal', 'filter']"></fa-icon>
    </ion-button>
    <div id="multiselect-action-buttons" [appLayout]="0.5" appLayoutJustifyContent="var(--header-justify-content)" appLayoutAlignItems="center">
      <ion-button *ngIf="notConnectedEnabled.show$ | async" class="ion-hide-md-down omg-btn-secondary" (click)="analytics.plansActionClicked(selectedCount(), 'delete'); delete()" [disabled]="!(anySelectedAndAllDeletable$ | async)">
        <fa-icon slot="start" [icon]="['fal', 'trash-alt']"></fa-icon>
        <span class="bau-hide-all-col-down">{{'project_room.pdf_plan_folder.toolbar.delete' | translate}}</span>
      </ion-button>
      <ion-button *ngIf="notConnectedEnabled.show$ | async" class="ion-hide-md-down omg-btn-secondary" (click)="analytics.plansActionClicked(selectedCount(), 'move'); move()" [disabled]="!(anySelected$ | async)">
        <fa-icon slot="start" [icon]="['fal', 'arrow-alt-to-left']"></fa-icon>
        <span class="bau-hide-all-col-down">{{'project_room.pdf_plan_folder.toolbar.move' | translate}}</span>
      </ion-button>
      <ion-button (click)="analytics.plansActionClicked(selectedCount(), 'download'); download()" [disabled]="!(anySelected$ | async)" class="omg-btn-secondary">
        <fa-icon slot="start" [icon]="['fal', 'download']"></fa-icon>
        <span class="bau-hide-all-col-down">{{'project_room.pdf_plan_folder.toolbar.download' | translate}}</span>
      </ion-button>
      <ion-button *ngIf="notConnectedEnabled.show$ | async" class="ion-hide-md-down omg-btn-secondary" (click)="analytics.plansActionClicked(selectedCount(), 'edit'); edit()" [disabled]="!(anySelected$ | async)">
        <fa-icon slot="start" [icon]="['fal', 'pencil']"></fa-icon>
        <span class="bau-hide-all-col-down">{{'project_room.pdf_plan_folder.toolbar.edit' | translate}}</span>
      </ion-button>
      <ion-button *ngIf="notConnectedEnabled.show$ | async" (click)="analytics.plansActionClicked(selectedCount(), 'share'); share()" [disabled]="!(anySelected$ | async)" class="omg-btn-secondary">
        <fa-icon slot="start" [icon]="['fal', 'paper-plane']"></fa-icon>
        <span class="bau-hide-all-col-down">{{'project_room.pdf_plan_folder.toolbar.share_plans' | translate}}</span>
      </ion-button>
    </div>
    <app-tooltip target="multiselect-action-buttons" *ngIf="!(anySelected$ | async)" placement="bottom">
      {{'project_room.pdf_plan_folder.toolbar.multiselect_actions' | translate}}
    </app-tooltip>
    <ion-button (click)="toggleMultiselect()" class="omg-btn-secondary" [class.omg-btn-color-selected]="(multiselectMode$ | async)">
      <fa-icon slot="start" [icon]="['fal', 'tasks']"></fa-icon>
      <span class="bau-hide-all-col-down">{{'project_room.pdf_plan_folder.toolbar.multiselect' | translate}}</span>
    </ion-button>
  </ng-template>
</div>
