<svg viewBox="0 0 69 99" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M0 7.95589C0 3.97942 3.22356 0.755859 7.20003 0.755859H67.2003C68.1944 0.755859 69.0003 1.56175 69.0003 2.55587V96.7562C69.0003 97.7503 68.1944 98.5562 67.2003 98.5562H1.80001C0.805897 98.5562 0 97.7503 0 96.7562V7.95589Z"
    class="svg-background" />
  <rect x="57.8193" y="56.4019" width="6.52098" height="8.69464" rx="0.900004" class="svg-text" />
  <rect x="5.2168" y="56.4019" width="49.5595" height="8.69464" rx="0.900004" class="svg-text" />
  <mask id="path-4-inside-1_1089_110258" fill="white">
    <path d="M5.09473 53.3589H64.653V67.7051H5.09473V53.3589Z" />
  </mask>
  <path d="M5.09473 53.9588H64.653V52.759H5.09473V53.9588ZM64.653 67.1051H5.09473V68.305H64.653V67.1051Z" class="svg-text"
    mask="url(#path-4-inside-1_1089_110258)" />
  <path
    d="M7.80029 39.4342C7.80029 38.725 8.38887 38.1484 9.1128 38.1484H16.9878C17.7118 38.1484 18.3003 38.725 18.3003 39.4342V45.8628C18.3003 46.5719 17.7118 47.1485 16.9878 47.1485H9.1128C8.38887 47.1485 7.80029 46.5719 7.80029 45.8628V39.4342ZM14.4407 41.5737C14.3485 41.4411 14.1967 41.3627 14.0347 41.3627C13.8727 41.3627 13.7189 41.4411 13.6286 41.5737L11.8444 44.1371L11.301 43.4721C11.2067 43.3576 11.0651 43.2913 10.9175 43.2913C10.7698 43.2913 10.6263 43.3576 10.534 43.4721L9.22149 45.0793C9.10254 45.2239 9.07999 45.4228 9.16202 45.5895C9.24405 45.7563 9.41631 45.8628 9.60499 45.8628H11.5737H12.23H16.4956C16.6782 45.8628 16.8463 45.7643 16.9304 45.6056C17.0145 45.4469 17.0042 45.2561 16.9017 45.1094L14.4407 41.5737ZM10.0972 41.3627C10.3583 41.3627 10.6086 41.2611 10.7932 41.0803C10.9778 40.8995 11.0816 40.6542 11.0816 40.3984C11.0816 40.1427 10.9778 39.8974 10.7932 39.7166C10.6086 39.5358 10.3583 39.4342 10.0972 39.4342C9.8361 39.4342 9.58572 39.5358 9.40112 39.7166C9.21651 39.8974 9.1128 40.1427 9.1128 40.3984C9.1128 40.6542 9.21651 40.8995 9.40112 41.0803C9.58572 41.2611 9.8361 41.3627 10.0972 41.3627Z"
    class="svg-text" />
  <path
    d="M20.7002 39.4342C20.7002 38.725 21.2888 38.1484 22.0127 38.1484H29.8877C30.6117 38.1484 31.2002 38.725 31.2002 39.4342V45.8628C31.2002 46.5719 30.6117 47.1485 29.8877 47.1485H22.0127C21.2888 47.1485 20.7002 46.5719 20.7002 45.8628V39.4342ZM27.3406 41.5737C27.2484 41.4411 27.0966 41.3627 26.9346 41.3627C26.7726 41.3627 26.6188 41.4411 26.5285 41.5737L24.7444 44.1371L24.2009 43.4721C24.1066 43.3576 23.9651 43.2913 23.8174 43.2913C23.6697 43.2913 23.5262 43.3576 23.4339 43.4721L22.1214 45.0793C22.0024 45.2239 21.9799 45.4228 22.0619 45.5895C22.144 45.7563 22.3162 45.8628 22.5049 45.8628H24.4736H25.1299H29.3955C29.5781 45.8628 29.7462 45.7643 29.8303 45.6056C29.9144 45.4469 29.9041 45.2561 29.8016 45.1094L27.3406 41.5737ZM22.9971 41.3627C23.2582 41.3627 23.5085 41.2611 23.6931 41.0803C23.8777 40.8995 23.9815 40.6542 23.9815 40.3984C23.9815 40.1427 23.8777 39.8974 23.6931 39.7166C23.5085 39.5358 23.2582 39.4342 22.9971 39.4342C22.736 39.4342 22.4856 39.5358 22.301 39.7166C22.1164 39.8974 22.0127 40.1427 22.0127 40.3984C22.0127 40.6542 22.1164 40.8995 22.301 41.0803C22.4856 41.2611 22.736 41.3627 22.9971 41.3627Z"
    class="svg-text" />
  <path
    d="M33.6001 39.4342C33.6001 38.725 34.1887 38.1484 34.9126 38.1484H42.7876C43.5116 38.1484 44.1001 38.725 44.1001 39.4342V45.8628C44.1001 46.5719 43.5116 47.1485 42.7876 47.1485H34.9126C34.1887 47.1485 33.6001 46.5719 33.6001 45.8628V39.4342ZM40.2406 41.5737C40.1483 41.4411 39.9965 41.3627 39.8345 41.3627C39.6725 41.3627 39.5187 41.4411 39.4284 41.5737L37.6443 44.1371L37.1008 43.4721C37.0065 43.3576 36.865 43.2913 36.7173 43.2913C36.5696 43.2913 36.4261 43.3576 36.3338 43.4721L35.0213 45.0793C34.9023 45.2239 34.8798 45.4228 34.9618 45.5895C35.0439 45.7563 35.2161 45.8628 35.4048 45.8628H37.3735H38.0298H42.2954C42.478 45.8628 42.6461 45.7643 42.7302 45.6056C42.8143 45.4469 42.804 45.2561 42.7015 45.1094L40.2406 41.5737ZM35.897 41.3627C36.1581 41.3627 36.4084 41.2611 36.593 41.0803C36.7776 40.8995 36.8814 40.6542 36.8814 40.3984C36.8814 40.1427 36.7776 39.8974 36.593 39.7166C36.4084 39.5358 36.1581 39.4342 35.897 39.4342C35.6359 39.4342 35.3855 39.5358 35.2009 39.7166C35.0163 39.8974 34.9126 40.1427 34.9126 40.3984C34.9126 40.6542 35.0163 40.8995 35.2009 41.0803C35.3855 41.2611 35.6359 41.3627 35.897 41.3627Z"
    class="svg-text" />
  <rect x="57.8193" y="9.45068" width="6.52098" height="8.69464" rx="0.900004" class="svg-text" />
  <rect x="5.2168" y="9.45068" width="49.5595" height="8.69464" rx="0.900004" class="svg-text" />
  <mask id="path-11-inside-2_1089_110258" fill="white">
    <path d="M5.1001 15.6484H64.5003V20.7485H5.1001V15.6484Z" />
  </mask>
  <path d="M64.5003 20.1485H5.1001V21.3485H64.5003V20.1485Z" class="svg-text" mask="url(#path-11-inside-2_1089_110258)" />
  <mask id="path-13-inside-3_1089_110258" fill="white">
    <path d="M7.80029 30.9482H64.5005V36.0483H7.80029V30.9482Z" />
  </mask>
  <path d="M64.5005 35.4483H7.80029V36.6483H64.5005V35.4483Z" class="svg-text"
    mask="url(#path-13-inside-3_1089_110258)" />
  <mask id="path-15-inside-4_1089_110258" fill="white">
    <path d="M5.09473 6.84229H64.2183V49.0113H5.09473V6.84229Z" />
  </mask>
  <path d="M5.09473 7.44229H64.2183V6.24228H5.09473V7.44229ZM64.2183 48.4113H5.09473V49.6113H64.2183V48.4113Z"
    class="svg-text" mask="url(#path-15-inside-4_1089_110258)" />
  <path
    d="M16.9378 24.96C16.9378 26.1939 15.3007 28.3928 14.5818 29.2991C14.4093 29.516 14.0891 29.516 13.9189 29.2991C13.1999 28.3928 11.5628 26.1939 11.5628 24.96C11.5628 23.4616 12.7655 22.248 14.2503 22.248C15.7352 22.248 16.9378 23.4616 16.9378 24.96ZM17.117 26.777C17.1954 26.621 17.267 26.4651 17.332 26.3114C17.3432 26.2843 17.3544 26.2549 17.3656 26.2278L19.9635 25.1792C20.3174 25.0368 20.7003 25.299 20.7003 25.6832V31.8031C20.7003 32.0246 20.566 32.2235 20.3622 32.3071L17.117 33.6156V26.777ZM10.882 25.3736C10.9357 25.6922 11.0432 26.0131 11.1686 26.3114C11.2336 26.4651 11.3053 26.621 11.3836 26.777V32.4585L8.53712 33.6088C8.18326 33.7512 7.80029 33.489 7.80029 33.1048V26.9849C7.80029 26.7634 7.93467 26.5645 8.13847 26.4809L10.8842 25.3736H10.882ZM15.1417 29.7511C15.453 29.3578 15.9412 28.7183 16.4003 28.0109V33.645L12.1003 32.4043V28.0109C12.5594 28.7183 13.0477 29.3578 13.359 29.7511C13.8181 30.3296 14.6826 30.3296 15.1417 29.7511ZM14.2503 25.6832C14.4879 25.6832 14.7158 25.5879 14.8838 25.4184C15.0518 25.2489 15.1462 25.0189 15.1462 24.7792C15.1462 24.5394 15.0518 24.3095 14.8838 24.14C14.7158 23.9704 14.4879 23.8752 14.2503 23.8752C14.0127 23.8752 13.7849 23.9704 13.6169 24.14C13.4489 24.3095 13.3545 24.5394 13.3545 24.7792C13.3545 25.0189 13.4489 25.2489 13.6169 25.4184C13.7849 25.5879 14.0127 25.6832 14.2503 25.6832Z"
    class="svg-text" />
  <rect x="24.6001" y="22.248" width="17.1001" height="11.4" rx="0.900004" class="svg-text" />
  <path
    d="M31.6507 28.8481C31.6507 28.8481 33.6007 26.7762 33.6007 25.6606C33.6007 24.6047 32.7273 23.748 31.6507 23.748C30.5741 23.748 29.7007 24.6047 29.7007 25.6606C29.7007 26.7762 31.6507 28.8481 31.6507 28.8481ZM31.6507 25.0231C31.8231 25.0231 31.9884 25.0902 32.1103 25.2098C32.2322 25.3293 32.3007 25.4915 32.3007 25.6606C32.3007 25.8296 32.2322 25.9918 32.1103 26.1113C31.9884 26.2309 31.8231 26.2981 31.6507 26.2981C31.4783 26.2981 31.313 26.2309 31.1911 26.1113C31.0692 25.9918 31.0007 25.8296 31.0007 25.6606C31.0007 25.4915 31.0692 25.3293 31.1911 25.2098C31.313 25.0902 31.4783 25.0231 31.6507 25.0231Z"
    class="svg-white" />
  <path d="M31.8003 33.6486V30.3486H44.1003" class="svg-white-stroke" stroke-width="0.900004" />
  <rect x="5.09473" y="69.8784" width="59.5583" height="8.69464" rx="0.900004" class="svg-highlight" />
</svg>