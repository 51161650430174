import {ChangeDetectorRef, Directive, Input, OnChanges, OnDestroy, OnInit, Optional} from '@angular/core';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {distinctUntilChanged, shareReplay, startWith, switchMap, takeUntil} from 'rxjs/operators';
import {TagListComponent} from 'src/app/components/tags/tag-list/tag-list.component';
import {TagObjectType} from 'src/app/model/tag';
import {IdType, TagBase} from 'submodules/baumaster-v2-common';
import {TagService} from '../../services/tags/tag.service';

@Directive({
  selector: '[appUseTagsClient]',
  exportAs: 'appUseTagsClient',
})
export class UseTagsClientDirective implements OnChanges, OnDestroy, OnInit {

  private properties = new ReplaySubject<[IdType, TagObjectType]>(1);
  private destroy$ = new Subject<void>();

  @Input()
  objectId: IdType;

  @Input()
  objectType: TagObjectType;

  tags$: Observable<TagBase[]> = this.properties.pipe(
    distinctUntilChanged((a, b) => a.join(',') === b.join(',')),
    switchMap(([id, type]) => this.tagService.getTagsForObject$(id, type)),
    startWith([]),
    shareReplay({
      refCount: true,
      bufferSize: 1
    }),
    takeUntil(this.destroy$)
  );

  constructor(
    private tagService: TagService,
    private cdRef: ChangeDetectorRef,
    @Optional() private tagListComponent: TagListComponent
  ) {}

  ngOnInit() {
    if (this.tagListComponent) {
      this.tags$.subscribe((tags) => {
        this.tagListComponent.tags = tags;
        this.cdRef.detectChanges();
      });
    }
  }

  ngOnChanges() {
    this.properties.next([this.objectId, this.objectType]);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.properties.complete();
  }
}
