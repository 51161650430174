import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Device} from '@capacitor/device';
import _ from 'lodash';
import {interval, merge, Observable, Subject} from 'rxjs';
import {distinctUntilChanged, map, startWith, switchMap, timeout} from 'rxjs/operators';
import {UserDevice} from 'submodules/baumaster-v2-common/';
import {environment} from '../../../environments/environment';
import {observableToPromise} from '../../utils/async-utils';
import {AuthenticationService} from './authentication.service';

const ENDPOINT_FOR_USER_DEVICES = 'userDevice';
@Injectable({
  providedIn: 'root'
})
export class UserDeviceService {

  constructor(private authenticationService: AuthenticationService, private http: HttpClient) { }

  private fetchWithoutTimeout$ = new Subject<void>();

  getDevices$(): Observable<UserDevice[]> {
    const interval$ = interval(30000).pipe(startWith(0));
    return merge(interval$, this.fetchWithoutTimeout$).pipe(
      switchMap(() => this.fetchDevices()),
      distinctUntilChanged(_.isEqual)
    );
  }

  updateObservableWithoutInterval(): void {
    this.fetchWithoutTimeout$.next();
  }

  private fetchDevices(): Observable<UserDevice[]> {
    return this.http.get<UserDevice[]>(environment.serverUrl + ENDPOINT_FOR_USER_DEVICES);
  }

  async deactivateUserDevice(deviceUuidToDeactivate?: string, timeoutMs?: number): Promise<void> {
    const deviceUuid = await this.getDeviceUuid();
    if (!deviceUuidToDeactivate) {
      deviceUuidToDeactivate = deviceUuid;
    }

    const isAuthenticated = await observableToPromise(this.authenticationService.isAuthenticated$);
    if (!isAuthenticated) {
      throw new Error('Unable to deactivateUserDevice as no user is authenticated');
    }

    const url = `${environment.serverUrl}userDevice/${deviceUuidToDeactivate}/deactivate`;
    await observableToPromise(this.http.post(url, {}).pipe(
      timeoutMs ? timeout(timeoutMs) : map((v) => v)
    ));
  }

  async activateUserDevice(deviceUuidToActivate: string, timeoutMs?: number): Promise<void> {
    const isAuthenticated = await observableToPromise(this.authenticationService.isAuthenticated$);
    if (!isAuthenticated) {
      throw new Error('Unable to activateUserDevice as no user is authenticated');
    }

    const url = `${environment.serverUrl}userDevice/${deviceUuidToActivate}/activate`;
    await observableToPromise(this.http.post(url, {}).pipe(
      timeoutMs ? timeout(timeoutMs) : map((v) => v)
    ));
  }

  async deactivateAndActivateUserDevice(uuidToActivate: string, uuidToDeactivate: string, timeoutMs?: number) {
    const isAuthenticated = await observableToPromise(this.authenticationService.isAuthenticated$);
    if (!isAuthenticated) {
      throw new Error('Unable to activateUserDevice as no user is authenticated');
    }

    const url = `${environment.serverUrl}userDevice/${uuidToDeactivate}/deactivate/${uuidToActivate}/activate`;
    await observableToPromise(this.http.post(url, {}).pipe(
      timeoutMs ? timeout(timeoutMs) : map((v) => v)
    ));
  }

  private async getDeviceUuid(): Promise<string> {
    const deviceId = await Device.getId();
    return deviceId.identifier;
  }
}
