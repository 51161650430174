import {fabric} from 'fabric';
import _, {isEmpty} from 'lodash';
import {FabricPdfPlanMarker, MARKER_DEFAULT_PARAMETER, MarkerData, MarkerType, OldMarkerSize} from './fabricPdfPlanMarker';
import {
  PdfPlanMarkerProtocolEntry,
  PdfPlanPageMarking,
  PdfPlanPageMarkingBase,
  PdfPlanVersionQualityEnum,
  ProtocolEntry,
  ProtocolEntryIconStatus,
  ProtocolEntryStatus,
  ProtocolEntryType
} from '../models';
import {IImageOptions} from 'fabric/fabric-impl';

const IMAGE_QUALITY = 0.5;
/**
 * If scale is provided for a plan, it is used to calculate the zoom factor.
 * The default ZOOM_FACTOR is based on this scale ZOOM_FACTOR_REFERENCE_SCALE (1:50 is mostly used)
 */
const ZOOM_FACTOR_DEFAULT_SCALE = 50;
/*
ZOOM_FACTOR depends on the image width/height. ZOOM_FACTOR is based on this width value. The actual zoomFactor is being calculated based on the difference.
Increasing this value will zoom in more.
 */
const PLAN_MARKER_CLIP_HEIGHT_IN_METER = 4;
const PLAN_MARKER_NOT_ZOOMED_ENOUGH_LIMIT_IN_PERCENT = 80;
const PLAN_MARKER_NOT_ZOOMED_ENOUGH_ZOOM_FACTOR = 2;
const ZOOM_FACTOR_DECREMENT_FACTOR = 0.95;
export const PLAN_MARKER_IMAGE_HEIGHT = 540; // increasing this will increase the image quality but also the PDF file size. It also requires to increase the ZOOM_FACTOR.
export const PLAN_MARKER_IMAGE_HEIGHT_FOR_DOWNLOAD_LINK = 1024; // increasing this will increase the image quality but also the PDF file size. It also requires to increase the ZOOM_FACTOR.
const MARKER_SIZE_FACTOR = 1;
const REGEXP_SCALE = new RegExp(/\b(\d+):\b(\d+)/);
const INCH_TO_MM_FACTOR = 25.4;
export const BIM_MARKER_COLORS = {
  [ProtocolEntryIconStatus.DONE]: '78c832',
  [ProtocolEntryIconStatus.ON_HOLD]: 'efb003',
  [ProtocolEntryIconStatus.OPEN]: 'AD003E',
  [ProtocolEntryIconStatus.INFO]: '2691c8',
};

async function createMarkerImage(color: string, loadImageFunction?: (src: string) => Promise<CanvasImageSource>, forSelected = false): Promise<CanvasImageSource> {
  const isFirefox = typeof navigator !== 'undefined' ? !!navigator?.userAgent?.includes('Firefox') : false;
  let src;
  if (isFirefox) {
    src = forSelected ? MARKER_DEFAULT_PARAMETER.pngSelectedMarkerData : MARKER_DEFAULT_PARAMETER.pngMarkerData;
  } else {
    src = forSelected ? MARKER_DEFAULT_PARAMETER.svgSelectedMarkerData.replace('000000', color) : MARKER_DEFAULT_PARAMETER.svgMarkerData.replace('000000', color);
  }
  if (loadImageFunction) {
    return await loadImageFunction(src);
  }
  const markerImage = new Image();
  markerImage.src = src;
  return new Promise((resolve, reject) => {
    markerImage.onload = () => {
      resolve(markerImage);
    };
    markerImage.onerror = (error) => {
      reject(error);
    };
  });
}

export async function loadMarkerImages(loadImageFunction?: (src: string) => Promise<CanvasImageSource>, forSelected = false): Promise<Map<ProtocolEntryIconStatus, CanvasImageSource>> {
  const markerImages = new Map<ProtocolEntryIconStatus, CanvasImageSource>();
  markerImages.set(ProtocolEntryIconStatus.DONE, await createMarkerImage('78c832', loadImageFunction, forSelected));
  markerImages.set(ProtocolEntryIconStatus.ON_HOLD, await createMarkerImage('efb003', loadImageFunction, forSelected));
  markerImages.set(ProtocolEntryIconStatus.OPEN, await createMarkerImage('AD003E', loadImageFunction, forSelected));
  markerImages.set(ProtocolEntryIconStatus.INFO, await createMarkerImage('2691c8', loadImageFunction, forSelected));
  return markerImages;
}

export async function loadImageWithWidthHeight(src: string): Promise<{image: fabric.Image, imgWidth: number, imgHeight: number}> {
  const image = await loadImage(src);
  const imgWidth = image.width || image.getOriginalSize().width;
  const imgHeight = image.height || image.getOriginalSize().height;
  if (!imgWidth || !imgHeight) {
    throw new Error(`Error loading image from source "${src}". Image not found or empty.`);
  }
  return {image, imgWidth, imgHeight};
}

export function loadImage(src: string, imgOptions?: IImageOptions): Promise<fabric.Image> {
  return new Promise((resolve, reject) => {
    fabric.Image.fromURL(src, async (img) => {
      if (_.isEmpty(img)) {
        reject(`Unable to load image from src "${src}".`);
      }
      if (img.width === undefined || img.height === undefined) {
        reject('Loading image from src "${src}" failed because img.width and/or img.height is undefined.');
        return;
      }
      resolve(img);
    }, imgOptions);
  });
}

function isWidthHeightEqualWithDiscrepancy(targetWidthHeight: number, actualWidthHeight: number, discrepancyInPx = 10): boolean {
  return actualWidthHeight >= targetWidthHeight - discrepancyInPx && actualWidthHeight <= targetWidthHeight + discrepancyInPx;
}

/**
 * This is the same as loadImage but makes sure the image is always scaled to the correct size (if provided). It is found on some low-end Android devices that images cannot be loaded in full size
 * (see BM2-2966 for details). This is not the fault of fabric either, html img elements would just not render images exceeding certain sizes.
 * If scaling is necessary the returned image can be used without limitations.
 * @param src source of the image to be loaded (url or objectUrl).
 * @param width the expected width of the image. If not provided, the image cannot be scaled and the correct width cannot be guaranteed.
 * @param height the expected height of the image. If not provided, the image cannot be scaled and the correct height cannot be guaranteed.
 */
export async function loadImageEnsureWidthHeight(src: string, width?: number|null, height?: number|null): Promise<{image: fabric.Image, imageScaled: boolean|undefined, scaleX?: number, scaleY?: number}> {
  const {image, imgWidth, imgHeight} = await loadImageWithWidthHeight(src);
  if (isEmpty(image) || !width || !height || !imgWidth || !imgHeight) {
    // No image or no width/height provided. No way to tell whether it was loaded properly.
    return {image, imageScaled: undefined};
  }
  if ((isWidthHeightEqualWithDiscrepancy(width, imgWidth) && isWidthHeightEqualWithDiscrepancy(height, imgHeight))) {
    // Image loaded with expected width/height.
    return {image, imageScaled: false};
  }
  // Image not loaded with expected width/height. Need to load again with scaling and manually set the width/height.
  const scaleX = width / imgWidth;
  const scaleY = height / imgHeight;
  const scaledImage = await loadImage(src, {scaleX, scaleY});
  scaledImage.getElement().width = width;
  scaledImage.getElement().height = height;
  scaledImage.width = width;
  scaledImage.height = height;
  return {image: scaledImage, imageScaled: true, scaleX, scaleY};
}

function calculateFactor(imageWidth: number, imageHeight: number, options?: RenderPlanMarkerOptions): {canvasWidth: number; canvasHeight: number; factor: number} {
  let ret: {canvasWidth: number; canvasHeight: number; factor: number};

  if (options?.viewHeight !== undefined && options?.viewHeight < imageHeight) {
    const factor = options?.viewHeight / imageHeight;
    const canvasWidth = Math.floor(imageWidth * factor);
    ret = {
      canvasWidth,
      canvasHeight: options?.viewHeight,
      factor
    };
  } else if (options?.viewWidth !== undefined && options?.viewWidth < imageWidth) {
    const factor = options?.viewWidth / imageWidth;
    const canvasHeight = Math.floor(imageHeight * factor);
    ret = {
      canvasWidth: options?.viewWidth,
      canvasHeight,
      factor
    };
  } else {
    ret = {
      canvasWidth: imageWidth,
      canvasHeight: imageHeight,
      factor: 1
    };
  }
  return ret;
}

/**
 * Extracts a string representation of a scale (e.g. "1:50") to the scale factor (e.g. 50).
 * Example "2:50" results to 25.
 * Any characters not matching a valid scale are ignored (e.g. "M 1:100" results to 100).
 * Any invalid strings result to undefined (e.g. "I am not a scale" results to undefined).
 * @param scale the scale as string.
 */
export function extractScaleNumber(scale: string|null|undefined): number|undefined {
  if (!scale) {
    return undefined;
  }
  const match = scale.match(REGEXP_SCALE);
  if (!match) {
    return undefined;
  }
  const first = +match[1]; // First regex catch group
  const last = +match[2]; // Second regex catch group
  return first === 0 ? undefined : last / first;
}

async function setCanvasBackgroundImage(canvas: fabric.Canvas, img: fabric.Image): Promise<void> {
  return new Promise((resolve, reject) => {
    canvas.setBackgroundImage(img, () => {
      resolve();
    }, {width: img.width, height: img.height});
  });
}

async function loadCanvasBackgroundImage(src: string, markings?: Array<string>, options?: RenderPlanMarkerOptions):
  Promise<{canvas: fabric.Canvas; img: fabric.Image; canvasWidth: number; canvasHeight: number; factor: number, imgWidth: number, imgHeight: number}> {
  return new Promise(async (resolve, reject) => {
    try {
      const {image: img, imgWidth, imgHeight} = await loadImageWithWidthHeight(src);
      const {canvasWidth, canvasHeight, factor} = calculateFactor(imgWidth, imgHeight, options);
      const canvas = new fabric.Canvas(null, {width: canvasWidth, height: canvasHeight});
      canvas.setZoom(factor);
      if (markings?.length) {
        loadMarkings(canvas, markings);
      }
      await setCanvasBackgroundImage(canvas, img);
      resolve({canvas, img, canvasWidth, canvasHeight, factor, imgWidth, imgHeight});
    } catch (e) {
      reject(e);
    }
  });
}

export async function addCanvasMarker(canvas: fabric.Canvas, markerImages: Map<ProtocolEntryIconStatus, CanvasImageSource>, markerData: MarkerData, factor: number): Promise<FabricPdfPlanMarker> {
  const marker = new FabricPdfPlanMarker(markerImages, markerData);
  marker.setSize(factor * MARKER_SIZE_FACTOR);
  canvas.add(marker);
  return marker;
}

export async function addCanvasMarkers(canvas: fabric.Canvas, markerImages: Map<ProtocolEntryIconStatus, CanvasImageSource>, markersData: Array<MarkerData>, factor: number):
  Promise<Array<FabricPdfPlanMarker>> {
  const markers: FabricPdfPlanMarker[] = [];
  for (const markerData of markersData) {
    markers.push(await addCanvasMarker(canvas, markerImages, markerData, factor));
  }
  return markers;
}

export interface RenderPlanMarkerOptions {
  viewWidth?: number;
  viewHeight?: number;
  planMarkerClipHeightInMeter?: number;
  zoomToMarker?: boolean; // default false
  pdfPlanVersionQuality?: PdfPlanVersionQualityEnum | null;
  scaleNumber?: number;
}

function convertPixelToMillimeter(pixel: number, dpi = 72): number {
  return pixel * INCH_TO_MM_FACTOR / dpi;
}

function convertMillimeterToPixel(mm: number, dpi = 72): number {
  return mm / INCH_TO_MM_FACTOR * dpi;
}

function calculatePlanMarkerZoomFactor(factor: number, imgWidth: number, imgHeight: number, options?: RenderPlanMarkerOptions): number {
  const pdfPlanVersionQualityFactor = options?.pdfPlanVersionQuality ?? PdfPlanVersionQualityEnum.LOW;
  const scaleNumber = options?.scaleNumber ?? ZOOM_FACTOR_DEFAULT_SCALE;
  const planMarkerClipHeightInMeter = options?.planMarkerClipHeightInMeter ?? PLAN_MARKER_CLIP_HEIGHT_IN_METER;
  let clipHeightInPixel = convertMillimeterToPixel(planMarkerClipHeightInMeter * 1000 * (1 / scaleNumber) * pdfPlanVersionQualityFactor);
  if (clipHeightInPixel >= imgHeight / 100 * PLAN_MARKER_NOT_ZOOMED_ENOUGH_LIMIT_IN_PERCENT) {
    clipHeightInPixel = imgHeight / PLAN_MARKER_NOT_ZOOMED_ENOUGH_ZOOM_FACTOR;
  }
  const zoomFactor = imgHeight / clipHeightInPixel;
  return zoomFactor * factor;
}

export async function renderPlanMarker(src: string, markers: MarkerData[]|undefined, markerImages: Map<ProtocolEntryIconStatus, CanvasImageSource>, markings?: Array<string>,
                                       options?: RenderPlanMarkerOptions): Promise<string> {
  let canvasToDispose: fabric.Canvas|undefined;
  try {
    const {canvas, factor, imgWidth, imgHeight} = await loadCanvasBackgroundImage(src, markings, options);
    canvasToDispose = canvas;
    const zoomFactor = calculatePlanMarkerZoomFactor(factor, imgWidth, imgHeight, options);
    if (options?.zoomToMarker && factor <= zoomFactor) {
      let actualCanvasZoomFactor: number|undefined;
      const fabricMarkers = new Array<FabricPdfPlanMarker>();
      if (markers?.length) {
        for (const marker of markers) {
          fabricMarkers.push(await addCanvasMarker(canvas, markerImages, marker, actualCanvasZoomFactor ?? zoomFactor));
        }
      }
      if (markings?.length || (markers && markers.length > 1)) {
        // more than one object on canvas
        canvas.setZoom(1);
        const maxTopLeftBottomRight = findObjectsTopLeftBottomRight(canvas);
        if (maxTopLeftBottomRight) {
          const imageWidth = canvas.getWidth() / factor;
          const imageHeight = canvas.getHeight() / factor;
          const imageZoomFactor = Math.min((imageWidth / maxTopLeftBottomRight.width), (imageHeight / maxTopLeftBottomRight.height));
          const canvasZoomFactor = (imageZoomFactor * factor);
          actualCanvasZoomFactor = Math.max(canvasZoomFactor * ZOOM_FACTOR_DECREMENT_FACTOR, factor); // zoom out a little, so we can see the surroundings. But never outside the image.
          if (markers?.length) {
            // A marker alone (without markings) will use the ZOOM_FACTOR. If a marker is set, make sure we do not zoom in more than we did without a markers.
            actualCanvasZoomFactor = Math.min(actualCanvasZoomFactor, zoomFactor);
          }
          if (actualCanvasZoomFactor !== undefined) {
            // we need to change the size of the markers since we now know how much we need to zoom in (resp. zoom out)
            for (const fabricMarker of fabricMarkers) {
              fabricMarker.setSize(actualCanvasZoomFactor * MARKER_SIZE_FACTOR);
            }
          }
          zoomToPoint(canvas, factor, maxTopLeftBottomRight.middleX, maxTopLeftBottomRight.middleY, actualCanvasZoomFactor);
        }

      } else if (markers?.length === 1) {
        // ony one object (one marker) on canvas
        const firstMarker = markers[0];
        if (firstMarker.x === undefined || firstMarker.y === undefined) {
          throw new Error('marker.x and/or marker.y is not set which should not have happened.');
        }
        zoomToPoint(canvas, factor, firstMarker.x, firstMarker.y, zoomFactor);
      }
    } else {
      if (markers?.length) {
        for (const marker of markers) {
          await addCanvasMarker(canvas, markerImages, marker, factor);
        }
      }
      canvas.setZoom(factor);
    }
    canvas.requestRenderAll();
    return canvas.toDataURL({format: 'jpeg', quality: IMAGE_QUALITY});
  } finally {
    if (canvasToDispose) {
      canvasToDispose.dispose();
    }
  }
}

export function zoomToCenter(canvas: fabric.Canvas, factor: number, zoomFactor: number) {
  const center = canvas.getCenter();
  zoomToPoint(canvas, factor, center.top, center.left, zoomFactor);
}

export function zoomToPoint(canvas: fabric.Canvas, factor: number, x: number, y: number, zoomFactor: number) {
  canvas.setZoom(1);  // reset zoom so pan actions work as expected
  const viewPointWidth = canvas.getWidth() / zoomFactor;
  const viewPointHeight = canvas.getHeight() / zoomFactor;
  const imageWidth = canvas.getWidth() / factor;
  const imageHeight = canvas.getHeight() / factor;

  const bottomRightX = Math.min(x + viewPointWidth / 2, imageWidth); // Math.min ensures that the viewport is not outside the image (right)
  const bottomRightY = Math.min(y + viewPointHeight / 2, imageHeight);
  const topLeftX = Math.max(bottomRightX - viewPointWidth, 0); // Math.max with 0 ensures, that the viewport is not outside the image (left)
  const topLeftY = Math.max(bottomRightY - viewPointHeight, 0);

  canvas.absolutePan(new fabric.Point(topLeftX, topLeftY));
  canvas.setZoom(zoomFactor);
}

function isMarkerObject(fabricObject: fabric.Object): boolean {
  return 'left' in fabricObject && fabricObject.left !== undefined && fabricObject.left !== null &&
    'top' in fabricObject && fabricObject.top !== undefined && fabricObject.top !== null &&
    'width' in fabricObject && fabricObject.width !== undefined && fabricObject.width !== null &&
    'height' in fabricObject && fabricObject.height !== undefined && fabricObject.height !== null &&
    fabricObject.type === 'rect' && !('path' in fabricObject);
}

export function findObjectsTopLeftBottomRight(canvas: fabric.Canvas):
  {topLeftX: number, topLeftY: number, bottomRightX: number, bottomRightY: number, middleX: number; middleY: number, width: number, height: number} | undefined {
  let topLeftX: number|undefined;
  let topLeftY: number|undefined;
  let bottomRightX: number|undefined;
  let bottomRightY: number|undefined;
  canvas.forEachObject((element) => {
    if ('left' in element && element.left !== undefined && element.left !== null &&
      'top' in element && element.top !== undefined && element.top !== null &&
      'width' in element && element.width !== undefined && element.width !== null &&
      'height' in element && element.height !== undefined && element.height !== null) {
      let elementWidth: number, elementHeight: number;
      let elementLeft: number, elementTop: number, elementBottomRightX: number, elementBottomRightY: number;
      if (isMarkerObject(element)) {
        // for marker the element.top and element.left is actually the center of the marker object
        elementWidth = MARKER_DEFAULT_PARAMETER.rectHeight; // not a mistake, use rectHeight since there is no field rectWidth, and it is a square
        elementHeight = MARKER_DEFAULT_PARAMETER.rectHeight;
        elementLeft = element.left - (elementWidth / 2);
        elementTop = element.top - elementHeight - (elementHeight / 2);
        elementBottomRightX = element.left + (elementWidth / 2);
        elementBottomRightY = element.top + elementHeight - (elementHeight / 2);
      } else {
        elementWidth = Math.max(element.width, element.strokeWidth ?? 0.1); // width could be 0 which will cause problems later, so set it to strokeWidth or 0.1
        elementHeight = Math.max(element.height, element.strokeWidth ?? 0.1); // width could be 0 which will cause problems later, so set it to strokeWidth or 0.1
        elementLeft = element.left;
        elementTop = element.top;
        elementBottomRightX = elementLeft + elementWidth;
        elementBottomRightY = elementTop + elementHeight;
      }
      if (topLeftX === undefined || topLeftX > elementLeft) {
        topLeftX = elementLeft;
      }
      if (topLeftY === undefined || topLeftY > elementTop) {
        topLeftY = elementTop;
      }
      if (bottomRightX === undefined || bottomRightX < elementBottomRightX) {
        bottomRightX = elementBottomRightX;
      }
      if (bottomRightY === undefined || bottomRightY < elementBottomRightY) {
        bottomRightY = elementBottomRightY;
      }
    } else {
      console.warn('Element does not have left, top width, height');
    }
  });

  return topLeftX === undefined || topLeftY === undefined || bottomRightX === undefined || bottomRightY === undefined ? undefined :
    {topLeftX, topLeftY, bottomRightX, bottomRightY,
      middleX: topLeftX + ((bottomRightX - topLeftX) / 2), middleY: topLeftY + ((bottomRightY - topLeftY) / 2),
      width: bottomRightX - topLeftX, height: bottomRightY - topLeftY };
}

export function getProtocolEntryIconStatus(protocolEntry: ProtocolEntry|undefined, protocolEntryType: ProtocolEntryType | undefined): ProtocolEntryIconStatus {
  if (typeof protocolEntryType === 'undefined' || protocolEntryType?.statusFieldActive === false) {
    return ProtocolEntryIconStatus.INFO;
  }
  return getProtocolEntryStatus(protocolEntry);
}

export function getProtocolEntryStatus(protocolEntry: ProtocolEntry|undefined): ProtocolEntryIconStatus {
  switch (protocolEntry?.status) {
    case ProtocolEntryStatus.COMPANY_DONE:
      return ProtocolEntryIconStatus.ON_HOLD;
    case ProtocolEntryStatus.DONE:
      return ProtocolEntryIconStatus.DONE;
    default:
      return ProtocolEntryIconStatus.OPEN;
  }
}

export function toMarkerData(protocolEntry: ProtocolEntry, protocolEntryType: ProtocolEntryType|undefined, pdfPlanMarkerProtocolEntry: PdfPlanMarkerProtocolEntry,
                             isProtocolLayoutShort: boolean|undefined): MarkerData {
  const markerData: MarkerData = {
    id: pdfPlanMarkerProtocolEntry.id,
    title: protocolEntry.title || '',
    status: isProtocolLayoutShort ? getProtocolEntryStatus(protocolEntry) : getProtocolEntryIconStatus(protocolEntry, protocolEntryType),
    protocolEntry,
    x: pdfPlanMarkerProtocolEntry.positionX,
    y: pdfPlanMarkerProtocolEntry.positionY,
    markerType: MarkerType.OLD,
    oldMarkerSize: OldMarkerSize.DEFAULT
  };
  return markerData;
}

export function loadPdfPlanPageMarkings(canvas: fabric.Canvas, pdfPlanPageMarkings: Array<PdfPlanPageMarking|PdfPlanPageMarkingBase> | undefined) {
  if (!pdfPlanPageMarkings?.length) {
    return;
  }
  loadMarkings(canvas, pdfPlanPageMarkings.map((value) => value.markings));
}

export function loadMarkings(canvas: fabric.Canvas, markings: Array<string> | undefined) {
  if (!markings?.length) {
    return;
  }
  const fabricDataAll = {
    backgroundImage: null,
    objects: [],
    version: '4.3.1'
  };
  for (const marking of markings) {
    if (!marking) {
      continue;
    }
    let data = typeof marking === 'string' ? JSON.parse(marking) : marking;
    if (typeof data === 'string') {
      // markings are being stored as string in the database, even though is a jsonb datatype. This allows to work with both types of data.
      data = JSON.parse(data);
    }
    fabricDataAll.objects = fabricDataAll.objects.concat(data.fabricData.objects);
    fabricDataAll.version = data.fabricData.version;
  }
  canvas.loadFromJSON(fabricDataAll, canvas.renderAll.bind(canvas));
}

const FABRIC_OBJECTS_PROPERTIES_TO_SCALE = ['x', 'x1', 'x2', 'y', 'y1', 'y2', 'top', 'left', 'width', 'height', 'fontSize', 'lineHeight', 'strokeWidth'];

function scaleFabricObject(object: any, factor: number) {
  for (const property of FABRIC_OBJECTS_PROPERTIES_TO_SCALE) {
    if (property in object && object[property] && _.isNumber(object[property])) {
      object[property] = object[property] * factor;
    }
  }
  if ('path' in object && object.path && _.isArray(object.path)) {
    const pathValues: Array<Array<any>> = object.path;
    for (const pathValue of pathValues) {
      for (let i = 1; i < pathValue.length; i++) {
        if (pathValue[i] && _.isNumber(pathValue[i])) {
          pathValue[i] = pathValue[i] * factor;
        }
      }
    }
  }
  if ('objects' in object && object.objects && _.isArray(object.objects)) {
    for (const subObject of object.objects) {
      scaleFabricObject(subObject, factor);
    }
  }
}

export function scalePdfPlanPageMarkingObjects(pdfPlanPageMarking: PdfPlanPageMarkingBase, factor: number): void {
  if (!pdfPlanPageMarking.markings) {
    return;
  }

  let numberOfJsonParsed = 0;
  let data: any;
  if (typeof pdfPlanPageMarking.markings === 'string') {
    numberOfJsonParsed ++;
    data = JSON.parse(pdfPlanPageMarking.markings);
  } else {
    data = pdfPlanPageMarking.markings;
  }
  if (typeof data === 'string') {
    // markings are being stored as string in the database, even though is a jsonb datatype. This allows to work with both types of data.
    numberOfJsonParsed ++;
    data = JSON.parse(data);
  }
  if (data?.fabricData?.objects && _.isArray(data.fabricData.objects)) {
    for (const object of data.fabricData.objects) {
      scaleFabricObject(object, factor);
    }
  }
  let markingValue = data;
  for (let iJsonParse = 0; iJsonParse < numberOfJsonParsed; iJsonParse++) {
    markingValue = JSON.stringify(markingValue);
  }
  pdfPlanPageMarking.markings = markingValue;
}

export function loadPdfPlanPageMarkingsGrouped(canvas: fabric.Canvas, pdfPlanPageMarkings: Array<PdfPlanPageMarking|PdfPlanPageMarkingBase> | undefined, showMoveHoverCursor = false) {
  if (!pdfPlanPageMarkings?.length) {
    return;
  }
  for (const pdfPlanPageMarking of pdfPlanPageMarkings) {
    const marking = pdfPlanPageMarking?.markings;
    if (!marking) {
      continue;
    }
    let data = typeof marking === 'string' ? JSON.parse(marking) : marking;
    if (typeof data === 'string') {
      // markings are being stored as string in the database, even though is a jsonb datatype. This allows to work with both types of data.
      data = JSON.parse(data);
    }
    let name = '';
    if ('id' in pdfPlanPageMarking) {
      name = _.get(pdfPlanPageMarking, 'id');
    }
    fabric.util.enlivenObjects(data.fabricData.objects, (canvasObjects: Array<any>) => {
      const group = new fabric.Group(canvasObjects, {name});
      if (showMoveHoverCursor) {
        group.hoverCursor = 'move';
        group.selectable = true;
      }
      canvas.add(group);
    }, '');
  }
}
