import {BehaviorSubject, Observable, race, Subject, timer} from 'rxjs';
import {filter, map, take} from 'rxjs/operators';
import _ from 'lodash';
import {observableToPromise} from './observable-to-promise';
import {AuthTokenWithExpireAt} from '../model/auth';
import {TimeoutError, UnauthorizedError} from '../shared/errors';

interface TokenHolderOptions {
  /**
   * Indicates how long `getToken` method should wait for a new token after it will fail.
   *
   * If `< 0`, will wait forever.
   */
  failAfterMs: number;
}

const FAIL_WHEN_NO_TOKEN_AFTER_MS = 1000 * 10;

export interface TokenGetter {
  runAndInvalidateOnError<T>(fn: (token: string) => Promise<T>): Promise<T>;
  /**
   * IMPORTANT: When using this method, make sure to call `invalidateToken`, if the request is rejected with 401 error
   */
  getTokenPromise(): Promise<string>;
  invalidateToken(token: string): void;
}

export class TokenHolder implements TokenGetter {
  private tokenInvalidSubject = new Subject<void>();

  tokenInvalid$ = this.tokenInvalidSubject.asObservable();

  private tokenExpireAt = new Map<string, Date>();
  private currentTokenSubject = new BehaviorSubject<AuthTokenWithExpireAt|undefined>(undefined);

  private get currentToken(): AuthTokenWithExpireAt|undefined { return this.currentTokenSubject.value; }
  private set currentToken(token: AuthTokenWithExpireAt|undefined) { this.currentTokenSubject.next(token); }

  private options: TokenHolderOptions;

  constructor(options: Partial<TokenHolderOptions> = {}) {
    this.options = {
      ...options,
      failAfterMs: FAIL_WHEN_NO_TOKEN_AFTER_MS,
    };
  }

  getToken(): Observable<string> {
    const token$ = this.currentTokenSubject.pipe(
      filter((token) => token && token.expireAt > new Date()),
      take(1),
      map((token) => token.token)
    );
    const fail$ = timer(this.options.failAfterMs).pipe(map(() => { throw new TimeoutError('Failed to obtain new access token'); }));
    return this.options.failAfterMs < 0 ? token$ : race(fail$, token$);
  }

  getTokenPromise = (): Promise<string> => {
    return observableToPromise(this.getToken());
  };

  addToken(token: string, expireAt: Date) {
    if (expireAt > new Date() && (!this.currentToken || expireAt > this.currentToken?.expireAt)) {
      this.currentToken = {token, expireAt};
    }
    this.tokenExpireAt.set(token, expireAt);
  }

  invalidateToken(token: string) {
    this.tokenExpireAt.set(token, new Date(0));
    if (this.currentToken?.token === token) {
      const entries = _.orderBy(Array.from(this.tokenExpireAt.entries()), [1], ['desc']);
      for (const [tokenInMap, expireAt] of entries) {
        if (expireAt > new Date()) {
          this.currentToken = {token: tokenInMap, expireAt};
          return;
        }
      }
      this.currentToken = undefined;
    }
    this.tokenInvalidSubject.next();
  }

  async runAndInvalidateOnError<T>(fn: (token: string) => Promise<T>): Promise<T> {
    const token = await observableToPromise(this.getToken());
    try {
      return await fn(token);
    } catch (e) {
      if (e instanceof UnauthorizedError) {
        this.invalidateToken(token);
      }

      throw e;
    }
  }
}
