export const getBoundsOfAutodeskFragments = (
  fragmentList: Autodesk.Viewing.Private.FragmentList,
  fragIds: number[],
  resultBox: THREE.Box3 = new THREE.Box3(),
  helperBox: THREE.Box3 = new THREE.Box3()
): THREE.Box3 => {
  resultBox.makeEmpty();
  for (const fragId of fragIds) {
    fragmentList.getWorldBounds(fragId, helperBox);
    resultBox.union(helperBox);
  }

  return resultBox;
};
