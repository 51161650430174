import {Injectable} from '@angular/core';
import {AbstractProjectAwareDataService} from './abstract-project-aware-data.service';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {ProjectDataService} from './project-data.service';
import {IdType, PdfPlanMarkerProtocolEntry, PdfPlanPage, User} from 'submodules/baumaster-v2-common';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import _ from 'lodash';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';

const REST_ENDPOINT_URI = 'api/data/pdfPLanMarkerProtocolEntries';

@Injectable({
  providedIn: 'root'
})
export class PdfPlanMarkerProtocolEntryDataService extends AbstractProjectAwareDataService<PdfPlanMarkerProtocolEntry> {

  readonly dataByProtocolEntryId$: Observable<Record<IdType, PdfPlanMarkerProtocolEntry[]>> = this.data.pipe(
    map((markers) => _.groupBy(markers ?? [], 'protocolEntryId'))
  );
  readonly dataByProtocolEntryIdAcrossProjects$: Observable<Record<IdType, PdfPlanMarkerProtocolEntry[]>> = this.dataAcrossProjects$.pipe(
    map((markers) => _.groupBy(markers ?? [], 'protocolEntryId'))
  );

  constructor(http: HttpClient, storage: StorageService, authenticationService: AuthenticationService, userService: UserService, protected projectDataService: ProjectDataService,
              protected projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
              loggingService: LoggingService, integrityResolverService: IntegrityResolverService) {
    super(StorageKeyEnum.PDF_PLAN_MARKER_PROTOCOL_ENTRY, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, projectDataService, loggingService,
      projectAvailabilityExpirationService, integrityResolverService);
  }

  public getByProtocolEntry(protocolEntryId: IdType): Observable<Array<PdfPlanMarkerProtocolEntry>> {
    return this.data.pipe(
      map(entries => entries.filter(pdfPlanMarkerProtocolEntry => pdfPlanMarkerProtocolEntry.protocolEntryId === protocolEntryId))
    );
  }

  public getByPlanPageId(planPageId: IdType): Observable<Array<PdfPlanMarkerProtocolEntry>> {
    return this.data.pipe(
      map(entries => entries.filter(pdfPlanMarkerProtocolEntry => pdfPlanMarkerProtocolEntry.pdfPlanPageId === planPageId))
    );
  }

  public getByPlanPages(pdfPlanPages: Array<PdfPlanPage>|undefined): Observable<Array<PdfPlanMarkerProtocolEntry>> {
    if (!pdfPlanPages?.length) {
      return of([]);
    }
    return this.getByPlanPagesId(pdfPlanPages.map((v) => v.id));
  }

  public getByPlanPagesId(planPageIds: Array<IdType>): Observable<Array<PdfPlanMarkerProtocolEntry>> {
    return this.data.pipe(
      map(entries => entries.filter(pdfPlanMarkerProtocolEntry => planPageIds.some((pdfPlanPageId) => pdfPlanMarkerProtocolEntry.pdfPlanPageId === pdfPlanPageId)))
    );
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }

}
