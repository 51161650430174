<div class="ion-padding-horizontal sync-status ion-justify-content-center d-flex">
  <fa-icon *ngIf="!(online$ | async)" [icon]="['fal', 'wifi-slash']" class="error" title="{{'sync.status.info.noNetwork' | translate}}"></fa-icon>
  <fa-icon *ngIf="(numberOfAttachmentsInErrorUploadQueueObservable | async)" [icon]="['fal', 'exclamation-triangle']" title="{{'sync.status.info.localChanges' | translate}}"></fa-icon>
  <fa-icon *ngIf="!(dataSyncInProgressObservable | async) && (localChangesObservable | async)" [icon]="['fal', 'edit']" title="{{'sync.status.info.localChanges' | translate}}"></fa-icon>
  <span class="icon-and-progress-bar" *ngIf="(numberOfAttachmentsInUploadQueueObservable | async) || (attachmentUploadInProgressObservable | async)">
    <fa-icon *ngIf="(numberOfAttachmentsInUploadQueueObservable | async) || (attachmentUploadInProgressObservable | async)" [icon]="['fal', 'file-upload']" title="{{'sync.status.info.localChanges' | translate}}"></fa-icon>
    <ion-progress-bar *ngIf="(attachmentUploadInProgressObservable | async)" type="indeterminate" [color]="inverseColor ? 'bg-light': 'primary'"></ion-progress-bar>
  </span>
  <span class="icon-and-progress-bar" *ngIf="(attachmentSyncInProgressObservable | async)">
    <fa-icon [icon]="['fal', 'file-download']"  title="{{'sync.status.info.automaticSyncInProgress' | translate}}"></fa-icon>
    <ion-progress-bar type="indeterminate" [color]="inverseColor ? 'bg-light': 'primary'"></ion-progress-bar>
  </span>
  <span class="icon-and-progress-bar" *ngIf="(dataSyncInProgressObservable | async)">
    <fa-icon [icon]="['fal', 'sync']"  title="{{'sync.status.info.automaticSyncInProgress' | translate}}"></fa-icon>
    <ion-progress-bar type="indeterminate" [color]="inverseColor ? 'bg-light': 'primary'"></ion-progress-bar>
  </span>
</div>
