import {ColumnConfig} from '../ColumnConfig';

const calendarDayUnique = ['calendar_id', 'date', 'name'];

const CalendarDayBaseColumnSet: Array<ColumnConfig> = [
  {name: 'id', cnd: true},
  {name: 'calendar_id', prop: 'calendarId', cnd: true, uniqueWith: [calendarDayUnique]},
  {name: 'name', uniqueWith: [calendarDayUnique]},
  {name: 'date', cast: 'Timestamp', uniqueWith: [calendarDayUnique]},
  {name: 'is_active', prop: 'isActive', def: true},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'created_at', prop: 'createdAt', cast: 'Timestamp'},
  {name: 'created_by_id', prop: 'createdById', def: null}
];

export const CalendarDayColumnSet: Array<ColumnConfig> = [
  ...CalendarDayBaseColumnSet
];

export const ClientCalendarDayColumnSet: Array<ColumnConfig> = [
  ...CalendarDayBaseColumnSet
];

export const ProjectCalendarDayColumnSet: Array<ColumnConfig> = [
  ...CalendarDayBaseColumnSet
];

