import { Injectable } from '@angular/core';
import {environment} from 'src/environments/environment';
import {LoggingService} from './common/logging.service';

declare let Candu: any;

const LOG_SOURCE = 'CanduService';

@Injectable({
  providedIn: 'root'
})
export class CanduService {

  constructor(private loggingService: LoggingService) { }

  private canduAvailableGuard(): boolean {
    if (!Candu) {
      this.loggingService.warn(LOG_SOURCE, `Candu is not available in window object!`);
      return false;
    }

    return true;
  }

  private runGuarded<T>(fn: () => T): T|void {
    if (this.canduAvailableGuard()) {
      return fn();
    }
  }

  initialize() {
    this.runGuarded(() => {
      Candu.init({
        clientToken: environment.canduToken,
      });
    });
  }
}
