<ion-item #slidesContainer [hidden]="!(attachmentsObservable|async)?.length" [class.ion-hide]="!(attachmentsObservable|async)?.length" class="images slidesContainer {{backgroundCss}}" lines="none">
  <swiper-container #swiperContainer
                    [modules]="swiperModules"
                    space-between="10"
                    speed="400"
                    slides-per-view="1"
                    slides-per-group="1"
                    navigation="true"
                    [pagination]="{ clickable: true }"
                    [breakpoints]="{
      '768': {
        slidesPerView: 3,
        slidesPerGroup: 3
      },
      '576': {
        slidesPerView: 2,
        slidesPerGroup: 2
      }
    }">
    <swiper-slide *ngFor="let attachment of theAttachmentsObservable | async; let arrIndex = index">
      <app-attachment-viewer (click)="openFullScreen(attachment, arrIndex)" [attachment]="attachment"></app-attachment-viewer>
    </swiper-slide>
  </swiper-container>
</ion-item>
