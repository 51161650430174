<div class="title">
  {{ 'screen_too_small.title' | translate }}
</div>

<div class="subtitle">
  {{ 'screen_too_small.subtitle' | translate }}
</div>

<div class="image">
  <svg width="261" height="320" viewBox="0 0 261 320" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M240.941 5.09827H138.435C131.367 5.09827 125.637 10.8489 125.637 17.9426V236.138C125.637 243.232 131.367 248.983 138.435 248.983H240.941C248.009 248.983 253.738 243.232 253.738 236.138V17.9426C253.738 10.8489 248.009 5.09827 240.941 5.09827Z"
      stroke="white" stroke-width="8.62218" />
    <path d="M155.199 49.4408L222.945 112.26M222.945 49.4408L155.199 112.26" stroke="#AD003E" stroke-width="11.1804"
      stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M94.3102 155.559L92.9011 168.837C92.9011 168.837 113.55 172.414 115.393 169.758C117.181 167.103 116.477 161.033 116.477 160.599C116.477 160.111 113.875 152.253 112.574 151.711C111.22 151.223 94.3102 155.559 94.3102 155.559Z"
      fill="#D6BD96" />
    <path
      d="M122.059 113.937C122.059 114.208 124.986 128.245 126.016 132.147C127.099 136.049 126.449 152.525 123.088 154.205C119.728 155.885 111.274 150.953 110.623 143.149C109.973 135.345 122.059 113.937 122.059 113.937Z"
      fill="#D6BD96" />
    <path
      d="M118.97 123.313C118.97 123.313 127.045 116.051 126.503 109.385C125.961 102.664 122.384 95.6182 124.444 92.4206C126.449 89.223 122.709 76.8662 110.623 77.5166C98.5374 78.1127 87.2646 86.7842 82.4953 98.0029C77.726 109.222 86.4517 114.859 86.4517 114.859C86.4517 114.859 75.1246 113.341 74.6368 129.979C74.0949 146.564 84.5005 142.174 89.7575 151.17C94.9604 160.167 98.0497 164.611 105.908 159.896C113.767 155.235 119.349 147.16 118.59 141.686C117.831 136.212 117.019 126.457 118.97 123.313Z"
      fill="#5B3E1C" />
    <path
      d="M121.95 139.301C121.95 139.301 124.714 140.71 126.448 136.266C126.448 136.266 128.128 142.553 129.971 145.426C131.813 148.352 130.675 159.517 124.659 164.557C118.644 169.597 111.057 166.508 111.165 161.685C111.273 156.861 115.392 146.726 116.476 144.613C117.56 142.553 115.934 137.513 121.95 139.301Z"
      fill="#5B3E1C" />
    <path
      d="M121.679 128.462C121.679 128.462 120.704 131.387 121.137 134.26C121.571 137.132 122.005 139.897 121.029 140.331C120.053 140.765 117.777 139.625 117.886 133.176C117.94 126.726 119.349 126.293 120.921 126.13C122.492 125.968 121.896 127.649 121.679 128.462Z"
      fill="#BAA286" />
    <path
      d="M172.191 106.458C172.191 106.458 164.874 98.0028 161.243 96.4311C157.612 94.8594 147.803 88.5184 145.96 88.5184C144.117 88.5184 122.384 100.062 120.108 100.875C117.831 101.689 116.368 102.989 118.59 103.802C120.812 104.615 130.893 102.881 133.115 102.014C135.337 101.201 137.614 101.526 139.131 103.098C140.649 104.615 138.427 106.404 136.801 106.404C135.175 106.404 132.898 103.694 130.839 103.694C128.78 103.694 130.676 106.512 131.869 107.921C133.061 109.33 135.771 112.582 137.776 112.962C139.781 113.341 144.117 112.094 146.719 112.962C149.32 113.829 150.946 113.883 152.734 112.962C154.523 112.04 159.346 113.612 159.346 113.612L172.191 106.458Z"
      fill="#D6BD96" />
    <path
      d="M250.776 214.092C246.007 218.645 240.045 222.168 234.517 224.498C220.589 230.351 172.733 235.066 172.733 235.066C172.733 235.066 157.829 380.367 170.511 412.723C171.595 415.541 171.649 417.926 170.728 419.877C154.469 413.427 137.993 405.352 121.517 396.139C92.2507 379.771 63.0929 359.827 35.7237 338.961C31.1169 335.439 26.5644 331.916 22.0661 328.393V315.765C22.0661 315.765 18.8685 315.548 14.9664 313.922C13.4489 313.272 11.823 312.459 10.2513 311.321C4.1813 307.094 -1.56352 298.856 0.387553 282.868C3.69354 254.794 27.1064 202.82 40.2762 190.951C41.7937 189.596 43.1486 188.783 44.3409 188.566C55.9932 186.452 80.8694 177.293 80.8694 177.293L82.1159 169.001C82.1159 169.001 87.4272 166.291 100.543 165.966C105.962 165.858 112.683 166.129 120.921 167.158L122.276 173.77C122.276 173.77 155.336 173.391 159.943 174.312C164.549 175.179 203.571 179.949 203.571 179.949L163.465 123.096L160.972 119.574L156.962 113.937C156.962 113.937 160.159 105.645 174.955 106.512C174.955 106.512 176.906 108.084 179.941 110.523C189.263 117.948 209.153 133.556 219.071 140.06C232.295 148.731 251.752 169.543 258.472 187.211C262.591 197.888 258.093 207.155 250.776 214.092Z"
      fill="#2A92C9" />
    <path
      d="M180.049 110.523C163.411 111.498 161.026 119.574 161.026 119.574L157.016 113.937C157.016 113.937 160.213 105.645 175.009 106.512C175.063 106.512 176.96 108.084 180.049 110.523Z"
      fill="white" />
    <path
      d="M29.8704 221.03C24.0171 240.811 22.1203 315.765 22.1203 315.765C22.1203 315.765 16.0502 315.386 10.3054 311.375C8.24597 286.878 29.5994 222.005 29.8704 221.03Z"
      fill="#0D6084" />
    <path opacity="0.36"
      d="M233.433 187.59C224.816 184.068 203.679 180.003 203.679 180.003L163.574 123.151C168.506 124.289 178.153 127.757 192.135 138.38C213.109 154.259 233.108 187.048 233.433 187.59Z"
      fill="#0D6084" />
    <path opacity="0.36"
      d="M104.716 173.445C104.716 173.445 68.7293 182.821 62.9302 186.669C57.077 190.571 48.7849 190.354 44.2324 192.847C39.7341 195.286 34.5854 206.288 34.3687 206.776C34.477 206.342 37.0785 198.159 40.3845 191.005C41.902 189.65 43.2569 188.837 44.4492 188.62C56.1015 186.506 80.9777 177.347 80.9777 177.347C80.9777 177.347 91.817 173.608 104.716 173.445Z"
      fill="#0D6084" />
    <path opacity="0.36"
      d="M122.384 173.824C122.384 173.824 117.018 172.307 110.081 173.445C110.081 173.445 110.461 167.7 100.597 165.966C106.017 165.858 112.737 166.129 120.975 167.158L122.384 173.824Z"
      fill="#0D6084" />
    <path opacity="0.36"
      d="M250.776 214.093C246.007 218.645 240.045 222.168 234.517 224.498C220.589 230.352 172.733 235.067 172.733 235.067C172.733 235.067 157.829 380.368 170.511 412.723C171.595 415.541 171.649 417.926 170.728 419.877C154.469 413.428 137.993 405.352 121.517 396.139C122.926 345.248 149.808 264.983 153.006 255.119C157.233 241.95 156.312 205.204 156.312 205.204C161.027 213.659 164.333 222.168 196.58 221.626C228.501 221.084 250.343 214.201 250.776 214.093Z"
      fill="#0D6084" />
    <path opacity="0.36"
      d="M34.3145 333.054C34.7481 335.059 35.2358 337.01 35.7778 338.961C31.1711 335.439 26.6186 331.916 22.1203 328.393V315.765C22.1203 315.765 18.9227 315.548 15.0205 313.923C22.7706 197.183 46.8339 205.204 46.8339 205.204C46.8339 205.204 18.1639 261.243 34.3145 333.054Z"
      fill="#0D6084" />
    <path
      d="M57.5651 102.502L56.427 101.526C54.0965 99.6828 52.5248 96.9189 51.9828 92.9084C51.6035 90.0901 51.2241 87.7055 49.7608 86.4048C48.2433 85.0499 46.4006 85.1583 44.3411 87.2177C43.0404 88.6268 42.0107 90.7947 41.7939 92.6374L35.7781 89.8732C36.2658 87.4886 37.7292 84.1826 40.4932 81.1476C45.5876 75.5112 51.0615 75.7279 54.6385 78.9797C57.8903 81.9063 58.2154 85.8628 58.5406 89.1146C58.8658 92.3122 59.9497 94.4259 61.9008 96.1602L62.6596 96.8646L57.5651 102.502ZM62.497 109.005C60.2207 106.946 60.0039 103.911 62.0634 101.689C64.1771 99.3578 67.1579 99.3578 69.4341 101.364C71.6562 103.369 71.9272 106.458 69.8677 108.734C67.8082 110.956 64.719 111.011 62.497 109.005Z"
      fill="#5B3E1C" />
    <path
      d="M51.1694 120.656L50.2481 120.385C48.4596 119.898 46.8879 118.76 45.533 116.646C44.6116 115.128 43.7986 113.882 42.6062 113.503C41.4139 113.123 40.3842 113.665 39.7338 115.345C39.3545 116.483 39.3545 117.947 39.6254 119.031L35.5066 118.976C35.1814 117.513 35.1814 115.237 35.9944 112.798C37.4577 108.3 40.6553 107.053 43.4735 107.974C46.075 108.842 47.2673 111.009 48.2428 112.744C49.2184 114.478 50.4106 115.399 51.9281 115.887L52.5243 116.104L51.1694 120.656ZM55.6134 123.095C53.8249 122.499 52.9037 120.819 53.4999 119.031C54.096 117.188 55.776 116.429 57.6187 116.971C59.4072 117.567 60.3286 119.193 59.7325 121.036C59.0821 122.879 57.3477 123.691 55.6134 123.095Z"
      fill="#5B3E1C" />
    <path
      d="M73.4986 87.3262L73.3902 86.6216C73.1192 85.2125 73.3902 83.7492 74.4199 82.0691C75.1787 80.8768 75.7748 79.9011 75.6122 78.9798C75.5038 78.0043 74.7451 77.4624 73.3902 77.5708C72.4688 77.6792 71.4391 78.167 70.7887 78.7631L69.4338 75.8907C70.3552 75.1862 71.9269 74.4274 73.878 74.1564C77.5091 73.6687 79.4602 75.4571 79.7312 77.7334C80.0022 79.8471 78.9182 81.3646 77.9969 82.6653C77.1297 83.9118 76.8588 85.0498 77.0755 86.2963L77.1297 86.7842L73.4986 87.3262ZM73.2818 91.2283C73.065 89.765 73.9863 88.5727 75.3955 88.4101C76.913 88.1933 77.9969 89.1147 78.2137 90.578C78.4305 91.9871 77.5633 93.2336 76.0458 93.4504C74.6367 93.613 73.4986 92.6374 73.2818 91.2283Z"
      fill="#5B3E1C" />
    <path
      d="M87.2645 115.509C87.2645 115.509 84.9881 113.124 79.8394 114.479C74.6908 115.834 73.9321 117.243 73.9321 117.243C73.9321 117.243 80.8151 112.637 86.3973 116.105C91.9796 119.52 87.2645 115.509 87.2645 115.509Z"
      fill="#5B3E1C" />
    <path
      d="M84.5545 116.81C84.5545 116.81 82.5493 115.075 79.7852 115.78C77.0754 116.43 76.3167 116.81 76.3167 116.81C76.3167 116.81 81.6821 115.346 82.2241 117.46C82.766 119.574 84.5545 116.81 84.5545 116.81Z"
      fill="#5B3E1C" />
    <path
      d="M74.7992 134.261C74.7992 134.261 72.6313 139.247 75.1786 143.366C75.1786 143.366 73.3359 137.296 76.967 133.069C80.5982 128.841 74.7992 134.261 74.7992 134.261Z"
      fill="#5B3E1C" />
    <path
      d="M76.0458 136.483C76.0458 136.483 75.3955 140.656 78.1595 143.637C78.1595 143.637 76.371 139.572 77.5092 137.459C78.6473 135.291 76.0458 136.483 76.0458 136.483Z"
      fill="#5B3E1C" />
    <path
      d="M84.8799 93.4504C84.8799 93.4504 89.2157 88.9521 87.102 86.0255C84.9884 83.0989 82.0618 86.0255 82.0618 86.0255C82.0618 86.0255 85.2592 82.6111 88.4026 85.3209C91.6002 88.0307 89.7034 90.9032 89.7034 90.9032L84.8799 93.4504Z"
      fill="#5B3E1C" />
    <path
      d="M94.3099 89.6025L90.2994 88.8438C90.2994 88.8438 89.7032 84.0203 92.3046 82.1234C92.3046 82.1234 89.9742 86.0798 93.7137 87.4889C97.4533 88.898 94.3099 89.6025 94.3099 89.6025Z"
      fill="#5B3E1C" />
    <path
      d="M124.877 91.2282C124.877 91.2282 126.286 86.5673 125.582 84.2369C124.877 81.9064 123.088 80.8225 123.088 80.8225C123.088 80.8225 126.395 86.188 122.384 88.8436C118.427 91.4992 124.877 91.2282 124.877 91.2282Z"
      fill="#5B3E1C" />
    <path
      d="M121.951 84.1828C121.951 84.1828 118.428 80.6058 119.891 79.0341C121.354 77.4624 122.384 77.4082 122.384 77.4082C122.384 77.4082 116.639 77.7876 117.452 80.118C118.265 82.4485 121.951 84.1828 121.951 84.1828Z"
      fill="#5B3E1C" />
    <path opacity="0.32"
      d="M107.588 121.524C107.588 121.524 104.987 133.068 107.588 133.339C110.19 133.664 107.588 121.524 107.588 121.524Z"
      fill="#D1D1CF" />
    <path opacity="0.32"
      d="M102.06 130.954C102.06 130.954 100.055 136.482 101.464 137.782C102.873 139.083 102.06 130.954 102.06 130.954Z"
      fill="#D1D1CF" />
    <path opacity="0.32"
      d="M106.884 139.843C106.884 139.843 104.878 145.371 106.288 146.672C107.697 147.973 106.884 139.843 106.884 139.843Z"
      fill="#D1D1CF" />
    <path opacity="0.32"
      d="M87.2646 134.695C87.2646 134.695 85.2593 140.223 86.6684 141.523C88.0775 142.824 87.2646 134.695 87.2646 134.695Z"
      fill="#D1D1CF" />
    <path opacity="0.32"
      d="M119.566 118.436C119.566 118.436 117.56 123.964 118.969 125.264C120.379 126.565 119.566 118.436 119.566 118.436Z"
      fill="#D1D1CF" />
    <path opacity="0.32"
      d="M97.3451 123.747C97.3451 123.747 95.0147 130.846 96.6406 131.551C98.2665 132.256 97.3451 123.747 97.3451 123.747Z"
      fill="#D1D1CF" />
    <path opacity="0.32"
      d="M112.683 135.562C112.683 135.562 109.973 143.8 113.225 144.233C116.477 144.667 112.683 135.562 112.683 135.562Z"
      fill="#D1D1CF" />
  </svg>
</div>