import {Activity, Attachment, AttachmentReportCompany, Equipment, IdAware, IdType, Material, ProtocolEntryLocation, Report, ReportCompany} from 'submodules/baumaster-v2-common';
import {AttachmentBlob} from './attachments';
import {Observable} from 'rxjs';
import {CompanySource} from './contacts';
import {ReportTypeCode} from 'submodules/baumaster-v2-common';

export enum ReportsPageTypeEnum {
  REPORTS = 'reports',
  CONSTRUCTION_REPORTS = 'constructionReports'
}

export interface ReportTypeOrCustomReportType {
  id: IdType;
  reportTypeId: IdType;
  reportTypeCode: ReportTypeCode;
  customReportTypeId?: IdType;
  name: string;
}

export interface ReportCompanySource {
  isCompanyRemoved: boolean;
  company: CompanySource;
  reportCompany: ReportCompany;
  reportCompanyAttachments: Array<AttachmentReportCompany | AttachmentBlob>;
  reportCompanyAttachments$: Observable<Array<AttachmentReportCompany | AttachmentBlob>>;
  activities: Array<ActivitySource>;
}

export interface WithAttachmentsSource {
  attachments?: (Attachment | AttachmentBlob)[];
}

export interface ActivitySource extends Activity, WithAttachmentsSource {
  location?: ProtocolEntryLocation;
}

export interface MaterialSource extends Material, WithAttachmentsSource {}

export interface EquipmentSource extends Equipment, WithAttachmentsSource {}

export interface ReportSummary {
  numberOfCompanies: number;
  numberOfPersons: number;
  numberOfHours: number;
}

export interface ReportTypeWithHasReports extends ReportTypeOrCustomReportType {
  hasReports: boolean;
  hasClosedReports: boolean;
  hasPdfPreviews: boolean;
}

export interface ReportWithPreviews extends Report {
  hasPdfPreviews: boolean;
}

export interface ReportAttachmentEventBase {
  reportCompany?: ReportCompany;
  activity?: Activity;
  material?: Material;
  equipment?: Equipment;
}

export interface ReportAttachmentCreatedEvent extends ReportAttachmentEventBase {
  attachment: AttachmentBlob;
}

export interface ReportAttachmentDeletedEvent extends ReportAttachmentEventBase {
  attachment: Attachment;
}

export interface ReportAttachmentUpdatedEvent extends ReportAttachmentEventBase {
  attachment: Attachment | AttachmentBlob;
}

export interface ReportDiffObjectsEvent<T extends IdAware> {
  created: T[];
  updated: T[];
}
