<ion-content color="omg-card-surface" #lt="appLicenseType" appLicenseType [class.withFab]="showFab$ | async">
  <ng-container *ngIf="(folderId$ | async) as folderId">
    <app-pdf-plan-holder-list-header [editMode]="!!(editMode$ | async)" appPdfPlansSort appPdfPlansSelectAllHeader #selection="appPdfPlansSelectAllHeader" [folderId$]="folderId$"></app-pdf-plan-holder-list-header>
    <app-pdf-plan-folders *ngIf="!(editMode$ | async); else editModeTemplate">
      <div class="omg-boundary" [appLayout]="0.5" [appLayoutColumn]="true" appLayoutAlignItems="flex-start" *ngIf="(multiselectMode$ | async)">
        <div class="select-mode-info">
          {{ 'project_room.pdf_plan_holders.selection.nSelected' | translate:{count:(selectedPlanIds$ | async)?.length || 0} }}
        </div>
        <ion-button class="omg-btn-text" (click)="selection.allSelected() ? selection.unselectAll() : selection.selectAll()">{{ (selection.allSelected() ? 'project_room.pdf_plan_holders.selection.unselectAll' : 'project_room.pdf_plan_holders.selection.selectAll') | translate }}</ion-button>
      </div>
      <ng-container *ngIf="folderId === ALL_FOLDERS_PAGE_SLUG">
        <ng-container *ngIf="allPdfPlanHolders?.length; else (isFilterApplied$ | async) || (searchQuery$ | async) ? noFilterResults : emptyPdfPlanHolders">
          <ng-container *ngTemplateOutlet="pdfPlanFoldersWithPlansTemplate; context: {allPdfPlanHolders: allPdfPlanHolders}"></ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="folderId !== ALL_FOLDERS_PAGE_SLUG">
        <ng-container *ngIf="pdfPlanHolders?.length; else (isFilterApplied$ | async) || (searchQuery$ | async) ? noFilterResults : emptyPdfPlanHolders">
          <ng-container *ngTemplateOutlet="pdfPlanFolderTemplate; context: {pdfPlanHolders: pdfPlanHolders}"></ng-container>
        </ng-container>
      </ng-container>
    </app-pdf-plan-folders>
  </ng-container>
</ion-content>

<ion-footer class="ion-hide-md-down" *appFeatureEnabled="false; forConnected true; forLicenses [lt.LicenseType.VIEWER]">
  <ion-fab vertical="bottom" horizontal="end" *ngIf="showFab$ | async">
    <ion-button class="omg-btn-primary" (click)="addNewPdfPlanHolder()">
      {{ 'project_room.pdf_plan_holders.add_plan_holder' | translate }}
    </ion-button>
  </ion-fab>
</ion-footer>

<ng-template #editModeTemplate>
  <app-screen-too-small class="ion-hide-md-up" slot="fixed"></app-screen-too-small>
  <app-pdf-plan-folders class="ion-hide-md-down"
    appUseEditPlansData
    #useEditPlansData="appUseEditPlansData"
  >
    <app-pdf-plan-edit-holders
      *ngIf="(useEditPlansData.editPlansData$ | async) as editPlansData"
      [pdfPlanHolders]="editPlansData.pdfPlans"
      [tagsById]="editPlansData.tagsById"
      (cancel)="cancelEditMode()"
      (submitEdit)="handleEditModeSave($event)"
      (submitAndShare)="handleEditModeSaveAndShare($event)"
    ></app-pdf-plan-edit-holders>
  </app-pdf-plan-folders>
</ng-template>

<ng-template #pdfPlanFoldersWithPlansTemplate let-allPdfPlanHolders="allPdfPlanHolders">
  <ng-container *ngIf="(allPdfPlanHolders.length === 0) && !((isFilterApplied$ | async) || (searchQuery$ | async))">
    <ng-container *ngTemplateOutlet="emptyPdfPlanHolders"></ng-container>
  </ng-container>
  <ng-container *ngIf="(allPdfPlanHolders.length === 0) && ((isFilterApplied$ | async) || (searchQuery$ | async))">
    <ng-container *ngTemplateOutlet="noFilterResults"></ng-container>
  </ng-container>
    <app-pdf-plan-holder-list
      *ngIf="allPdfPlanHolders?.length && allPdfPlanFolders"
      [viewMode]="(multiselectMode$ | async) ? 'select' : 'read'"
      [selected]="selectedPlanIds$ | async"
      [pdfPlanHolders]="allPdfPlanHolders"
      [pdfPlanFolders]="allPdfPlanFolders"
      (selectedChange)="handleSelectedChange($event)"
      (holderClick)="handleHolderClick($event)"
      (actionsClick)="handleHolderActionsClick($event)"
      (activeChange)="handleHolderActiveChange($event)"
      (uploadVersionClick)="handleHolderUploadVersionClick($event)"
    ></app-pdf-plan-holder-list>
</ng-template>

<ng-template #pdfPlanFolderTemplate let-pdfPlanHolders="pdfPlanHolders">
  <ng-container *ngIf="(pdfPlanHolders.length === 0) && !((isFilterApplied$ | async) || (searchQuery$ | async))">
    <ng-container *ngTemplateOutlet="emptyPdfPlanHolders"></ng-container>
  </ng-container>
  <ng-container *ngIf="(pdfPlanHolders.length === 0) && ((isFilterApplied$ | async) || (searchQuery$ | async))">
    <ng-container *ngTemplateOutlet="noFilterResults"></ng-container>
  </ng-container>
  <app-pdf-plan-holder-list
    *ngIf="pdfPlanHolders"
    [viewMode]="(multiselectMode$ | async) ? 'select' : 'read'"
    [selected]="selectedPlanIds$ | async"
    [pdfPlanHolders]="pdfPlanHolders"
    (selectedChange)="handleSelectedChange($event)"
    (holderClick)="handleHolderClick($event)"
    (actionsClick)="handleHolderActionsClick($event)"
    (activeChange)="handleHolderActiveChange($event)"
    (uploadVersionClick)="handleHolderUploadVersionClick($event)"
  ></app-pdf-plan-holder-list>
</ng-template>

<ng-template #emptyPdfPlanHolders>
  <div class="no-entries">
    <app-no-content-svg [messageString]=" ((isNotSmallDevice$ | async) ? 'project_room.pdf_plan_holders.noRows' : 'project_room.pdf_plan_holders.noRowsSmallDevice') | translate"></app-no-content-svg>
  </div>
</ng-template>

<ng-template #noFilterResults>
  <div class="no-entries">
    <app-no-content-svg [messageString]="'project_room.pdf_plan_holders.noFilterResults' | translate"></app-no-content-svg>
  </div>
</ng-template>
