import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UnitLevel, User} from 'submodules/baumaster-v2-common';
import {StorageKeyEnum} from '../../shared/constants';
import {AuthenticationService} from '../auth/authentication.service';
import {LoggingService} from '../common/logging.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {StorageService} from '../storage.service';
import {UserService} from '../user/user.service';
import {AbstractProjectAwareDataService} from './abstract-project-aware-data.service';
import {ProjectDataService} from './project-data.service';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';

const REST_ENDPOINT_URI = 'api/data/unitLevels';

@Injectable({
  providedIn: 'root'
})
export class UnitLevelDataService extends AbstractProjectAwareDataService<UnitLevel> {

  constructor(http: HttpClient, storage: StorageService, authenticationService: AuthenticationService, userService: UserService, protected projectDataService: ProjectDataService,
              loggingService: LoggingService, projectAvailabilityExpirationService: ProjectAvailabilityExpirationService, integrityResolverService: IntegrityResolverService) {
    super(StorageKeyEnum.UNIT_LEVEL, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, projectDataService, loggingService,
          projectAvailabilityExpirationService, integrityResolverService, '2.30.0', ['index', 'id']);
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }

}
