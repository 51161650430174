import { ColumnConfig } from '../ColumnConfig';
import { skipColumn } from './skipColumn';

const UniqueCraftsInProfileUniq = ['profile_id', 'craft_id'];

export const ProfileCraftColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true, skip: skipColumn},
  {name: 'profile_id', prop: 'profileId', compositeKey: true, uniqueWith: [UniqueCraftsInProfileUniq]},
  {name: 'craft_id', prop: 'craftId', compositeKey: true, uniqueWith: [UniqueCraftsInProfileUniq]},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'}
];
