import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import _ from 'lodash';
import {from, Observable, of, throwError} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {IndividualNextMeetingForm, NextMeetingForm} from 'src/app/components/pdf/next-meeting/next-meeting.interface';
import {ToastDurationInMs} from 'src/app/shared/constants';
import {AttachmentKind, convertErrorToMessage, UnprocessableImageForPdfGenerationError} from 'src/app/shared/errors';
import {
  Attachment,
  AttachmentChat,
  AttachmentClient,
  AttachmentProjectBanner,
  AttachmentProjectBannerType,
  AttachmentProjectImage,
  AttachmentProtocolEntry,
  AttachmentProtocolSignature,
  AttachmentWithContent,
  Client,
  ClientAttachmentType,
  EmailSettings,
  IdAware,
  IdType,
  imageHeightByShowPicturesEnum,
  isUnitFeatureEnabledForClient,
  ONLY_ADMIN_CAN_CLOSE_PROTOCOL_CLIENT_IDS,
  ParticipantForPdfProtocol,
  PdfPlanAttachmentWithContent,
  PdfPlanMarkerProtocolEntry,
  PdfPlanPage,
  PdfPlanPageMarking,
  PdfPlanVersion,
  PdfPlanVersionQualityEnum,
  PdfProtocolGenerateData,
  PdfProtocolSendReq,
  PdfProtocolSendRes,
  PdfProtocolSetting,
  Protocol,
  ProtocolEntry,
  ProtocolEntryType,
  SendPdfProtocolAdditionalInfo,
  SendPdfProtocolOption,
  ShowPicturesEnum,
  UnitForBreadcrumbs
} from 'submodules/baumaster-v2-common';
import {extractScaleNumber, loadMarkerImages, renderPlanMarker, toMarkerData} from '../../../../submodules/baumaster-v2-common/dist/planMarker/planMarkerCanvasUtils';
import {environment} from '../../../environments/environment';
import {ProtocolEntryOrOpen} from '../../model/protocol';
import {SyncHistory} from '../../model/sync-models';
import {observableToPromise, switchMapOrDefault} from '../../utils/async-utils';
import {convertISOStringToDate} from '../../utils/date-utils';
import {AuthenticationService} from '../auth/authentication.service';
import {ClientService} from '../client/client.service';
import {ToastService} from '../common/toast.service';
import {AddressDataService} from '../data/address-data.service';
import {AttachmentChatDataService} from '../data/attachment-chat-data.service';
import {AttachmentClientDataService} from '../data/attachment-client-data.service';
import {AttachmentEntryDataService} from '../data/attachment-entry-data.service';
import {AttachmentProjectImageDataService} from '../data/attachment-project-image-data.service';
import {AttachmentProtocolSignatureDataService} from '../data/attachment-protocol-signature-data.service';
import {ClientDataService} from '../data/client-data.service';
import {CompanyDataService} from '../data/company-data.service';
import {CraftDataService} from '../data/craft-data.service';
import {CustomPdfConfigurationDataService} from '../data/custom-pdf-configuration-data.service';
import {NameableDropdownDataService} from '../data/nameable-dropdown-data.service';
import {NameableDropdownItemDataService} from '../data/nameable-dropdown-item-data.service';
import {ParticipantDataService} from '../data/participant-data.service';
import {PdfPlanPageDataService} from '../data/pdf-plan-page-data.service';
import {PdfPlanVersionDataService} from '../data/pdf-plan-version-data.service';
import {PdfProtocolSettingDataService} from '../data/pdf-protocol-setting-data.service';
import {ProfileCraftDataService} from '../data/profile-craft-data.service';
import {ProfileDataService} from '../data/profile-data.service';
import {ProjectCompanyDataService} from '../data/project-company-data.service';
import {ProjectDataService} from '../data/project-data.service';
import {ProjectProfileDataService} from '../data/project-profile-data.service';
import {ProtocolDataService} from '../data/protocol-data.service';
import {ProtocolEntryChatDataService} from '../data/protocol-entry-chat-data.service';
import {ProtocolEntryCompanyDataService} from '../data/protocol-entry-company-data.service';
import {ProtocolEntryDataService} from '../data/protocol-entry-data.service';
import {ProtocolEntryLocationDataService} from '../data/protocol-entry-location-data.service';
import {ProtocolEntryTypeDataService} from '../data/protocol-entry-type-data.service';
import {ProtocolLayoutDataService} from '../data/protocol-layout-data.service';
import {ProtocolOpenEntryDataService} from '../data/protocol-open-entry-data.service';
import {ProtocolTypeDataService} from '../data/protocol-type-data.service';
import {UserPublicDataService} from '../data/user-public-data.service';
import {SystemEventService} from '../event/system-event.service';
import {PhotoService} from '../photo/photo.service';
import {ProtocolService} from '../protocol/protocol.service';
import {SyncHistoryService} from '../sync/sync-history.service';
import {SyncStrategy} from '../sync/sync-utils';
import {SyncService} from '../sync/sync.service';
import {UserService} from '../user/user.service';
import {PdfPlanService} from './pdf-plan.service';
import {PdfProtocolCommonService} from './pdf-protocol-common.service';
import {isJpegOrPng} from '../../utils/image-utils';
import {PosthogService} from '../posthog/posthog.service';
import {AttachmentBimMarkerScreenshotDataService} from '../data/attachment-bim-marker-screenshot-data.service';
import {BimPlanService} from '../project-room/bim-plan.service';
import {AttachmentProjectBannerDataService} from '../data/attachment-project-banner-data.service';
import {UnitService} from '../unit/unit.service';

const isImage = (mime: string) => mime.startsWith('image/');
const isAttachmentImage = <T extends Attachment>(attachment: T) => isImage(attachment.mimeType);
const getEnsureImageJpegOrPngOrThrow = (attachmentKind: AttachmentKind) => <T extends Attachment>(attachment: T, base64: string|undefined): void => {
  if (!base64) {
    return;
  }
  if (!isAttachmentImage(attachment)) {
    return;
  }
  if (isJpegOrPng(base64)) {
    return;
  }

  throw new UnprocessableImageForPdfGenerationError(attachment, attachmentKind);
};

export interface NextMeetingDetails {
  nextMeeting: NextMeetingForm;
  individualNextMeetings: IndividualNextMeetingForm[];
}

const LOG_SOURCE = 'PdfProtocolService';
@Injectable({
  providedIn: 'root'
})
export class PdfProtocolService {
  constructor(private http: HttpClient,
              private pdfProtocolCommonService: PdfProtocolCommonService,
              private authenticationService: AuthenticationService,
              private syncHistoryService: SyncHistoryService,
              private photoService: PhotoService,
              private projectDataService: ProjectDataService,
              private clientService: ClientService,
              private clientDataService: ClientDataService,
              private protocolDataService: ProtocolDataService,
              private protocolEntryDataService: ProtocolEntryDataService,
              private protocolOpenEntryDataService: ProtocolOpenEntryDataService,
              private protocolEntryChatDataService: ProtocolEntryChatDataService,
              private participantDataService: ParticipantDataService,
              private attachmentEntryDataService: AttachmentEntryDataService,
              private attachmentChatDataService: AttachmentChatDataService,
              private pdfPlanPageDataService: PdfPlanPageDataService,
              private protocolTypeDataService: ProtocolTypeDataService,
              private protocolLayoutDataService: ProtocolLayoutDataService,
              private protocolEntryTypeDataService: ProtocolEntryTypeDataService,
              private companyDataService: CompanyDataService,
              private craftDataService: CraftDataService,
              private protocolEntryLocationDataService: ProtocolEntryLocationDataService,
              private nameableDropdownDataService: NameableDropdownDataService,
              private profileDataService: ProfileDataService,
              private addressDataService: AddressDataService,
              private attachmentClientDataService: AttachmentClientDataService,
              private attachmentProjectImageDataService: AttachmentProjectImageDataService,
              private profileCraftDataService: ProfileCraftDataService,
              private nameableDropdownItemDataService: NameableDropdownItemDataService,
              private userPublicDataService: UserPublicDataService,
              private syncService: SyncService,
              private projectCompanyDataService: ProjectCompanyDataService,
              private projectProfileDataService: ProjectProfileDataService,
              private systemEventService: SystemEventService,
              private customPdfConfigurationDataService: CustomPdfConfigurationDataService,
              private attachmentProtocolSignatureDataService: AttachmentProtocolSignatureDataService,
              private protocolEntryCompanyDataService: ProtocolEntryCompanyDataService,
              private protocolService: ProtocolService,
              private pdfPlanService: PdfPlanService,
              private pdfPlanVersionDataService: PdfPlanVersionDataService,
              private toastService: ToastService,
              private pdfProtocolSettingDataService: PdfProtocolSettingDataService,
              private userService: UserService,
              private posthogService: PosthogService,
              private bimPlanService: BimPlanService,
              private attachmentBimMarkerScreenshotDataService: AttachmentBimMarkerScreenshotDataService,
              private attachmentProjectBannerDataService: AttachmentProjectBannerDataService,
              private unitService: UnitService,
  ) {
  }

  /**
   * This method **does not** check if the user has a proper license, nor if the protocol is closed.
   */
  public canCloseProtocol$(protocolId: IdType): Observable<boolean> {
    return this.protocolService.getProjectByProtocolId(protocolId).pipe(
      switchMap((project) => {
        if (!project) {
          return throwError(new Error(`Project or protocol not found for protocol ${protocolId}`));
        }
        if (ONLY_ADMIN_CAN_CLOSE_PROTOCOL_CLIENT_IDS.includes(project.clientId)) {
          return this.userService.isCurrentUserAdmin$;
        }
        return of(true);
      })
    );
  }

  /**
   * This method **does not** check if the user has a proper license.
   */
  public canOpenProtocol$(protocolId: IdType): Observable<boolean|undefined> {
    return this.protocolService.canOpenProtocol$(protocolId);
  }

  public canCloseProtocol(protocolId: IdType): Promise<boolean> {
    return observableToPromise(this.canCloseProtocol$(protocolId));
  }

  public canOpenProtocol(protocolId: IdType): Promise<boolean> {
    return observableToPromise(this.canOpenProtocol$(protocolId));
  }

  public async closeProtocol(protocolId: IdType): Promise<boolean> {
    if (!(await this.canCloseProtocol(protocolId))) {
      throw new Error(`Cannot close protocol, canCloseProtocol(${protocolId}) refused to close it.`);
    }
    const protocol = await observableToPromise(this.protocolDataService.getById(protocolId));
    if (!protocol) {
      throw new Error(`Unable to find protocol with id ${protocolId}.`);
    }
    const pdfProtocolSetting = await observableToPromise(this.pdfProtocolSettingDataService.getByProtocolTypeId(protocol.typeId));
    const pdfProtocolSendReq: PdfProtocolSendReq = {
      sendPdfProtocolOption: 1,
      sendPdfProtocolAdditionalInfo: {},
      pdfProtocolSetting
    };
    await this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
    const url = await this.getCloseProtocolUrl(protocol);
    try {
      await observableToPromise(this.http.post(url, pdfProtocolSendReq));

      this.posthogService.captureEvent('[Protocols][State] Close', {
        type: 'menu'
      });
    } catch (error) {
      this.systemEventService.logErrorEvent(LOG_SOURCE + ' - closeProtocol', error?.userMessage + '-' + error?.message);
      await this.toastService.toastWithTranslateParams('protocol.toast.closeError', {message: convertErrorToMessage(error)}, ToastDurationInMs.ERROR);
      return false;
    } finally {
      this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
    }
    return true;
  }

  public async openProtocol(protocolId: IdType): Promise<boolean> {
    const protocol = await observableToPromise(this.protocolDataService.getById(protocolId));
    if (!protocol) {
      throw new Error(`Unable to find protocol with id ${protocolId}.`);
    }
    await this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
    if (!(await observableToPromise(this.protocolService.canOpenProtocol$(protocolId)))) {
      await this.toastService.error('protocol.toast.cannotOpenError');
      return;
    }
    const url = await this.getOpenProtocolUrl(protocol);
    try {
      await observableToPromise(this.http.post(url, {}));

      this.posthogService.captureEvent('[Protocols][State] Re-Opened', {});
    } catch (error) {
      this.systemEventService.logErrorEvent(LOG_SOURCE + ' - openProtocol', convertErrorToMessage(error));
      await this.toastService.toastWithTranslateParams('protocol.toast.openError', {message: convertErrorToMessage(error)}, ToastDurationInMs.ERROR);
      return false;
    } finally {
      this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
    }
    return true;
  }

  public async sendPdf(
    protocolId: IdType,
    sendPdfProtocolOption: SendPdfProtocolOption,
    sendPdfProtocolAdditionalInfo: SendPdfProtocolAdditionalInfo,
    {
      pdfProtocolSetting,
      meetingDetails,
      filteredProtocolEntries,
      emailSettings,
    }: {
      pdfProtocolSetting?: PdfProtocolSetting;
      meetingDetails?: Partial<NextMeetingDetails>;
      filteredProtocolEntries?: ProtocolEntry[];
      emailSettings?: EmailSettings;
    } = {}
  ): Promise<void> {
    if (sendPdfProtocolOption === SendPdfProtocolOption.CLOSE_AND_SEND && !(await this.canCloseProtocol(protocolId))) {
      throw new Error(`Cannot send & close protocol, canCloseProtocol(${protocolId}) refused to close it.`);
    }
    const customPdfConfigs =  await observableToPromise(this.customPdfConfigurationDataService.data);
    const pdfProtocolSendReq: PdfProtocolSendReq = {
      sendPdfProtocolOption,
      sendPdfProtocolAdditionalInfo,
      pdfProtocolSetting,
      participants: await this.getParticipantsForPdfProtocol(protocolId),
      nextMeeting: meetingDetails ? {
        ...meetingDetails?.nextMeeting,
        protocolId,
      } : undefined,
      individualNextMeetings: meetingDetails?.individualNextMeetings?.map(({profile, ...meeting}) => ({
        ...meeting,
        profileId: profile.id
      })),
      customPdfConfig: _.first(customPdfConfigs),
      emailSettings,
      filteredEntryIds: filteredProtocolEntries ? filteredProtocolEntries.map(({id}) => id) : undefined,
    };
    this.systemEventService.logEvent('PdfWorkflowService.sendPdf', `Calling sendPdf (COMPACT-Layout) for protocol ${protocolId}`);
    const protocol = await observableToPromise(this.protocolDataService.getById(protocolId));
    if (!protocol) {
      throw new Error(`Unable to find protocol with id ${protocolId}.`);
    }
    const {url} = await this.getSendPdfUrl(protocol);
    try {
      await observableToPromise(this.http.post<PdfProtocolSendRes>(url, pdfProtocolSendReq));
      if (pdfProtocolSendReq.sendPdfProtocolOption === SendPdfProtocolOption.CLOSE_AND_SEND) {
        this.posthogService.captureEvent('[Protocols][State] Close', {
          type: 'send'
        });
      }
    } finally {
      this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
    }
  }

  private async getParticipantsForPdfProtocol(protocolId: IdType): Promise<ParticipantForPdfProtocol[]> {
    const participants = await observableToPromise(this.participantDataService.getByProtocolId(protocolId));
    const participantsMailingListOrPresent = participants.filter((participant) => participant.mailingList || participant.present);
    return participantsMailingListOrPresent.map((participant) => _.pick(participant, 'id', 'mailingList', 'present'));
  }

  private async getCloseProtocolUrl(protocol: Protocol): Promise<string>  {
    const currentProject = await this.projectDataService.getMandatoryCurrentProject();
    if (!currentProject) {
      throw new Error('Currently no project selected.');
    }
    return environment.serverUrl + `api/pdf/protocol/${protocol.id}/close?projectId=${currentProject.id}`;
  }

  private async getOpenProtocolUrl(protocol: Protocol): Promise<string>  {
    const currentProject = await this.projectDataService.getMandatoryCurrentProject();
    if (!currentProject) {
      throw new Error('Currently no project selected.');
    }
    return environment.serverUrl + `api/pdf/protocol/${protocol.id}/open?projectId=${currentProject.id}`;
  }

  private async getSendPdfUrl(protocol: Protocol): Promise<{url: string, wasSyncNecessary: boolean}> {
    let wasSyncNecessary = false;
    const currentProject = await this.projectDataService.getCurrentProject();
    if (!currentProject) {
      throw new Error('Currently no project selected.');
    }
    let url = environment.serverUrl + `api/pdf/protocol/${protocol.id}/send?projectId=${currentProject.id}`;
    let syncHistory = await this.syncHistoryService.getSyncHistory(protocol.projectId);
    const syncServerHistory = await this.syncHistoryService.getSyncToServerHistory(protocol.projectId);
    if (syncHistory && syncServerHistory && convertISOStringToDate(syncServerHistory.startServerTime)?.getTime() > convertISOStringToDate(syncHistory.startServerTime)?.getTime()) {
      await this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
      wasSyncNecessary = true;
      syncHistory = await this.syncHistoryService.getSyncHistory(protocol.projectId);
    }
    const since = this.getSinceParameter(syncHistory);
    if (since) {
      url += '&since=' + since;
    }
    return {url, wasSyncNecessary};
  }

  private getSinceParameter(latestSyncHistory?: SyncHistory|null): string|undefined {
    if (!latestSyncHistory?.startServerTime) {
      return undefined;
    }
    return typeof latestSyncHistory.startServerTime === 'string' ? latestSyncHistory.startServerTime : latestSyncHistory.startServerTime.toString();
  }

  public async generatePdfPreview(protocolId: IdType, sendPdfProtocolOption: SendPdfProtocolOption, sendPdfProtocolAdditionalInfo: SendPdfProtocolAdditionalInfo,
                                  pdfProtocolSetting?: PdfProtocolSetting, meetingDetails?: Partial<NextMeetingDetails>,
                                  filteredProtocolEntries?: ProtocolEntry[],
                                  abortSignal?: AbortSignal): Promise<{blob: Blob, attachmentsMissingContent: Array<Attachment>}|undefined> {
    const customPdfConfigs =  await observableToPromise(this.customPdfConfigurationDataService.data);
    if (abortSignal?.aborted) {
      return undefined;
    }
    const pdfProtocolSendReq: PdfProtocolSendReq = {
      sendPdfProtocolOption,
      sendPdfProtocolAdditionalInfo,
      pdfProtocolSetting,
      nextMeeting: meetingDetails ? {
        ...meetingDetails?.nextMeeting,
        protocolId,
      } : undefined,
      individualNextMeetings: meetingDetails?.individualNextMeetings?.map(({profile, ...meeting}) => ({
        ...meeting,
        profileId: profile.id
      })),
      customPdfConfig: _.first(customPdfConfigs)
    };
    this.systemEventService.logEvent('PdfWorkflowService.generatePdfPreview', `Generating PDF-Preview of protocol ${protocolId}`);
    const data = await this.getData(protocolId, pdfProtocolSetting, false, filteredProtocolEntries?.map((value) => value.id));
    if (abortSignal?.aborted) {
      return undefined;
    }
    data.filteredProtocolEntries = filteredProtocolEntries;
    const blob = await this.pdfProtocolCommonService.generatePdf(pdfProtocolSendReq, data);
    return {blob, attachmentsMissingContent: data.attachmentsMissingContent};
  }

  public async getData(protocolId: IdType, pdfProtocolSetting: PdfProtocolSetting, acrossClientsOrProjects = false,
                       filteredProtocolEntries?: IdType[], isGlobalSearch?: boolean): Promise<PdfProtocolGenerateData> {
    const protocol = await observableToPromise(acrossClientsOrProjects ? this.protocolDataService.getByIdAcrossProjects(protocolId) : this.protocolDataService.getById(protocolId));
    const project = await observableToPromise(acrossClientsOrProjects ? this.projectDataService.getByIdAcrossClients(protocol.projectId) : this.projectDataService.getById(protocol.projectId));
    const projectAddress = await observableToPromise(this.addressDataService.getById(project.addressId));
    const client = await observableToPromise(this.clientDataService.getById(project.clientId));
    let protocolEntries = await observableToPromise(acrossClientsOrProjects ?
      this.protocolEntryDataService.getProtocolEntryOrOpenByProtocolIdAcrossProjects(protocolId) : this.protocolEntryDataService.getProtocolEntryOrOpenByProtocolId(protocolId));
    let protocolOpenEntries = await observableToPromise(acrossClientsOrProjects ?
      this.protocolOpenEntryDataService.getByProtocolIdAcrossProjects(protocolId) : this.protocolOpenEntryDataService.getByProtocolId(protocolId));
    if (filteredProtocolEntries?.length) {
      const filteredProtocolEntryIdSet = new Set(filteredProtocolEntries);
      const filteredEntries = protocolEntries.filter((protocolEntry) => filteredProtocolEntryIdSet.has(protocolEntry.id));
      const filteredEntriesParentIdSet = new Set(filteredEntries.filter((entry) => entry.parentId).map(({parentId}) => parentId));
      protocolEntries = protocolEntries.filter((protocolEntry) => filteredProtocolEntryIdSet.has(protocolEntry.id) || filteredEntriesParentIdSet.has(protocolEntry.id));
      protocolOpenEntries = protocolOpenEntries.filter(
        (protocolEntry) => filteredProtocolEntryIdSet.has(protocolEntry.protocolEntryId) || filteredEntriesParentIdSet.has(protocolEntry.protocolEntryId)
      );
    }
    const protocolEntryCompanies = await observableToPromise(acrossClientsOrProjects
      ? this.protocolEntryCompanyDataService.findAllByProtocolEntryIdsAcrossProjects(protocolEntries.map(({id}) => id))
      : this.protocolEntryCompanyDataService.findAllByProtocolEntryIds(protocolEntries.map(({id}) => id))
    );
    const protocolEntryIds = protocolEntries.map((protocolEntry) => protocolEntry.id);
    const protocolIdsOfOpenEntries = protocolEntries.filter((protocolEntryFilter) => !!protocolEntryFilter.originalProtocolId).map((protocolEntryMap) => protocolEntryMap.originalProtocolId);
    const authenticatedUserId = await observableToPromise(this.authenticationService.authenticatedUserId$);
    const protocolEntryChats = await observableToPromise(acrossClientsOrProjects ?
      this.protocolEntryChatDataService.getByProtocolEntriesAcrossProjects(protocolEntryIds) : this.protocolEntryChatDataService.getByProtocolEntries(protocolEntryIds));
    const {pdfPlanMarkerProtocolEntries, pdfPlanPageMarkings} = await observableToPromise(this.pdfPlanService.getLatestMarkers(protocolEntryIds, acrossClientsOrProjects));
    const pdfPlanPageIds = _.uniq(pdfPlanMarkerProtocolEntries.map((pdfPlanMarker) => pdfPlanMarker.pdfPlanPageId)
      .concat(pdfPlanPageMarkings.map((pdfPlanPageMarking) => pdfPlanPageMarking.pdfPlanPageId)));
    const participants = await observableToPromise(acrossClientsOrProjects ?
      this.participantDataService.getByProtocolIdAcrossProjects(protocolId) : this.participantDataService.getByProtocolId(protocolId));
    const createdInProtocolIds = new Set(protocolEntries.filter(({createdInProtocolId}) => createdInProtocolId).map(({createdInProtocolId}) => createdInProtocolId));

    const protocolMaps = new Map<IdType, Protocol>();
    for (const protocolIdOfOpenEntry of [...protocolIdsOfOpenEntries, ...Array.from(createdInProtocolIds.values())]) {
      const originalProtocol = await observableToPromise(acrossClientsOrProjects ?
        this.protocolDataService.getByIdAcrossProjects(protocolIdOfOpenEntry) : this.protocolDataService.getById(protocolIdOfOpenEntry));
      if (originalProtocol !== undefined && !protocolMaps.has(originalProtocol.id)) {
        protocolMaps.set(originalProtocol.id, originalProtocol);
      }
    }

    // bim
    const bimMarkers = await observableToPromise(this.bimPlanService.getLatestMarkers$(protocolEntryIds, acrossClientsOrProjects));
    const bimMarkerIds = bimMarkers.map((bimMarker) => bimMarker.id);

    // attachments
    const attachmentsMissingContent = new Array<Attachment>();
    const attachmentBimMarkerScreenshots = await observableToPromise(this.attachmentBimMarkerScreenshotDataService.getByBimMarkerIds$(bimMarkerIds, acrossClientsOrProjects)
      .pipe(switchMap((values) => this.photoService.toAttachmentWithContent(values, 'image', attachmentsMissingContent)),
      map((attachments) => new Map(attachments.map((a) => [a.attachment.bimMarkerId, a])))
    ));
    let attachmentProtocolEntriesWithContent: Array<AttachmentWithContent<AttachmentProtocolEntry>> | undefined;
    let attachmentChatsWithContent: Array<AttachmentWithContent<AttachmentChat>> | undefined;
    let pdfPlanPagesWithContent: Array<PdfPlanAttachmentWithContent> | undefined;
    let pdfPlanVersions: Array<PdfPlanVersion> | undefined;
    if (pdfProtocolSetting === undefined ||  pdfProtocolSetting.showPictures === ShowPicturesEnum.MEDIUM ||
        pdfProtocolSetting.showPictures === ShowPicturesEnum.SMALL || pdfProtocolSetting.showPictures === ShowPicturesEnum.LARGE) {
      const attachmentProtocolEntries = await observableToPromise(acrossClientsOrProjects ?
        this.attachmentEntryDataService.getByProtocolEntriesAcrossProjects(protocolEntryIds) : this.attachmentEntryDataService.getByProtocolEntries(protocolEntryIds));
      attachmentProtocolEntriesWithContent = await this.photoService.toAttachmentWithContent(attachmentProtocolEntries, 'thumbnail', attachmentsMissingContent, {
        shouldPopulateContentBase64: isAttachmentImage,
        validateContentBase64: getEnsureImageJpegOrPngOrThrow('entry'),
      });

      if (pdfProtocolSetting === undefined || pdfProtocolSetting.showEntryCommentPictures) {
        const attachmentChats = await observableToPromise(acrossClientsOrProjects ?
          this.attachmentChatDataService.getByProtocolEntriesAcrossProjects(protocolEntryIds) : this.attachmentChatDataService.getByProtocolEntries(protocolEntryIds));
        attachmentChatsWithContent = await this.photoService.toAttachmentWithContent(attachmentChats, 'thumbnail', attachmentsMissingContent, {
          shouldPopulateContentBase64: isAttachmentImage,
          validateContentBase64: getEnsureImageJpegOrPngOrThrow('entryChat'),
        });
      }
    }

    const protocolEntryTypes = await this.toMap(acrossClientsOrProjects ? this.protocolEntryTypeDataService.dataAcrossClients$ : this.protocolEntryTypeDataService.data);
    if ((pdfPlanMarkerProtocolEntries.length || pdfPlanPageMarkings?.length) && (pdfProtocolSetting === undefined || pdfProtocolSetting.showPdfPlanMarker)) {
      const pdfPlanPages = await observableToPromise(acrossClientsOrProjects ?
        this.pdfPlanPageDataService.getByIdsAcrossProjects(pdfPlanPageIds) : this.pdfPlanPageDataService.getByIds(pdfPlanPageIds));
      const pdfPlanVersionIds = _.compact(pdfPlanPages.map((pdfPlanPage) => pdfPlanPage.pdfPlanVersionId));
      pdfPlanVersions = await observableToPromise((acrossClientsOrProjects ?
        this.pdfPlanVersionDataService.getByIdsAcrossProjects(pdfPlanVersionIds) : this.pdfPlanVersionDataService.getByIds(pdfPlanVersionIds)));
      pdfPlanPagesWithContent = await this.toPdfPlanAttachmentWithContent(pdfPlanVersions, pdfPlanPages, pdfPlanMarkerProtocolEntries, pdfPlanPageMarkings, protocolEntries, protocolEntryTypes,
        attachmentsMissingContent);
    }

    const attachmentClients = await this.getAttachmentClientMap(client, attachmentsMissingContent);
    const pdfProjectBanners = await this.getPdfProjectBannersMap(attachmentsMissingContent);

    let projectAttachmentImages: AttachmentWithContent<AttachmentProjectImage>;
    const attachmentProjectImages = await observableToPromise(acrossClientsOrProjects ?
      this.attachmentProjectImageDataService.getByProjectIdAcrossClients(project?.id) : this.attachmentProjectImageDataService.getByProjectId(project?.id));
    if (attachmentProjectImages.length > 0) {
      projectAttachmentImages = _.head(await this.photoService.toAttachmentWithContent(attachmentProjectImages, 'image', attachmentsMissingContent, {
        shouldPopulateContentBase64: isAttachmentImage,
        validateContentBase64: getEnsureImageJpegOrPngOrThrow('projectImage'),
      }));
    }

    let attachmentProtocolSignaturesWithContent: Array<AttachmentWithContent<AttachmentProtocolSignature>> | undefined;
    const attachmentProtocolSignatures = await observableToPromise(this.attachmentProtocolSignatureDataService.getByProtocolId(protocolId));
    if (attachmentProtocolSignatures.length > 0) {
      attachmentProtocolSignaturesWithContent = await this.photoService.toAttachmentWithContent(attachmentProtocolSignatures, 'image', attachmentsMissingContent, {
        shouldPopulateContentBase64: isAttachmentImage,
        validateContentBase64: getEnsureImageJpegOrPngOrThrow('protocolSignature'),
      });
    }

    const unitForBreadcrumbs: Array<UnitForBreadcrumbs> | undefined = isUnitFeatureEnabledForClient(client.id) ? await observableToPromise(this.unitService.unitsForBreadcrumbs$) : undefined;

    return {
      userId: authenticatedUserId,
      client,
      project,
      projectAddress,
      protocol,
      protocolEntries,
      protocolEntryChats,
      protocolEntryCompanies,
      pdfPlanMarkerProtocolEntries,
      pdfPlanPageMarkings,
      participants,
      attachmentProtocolEntries: attachmentProtocolEntriesWithContent,
      attachmentChats: attachmentChatsWithContent,
      pdfPlanPages: pdfPlanPagesWithContent,
      pdfPlanVersions,
      attachmentClients,
      pdfProjectBanners,
      attachmentProjectImage: projectAttachmentImages,
      attachmentProtocolSignatures: attachmentProtocolSignaturesWithContent,
      protocolOpenEntries,
      attachmentsMissingContent: _.uniq(attachmentsMissingContent),
      bimMarkers,
      attachmentBimMarkerScreenshots,
      unitForBreadcrumbs,
      lookup: {
        protocols: protocolMaps,
        protocolTypes: await this.toMap(acrossClientsOrProjects ? this.protocolTypeDataService.dataWithoutHiddenAcrossClients$ : this.protocolTypeDataService.dataWithoutHidden$),
        protocolLayouts: await this.toMap(this.protocolLayoutDataService.data),
        protocolEntryTypes,
        companies: await this.toMap(acrossClientsOrProjects ? this.companyDataService.dataAcrossClients$ : this.companyDataService.data),
        projectCompanies: await this.toMap(acrossClientsOrProjects ? this.projectCompanyDataService.dataAcrossProjects$ : this.projectCompanyDataService.data),
        projectProfiles: await observableToPromise(acrossClientsOrProjects ? this.projectProfileDataService.dataAcrossProjects$ : this.projectProfileDataService.data),
        crafts: await this.toMap(acrossClientsOrProjects ? this.craftDataService.dataAcrossClients$ : this.craftDataService.data),
        profileCrafts: await observableToPromise(acrossClientsOrProjects ? this.profileCraftDataService.dataAcrossClients$ : this.profileCraftDataService.data),
        userPublicData: await this.toMap(acrossClientsOrProjects ? this.userPublicDataService.dataAcrossClients$ : this.userPublicDataService.data),
        protocolEntryLocations: await this.toMap(acrossClientsOrProjects ? this.protocolEntryLocationDataService.dataAcrossClients$ : this.protocolEntryLocationDataService.data),
        nameableDropdowns: await this.toMap(acrossClientsOrProjects ? this.nameableDropdownDataService.dataAcrossClients$ : this.nameableDropdownDataService.data),
        nameableDropdownItems: await this.toMap(acrossClientsOrProjects ? this.nameableDropdownItemDataService.dataAcrossProjects$ : this.nameableDropdownItemDataService.data),
        profiles: await this.toMap(acrossClientsOrProjects ? this.profileDataService.dataAcrossClientsWithDefaultType$ : this.profileDataService.data),
        profilesByAttachedToUserId: await observableToPromise(this.profileDataService.dataGroupedByAttachedToUserId),
        addresses: await this.toMap(acrossClientsOrProjects ? this.addressDataService.dataAcrossClients$ : this.addressDataService.data),
        clients: await this.toMap(this.clientService.clients$) // we might also need the clients for all the profiles we have
      }
    } as PdfProtocolGenerateData;
  }

  public async getPdfProjectBannersMap(attachmentsMissingContent: Attachment[], forProjectId?: IdType): Promise<Map<AttachmentProjectBannerType, AttachmentWithContent<AttachmentProjectBanner>>> {
    return await observableToPromise((forProjectId ? this.attachmentProjectBannerDataService.getDataForProject$(forProjectId) : this.attachmentProjectBannerDataService.data).pipe(
      switchMapOrDefault((attachments) => from(this.photoService.toAttachmentWithContent(attachments, 'image', attachmentsMissingContent, {
        shouldPopulateContentBase64: isAttachmentImage,
        validateContentBase64: getEnsureImageJpegOrPngOrThrow('projectBanner'),
      }))),
      map((attachments) => new Map(attachments.map((att) => [att.attachment.bannerType, att])))
    ));
  }

  public async getAttachmentClientMap(client: Client, attachmentsMissingContent: Array<Attachment>): Promise<Map<ClientAttachmentType, AttachmentWithContent<AttachmentClient>>> {
    const clientAttachmentDataMap = await observableToPromise(this.attachmentClientDataService.getDataMappedById());
    const clientMapAttachments = new Map<ClientAttachmentType, AttachmentWithContent<AttachmentClient>>();
    if (clientAttachmentDataMap.has(client.pdfstartbannerId)) {
      clientMapAttachments.set('pdfStartBanner',
        _.head(await this.photoService.toAttachmentWithContent([clientAttachmentDataMap.get(client.pdfstartbannerId)], 'image', attachmentsMissingContent, {
          shouldPopulateContentBase64: isAttachmentImage,
          validateContentBase64: getEnsureImageJpegOrPngOrThrow('clientPdfStartBanner'),
        })));
    }
    if (clientAttachmentDataMap.has(client.pdfendbannerId)) {
      clientMapAttachments.set('pdfEndBanner',
        _.head(await this.photoService.toAttachmentWithContent([clientAttachmentDataMap.get(client.pdfendbannerId)], 'image', attachmentsMissingContent, {
          shouldPopulateContentBase64: isAttachmentImage,
          validateContentBase64: getEnsureImageJpegOrPngOrThrow('clientPdfEndBanner'),
        })));
    }
    if (clientAttachmentDataMap.has(client.logoId)) {
      clientMapAttachments.set('logo',
        _.head(await this.photoService.toAttachmentWithContent([clientAttachmentDataMap.get(client.logoId)], 'image', attachmentsMissingContent, {
          shouldPopulateContentBase64: isAttachmentImage,
          validateContentBase64: getEnsureImageJpegOrPngOrThrow('clientLogo'),
        })));
    }
    return clientMapAttachments;
  }

  public async toMap<T extends IdAware>(values$: Observable<Array<T>>): Promise<Map<IdType, T>> {
    const values = await observableToPromise(values$);
    const valueMap = new Map<IdType, T>();
    for (const value of values) {
      valueMap.set(value.id, value);
    }
    return valueMap;
  }

  private async toPdfPlanAttachmentWithContent(pdfPlanVersions: Array<PdfPlanVersion>, pdfPlanPages: Array<PdfPlanPage>, allPdfPlanMarkerProtocolEntries: Array<PdfPlanMarkerProtocolEntry>,
                                               pdfPlanPageMarkings: Array<PdfPlanPageMarking>, protocolEntries: Array<ProtocolEntryOrOpen>,
                                               protocolEntryTypes: Map<IdType, ProtocolEntryType>, attachmentsMissingContent: Array<Attachment>): Promise<Array<PdfPlanAttachmentWithContent>> {
    if (!pdfPlanPages?.length) {
      return [];
    }
    const attachmentsWithContent = new Array<PdfPlanAttachmentWithContent>();
    const markerImages = await loadMarkerImages();
    let attachmentObjectUrl: string|undefined;
    try {
      const protocolEntryIds = _.compact(_.uniq(allPdfPlanMarkerProtocolEntries.map((value) => value.protocolEntryId)
        .concat(pdfPlanPageMarkings.filter((value) => value.protocolEntryId).map((value) => value.protocolEntryId))));
      for (const protocolEntryId of protocolEntryIds) {
        const pdfPlanMarkerProtocolEntries = allPdfPlanMarkerProtocolEntries.filter((value) => value.protocolEntryId === protocolEntryId);
        const firstPdfPlanMarkerProtocolEntry = _.head(pdfPlanMarkerProtocolEntries);
        const pdfPlanPageMarking = pdfPlanPageMarkings.find((value) => value.protocolEntryId === protocolEntryId);
        const markings = _.compact([pdfPlanPageMarking?.markings]);

        const protocolEntry = protocolEntries.find((value) => value.id === protocolEntryId);
        const protocolEntryType = protocolEntryTypes.get(protocolEntry.typeId);
        const pdfPlanPage = pdfPlanPages.find((value) => value.id === firstPdfPlanMarkerProtocolEntry?.pdfPlanPageId || value.id === pdfPlanPageMarking?.pdfPlanPageId);
        const isProtocolLayoutShort = await observableToPromise(this.protocolService.getIsProtocolLayoutShort$(protocolEntry.protocolId));
        const markers = pdfPlanMarkerProtocolEntries.map((pdfPlanMarkerProtocolEntry) => toMarkerData(protocolEntry, protocolEntryType, pdfPlanMarkerProtocolEntry, isProtocolLayoutShort));

        const attachmentBlob = await this.photoService.downloadAttachment(pdfPlanPage, 'image');
        if (attachmentObjectUrl) {
          URL.revokeObjectURL(attachmentObjectUrl);
          attachmentObjectUrl = undefined;
        }
        if (!attachmentBlob) {
          attachmentsMissingContent.push(pdfPlanPage);
          attachmentsWithContent.push({
            attachment: pdfPlanPage,
            protocolEntryId: protocolEntry.id,
            pdfPlanMarkerProtocolEntryId: firstPdfPlanMarkerProtocolEntry?.id,
            pdfPlanPageMarkingId: pdfPlanPageMarking?.id,
            contentBase64: undefined,
            secondContentBase64: undefined
          });
          continue;
        }
        const imageHeight = imageHeightByShowPicturesEnum[ShowPicturesEnum.LARGE];
        attachmentObjectUrl = URL.createObjectURL(attachmentBlob);

        const pdfPlanVersion = pdfPlanVersions.find((value) => value.id === pdfPlanPage.pdfPlanVersionId);
        if (!pdfPlanVersion) {
          throw new Error(`toPdfPlanAttachmentWithContent - Unable to find pdfPlanVersion with id ${pdfPlanPage.pdfPlanVersionId} referenced by pdfPlanPage ${pdfPlanPage.id}`);
        }
        const pdfPlanVersionQuality = pdfPlanVersion.quality || PdfPlanVersionQualityEnum.DEFAULT;
        const scaleNumber = extractScaleNumber(pdfPlanVersion.scale);
        const contentFullBase64 = await renderPlanMarker(attachmentObjectUrl, markers, markerImages, markings,  {viewHeight: imageHeight, pdfPlanVersionQuality, scaleNumber});
        const contentZoomBase64 = await renderPlanMarker(attachmentObjectUrl, markers, markerImages, markings, {viewHeight: imageHeight, zoomToMarker: true, pdfPlanVersionQuality, scaleNumber});
        attachmentsWithContent.push({
          attachment: pdfPlanPage,
          protocolEntryId: protocolEntry.id,
          pdfPlanMarkerProtocolEntryId: firstPdfPlanMarkerProtocolEntry?.id,
          contentBase64: contentFullBase64,
          secondContentBase64: contentZoomBase64
        });
      }

      return attachmentsWithContent;
    } finally {
      if (attachmentObjectUrl) {
        URL.revokeObjectURL(attachmentObjectUrl);
      }
    }
  }

}
