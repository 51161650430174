import {Component, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, of, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {PdfPlanEditEvent} from 'src/app/components/project-room/pdf-plan-folders/pdf-plan-edit-holders/pdf-plan-edit-holders.component';
import {
  HolderActiveChangeEvent,
  HolderMouseEvent,
  HolderSelectedChangeEvent,
  PdfPlanHolderListComponent
} from 'src/app/components/project-room/pdf-plan-folders/pdf-plan-holder-list/pdf-plan-holder-list.component';
import {PdfPlanVersionWithAttachment, PdfPlanWithDeletable} from 'src/app/model/pdf-plan-with-deletable';
import {LoggingService} from 'src/app/services/common/logging.service';
import {PdfPlanVersionDataService} from 'src/app/services/data/pdf-plan-version-data.service';
import {SystemEventService} from 'src/app/services/event/system-event.service';
import {PdfPlanFolderService, PdfPlanFolderWithDeletable} from 'src/app/services/project-room/pdf-plan-folder.service';
import {PdfPlanHolderActionsService} from 'src/app/services/project-room/pdf-plan-holder-actions.service';
import {PdfPlanHolderEditModeService} from 'src/app/services/project-room/pdf-plan-holder-edit-mode.service';
import {PdfPlanHolderSelectionService} from 'src/app/services/project-room/pdf-plan-holder-selection.service';
import {PdfPlanHolderService} from 'src/app/services/project-room/pdf-plan-holder.service';
import {PdfPlanVersionAccessService} from 'src/app/services/project-room/pdf-plan-version-access.service';
import {PdfPlansFilterService} from 'src/app/services/project-room/pdf-plans-filter.service';
import {PdfPlansFilteredDataService} from 'src/app/services/project-room/pdf-plans-filtered-data.service';
import {TagService} from 'src/app/services/tags/tag.service';
import {Breakpoints, DeviceService} from 'src/app/services/ui/device.service';
import {ALL_FOLDERS_PAGE_SLUG} from 'src/app/shared/constants';
import {combineLatestAsync, observableToPromise} from 'src/app/utils/async-utils';
import {trackById} from 'src/app/utils/track-by-id';
import {IdType, PdfPlan, PdfPlanFolder, PdfPlanVersionAccessType} from 'submodules/baumaster-v2-common';
import {OmgToastService} from '../../../services/ui/omg-toast.service';
import {convertErrorToMessage} from '../../../shared/errors';

interface PdfPlanFolderWithHolders {
  id: IdType;
  pdfPlanFolder: PdfPlanFolder;
  pdfPlanHolders: PdfPlanWithDeletable[];
}

const LOG_SOURCE = 'ProjectRoomPdfPlanFolderPage';

@Component({
  selector: 'app-project-room-pdf-plan-folder',
  templateUrl: './project-room-pdf-plan-folder.page.html',
  styleUrls: ['./project-room-pdf-plan-folder.page.scss'],
})
export class ProjectRoomPdfPlanFolderPage implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();
  readonly trackById = trackById;
  readonly ALL_FOLDERS_PAGE_SLUG = ALL_FOLDERS_PAGE_SLUG;
  folderId$: Observable<string | undefined> = this.route.params.pipe(map(({folderId}) => folderId));
  readonly acceptedMimeTypesForUpload = this.pdfPlanHolderService.acceptedMimeTypesForUpload;
  private pdfPlanHolders$ = this.pdfPlansFilteredDataService.getPdfPlansForFolder$(this.folderId$);
  private allPdfPlanHolders$ = this.pdfPlansFilteredDataService.getPdfPlanFoldersWithPlans$(this.folderId$).pipe(
    map(folderarray => folderarray.map(planFolder => planFolder.pdfPlanHolders).reduce((acc, curr) => [...acc, ...curr], [])));
  private allPdfPlanFolders$ = this.pdfPlanFolderService.getPdfPlanFolderWithDeletable$(of(undefined));

  editMode$ = this.pdfPlanHolderEditModeService.editMode$;
  selectedPlanIds$ = this.pdfPlanHolderSelectionService.selectedSet$.pipe(
    map((set) => Array.from(set.values()))
  );
  multiselectMode$ = this.pdfPlanHolderSelectionService.multiselectMode$;
  showFab$ = combineLatestAsync([
    this.pdfPlanHolderSelectionService.multiselectMode$,
    this.pdfPlanHolderEditModeService.editMode$
  ]).pipe(map(([editMode, multiselectMode]) => !editMode && !multiselectMode));
  selectedPdfPlanHolders$ = this.pdfPlanHolderSelectionService.selected$;

  isFilterApplied$ = this.pdfPlansFilterService.hasFilter$;
  searchQuery$: Observable<string | undefined> = this.route.queryParams.pipe(map(({q}) => q));
  isNotSmallDevice$ = this.deviceService.isAboveBreakpoint(Breakpoints.sm);

  @ViewChildren(PdfPlanHolderListComponent)
  holderListComponents: QueryList<PdfPlanHolderListComponent>;
  allPdfPlanFolders: PdfPlanFolderWithDeletable[] | undefined;
  allPdfPlanHolders: PdfPlanWithDeletable[] | undefined;
  pdfPlanHolders: PdfPlanWithDeletable[] | undefined;

  constructor(
    private route: ActivatedRoute,
    private pdfPlanHolderService: PdfPlanHolderService,
    private router: Router,
    private systemEventService: SystemEventService,
    private pdfPlanHolderEditModeService: PdfPlanHolderEditModeService,
    private pdfPlanHolderSelectionService: PdfPlanHolderSelectionService,
    private loggingService: LoggingService,
    private pdfPlanVersionDataService: PdfPlanVersionDataService,
    private tagService: TagService,
    private pdfPlanHolderActionsService: PdfPlanHolderActionsService,
    private pdfPlansFilteredDataService: PdfPlansFilteredDataService,
    private toastService: OmgToastService,
    private pdfPlanFolderService: PdfPlanFolderService,
    private pdfPlansFilterService: PdfPlansFilterService,
    private deviceService: DeviceService,
    private pdfPlanVersionAccessService: PdfPlanVersionAccessService,
  ) {}

  ngOnInit() {
    combineLatestAsync([this.pdfPlanHolders$, this.allPdfPlanFolders$, this.allPdfPlanHolders$]).pipe(takeUntil(this.destroy$))
      .subscribe(([pdfPlanHolders, allPdfPlanFolders, allPdfPlanHolders]) => {
        this.pdfPlanHolders = pdfPlanHolders;
        this.allPdfPlanFolders = allPdfPlanFolders;
        this.allPdfPlanHolders = allPdfPlanHolders;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  areAllFoldersEmpty(pdfPlanFolders: PdfPlanFolderWithHolders[]) {
    return pdfPlanFolders.every((folder) => folder.pdfPlanHolders.length === 0);
  }

  async addNewPdfPlanHolder() {
    this.systemEventService.logEvent(LOG_SOURCE, 'Opening file picker for new PDF plan');
    if (!(await observableToPromise(this.pdfPlanFolderService.getPdfPlanFolderWithDeletable$())).length) {
      if (!(await this.pdfPlanFolderService.addNewFolder(true))) {
        return;
      }
    }
    const pdfPlanFolderId = await observableToPromise(this.folderId$);
    await this.pdfPlanHolderActionsService.showUploadPlanDialog(pdfPlanFolderId === 'all' ? undefined : pdfPlanFolderId);
  }

  handleHolderClick(event: HolderMouseEvent) {
    if (!this.pdfPlanHolderSelectionService.multiselectMode) {
      if (event.version) {
        this.navigateToOldPlanMarker(event.version, event.holder);
        return;
      }
      this.navigateToPlanMarker(event.holder);
    } else {
      this.pdfPlanHolderSelectionService.toggleSelected(event.holder);
    }
  }

  async handleHolderActionsClick(event: HolderMouseEvent) {
    if (event.version) {
      await this.pdfPlanHolderActionsService.openPdfPlanVersionActions(event.event, event.version);
      return;
    }
    const holderId = event.holder.id;
    const holderActionResult = await this.pdfPlanHolderActionsService.openPdfPlanHolderActions(event.event, event.holder);
    if (holderActionResult && holderActionResult.action === 'uploadVersion' && holderActionResult.result) {
      this.holderListComponents.forEach((component) => {
        if (component.pdfPlanHolders.some(({id}) => id === holderId)) {
          const holder = component.pdfPlanHolders.find(planHolder => planHolder.id === holderId);
          if (!component.isExpanded(holderId)) {
            component.toggleExpanded(holderId, holder);
          } else {
            component.planItemHeightChanged(holder);
          }
        }
      });
    }
  }

  handleHolderActiveChange(event: HolderActiveChangeEvent) {
    this.changePdfPlanHolderActive(event.holder, event.active);
  }

  async handleHolderUploadVersionClick(event: HolderMouseEvent) {
    const holderId = event.holder.id;
    const result = (await this.pdfPlanHolderActionsService.openUploadPdfPlanVersionModal(event.holder)).result;
    if (result) {
      this.holderListComponents.forEach((component) => {
        if (component.pdfPlanHolders.some(({id}) => id === holderId)) {
          component.planItemHeightChanged(component.pdfPlanHolders.find(holder => holder.id === holderId));
        }
      });
    }
  }

  handleSelectedChange(event: HolderSelectedChangeEvent) {
    this.pdfPlanHolderSelectionService.toggleSelected(event.holder);
  }

  async cancelEditMode() {
    const editMode = this.pdfPlanHolderEditModeService.editMode;
    const result = await this.pdfPlanHolderEditModeService.safeLeaveEditMode();
    if (result === 'left' && editMode === 'single') {
      this.pdfPlanHolderSelectionService.leaveMultiselectMode();
    }
  }

  async handleEditModeSave({ pdfPlans, tagsById }: PdfPlanEditEvent) {
    try {
      await this.pdfPlanHolderService.savePdfPlans(pdfPlans);
      await this.tagService.saveGroupedTags(tagsById, 'pdfPlanVersions');
      this.pdfPlanHolderEditModeService.unsafeLeaveEditMode();
      this.pdfPlanHolderSelectionService.leaveMultiselectMode();
    } catch (error) {
      this.systemEventService.logErrorEvent('PdfPlanFolderWithHolders - handleEditModeSave', error);
      this.loggingService.error(LOG_SOURCE, `handleEditModeSave - ${convertErrorToMessage(error)}`)
      await this.toastService.error('error_saving_message');
    }
  }

  async handleEditModeSaveAndShare({ pdfPlans, tagsById }: PdfPlanEditEvent) {
    try {
      await this.pdfPlanHolderService.savePdfPlans(pdfPlans);
      await this.tagService.saveGroupedTags(tagsById, 'pdfPlanVersions');
      this.pdfPlanHolderEditModeService.unsafeLeaveEditMode();
      if (await this.pdfPlanHolderActionsService.openSharePdfPlans(pdfPlans)) {
        this.pdfPlanHolderSelectionService.leaveMultiselectMode();
      }
    } catch (error) {
      this.systemEventService.logErrorEvent('PdfPlanFolderWithHolders - handleEditModeSaveAndShare', error);
      this.loggingService.error(LOG_SOURCE, `handleEditModeSaveAndShare - ${convertErrorToMessage(error)}`)
      await this.toastService.error('error_saving_message');
    }
  }

  private async changePdfPlanHolderActive(pdfPlanHolder: PdfPlan, activeNewValue: boolean) {
    try {
      await this.pdfPlanHolderService.changePdfPlanActive(pdfPlanHolder, activeNewValue);
    } catch (error) {
      this.systemEventService.logErrorEvent('PdfPlanFolderWithHolders - changePdfPlanHolderActive', error);
      this.loggingService.error(LOG_SOURCE, `changePdfPlanHolderActive - ${convertErrorToMessage(error)}`)
      await this.toastService.error('error_saving_message');
    }
  }

  private async navigateToPlanMarker(pdfPlan: PdfPlanWithDeletable) {
    const pdfPlanVersion = pdfPlan.latestPdfPlanVersion ?? await observableToPromise(this.pdfPlanVersionDataService.getLatestByPdfPlan$(pdfPlan.id));
    if (!pdfPlanVersion) {
      this.loggingService.warn(LOG_SOURCE, `Unable to navigateToPlanMarker as there is no pdfPlanVersion for pdfPlan ${pdfPlan?.id}`);
      return;
    }
    this.trackAccess(pdfPlanVersion.id, 'view');
    await this.router.navigate(['/project-room', 'pdf-plan-markers', pdfPlanVersion.id], {
      queryParams: await observableToPromise(this.folderId$) === this.ALL_FOLDERS_PAGE_SLUG ? { wasOnAllPage: true } : undefined
    });
  }

  private async navigateToOldPlanMarker(pdfPlanVersion: PdfPlanVersionWithAttachment, pdfPlan: PdfPlanWithDeletable) {
    if (!pdfPlanVersion.id) {
      this.loggingService.warn(LOG_SOURCE, `Unable to navigateToPlanMarker as there is no pdfPlanVersion for pdfPlan ${pdfPlan?.id}`);
      return;
    }
    this.trackAccess(pdfPlanVersion.id, 'view');
    await this.router.navigate(['/project-room', 'pdf-plan-markers', pdfPlanVersion.id], {
      queryParams: await observableToPromise(this.folderId$) === this.ALL_FOLDERS_PAGE_SLUG ? { wasOnAllPage: true } : undefined
    });
  }

  private async trackAccess(pdfPlanVersionId: IdType, type: PdfPlanVersionAccessType) {
    await this.pdfPlanVersionAccessService.trackAccess(pdfPlanVersionId, type);
  }
}
