import { Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Nullish} from 'src/app/model/nullish';
import {Translatable} from 'src/app/model/translatable';

@Injectable({
  providedIn: 'root'
})
export class WithParamsTranslateService {

  constructor(private translateService: TranslateService) { }

  translate(str: Nullish<Translatable>): string|undefined {
    if (str === undefined || str === null) {
      return undefined;
    }
    if (typeof str === 'string') {
      return this.translateService.instant(str);
    }
    if ('key' in str) {
      return this.translateService.instant(str.key, str.params);
    }

    return str.doNotTranslate;
  }

}
