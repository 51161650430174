<ion-app [class.allow-expanded-menu]="isDesktop && ((isMenuExpandedOnScreen$ | async) && (sideBarSetting$ | async)?.pinned)">
  <ion-menu [disabled]="!(enableIonMenu$ | async) || (isDesktop && isMenuExpandedOnScreen$ | async)" contentId="main-content" type="overlay">
    <app-sidebar-menu [withMenuButton]="(isDesktop && (isMenuExpandedOnScreen$ | async) && (sideBarSetting$ | async)?.pinned)"></app-sidebar-menu>
  </ion-menu>
  <div class="app-split-pane">
    <ion-content color="menu" class="app-split-pane-side static-sidebar-menu" *ngIf="(pageWithSidebarMenu$ | async) && (showSidebarMenuStatically$ | async) && isAuthenticated">
      <app-sidebar-menu [withMenuButton]="true" [renderTooltips]="renderTooltips$ | async"></app-sidebar-menu>
    </ion-content>
    <div class="app-split-pane-main">
      <ion-content>
        <ion-router-outlet id="main-content"></ion-router-outlet>
      </ion-content>
    </div>
  </div>
</ion-app>
