import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {AlertController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {Observable, Subject, Subscription} from 'rxjs';
import {ProjectDataService} from 'src/app/services/data/project-data.service';
import {ProtocolDataService} from 'src/app/services/data/protocol-data.service';
import {IdType, LicenseType, Protocol, ProtocolEntryDefaultValue} from 'submodules/baumaster-v2-common';
import _ from 'lodash';
import {LoggingService} from 'src/app/services/common/logging.service';
import {ProtocolService} from 'src/app/services/protocol/protocol.service';
import {convertDateTimeToISOString, trimTimeFromDate} from 'src/app/utils/date-utils';
import {SystemEventService} from '../../../services/event/system-event.service';
import {ProtocolDeletionService} from '../../../services/protocol/protocol-deletion.service';
import {FeatureEnabledService} from 'src/app/services/feature/feature-enabled.service';
import {ToastService} from 'src/app/services/common/toast.service';
import {convertErrorToMessage} from 'src/app/shared/errors';
import {FormDirty} from 'src/app/components/protocol/protocol-entry-form/protocol-entry-form.component';
import {AlertService} from 'src/app/services/ui/alert.service';
import {ProtocolEntryDefaultValueDataService} from 'src/app/services/data/protocol-entry-default-value-data.service';
import {ProtocolEntryService} from 'src/app/services/protocol/protocol-entry.service';
import {UnitService} from '../../../services/unit/unit.service';
import {observableToPromise} from '../../../utils/async-utils';

const LOG_SOURCE = 'ProtocolDetailEditComponent';

@Component({
  selector: 'app-protocol-detail-edit',
  templateUrl: './protocol-detail-edit.component.html',
  styleUrls: ['./protocol-detail-edit.component.scss'],
})
export class ProtocolDetailEditComponent implements OnInit, OnDestroy {

  @Input() protocolId: string;
  public protocol: Protocol;
  public enabledForm = true;
  public protocolForm: UntypedFormGroup;
  private modal: HTMLIonModalElement;

  private routeSubscription: Subscription|undefined;
  private protocolDataSubscription: Subscription|undefined;
  private formDirtySubject = new Subject<FormDirty>();
  public _protocolFormDirty: FormDirty = {dirty: false};
  private saveInProgress = false;
  private allProtocolDataSubscription: Subscription|undefined;

  public isDeletableObservable?: Observable<boolean> | undefined;
  protected protocolData: Array<Protocol>;

  public readonly isEditEnabled$ = this.featureEnabledService.isFeatureEnabled$(false, true, [LicenseType.VIEWER]);

  constructor(private translateService: TranslateService,
              private toastService: ToastService,
              private projectDataService: ProjectDataService,
              private systemEventService: SystemEventService,
              private loggingService: LoggingService,
              private protocolDataService: ProtocolDataService,
              private protocolService: ProtocolService,
              private unitService: UnitService,
              private alertCtrl: AlertController,
              private router: Router,
              private protocolDeletionService: ProtocolDeletionService,
              private featureEnabledService: FeatureEnabledService,
              private alertService: AlertService,
              private protocolEntryDefaultValueDataService: ProtocolEntryDefaultValueDataService,
              private protocolEntryService: ProtocolEntryService) { }

  async ngOnInit() {
    this.initProtocol(this.protocolId);
    this.setCanDismiss();
  }

  get protocolFormDirty(): FormDirty {
    return this._protocolFormDirty;
  }

  private canDismiss = async () => {
    if (!this.protocolForm.dirty) {
      return true;
    }
    const confirmed = await this.alertService.confirm({
      header: 'protocolCreation.data_loss_header',
      message: 'protocolCreation.data_loss_message',
      confirmButton: {
        color: 'text-primary',
        fill: 'solid',
      },
      confirmLabel: 'yes',
      cancelButton: {
        fill: 'clear',
      },
      cancelLabel: 'no'
    });
    return confirmed;
  };

  private setCanDismiss() {
    this.modal.canDismiss = this.canDismiss;
  }

  set protocolFormDirty(newValue: FormDirty) {
    this.loggingService.debug(LOG_SOURCE, 'protocolFormDirty changed to ' + newValue.dirty);
    this._protocolFormDirty = newValue;
    this.loggingService.debug(LOG_SOURCE, `formDirty=${newValue} at ${new Date().toLocaleTimeString()}`);
    this.formDirtySubject.next(newValue);
  }

  initProtocol(protocolId: IdType) {
    this.unsubscribeProtocolDataSubscription();
    this.allProtocolDataSubscription = this.protocolDataService.dataWithoutHidden$.subscribe(protocols => {
      this.protocolData = protocols;
    });
    this.protocolDataSubscription = this.protocolDataService.getById(protocolId)
      .subscribe((protocol) => {
        this.protocol = protocol;
        if (protocol) {
          this.isDeletableObservable = this.protocolDeletionService.isDeletableStream(this.protocol);
        } else {
          this.isDeletableObservable = undefined;
        }
        if (!_.isEmpty(this.protocol?.closedAt)) {
          this.enabledForm = false;
        }
      });
  }

  async ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
      this.routeSubscription = undefined;
    }
    this.unsubscribeProtocolDataSubscription();
  }

  private unsubscribeProtocolDataSubscription() {
    if (this.protocolDataSubscription) {
      this.protocolDataSubscription.unsubscribe();
      this.protocolDataSubscription = undefined;
    }

    if (this.allProtocolDataSubscription) {
      this.allProtocolDataSubscription.unsubscribe();
      this.allProtocolDataSubscription = undefined;
    }
  }

  async saveProtocol() {
    if (!this.protocolFormDirty.dirty || !this.protocolForm.valid) {
      return;
    }
    await this.save();
    this.protocolFormDirty = {dirty: false};
  }

  async save() {
    if (!this.enabledForm || !(await this.featureEnabledService.isFeatureEnabled(false, true, [LicenseType.VIEWER]))) {
      return;
    }

    if (this.saveInProgress) {
      return;
    }

    this.saveInProgress = true;

    if (this.protocolForm.controls.isUnitEntryDefault.value === true && this.protocolForm.controls.unit.value &&
      (!this.protocol.unitId || this.protocol.unitId !== this.protocolForm.controls.unit.value?.id || !this.protocol.isUnitEntryDefault)) {
      if (await observableToPromise(this.unitService.hasProtocolEntriesWithEmptyUnit(this.protocol.id))) {
        const confirmUpdating = await this.alertService.confirm({
          header: 'protocol.unit.confirmUpdatingProtocolEntryUnit.title',
          message: 'protocol.unit.confirmUpdatingProtocolEntryUnit.message',
          confirmButton: {
            color: 'text-primary',
            fill: 'solid',
          },
          confirmLabel: 'yes',
          cancelLabel: 'no'
        });
        if (confirmUpdating) {
          await this.unitService.updateUnitOfProtocolEntriesWithEmptyUnit(this.protocol.id, this.protocolForm.controls.unit.value.id)
        }
      }
    }

    const logInstance = this.systemEventService.logAction(LOG_SOURCE, () => `Edit protocol (id=${
      this.protocol.id
    }, number=${this.protocol.number})`);
    try {
      const rawData = this.protocolForm.getRawValue();

      logInstance.logCheckpoint(() => `(type=${rawData.type?.name} (${rawData.type?.id}), layoutId=${rawData.type?.layoutId})`);
      const protocol: Partial<Protocol> = {
        name: rawData.name,
        number: rawData.number,
        date: convertDateTimeToISOString(trimTimeFromDate(rawData.date)),
        timeFrom: convertDateTimeToISOString(rawData.timeFrom),
        timeUntil: convertDateTimeToISOString(rawData.timeUntil),
        location: rawData.location,
        typeId: rawData.type?.id,
        sortEntriesBy: rawData.sortEntriesBy?.uniqueId,
        includesVat: rawData.includesVat,
        weather: rawData.weather,
        minTemp: rawData.temperatureMin,
        maxTemp: rawData.temperatureMax,
        humidity: rawData.humidity,
        windspeed: rawData.windspeed,
        weatherActivated: rawData.weatherActivated,
        weatherFromApi: rawData.weatherFromApi ?? false,
        unitId: rawData.unit?.id ?? null,
        isUnitEntryDefault: rawData.unit ? rawData.isUnitEntryDefault : null
      };

      this.protocol = {...this.protocol, ...protocol};

      const currentProject = await this.projectDataService.getCurrentProject();
      const {defaultChanged, protocolDefaultValue} = await this.createOrUpdateProtocolEntryDefaultValue(currentProject.id);
      if (defaultChanged && protocolDefaultValue) {
        logInstance.logCheckpoint(() => `ProtocolEntryDefaultValue created or updated (id=${protocolDefaultValue?.id} protocolId=${protocol?.id})`);
      }

      await this.protocolDataService.update(this.protocol, currentProject.id);
      this.protocolForm.markAsPristine();

      await this.toastService.savingSuccess();
      if (defaultChanged) {
        const confirmed = await this.alertService.confirm({
          header: 'protocol.defaultValues.alertHeader',
          message: 'protocol.defaultValues.alertMessage',
          confirmButton: {
            color: 'text-primary',
            fill: 'solid',
          },
          confirmLabel: 'button.apply',
          cancelButton: {
            fill: 'clear',
          },
          cancelLabel: 'no'
        });
        if (confirmed) {
          await this.protocolEntryService.updateEntriesInProtocolWithDefaultValues(protocolDefaultValue, this.protocol.id, this.protocol.projectId);
        }
      }
      await this.modal.dismiss();
      logInstance.success();
    } catch (error) {
      logInstance.failure(error);
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - saveProtocol', error?.userMessage + '-' + error?.message);
      this.loggingService.error(LOG_SOURCE, `Error saveProtocol. "${error?.userMessage}" - "${error?.message}"`);
      await this.toastService.errorWithMessageAndHeader('error_saving_message', convertErrorToMessage(error));
    } finally {
      this.saveInProgress = false;
    }
  }

  async createOrUpdateProtocolEntryDefaultValue(projectId: IdType): Promise<{defaultChanged: boolean, protocolDefaultValue: ProtocolEntryDefaultValue|undefined}> {
    const defaultValuesControl = this.protocolForm.get('defaultValues');
    const rawDefaultValue = defaultValuesControl.getRawValue();
    const newDate = new Date().toISOString();
    const defaultForProtocol = await observableToPromise(this.protocolEntryDefaultValueDataService.getByProtocolId(this.protocol.id));
    if (!defaultValuesControl.dirty) {
      return {defaultChanged: false, protocolDefaultValue: defaultForProtocol};
    }
    const protocolDefaultValue: ProtocolEntryDefaultValue = {
      id: defaultForProtocol ? defaultForProtocol.id : null,
      protocolId: this.protocol.id,
      createdAt: defaultForProtocol ? defaultForProtocol.createdAt : newDate,
      changedAt: defaultForProtocol ? defaultForProtocol.changedAt : newDate,
      companyId: rawDefaultValue.company?.id ?? null,
      priority: rawDefaultValue.priority,
      craftId: rawDefaultValue.craft?.id ?? null,
      locationId: rawDefaultValue.location?.id ?? null,
      nameableDropdownId: rawDefaultValue.nameableDropdownId ? rawDefaultValue.nameableDropdownId : null,
      internalAssignmentId: rawDefaultValue.internalAssignmentId?.id ?? null,
      observerCompanyIds: rawDefaultValue.observerCompanies,
      startDate: rawDefaultValue.startDate,
      todoUntil: rawDefaultValue.todoUntil,
      allCompanies: (rawDefaultValue.company && rawDefaultValue.company?.id === null) ? true : false
    }
    await this.protocolEntryDefaultValueDataService.insertOrUpdate(protocolDefaultValue, projectId);
    return {defaultChanged: true, protocolDefaultValue};
  }

  async deleteProtocol() {
    if (!(await this.featureEnabledService.isFeatureEnabled(false, true, [LicenseType.VIEWER]))) {
      return;
    }

    const isDeletable = await this.protocolDeletionService.isDeletable(this.protocol);
    if (!isDeletable && this.protocol.closedAt !== null) {
      await this.alertService.ok({
        header: 'protocol.notDeleteable.headerClosed',
        message: 'protocol.notDeleteable.messageClosed',
        confirmLabel: 'close'
      });
      return;
    }

    if (!isDeletable && this.protocol.closedAt === null) {
      await this.alertService.ok({
        header: 'protocol.notDeleteable.headerEntries',
        message: 'protocol.notDeleteable.messageEntries',
        confirmLabel: 'close'
      });
      return;
    }

    const alert = await this.alertCtrl.create({
      header: this.translateService.instant('protocolDeletion.confirm_header'),
      message: this.translateService.instant('protocolDeletion.confirm_message'),
      buttons: [
        {
          text: this.translateService.instant('no'),
          role: 'cancel'
        },
        {
          text: this.translateService.instant('yes'),
          handler: async () => {
            const logInstance = this.systemEventService.logAction(LOG_SOURCE, () => `Delete protocol (id=${this.protocol.id}, number=${this.protocol.number}, typeId=${this.protocol.typeId})`);
            try {
              const currentProject = await this.projectDataService.getCurrentProject();
              await this.protocolDeletionService.deleteProtocolOnServer(this.protocol, currentProject);
              await this.dismissModal();
              await this.router.navigate(['/protocols'], {replaceUrl: true});
              logInstance.success();
            } catch (e) {
              logInstance.failure(e);
              throw e;
            }
          }
        }
      ]
    });
    await alert.present();
  }

  async dismissModal(): Promise<boolean> {
    await this.modal.dismiss();
    return true;
  }

  async handleNavigateAway(routerUrl: string) {
    if (!routerUrl) {
      this.loggingService.error(LOG_SOURCE, 'Ignoring navigateAway since routerUrl is empty.');
    }
    const closed = await this.dismissModal();
    if (closed) {
      await this.router.navigate([routerUrl]);
    }
  }
}
