import { Pipe, PipeTransform } from '@angular/core';
import {SendPdfWorkflowStep} from 'src/app/model/send-protocol';

@Pipe({
  name: 'pdfStepTranslationKey',
  pure: true,
})
export class PdfStepTranslationKeyPipe implements PipeTransform {

  transform(currentStep: SendPdfWorkflowStep): string {
    if (currentStep === SendPdfWorkflowStep.MAILING_LIST) {
      return 'sendProtocol.mailingList.title';
    }
    if (currentStep === SendPdfWorkflowStep.PROTOCOL_CONFIGURATION) {
      return 'sendProtocol.protocolConfig.title';
    }
    if (currentStep === SendPdfWorkflowStep.EMAIL_SETTINGS) {
      return 'sendProtocol.emailSettings.title';
    }
    if (currentStep === SendPdfWorkflowStep.ADVANCED_SETTINGS) {
      return 'sendProtocol.advancedSettings.title';
    }

    return '';
  }

}
