import {FormControl, Validators} from '@angular/forms';
import {TwoFactorAuthenticate, TwoFactorDeviceMethod} from 'submodules/baumaster-v2-common';

export const getEmptyTwoFactorAuthenticate = (method: TwoFactorDeviceMethod): TwoFactorAuthenticate => {
  if (method === 'recovery_code') {
    return {
      code: '',
      method,
    };
  }
  if (method === 'totp') {
    return {
      code: '',
      method,
    };
  }

  throw new Error(`Unknown two factor method ${method}.`);
};

export const getConcludeCodeControl = () => new FormControl(
  '',
  [
    Validators.required,
    Validators.minLength(12),
    Validators.maxLength(14),
    Validators.pattern(/^(?:[0-9a-zA-Z]){4}[-\s]?(?:[0-9a-zA-Z]){4}[-\s]?(?:[0-9a-zA-Z]){4}$/)
  ]
);

export const getConfigureCodeControl = () => new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern(/^[0-9]{6}$/)]);
