import {CommonModule} from '@angular/common';
import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {Router} from '@angular/router';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {IonicModule, IonSearchbar, ModalController} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import _ from 'lodash';
import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import {EntryCardModel} from 'src/app/model/entry-card-model';
import {EntryCreationModeService} from 'src/app/services/entry/entry-creation-mode.service';
import {EntryThreeDotsActionsService} from 'src/app/services/entry/entry-three-dots-actions.service';
import {MoveEntryService} from 'src/app/services/entry/move-entry.service';
import {RemoveEntryService} from 'src/app/services/entry/remove-entry.service';
import {PosthogService} from 'src/app/services/posthog/posthog.service';
import {CreateEntryService} from 'src/app/services/protocol/create-entry.service';
import {ProtocolEntrySelectionService} from 'src/app/services/protocol/protocol-entry-selection.service';
import {ProtocolEntrySearchFilterService} from 'src/app/services/search/protocol-entry-search-filter.service';
import {SideBarSettingsService} from 'src/app/services/sidebar/sidebar-settings.service';
import {isPopoverDismissed, PopoverService} from 'src/app/services/ui/popover.service';
import {TooltipModule} from 'src/app/shared/module/tooltip/tooltip.module';
import {findPreviousTask} from 'src/app/utils/entry-utils';
import {getTaskPagePath} from 'src/app/utils/router-utils';
import {IdType, LicenseType, ProtocolEntry} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../../../services/common/logging.service';
import {EditEntryService} from '../../../services/entry/edit-entry.service';
import {FeatureEnabledService} from '../../../services/feature/feature-enabled.service';
import {TasksPageService} from '../../../services/tasks/tasks-page.service';
import {combineLatestAsync, observableToPromise} from '../../../utils/async-utils';
import {ProjectSelectorModule} from '../../common/project-selector/project-selector.module';
import {EntryCardSettingsComponent} from '../../entry/entry-card-settings/entry-card-settings.component';
import {EntryFilterModalComponent} from '../../entry/entry-filter-modal/entry-filter-modal.component';
import {ProtocolEntryDataService} from '../../../services/data/protocol-entry-data.service';
import {ConvertEntryService} from '../../../services/entry/convert-entry.service';
import {EntryService} from '../../../services/entry/entry.service';
import {Breakpoints, DeviceService, DISPLAY_SIZE} from 'src/app/services/ui/device.service';

const LOG_SOURCE = 'TaskCardToolbarComponent';

@Component({
  selector: 'app-task-card-toolbar',
  templateUrl: './task-card-toolbar.component.html',
  styleUrls: ['./task-card-toolbar.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    ProjectSelectorModule,
    TranslateModule,
    FontAwesomeModule,
    FormsModule,
    TooltipModule
  ]
})
export class TaskCardToolbarComponent {
  private readonly inDetailViewSubject = new BehaviorSubject(false);

  @Input()
  set inDetailView(inDetailView: boolean|undefined) {
    this.inDetailViewSubject.next(inDetailView ?? false);
  }
  get inDetailView() { return this.inDetailViewSubject.value; }

  private readonly taskIdSubject = new BehaviorSubject<IdType|null>(null);

  public readonly isEditEnabled$ = this.featureEnabledService.isFeatureEnabled$(false, true, [LicenseType.VIEWER, LicenseType.LIGHT]);

  public entryCreationModeIcon$ = this.entryCreationModeService.entryCreationModeIcon$;

  public task$ = this.taskIdSubject.pipe(switchMap((taskId) => this.protocolEntryDataService.getById(taskId)));

  @Input()
  set taskId(taskId: IdType|null) {
    if (!taskId) {
      this.taskIdSubject.next(null);
      return;
    }

    this.taskIdSubject.next(taskId);
  }

  get taskId() { return this.taskIdSubject.value; }

  @Output()
  taskCreated = new EventEmitter<ProtocolEntry>();

  @ViewChild('searchbar', {static: false}) searchbar: IonSearchbar;

  toolbarMode$: Observable<'default' | 'select'> = this.protocolEntrySelectionService.isSelectMode$.pipe(
    map((isSelectMode) => isSelectMode ? 'select' as const : 'default' as const),
    distinctUntilChanged()
  );

  isBelowSm$ = this.deviceService.isAboveMediaQuery(`(max-width: ${DISPLAY_SIZE[Breakpoints.sm]}px)`);

  toolbarClass$ = combineLatestAsync([this.toolbarMode$, this.inDetailViewSubject, this.sideBarSettingsService.sideBarSetting$]).pipe(
    map(([toolbarMode, inDetailView, sideBarSetting]) => {
      const classes = [`toolbar-${toolbarMode}`];

      if (inDetailView) {
        classes.push('in-detail-view');
      }

      if (sideBarSetting.pinned) {
        classes.push('menu-pinned');
      }

      return classes;
    })
  );
  get search() { return this.tasksPageService.search; }

  get withSubEntries() {
    return this.tasksPageService.withSubEntries;
  }

  selectedCount$ = this.protocolEntrySelectionService.selectedEntries$.pipe(
    map((entries) => entries.length),
    distinctUntilChanged()
  );

  readonly getPrevTask$: Observable<EntryCardModel|undefined> = combineLatestAsync([
    this.taskIdSubject,
    this.tasksPageService.entriesFiltered$
  ]).pipe(map(([taskId, tasks]) => {
    const currentTaskIndex = tasks.findIndex((task) => task.id === taskId);

    if (currentTaskIndex > 0) {
      return tasks[currentTaskIndex - 1];
    }

    return undefined;
  }));

  readonly hasPrevTask$ = this.getPrevTask$.pipe(map(Boolean));

  readonly getNextTask$: Observable<EntryCardModel|undefined> = combineLatestAsync([
    this.taskIdSubject,
    this.tasksPageService.entriesFiltered$
  ]).pipe(map(([taskId, tasks]) => {
    const currentTaskIndex = tasks.findIndex((task) => task.id === taskId);

    if (currentTaskIndex > -1 && currentTaskIndex < tasks.length - 1) {
      return tasks[currentTaskIndex + 1];
    }

    return undefined;
  }));

  readonly getCurrentTaskOrLast$: Observable<EntryCardModel|undefined> = combineLatestAsync([
    this.taskIdSubject,
    this.tasksPageService.entriesFiltered$
  ]).pipe(map(([taskId, tasks]) => {
    if (!tasks.length) {
      return undefined;
    }
    const currentTaskIndex = tasks.findIndex((task) => task.id === taskId);

    if (currentTaskIndex > -1 && currentTaskIndex < tasks.length - 1) {
      return tasks[currentTaskIndex + 1];
    }

    return tasks[tasks.length - 1];
  }));

  readonly hasNextTask$ = this.getNextTask$.pipe(map(Boolean));

  hasFilter$ = this.protocolEntrySearchFilterService.hasFilter$;
  sortOrderAsc$ = this.tasksPageService.sortOrderAsc$;
  showFullWidthSearchbar = false;

  constructor(
    private createEntryService: CreateEntryService,
    private protocolEntrySelectionService: ProtocolEntrySelectionService<EntryCardModel>,
    private moveEntryService: MoveEntryService,
    private removeEntryService: RemoveEntryService,
    private modalController: ModalController,
    private protocolEntrySearchFilterService: ProtocolEntrySearchFilterService,
    private tasksPageService: TasksPageService,
    private editEntryService: EditEntryService,
    private loggingService: LoggingService,
    private popoverService: PopoverService,
    private router: Router,
    private posthogService: PosthogService,
    private featureEnabledService: FeatureEnabledService,
    private entryCreationModeService: EntryCreationModeService,
    private sideBarSettingsService: SideBarSettingsService,
    private protocolEntryDataService: ProtocolEntryDataService,
    private convertEntryService: ConvertEntryService,
    private entryThreeDotsActionsService: EntryThreeDotsActionsService,
    private entryService: EntryService,
    private deviceService: DeviceService
  ) {}

  leaveSelectMode() {
    this.protocolEntrySelectionService.leaveSelectMode();
  }

  async createTask() {
    const result = await this.createEntryService.createTask({entryCreationMode: this.entryCreationModeService.entryCreationMode});
    if (result.role === 'save') {
      this.taskCreated.emit(result.entry);
    }
  }

  async createSubTask() {
    const result = await this.createEntryService.createTask(
      {entryCreationMode: this.entryCreationModeService.entryCreationMode,
        entryCreateComponentProps: {parentEntryId: this.taskId}},
    );
    if (result.role === 'save') {
      this.taskCreated.emit(result.entry);
    }
  }

  async assignTasks() {
    const selectedEntryIds = this.getSelectedTaskIds();
    if (!selectedEntryIds.length) {
      return;
    }
    await this.moveEntryService.moveTasks(selectedEntryIds);
    this.leaveSelectMode();
  }

  async assignCurrentTaskWithParent() {
    const task = await observableToPromise(this.task$);
    const entryIds = [this.taskId];
    if (task?.parentId) {
      entryIds.push(task.parentId)
    }
    const moved = await this.moveEntryService.moveTasks(entryIds);
    if (moved) {
      await this.currentTaskOrTaskList();
    }
  }

  async removeTasks() {
    const selectedEntryIds = this.getSelectedTaskIds();
    if (!selectedEntryIds.length) {
      return;
    }
    const currentTaskIdBeforeDeleting = this.taskIdSubject.value;
    const tasksBeforeDeleting = await observableToPromise(this.tasksPageService.entriesFiltered$);
    await this.removeEntryService.deleteEntries(selectedEntryIds);
    this.leaveSelectMode();
    const tasks = await observableToPromise(this.tasksPageService.entriesFiltered$);
    if (!tasks.length) {
      this.router.navigate(['/tasks', 'card']);
      return;
    }
    if (tasks.length) {
      if (tasks.some((task) => task.id === currentTaskIdBeforeDeleting)) {
        return; // current task was not deleted. No need to navigate
      }
      const taskToNavigateTo = findPreviousTask(tasks, currentTaskIdBeforeDeleting, tasksBeforeDeleting);
      if (taskToNavigateTo) {
        this.router.navigate(getTaskPagePath('', taskToNavigateTo.id));
      } else {
        this.router.navigate(['/tasks', 'card']);
      }
    }
  }

  toggleSubEntries() {
    this.tasksPageService.withSubEntries = !this.tasksPageService.withSubEntries;
    this.posthogService.captureEvent('Toggle Subtasks', {expanded: this.tasksPageService.withSubEntries});
  }

  toggleSortOrder() {
    this.tasksPageService.sortOrderAsc = !this.tasksPageService.sortOrderAsc;
    this.posthogService.captureEvent('Toggle Order Tasks', {ascending: this.tasksPageService.sortOrderAsc});
  }

  private getSelectedTaskIds(): IdType[] {
    if (this.inDetailView && !this.protocolEntrySelectionService.isSelectMode) {
      return this.taskId ? [this.taskId] : [];
    }
    return this.protocolEntrySelectionService.selectedEntries.map((task) => task.id);
  }

  private async getSelectedTaskIdsInOriginalOrderWithParent(): Promise<string[]> {
    const selectedTaskIds = this.getSelectedTaskIds();
    const tasks = await observableToPromise(this.tasksPageService.entriesFiltered$);
    const selectedTasks = tasks.filter((task) => selectedTaskIds.includes(task.id));
    const parentIdsOfSelectedSubTasks = selectedTasks.filter((task) => task.parentId).map((task) => task.parentId);
    const selectedTasksWithParents = tasks.filter((task) => selectedTaskIds.includes(task.id) || parentIdsOfSelectedSubTasks.includes(task.id));
    return selectedTasksWithParents.map((task) => task.id);
  }

  async startSendHtml() {
    const taskIds = await this.getSelectedTaskIdsInOriginalOrderWithParent();
    if (!taskIds.length) {
      return;
    }

    await this.entryThreeDotsActionsService.openSendTasksModal(taskIds);
  }

  toggleShowFullWidthSearchbar() {
    this.showFullWidthSearchbar = !this.showFullWidthSearchbar;
    if (this.showFullWidthSearchbar && this.searchbar) {
      this.searchbar.setFocus();
    }
  }

  searchbarFocusOut() {
    this.loggingService.debug(LOG_SOURCE, 'searchbarFocusOut');
    if (this.showFullWidthSearchbar) {
      this.showFullWidthSearchbar = false;
    }
    if (!_.isEmpty(this.search)) {
      this.posthogService.captureEvent('[Tasks] Searchbar used', {});
    }
  }

  handleSearchChange(value: string) {
    this.tasksPageService.search = value;
  }

  async openEntryCardSettings() {
    const modal = await this.modalController.create({
      component: EntryCardSettingsComponent,
      cssClass: 'omg-modal',
      backdropDismiss: true
    });

    await modal.present();
  }

  async massEdit() {
    const selectedEntryIds = this.getSelectedTaskIds();
    if (!selectedEntryIds.length) {
      return;
    }

    const success = await this.editEntryService.massEdit(selectedEntryIds);
    if (success) {
      this.leaveSelectMode();
    }
  }

  async openEntryFilter() {
    const modal = await this.modalController.create({
      component: EntryFilterModalComponent,
      cssClass: 'omg-modal'
    });

    await modal.present();
  }

  async openButtonActions(event: Event) {
    if (await this.entryCreationModeService.openEntryCreationModeOptions(event)) {
      await this.createTask();
    }
  }

  async openMoreActions(event: Event) {
    const result = await this.popoverService.openActions(event, [
      {
        label: 'entryCardSettings.title',
        role: 'card-settings',
        icon: ['fal', 'cog'],
      },
      {
        label: 'Sort',
        role: 'sort',
        icon: ['fal', 'sort-alt'],
      },
      {
        label: 'expand_collapse_tasks',
        role: 'subentries',
        icon: ['fal6', 'diagram-subtask'],
      },
    ]);

    if (isPopoverDismissed(result)) {
      return;
    }

    if (result === 'card-settings') {
      return this.openEntryCardSettings();
    }

    if (result === 'sort') {
      return this.toggleSortOrder();
    }

    if (result === 'subentries') {
      return this.toggleSubEntries();
    }

    throw new Error(`Unhandled three-dots menu action "${result}"`);
  }

  async openSelectActions(event: Event) {
    const result = await this.popoverService.openActions(event, [
      {
        label: 'tasks.toolbar.actions.assignToProtocol',
        role: 'assign',
        icon: ['fal', 'clipboard'],
      },
      {
        label: 'edit',
        role: 'edit',
        icon: ['fal', 'pencil'],
      },
      {
        label: 'delete',
        role: 'remove',
        icon: ['fal', 'trash-alt'],
      },
    ]);

    if (isPopoverDismissed(result)) {
      return;
    }

    if (result === 'assign') {
      return this.assignTasks();
    }

    if (result === 'edit') {
      return this.massEdit();
    }

    if (result === 'remove') {
      return this.removeTasks();
    }

    throw new Error(`Unhandled three-dots menu action "${result}"`);
  }

  async prevTask(): Promise<boolean> {
    if (!this.taskId) {
      return false;
    }
    const prevTask = await observableToPromise(this.getPrevTask$);
    if (prevTask) {
      await this.router.navigate(getTaskPagePath('', prevTask.id));
      return true;
    }
    return false;
  }

  async nextTask(): Promise<boolean> {
    if (!this.taskId) {
      return false;
    }
    const nextTask = await observableToPromise(this.getNextTask$);
    if (nextTask) {
      await this.router.navigate(getTaskPagePath('', nextTask.id));
      return true;
    }
    return false;
  }

  async currentTaskOrTaskList(): Promise<boolean> {
    if (!this.taskId) {
      return false;
    }
    const task = await observableToPromise(this.getCurrentTaskOrLast$);
    if (task) {
      await this.router.navigate(getTaskPagePath('', task.id));
      return true;
    }
    await this.router.navigate(['/tasks', 'card']);
    return false;
  }

  async openActionsPopopver() {
    if (!(await observableToPromise(this.isEditEnabled$))) {
      this.loggingService.warn(LOG_SOURCE, 'openActionsPopopver - NOT editEnabled');
      return;
    }
    const task = await observableToPromise(this.task$);
    const isSubTask = !!task.parentId;
    const result = await this.popoverService.openActions(event, _.compact([
      {
        label: 'tasks.toolbar.actions.assignToProtocol',
        role: 'assign',
        icon: ['fal', 'clipboard'],
      },
      isSubTask ?
        {
          label: 'convertToMainOrSubEntry.toMain.buttonLabel',
          role: 'convertToMain',
          icon: ['fal', 'arrow-from-bottom'],
        } : undefined,
      !isSubTask ?
        {
          label: 'convertToMainOrSubEntry.toSub.buttonLabel',
          role: 'convertToSub',
          icon: ['fal', 'arrow-from-top'],
        } : undefined,
      {
        label: 'delete',
        role: 'delete',
        icon: ['fal', 'trash-alt']
      },
    ]));

    if (result !== 'backdrop') {
      switch (result) {
        case 'assign':
          await this.assignCurrentTaskWithParent();
          break;
        case 'convertToMain':
          await this.convertToMain();
          break;
        case 'convertToSub':
          await this.convertToSub();
          break;
        case 'delete':
          await this.removeTasks();
          break;
        default:
          throw new Error('Unsupported action: ' + result);
      }
    }
  }

  private async convertToMain() {
    const protocol = await observableToPromise(this.entryService.taskProtocol$);
    if (!protocol) {
      throw new Error('convertToMain - taskProtocol not found.');
    }
    await this.convertEntryService.convertToMainEntryWithConfirm(protocol.id, this.taskId);
  }

  private async convertToSub() {
    const protocol = await observableToPromise(this.entryService.taskProtocol$);
    if (!protocol) {
      throw new Error('convertToMain - taskProtocol not found.');
    }
    await this.convertEntryService.convertToSubEntryWithConfirm(protocol.id, this.taskId);
  }

}
