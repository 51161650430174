import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {PdfViewerComponent} from 'src/app/components/pdf/pdf-viewer/pdf-viewer.component';
import {PdfPlanFolderPickerModalComponent} from 'src/app/components/project-room/pdf-plan-folder-picker-modal/pdf-plan-folder-picker-modal.component';
import {SharePdfPlansWorkflowComponent} from 'src/app/components/project-room/share-pdf-plans-workflow/share-pdf-plans-workflow.component';
import {UploadPdfPlanWorkflowComponent} from 'src/app/components/project-room/upload-pdf-plan-workflow/upload-pdf-plan-workflow.component';
import {PdfPlanVersionWithAttachment, PdfPlanWithDeletable} from 'src/app/model/pdf-plan-with-deletable';
import {observableToPromise} from 'src/app/utils/async-utils';
import {environment} from 'src/environments/environment';
import {IdType, LicenseType, PdfPlanVersionAccessType} from 'submodules/baumaster-v2-common';
import {UploadPdfPlanVersionWorkflowComponent} from '../../components/project-room/upload-pdf-plan-version-workflow/upload-pdf-plan-version-workflow.component';
import {LoadingService} from '../common/loading.service';
import {DownloadService} from '../download/download.service';
import {SystemEventService} from '../event/system-event.service';
import {FeatureEnabledService} from '../feature/feature-enabled.service';
import {PhotoService} from '../photo/photo.service';
import {SyncStrategy} from '../sync/sync-utils';
import {SyncService} from '../sync/sync.service';
import {OmgToastService} from '../ui/omg-toast.service';
import {PopoverService} from '../ui/popover.service';
import {PdfPlanHolderService} from './pdf-plan-holder.service';
import {PdfPlanHoldersActionsService} from './pdf-plan-holders-actions.service';
import {PlanAnalyticsService} from './plan-analytics.service';
import {ManageCadFilesComponent} from '../../components/project-room/manage-cad-files/manage-cad-files.component';
import {PdfPlanVersionAccessService} from './pdf-plan-version-access.service';
import {PdfPlanHistoryComponent} from 'src/app/components/project-room/pdf-plan-distribution-history/pdf-plan-history.component';
import {NetworkStatusService} from '../common/network-status.service';
import {OfflineInfoService} from '../common/offline-info.service';
import { convertErrorToMessage, OfflineError } from 'src/app/shared/errors';
import { LoggingService } from '../common/logging.service';

const DOWNLOAD_PDF_PLANS_ENDPOINT = 'api/data/pdfPlans/download';
const LOG_SOURCE = 'PdfPlanHolderActionsService';

type HolderActionResult = {
  action: 'preview' | 'download' | 'move' | 'uploadVersion' | 'edit' | 'delete' | 'manageCadFiles' | 'history';
  result: boolean;
};

@Injectable({
  providedIn: 'root'
})
export class PdfPlanHolderActionsService {

  constructor(
    private photoService: PhotoService,
    private modalController: ModalController,
    private popoverService: PopoverService,
    private toastService: OmgToastService,
    private featureEnabledService: FeatureEnabledService,
    private pdfPlanHolderService: PdfPlanHolderService,
    private loadingService: LoadingService,
    private http: HttpClient,
    private systemEventService: SystemEventService,
    private translateService: TranslateService,
    private syncService: SyncService,
    private downloadService: DownloadService,
    private pdfPlanHoldersActionsService: PdfPlanHoldersActionsService,
    private planAnalyticsService: PlanAnalyticsService,
    private pdfPlanVersionAccessService: PdfPlanVersionAccessService,
    private networkStatusService: NetworkStatusService,
    private offlineInfoService: OfflineInfoService,
    private loggingService: LoggingService
  ) { }

  async openPdfPlanPreview(holder: PdfPlanWithDeletable): Promise<HolderActionResult> {
    let pdfPreviewObjectUrl;
    try {
      const attachment = holder.latestPdfPlanVersion?.pdfPlanAttachment;
      const isOnline = await this.networkStatusService.isOnline();
      if (!attachment && !isOnline) {
        throw new OfflineError(`Unable to load attachment ${attachment?.id} from the server as the device is offline and the attachment is not stored locally.`);
      } else if (!attachment && isOnline) {
        throw new Error(`pdfPlanAttachment for pdfPlanVersion ${holder.latestPdfPlanVersion?.id} not found.`);
      }
      const pdfBlob = await this.photoService.downloadAttachment(attachment, 'image');
      pdfPreviewObjectUrl = URL.createObjectURL(pdfBlob);
      const modal = await this.modalController.create({
        component: PdfViewerComponent,
        cssClass: 'pdf-workflow-modal',
        componentProps: {
          pdfObjectUrl: pdfPreviewObjectUrl,
          pdfBlob,
          filename: holder.latestPdfPlanVersion.name,
          onDownloadClick: () => {
            this.planAnalyticsService.planActionClicked(holder.id, 'previewDownload');
            this.trackAccess(holder.latestPdfPlanVersion.id, 'download');
          }
        }
      });
      this.trackAccess(holder.latestPdfPlanVersion.id, 'view');
      await modal.present();
      await modal.onDidDismiss();
    } catch(error) {
      if (error instanceof OfflineError) {
        this.loggingService.warn(LOG_SOURCE, `openPdfPlanPreview failed with error: ${convertErrorToMessage(error)}`);
        if (await this.offlineInfoService.showOfflineAlert()) {
          this.openPdfPlanPreview(holder);
        }
      } else {
        this.loggingService.error(LOG_SOURCE, `openPdfPlanPreview failed with error: ${convertErrorToMessage(error)}`);
        await this.toastService.error(`pdfPlanAttachment for pdfPlanVersion ${holder.latestPdfPlanVersion?.id} not found.`);
      }
    } finally {
      if (pdfPreviewObjectUrl) {
        URL.revokeObjectURL(pdfPreviewObjectUrl);
      }
    }
    return {
      action: 'preview',
      result: true
    };
  }

  async openPdfPlanHistory(holder: PdfPlanWithDeletable): Promise<HolderActionResult> {
    const modal = await this.modalController.create({
      component: PdfPlanHistoryComponent,
      cssClass: 'omg-modal omg-boundary protocol-entry-modal',
      componentProps: {
        pdfPlan: holder,
      }
    });
    await modal.present();
    await modal.onDidDismiss();
    return {
      action: 'history',
      result: true
    };
  }

  async openOldPdfPlanPreview(version: PdfPlanVersionWithAttachment): Promise<boolean> {
    const attachment = version?.pdfPlanAttachment;
    if (!attachment) {
      throw new Error(`pdfPlanAttachment for pdfPlanVersion ${version.id} not found.`);
    }
    const pdfBlob = await this.photoService.downloadAttachment(attachment, 'image');
    const pdfPreviewObjectUrl = URL.createObjectURL(pdfBlob);
    try {
      const modal = await this.modalController.create({
        component: PdfViewerComponent,
        cssClass: 'pdf-workflow-modal',
        componentProps: {
          pdfObjectUrl: pdfPreviewObjectUrl,
          pdfBlob,
          filename: version.pdfPlanAttachment.fileName,
          onDownloadClick: () => {
            this.planAnalyticsService.planVersionActionClicked(version.id, 'previewDownload');
            this.trackAccess(version.id, 'download');
          }
        }
      });
      this.trackAccess(version.id, 'view');
      await modal.present();
      await modal.onDidDismiss();
    } finally {
      URL.revokeObjectURL(pdfPreviewObjectUrl);
    }
    return true;
  }

  async downloadMultiplePdfPlans(pdfPlans: PdfPlanWithDeletable[], projectId: IdType) {
    if (pdfPlans.length === 0) {
      throw new Error('downloadMultiplePdfPlans called with empty pdfPlans list');
    }
    try {
      await this.loadingService.withLoading(async () => {
        const filename = this.translateService.instant('project_room.pdf_plan_holders.downloadPdfPlans.zipFilename');
        await this.syncService.startSync(SyncStrategy.PROJECTS_WITH_CHANGES);
        const blob = await observableToPromise(this.http.get(`${environment.serverUrl}${DOWNLOAD_PDF_PLANS_ENDPOINT}?projectId=${projectId}&pdfPlanIds=${pdfPlans.map(({id}) => id).join(',')}`, {
          responseType: 'blob'
        }));

        if (this.downloadService.isDownloadNativePlatform()) {
          return await this.downloadService.downloadBlob(blob, filename);
        }

        let url: string|undefined;

        try {
          url = URL.createObjectURL(blob);
          const linkElement = document.createElement('a');
          linkElement.href = url;
          linkElement.setAttribute('download', filename);
          linkElement.click();
          linkElement.remove();
        } catch (e) {
          throw e;
        } finally {
          if (url) {
            URL.revokeObjectURL(url);
          }
        }
      }, {message: this.translateService.instant('project_room.pdf_plan_holders.downloadPdfPlans.loadingMessage')});
    } catch (error) {
      this.systemEventService.logErrorEvent(`${LOG_SOURCE} - downloadMultiplePdfPlans`, error);
      this.toastService.errorWithMessageAndHeader(
        'project_room.pdf_plan_holders.downloadPdfPlans.failed.header',
        'project_room.pdf_plan_holders.downloadPdfPlans.failed.message'
      );
    }
    pdfPlans.forEach(plan => this.trackAccess(plan.latestPdfPlanVersion.id, 'download'));
  }

  async openPdfPlanHolderActions(event: MouseEvent, holder: PdfPlanWithDeletable): Promise<HolderActionResult> {
    const action = await this.popoverService.openActions(event, [
      {
        role: 'preview',
        label: 'project_room.pdf_plan_holders.actions.preview',
        icon: ['fal', 'file-pdf'],
      },
      {
        role: 'download',
        label: 'project_room.pdf_plan_holders.actions.download',
        icon: ['fal', 'download'],
      },
      {
        role: 'move',
        label: 'project_room.pdf_plan_holders.actions.move',
        icon: ['fal', 'arrow-alt-to-left'],
        permissions: {
          featureEnabled: false,
          forConnected: true,
          forLicenses: [LicenseType.VIEWER],
        }
      },
      {
        role: 'edit',
        label: 'project_room.pdf_plan_holders.actions.edit',
        icon: ['fal', 'pencil'],
        permissions: {
          featureEnabled: false,
          forConnected: true,
          forLicenses: [LicenseType.VIEWER],
        }
      },
      {
        role: 'uploadVersion',
        label: 'project_room.pdf_plan_holders.actions.uploadVersion',
        icon: ['fal', 'plus'],
        permissions: {
          featureEnabled: true,
          forConnected: false,
          forLicenses: [LicenseType.PROFESSIONAL],
        }
      },
      {
        role: 'manageCadFiles',
        label: 'project_room.pdf_plan_holders.actions.manageCadFiles',
        icon: ['fal', 'drafting-compass'],
        permissions: {
          featureEnabled: true,
          forConnected: false,
          forLicenses: [LicenseType.PROFESSIONAL],
        }
      },
      {
        role: 'history',
        label: 'project_room.pdf_plan_holders.actions.history',
        icon: ['fal', 'history'],
        permissions: {
          featureEnabled: true,
          forConnected: false,
          forLicenses: [LicenseType.PROFESSIONAL],
        }
      },
      {
        role: 'delete',
        label: 'project_room.pdf_plan_holders.actions.delete',
        icon: ['fal', 'trash-alt'],
        lookDisabled: !holder.deletable && !holder.latestPdfPlanVersionDeletable,
        permissions: {
          featureEnabled: false,
          forConnected: true,
          forLicenses: [LicenseType.VIEWER],
        }
      },
    ]);

    if (action === 'backdrop' || action === 'feature-disabled-connected' || action === 'feature-disabled-license') {
      return;
    }

    this.planAnalyticsService.planActionClicked(holder.id, action);

    switch (action) {
      case 'preview': return this.openPdfPlanPreview(holder);
      case 'download': return this.downloadLatestPdfPlan(holder).then((result) => ({ action, result }));
      case 'move': return this.openChangePdfPlansFolder([holder]).then((result) => ({ action, result }));
      case 'uploadVersion': return this.openUploadPdfPlanVersionModal(holder);
      case 'edit': {
        this.pdfPlanHoldersActionsService.enterEditMode([holder], 'single');
        return { action, result: true };
      }
      case 'history': return this.openPdfPlanHistory(holder);
      case 'delete': {
        if (holder.latestPdfPlanVersion && holder.latestPdfPlanVersionDeletable) {
          return await this.pdfPlanHolderService.deletePdfPLanVersion(holder.latestPdfPlanVersion).then((result) => ({ action, result }));
        } else if (!holder.latestPdfPlanVersion && holder.deletable) {
          // This is a fallback in case there is an empty plan holder (with no planVersion) to still be able to delete it without contacting support
          return await this.pdfPlanHolderService.deletePdfPlan(holder).then((result) => ({ action, result }));
        } else {
          return this.toastService.infoWithMessageAndHeader(
            'project_room.pdf_plan_holders.notDeletable.header',
            'project_room.pdf_plan_holders.notDeletable.message'
          ).then(() => ({ action, result: false }));
        }
      }
      case 'manageCadFiles': {
        await this.openManageCadFilesModal(holder);
        return { action, result: true };
      }
      default: throw new Error(`Unknown menu action "${action}" in PDF plan holder`);
    }
  }

  async openPdfPlanVersionActions(event: MouseEvent, version: PdfPlanVersionWithAttachment): Promise<boolean> {
    const action = await this.popoverService.openActions(event, [
      {
        role: 'preview',
        label: 'project_room.pdf_plan_holders.actions.preview',
        icon: ['fal', 'file-pdf'],
      },
      {
        role: 'download',
        label: 'project_room.pdf_plan_holders.actions.download',
        icon: ['fal', 'download'],
      }
    ]);

    if (action === 'backdrop' || action === 'feature-disabled-connected' || action === 'feature-disabled-license') {
      return;
    }

    this.planAnalyticsService.planVersionActionClicked(version.id, action);

    switch (action) {
      case 'preview': return this.openOldPdfPlanPreview(version);
      case 'download': return this.downloadOldVersionPdfPlan(version);
      default: throw new Error(`Unknown menu action "${action}" in PDF plan holder`);
    }
  }

  async openUploadPdfPlanVersionModal(holder: PdfPlanWithDeletable): Promise<HolderActionResult> {
    if (!(await this.featureEnabledService.isFeatureEnabledOrToast(true, false, [LicenseType.PROFESSIONAL]))) {
      return {action: 'uploadVersion', result: false};
    }
    const modal = await this.modalController.create({
      component: UploadPdfPlanVersionWorkflowComponent,
      componentProps: {
        pdfPlanFolderId: holder.folderId,
        pdfPlanId: holder.id
      },
      cssClass: 'omg-modal omg-boundary',
    });
    await modal.present();
    const { role } = await modal.onDidDismiss();

    return {
      action: 'uploadVersion',
      result: role === 'finished',
    };
  }

  async openChangePdfPlansFolder(plans: PdfPlanWithDeletable[]): Promise<boolean> {
    if (!(await this.featureEnabledService.isFeatureEnabledOrToast(false, true, [LicenseType.VIEWER]))) {
      return false;
    }
    const uniqueFolderIds = Array.from(new Set(plans.map((plan) => plan.folderId)));
    const folderId = uniqueFolderIds.length === 1 ? uniqueFolderIds[0] : undefined;
    const modal = await this.modalController.create({
      component: PdfPlanFolderPickerModalComponent,
      componentProps: {
        folderId,
      },
      cssClass: 'omg-modal omg-boundary',
    });

    await modal.present();
    const {data, role} = await modal.onWillDismiss();
    if (role === 'save' && data) {
      try {
        await this.loadingService.withLoading(() => this.pdfPlanHolderService.updatePdfPlansFolder(plans, data));
        await this.toastService.infoWithMessageAndHeader('project_room.multiselect.change_folder_modal.completed.header', 'project_room.multiselect.change_folder_modal.completed.message');

        return true;
      } catch (e) {
        this.systemEventService.logErrorEvent(`${LOG_SOURCE} - openChangePdfPlansFolder`, e);
        this.toastService.errorWithMessageAndHeader(
          'project_room.pdf_plan_holders.changePdfPlansFolder.failed.header',
          'project_room.pdf_plan_holders.changePdfPlansFolder.failed.message'
        );
      }
    }

    return false;
  }

  async openSharePdfPlans(plans: PdfPlanWithDeletable[]): Promise<boolean> {
    if (!(await this.featureEnabledService.isFeatureEnabledOrToast(true, false, [LicenseType.PROFESSIONAL]))) {
      return false;
    }
    const modal = await this.modalController.create({
      component: SharePdfPlansWorkflowComponent,
      componentProps: {
        plans,
      },
      cssClass: 'omg-modal omg-boundary pdf-workflow-modal',
    });

    await modal.present();
    const { role } = await modal.onDidDismiss();

    return role === 'sent';
  }

  public async showUploadPlanDialog(pdfPlanFolderId?: IdType) {
    if (!(await this.featureEnabledService.isFeatureEnabledOrToast(false, true, [LicenseType.VIEWER]))) {
      return false;
    }
    const modal = await this.modalController.create({
      component: UploadPdfPlanWorkflowComponent,
      backdropDismiss: false,
      componentProps: {
        pdfPlanFolderId,
      },
      cssClass: 'omg-modal omg-boundary',
    });
    await modal.present();
  }

  private async downloadLatestPdfPlan(holder: PdfPlanWithDeletable) {
    if (!holder.latestPdfPlanVersion?.pdfPlanAttachment) {
      await this.toastService.error('project_room.pdf_plan_holders.downloadNotPossibleNoLatestPdfPlanVersion');
      return false;
    }
    await this.photoService.downloadAttachmentToDevice(holder.latestPdfPlanVersion.pdfPlanAttachment);
    if (holder.latestPdfPlanVersion.pdfPlanCadAttachments?.length) {
      for (const cadAttachment of holder.latestPdfPlanVersion.pdfPlanCadAttachments) {
        await this.photoService.downloadAttachmentToDevice(cadAttachment);
      }
    }
    this.trackAccess(holder.latestPdfPlanVersion.id, 'download');
    return true;
  }

  private async openManageCadFilesModal(holder: PdfPlanWithDeletable): Promise<boolean> {
    if (!(await this.featureEnabledService.isFeatureEnabledOrToast(false, true, [LicenseType.VIEWER]))) {
      return false;
    }
    if (!holder.latestPdfPlanVersion) {
      await this.toastService.error('project_room.pdf_plan_holders.noLatestPlanVersion');
      return;
    }
    const modal = await this.modalController.create({
      component: ManageCadFilesComponent,
      backdropDismiss: false,
      componentProps: {
        pdfPlanId: holder.id,
        pdfPlanVersionId: holder.latestPdfPlanVersion.id
      },
      cssClass: 'omg-modal omg-boundary',
    });
    await modal.present();
    return true;
  }

  private async downloadOldVersionPdfPlan(version: PdfPlanVersionWithAttachment) {
    if (!version?.pdfPlanAttachment) {
      await this.toastService.error('project_room.pdf_plan_holders.downloadNotPossibleNoLatestPdfPlanVersion');
      return false;
    }
    await this.photoService.downloadAttachmentToDevice(version.pdfPlanAttachment);
    this.trackAccess(version.id, 'download');
    return true;
  }

  private async trackAccess(pdfPlanVersionId: IdType, type: PdfPlanVersionAccessType) {
    await this.pdfPlanVersionAccessService.trackAccess(pdfPlanVersionId, type);
  }
}
