
export function getGroupingSeparator(lng: string) {
  switch (lng) {
    case 'en':
      return ',';
    case 'de':
      return '.';
    case 'fr':
      return '.';
    default:
      return '';
  }
}

export function getFractionSeparator(lng: string) {
  switch (lng) {
    case 'de':
      return ',';
    case 'fr':
      return ',';
    case 'en':
    default:
      return '.';
  }
}

export const groupNumberStringBy3 = (value: string): string[] => {
  if (value.length % 3 === 0) {
    // No remainder, so we just use match.
    return value.match(/.{1,3}/g) ?? [];
  }
  // Slice remainder from the beginning and then group remaining string by 3.
  // We do that, because "match" goes from left to right, where grouping should be
  // from right to left.
  return [
    value.slice(0, value.length % 3),
    ...(
      // " ?? []" ensures that for number string lower than 1000, match will not return a null
      value.slice(value.length % 3).match(/.{1,3}/g) ?? []
    )
  ];
};

export type NumberFormatTypes = 'currency' | 'hours' | 'integer';

export function getFormattedNumber(lng: string, value: number | null | undefined, style: NumberFormatTypes = 'currency'): string {
  if (value === null || value === undefined) {
    return '';
  }

  if (style === 'currency') {
    const groupingSeparator = getGroupingSeparator(lng);
    const fractionSeparator = getFractionSeparator(lng);

    const [wholeNumberPart, decimalPart] = value.toFixed(2).split('.');

    return `${wholeNumberPart[0] === '-' ? '-' : ''}${groupNumberStringBy3(wholeNumberPart.replace('-', '')).join(groupingSeparator)}${fractionSeparator}${decimalPart}`;
  }

  if (style === 'hours' || style === 'integer') {
    const groupingSeparator = getGroupingSeparator(lng);
    const fractionSeparator = getFractionSeparator(lng);

    const [wholeNumberPart, decimalPart] = value.toString().split('.');

    const commaPart = decimalPart ? `${fractionSeparator}${decimalPart.slice(0, 2)}` : `${fractionSeparator}0`;

    return `${wholeNumberPart[0] === '-' ? '-' : ''}${groupNumberStringBy3(wholeNumberPart.replace('-', '')).join(groupingSeparator)}${style === 'integer' ? '' : commaPart}`;
  }

  throw new Error(`Unsupported format style '${style}'`);
}
