<ion-row>
  <ion-col size="3" class="companyName ion-hide-sm-down">
    <span class="text-wrap-one-line">{{profileCompanyAddress?.company?.name}}</span>
  </ion-col>
  <ion-col size-sm="4" size-xs="4" class="name" *ngIf="profileCompanyAddress?.address">
    <span class="text-wrap-one-line">{{profileCompanyAddress?.address | userNameString}}</span>
    <ion-note class="text-wrap-one-line ion-hide-sm-up">{{profileCompanyAddress?.company?.name}}</ion-note>
  </ion-col>
  <ion-col size-sm="4" size-xs="4" class="name" *ngIf="!profileCompanyAddress?.address">
    <span class="text-wrap-one-line">{{pdfProtocolSignature.name}}</span>
  </ion-col>
  <ion-col size-sm="5" size-xs="8" class="signature" *ngIf="!pdfProtocolSignature.signature">
    <ion-item lines="none" class="item-small ion-no-margin ion-no-padding">
      <ion-button class="omg-btn-secondary" (click)="sign()" [disabled]="readonly">{{'pdfReport.signature.signature' | translate}}</ion-button>
      <ion-buttons slot="end">
        <ion-button *ngIf="allowDelete" class="omg-btn-text" (click)="deleteSignature()" [disabled]="readonly || pdfProtocolSignature.signature">
          <fa-icon [icon]="['fal', 'trash-alt']"></fa-icon>
        </ion-button>
      </ion-buttons>
    </ion-item>
  </ion-col>
  <ion-col size-sm="5" size-xs="8" class="signature" *ngIf="pdfProtocolSignature.signature">
    <ion-item lines="none" class="item-small">
      <app-attachment-viewer *ngIf="!showCheckInsteadOfImage" size="small" [attachment]="pdfProtocolSignature.signature" class="ion-no-padding"></app-attachment-viewer>
      <fa-icon *ngIf="showCheckInsteadOfImage" [icon]="['fas', 'check']" class="checkSigned"></fa-icon>
      <ion-buttons slot="end">
        <ion-button *ngIf="pdfProtocolSignature.signature" class="omg-btn-text" (click)="removeSignature()" [disabled]="readonly">
          <fa-icon [icon]="['fal', 'times-circle']"></fa-icon>
        </ion-button>
        <ion-button *ngIf="allowDelete" class="omg-btn-text" (click)="deleteSignature()" [disabled]="readonly || pdfProtocolSignature.signature">
          <fa-icon [icon]="['fal', 'trash-alt']"></fa-icon>
        </ion-button>
      </ion-buttons>
    </ion-item>
  </ion-col>
</ion-row>
