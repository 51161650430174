<app-modal-header>
  <ion-title *ngIf="!protocolTypeId">{{ 'protocolTypeForm.createTitle' | translate }}</ion-title>
  <ion-title *ngIf="protocolTypeId">{{ 'protocolTypeForm.editTitle' | translate }}</ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <div class="omg-attention omg-attention-danger ion-text-center mb-4" *ngIf="protocolTypeId && (isOffline$ | async)">
    {{'protocolTypeForm.noInternet' | translate}}
  </div>
  <form class="omg-form" [formGroup]="protocolTypeForm" action="#">
    <ion-grid class="grid-gap-4">
      <ion-row>
        <ion-col size="12">
          <ion-item >
            <ion-input label="{{ 'protocolTypeForm.code' | translate }}" label-placement="floating" #inputCode formControlName="code" maxLength="6" type="text" class="omg-required"></ion-input>
          </ion-item>
          <span *ngIf="protocolTypeForm.controls.code.errors?.required && protocolTypeForm.controls.code.dirty" class="error-message"> {{ 'formValidation.emptyField' | translate:{field: 'protocolTypeForm.code' | translate} }}</span>
          <span *ngIf="protocolTypeForm.controls.code.errors?.codeExist && protocolTypeForm.controls.code.dirty" class="error-message"> {{ 'protocolTypeForm.codeExist' | translate }} </span>
          <span *ngIf="protocolTypeForm.controls.code.errors?.codeSystemReserved && protocolTypeForm.controls.code.dirty" class="error-message"> {{ 'formValidation.codeSystemReserved' | translate }} </span>
           <span *ngIf="protocolTypeForm.controls.code.errors?.codeExistAndInactive && protocolTypeForm.controls.code.dirty && !restoreMode" class="error-message">
             {{ 'protocolTypeForm.codeExistAndInactive' | translate }} <ion-text class="cursor-pointer" color="primary" (click)="restoreByCode()" *ngIf="protocolTypeId === null"> {{ 'button.restore' | translate }} </ion-text>
          </span>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <ion-item>
            <ion-input label="{{ 'protocolTypeForm.name' | translate }}" label-placement="floating" formControlName="name" maxLength="40" type="text" class="omg-required"></ion-input>
          </ion-item>
          <span *ngIf="protocolTypeForm.controls.name.errors?.required && protocolTypeForm.controls.name.dirty" class="error-message"> {{ 'formValidation.emptyField' | translate:{field: 'protocolTypeForm.name' | translate} }}</span>
          <span *ngIf="protocolTypeForm.controls.name.errors?.nameExist && protocolTypeForm.controls.name.dirty" class="error-message"> {{ 'protocolTypeForm.nameExist' | translate }} </span>
          <span *ngIf="protocolTypeForm.controls.name.errors?.nameSystemReserved && protocolTypeForm.controls.name.dirty" class="error-message"> {{ 'formValidation.nameSystemReserved' | translate }} </span>
          <span *ngIf="protocolTypeForm.controls.name.errors?.nameExistAndInactive && protocolTypeForm.controls.name.dirty && !restoreMode" class="error-message">
             {{ 'protocolTypeForm.nameExistAndInactive' | translate }} <ion-text class="cursor-pointer" color="primary" (click)="restoreByName()" *ngIf="protocolTypeId === null"> {{ 'button.restore' | translate }}</ion-text>
          </span>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <ion-item>
            <ion-label position="floating">{{ 'protocolTypeForm.layout' | translate }}</ion-label>
            <ionic-selectable #layoutIonicSelectable
                              [isEnabled]="!protocolTypeId && !layoutId"
                              [canSearch]="true"
                              [items]=" protocolLayouts$ | async"
                              formControlName="layout"
                              item-content
                              itemTextField="label"
                              itemValueField="id"
                              searchPlaceholder="{{'search' | translate}}"
                              searchFailText="{{'noItemsFound' | translate}}"
                              appSelectableCommon
                              #fs="appSelectableCommon"
                              [shouldFocusSearchbar]="fs.isDesktop"
                              (onOpen)="onOpen($event)"
                              (onClose)="onClose($event)">
              <ng-template ionicSelectableItemIconTemplate let-selected="isItemSelected">
                <app-selectable-item-icon [selected]="selected"></app-selectable-item-icon>
              </ng-template>
              <ng-template ionicSelectableValueTemplate let-value="value">
                <app-selectable-value [value]="value"></app-selectable-value>
              </ng-template>
              <ng-template ionicSelectableHeaderTemplate>
                <app-selectable-header></app-selectable-header>
              </ng-template>
              <ng-template ionicSelectableFooterTemplate>
                <app-selectable-footer></app-selectable-footer>
              </ng-template>
            </ionic-selectable>
          </ion-item>
          <span *ngIf="protocolTypeForm.controls.layout.errors?.required && protocolTypeForm.controls.layout.dirty" class="error-message"> {{ 'formValidation.emptyField' | translate:{field: 'protocolTypeForm.layout' | translate} }}</span>
          <span *ngIf="protocolTypeForm.controls.layout.errors?.protocolTypeInUse && protocolTypeForm.controls.layout.dirty" class="error-message"> {{ 'protocolTypeForm.protocolTypeInUse' | translate }}</span>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="start" class="omg-form">
    <ion-item class="omg omg-form-checkbox" (click)="toggleAddToProject()" *ngIf="!protocolTypeId && (notConnected$ | async) && !createForSelectable">
     <ion-checkbox label-placement="end" justify="start" [ngModel]="addToProject">
       {{ 'protocolTypeForm.addToProject' | translate }}
     </ion-checkbox>
    </ion-item>
    <ion-button *ngIf="protocolTypeId" class="omg-btn-secondary omg-btn-color-danger" (click)="deleteProtocolType(protocolType)">
      <fa-icon slot="start" [icon]="['fal', 'trash-alt']"></fa-icon>
      <span>{{'delete' | translate}}</span>
    </ion-button>
  </ion-buttons>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" (click)="dismissModal()">{{ 'cancel' | translate }}</ion-button>
    <ion-button  class="omg-btn-primary" [disabled]="((!protocolTypeForm?.valid || !protocolTypeForm?.dirty || loading) && !restoreMode) || (protocolTypeId && (isOffline$ | async))" (click)="saveProtocolType()" >
      <span *ngIf="!protocolTypeId && !restoreMode">{{'button.create' | translate }}</span>
      <span *ngIf="protocolTypeId">{{'button.save' | translate }}</span>
      <span *ngIf="!protocolTypeId && restoreMode">{{'button.restore' | translate }}</span>
    </ion-button>
  </ion-buttons>
</app-modal-footer>
