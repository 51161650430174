import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {LanguageService} from '../i18n/language.service';
import {localeDe, localeEn, localeFr} from '@mobiscroll/angular-ivy';
import {ThemeService} from '../ui/theme.service';
import {MbscLocale} from '@mobiscroll/angular-ivy/dist/js/i18n/locale';

@Injectable({
  providedIn: 'root'
})
export class MobiscrollService {
  public readonly DATE_FORMAT = "DD.MM.YYYY";
  public readonly TIME_FORMAT = "HH:mm";
  public readonly themeVariant: 'light' | 'dark' = this.themeService.isDark ? 'dark' : 'light';
  public readonly themeVariant$: Observable<'light' | 'dark'> = this.themeService.isDark$.pipe(map((isDark) => isDark ? 'dark' : 'light'));
  public readonly locale$: Observable<MbscLocale> = this.languageService.selectedLanguage.pipe(map((language) => {
    switch (language?.toLowerCase()?.trim()) {
      case 'de': return localeDe;
      case 'en': return localeEn;
      case 'fr': return localeFr;
      default: return localeDe;
    }
  }));
  public readonly timeRangeEmptyValue = null;

  constructor(private languageService: LanguageService, private themeService: ThemeService) {
  }
}
