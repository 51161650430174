import { Injectable } from '@angular/core';
import {ShareVersionsEmailSettings, ShareVersionsEmailSettingsTemplate} from 'submodules/baumaster-v2-common';
import {combineLatestAsync, observableToPromise} from '../../utils/async-utils';
import {ShareVersionsEmailSettingsTemplateDataService} from '../data/share-versions-email-settings-template-data.service';
import {v4} from 'uuid';
import {ProjectDataService} from '../data/project-data.service';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {EmptyUserEmailSignature} from '../../model/email-signature-form-model';
import {UserEmailSignatureDataService} from '../data/user-email-signature-data.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ShareVersionsEmailSettingsTemplateService {

  template$ = combineLatestAsync([
    this.userEmailSignatureDataService.data,
    this.shareVersionsEmailSettingsTemplateDataService.data
  ]).pipe(map(([[userEmailSignature], [currentTemplate]]) => {
    const template: ShareVersionsEmailSettings = {
      addSignature: currentTemplate?.addSignature ?? false,
      mailTextUnderDownloadLink: currentTemplate?.mailTextUnderDownloadLink ?? '',
      subject: currentTemplate?.subject ?? '',
      text: currentTemplate?.text ?? '',
    };

    let emailSignature = {
      ...EmptyUserEmailSignature
    };

    if (userEmailSignature) {
      const { id, changedAt, ...theSignature } = userEmailSignature;

      emailSignature = theSignature;
    }


    return {
      ...template,
      emailSignature
    };
  }));

  constructor(
    private userEmailSignatureDataService: UserEmailSignatureDataService,
    private shareVersionsEmailSettingsTemplateDataService: ShareVersionsEmailSettingsTemplateDataService,
    private projectDataService: ProjectDataService
  ) { }

  getEmptyFormGroup() {
    return new UntypedFormGroup({
      subject: new UntypedFormControl(''),
      text: new UntypedFormControl(''),
      addSignature: new UntypedFormControl(false),
      emailSignature: new UntypedFormControl({...EmptyUserEmailSignature}),
      mailTextUnderDownloadLink: new UntypedFormControl(''),
    });
  }

  async saveAsDefaultTemplate(template: ShareVersionsEmailSettings): Promise<ShareVersionsEmailSettingsTemplate> {
    const project = await this.projectDataService.getMandatoryCurrentProject();
    const [currentTemplate] = (await observableToPromise(this.shareVersionsEmailSettingsTemplateDataService.data)) ?? [];
    if (currentTemplate) {
      const updatedTemplate: ShareVersionsEmailSettingsTemplate = {
        ...currentTemplate,
        ...template,
      };

      return (await this.shareVersionsEmailSettingsTemplateDataService.update(updatedTemplate, project.id))[0];
    } else {
      const newTemplate: ShareVersionsEmailSettingsTemplate = {
        ...template,
        id: v4(),
        changedAt: new Date().toISOString(),
        projectId: project.id,
      };

      return (await this.shareVersionsEmailSettingsTemplateDataService.insert(newTemplate, project.id))[0];
    }
  }
}
