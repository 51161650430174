import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {IdType, BimVersionAccess, BimVersionAccessType, User} from 'submodules/baumaster-v2-common';
import {StorageKeyEnum} from '../../shared/constants';
import {AuthenticationService} from '../auth/authentication.service';
import {LoggingService} from '../common/logging.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {StorageService} from '../storage.service';
import {UserService} from '../user/user.service';
import {AbstractProjectAwareDataService} from './abstract-project-aware-data.service';
import {ProjectDataService} from './project-data.service';
import _ from 'lodash';
import {VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';

const REST_ENDPOINT_URI = 'api/data/bimVersionAccess';

export const filterByBimVersionIdUserIdAndTypeFn = (data: BimVersionAccess[], bimVersionId: string, userId: string, type: string): BimVersionAccess[] => {
  return data.filter((value) => value.bimVersionId === bimVersionId && value.userId === userId && value.type === type);
};

@Injectable({
  providedIn: 'root'
})
export class BimVersionAccessDataService extends AbstractProjectAwareDataService<BimVersionAccess>{

  constructor(http: HttpClient, storage: StorageService,
              authenticationService: AuthenticationService,
              userService: UserService,
              protected projectDataService: ProjectDataService,
              projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
              loggingService: LoggingService, integrityResolverService: IntegrityResolverService) {
    super(StorageKeyEnum.BIM_VERSION_ACCESS, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, projectDataService, loggingService,
          projectAvailabilityExpirationService, integrityResolverService, VERSION_INTRODUCED_DEFAULT, ['bimVersionId', 'changedAt', 'type']);
  }

  getFirstByBimVersionIdUserIdAndType$(bimVersionId: IdType, userId: IdType, type: BimVersionAccessType): Observable<BimVersionAccess|undefined> {
    return this.data.pipe(map((data) => _.first(filterByBimVersionIdUserIdAndTypeFn(data, bimVersionId, userId, type))));
  }

  getAllByBimVersionIdUserIdAndType$(bimVersionId: IdType, userId: IdType, type: BimVersionAccessType): Observable<BimVersionAccess[]> {
    return this.data.pipe(map((data) => filterByBimVersionIdUserIdAndTypeFn(data, bimVersionId, userId, type)));
  }

  getAllByBimVersionId$(bimVersionId: IdType): Observable<BimVersionAccess[]> {
    return this.data.pipe(map((data) => data.filter((item) => item.bimVersionId === bimVersionId)));
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }
}
