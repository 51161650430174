import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {AbstractClientAwareDataService} from './abstract-client-aware-data.service';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {ClientService} from '../client/client.service';
import {User, UserInvite} from 'submodules/baumaster-v2-common';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';

const REST_ENDPOINT_URI = 'api/data/userInvite';

@Injectable({
  providedIn: 'root'
})
export class UserInviteDataService extends AbstractClientAwareDataService<UserInvite> {

  constructor(http: HttpClient, storage: StorageService, authenticationService: AuthenticationService, userService: UserService, clientService: ClientService,
              loggingService: LoggingService, integrityResolverService: IntegrityResolverService) {
    super(StorageKeyEnum.USER_INVITE, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, clientService, loggingService,
          integrityResolverService);
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return currentUser.isClientAdmin;
  }
}
