import {Pipe, PipeTransform} from '@angular/core';
import {ProtocolLayoutDataService} from '../services/data/protocol-layout-data.service';
import { observableToPromise } from '../utils/observable-to-promise';

const LOG_SOURCE = 'ProtocolLayoutPipe';

@Pipe({
  name: 'protocolLayoutName'
})
export class ProtocolLayoutNamePipe implements PipeTransform {
  constructor(private protocolLayoutDataService: ProtocolLayoutDataService) {
  }

  async transform(typeId: string): Promise<string> {
    const layouts = await observableToPromise(this.protocolLayoutDataService.dataGroupedById);
    if (layouts[typeId]) {
      const layout = layouts[typeId];
      return layout.name;
    } else {
      return '';
    }
  }

}
