import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges} from '@angular/core';
import {Observable, of, Subject, Subscription} from 'rxjs';
import {PdfProtocolSignatureForm} from '../pdf-protocol-signatures/pdf-protocol-signatures.interface';
import {IdType} from 'submodules/baumaster-v2-common';
import {ProfileDataService} from '../../../services/data/profile-data.service';
import {AddressDataService} from '../../../services/data/address-data.service';
import {map, switchMap} from 'rxjs/operators';
import {combineLatestAsync} from '../../../utils/async-utils';
import {CompanyDataService} from '../../../services/data/company-data.service';
import {PhotoService} from '../../../services/photo/photo.service';
import {SIGNATURE_HEIGHT, SIGNATURE_WIDTH} from '../../../model/send-protocol';
import {ModalController} from '@ionic/angular';
import {SketchComponent} from '../../common/sketch/sketch.component';
import {ContactService} from '../../../services/contact/contact.service';
import {ProfileCompanyAddress} from '../../../model/contacts';
import {Nullish} from '../../../model/nullish';

@Component({
  selector: 'app-pdf-protocol-signature',
  templateUrl: './pdf-protocol-signature.component.html',
  styleUrls: ['./pdf-protocol-signature.component.scss'],
})
export class PdfProtocolSignatureComponent implements OnDestroy, OnChanges {
  private destroy$ = new Subject<void>();
  private profileCompanyAddressSubscription: Subscription|undefined;

  @Input()
  showCheckInsteadOfImage = false;
  @Input()
  readonly = false;
  @Input()
  allowDelete = false;
  @Input()
  pdfProtocolSignature: PdfProtocolSignatureForm;
  @Output()
  pdfProtocolSignatureChange = new EventEmitter<PdfProtocolSignatureForm>();
  @Output()
  pdfProtocolSignatureDelete = new EventEmitter<PdfProtocolSignatureForm>();

  public profileCompanyAddress$: Observable<Omit<ProfileCompanyAddress, 'id' | 'searchText'>>|undefined;
  public profileCompanyAddress: Omit<ProfileCompanyAddress, 'id' | 'searchText'>|undefined;

  constructor(private profileDataService: ProfileDataService, private addressDataService: AddressDataService, private companyDataService: CompanyDataService, private photoService: PhotoService,
              private contactService: ContactService, private modalController: ModalController) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pdfProtocolSignature) {
      this.unsubscribeProfileCompanyAddress();
      this.profileCompanyAddress = undefined;
      if (this.pdfProtocolSignature.profileId) {
        this.profileCompanyAddress$ =  this.getProfileCompanyAddress$(this.pdfProtocolSignature.profileId);
        this.profileCompanyAddressSubscription = this.profileCompanyAddress$.subscribe((profileCompanyAddress) => this.profileCompanyAddress = profileCompanyAddress);
      } else {
        this.profileCompanyAddress$ = undefined;
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.unsubscribeProfileCompanyAddress();
  }

  private getProfileCompanyAddress$(profileId: IdType): Observable<Omit<ProfileCompanyAddress, 'id' | 'searchText'>> {
    return this.profileDataService.getById(profileId)
      .pipe(switchMap((profile) => {
        if (!profile) {
          return of(undefined);
        }
        return combineLatestAsync([this.addressDataService.getById(profile.addressId), this.companyDataService.getById(profile.companyId)])
          .pipe(map(([address, company]) => {
            return {profile, address, company} as Omit<ProfileCompanyAddress, 'id' | 'searchText'>;
          }));
      }));
  }

  public removeSignature() {
    this.assertNotReadonly();

    this.pdfProtocolSignature.signature = undefined;
    this.pdfProtocolSignatureChange.emit(this.pdfProtocolSignature);
  }

  public deleteSignature() {
    this.assertNotReadonly();
    if (!this.allowDelete) {
      throw new Error('Signature is not allowed to be deleted.');
    }

    this.pdfProtocolSignatureDelete.emit(this.pdfProtocolSignature);
  }

  public async sign() {
    this.assertNotReadonly();

    const blob = this.photoService.createEmptyImage(SIGNATURE_WIDTH, SIGNATURE_HEIGHT);
    const attachmentUrl = URL.createObjectURL(blob);
    const modal = await this.modalController.create({
      component: SketchComponent,
      backdropDismiss: true,
      componentProps: {
        attachmentUrl,
        signature: true,
        onMarkingsChanged: async (markings: Nullish<string>) => {
          this.pdfProtocolSignature.signature = this.photoService.createAttachmentBlob(blob, 'signature.jpg', markings);
          this.pdfProtocolSignatureChange.emit(this.pdfProtocolSignature);
        }
      }
    });
    await modal.present();
    modal.onDidDismiss().finally(() => URL.revokeObjectURL(attachmentUrl));
  }

  private assertNotReadonly() {
    if (this.readonly) {
      throw new Error('Unable to change anything in readonly mode.');
    }
  }

  private unsubscribeProfileCompanyAddress() {
    this.profileCompanyAddressSubscription?.unsubscribe();
    this.profileCompanyAddressSubscription = undefined;
  }

  public handlePdfProtocolSignatureChange(pdfProtocolSignature: PdfProtocolSignatureForm) {
    this.pdfProtocolSignatureChange.emit(this.pdfProtocolSignature);
  }
}
