import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {Activity, ActivityTypeEnum, IdType, LicenseType, User} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {AbstractProjectAwareDataService} from './abstract-project-aware-data.service';
import {ProjectDataService} from './project-data.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {UserService} from '../user/user.service';
import _ from 'lodash';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';
import {VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';

const REST_ENDPOINT_URI = 'api/data/activities';

@Injectable({
  providedIn: 'root'
})
export class ActivityDataService extends AbstractProjectAwareDataService<Activity> {

  constructor(http: HttpClient, storage: StorageService, authenticationService: AuthenticationService, userService: UserService, protected projectDataService: ProjectDataService,
              protected projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
              loggingService: LoggingService, integrityResolverService: IntegrityResolverService) {
    super(StorageKeyEnum.ACTIVITY, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, projectDataService, loggingService, projectAvailabilityExpirationService,
          integrityResolverService, VERSION_INTRODUCED_DEFAULT, ['type', 'position', 'changedAt']);
  }

  public getByReport(reportId: IdType): Observable<Array<Activity>> {
    return this.data.pipe(map((activities) => activities.filter((activity) => activity.reportId === reportId)));
  }

  public getActiveByReportId(reportId: IdType): Observable<Array<Activity>> {
    return this.data.pipe(map((activities) => activities.filter((activity) => activity.reportId === reportId && !activity.isDeleted)));
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return currentUser.role === LicenseType.BASIC || currentUser.role === LicenseType.PROFESSIONAL || currentUser.assignedReportRights;
  }

  getActivitiesByTypeAndReport(type: ActivityTypeEnum, reportId: IdType): Observable<Activity[]> {
    return this.data.pipe(
      map((activities) => activities.filter((activity) => activity.reportId === reportId && activity.type === type)),
      map((activities) => _.sortBy(activities, 'position')),
    );
  }

  getSpecialOccurrencesByReport(reportId: IdType): Observable<Activity[]> {
    return this.getActivitiesByTypeAndReport(ActivityTypeEnum.SPECIAL_OCCURRENCE, reportId);
  }

  getSortedByTypeAndReport(reportId: IdType, type: ActivityTypeEnum): Observable<Array<Activity>> {
    return this.data.pipe(
      map((activities) => _.orderBy(activities.filter((activity) => activity.reportId === reportId && activity.type === type), ['position', 'changedAt'])));
  }

  getNextPosition(reportId: IdType, type: ActivityTypeEnum): Observable<number> {
    return this.getSortedByTypeAndReport(reportId, type).pipe(map((activities) => (activities.length ? activities[activities.length - 1].position || 0 : 0) + 1));
  }

}
