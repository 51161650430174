import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {IdType, MIME_TYPES_CAD, PdfPlanAttachment} from 'submodules/baumaster-v2-common';
import {PdfPlanAttachmentDataService} from '../../../services/data/pdf-plan-attachment-data.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {AlertService} from '../../../services/ui/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {ProjectDataService} from '../../../services/data/project-data.service';
import {ToastService} from '../../../services/common/toast.service';
import {convertErrorToMessage} from '../../../shared/errors';
import {PhotoService} from '../../../services/photo/photo.service';
import {AttachmentBlob} from '../../../model/attachments';
import _ from 'lodash';
import {isAttachmentBlob} from '../../../utils/attachment-utils';
import {DownloadService} from '../../../services/download/download.service';
import {LoggingService} from '../../../services/common/logging.service';
import {SystemEventService} from '../../../services/event/system-event.service';

const LOG_SOURCE = 'ManageCadFilesComponent';

@Component({
  selector: 'app-manage-cad-files',
  templateUrl: './manage-cad-files.component.html',
  styleUrls: ['./manage-cad-files.component.scss'],
})
export class ManageCadFilesComponent implements OnInit, OnDestroy {
  @Input()
  pdfPlanId: IdType;
  @Input()
  pdfPlanVersionId: IdType;

  private modal: HTMLIonModalElement;
  private destroy$ = new Subject<void>();
  readonly acceptedMimeTypes = MIME_TYPES_CAD;
  files = new Array<File>();
  processing = false;
  processingUploadedFile = false;
  pdfPlanAttachments: Array<PdfPlanAttachment> | undefined;
  newAttachments = new Array<AttachmentBlob>();
  pdfPlanAttachmentsToDelete = new Array<PdfPlanAttachment>();
  allFiles = new Array<AttachmentBlob|PdfPlanAttachment>();

  downloadObjectUrl: string|undefined;
  downloadFilename: string|undefined;
  @ViewChild('downloadLink', {static: false}) downloadLink: ElementRef<HTMLLinkElement>;

  constructor(private pdfPlanAttachmentDataService: PdfPlanAttachmentDataService, private projectDataService: ProjectDataService,
              private alertService: AlertService, private translateService: TranslateService, private toastService: ToastService, private photoService: PhotoService,
              private downloadService: DownloadService, private loggingService: LoggingService, private systemEventService: SystemEventService) { }

  ngOnInit() {
    this.pdfPlanAttachmentDataService.getCadFilesByPdfPlanVersionId$(this.pdfPlanVersionId).pipe(takeUntil(this.destroy$))
      .subscribe((pdfPlanAttachments) => {
        this.pdfPlanAttachments = pdfPlanAttachments;
        this.mergeFilesAndPdfPlanAttachments();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.downloadObjectUrl) {
      URL.revokeObjectURL(this.downloadObjectUrl);
    }
  }

  public filesChange(files: Array<File>) {
    this.processingUploadedFile = true;
    try {
      this.files = files;
      this.newAttachments = this.files.map((file) => this.photoService.createAttachmentBlob(file, file.name));
      this.mergeFilesAndPdfPlanAttachments();
    } finally {
      this.processingUploadedFile = false;
    }
  }

  private mergeFilesAndPdfPlanAttachments() {
    this.allFiles = [...(this.pdfPlanAttachments ?? []), ...this.newAttachments];
  }

  public async downloadFile(attachment: AttachmentBlob|PdfPlanAttachment) {
    let blob: Blob;
    if (isAttachmentBlob(attachment)) {
      blob = attachment.blob;
    } else {
      blob = await this.photoService.downloadAttachment(attachment, 'image');
    }
    if (!blob) {
      await this.toastService.error('project_room.manageCadFiles.attachmentForDownloadNotFound');
      return;
    }

    if (this.downloadObjectUrl) {
      URL.revokeObjectURL(this.downloadObjectUrl);
    }
    this.downloadObjectUrl = URL.createObjectURL(blob);
    this.downloadFilename = attachment.fileName;
    if (this.downloadService.isDownloadNativePlatform()) {
      await this.downloadService.downloadBlob(blob, attachment.fileName);
    } else {
      setTimeout(() => {
        this.downloadLink.nativeElement.click();
      }, 0);
    }
  }

  public async deleteFile(attachment: AttachmentBlob|PdfPlanAttachment) {
    const confirmDelete = await this.alertService.confirm(
      {
        header: 'project_room.manageCadFiles.confirmDeleteHeader',
        message: {key: 'project_room.manageCadFiles.confirmDeleteMessage', params: {fileName: attachment.fileName}},
        confirmButton: {
          color: 'danger',
          fill: 'solid',
        },
        confirmLabel: 'button.delete',
      });
    if (!confirmDelete) {
      return;
    }
    if (this.processing) {
      return;
    }
    try {
      this.processing = true;
      if (isAttachmentBlob(attachment)) {
        const index = this.newAttachments.indexOf(attachment);
        if (index === -1) {
          throw new Error(`attachment with id ${attachment.id} is a AttachmentBlob but was not found in newAttachments`);
        }
        this.newAttachments.splice(index, 1);
        this.files.splice(index, 1);
        this.newAttachments = [...this.newAttachments];
        this.files = [...this.files];
        this.mergeFilesAndPdfPlanAttachments();
      } else {
        const index = this.pdfPlanAttachments.indexOf(attachment);
        if (index === -1) {
          throw new Error(`attachment with id ${attachment.id} is a PdfPlanAttachments but was not found in pdfPlanAttachments`);
        }
        this.pdfPlanAttachments.splice(index, 1);
        this.pdfPlanAttachments = [...this.pdfPlanAttachments];
        this.pdfPlanAttachmentsToDelete.push(attachment);
        this.mergeFilesAndPdfPlanAttachments();
      }
    } finally {
      this.processing = false;
    }
  }

  private attachmentBlobsToPdfPlanAttachments(attachmentBlobs: Array<AttachmentBlob>, projectId: IdType): Array<PdfPlanAttachment> {
    const pdfPlanAttachments = new Array<PdfPlanAttachment>();
    for (const attachmentBlob of attachmentBlobs) {
      const pdfPlanAttachment: PdfPlanAttachment = {
        ..._.omit(attachmentBlob, 'blob'),
        projectId,
        pdfPlanVersionId: this.pdfPlanVersionId
      };
      pdfPlanAttachments.push(pdfPlanAttachment);
    }
    return pdfPlanAttachments;
  }

  public isDirty(): boolean {
    return Boolean(this.files?.length || this.pdfPlanAttachmentsToDelete.length);
  }

  async dismissModal() {
    await this.modal.dismiss();
  }

  async cancel() {
    if (this.isDirty()) {
      const confirmResult = await this.alertService.confirm({header: 'constructionSchedule.confirmDismiss.header', message: 'constructionSchedule.confirmDismiss.message'});
      if (!confirmResult) {
        return;
      }
    }
    await this.dismissModal();
  }

  async save() {
    if (this.processing) {
      return;
    }
    try {
      this.processing = true;
      const currentProject = await this.projectDataService.getMandatoryCurrentProject();
      const projectId = currentProject.id;
      if (this.newAttachments.length) {
        const newPdfPlanAttachments = this.attachmentBlobsToPdfPlanAttachments(this.newAttachments, projectId);
        await this.pdfPlanAttachmentDataService.insert(newPdfPlanAttachments, projectId, undefined, this.files);
        this.files = [];
        this.newAttachments = [];
        this.mergeFilesAndPdfPlanAttachments();
      }
      if (this.pdfPlanAttachmentsToDelete.length) {
        await this.pdfPlanAttachmentDataService.delete(this.pdfPlanAttachmentsToDelete, projectId);
        this.pdfPlanAttachmentsToDelete = [];
        this.mergeFilesAndPdfPlanAttachments();
      }
      await this.toastService.info('saving_success');
      await this.dismissModal();
    } catch (error) {
      this.systemEventService.logErrorEvent(LOG_SOURCE + ' save', `Error saving ${convertErrorToMessage(error)}`);
      this.loggingService.error(LOG_SOURCE, `Error saving ${convertErrorToMessage(error)}`);
      await this.toastService.error(this.translateService.instant('error_saving_message') + ' ' + convertErrorToMessage(error));
    } finally {
      this.processing = false;
    }
  }
}
