import {Component, Input, OnInit} from '@angular/core';
import ProjectForDisplay from '../../../../model/ProjectForDisplay';

@Component({
  selector: 'app-project-selector-item',
  templateUrl: './project-selector-item.component.html',
  styleUrls: ['./project-selector-item.component.scss'],
})
export class ProjectSelectorItemComponent implements OnInit {
  @Input()
  project: ProjectForDisplay;

  constructor() { }

  ngOnInit() {}

}
