import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import _, {groupBy, orderBy} from 'lodash';
import {Observable, of} from 'rxjs';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {CreateUnitInLevelEvent} from 'src/app/components/units/unit-levels-grid/unit-levels.grid.model';
import {haveObjectsEqualProperties} from 'src/app/utils/object-utils';
import {
  Address,
  IdType,
  isUnitFeatureEnabledForClient,
  Participant,
  Profile,
  Project,
  ProjectProfile,
  ProtocolEntry,
  Unit,
  UnitForBreadcrumbs,
  UnitLevel,
  unitsToUnitForBreadcrumbs
} from 'submodules/baumaster-v2-common';
import {v4} from 'uuid';
import {UnitForBreadcrumbsWithProfileAddresses, UnitProfileAddress} from '../../model/unit';
import {PROTOCOL_LAYOUT_NAME_STANDARD} from '../../shared/constants';
import {combineLatestAsync, observableToPromise} from '../../utils/async-utils';
import {ClientService} from '../client/client.service';
import {LoggingService} from '../common/logging.service';
import {AddressDataService} from '../data/address-data.service';
import {ProfileDataService} from '../data/profile-data.service';
import {ProjectDataService} from '../data/project-data.service';
import {ProjectProfileDataService} from '../data/project-profile-data.service';
import {ProtocolDataService} from '../data/protocol-data.service';
import {ProtocolEntryDataService} from '../data/protocol-entry-data.service';
import {ProtocolLayoutDataService} from '../data/protocol-layout-data.service';
import {ProtocolTypeDataService} from '../data/protocol-type-data.service';
import {UnitDataService} from '../data/unit-data.service';
import {UnitLevelDataService} from '../data/unit-level-data.service';
import {UnitProfileDataService} from '../data/unit-profile-data.service';
import {SyncService} from '../sync/sync.service';
import {SyncStrategy} from '../sync/sync-utils';
import {ParticipantDataService} from '../data/participant-data.service';
import {AlertService} from '../ui/alert.service';
import {SystemEventService} from '../event/system-event.service';
import {convertErrorToMessage} from '../../shared/errors';
import {LoadingController} from '@ionic/angular';

const LOG_SOURCE = 'UnitService';

interface UnitDeleteOptions {
  /** Whether or not to run a sync before the deletion to make sure, we've got the latest data. */
  runSyncBefore: boolean;
  /** Whether or not to run a sync after the deletion to make sure, we've got the latest data. */
  runSyncAfter: boolean;
  /** If all units of a level are deleted, also delete the UnitLevel. This is not relevant when calling deleteUnitLevel. */
  deleteEmptyUnitLevels: boolean;
  /** If a unit has a unitProfile assigned to, and the Profile is not assigned to any other units, delete the unitProfile/Profile. */
  deleteOrphanedProfiles: boolean;
  /** Ask the user for confirmation before deleting orphand profiles. */
  askUserBeforeDeleteOrphanedProfiles: boolean;
  /** If the unit is assigned to any entities (Protocols,  ProtocolEntries), update the entities and set the unitId to null. This will even update closed protocols.
   * If set to false and there are entities assigned, it will throw an error*/
  unassignUnitsFromEntities: boolean;
  /** Ask the user for confirmation before updating protocols and protocolEntries that have a unit assigned. */
  askUserBeforeUnassignUnitsFromEntities: boolean;
  /** Whether to logically delete profiles or not. Setting this to false may result in unresolvable sync errors!  */
  logicallyDeleteProfiles: boolean;
}

const UNIT_DELETE_DEFAULT_OPTIONS: UnitDeleteOptions = {
  runSyncBefore: true,
  runSyncAfter: true,
  deleteEmptyUnitLevels: true,
  deleteOrphanedProfiles: true,
  askUserBeforeDeleteOrphanedProfiles: true,
  unassignUnitsFromEntities: true,
  askUserBeforeUnassignUnitsFromEntities: true,
  logicallyDeleteProfiles: true
};

const UNIT_LEVEL_DELETE_DEFAULT_OPTIONS: UnitDeleteOptions = {
  ...UNIT_DELETE_DEFAULT_OPTIONS,
  deleteEmptyUnitLevels: false
};

interface OrphanedUnitProfilesDeleteInfo {
  participantsToDelete: Array<Participant>,
  addressesToDelete: Array<Address>,
  projectProfilesToDelete: Array<ProjectProfile>;
  profilesToDelete: Array<Profile>;
}

@Injectable({
  providedIn: 'root',
})
export class UnitService {

  unitsForBreadcrumbs$: Observable<Array<UnitForBreadcrumbs>>;
  unitsForBreadcrumbsAcrossProjects$: Observable<Array<UnitForBreadcrumbs>>;
  unitProfileAddresses$: Observable<Array<UnitProfileAddress>>;
  isFeatureEnabled$: Observable<boolean|undefined>
  unitProfileAddressesByUnitId$: Observable<Record<IdType, Array<UnitProfileAddress>>>;

  constructor(private unitProfileDataService: UnitProfileDataService,
              private profileDataService: ProfileDataService,
              private addressDataService: AddressDataService,
              private unitDataService: UnitDataService,
              private unitLevelDataService: UnitLevelDataService,
              private protocolDataService: ProtocolDataService,
              private protocolTypeDataService: ProtocolTypeDataService,
              private protocolLayoutDataService: ProtocolLayoutDataService,
              private protocolEntryDataService: ProtocolEntryDataService,
              private loggingService: LoggingService,
              private systemEventService: SystemEventService,
              private clientService: ClientService,
              private projectProfileDataService: ProjectProfileDataService,
              private projectDataService: ProjectDataService,
              private translateService: TranslateService,
              private syncService: SyncService,
              private participantDataService: ParticipantDataService,
              private alertService: AlertService,
              private loadingController: LoadingController,
              ) {
    this.unitsForBreadcrumbs$ = unitDataService.data.pipe(map((units) => unitsToUnitForBreadcrumbs(units)));
    this.unitsForBreadcrumbsAcrossProjects$ = unitDataService.dataAcrossProjects$.pipe(map((units) => unitsToUnitForBreadcrumbs(units)))
      .pipe(shareReplay({ bufferSize: 1, refCount: true}));
    this.unitProfileAddresses$ =
      combineLatestAsync([this.unitDataService.dataGroupedById, this.unitProfileDataService.data, this.profileDataService.dataGroupedById, this.addressDataService.dataGroupedById,
        this.projectProfileDataService.data])
      .pipe(map(([unitsById, unitProfiles, profilesById, addressesById, projectProfiles]) => {
        const unitProfileAddresses = new Array<UnitProfileAddress>();
        for (const unitProfile of unitProfiles.filter((up) => up.isActive && projectProfiles.some((projectProfile) => projectProfile.profileId === up.profileId))) {
          const unit = unitsById[unitProfile.unitId];
          const profile = profilesById[unitProfile.profileId];
          const address = addressesById[profile?.addressId];
          if (!unit || !profile || !address) {
            this.loggingService.warn(LOG_SOURCE, `unit, profile and/or address for unitProfile ${unitProfile.id} not found.`);
            continue;
          }
          unitProfileAddresses.push({
            ...unit,
            unitProfile,
            profile,
            address
          });
        }
        return _.orderBy(unitProfileAddresses, ['id', 'unitProfile.isDefault', 'unitProfile.name', 'address.lastName', 'address.firstName', 'unitProfile.id']);
        }
      )).pipe(shareReplay({ bufferSize: 1, refCount: true}));
    this.unitProfileAddressesByUnitId$ = this.unitProfileAddresses$.pipe(map((unitProfileAddresses) => _.groupBy(unitProfileAddresses, 'id')))
    this.isFeatureEnabled$ = this.clientService.getOwnClient().pipe(map((client) => client ? isUnitFeatureEnabledForClient(client.id) : undefined));
  }

  getUnitForBreadcrumbsById$(unitId: IdType): Observable<UnitForBreadcrumbs|undefined> {
    return this.unitsForBreadcrumbs$.pipe(map((units) => units.find((unit) => unit.id === unitId)));
  }

  getUnitForBreadcrumbsByIds$(unitIds: IdType[]): Observable<UnitForBreadcrumbs[]|undefined> {
    return this.unitsForBreadcrumbs$.pipe(map((units) => units.filter((unit) => unitIds.includes(unit.id))));
  }

  getUnitForBreadcrumbsByIdAcrossProjects$(unitId: IdType): Observable<UnitForBreadcrumbs|undefined> {
    return this.unitsForBreadcrumbsAcrossProjects$.pipe(map((units) => units.find((unit) => unit.id === unitId)));
  }

  getUnitProfileAddressesByUnitId$(unitId: IdType): Observable<Array<UnitProfileAddress>> {
    return this.unitProfileAddressesByUnitId$.pipe(map((dataByUnitId) => dataByUnitId[unitId] ?? []));
  }

  getUnitForBreadcrumbAndProfileAddressesByProtocolId$(protocolId: IdType): Observable<UnitForBreadcrumbsWithProfileAddresses | undefined> {
    return this.protocolDataService.getById(protocolId)
      .pipe(switchMap((protocol) => !protocol?.unitId ? of(undefined) :
        combineLatestAsync([this.getUnitForBreadcrumbsById$(protocol.unitId), this.getUnitProfileAddressesByUnitId$(protocol.unitId)])
          .pipe(map(([unitForBreadcrumbs, unitProfileAddresses]) => {
            if (!unitForBreadcrumbs) {
              return undefined;
            }
            return {...unitForBreadcrumbs, unitProfileAddresses: unitProfileAddresses ?? []}
          }))));
  }

  hasProtocolEntriesWithEmptyUnit(protocolId: IdType): Observable<boolean> {
    return this.protocolEntryDataService.getByProtocolId(protocolId)
      .pipe(map((protocolEntries) => protocolEntries.length && protocolEntries.some((protocolEntry) => !protocolEntry.unitId)));
  }

  async updateUnitOfProtocolEntriesWithEmptyUnit(protocolId: IdType, unitId: IdType): Promise<Array<ProtocolEntry>> {
    const protocol = await observableToPromise(this.protocolDataService.getById(protocolId));
    const unit = await observableToPromise(this.unitDataService.getById(unitId));
    if (!protocol) {
      throw new Error(`updateUnitOfAllProtocolEntriesIfAllEmpty - Protocol with id ${protocolId} not found.`);
    }
    if (protocol.closedAt) {
      throw new Error(`updateUnitOfAllProtocolEntriesIfAllEmpty - Unable to update entries of Protocol with id ${protocolId} as it is already closed.`);
    }
    if (!unit) {
      throw new Error(`updateUnitOfAllProtocolEntriesIfAllEmpty - Unit with id ${unitId} not found.`);
    }
    const protocolEntries = await observableToPromise(this.protocolEntryDataService.getByProtocolId(protocolId));
    if (!protocolEntries.length) {
      return [];
    }
    const protocolEntriesWithoutUnit = protocolEntries.filter((protocolEntry) => !protocolEntry.unitId);
    if (!protocolEntriesWithoutUnit.length) {
      return [];
    }
    const protocolEntriesToUpdate: Array<ProtocolEntry> = protocolEntriesWithoutUnit.map((protocolEntry) => {
      return {...protocolEntry, unitId};
    })
    await this.protocolEntryDataService.update(protocolEntriesToUpdate, protocol.projectId);
  }

  // This is a duplicate of the method in ProtocolService but we cannot use that without having a circular reference
  private getIsProtocolLayoutStandard$(protocolId: IdType, acrossProject: boolean = false): Observable<boolean|undefined> {
    const protocol$ = acrossProject ? this.protocolDataService.getByIdAcrossProjects(protocolId) : this.protocolDataService.getById(protocolId);
    return protocol$
      .pipe(switchMap((protocol) => !protocol?.typeId ? of (undefined) :
        (acrossProject ? this.protocolTypeDataService.getByIdAcrossClients(protocol.typeId) : this.protocolTypeDataService.getById(protocol.typeId))))
      .pipe(switchMap((protocolType) => !protocolType?.layoutId ? of (undefined) :
        (acrossProject ? this.protocolLayoutDataService.getByIdAcrossClients(protocolType.layoutId) : this.protocolLayoutDataService.getById(protocolType.layoutId))))
      .pipe(map((protocolLayout) => protocolLayout.name === PROTOCOL_LAYOUT_NAME_STANDARD));
  }

  getUnitDefaultForProtocol$(protocolId: IdType): Observable<UnitForBreadcrumbs|undefined|null> {
    return this.isFeatureEnabled$.pipe(switchMap((isFeatureEnabled) => !isFeatureEnabled ? of([undefined, undefined, undefined]) :
      combineLatestAsync([this.protocolDataService.getById(protocolId), this.getIsProtocolLayoutStandard$(protocolId), this.unitsForBreadcrumbs$])))
      .pipe(map(([protocol, isProtocolLayoutStandard, units]) => {
        if (!protocol || !isProtocolLayoutStandard) {
          return undefined;
        }
        if (!protocol.isUnitEntryDefault || !protocol.unitId) {
          return null;
        }
        return units.find((unit) => unit.id === protocol.unitId);
      }));
  }

  getUnitsForBreadcrumbsForProjectId(projectId: IdType): Observable<Array<UnitForBreadcrumbs>> {
    return this.unitLevelDataService.getDataForProject$(projectId)
      .pipe(map((unitLevels) => unitLevels ? unitLevels.map((unitLevel) => unitLevel.id) : []))
      .pipe(switchMap((unitLevelIds) => this.unitsForBreadcrumbsAcrossProjects$
        .pipe(map((unitsForBreadcrumbs) => unitsForBreadcrumbs.filter((unitForBreadcrumbs) => unitLevelIds.includes(unitForBreadcrumbs.unitLevelId))))));
  }

  async switchUnitPositions([unitA, unitB]: [Unit, Unit]) {
    if (!haveObjectsEqualProperties(unitA, unitB, ['unitLevelId', 'parentId'])) {
      throw new Error(`Cannot switch unit positions; units are not part of the same level and parent ` + 
        `(${unitA.id}[level=${unitA.unitLevelId},parent=${unitA.parentId}]) vs (${unitB.id}[level=${unitB.unitLevelId},parent=${unitB.parentId}])`);
    }

    const unitsOfLevel = await observableToPromise(this.unitDataService.getByUnitLevel(unitA.unitLevelId));
    const unitsWithSameParent = unitsOfLevel.filter((unit) => haveObjectsEqualProperties(unit, unitA, ['parentId']));
    const indexOfA = unitsWithSameParent.findIndex((unit) => unit.id === unitA.id);
    const indexOfB = unitsWithSameParent.findIndex((unit) => unit.id === unitB.id);

    if (indexOfA === -1 || indexOfB === -1) {
      throw new Error(`switchUnitPositions - unitA (${unitA.id}) and/or unitB (${unitA.id}) not found in list of units.`);
    }

    unitsWithSameParent[indexOfA] = unitB;
    unitsWithSameParent[indexOfB] = unitA;

    const unitsToUpdate = this.ensureSuccessiveUniqueIndex(unitsWithSameParent);

    if (unitsToUpdate.length) {
      const projectId = (await this.projectDataService.getMandatoryCurrentProject()).id;
      await this.unitDataService.update(unitsToUpdate, projectId);
    }
  }

  private ensureSuccessiveUniqueIndex(units: Unit[]): Unit[] {
    const unitsToUpdate = new Array<Unit>();

    for (let i = 0; i < units.length; i++) {
      const unit = units[i];
      if (unit.index !== i) {
        unit.index = i;
        unitsToUpdate.push(unit);
      }
    }

    return unitsToUpdate;
  }

  getEnsureIndexUpdatesForInsertWithPosition(unit: Unit, insertPosition?: CreateUnitInLevelEvent['insertPosition']): (storageData: Unit[]) => Unit[] {
    const updates = (storageData: Unit[]): Unit[] => {
      const unitsInLevelAndParent = orderBy(storageData
        .filter((u) => haveObjectsEqualProperties(u, unit, ['parentId', 'unitLevelId'])), ['index', 'name', 'id']);

      let indexOfNewUnit = unitsInLevelAndParent.findIndex((u) => u.id === unit.id);
      if (indexOfNewUnit === -1) {
        unitsInLevelAndParent.push(unit);
        indexOfNewUnit = unitsInLevelAndParent.length - 1;
      }

      const [newUnit] = unitsInLevelAndParent.splice(indexOfNewUnit, 1);
      if (!insertPosition) {
        unitsInLevelAndParent.push(newUnit);
      } else {
        const indexOfReferenceUnit = unitsInLevelAndParent.findIndex((u) => u.id === insertPosition.unit.id);
        if (indexOfReferenceUnit === -1) {
          unitsInLevelAndParent.push(newUnit);
        } else {
          // splice at `indexOfReferenceUnit` = insert above
          // splice at `indexOfReferenceUnit + 1` = insert below
          unitsInLevelAndParent.splice(indexOfReferenceUnit + (insertPosition.placement === 'below' ? 1 : 0), 0, newUnit);
        }
      }

      return this.ensureSuccessiveUniqueIndex(unitsInLevelAndParent);
    };

    return updates;
  }
  

  async duplicateUnit(unit: Unit) {
    const projectId = (await this.projectDataService.getMandatoryCurrentProject()).id;
    const allUnits = await observableToPromise(this.unitDataService.data);
    const unitsByParentId = groupBy(allUnits, 'parentId');
    const descendantUnits = this.getAllDescendantUnits(unit, unitsByParentId);
    const addressesById = await observableToPromise(this.addressDataService.dataGroupedById);

    const oldToNewId = new Map<IdType, IdType>();
    const unitsToInsert: Unit[] = [];
    const addressesToInsert: Address[] = [];

    const changedAt = new Date().toISOString();
    const createdAt = changedAt;

    const {unit: baseUnit, address: baseAddress} = this.getClonedUnitAndAddress(addressesById, oldToNewId, unit, changedAt, createdAt);

    const copySuffix = this.translateService.instant('copyWorkflow.copySuffix');
    if (baseUnit.name.length + copySuffix.length > 255) {
      baseUnit.name = baseUnit.name.slice(0, 255 - baseUnit.name.length - copySuffix.length);
    }
    baseUnit.name += copySuffix;
    baseUnit.index = 0; // Will be updated by `updates`
    unitsToInsert.push(baseUnit);
    if (baseAddress) {
      addressesToInsert.push(baseAddress);
    }

    for (const descendantUnit of descendantUnits) {
      const {unit: copiedUnit, address: copiedAddress} = this.getClonedUnitAndAddress(addressesById, oldToNewId, descendantUnit, changedAt, createdAt);
      unitsToInsert.push(copiedUnit);
      if (copiedAddress) {
        addressesToInsert.push(copiedAddress);
      }
    }

    const unitsToUpdate = this.getEnsureIndexUpdatesForInsertWithPosition(baseUnit, {unit, placement: 'below'});

    await this.addressDataService.insert(addressesToInsert);
    await this.unitDataService.insertUpdateDelete({
      inserts: unitsToInsert,
      updates: unitsToUpdate,
    }, projectId);
  }

  private getClonedUnitAndAddress(
    addressesById: Record<IdType, Address>,
    oldToNewId: Map<IdType, IdType>,
    sourceUnit: Unit,
    changedAt: string,
    createdAt: string
  ): {unit: Unit, address?: Address} {
    oldToNewId.set(sourceUnit.id, v4());
    if (sourceUnit.addressId) {
      oldToNewId.set(sourceUnit.addressId, v4());
    }

    const unit: Unit = {
      ...sourceUnit,
      parentId: oldToNewId.get(sourceUnit.parentId) ?? sourceUnit.parentId,
      id: oldToNewId.get(sourceUnit.id),
      changedAt,
      createdAt,
      addressId: sourceUnit.addressId ? oldToNewId.get(sourceUnit.addressId) : undefined,
    };

    if (!sourceUnit.addressId) {
      return {unit};
    }

    const sourceAddress = addressesById[sourceUnit.addressId];
    if (!sourceAddress) {
      throw new Error(`Inconsistent data; cannot find address with id ${sourceUnit.addressId} (source unit: ${sourceUnit.id})`);
    }

    const address: Address = {
      ...sourceAddress,
      changedAt,
      id: oldToNewId.get(sourceUnit.addressId)
    };

    return {unit, address};
  }

  private getAllDescendantUnits(unit: Unit, unitsByParentId: Record<IdType, Unit[]>): Unit[] {
    const childUnits = unitsByParentId[unit.id] ?? [];

    if (childUnits.length === 0) {
      return [];
    }

    return childUnits.concat(...childUnits.map((child) => this.getAllDescendantUnits(child, unitsByParentId)))
  }

  async createFromTemplate() {
    const project = await this.projectDataService.getMandatoryCurrentProject();
    const isGerman = project.language === 'de';

    const unitLevel1 = await this.createLevelForTemplate(project, 0, isGerman ? 'Haus' : 'Section');
    const unitLevel2 = await this.createLevelForTemplate(project, 1, isGerman ? 'Geschoss' : 'Level');
    const unitLevel3 = await this.createLevelForTemplate(project, 2, isGerman ? 'Wohneinheit' : 'Apartment');

    const house1 = await this.createUnitForTemplate(project, isGerman ? 'Haus 01' : 'Building 01', 0, unitLevel1.id);
    const house2 = await this.createUnitForTemplate(project, isGerman ? 'Haus 02' : 'Building 02', 1, unitLevel1.id);

    const floor1House1 = await this.createUnitForTemplate(project, isGerman ? 'OG' : '1', 0, unitLevel2.id, house1.id);
    const floor2House1 = await this.createUnitForTemplate(project, isGerman ? 'EG' : 'G', 1, unitLevel2.id, house1.id);

    const floor1House2 = await this.createUnitForTemplate(project, isGerman ? 'OG' : '1', 0, unitLevel2.id, house2.id);
    const floor2House2 = await this.createUnitForTemplate(project, isGerman ? 'EG' : 'G', 1, unitLevel2.id, house2.id);

    await this.createUnitForTemplate(project, isGerman ? 'Wohnung 04' : 'Apartment 04', 0, unitLevel3.id, floor1House1.id);
    await this.createUnitForTemplate(project, isGerman ? 'Wohnung 05' : 'Apartment 05', 1, unitLevel3.id, floor1House1.id);
    await this.createUnitForTemplate(project, isGerman ? 'Wohnung 06' : 'Apartment 06', 2, unitLevel3.id, floor1House1.id);

    await this.createUnitForTemplate(project, isGerman ? 'Wohnung 01' : 'Apartment 01', 0, unitLevel3.id, floor2House1.id);
    await this.createUnitForTemplate(project, isGerman ? 'Wohnung 02' : 'Apartment 02', 1, unitLevel3.id, floor2House1.id);
    await this.createUnitForTemplate(project, isGerman ? 'Wohnung 03' : 'Apartment 03', 2, unitLevel3.id, floor2House1.id);

    await this.createUnitForTemplate(project, isGerman ? 'Wohnung 04' : 'Apartment 04', 0, unitLevel3.id, floor1House2.id);
    await this.createUnitForTemplate(project, isGerman ? 'Wohnung 05' : 'Apartment 05', 1, unitLevel3.id, floor1House2.id);
    await this.createUnitForTemplate(project, isGerman ? 'Wohnung 06' : 'Apartment 06', 2, unitLevel3.id, floor1House2.id);

    await this.createUnitForTemplate(project, isGerman ? 'Wohnung 01' : 'Apartment 01', 0, unitLevel3.id, floor2House2.id);
    await this.createUnitForTemplate(project, isGerman ? 'Wohnung 02' : 'Apartment 02', 1, unitLevel3.id, floor2House2.id);
    await this.createUnitForTemplate(project, isGerman ? 'Wohnung 03' : 'Apartment 03', 2, unitLevel3.id, floor2House2.id);
  }

  async createLevelForTemplate(project: Project, index: number, name: string): Promise<UnitLevel> {
    const unitLevel: UnitLevel = {
      id: v4(),
      changedAt: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      index,
      name,
      projectId: project.id,
    };
    return (await this.unitLevelDataService.insert(unitLevel, project.id))?.[0];
  }

  async createUnitForTemplate(project: Project, name: string, index: number, unitLevelId: IdType, parentId?: IdType): Promise<Unit> {
    const address: Address = {
      id: v4(),
      changedAt: new Date().toISOString(),
      clientId: project.clientId,
    };

    await this.addressDataService.insert(address, address.clientId);

    const unit: Unit = {
      addressId: address.id,
      id: v4(),
      name,
      changedAt: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      index,
      unitLevelId,
      parentId
    };

    return (await this.unitDataService.insert(unit, project.id))?.[0];
  }

  async deleteUnitLevel(unitLevelToDelete: UnitLevel, partialOptions?: Partial<UnitDeleteOptions>, loading?: HTMLIonLoadingElement, abortSignal?: AbortSignal): Promise<boolean> {
    const options = {...UNIT_LEVEL_DELETE_DEFAULT_OPTIONS, ...partialOptions};
    const removingMessage = this.translateService.instant('units_settings.removing');
    const syncingMessage = this.translateService.instant('MENU.synchronizationInProgress');
    let dismissLoading = false;

    try {
      if (abortSignal?.aborted) {
        return false;
      }

      if (!loading) {
        loading = await this.loadingController.create({message: removingMessage});
        await loading.present();
        dismissLoading = true;
      }

      if (options.runSyncBefore) {
        loading.message = syncingMessage;
        const syncResult = await this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
        if (syncResult !== 'FINISHED') {
          throw new Error('Sync (before deleting started) failed.');
        }
        loading.message = removingMessage;
      }
      if (abortSignal?.aborted) {
        return false;
      }

      const unitLevels = await observableToPromise(this.unitLevelDataService.data);
      if (!unitLevels.length) {
        throw new Error(`deleteUnitLevel was called with unitLevel ${unitLevelToDelete.id} but no unitLevels are in the storage. Must have been deleted in the meantime.`);
      }
      if (unitLevelToDelete.id !== _.last(unitLevels)?.id) {
        throw new Error(`deleteUnitLevel was called with unitLevel ${unitLevelToDelete.id} (index${unitLevelToDelete.index} but only the last unitLevel can be deleted.`);
      }
      const unitsToDelete = await observableToPromise(this.unitDataService.getByUnitLevel(unitLevelToDelete.id));
      if (unitsToDelete.length) {
        const optionsDeleteUnitsRecursive: UnitDeleteOptions = {...options, runSyncBefore: false, runSyncAfter: false, deleteEmptyUnitLevels: false};
        const finished = await this.deleteUnitsRecursive(unitsToDelete, optionsDeleteUnitsRecursive, loading, abortSignal);
        if (!finished) {
          return false;
        }
      }
      if (abortSignal?.aborted) {
        return false;
      }

      if (options.deleteEmptyUnitLevels) {
        await this.deleteEmptyUnitLevels();
      } else {
        await this.unitLevelDataService.delete(unitLevelToDelete, unitLevelToDelete.projectId);
      }

      if (abortSignal?.aborted) {
        return false;
      }

      if (options.runSyncAfter) {
        loading.message = syncingMessage;
        await this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
      }
      return true;
    } finally {
      if (dismissLoading && loading) {
        await loading.dismiss();
      }
    }
  }

  async deleteUnitsRecursive(unitsToDelete?: Array<Unit>, partialOptions?: Partial<UnitDeleteOptions>, loading?: HTMLIonLoadingElement, abortSignal?: AbortSignal): Promise<boolean> {
    const options = {...UNIT_DELETE_DEFAULT_OPTIONS, ...partialOptions};
    const removingMessage = this.translateService.instant('units_settings.removing');
    const syncingMessage = this.translateService.instant('MENU.synchronizationInProgress');
    let dismissLoading = false;

    try {
      this.loggingService.info(LOG_SOURCE, `deleteUnitsRecursive called with ${unitsToDelete ? unitsToDelete.length : 'ALL'} units.`);
      this.systemEventService.logEvent(LOG_SOURCE + 'deleteUnitsRecursive', () => `deleteUnitsRecursive called with ${unitsToDelete ? unitsToDelete.length : 'ALL'} units.`);

      if (abortSignal?.aborted) {
        return false;
      }

      const createAndPresentLoading = async () => {
        loading = await this.loadingController.create({message: removingMessage});
        await loading.present();
        dismissLoading = true;
      }

      if (!loading) {
        await createAndPresentLoading();
      }

      if (options.runSyncBefore) {
        loading.message = syncingMessage;
        const syncResult = await this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
        if (syncResult !== 'FINISHED') {
          throw new Error('Sync (before deleting started) failed.');
        }
      }
      loading.message = removingMessage;
      if (abortSignal?.aborted) {
        return false;
      }

      this.loggingService.debug(LOG_SOURCE, `deleteUnitsRecursive - before collecting data.`);
      const unitLevels = await observableToPromise(this.unitLevelDataService.data);
      const unitLevelsById = _.keyBy(unitLevels, 'id');
      const units = await observableToPromise(this.unitDataService.data);
      const unitProfiles = await observableToPromise(this.unitProfileDataService.data);
      const unitsByParentId = _.groupBy(units, 'parentId');
      const unitsSortedForDeletion = _.orderBy(units, [(unit) => unitLevelsById[unit.unitLevelId].index, 'parentId', 'index'], ['desc', 'asc', 'asc']);
      const protocolsWithUnit = (await observableToPromise(this.protocolDataService.data)).filter((protocol) => protocol.unitId);
      const protocolsEntriesWithUnit = (await observableToPromise(this.protocolEntryDataService.data)).filter((protocolEntry) => protocolEntry.unitId);

      const rootUnitsToDelete = unitsToDelete ? unitsToDelete : units.filter((unit) => !unit.parentId);
      const unitsAndSubUnitsToDelete = this.findSubUnits(rootUnitsToDelete, unitsByParentId);
      const unitsAndSubUnitIdsToDelete = unitsAndSubUnitsToDelete.map((unit) => unit.id);

      const sortedUnitsToDelete = unitsSortedForDeletion.filter(((unit) => unitsAndSubUnitIdsToDelete.includes(unit.id)));
      const unitProfilesToDelete = unitProfiles.filter((unitProfile) => unitsAndSubUnitIdsToDelete.includes(unitProfile.unitId));
      const projectId = unitLevels[0].projectId;

      const protocolsWithAssignedUnit = protocolsWithUnit.filter((protocol) => unitsAndSubUnitIdsToDelete.includes(protocol.unitId));
      const protocolsEntriesWithAssignedUnit = protocolsEntriesWithUnit.filter((protocolEntry) => unitsAndSubUnitIdsToDelete.includes(protocolEntry.unitId));

      if (!options.unassignUnitsFromEntities && (protocolsWithAssignedUnit.length || protocolsEntriesWithAssignedUnit.length)) {
        throw new Error(`Unable to delete units as they are still assigned to protocols or protocolEntries`);
      }

      if ((protocolsWithAssignedUnit.length || protocolsEntriesWithAssignedUnit.length) && options.askUserBeforeUnassignUnitsFromEntities) {
        const updateCount = protocolsWithAssignedUnit.length + protocolsEntriesWithAssignedUnit.length;
        this.loggingService.info(LOG_SOURCE, `deleteUnitsRecursive - Asking user if he wants to update ${updateCount} Protocols/ProtocolEntries.`);
        this.systemEventService.logEvent(LOG_SOURCE + 'deleteUnitsRecursive', () => `Asking user if he wants to update ${updateCount} Protocols/ProtocolEntries.`);
        await loading?.dismiss();
        const updateConfirmed = await this.alertService.confirm({
          header: 'units_settings.remove_update_assigned_entities.header',
          message: this.translateService.instant('units_settings.remove_update_assigned_entities.message', {updateCount}),
          confirmLabel: 'units_settings.remove_update_assigned_entities.confirmLabel',
          cancelLabel: 'units_settings.remove_update_assigned_entities.cancelLabel',
          confirmButton: {
            color: 'danger',
            fill: 'solid'
          }
        });
        await createAndPresentLoading();
        if (!updateConfirmed) {
          this.loggingService.info(LOG_SOURCE, `deleteUnitsRecursive - User clicked on cancel. Does not want to update ${updateCount} Protocols/ProtocolEntries.`);
          this.systemEventService.logEvent(LOG_SOURCE + 'deleteUnitsRecursive', () => `User clicked on cancel. Does not want to update ${updateCount} Protocols/ProtocolEntries.`);
          return false;
        }
        this.loggingService.info(LOG_SOURCE, `deleteUnitsRecursive - User confirmed to update ${updateCount} Protocols/ProtocolEntries.`);
        this.systemEventService.logEvent(LOG_SOURCE + 'deleteUnitsRecursive', () => `User confirmed to update ${updateCount} Protocols/ProtocolEntries.`);
      }

      if (protocolsWithAssignedUnit.length) {
        const protocolsWithNullUnit = protocolsWithAssignedUnit.map((p) => {
          return {...p, unitId: null};
        });
        await this.protocolDataService.update(protocolsWithNullUnit, projectId);
      }
      if (protocolsEntriesWithAssignedUnit.length) {
        const protocolEntriesWithNullUnit = protocolsEntriesWithAssignedUnit.map((p) => {
          return {...p, unitId: null};
        });
        await this.protocolEntryDataService.update(protocolEntriesWithNullUnit, projectId);
      }
      if (abortSignal?.aborted) {
        return false;
      }

      await this.unitProfileDataService.delete(unitProfilesToDelete, projectId);
      await this.unitDataService.delete(sortedUnitsToDelete, projectId);
      if (abortSignal?.aborted) {
        return false;
      }
      if (unitProfilesToDelete?.length) {
        const profileIdsToDelete = unitProfilesToDelete.map((unitProfile) => unitProfile.profileId);
        const unitProfileDeleteInfo = await this.determineOrphanedUnitProfilesToDelete(profileIdsToDelete, projectId);

        let reallyDeleteOrphanedProfiles = options.deleteOrphanedProfiles;
        if (unitProfileDeleteInfo.profilesToDelete.length && options.deleteOrphanedProfiles && options.askUserBeforeDeleteOrphanedProfiles) {
          const deleteCount = unitProfileDeleteInfo.profilesToDelete.length;
          this.loggingService.info(LOG_SOURCE, `deleteUnitsRecursive - Asking user if he wants to delete ${deleteCount} orphaned profiles.`);
          this.systemEventService.logEvent(LOG_SOURCE + 'deleteUnitsRecursive', () => `Asking user if he wants to delete ${deleteCount} orphaned profiles.`);
          await loading?.dismiss();
          reallyDeleteOrphanedProfiles = await this.alertService.confirm({
            header: 'units_settings.remove_orphaned_profiles.header',
            message: this.translateService.instant('units_settings.remove_orphaned_profiles.message', {deleteCount}),
            confirmLabel: 'units_settings.remove_orphaned_profiles.confirmLabel',
            cancelLabel: 'units_settings.remove_orphaned_profiles.cancelLabel',
            confirmButton: {
              color: 'danger',
              fill: 'solid'
            }
          });
          await createAndPresentLoading();
        }

        if (reallyDeleteOrphanedProfiles) {
          await this.deleteOrphanedUnitProfiles(unitProfileDeleteInfo, projectId, options);
        }
      }

      if (options.deleteEmptyUnitLevels) {
        await this.deleteEmptyUnitLevels();
      }
      if (abortSignal?.aborted) {
        return false;
      }

      this.loggingService.info(LOG_SOURCE, `deleteUnitsRecursive finished successfully (sync still needs to run)`);
      this.systemEventService.logEvent(LOG_SOURCE + 'deleteUnitsRecursive', `deleteUnitsRecursive finished successfully (sync still needs to run)`);
      if (options.runSyncAfter) {
        loading.message = syncingMessage;
        await this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
        loading.message = removingMessage;
      }
      return true;
    } catch (error) {
      this.loggingService.error(LOG_SOURCE, `deleteUnitsRecursive failed with error: ${convertErrorToMessage(error)}`);
      this.systemEventService.logErrorEvent(LOG_SOURCE + 'deleteUnitsRecursive', error);
      throw error;
    } finally {
      if (dismissLoading && loading) {
        await loading.dismiss();
      }
    }
  }

  private async getEmptyUnitLevels(): Promise<Array<UnitLevel>> {
    const unitLevels = await observableToPromise(this.unitLevelDataService.data);
    const unitsByUnitLevelId = _.groupBy(await observableToPromise(this.unitDataService.data), 'unitLevelId');

    const emptyUnitLevels = new Array<UnitLevel>();
    for (let i=unitLevels.length-1; i>= 0; i--) {
      const unitLevel = unitLevels[i];
      if (unitsByUnitLevelId[unitLevel.id]?.length) {
        break;
      }
      emptyUnitLevels.push(unitLevel);
    }

   return emptyUnitLevels;
  }

  private async deleteEmptyUnitLevels() {
    const unitLevelsToDelete = await this.getEmptyUnitLevels();
    if (unitLevelsToDelete.length) {
      const projectId = unitLevelsToDelete[0].projectId
      await this.unitLevelDataService.delete(unitLevelsToDelete, projectId);
    }
  }

  private async determineOrphanedUnitProfilesToDelete(profileIds: Array<IdType>, projectId: IdType): Promise<OrphanedUnitProfilesDeleteInfo> {
    const profiles = await observableToPromise(this.profileDataService.getByIds(profileIds));
    const unitProfilesByProfileId = _.groupBy(await observableToPromise(this.unitProfileDataService.data), 'profileId');
    const projectProfilesByProfileId = _.groupBy(await observableToPromise(this.projectProfileDataService.data), 'profileId');
    const addressesById = await observableToPromise(this.addressDataService.dataGroupedById);
    const allParticipants = await observableToPromise(this.participantDataService.data);
    const participantsByProfileId = _.groupBy(allParticipants.filter((participant) => profileIds.includes(participant.profileId)), 'profileId');

    const participantsToDelete = new Array<Participant>();
    const addressesToDelete = new Array<Address>();
    const projectProfilesToDelete = new Array<ProjectProfile>();
    const profilesToDelete = new Array<Profile>();
    for (const profile of profiles) {
      if (profile.type !== 'UNIT_CONTACT') {
        throw new Error(`deleteOrphanUnitProfiles - got to delete a profile ${profile.id} that is not of type "UNIT_CONTACT". Check implementation.`);
      }
      if (unitProfilesByProfileId[profile.id]?.length) {
        this.loggingService.info(LOG_SOURCE, `deleteOrphanedUnitProfiles - Cannot deleted profile ${profile.id} as it is being referenced by ${unitProfilesByProfileId[profile.id]?.length} units.`);
        continue; // still being used
      }
      const participants = participantsByProfileId[profile.id];
      if (participants?.length) {
        // Unit contacts can only be used for sending protocols (no reports or global search)
        const participantsCanBeDeleted = participants.filter((participant) => participant.protocolId && !participant.pdfpreviewId);
        if (participantsToDelete.length !== participants.length) {
          // already being used to send protocols
          this.loggingService.info(LOG_SOURCE, `deleteOrphanedUnitProfiles - Cannot deleted profile ${profile.id} as it was already used as participant.`);
          continue;
        }
        participantsToDelete.push(...participantsCanBeDeleted);
      }
      if (addressesById[profile.addressId]) {
        addressesToDelete.push(addressesById[profile.addressId]);
      }
      if (projectProfilesByProfileId[profile.id]?.length) {
        const projectProfiles = projectProfilesByProfileId[profile.id];
        if (projectProfiles.length > 1) {
          throw new Error(`deleteOrphanUnitProfiles - found ${projectProfiles.length} projectProfiles for profile ${profile.id} but UNIT_CONTACT profiles should only be assigned to one project.`);
        }
        const projectProfile = projectProfiles[0];
        if (projectProfile.projectId !== projectId) {
          throw new Error(`deleteOrphanUnitProfiles - found ${projectProfile.id} projectProfile assinged to project ${projectProfile.projectId} but should be project ${projectId}.`);
        }
        projectProfilesToDelete.push(projectProfile);
      }
      profilesToDelete.push(profile);
    }

    return {
      participantsToDelete,
      addressesToDelete,
      projectProfilesToDelete,
      profilesToDelete
    }
  }

  private async deleteOrphanedUnitProfiles(info: OrphanedUnitProfilesDeleteInfo, projectId: IdType, options: UnitDeleteOptions) {
    if (info.participantsToDelete?.length && !options.logicallyDeleteProfiles) {
      await this.participantDataService.delete(info.participantsToDelete, projectId);
    }
    if (info.projectProfilesToDelete?.length && !options.logicallyDeleteProfiles) {
      await this.projectProfileDataService.delete(info.projectProfilesToDelete, projectId);
    }
    if (info.profilesToDelete?.length) {
      if (options.logicallyDeleteProfiles) {
        const profilesDeactivated = info.profilesToDelete.map((profile) => {
          return {...profile, isActive: false};
        })
        await this.profileDataService.update(profilesDeactivated);
      } else {
        await this.profileDataService.delete(info.profilesToDelete);
      }
    }
    if (info.addressesToDelete?.length && !options.logicallyDeleteProfiles) {
      await this.addressDataService.delete(info.addressesToDelete);
    }
  }

  private findSubUnits(units: Array<Unit>, unitsByParentId: Record<IdType, Unit[]>): Unit[] {
    if (!units.length) {
      return [];
    }
    let unitsFound = new Array<Unit>();

    for (const unit of units) {
      const subUnits = unitsByParentId[unit.id];
      if (!subUnits?.length) {
        continue;
      }
      unitsFound = [...this.findSubUnits(subUnits, unitsByParentId), ...unitsFound];
    }

    return [...unitsFound, ...units];
  }
}
