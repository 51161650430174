import { ColumnConfig } from '../ColumnConfig';

export const StaffColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'status_editor', prop: 'statusEditor', def: null},
  {name: 'status_employee', prop: 'statusEmployee', def: null},
  {name: 'is_deleted', prop: 'isDeleted'},
  {name: 'display_name', prop: 'displayName', def: null},
  {name: 'count', prop: 'count', def: null},
  {name: 'hours', prop: 'hours', def: null},
  {name: 'modified_by_id', prop: 'modifiedById', def: null},
  {name: 'name_id', prop: 'nameId', def: null},
  {name: 'staffing_type_id', prop: 'staffingTypeId', def: null},
  {name: 'parent_id', prop: 'parentId', def: null},
  {name: 'report_id', prop: 'reportId'},
  {name: 'working_time_from_1', prop: 'workingTimeFrom1', cast: 'Time', def: null},
  {name: 'working_time_to_1', prop: 'workingTimeTo1', cast: 'Time', def: null},
  {name: 'working_time_from_2', prop: 'workingTimeFrom2', cast: 'Time', def: null},
  {name: 'working_time_to_2', prop: 'workingTimeTo2', cast: 'Time', def: null},
  {name: 'working_time_from_3', prop: 'workingTimeFrom3', cast: 'Time', def: null},
  {name: 'working_time_to_3', prop: 'workingTimeTo3', cast: 'Time', def: null},
  {name: 'working_time_from_4', prop: 'workingTimeFrom4', cast: 'Time', def: null},
  {name: 'working_time_to_4', prop: 'workingTimeTo4', cast: 'Time', def: null},
  {name: 'working_time_from_5', prop: 'workingTimeFrom5', cast: 'Time', def: null},
  {name: 'working_time_to_5', prop: 'workingTimeTo5', cast: 'Time', def: null},
  {name: 'use_time_blocks', prop: 'useTimeBlocks', def: false},
];

StaffColumnSet.push(
  {name: 'staffs', fk: 'parentId', mappedBy: StaffColumnSet, transient: true}
);
