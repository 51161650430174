import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import {interval, Subscription} from 'rxjs';
import {LoggingService} from './logging.service';
import {StorageKeyEnum} from 'src/app/shared/constants';
import {StorageService} from '../storage.service';
import {AlertService} from '../ui/alert.service';
import {Device} from '@capacitor/device';
import {startWith} from 'rxjs/operators';
import {environment} from 'src/environments/environment';

const LOG_SOURCE = 'FirefoxService';
const STORAGE_KEY = StorageKeyEnum.FIREFOX_REMIND_DATE;
const CHECK_FOR_UPDATES_INTERVAL_IN_MS = 30 * 60 * 1000;
const TIME_UNTIL_NEXT_FIREFOX_REMINDER = 24 * 60 * 60 * 1000;
const WINDOWS_OS = 'windows';

@Injectable({
  providedIn: 'root'
})
export class FirefoxService {

  private checkForFirefoxSubscription: Subscription|undefined;
  private alertOpen = false;

  constructor(private platform: Platform, private loggingService: LoggingService, private alertService: AlertService, private storage: StorageService) { }

  public async startCheckForFirefox() {
    const isFirefox = typeof navigator !== 'undefined' ? !!navigator?.userAgent?.includes('Firefox') : false;
    if (!this.platform.is('desktop') || !isFirefox) {
      this.loggingService.info(LOG_SOURCE, 'No need to check - device is either no desktop or not using firefox');
      return;
    }
    const isWindows = (await Device.getInfo()).operatingSystem === WINDOWS_OS;
    this.loggingService.info(LOG_SOURCE, 'startCheckForFirefox');
    this.checkForFirefoxSubscription?.unsubscribe();
    this.checkForFirefoxSubscription = interval(CHECK_FOR_UPDATES_INTERVAL_IN_MS).pipe(startWith(0)).subscribe(async () => {
      this.loggingService.info(LOG_SOURCE, 'checking for firefox.');
      if (isFirefox && !this.alertOpen && await this.shouldShowAlert() ) {
        const confirm = await this.openFirefoxAlert(isWindows);
        if (confirm && isWindows) {
          window.open(environment.microsoftStoreLink);
        } else {
          await this.storage.set(STORAGE_KEY, new Date().getTime());
        }
        this.alertOpen = false;
      }
    });
  }

  public async openFirefoxAlert(isWindows: boolean): Promise<boolean> {
    this.alertOpen = true;
    if (isWindows) {
      return await this.alertService.confirm({
        message: 'firefox.windows.message',
        cancelLabel: 'close',
        confirmLabel: 'firefox.windows.button',
        confirmButton: {
          color: 'text-primary',
          fill: 'solid',
        }
      });
    } else {
      return await this.alertService.ok({
        message: 'firefox.message',
        confirmLabel: 'close',
      });
    }
  }

  private async shouldShowAlert(): Promise<boolean> {
    const timestamp = await this.storage.get(STORAGE_KEY);
    if (timestamp) {
      const difference = (new Date().getTime()) - timestamp;
      return difference >= TIME_UNTIL_NEXT_FIREFOX_REMINDER;
    } else {
      return true;
    }
  }
}
