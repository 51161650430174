import {Injectable} from '@angular/core';
import {combineLatest, Observable, of} from 'rxjs';
import {
  Address,
  Company,
  CompanyCraft,
  Craft,
  hasUserInviteExpired,
  IdType,
  Participant,
  Profile,
  ProfileCraft,
  ProjectCompany,
  ProjectProfile,
  Protocol,
  ProtocolEntry,
  User,
  UserConnectionInvite,
  UserInvite
} from 'submodules/baumaster-v2-common';
import {debounceTime, filter, map, switchMap} from 'rxjs/operators';
import {ProjectCompanyDataService} from '../data/project-company-data.service';
import {AddressDataService} from '../data/address-data.service';
import {ProjectProfileDataService} from '../data/project-profile-data.service';
import {CompanyCraftDataService} from '../data/company-craft-data.service';
import {CraftDataService} from '../data/craft-data.service';
import {CompanyDataService} from '../data/company-data.service';
import {ProfileDataService} from '../data/profile-data.service';
import _ from 'lodash';
import {ProjectDataService} from '../data/project-data.service';
import {CompanySource, Employee, ProfileCompanyAddress, umlautMap} from '../../model/contacts';
import {ProtocolEntryDataService} from '../data/protocol-entry-data.service';
import {combineLatestAsync, observableToPromise} from '../../utils/async-utils';
import {ProfileCraftDataService} from '../data/profile-craft-data.service';
import {ProtocolDataService} from '../data/protocol-data.service';
import {ParticipantDataService} from '../data/participant-data.service';
import {UserDataService} from '../data/user-data.service';
import {UserInviteDataService} from '../data/user-invite-data.service';
import {UserConnectionInviteDataService} from '../data/user-connection-invite-data.service';
import {ReportDataService} from '../data/report-data.service';
import {ContactsFilterService} from './contacts-filter.service';
import {EMPTY_FILTER_ID} from 'src/app/utils/filter-utils';
import {NotificationConfigRecipientDataService} from '../data/notification-config-recipient-data.service';
import {UnitProfileDataService} from '../data/unit-profile-data.service';
import {UnitService} from '../unit/unit.service';
import {UnitForBreadcrumbsWithProfileAddresses} from '../../model/unit';
import {UserPublicDataService} from '../data/user-public-data.service';
import {AuthenticationService} from '../auth/authentication.service';

const LOG_SOURCE = 'ContactService';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  public readonly sortedCompanies$: Observable<Array<CompanySource>> | undefined;
  public readonly sortedCompaniesAcrossProjects$: Observable<Array<CompanySource>> | undefined;
  public readonly addressByUserId$: Observable<Record<IdType, Address>> = combineLatestAsync([
    this.userDataService.data,
    this.profileDataService.dataGroupedByIdWithDefaultType$,
    this.addressDataService.dataGroupedById
  ]).pipe(
    map(([users, profileById, addressById]) => users.reduce((addressByUserId, user) => {
      const profile = profileById[user.profileId];
      if (!profile) {
        return addressByUserId;
      }
      const address = addressById[profile.addressId];
      if (!address) {
        return addressByUserId;
      }
      addressByUserId[user.id] = address;
      return addressByUserId;
    }, {}))
  );

  constructor(private projectCompanyDataService: ProjectCompanyDataService,
              private addressDataService: AddressDataService,
              private projectProfileDataService: ProjectProfileDataService,
              private companyCraftDataService: CompanyCraftDataService,
              private craftDataService: CraftDataService,
              private companyDataService: CompanyDataService,
              private profileDataService: ProfileDataService,
              private profileCraftDataService: ProfileCraftDataService,
              private projectDataService: ProjectDataService,
              private protocolDataService: ProtocolDataService,
              private protocolEntryDataService: ProtocolEntryDataService,
              private participantDataService: ParticipantDataService,
              private userDataService: UserDataService,
              private userInviteDataService: UserInviteDataService,
              private userConnectionInviteDataService: UserConnectionInviteDataService,
              private reportDataService: ReportDataService,
              private contactsFilterService: ContactsFilterService,
              private notificationConfigRecipientDataService: NotificationConfigRecipientDataService,
              private unitProfileDataService: UnitProfileDataService,
              private unitService: UnitService,
              private authenticationService: AuthenticationService,
              private userPublicDataService: UserPublicDataService,
              ) {
    this.sortedCompanies$ =
      combineLatest([companyCraftDataService.dataForOwnClient$, craftDataService.dataForOwnClientWithDeletedSuffix$, addressDataService.dataForOwnClient$,
        profileDataService.dataForOwnClientWithDefaultType$, companyDataService.dataForOwnClient$, profileCraftDataService.dataForOwnClient$,
        projectCompanyDataService.data, projectProfileDataService.data, protocolEntryDataService.data,
        userDataService.data, userInviteDataService.data, userConnectionInviteDataService.data]).pipe(
          debounceTime(0),
          filter((allData) => allData.every((data) => Boolean(data))),
        map(([companyCrafts, crafts, addresses, profiles, companies, profileCrafts, projectCompanies, projectProfiles, protocolEntries, users, userInvites, userConnectionInvites]) =>
          this.mapFunction([companyCrafts, crafts, addresses, profiles, companies, profileCrafts, projectCompanies, projectProfiles, protocolEntries, users, userInvites, userConnectionInvites,
            undefined, undefined]))
      );
    this.sortedCompaniesAcrossProjects$ =
      combineLatest([companyCraftDataService.dataForOwnClient$, craftDataService.dataForOwnClient$, addressDataService.dataForOwnClient$,
        profileDataService.dataForOwnClientWithDefaultType$, companyDataService.dataForOwnClient$, profileCraftDataService.dataForOwnClient$,
        projectCompanyDataService.dataAcrossProjects$, projectProfileDataService.dataAcrossProjects$, protocolEntryDataService.dataAcrossProjects$,
        userDataService.data, userInviteDataService.data, userConnectionInviteDataService.data]).pipe(
        debounceTime(0),
        filter((allData) => allData.every((data) => Boolean(data))),
        map(([companyCrafts, crafts, addresses, profiles, companies, profileCrafts, projectCompanies, projectProfiles, protocolEntries, users, userInvites, userConnectionInvites]) =>
          this.mapFunction([companyCrafts, crafts, addresses, profiles, companies, profileCrafts, projectCompanies, projectProfiles, protocolEntries, users, userInvites, userConnectionInvites,
            undefined, undefined]))
      );
  }

  private getOwnCompany$(): Observable<Company|undefined> {
    return combineLatestAsync([this.authenticationService.authenticatedUserId$, this.userPublicDataService.data, this.companyDataService.data, this.profileDataService.dataWithDefaultType$]).pipe(
      map(([authenticatedUserId, userPublics, companies, profiles]) => {
        const currentUserPublic = userPublics.find(user => user.id === authenticatedUserId);
        const currentProfile = profiles.find(profile => profile.id === currentUserPublic?.profileId);
        return companies.find(company => company.id === currentProfile?.companyId);
      })
    );
  }

  private mapFunction = ([companyCrafts, crafts, addresses, profiles, companies, profileCrafts, projectCompanies, projectProfiles,
                           protocolEntries, users, userInvites, userConnectionInvites, ownCompany, unitForBreadcrumbsWithProfileAddresses]:
                           [Array<CompanyCraft>, Array<Craft>, Array<Address>, Array<Profile>, Array<Company>, Array<ProfileCraft>, Array<ProjectCompany>, Array<ProjectProfile>,
                             Array<ProtocolEntry>, Array<User>, Array<UserInvite>, Array<UserConnectionInvite>,
                               Company|undefined, UnitForBreadcrumbsWithProfileAddresses | undefined]): Array<CompanySource> => {
    // @ts-ignore
    const profileIdsInProjectProfiles: Array<IdType> = _.uniq(_.compact((projectProfiles.map((v) => v.profileId))));
    const companyContacts = companies.map((company) => {
      const craftIds = _.map(_.filter(companyCrafts, {companyId: company.id}), 'craftId');
      const profilesAndAssignedUnitProfiles = profiles.filter((profile) => !profile.type || (profile.type === 'UNIT_CONTACT' && profileIdsInProjectProfiles.includes(profile.id)));
      const addressIds = _.map(_.filter(profilesAndAssignedUnitProfiles, {companyId: company.id}), 'addressId');
      const filteredAddresses = _.orderBy(_.filter(addresses, address => addressIds.includes(address.id)), ['firstName', 'lastName'], ['asc', 'asc']);
      const currentCrafts = _.filter(crafts, craft => craftIds.includes(craft.id));
      const internalAssignmentIds = _.map(_.filter(protocolEntries, {companyId: company.id}), 'internalAssignmentId').filter(internalAssignmentId => internalAssignmentId);
      const usersByProfileId: {[key in IdType]: User} = _.keyBy(users, 'profileId');
      const userInvitesByProfileId: {[key in IdType]: UserInvite}  = _.keyBy(userInvites, 'profileId');
      const userConnectionInvitesByProfileId: {[key in IdType]: UserConnectionInvite}  = _.keyBy(userConnectionInvites, 'profileId');
      const isUserCompany = ownCompany ? company.id === ownCompany.id : undefined;
      const employees: Array<Employee> = filteredAddresses.map((address) => {
        const profile: Profile = _.find(profilesAndAssignedUnitProfiles, profileData => address.id === profileData.addressId);
        const profileId = profile.id;
        const employeeCraftIds = _.map(_.filter(profileCrafts, {profileId}), 'craftId');
        const employee: Employee = {
          ...address,
          profile: profile,
          crafts: _.filter(crafts, craft => employeeCraftIds.includes(craft.id)),
          projectProfileId: profileId,
          projectProfile: _.find(projectProfiles, projectProfile => profileId === projectProfile.profileId),
          projectProfiles: _.filter(projectProfiles, projectProfile => profileId === projectProfile.profileId),
          removedUnitProfile: !unitForBreadcrumbsWithProfileAddresses || profile.type !== 'UNIT_CONTACT' ? undefined :
            !unitForBreadcrumbsWithProfileAddresses.unitProfileAddresses?.some((v) => v.profile?.id === profile.id),
          hasProtocol: internalAssignmentIds.includes(profileId),
          user: usersByProfileId[profileId],
          userInvite: userInvitesByProfileId[profileId],
          userConnectionInvite: userConnectionInvitesByProfileId[profileId]
        }
        return employee;
      });
      const letter = company.name.toUpperCase().charAt(0) || '-';
      const firstLetter = !isNaN(Number(letter)) ? '#' : umlautMap[letter] || letter;
      const companyContact: CompanySource = {
        hasProtocol: _.some(_.filter(protocolEntries, {companyId: company.id})),
        assignedEntries: _.filter(protocolEntries, {companyId: company.id}),
        groupName: firstLetter,
        crafts: currentCrafts,
        employees,
        projectCompany: _.find(projectCompanies, projectCompany => company.id === projectCompany.companyId),
        projectCompanies: _.filter(projectCompanies, projectCompany => company.id === projectCompany.companyId),
        isUserCompany,
        ...company
      };
      return companyContact;
    });
    return this.sortByName(companyContacts);
  };

  order(a: CompanySource, b: CompanySource): number {
    const firstKey = a.name;
    const secondKey = b.name;
    return firstKey === '#' ? 1 : secondKey === '#' ? -1 : firstKey.localeCompare(secondKey);
  }

  private sortByName(sortedCompanies: Array<CompanySource>): Array<CompanySource> {
    let prevGroupName;
    const companySources = sortedCompanies.sort(this.order);
    return _.map(companySources, company => {
      company.firstElementInGroup = prevGroupName !== company.groupName;
      prevGroupName = company.groupName;
      return company;
    });
  }

  public async addCompanyToProject(companyId: string): Promise<ProjectCompany | undefined> {
    const projectCompanies = await observableToPromise(this.projectCompanyDataService.data);
    const currentProject = await this.projectDataService.getCurrentProject();

    if (projectCompanies.find(projectCompany => projectCompany.companyId === companyId && projectCompany.projectId === currentProject.id)) {
      return;
    }

    const projectCompanyData: ProjectCompany = {
      id: currentProject.id + companyId,
      companyId,
      projectId: currentProject.id,
      changedAt: new Date().toISOString()
    };
    await this.projectCompanyDataService.insert(projectCompanyData, currentProject.id);
    return projectCompanyData;
  }

  public async removeCompanyFromProject(projectCompany: ProjectCompany) {
    const currentProject = await this.projectDataService.getCurrentProject();
    const projectCompanies = await observableToPromise(this.projectCompanyDataService.data);
    const projectCompaniesToDelete = projectCompanies.filter((value) =>
      value.projectId === currentProject.id && value.projectId === projectCompany.projectId && value.companyId === projectCompany.companyId);
    await this.projectCompanyDataService.delete(projectCompaniesToDelete, currentProject.id);
  }

  public async addContactToProject(profileId: string): Promise<ProjectProfile | undefined> {
    const projectProfiles = await observableToPromise(this.projectProfileDataService.data);
    const currentProject = await this.projectDataService.getCurrentProject();

    if (!currentProject) {
      return;
    }

    if (projectProfiles.find(projectProfile => projectProfile.profileId === profileId && projectProfile.projectId === currentProject.id)) {
      return;
    }

    const newProjectProfile: ProjectProfile = {
      id: currentProject.id + profileId,
      profileId,
      projectId: currentProject.id,
      changedAt: new Date().toISOString()
    };
    await this.projectProfileDataService.insert(newProjectProfile, currentProject.id);
    return newProjectProfile;
  }

  public async removeContactToProject(contacts: Employee[]) {
    const currentProject = await this.projectDataService.getCurrentProject();
    const projectProfiles = await observableToPromise(this.projectProfileDataService.data);
    const projectProfilesToDelete = projectProfiles.filter((projectProfile) => projectProfile.projectId === currentProject.id &&
      contacts.find((contact) => contact.projectProfile && contact.projectProfile.profileId === projectProfile.profileId && contact.projectProfile.projectId === projectProfile.projectId));
    await this.projectProfileDataService.delete(projectProfilesToDelete, currentProject.id);
    await this.notificationConfigRecipientDataService.deleteByProfileIds(projectProfilesToDelete.map(projectProfile => projectProfile.profileId));
    for (const contact of contacts) {
      const participantsToDelete = await this.getParticipantsOfNotClosedReferences(contact.profile.id);
      await this.participantDataService.delete(participantsToDelete, currentProject.id);
    }
  }

  private async getParticipantsOfNotClosedReferences(profileId: IdType): Promise<Array<Participant>> {
    const participants = await observableToPromise(this.participantDataService.getByProfileId(profileId));
    const filteredParticipants = new Array<Participant>();
    for (const participant of participants) {
      if (participant.pdfpreviewId || participant.seenAt) {
        continue;
      }
      if (participant.protocolId) {
        const protocol = await observableToPromise(this.protocolDataService.getByIdAcrossProjects(participant.protocolId));
        if (!protocol) {
          throw new Error(`Unable to find protocol with id ${participant.protocolId}`);
        }
        if (protocol.closedAt) {
          continue;
        }
      }
      if (participant.reportId) {
        const report = await observableToPromise(this.reportDataService.getByIdAcrossProjects(participant.reportId));
        if (!report) {
          throw new Error(`Unable to find report  with id ${participant.reportId}`);
        }
        if (report.closedAt) {
          continue;
        }
      }
      filteredParticipants.push(participant);
    }
    return filteredParticipants;
  }

  public getSortedCompaniesActiveOnly(): Observable<CompanySource[]> {
    return this.sortedCompanies$.pipe(map((companySources) => {
      const activeCompanySources: CompanySource[] = companySources.filter((companySource) => companySource.isActive === undefined || companySource.isActive);
      activeCompanySources.forEach((companySource) => {
        companySource.employees = _.sortBy(companySource.employees.filter(employee => employee.profile.isActive === undefined || employee.profile.isActive),
          [employee => employee.lastName?.toLowerCase()]);
      });
      return activeCompanySources;
    }));
  }

  public getFilteredAndSortedCompaniesActiveOnly(): Observable<CompanySource[]> {
    return combineLatestAsync([this.getSortedCompaniesActiveOnly(), this.contactsFilterService.filter$]).pipe(
      map(([companies, filters]) => companies.filter((company) => filters.craftIds.length === 0 ||
        company.crafts.some((craft) => filters.craftIds.includes(craft.id)) || (filters.craftIds.includes(EMPTY_FILTER_ID) && _.isEmpty(company.crafts)))
        .map((company) => ({
          ...company,
          employees: company.employees.filter((employee) => filters.craftIds.length === 0 ||
            employee.crafts.some((craft) => filters.craftIds.includes(craft.id)) || (filters.craftIds.includes(EMPTY_FILTER_ID) && _.isEmpty(employee.crafts)))
        })))
    );
  }

  public isProfileAssignedToOpenEntry(profile: Profile): Observable<boolean> {
    return combineLatest([this.protocolEntryDataService.dataByProjectId$, this.protocolDataService.dataWithoutHiddenByProjectId$]).pipe(map(([allEntriesMap, allProtocolsMap]) => {
      const entries: ProtocolEntry[] = _.flatten(Array.from(allEntriesMap.values())).filter((entry) => entry.internalAssignmentId === profile.id);
      const nonClosedProtocols: Protocol[] = _.flatten(Array.from(allProtocolsMap.values())).filter((protocol) => !protocol.closedAt);
      for (const entry of entries) {
        const isAssigned = nonClosedProtocols.some(protocol => protocol.id === entry.protocolId);
        if (isAssigned) {
          return true;
        }
      }
      return false;
    }));
  }

  public isProfileAnActiveUserOrActiveUserInvite(profile: Profile): Observable<boolean> {
    return combineLatest([this.userDataService.data, this.userInviteDataService.dataForOwnClient$]).pipe(debounceTime(0), map(([users, userInvites]) => {
      const activeUser = users.some((user) => user.profileId === profile.id && user.isActive);
      const activeUserInvite = userInvites.some((userInvite) => userInvite.profileId === profile.id && !hasUserInviteExpired(userInvite));
      return activeUser || activeUserInvite;
    }));
  }

  public async deleteContact(profile: Profile, isUnitContact = false) {
    profile.isActive = false;
    await this.profileDataService.update(profile, profile.clientId);
    await this.removeContactFromProject([profile]);
    const currentProject = await this.projectDataService.getCurrentProject();
    await this.notificationConfigRecipientDataService.deleteByProfileId(profile.id);
    const participantsToDelete = await this.getParticipantsOfNotClosedReferences(profile.id);
    await this.participantDataService.delete(participantsToDelete, currentProject.id);
    if (isUnitContact) {
      const unitProfilesToDelete = await observableToPromise(this.unitProfileDataService.getByProfileId(profile.id));
      await this.unitProfileDataService.delete(unitProfilesToDelete, currentProject.id);
    }
  }

  public async deleteContacts(profiles: Profile[]) {
    if (!profiles.length) {
      return;
    }
    profiles.forEach(profile => profile.isActive = false);
    await this.profileDataService.update(profiles, profiles[0].clientId);
    await this.removeContactFromProject(profiles);
    const currentProject = await this.projectDataService.getCurrentProject();
    const participantsToDelete = new Array<Participant>();
    for (const profile of profiles) {
      const participants = await this.getParticipantsOfNotClosedReferences(profile.id);
      participantsToDelete.push(...participants);
      await this.notificationConfigRecipientDataService.deleteByProfileId(profile.id);
    }
    await this.participantDataService.delete(participantsToDelete, currentProject.id);
  }

  private async removeContactFromProject(profiles: Profile[]) {
    const currentProject = await this.projectDataService.getCurrentProject();
    const projectProfiles = await observableToPromise(this.projectProfileDataService.data);
    const projectProfilesToDelete = projectProfiles.filter((projectProfile) => projectProfile.projectId === currentProject.id &&
      profiles.find((profile) => profile.id === projectProfile.profileId));
    await this.projectProfileDataService.delete(projectProfilesToDelete, currentProject.id);
  }

  private convertToSortedProfileCompanyAddresses(profiles: Array<Profile>, addresses: Array<Address>, companies: Array<Company>): Array<ProfileCompanyAddress> {
    const profileCompanyAddresses = profiles.map((profile) => {
      const company = profile.companyId ? companies.find((value) => value.id === profile.companyId) : undefined;
      const address = profile.addressId ? addresses.find((value) => value.id === profile.addressId) : undefined;
      return {
        id: profile.id,
        profile,
        company,
        address,
        searchText: (company?.name ?? '') + ' ' + (address?.firstName ?? '') + ' ' + (address?.lastName ?? '')
      } as ProfileCompanyAddress;
    });

    return _.orderBy(profileCompanyAddresses, [value => value.company?.name?.toLowerCase(), value => value.address?.firstName?.toLowerCase(), value => value.address?.lastName?.toLowerCase()]);
  }

  public getProfilesOrActiveProjectProfiles$(profileIds?: Array<IdType>): Observable<Array<Profile>> {
    return combineLatestAsync([this.profileDataService.dataWithDefaultType$, this.projectProfileDataService.data])
      .pipe(map(([profiles, projectProfiles]) => {
        if (profileIds) {
          return profiles.filter((profile) => profileIds.includes(profile.id));
        }
        return profiles.filter((profile) => (profile.isActive === undefined || profile.isActive === true) && projectProfiles.some((projectProfile) => projectProfile.profileId === profile.id));
      }));
  }

  public isProjectProfileAndActive$(profileId: IdType): Observable<boolean> {
    return combineLatestAsync([this.profileDataService.dataWithDefaultType$, this.projectProfileDataService.data])
      .pipe(map(([profiles, projectProfiles]) => {
        return profiles.some((profile) => (profile.isActive === undefined || profile.isActive === true) &&
        (profile.id === profileId) && projectProfiles.some((projectProfile) => projectProfile.profileId === profile.id));
      }));
  }

  public getProfileCompanyAddresses$(profileIds?: Array<IdType>): Observable<Array<ProfileCompanyAddress>> {
    return this.getProfilesOrActiveProjectProfiles$(profileIds)
      .pipe(switchMap((profiles) => {
        if (!profiles?.length) {
          return of([]);
        }
        return combineLatestAsync([this.addressDataService.data, this.companyDataService.data, this.projectProfileDataService.data, this.projectCompanyDataService.data])
          .pipe(map(([addresses, companies, projectProfiles, projectCompanies]) => {
            return this.convertToSortedProfileCompanyAddresses(profiles, addresses, companies);
          }));
      }));
  }

  public getProfileCompanyAddress$(profileId: IdType): Observable<ProfileCompanyAddress|undefined> {
    return this.getProfileCompanyAddresses$([profileId])
      .pipe(map((projectProfileAddresses) => projectProfileAddresses.find((projectProfileAddress) => projectProfileAddress.profile.id === profileId)));
  }


  getSortedCompaniesWithUnitContacts$(protocolId: IdType): Observable<Array<CompanySource>> {
    return combineLatest([this.companyCraftDataService.dataForOwnClient$, this.craftDataService.dataForOwnClientWithDeletedSuffix$,
      this.addressDataService.dataForOwnClient$,
      this.profileDataService.dataForOwnClient$, this.companyDataService.dataForOwnClient$, this.profileCraftDataService.dataForOwnClient$,
      this.projectCompanyDataService.data, this.projectProfileDataService.data, this.protocolEntryDataService.data,
      this.userDataService.data, this.userInviteDataService.data, this.userConnectionInviteDataService.data, this.getOwnCompany$(),
      this.unitService.getUnitForBreadcrumbAndProfileAddressesByProtocolId$(protocolId)])
      .pipe(debounceTime(0),
        filter((allData) => allData.every((data) => Boolean(data))),
        map(this.mapFunction));
  }
}
