import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {PdfPlanFolder} from 'submodules/baumaster-v2-common';
import {v4 as uuid4} from 'uuid';
import {ToastDurationInMs} from '../../../shared/constants';
import {PdfPlanFolderDataService} from '../../../services/data/pdf-plan-folder-data.service';
import {takeUntil} from 'rxjs/operators';
import _ from 'lodash';
import {ProjectDataService} from '../../../services/data/project-data.service';
import {SystemEventService} from '../../../services/event/system-event.service';
import {LoggingService} from '../../../services/common/logging.service';
import {IonInput, NavParams} from '@ionic/angular';
import {Capacitor} from '@capacitor/core';
import {Keyboard} from '@capacitor/keyboard';
import {ToastService} from 'src/app/services/common/toast.service';
import {convertErrorToMessage} from 'src/app/shared/errors';

const LOG_SOURCE = 'PdfPlanFolderModalComponent';

@Component({
  selector: 'app-pdf-plan-folder-modal',
  templateUrl: './pdf-plan-folder-modal.component.html',
  styleUrls: ['./pdf-plan-folder-modal.component.scss'],
})
export class PdfPlanFolderModalComponent implements OnInit, OnDestroy {
  private modal: HTMLIonModalElement;

  public loading = false;
  public createMode = true;
  private destroy$ = new Subject<void>();
  public componentForm: UntypedFormGroup = this.formBuilder.group({
    name: ['', [Validators.required, this.uniqueName()]],
    icon: [''],
  });
  public pdfPlanFolder: PdfPlanFolder|undefined;
  private pdfPlanFolders: Array<PdfPlanFolder>|undefined;
  @ViewChild('nameInput', {static: false}) nameInput: IonInput;

  constructor(private formBuilder: UntypedFormBuilder, private loggingService: LoggingService, private systemEventService: SystemEventService,
              private toastService: ToastService, public navParams: NavParams,
              private pdfPlanFolderDataService: PdfPlanFolderDataService, private projectDataService: ProjectDataService) { }

  ngOnInit() {
    this.pdfPlanFolder = this.navParams.data.pdfPlanFolder;
    this.createMode = this.navParams.data.createMode;
    if (!this.createMode) {
      if (!this.pdfPlanFolder) {
        throw new Error('Component is in editMode (createMode=false) but param pdfPlanFolder was not provided.');
      }
      this.componentForm.get('name').setValue(this.pdfPlanFolder.name);
      this.componentForm.get('icon').setValue(this.pdfPlanFolder.icon);
    }
    this.pdfPlanFolderDataService.data
      .pipe(takeUntil(this.destroy$))
      .subscribe((pdfPlanFolders) => {
        this.pdfPlanFolders = pdfPlanFolders;
      });
  }

  async ionViewDidEnter() {
    setTimeout(async () => {
      if (Capacitor.isPluginAvailable('Keyboard')) {
        await Keyboard.show();
      }
      await this.nameInput.setFocus();
    }, 500);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private uniqueName(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const name: string | null = control.value;
      if (_.isEmpty(name) || !this.pdfPlanFolders) {
        return null;
      }

      const recordExist = this.pdfPlanFolders.some((pdfPlanFolder) => pdfPlanFolder.name.toLowerCase() === name.toLowerCase() &&
        (this.createMode || !this.createMode && (pdfPlanFolder.id !== this.pdfPlanFolder?.id)));
      if (recordExist) {
        return { nameExist: true };
      }

      return null;
    };
  }

  async save() {
    try {
      this.loading = true;

      const rawData = this.componentForm.getRawValue();
      const projectId = (await this.projectDataService.getCurrentProject()).id;

      if (this.createMode) {
        const pdfPlanFolder: PdfPlanFolder = {
          id: this.createMode ? uuid4() : this.pdfPlanFolder.id,
          name: rawData.name,
          icon: rawData.icon || null,
          projectId,
          changedAt: new Date().toISOString()
        };
        await this.createPdfPlanFolder(pdfPlanFolder);
      } else {
        this.pdfPlanFolder.name = rawData.name;
        this.pdfPlanFolder.icon = rawData.icon || null;
        await this.updatePdfPlanFolder(this.pdfPlanFolder);
      }
    } catch (error) {
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - Error saving PdfPlanFolder', error?.userMessage + '-' + error?.message);
      this.loggingService.error(LOG_SOURCE, `Error saving PdfPlanFolder. "${error?.userMessage}" - "${error?.message}"`);
      await this.toastService.errorWithMessageAndHeader('error_saving_message', convertErrorToMessage(error));
      throw error;
    } finally {
      this.loading = false;
    }
  }

  async createPdfPlanFolder(pdfPlanFolder: PdfPlanFolder) {
    await this.pdfPlanFolderDataService.insert(pdfPlanFolder, pdfPlanFolder.projectId);
    this.componentForm.reset();
    await this.toastService.toastWithTranslateParams('project_room.pdf_plan_folder.created', {name: pdfPlanFolder.name}, ToastDurationInMs.INFO);
    await this.modal.dismiss(pdfPlanFolder);
  }

  async updatePdfPlanFolder(pdfPlanFolder: PdfPlanFolder) {
    await this.pdfPlanFolderDataService.update(pdfPlanFolder, pdfPlanFolder.projectId);
    this.componentForm.reset();
    await this.toastService.savingSuccess();
    await this.modal.dismiss(pdfPlanFolder);
  }

  async dismissModal() {
    await this.modal.dismiss();
  }
}
