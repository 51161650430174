import { ColumnConfig } from '../ColumnConfig';
import { ProjectCustomReportTypeColumnSet } from './ProjectCustomReportTypeColumnSet';
import { ReportWeekColumnSet } from './ReportWeekColumnSet';

export const CustomReportTypeColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'name', prop: 'name'},
  {name: 'is_active', prop: 'isActive'},
  {name: 'client_id', prop: 'clientId'},
  {name: 'report_type_id', prop: 'reportTypeId'},
  {name: 'projectCustomReportTypes', fk: 'customReportTypeId', mappedBy: ProjectCustomReportTypeColumnSet, transient: true},
  {name: 'reportWeeks', fk: 'customReportTypeId', mappedBy: ReportWeekColumnSet, transient: true},
];
