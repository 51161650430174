import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {StorageQuotaLevelEnum, SystemInfoService} from '../../services/common/system-info.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-storage-quota-notification',
  templateUrl: './storage-quota-notification.component.html',
  styleUrls: ['./storage-quota-notification.component.scss'],
})
export class StorageQuotaNotificationComponent implements OnInit, OnDestroy {
  @Input()
  showCritical = false;

  isStorageQuotaLevelWarningRelevantForDevice: boolean;
  storageQuotaLevel$: Observable<StorageQuotaLevelEnum | undefined> | undefined;
  StorageQuotaLevelEnum = StorageQuotaLevelEnum;

  constructor(private systemInfoService: SystemInfoService) { }

  ngOnInit() {
    this.isStorageQuotaLevelWarningRelevantForDevice = this.systemInfoService.isStorageQuotaLevelWarningRelevantForDevice();
    if (this.isStorageQuotaLevelWarningRelevantForDevice) {
      this.storageQuotaLevel$ = this.systemInfoService.storageQuotaLevel$;
    }
  }

  ngOnDestroy(): void {
  }

  async showStorageCriticalDialog() {
    await this.systemInfoService.showStorageSpaceLeftCriticalDialog();
  }

  async showStorageWarningDialog() {
    await this.systemInfoService.showStorageSpaceLeftWarningDialog();
  }
}
