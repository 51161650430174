<ion-content>
  <ion-button class="omg-btn-text close-button" (click)="dismissModal()">
    <fa-icon [fixedWidth]="true" [icon]="['fal', 'times']" slot="icon-only"></fa-icon>
  </ion-button>
  <div *ngIf="hasInternet === true" class="d-flex flex-column centered ion-align-items-center ion-justify-content-center gap-4 p-4">
    <img class="brian-download" src="/assets/images/brian_download.png" />
    <div class="ion-text-center">
      <div class="omg-t-header-2-bold">{{'notificationSync.header' | translate}}</div>
      <div class="omg-t-body omg-f-italic text-secondary white-space-pre-line">
        {{'notificationSync.message' | translate}}
      </div>
    </div>
    <fa-icon *ngIf="(dataSyncInProgress$ | async)?.inProgress" class="text-secondary" size="2x" [icon]="['far', 'spinner']" animation="spin"></fa-icon>
  </div>
  <div *ngIf="hasInternet === false" class="d-flex flex-column centered ion-align-items-center ion-justify-content-center gap-4 p-4">
    <div class="wifi-icon">
      <fa-icon [icon]="['fal', 'wifi-slash']"></fa-icon>
    </div>
    <div class="ion-text-center">
      <div class="omg-t-header-2-bold">{{'notificationSync.offlineHeader' | translate}}</div>
      <div class="omg-t-body omg-f-italic text-secondary white-space-pre-line">
        {{'notificationSync.offlineMessage' | translate}}
      </div>
    </div>
  </div>
  <ion-col class="wifi-icon"></ion-col>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" (click)="dismissModal()">{{'cancel' | translate}}</ion-button>
    <ion-button *ngIf="!hasInternet" class="omg-btn-primary" (click)="retryConnection()">{{ 'offlineInfo.retryLabel' | translate }}</ion-button>
  </ion-buttons>
</app-modal-footer>
