import {Column, Content, Style, StyleDictionary, TDocumentDefinitions} from 'pdfmake/interfaces';
import {PdfPreview, ShowPicturesEnum} from '../../models';
import _ from 'lodash';
import {PdfProtocolSendReq} from '../../requestResponse';
import {HeaderFooterData, PdfColor} from './pdfProtocol.model';
import {canvasLine, formatDate} from './content/abstractPdf.content';
import Translation from '../../i18n/translation';
import {PdfHelperFunctions} from '../common-report-utils';
import {formatProjectNumberOptional} from '../../commonUtils';
import {EMPTY_IMAGE_BASE64} from '../../constants';
import {HIDE_PROTOCOL_DATE_ON_PDF_CLIENT_IDS} from '../../constants';

export const imageHeightByShowPicturesEnum: {[key in ShowPicturesEnum]: number|null} = {
  1: null,
  2: 320,
  4: 480,
  3: 540,
};

export abstract class AbstractPdfCommon {

  abstract getDocDefinition(config: PdfProtocolSendReq, data: any, pdfHelperFunctions: PdfHelperFunctions, bannerDimensions: number[], indexNumber?: number,
                            pdfPreview?: PdfPreview): TDocumentDefinitions;

  protected getPdfHeader(data: HeaderFooterData, config: PdfProtocolSendReq, currentPage: number): Content[] {
    const content: Content[] = [];
    if (data.lookup.protocolTypes === undefined || data.protocol === undefined || data.project === undefined) {
      throw new Error('Error generating pdf. Protocol type is undefined.');
    }
    const protocolType = data.lookup.protocolTypes.get(data.protocol.typeId);

    const protocolShortId = `${protocolType?.code}${data.protocol.number.toString().padStart(2, '0')}`;
    const rawBannerContentBase64 = this.getPdfImage(data).pdfStartBanner;
    const bannerContentBase64 = rawBannerContentBase64.startsWith('data:') ? rawBannerContentBase64 : 'data:' + rawBannerContentBase64;
    if (bannerContentBase64 !== EMPTY_IMAGE_BASE64 && config.pdfProtocolSetting?.showHeaderBannerOnEveryPage) {
      content.push({ image: bannerContentBase64, fit: [515, 105], style: ['header'] });
    }
    if (currentPage !== 1) {
      content.push({
        style: [config.pdfProtocolSetting?.showHeaderBannerOnEveryPage && bannerContentBase64 !== EMPTY_IMAGE_BASE64 ? 'marginLeft40' : 'header'],
        columns: [
          {
            width: 'auto',
            style: ['font10'],
            text: `${formatProjectNumberOptional(data.project.number)} - ${data.project.name}`
          },
          {
            style: ['marginLeft10', 'font10'],
            width: 'auto',
            text: '/'
          },
          {
            width: 'auto',
            style: ['textBold', 'marginLeft10', 'font10'],
            text: `${protocolType?.name} | Nr. ${protocolShortId}`
          },
          {
            width: '*',
            style: ['alignRight', 'textBold', 'font10'],
            margin: [0, 0, 40, 0],
            text: HIDE_PROTOCOL_DATE_ON_PDF_CLIENT_IDS.includes(data.project.clientId) ? '' : `${formatDate(data.protocol.date)}`
          }
        ]
      });
    }
    return content;
  }

  protected getPdfFooter(currentPage: number, pageCount: number, lng: string, config: PdfProtocolSendReq, data: HeaderFooterData): Content {
    const i18n = this.getI18n(lng);
    const rawBannerContentBase64 = this.getPdfImage(data).pdfEndBanner;
    const bannerContentBase64 = rawBannerContentBase64.startsWith('data:') ? rawBannerContentBase64 : 'data:' + rawBannerContentBase64;
    const footerStyle: string[] | Style = (currentPage === pageCount || config.pdfProtocolSetting?.showFooterBannerOnEveryPage) && bannerContentBase64 !== EMPTY_IMAGE_BASE64 ? ['lastFooter'] : ['footer'];
    const columns: Column[] = [];

    if (currentPage === pageCount || (config.pdfProtocolSetting?.showFooterBannerOnEveryPage && bannerContentBase64 !== EMPTY_IMAGE_BASE64)) {
      if (bannerContentBase64 !== EMPTY_IMAGE_BASE64) {
        columns.push( { image: bannerContentBase64, fit: [515, 105] });
      }
      const footerColumns: Column[] = [];

      if (config.customPdfConfig?.oemPdfs !== true && currentPage === pageCount) {
        footerColumns.push({
          fontSize: 9,
          text: `${i18n?.get('footer')}`,
          width: 'auto',
        });

        footerColumns.push({
          fontSize: 9,
          text: '-',
          style: ['marginLeft2'],
          width: 'auto',
        });

        footerColumns.push({
          fontSize: 9,
          link: 'https://bau-master.com?utm_source=baumaster&utm_medium=protokoll_protokoll&utm_campaign=protokoll_footer',
          text: 'www.bau-master.com',
          style: ['marginLeft2'],
          width: 'auto',
        });
      }

      footerColumns.push({
        width: '*',
        fontSize: 9,
        text: `${i18n?.get('page')} ${currentPage.toString()}/${pageCount}`,
        alignment: 'right',
      });

      columns.push({
        columns: footerColumns
      });
    } else {
      columns.push({
        columns: [
          {
            width: '*',
            fontSize: 9,
            text: `${i18n?.get('page')} ${currentPage.toString()}/${pageCount}`,
            alignment: 'right',
          }
        ]
      });
    }

    return {
      style: footerStyle,
      columns: [columns]
    };
  }

  protected getI18n(lng: string): Map<string, string>|undefined {
    const translation = new Translation();
    return translation.getTranslation(lng);
  }

  protected getPdfStyle(config: PdfProtocolSendReq, bannerDimensions?: number[]|undefined): StyleDictionary {
    const protocolColor = config?.pdfProtocolSetting?.protocolColor !== undefined && config?.pdfProtocolSetting?.protocolColor !== '' ? config?.pdfProtocolSetting?.protocolColor : PdfColor.BLUE;
    let footerMargin = -95;
    let letterHeadMargin = 110;
    if (bannerDimensions) {
      const ratio = Math.min(515 / bannerDimensions[0], 105 / bannerDimensions[1]);
      const actualHeight = ratio * bannerDimensions[1];
      if (actualHeight < 105) {
        footerMargin = -(actualHeight - 10);
      }
      if (bannerDimensions[3] > 1) {
        const ratioHeader = Math.min(515 / bannerDimensions[2], 105 / bannerDimensions[3]);
        const actualHeightHeader = ratioHeader * bannerDimensions[3];
        letterHeadMargin -= actualHeightHeader;
      }
    }

    return {
      font7: { fontSize: 7 },
      font8: { fontSize: 8 },
      font9: { fontSize: 9 },
      font10: { fontSize: 10 },
      font11: { fontSize: 11 },
      font12: { fontSize: 12 },
      font14: { fontSize: 14 },
      font15: { fontSize: 15 },
      font18: { fontSize: 18 },
      margin5: {margin: 5},
      greyHeading: {
        color: '#4F5964',
        bold: true,
        fontSize: 8
      },
      greyText: { color: '#4F5964' },
      italic: { italics: true },
      marginBottom2: { margin: [0, 0, 0, 2] },
      marginBottom4: { margin: [0, 0, 0, 4] },
      marginBottom6: { margin: [0, 0, 0, 6], },
      marginBottom10: { margin: [0, 0, 0, 10], },
      marginBottom20: { margin: [0, 0, 0, 20], },
      marginTop2: { margin: [0, 2, 0, 0], },
      marginTop4: { margin: [0, 4, 0, 0], },
      marginTop5: { margin: [0, 5, 0, 0], },
      marginTop6: { margin: [0, 6, 0, 0], },
      marginTop8: { margin: [0, 8, 0, 0], },
      marginTop10: { margin: [0, 10, 0, 0], },
      marginTop20: { margin: [0, 20, 0, 0], },
      marginTop25: { margin: [0, 25, 0, 0], },
      marginTop3Bottom3: { margin: [0, 3, 0, 3], },
      marginTop5Bottom6: { margin: [0, 5, 0, 6], },
      marginTop10Bottom3: { margin: [0, 10, 0, 3], },
      groupName: { margin: [0, 10, 0, 0], },
      marginLeft10Top1: { margin: [10, 1, 0, 0], },
      marginLeft10Top2: { margin: [10, 2, 0, 0], },
      marginLeft10Top8: { margin: [10, 8, 0, 0], },
      marginLeft10Top5: { margin: [10, 5, 0, 0], },
      marginLeft5Top2: { margin: [5, 2, 0, 0] },
      marginLeft10: { margin: [10, 0, 0, 0] },
      marginLeft40: { margin: [40, -25, 0, 0] },
      marginLeft5: { margin: [5, 0, 0, 0] },
      marginLeft3: { margin: [3, 0, 0, 0] },
      marginLeft2: { margin: [2, 0, 0, 0] },
      marginRight3: { margin: [0, 0, 3, 0] },
      fontColorGray: { color: '#5D5D5D' },
      fontColorRed: { color: '#AD003E' },
      fontColorBlue: { color: '#2691C8' },
      colorGreen: { color: '#78C832' },
      protocolFontColor: { color: protocolColor },
      participantCompany: { margin: [0, 2, 0, 0] },
      textBold: { bold: true },
      alignRight: { alignment: 'right' },
      alignLeft: { alignment: 'left' },
      alignCenter: { alignment: 'center' },
      companyInfo: { margin: [0, (config.pdfProtocolSetting?.showHeaderBannerOnEveryPage && bannerDimensions && bannerDimensions[2] > 1) ? -25 : 5, 0, 0] },
      participant: { margin: [10, 2, 0, 0] },
      projectInfo: { fontSize: 16, margin: [0, 25, 0, 0] },
      moreInfoPage: { margin: [0, 3, 0, 0] },
      header: { margin: [40, 30] },
      footer: { margin: [40, 10] },
      lastFooter: { margin: [40, (config.pdfProtocolSetting?.showFooterBannerOnEveryPage && bannerDimensions && bannerDimensions[0] > 1) ? 10 : footerMargin] },
      letterHead: { margin: [0, letterHeadMargin, 0, 0]},
      lastPage: { margin: [40, 105] },
      marginTop10Bottom30: { margin: [ 0, 5, 0, 30] },
      backgroundGray: { fillColor: '#EEEEEE' },
      addedLater: { bold: true, background: '#FAB500', lineHeight: 1.2, preserveLeadingSpaces: true, preserveTrailingSpaces: true },
      linkUnderline: { decoration: 'underline' }
    };
  }


  protected getPdfImage(data: HeaderFooterData): { [key: string]: string } {
    return {
      pdfStartBanner: this.getImageBase64(
        data.pdfProjectBanners?.get('pdf_start_banner')?.contentBase64 ??
        data.attachmentClients?.get('pdfStartBanner')?.contentBase64
      ),
      logo: this.getImageBase64(data.attachmentClients?.get('logo')?.contentBase64),
      pdfEndBanner: this.getImageBase64(
        data.pdfProjectBanners?.get('pdf_end_banner')?.contentBase64 ??
        data.attachmentClients?.get('pdfEndBanner')?.contentBase64
      ),
      projectPhoto: this.getImageBase64(data.attachmentProjectImage?.contentBase64),
    };
  }

  protected getImageBase64(contentBase64: string|undefined): string {
    if (_.isEmpty(contentBase64)) {
      return EMPTY_IMAGE_BASE64;
    }
    return contentBase64 + '';
  }

}
